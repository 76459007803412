<div class="loader">
  <div class="loader__content" *ngIf="quickQuote$ | async; let quickQuote; else noQuote">
    quickQuote {{quickQuote}}
    <div class="loader__content-spinner" [hidden]="quickQuote">
      <mat-spinner></mat-spinner>
    </div>
    <div
      *ngIf="quickQuote"
       class="selection"
       [ngClass]="{'reduced': reduced }">
      <div class="selection__keys">
        <div>SSIP</div>
        <div>PAS 91</div>
        <div>Common Assessment Standard</div>
      </div>
      <div class="selection__items">
        <div class="most-popular">
          <em class="fa fa-star"></em>
          <span>most popular</span>
        </div>
        <div class="selection__items-container">
          <div
            matRipple
            (click)="selectBundle(bundleNames.Standard)"
            class="selection__item standard"
            [ngClass]="{
              selected: form.value.BundleName === bundleNames.Standard
            }" *ngIf="showStandardBundle">
            <h4 class="color--standard">
                <span>Standard</span>
                <span class="price">{{ quoteAmounts.Standard | currency: currencyCode }}</span>
            </h4>
            <div><em class="fa fa-check"></em></div>
            <div><em class="fa fa-times"></em></div>
            <div><em class="fa fa-times"></em></div>
            <div><em class="fa fa-times"></em></div>
            <div><em class="fa fa-times"></em></div>
            <div><em class="fa fa-times"></em></div>
            <div><em class="fa fa-times"></em></div>
            <div><em class="fa fa-times"></em></div>
            <div><em class="fa fa-times"></em></div>
            <div><em class="fa fa-times"></em></div>
            <div><em class="fa fa-times"></em></div>
            <div><em class="fa fa-times"></em></div>
            <div><em class="fa fa-times"></em></div>
            <a class="select-membership-button" (click)="selectBundle(bundleNames.Standard)">
              {{form.value.BundleName === bundleNames.Standard ? 'selected': 'select'}}
            </a>
          </div>
  
          <div 
            matRipple
            (click)="selectBundle(bundleNames.Advanced)"
            class="selection__item advanced" 
            [ngClass]="{
            selected: form.value.BundleName === bundleNames.Advanced
            }" *ngIf="showAdvancedBundle">
            <h4 class="color--advanced">
              <span>Advanced</span>
              <span class="price">{{
                  quickQuote.advanced.quoteAmount
                  | currency: currencyCode
                }}</span>
            </h4>
            <div><em class="fa fa-check"></em></div>
            <div><em class="fa fa-check"></em></div>
            <div><em class="fa fa-times"></em></div>
            <div><em class="fa fa-check"></em></div>
            <div><em class="fa fa-check"></em></div>
            <div><em class="fa fa-check"></em></div>
            <div><em class="fa fa-check"></em></div>
            <div><em class="fa fa-check"></em></div>
            <div><em class="fa fa-times"></em></div>
            <div><em class="fa fa-times"></em></div>
            <div><em class="fa fa-times"></em></div>
            <div><em class="fa fa-times"></em></div>
            <div><em class="fa fa-times"></em></div>
            <a class="select-membership-button" (click)="selectBundle(bundleNames.Advanced)">
              {{form.value.BundleName === bundleNames.Advanced ? 'selected': 'select'}}
            </a>
          </div>

          <div class="selection__item elite" matRipple [ngClass]="{
            selected: form.value.BundleName === bundleNames.Elite
          }" *ngIf="showEliteBundle">
            <h4 class="color--elite">
              <span>Elite</span>
              <span class="price" *ngIf="showEliteBundle">
                {{ quoteAmounts.Elite | currency: currencyCode }}
              </span>
           </h4>
           <div><em class="fa fa-check"></em></div>
           <div><em class="fa fa-check"></em></div>
           <div><em class="fa fa-check"></em></div>
           <div><em class="fa fa-check"></em></div>
           <div><em class="fa fa-check"></em></div>
           <div><em class="fa fa-check"></em></div>
           <div><em class="fa fa-check"></em></div>
           <div><em class="fa fa-check"></em></div>
           <div><em class="fa fa-check"></em></div>
           <div><em class="fa fa-check"></em></div>
           <div><em class="fa fa-check"></em></div>
           <div><em class="fa fa-check"></em></div>
           <div><em class="fa fa-check"></em></div>
           <a class="select-membership-button" (click)="selectBundle(bundleNames.Elite)">
             {{form.value.BundleName === bundleNames.Elite ? 'selected': 'select'}}
           </a>
          </div>
        </div>
      </div>
    </div>  
  </div>
  <div>
    <ng-template #noQuote>
      <mat-spinner></mat-spinner>
    </ng-template>
  </div>
</div>