import { createReducer, on } from '@ngrx/store';
import * as CurrentUserActions from '../actions/currentUser.actions';
import {
  AccountContact,
  AccountResponse,
  AccountTrade,
  CheckNewAuthenticationResponse,
  CompanyDetails,
  ContactRole,
  LatestBundleResponse,
  PriceList,
} from '../../../shared';
import { WorkRegion } from 'src/app/shared/components/work-region-selecter/work-region-selecter.component';
import { ContractorVehiclesState } from '../../../shared/interfaces/vehicle-compliance.model';

export const dataFeatureKey = 'data';

export interface CurrentUserState {
  loaded: boolean;
  allAccounts: CheckNewAuthenticationResponse[];
  currentAccount: CheckNewAuthenticationResponse;
  contacts: AccountContact[];
  roles: ContactRole[];
  companyDetails: CompanyDetails;
  crm: AccountResponse;
  bundles: LatestBundleResponse[];
  priceLists: PriceList[];
  isFreeMembership: boolean;
  workRegions: WorkRegion[];
  states: WorkRegion[];
  trades: AccountTrade[];
  vehicles: ContractorVehiclesState;
  featureFlags: [];
  hasConnectedClients: boolean;
  colleagues: [];
}

export const initialState: CurrentUserState = {
  loaded: false,
  allAccounts: [],
  currentAccount: undefined,
  contacts: [],
  roles: [],
  companyDetails: undefined,
  crm: undefined,
  isFreeMembership: false,
  bundles: [],
  priceLists: [],
  workRegions: [],
  states: [],
  trades: [],
  vehicles: {
    vehicleList: [],
    membershipId: null,
    validity: null,
  },
  featureFlags: [],
  hasConnectedClients: false,
  colleagues: [],
};

export const reducer = createReducer(
  initialState,
  on(CurrentUserActions.loadCurrentUser, (state) => state),
  on(CurrentUserActions.loadCurrentUserSuccess, (state, action) => {
    return {
      ...state,
      ...action.data,
      loaded: true,
    };
  }),
  on(CurrentUserActions.loadCurrentUserFailure, (state, action) => state)
);
