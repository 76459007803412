import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderSummaryPageComponent } from './order-summary.component';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { BootstrapComponentsModule } from 'src/app/shared/components/bootstrap/bootstrap.module';

const routes: Routes = [
  { path: '', component: OrderSummaryPageComponent }
]

@NgModule({
  declarations: [OrderSummaryPageComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    BootstrapComponentsModule,
    LoadingSpinnerModule,
    BootstrapComponentsModule
  ],
  exports: [OrderSummaryPageComponent],
  providers: [

  ]
})
export class OrderSummaryPageModule { }
