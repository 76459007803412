<div class="inner_content">
  <div class="card_text">
    <p>If your business is applying for credit or trying to secure new finance, chances are those lenders and suppliers
      will search your company credit score with CreditSafe.</p>
    <p>Find out how lenders and suppliers view your business credit score.</p>
  </div>
  <div class="card_buttons">
    <button class="btn btn-primary not_active" (click)="creditModal.show(); pushGoogleTag(); getCreditScore();">Get my
      credit score</button>
  </div>
</div>


<div bsModal #creditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-sizes-name1" class="modal-title pull-left">Your Credit Score - powered by CreditSafe</h4>
        <button type="button" class="close pull-right" #closebutton (click)="creditModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <img class="bundle-image" [src]="productImage">

        <div class="loading_spinner_margin" *ngIf="isLoading">
          <app-loading-spinner></app-loading-spinner>
        </div>

        <div [hidden]="isLoading">
          <h2 *ngIf="score">{{score.creditRating.value}} - {{score.creditRating.description}}</h2>
          <h2 *ngIf="!score">Not Rated</h2>
          <h5 *ngIf="score">DATE TAKEN = {{score.lastModified | date: 'dd/MM/yyyy'}}</h5>
        </div>


        <p>
          The CreditSafe international Score represents a common risk scoring platform which enables companies to be
          compared on an international basis.
        </p>
        <h5>What do the Grades mean?</h5>
        <ul>
          <li>A = Very Low Risk</li>
          <li>B = Low Risk</li>
          <li>C = Moderate Risk</li>
          <li>D = High Risk</li>
          <li>E = Not Rated</li>
        </ul>
        <p>
          There are also instances when the company is not rated, these could be:
        </p>
        <ul class="not_rated">
          <li>Financial Statements too old</li>
          <li>Liquidated</li>
          <li>Wound-up</li>
          <li>Dissolved</li>
          <li>Petition filed</li>
        </ul>
        <p>
          Or "Not Rated" due to:
        </p>
        <ul class="not_rated">
          <li>Newly registered on Companies House</li>
          <li>Not registered on Companies House</li>
          <li>Registered at Companies House but do not need to file accounts</li>
          <li>Companies House number has not been provided</li>
        </ul>

        <br />
        <p class="text-center"><strong>How Creditsafe calculate the credit risk information for companies</strong></p>
        <br />

        <p><strong>What is the maximum company credit score?</strong></p>
        <p>The score grades are from A to E and predicts the likelihood of a company becoming insolvent within the next
          12 months. There are five bands from Very Low Risk to High Risk, there is also a not rated score in some
          circumstances.</p>

        <p><strong>How is my company credit score calculated?</strong></p>
        <p>The company credit score calculation was created by analysing companies over a 12 month period. Using
          discrimination analysis, they identified key data variables that are essential in predicting the probability
          of a company becoming insolvent within the next 12 months. These variables were then ran against their entire
          database of companies and through statistical analysis, an appropriate risk weighting was assigned to each
          variable. Through the calculation of these key variables, combined with current variables; they generate a
          credit score for each individual business.</p>

        <p><strong>How often is the credit score calculated?</strong></p>
        <p>The scores are calculated daily and are entirely automated, there is no manual calculation required to
          manipulate or adjust credit scores/ratings. Daily feeds are taken from their databases to update scores on a
          real time basis. If a company submits its latest accounts to Companies House, then as soon as these are
          available to the public (usually 10 working days later) the documents will be analysed within 48 hours and
          updated on the database. As soon as this is completed, the company's credit risk score will be
          recorded/adjusted based on the latest filed accounts.</p>

        <p><strong>The system also continuously incorporates the following time critical information, which updates the
            credit scores on a real time basis:</strong></p>
        <p>
          Companies House Gazette <br />
          London, Edinburgh & Belfast Gazette <br />
          CCJ & High Court Writ data
        </p>

        <p><strong>Are all companies scored using the same algorithm?</strong></p>
        <p>No. The algorithms are split into two; one for established companies using the Companies House definition of
          a small company, and one for all medium and large companies. There is also one for New companies (less than 22
          months old and yet to file financial information).</p>
      </div>
    </div>
  </div>
</div>