
import { createAction, props } from '@ngrx/store';
import { IRegisterstepthree } from 'src/app/shared/interfaces/new/registerStepThree';

export enum RegisterstepthreeActions {
  Add = '[Registerstepthree] Add',
  AddSuccess = '[Registerstepthree] Add Successful',
  AddError = '[Registerstepthree] Add Error',
  Remove = '[Registerstepthree] Remove',
  RemoveSuccess = '[Registerstepthree] Remove Successful',
  RemoveError = '[Registerstepthree] Remove Error',
  Load = '[Registerstepthree] Load',
  LoadSuccess = '[Registerstepthree] Load Successful',
  LoadError = '[Registerstepthree] Load Error'
}

export const registerStepThreeAdd = createAction(RegisterstepthreeActions.Add, props<{ registerStepThree: IRegisterstepthree }>());
export const registerStepThreeAddSuccess = createAction(RegisterstepthreeActions.AddSuccess, props<{ payload: IRegisterstepthree }>());
export const registerStepThreeAddError = createAction(RegisterstepthreeActions.AddError);

export const registerStepThreeRemove = createAction(RegisterstepthreeActions.Remove);
export const registerStepThreeRemoveSuccess = createAction(RegisterstepthreeActions.RemoveSuccess, props<{ payload: IRegisterstepthree }>());
export const registerStepThreeRemoveError = createAction(RegisterstepthreeActions.RemoveError);

export const registerStepThreeLoad = createAction(RegisterstepthreeActions.Load);
export const registerStepThreeLoadSuccess = createAction(RegisterstepthreeActions.LoadSuccess, props<{ payload: IRegisterstepthree }>());
export const registerStepThreeLoadError = createAction(RegisterstepthreeActions.LoadError);
