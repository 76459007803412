export interface RetrieveProductBundlesResponse {
  '@odata.etag'?: string;
  name: string;
  id?: string;
  productNumber?: number;
  refCodeProductId?: string;
  refName?: string;
  hasRefCode?: boolean;
  refCode?: string;
  priceLevelId?: string;
}
