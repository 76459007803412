export const EramsProducts = [
    {
      highlight: false,
      name: "Basic",
      price: "30",
      term: "monthly",
      withvat: "36",
      link: "https://app.handshq.com/users/sign_up?plan=1&via=chas&coupon_code=chas-1month",
      benefits: [
        "1 project per month"
      ],
      note: "If you need a one-off, sign up for a basic plan and cancel immediately. You will only be charged for one month."
    },
    {
      highlight: true,
      name: "Advanced",
      price: "70",
      term: "monthly",
      withvat: "84",
      link: "https://app.handshq.com/users/sign_up?plan=2&via=chas&coupon_code=chas-1month",
      benefits: [
        "3 projects per month",
        "Unlimited COSHH assessments"
      ],
      note: ""
    },
    {
      highlight: false,
      name: "Pro",
      price: "135",
      term: "monthly",
      withvat: "162",
      link: "https://app.handshq.com/users/sign_up?plan=11&via=chas&coupon_code=chas-1month",
      benefits: [
        "6 projects per month",
        "Unlimited COSHH assessments",
        "Revision history",
      ],
      note: ""
    }
  ];