<div *ngIf="control" class="field">
  <div *ngIf="showLabel" class="field__title">
    {{title}}
  </div>
  <div class="field__input" *ngIf="type !== 'select'">
    <small *ngIf="description">
      {{description}}
    </small>

    <div *ngIf="type !== 'places' && type !== 'companies'" [ngClass]="{
      'field--editable': readonly === false,
      'field--readonly': readonly === true
    }">
      <app-form-field-text
        [name]="name"
        [type]="type"
        [title]="title"
        [control]="control"
        [readonly]="readonly"
        [companyHouseSearch]="companyHouseSearch"
        [isCompanyPrePopulated]="isCompanyPrePopulated"
        [tooltip]="tooltip"
        (found)="onCompany($event)"
        [refCode]="refCode"
        (onValidate)="validate()">
      </app-form-field-text>
    </div>

    <div *ngIf="type === 'companies'" [ngClass]="{
      'field--editable': readonly === false,
      'field--readonly': readonly === true
    }">
      <app-form-field-companies
        [name]="name"
        [type]="type"
        [title]="title"
        [readonly]="readonly"
        [control]="control"
        [companyHouseSearch]="companyHouseSearch"
        [isCompanyPrePopulated]="isCompanyPrePopulated"
        (output)="onCompany($event)">
      </app-form-field-companies>
    </div>

    <div *ngIf="type === 'places'" [ngClass]="{
      'field--editable': readonly === false,
      'field--readonly': readonly === true
    }">
      <app-form-field-places
        [name]="name"
        [type]="type"
        [title]="title"
        [readonly]="readonly"
        [control]="control"
        [companyHouseSearch]="companyHouseSearch"
        [isCompanyPrePopulated]="isCompanyPrePopulated"
        (output)="onCompany($event)">
      </app-form-field-places>
    </div>
  </div>

  <div class="field__input" *ngIf="type === 'select'" [ngClass]="{
    'field--editable': readonly === false,
    'field--readonly': readonly === true
  }">
    <mat-form-field class="u-width-{{width}}" appearance="fill" [readonly]="readonly">
      <mat-label>{{title}}</mat-label>
      <mat-select
        [name]="name"
        [formControl]="control"
        [readonly]="readonly">
        <mat-option *ngFor="let item of items" [value]="item[optionValueKey]">{{ item[optionNameKey] }}</mat-option>
      </mat-select>
      <mat-error *ngIf="control.touched && control.invalid">
        {{title}} is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
</div>
