import { Component } from '@angular/core';

import jsonTest from 'src/assets/json/questionset-test.json';
import { CheckAuthenticationResponse } from '../../../shared/interfaces/check-authentication-response';

@Component({
  selector: 'app-questionset-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class QuestionsetTestPageComponent {

  jsonData: any = jsonTest;
  authUser = {
    AccountId: 'abcdef',
    ContactId: 'hijklmno',
    UserRoles: [
      { Privilege: "Full Access", Role: "Administration" },
      { Privilege: "Full Access", Role: "Assessments" },
      { Privilege: "Full Access", Role: "Company Details" },
      { Privilege: "Full Access", Role: "My Documents" },
      { Privilege: "Full Access", Role: "My Products" }
    ]
  } as CheckAuthenticationResponse;
  account = {
    name: 'name',
    trades: [{
      ChasWorkCode: "11111",
      ConnectionId: "xxxx-1111",
      PrimaryTradeName: "CHAS test",
      TradeName: "CHAS test"
    }]
  }


}
