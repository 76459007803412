// ==================================================================
// DEV environment configuration.
// ==================================================================
export const environment = {
  countryCode: 'AU',
  territory: 'Australia',
  production: false,
  apiEndpoint: {
    accounts: 'https://localhost:7180/api/v1/accounts',
    assessments: 'https://localhost:7180/api/v1/assessments',
    businessShield: 'https://localhost:7180/api/v1/providers/business-shield',
    catalyst: 'https://api-dev.chas.co.uk/catalyst/api',
    chasPeople: 'https://localhost:7180/api/v1/credenxia/providers/credenxia',
    chasPlant: 'https://localhost:7180/api/v1/aquipa/providers/aquipa',
    contacts: 'https://localhost:7180/api/v1/contacts',
    credits: 'https://api-dev.chas.co.uk/credits/api',
    humanFocus: 'https://localhost:7180/api/v1/providers/human-focus',
    workCategories: 'https://localhost:7180/api/v1/workcategory',
    notifications: 'https://localhost:7180/api/v1/messaging',
    documents: 'https://localhost:7180/api/v1/documents',
    products: 'https://localhost:7180/api/v1/products',
    payments: 'https://localhost:7180/api/v1/payments',
    thirdParty: 'https://localhost:7180/api/v1/products/getthirdpartyproducts',
    companyAutoComplete: 'https://localhost:7180/api/v1/companysearch',
    featureFlag: 'https://api-dev.chas.co.uk/api/v1/featureflags',
    workLocations: 'https://localhost:7180/api/v1/worklocations',
    companySearch: 'https://localhost:7180/api/v1/companysearch',
    blobProducts: '/assets/json',
    course: 'https://api-dev.chas.co.uk/course/api',
    newChasAPIv1: 'https://api-dev.chas.co.uk',
    newChasAPI: 'https://localhost:7180',
  },
  publicDocumentsUri: 'https://chaspublic.blob.core.windows.net/public-documents',

  vatPercent: 0.2,
  monitorConfig: {
    connectionString:
      'InstrumentationKey=c26779c6-9bdd-41b8-b7e5-c30ac1eac96c;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com',
  },
  googleAnalytics: {
    googleTagManagerId: 'GTM-M3VKQ96',
  },
  clickDimensions: {
    clickDimensionsId: 'azWnVCOQwBEuG9563WU6m6',
  },
  showKitchenSink: false,
  showDashboardCarousel: true,
  poll: {
    alerts: 120,
    payments: 5,
  },
  urls: {
    environmentUrl: 'http://localhost:4200',
    gatewayHostName: 'localhost:7180',
  },
  authenticationConfig: {
    instance: {
      auth: {
        clientId: 'adcae6ba-ce23-4e98-b348-c03de4ddd768',
        authority: 'https://chasdevelopment.b2clogin.com/tfp/chasdevelopment.onmicrosoft.com/b2c_1_contractor_signin',
        chasauthority: 'https://chasdevelopment.b2clogin.com/tfp/chasdevelopment.onmicrosoft.com/b2c_1_internal_chas_sign_in',
        resetAuthority: 'https://chasdevelopment.b2clogin.com/tfp/chasdevelopment.onmicrosoft.com/b2c_1_my_chas_reset',
        redirectUri: 'http://localhost:4200',
        navigateToLoginRequestUrl: false,
        validateAuthority: false,
        knownAuthorities: [
          'https://chasdevelopment.b2clogin.com/tfp/chasdevelopment.onmicrosoft.com/b2c_1_contractor_signin',
          'https://chasdevelopment.b2clogin.com/tfp/chasdevelopment.onmicrosoft.com/b2c_1_internal_chas_sign_in',
        ],
      },
      interceptorConfig: {
        protectedResourceMap: new Map([
          ['https://mychasportalapi-dev.azurewebsites.net/api/v1/companysearch/search', null],
          ['https://mychasportalapi-dev.azurewebsites.net/api/v1/pricing/quotes', null],
          ['https://mychasportalapi-dev.azurewebsites.net/api/v1/payments/complete-payment', null],
          ['https://mychasportalapi-dev.azurewebsites.net/api/v1/payments/accounts/*/isPaymentComplete', null],
          ['https://mychasportalapi-dev.azurewebsites.net/api/v1/payments/*', null],
          ['https://mychasportalapi-dev.azurewebsites.net/api/v1/accounts/registeraccountcontactopportunity', null],
          ['https://mychasportalapi-dev.azurewebsites.net/api/v1/accounts/*/payment', null],
          ['https://api-dev.chas.co.uk/catalyst/api/account', null],
          ['https://api-dev.chas.co.uk/catalyst/api/v2/checkout', null],
          [
            'https://mychasportalapi-dev.azurewebsites.net/*',
            [
              'https://chasdevelopment.onmicrosoft.com/contractorAPI/read',
              'https://chasdevelopment.onmicrosoft.com/contractorAPI/user_impersonation',
              'https://chasdevelopment.onmicrosoft.com/contractorAPI/write',
            ],
          ],
          ['https://mychasapi-dev.chas.net.au/api/v1/companysearch/search', null],
          ['https://mychasapi-dev.chas.net.au/api/v1/pricing/quotes', null],
          ['https://mychasapi-dev.chas.net.au/api/v1/payments/complete-payment', null],
          ['https://mychasapi-dev.chas.net.au/api/v1/payments/accounts/*/isPaymentComplete', null],
          ['https://mychasapi-dev.chas.net.au/api/v1/payments/*', null],
          ['https://mychasapi-dev.chas.net.au/api/v1/accounts/registeraccountcontactopportunity', null],
          ['https://mychasapi-dev.chas.net.au/api/v1/accounts/*/payment', null],
          ['https://api-dev.chas.co.uk/catalyst/api/account', null],
          ['https://api-dev.chas.co.uk/catalyst/api/v2/checkout', null],
          [
            'https://mychasapi-dev.chas.net.au/*',
            [
              'https://chasdevelopment.onmicrosoft.com/contractorAPI/read',
              'https://chasdevelopment.onmicrosoft.com/contractorAPI/user_impersonation',
              'https://chasdevelopment.onmicrosoft.com/contractorAPI/write',
            ],
          ],
          ['https://localhost:7180/api/v1/companysearch/search', null],
          ['https://localhost:7180/api/v1/pricing/*', null],
          ['https://localhost:7180/api/v1/payments/complete-payment', null],
          ['https://localhost:7180/api/v1/payments/accounts/*/isPaymentComplete', null],
          ['https://localhost:7180/api/v1/payments/*', null],
          ['https://localhost:7180/api/v1/accounts/registeraccountcontactopportunity', null],
          ['https://localhost:7180/api/v1/accounts/*/payment', null],
          ['https://localhost:7180/catalyst/api/accounts', null],
          ['https://localhost:7180/catalyst/api/contacts/*', null],
          ['https://localhost:7180/catalyst/api/v2/checkout', null],
          ['https://localhost:7180/api/v1/assessments/*', null],
          ['https://localhost:7180/api/v1/worklocations/*', null],
          ['https://localhost:7180/api/v1/messaging/*', null],
          ['https://localhost:7180/api/v1/notifications/*', null],
          [
            'https://localhost:7180/*',
            [
              'https://chasdevelopment.onmicrosoft.com/contractorAPI/read',
              'https://chasdevelopment.onmicrosoft.com/contractorAPI/user_impersonation',
              'https://chasdevelopment.onmicrosoft.com/contractorAPI/write',
            ],
          ],
        ]),
      },
      framework: {
        isAngular: true,
      },
    },
    scopes: ['https://chasdevelopment.onmicrosoft.com/contractorAPI/user_impersonation'],
  },
  termsAndConditions: {
    lastUpdated: '2021-04-01',
    rationale: ['To comply with changes in legislation and reflect changes in the provision of our services to you.'],
    termsHtml: '/assets/static/terms-and-conditions.html',
    pdfDownload: 'https://www.chas.co.uk/wp-content/uploads/2021/04/Contractor-Terms-and-Conditions-2021.pdf',
  },
  session: {
    idleTimeout: 300, //5 mins
    timeoutNotification: 30,
  },
  fastFillToken: 'Chas1234',
  basketItemsSessionStorageKey: 'newBasketItems',
  basketAccountSessionStorageKey: 'newBasketAccount',
  basketQuoteSessionStorageKey: 'newBasketQuote',
  basketSummarySessionStorageKey: 'newBasketSummary',
  quickQuoteSessionStorageKey: 'quickQuote',
  registerStepSessionStorageKey: 'registerStep',
  registerStepOneSessionStorageKey: 'registerStepOne',
  registerStepTwoSessionStorageKey: 'registerStepTwo',
  registerStepThreeSessionStorageKey: 'registerStepThree',
  registerStepFourSessionStorageKey: 'registerStepFour',
  showNewRegister: false,
};
