import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkAreaSelecterComponent } from './work-area-selecter.component';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { BootstrapComponentsModule } from 'src/app/shared/components/bootstrap/bootstrap.module';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [WorkAreaSelecterComponent],
  exports: [WorkAreaSelecterComponent],
  imports: [
    CommonModule,
    LoadingSpinnerModule,
    BootstrapComponentsModule,
    MatTreeModule,
    MatIconModule,
    MatCheckboxModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule
  ]
})
export class WorkAreaSelecterModule { }
