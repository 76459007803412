<div class="carousel" [ngClass]="{
  'carousel--red': carouselIndex === 0,
  'carousel--purple': carouselIndex === 1,
  'carousel--blue': carouselIndex === 2
}">
  <div *ngIf="!isAus" class="carousel__content" [hidden]="carouselIndex !== 2">
    <h4>
      <em class="fas fa-star"></em>
      <em class="fas fa-star"></em>
      <em class="fas fa-star"></em>
      <em class="fas fa-star"></em>
      <em class="fas fa-star"></em>
    </h4>
    <h5 class="review">
      <span class="review__content">The CHAS renewal process was straight forward and dealt with our application very professionally.</span>
      <span class="review__author">JJ Integrated Solutions Ltd</span>
    </h5>
  </div>
  <div class="carousel__content" [hidden]="carouselIndex !== 1">
    <h4>
      Welcome <br>to a safer world of working!
    </h4>
    <p>Manage your CHAS membership, accreditation and risk management products from an easy-to-use dashboard. My CHAS is designed to help you proactively manage risks, easily access exclusive member benefits, saving your company time and money.</p>
    <div class="carousel__content-info" hidden>
      Not a member yet? <br><a [href]="registerUrl">Create Your Account</a>
    </div>
  </div>
  <div class="carousel__content" [hidden]="carouselIndex !== 0">
  <h4>
    Need
    <br>
    Any
    <br>
    Help?
  </h4>
  <p>Get in touch for more help from our award-winning team.</p>
  <div class="carousel__content-info">
    <strong>Phone: </strong>
    <span>{{phoneNumber}}</span>
    <small>Lines open {{ operatingHours }}</small>
  </div>
  <div class="carousel__content-info">
    <strong>Email: </strong>
    <a href="mailto:{{emailAddress}}">{{emailAddress}}</a>
  </div>
  <div class="carousel-info">
    <a href="{{mainUrl}}/faq/" target="_blank">FAQ's</a>
  </div>
  </div>
 <div class="carousel__footer">
    <div
      matRipple
      (click)="setCarouselIndex(0)"
      [ngClass]="{
        'active': carouselIndex === 0
      }"></div>
    <div
      matRipple
      (click)="setCarouselIndex(1)"
      [ngClass]="{
        'active': carouselIndex === 1
      }"></div>
    <div
      matRipple
      (click)="setCarouselIndex(2)"
      *ngIf="!isAus"
      [ngClass]="{
        'active': carouselIndex === 2
      }"></div>
  </div>
  <div *ngIf="!isAus" class="carousel__feefo">
    <img src="/assets/img/carousel/feefo.png" alt="CHAS Feefo Reviews"/>
  </div>
  <div class="carousel__back">
    <a href="/">Login to CHAS</a>
    <a [href]="mainUrl">Return to CHAS</a>
  </div>
</div>
