<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">We've updated our Terms and Conditions</h4>
  </div>
  <div class="modal-body">
    <div>
      <p>We have updated your Terms and Conditions:</p>
      <ul>
        <li *ngFor="let listItem of rationale">{{ listItem }}</li>
      </ul>
    </div>
    <iframe class="terms-frame" [attr.src]="termsHtml"></iframe>
    <p class="text-danger error-message" *ngIf="errorMessage">{{ errorMessage }}</p>
  </div>
  <div class="modal-footer">
    <a target="_blank" [attr.href]="pdfDownloadUrl" rel="noopener">View / Download PDF Document</a>
    <button type="button" class="btn btn-primary" (click)="acceptTermsAsync()" [class.is-loading]="isPosting">Accept and close</button>
  </div>
</ng-template>