import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DocumentListComponent } from './document-list.component';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { BootstrapComponentsModule } from '../bootstrap/bootstrap.module';
import { PipesModule } from '../../pipes/pipes.module';


@NgModule({
  declarations: [DocumentListComponent],
  imports: [
    CommonModule,
    FormsModule,
    BootstrapComponentsModule,
    LoadingSpinnerModule,
    PipesModule
  ],
  exports: [DocumentListComponent]
})
export class DocumentListModule { }
