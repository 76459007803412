import { Pipe, PipeTransform } from '@angular/core';
import { ComplianceStatus, ComplianceStatusColours } from '../interfaces/compliance.model';

@Pipe({
  name: 'complianceStatusColours',
})
export class ComplianceStatusColoursPipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case ComplianceStatus.Inconclusive:
        return ComplianceStatusColours.Purple;
      case ComplianceStatus.Unregistered:
        return ComplianceStatusColours.Grey;
      case ComplianceStatus.Risk:
        return ComplianceStatusColours.Red;
      case ComplianceStatus.Expiring:
        return ComplianceStatusColours.Orange;
      case ComplianceStatus.InProgress:
      case ComplianceStatus.UpdateRequired:
        return ComplianceStatusColours.Yellow;
      case ComplianceStatus.Accredited:
        return ComplianceStatusColours.Green;
      default:
        return null;
    }
  }
}
