import { Injectable } from '@angular/core';
import { Environment } from 'src/app/shared/classes/environment';

import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ParentWorkCategory, CheckBundlesMatchCategories, LatestBundleResponse, WorkCategory, WorkCategoryView, UnspscCode } from '../../shared';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountsService, WorkCategoriesModalComponent } from '..';
import { ApplicationType } from 'src/app/shared/classes/applicationType.class';

@Injectable({
  providedIn: 'root'
})
export class TradeService {
  private env = new Environment();
  private environment = this.env.getConfigSync();

  constructor(
    private http: HttpClient,
    private modalService: BsModalService,
    private accountService: AccountsService
  ) {}

  getCategories(): Observable<UnspscCode[]> {
    const url = `${this.environment.apiEndpoint.workCategories}/getworkcategorieshierarchy`;
    return this.http.get<UnspscCode[]>(url);
  }

  getAllWorkCategoryViewsByAccountId(accountId: string): Observable<WorkCategoryView[]> {
    const url = `${this.environment.apiEndpoint.workCategories}/account/${accountId}/getaccountallworkcategoryviews`;
    return this.http.get<WorkCategoryView[]>(url);
  }


  getMatchingCategories(accountId: string): Observable<ParentWorkCategory[]> {
    const url = `${this.environment.apiEndpoint.workCategories}/account/${accountId}/getaccountworkcategorieshierarchyall`;
    return this.http.get<ParentWorkCategory[]>(url);
  }

  private checkCategoriesMatchVariant(accountId: string, variant: number): Observable<WorkCategory[]> {
    const url = `${this.environment.apiEndpoint.workCategories}/account/${accountId}/variant/${variant}/work-categories`;

    return this.http.get<WorkCategory[]>(url);
  }

  /**
   * Update an account with specified work [sub]categories.
   */
  updateAccount(accountId: string, subcategoryIds: (string|number)[]): Observable<any> {
    const rows = subcategoryIds.map(id => {
      return { workCode: id, accountId: accountId };
    });

    // Only perform an update if there are work categories to save.
    if (rows.length) {
      const url = `${this.environment.apiEndpoint.workCategories}/update-account-workcategories`;
      const request = rows;
      return this.http.put(url, request);
    }

    return throwError('No subcategories selected.');
  }

  public async checkCategoriesAreCorrect(accountId: string, contactId: string, bundle: LatestBundleResponse, showAlways: boolean = false): Promise<boolean> {
    const result: CheckBundlesMatchCategories = {};

    // const categoryResult = await this.doCategoriesMatch(bundle, accountId);

    result[bundle.variant] = {
      bundle: bundle,
      result: null
    };

    if(!(bundle || showAlways)) return true;
    
    this.showWorkCategories(result, accountId, false);

    return false;
  }

  public async areCategoriesCorrect(accountId: string, contactId: string, showAlways: boolean = false): Promise<boolean> {
    const result = await this.checkBundlesMatchCategories(accountId, contactId);
    const bundles = Object.keys(result);

    if(bundles.length !== 0 || showAlways) {
      this.showWorkCategories(result, accountId, false);
      return false;
    }

    return true;
  }

  public showWorkCategories(notMatchingCategories: CheckBundlesMatchCategories, accountId: string, result: boolean): void {
    const applicationTypeKey = Object.keys(notMatchingCategories)[0];
    const bundle = notMatchingCategories[applicationTypeKey].bundle;

    this.modalService.show(
      WorkCategoriesModalComponent,
      {
        animated: false,
        backdrop: 'static',
        class: 'modal-xl modal-dialog-scrollable',
        initialState: {
          accountId: accountId,
          applicationType: new ApplicationType().getType(bundle).applicationType,
          showAlertBox: !result,
          autoRemoveCategories: notMatchingCategories
        }
      }
    );
  }

  private async doCategoriesMatch(bundle: LatestBundleResponse, accountId: string): Promise<WorkCategory[]> {
    const checkCategoriesMatch: WorkCategory[] = await this.checkCategoriesMatchVariant(accountId, bundle.variant).toPromise();

    return checkCategoriesMatch.filter((category) => {
      return category.matchesAccountVariant === false;
    });
  }

  private async checkBundlesMatchCategories(accountId: string, contactId: string): Promise<CheckBundlesMatchCategories> {
    const bundles = await this.accountService.getLatestBundle(accountId, contactId).toPromise();
    const result: CheckBundlesMatchCategories = {};

    for (const bundle of bundles) {
      const categoryResult = await this.doCategoriesMatch(bundle, accountId);

      result[bundle.variant] = {
        bundle: bundle,
        result: categoryResult
      };
    }

    return result;
  }
}
