import { Component, OnInit } from '@angular/core';
import { Environment } from 'src/app/shared/classes/environment';
import { HelplinePhoneNumber } from 'src/app/shared/components/register-carousel/register-carousel.component';
import { Territory } from 'src/app/shared/constants/territory.enum';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss'],
})
export class MembershipPageComponent implements OnInit {
  public phoneNumber = HelplinePhoneNumber.UNITED_KINGDOM;
  public membershipId = '';
  private readonly data$ = this.store.select(selectCurrentUserState);
  private isFoundation: boolean;
  private isUpgrade: boolean;
  private isRenewal: boolean;

  constructor(
    private readonly store: Store,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    await this.preloadEnvDataAsync();
    this.preloadUserData();
  }

  private async preloadEnvDataAsync() {
    const env = new Environment();
    const environment = await env.getConfig();

    let isAus = environment.territory === Territory.AUSTRALIA;

    if (isAus) {
      this.phoneNumber = HelplinePhoneNumber.AUSTRALIA;
    }

    this.isRenewal = this.router.url.includes('renew');
    this.isUpgrade = this.router.url.includes('upgrade');
    this.isFoundation = this.router.url.includes('foundation');
  }

  private preloadUserData() {
    this.data$.subscribe({
      next: async (data) => {
        if (!data.loaded) {
          return;
        }
        this.membershipId = data.currentAccount.membershipId;
      },
      error: (response: any) => {
        console.error('failed to load user', response.error);
      },
    });
  }
}
