import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, Observer, of } from 'rxjs';
import { finalize, first, switchMap } from 'rxjs/operators';
import { CompanySearchService } from 'src/app/core';
import { CompanyAutoCompleteService } from 'src/app/core/services/company-auto-complete.service';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();
import { CompanySearchEvent } from './company-search-event/company-search-event';

@Component({
  selector: 'app-company-search-field',
  templateUrl: './company-search-field.component.html',
  styleUrls: ['./company-search-field.component.scss']
})
export class CompanySearchFieldComponent implements OnInit {

  constructor(
    private companyAutoCompleteService: CompanyAutoCompleteService,
    private CompanySearch: CompanySearchService
  ) {  }

  private selectedCompany: any;
  loading: boolean;
  companyIsDissolved: boolean;
  companyNotFound: boolean;
  companySearchError: boolean;
  creditSafeSearchFlag: boolean;

  suggestions: Observable<any[]>;
  searchForm = new UntypedFormGroup({
    companyNumber: new UntypedFormControl('', Validators.required)
  });

  @Output() companySearch: EventEmitter<CompanySearchEvent> = new EventEmitter();

  ngOnInit(): void {
    this.suggestions = new Observable((observer: Observer<string>) => {
      observer.next(this.searchForm.value.companyNumber)
    })
      .pipe(
      first(),
      switchMap((query: string) => {
          if (query) {
            this.loading = true;
            return this.companyAutoCompleteService.searchCompanyByName(query);
          }

          return of([]);
        },
        ), finalize(() => { setTimeout(() => { this.loading = false }, 2000) }),);
  }

  searchCompaniesHouse(): void {
    if (this.searchForm.invalid) {
      return;
    }

    this.searchCompany(this.searchForm.value.companyNumber);
  }

  enterDetailsManually(): void {
    this.companySearch.emit({
      eventType: 'manualEntry',
      companySearchEventProperties: undefined
    });
  }

  shouldShowFastFill(): boolean {
    return !environment.production;
  }

  fastFill(): void {
    if (!this.shouldShowFastFill()) {
      return;
    }

    this.companySearch.emit({
      eventType: 'fastFill',
      companySearchEventProperties: undefined
    });
  }


  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.selectedCompany = e.item;
    this.searchCompany(e.item.companyHouseNumber);
  }

  async searchCompany(companyHouseNo: string): Promise<void> {
    this.companyIsDissolved = false;
    this.companyNotFound = false;
    this.companySearchError = false;

    this.searchForm.disable();
    this.CompanySearch.search(companyHouseNo)
    .pipe(
      first(),
      finalize(() => {
        this.searchForm.enable();
      })
    )
    .subscribe(
      (data) => {
        var exactMatch = data.items.find(result => {
          return result.isExactMatchOnSystemId;
        });
        
        if (exactMatch) {
          this.companySearch.emit({
            eventType: 'companieshouse_found',
            companySearchEventProperties : {
               searchSystemId: exactMatch.systemId,
              companiesHouseCompanyDetails: exactMatch,
            }

          });
        }
        else {
          this.companyNotFound = true;
          this.companySearch.emit({
            eventType: 'companieshouse_notfound',
            companySearchEventProperties : {
              searchSystemId: this.searchForm.value.companyNumber,
              companiesHouseCompanyDetails: undefined
            }
          });
        }

      },
      () => {
        this.companySearchError = true;
      }
    )
  }

}
