import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BranchAddressesModalComponent } from './branch-addresses-modal.component';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BootstrapComponentsModule } from '../bootstrap/bootstrap.module';

@NgModule({
  declarations: [BranchAddressesModalComponent],
  imports: [CommonModule, ReactiveFormsModule, BootstrapComponentsModule, LoadingSpinnerModule],
  exports: [BranchAddressesModalComponent]
})
export class BranchAddressesModalModule {}
