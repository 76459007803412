<div class="site-footer__left">&copy; CHAS 2013 Ltd. All rights reserved {{ currentYear }}.
    <a [href]="mainUrl + '/contractor-terms-conditions/'" target="_new"><i class="fa fa-file-signature"></i> Terms & Conditions</a>
    <a [href]="mainUrl + '/privacy-policy/'" target="_new"><i class="fa fa-user-secret"></i> Privacy Policy</a>


</div>

<div class="site-footer__right" >
    Need help?
    <a [href]="'mailto:' + emailAddress"><i class="fa fa-envelope"></i> {{emailAddress}}</a>
    <a [href]="'tel:' + phoneNumber"><i class="fa fa-phone"></i> {{phoneNumber}}</a>
</div>
