import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkRegionSelecterComponent } from './work-region-selecter.component';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { BootstrapComponentsModule } from '../bootstrap/bootstrap.module';

@NgModule({
  declarations: [WorkRegionSelecterComponent],
  exports: [WorkRegionSelecterComponent],
  imports: [
    CommonModule,
    LoadingSpinnerModule,
    BootstrapComponentsModule,MatTreeModule,MatIconModule,MatCheckboxModule]
})
export class WorkRegionSelecterModule { }
