<div class="accreditation-list__group" *ngFor="let row of assessmentGroups; let i = index">
  <button
    class="accreditation-list__group__button {{ row.class }}"
    [attr.aria-expanded]="row.isOpen"
    [attr.aria-controls]="'alist-' + i"
    (click)="toggleGroup(row)"
  >
    {{ row.name }} ({{ row.list.length | number }})
  </button>

  <ul [id]="'alist-' + i" class="accreditation-list__list" *ngIf="row.list.length" [class.collapse]="!row.isOpen">
    <li *ngFor="let a of row.list; trackBy: trackByFn">
      <a href="">{{ a.FullAssessmentName }}</a>
    </li>
  </ul>
</div>
