
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { QuickquoteActions, quickQuoteAdd, quickQuoteLoad, quickQuoteRemove } from '../actions/quickQuote.actions';
import { QuickquoteService } from '../../services/new/quickQuote.service';

@Injectable()
export class QuickquoteEffects {
  load$ = createEffect(() => this.actions$.pipe(
    ofType(quickQuoteLoad),
    mergeMap(() => this.quickQuoteService.getQuickquote().pipe(
      map(quickQuote => ({ type: QuickquoteActions.LoadSuccess, payload: quickQuote })),
      catchError(() => of({ type: QuickquoteActions.LoadError }))
    ))
  ));
  add$ = createEffect(() => this.actions$.pipe(
    ofType(quickQuoteAdd),
    mergeMap((action) => this.quickQuoteService.addQuickquote(action.quickQuote).pipe(
      map(quickQuote => ({ type: QuickquoteActions.AddSuccess, payload: quickQuote })),
      catchError(() => of({ type: QuickquoteActions.AddError }))
    ))
  ));
  remove$ = createEffect(() => this.actions$.pipe(
    ofType(quickQuoteRemove),
    mergeMap((action) => this.quickQuoteService.removeQuickquote().pipe(
      map(quickQuote => ({ type: QuickquoteActions.RemoveSuccess, payload: quickQuote })),
      catchError(() => of({ type: QuickquoteActions.RemoveError }))
    ))
  ));

  constructor(
    private actions$: Actions,
    private quickQuoteService: QuickquoteService
  ) { }
}
