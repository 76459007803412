import { Injectable } from '@angular/core';
import { ProductBundleDetails } from 'src/app/shared';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { GtmEvent, GtmUserCategory, GtmUserStatus } from './gtm.enum';
import { DataLayerObject, GtmAddToCart, GtmCompleteAssessment, GtmDirectEmployees } from './gtm.interface';
import { GtmOrderConfirmation, GtmOrderSummary, GtmPageView } from './gtm.interface';
import { GtmPayNow, GtmProductItem, GtmProductList, GtmReedemCode } from './gtm.interface';
import { GtmRequestCallback, GtmSignUp, GtmVideoClick } from './gtm.interface';

declare const dataLayer: DataLayerObject[] | undefined;

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  private environment: IEnvironment;
  
  constructor() {
    const env = new Environment();
    this.environment = env.getConfigSync();
  }

  public pageView(data: GtmPageView): void {
    this.pushTag({
      id: this.environment.googleAnalytics.googleTagManagerId,
      event: GtmEvent.pageMetaData,
      page_type: data.type,
      page_category: data.category,
      page_name: data.name,
      user_id: data.uid, //A unicode or User CRM ID
      user_status: data.status? GtmUserStatus.LoggedIn: GtmUserStatus.LoggedOff,
      user_category: GtmUserCategory.Contractor // contractor
    });
  }

  public contractorSignUp(data: GtmSignUp): void {
    this.pushTag({
      id: this.environment.googleAnalytics.googleTagManagerId,
      event: GtmEvent.contractorRegistration,
      user_id: data.uid, //A unique ID assigned to the users
      user_status: data.category,
      user_category:  GtmUserCategory.Contractor
    });
  }

  public requestCallback(data: GtmRequestCallback): void {
    this.pushTag({
      id: this.environment.googleAnalytics.googleTagManagerId,
      event: GtmEvent.requestCallback,
      page: data.path,
      page_category: data.category,    //contractor_account, /client_account, /common assessment standard, /pas91 / if it's home page then home page etc
      user_category: data.category //client
    });
  }

  public videoClick(data: GtmVideoClick): void {
    this.pushTag({
      id: this.environment.googleAnalytics.googleTagManagerId,
      event: GtmEvent.video,
      action: data.action, //'pause', '25%','50%','75%','100%' //if available
      video_title: data.title
    });
  }

  public completeAssessment(data: GtmCompleteAssessment): void {
    this.pushTag({
      id: this.environment.googleAnalytics.googleTagManagerId,
      event: GtmEvent.completeAssessment,
      dashboard: data.dashboard,
      assessment_status:  data.status,
      assessment_name: data.name //assesment name should be passed here
    });
  }

  public redeemCode(data: GtmReedemCode): void {
    this.pushTag({
      id: this.environment.googleAnalytics.googleTagManagerId,
      event: GtmEvent.redeemCode,
      code_name: data.name
    });
  }

  public productList(data: GtmProductList): void {
    this.pushTag({ ecommerce: null });  // Clear the previous ecommerce object.

    this.pushTag({
      id: this.environment.googleAnalytics.googleTagManagerId,
      event: GtmEvent.viewList,
      ecommerce: data
    });
  }

  public productItem(data: GtmProductItem): void {
    this.pushTag({ ecommerce: null });  // Clear the previous ecommerce object.

    this.pushTag({
      id: this.environment.googleAnalytics.googleTagManagerId,
      event: GtmEvent.viewItem,
      ecommerce: {
        click: {
          actionField: data.actionField,  // if it's signuppage, pass signup
          products: [data.product]
         }
      }
    });
  }

  public selectNumberOfDirectEmployees(data: GtmDirectEmployees): void {
    this.pushTag({ ecommerce: null });  // Clear the previous ecommerce object.

    this.pushTag({
      id: this.environment.googleAnalytics.googleTagManagerId,
      event: 'view_item',
      ecommerce: {
        detail: {
          actionField: data.actionField, // if it's signuppage, pass signup
          products: [data.products]
        }
      }
    });
  }

  public addToCart(data: GtmAddToCart): void {
    this.pushTag({ ecommerce: null });

    this.pushTag({
      id: this.environment.googleAnalytics.googleTagManagerId,
      event: GtmEvent.addToCart,
      direct_employees: data.directEmployees,
      labour_only_subcontractors: data.labourOnlySubcontractors,
      effective_employees: data.effectiveEmployees,
      bonafide_sub_contraactors: data.bonafideSubContraactors,
      user_id: data.uid,
      ecommerce: data.ecommerce
    });
  } // order now

  public orderSummary(data: GtmOrderSummary): void {
    this.pushTag({ ecommerce: null });

    this.pushTag({
      id: this.environment.googleAnalytics.googleTagManagerId,
      event: GtmEvent.checkout,
      user_status: data.status,
      user_id: data.uid,
      ecommerce: {
        checkout: {
          actionField: data.ecommerce.checkout.actionField,
          products: data.ecommerce.checkout.products
        }
      }
    });
  }

  public payNow(data: GtmPayNow): void {
    this.pushTag({ ecommerce: null });

    this.pushTag({
      id: this.environment.googleAnalytics.googleTagManagerId,
      event: GtmEvent.checkout,
      user_status: data.status,
      user_id: data.uid,
      ecommerce: {
        checkout: {
          actionField: data.ecommerce.checkout.actionField,
          products: data.ecommerce.checkout.products
        }
      }
    });
  }

  public orderConfirmation(data: GtmOrderConfirmation): void {
    this.pushTag({ ecommerce: null });  // Clear the previous ecommerce object.

    this.pushTag({
      id: this.environment.googleAnalytics.googleTagManagerId,
      event: GtmEvent.orderConfirmation,
      ecommerce: {
        payment_method: data.method,// debit card or cash etc
        purchase: {
          actionField: data.purchase.actionField,
          products: data.purchase.products
        }
      }
    });
  }

  public selectBundle(bundle: ProductBundleDetails) {
    this.pushTag({
      id: this.environment.googleAnalytics.googleTagManagerId,
      event: 'select_bundle',
      bundle: bundle
    });
  }

  public pushTag(data: DataLayerObject) {
    if(typeof dataLayer !== 'undefined') { 
      dataLayer.push(data);
    }
  }
}
