import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LatestBundleResponse, CheckAuthenticationResponse, AccountResponse, ActiveCard, ThirdPartyProduct, UserTypeCode, CompanyDetails } from '../../../shared';
import { ProductName } from 'src/app/shared/classes/productName.class';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class DashboardProductCardComponent  {
  @Input() authAccount: CheckAuthenticationResponse;
  @Input() companyDetails: CompanyDetails;
  @Input() bundle: LatestBundleResponse;
  @Input() type: string;
  @Input() active: ActiveCard;
  @Input() product: ThirdPartyProduct;
  @Input() account: AccountResponse;
  @Input() typeAndVariant: { assessmentType: string, variant: string };
  @Input() membershipExpired: boolean;
  @Input() foundation: boolean;
  ragStyle: any = 'white';
  isLoading: boolean;
  error: string;
  modalRef: BsModalRef;
  quoteAmount: number = 0;
  public customerTypes = UserTypeCode;
  tilesCount: number = 1;

  constructor(){
    //this.isLoading = true;
  }

  getRagClass($event) {
    this.ragStyle = $event[0];
    //this.isLoading = $event[1];
  }

  get checkComplianceCard() {
    if (this.type === 'CHAS'
      || this.type === 'CHAS Assured'
      || this.type === 'Standard Assured'
      || this.type === 'CHAS Premium'
      || this.type === 'CHAS Premium Plus'
      || this.type === 'CHAS Premium Plus Assured'
      || this.type === 'CHAS Premier (CAS) Assured'
      || this.type === 'CHAS Advanced'
      || this.type === 'CHAS Elite'
      || this.type === 'CHAS Standard'
      || this.type === 'Verified Contractor'
      || this.type === 'Verified Supplier'
      || this.type === 'Elite'
      || this.type === 'Elite with 1 Client Connection'
      || this.type === ProductName.level1
      || this.type === ProductName.level2
      || this.type === ProductName.level2Assured
      || this.type === ProductName.level3
      || this.type === ProductName.level3Assured
      || this.type === ProductName.level4
      || this.type === ProductName.level4Assured
      || this.type === 'Supplier'
      || this.type === null) return true;
    else return false;
  }

  get productImage(): string {
    let x = this.type ? this.type.toLowerCase().replace(',', '').replace('assured', null).split(' ') : [];
    const index = x.indexOf("null");
    if (index > -1) {
      x.splice(index, 1);
    }
    return this.type ? `assets/img/product-cards/${x.join('_')}.svg` : `assets/img/product-cards/chas.png`;

  }

  public getCustomerType(type: string) {
    return this.authAccount.MembershipId === type;
  }
}
