<div class="content">

  <app-survey-introduction [introText]="introText" [surveyAssistInfo]="surveyAssistInfo" [displaySurveyAssist]="displaySurveyAssist" *ngIf="!readonlyMode && (introText || displaySurveyAssist) && !surveyWasJustSubmitted"></app-survey-introduction>

  <div appQuestionsetHost>
    <div id="surveyElement"></div>
  </div>

  <div *ngIf="surveyWasJustSubmitted">
    <button type="button" class="btn btn-primary" (click)="goToDashboard()">
      Go back to the dashboard
    </button>
  </div>
</div>

<ng-template #certificateOptionsModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">How do you want your certificate?</h4>
  </div>
  <div class="modal-body">
    <br />
    <alert type="light">
      <p>Once your application has been verified as successful, you will automatically receive an electronic certificate via email.</p>

      <p>You also have the option to receive a hard copy certificate and stickers, by selecting one of the “opt-out” options, you will be taking action for the future of our planet. As part of CHAS’ sustainability initiative, we will donate *£1 or **£2 to Sustainable Merton.</p>
    </alert>
  </div>

  <form [formGroup]="certificateForm">
    <div class="form-group">
      <div class="certificate_option_container">
        <div class="custom-control custom-checkbox mr-4" *ngFor="let option of certificateOptions; let first = first; let last = last; trackBy: trackByFn" #inputEl>
          <p *ngIf="first">Please select one of the following “opt-out” options:</p>
          <p class="mt-3" *ngIf="last">Alternatively, you can select the “opt-in” option:</p>
          <input type="radio"
                 id="{{option.code}}"
                 name="certificateOption"
                 formControlName="certificateOption"
                 [value]="option.code"
                 (change)="changeCertificateOption($event)"
                 [checked]="first"
                 class="custom-control-input" />
          <label class="custom-control-label ml-5" for="{{option.code}}">{{option.description}}</label>
        </div>
        <p class="m-4">Our individual actions have a global impact.Together we can make small changes that add up to big results</p>
      </div>

      <div class="modal-footer clearfix">
        <button type="button"
                (click)="submitCertificateChoice(certificateForm.get('certificateOption').value)"
                class="btn btn-primary float-right"
                style="margin:0;"
                [class.is-loading]="modalBtnLoading">
          Save
        </button>
      </div>
    </div>
  </form>

</ng-template>

<!-- Dialogue to confirm deleting a contact. -->
<ng-template #modalDisabledAssessment>
  <div class="modal-body text-center">
    <alert type="danger"> No work categories submitted! </alert>
    <p>
      <br />In order to complete your Health & Safety assessment you first need to add work categories in the company details page
    </p>
  </div>
  <div class="modal-footer clearfix">
    <button type="button" class="btn btn-primary float-right" [routerLink]="['/company-details']" (click)="goToCompanyDetails()" style="margin:0;">
      Go to Company Details
    </button>
  </div>

</ng-template>

<!-- Dialogue for answers check warning. -->
<ng-template #answersCheckWarningModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Have you updated your answers?</h4>
  </div>
  <div class="modal-body">
    <p>Please confirm whether you have checked that all fields are correct and up to date.</p>
  </div>
  <div class="modal-footer clearfix">
    <button type="button" class="btn btn-secondary float-right mr-4" (click)="confirmAnswersCheck()" style="margin:0;">
      Yes, all details are correct
    </button>
    <button type="button" class="btn btn-primary float-right" (click)="modalRef.hide()" style="margin:0;">
      No, I want to go back
    </button>
  </div>
</ng-template>

<ng-template #previouslyUploadedDocumentPicker>
  <app-document-list-modal
    [accountId]="accountId"
    [documents]="documents"
    [selectedQuestion]="selectedUploadQuestion"
    [uploadedFiles]="uploadedFiles"
    (closeModal)="closeModal()"
    (filesAdded)="filesAdded($event)">
  </app-document-list-modal>
</ng-template>

<ng-template #resubmitWarningModel>
  <div class="modal-body">
    <div *ngIf="completedQuestionsForResubmit.length > 0">
      <p>Thank you for updating sections with more information</p>
      <ul *ngIf="completedQuestionsForResubmit.length > 0">
        <li *ngFor="let section of completedQuestionsForResubmit;">
          {{section}}
        </li>
      </ul>
    </div>
    <div *ngIf="uncompletedQuestionsForResubmit.length > 0">
      <p>It appears that you have not responded to all the areas. Please check that you have responded to the following sections:</p>
      <ul>
        <li *ngFor="let section of uncompletedQuestionsForResubmit;">
          {{section}}
        </li>
      </ul>
      <p>Failure to address all sections will result in a delay in your assessment.</p>
    </div>
    </div>
    <div class="modal-footer clearfix">
      <button type="button" class="btn btn-primary float-right mr-4" (click)="modalRef.hide()" style="margin:0;">
        Return to assessment
      </button>
      <button type="button" class="btn btn-primary float-right" (click)="confirmResubmit()" style="margin:0;">
        Confirm and submit
      </button>
    </div>
</ng-template>
