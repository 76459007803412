
import { createAction, props } from '@ngrx/store';
import { IQuickquote } from 'src/app/shared/interfaces/new/quickQuote';

export enum QuickquoteActions {
  Add = '[Quickquote] Add',
  AddSuccess = '[Quickquote] Add Successful',
  AddError = '[Quickquote] Add Error',
  Remove = '[Quickquote] Remove',
  RemoveSuccess = '[Quickquote] Remove Successful',
  RemoveError = '[Quickquote] Remove Error',
  Load = '[Quickquote] Load',
  LoadSuccess = '[Quickquote] Load Successful',
  LoadError = '[Quickquote] Load Error'
}

export const quickQuoteAdd = createAction(QuickquoteActions.Add, props<{ quickQuote: IQuickquote }>());
export const quickQuoteAddSuccess = createAction(QuickquoteActions.AddSuccess, props<{ payload: IQuickquote }>());
export const quickQuoteAddError = createAction(QuickquoteActions.AddError);

export const quickQuoteRemove = createAction(QuickquoteActions.Remove);
export const quickQuoteRemoveSuccess = createAction(QuickquoteActions.RemoveSuccess, props<{ payload: IQuickquote }>());
export const quickQuoteRemoveError = createAction(QuickquoteActions.RemoveError);

export const quickQuoteLoad = createAction(QuickquoteActions.Load);
export const quickQuoteLoadSuccess = createAction(QuickquoteActions.LoadSuccess, props<{ payload: IQuickquote }>());
export const quickQuoteLoadError = createAction(QuickquoteActions.LoadError);
