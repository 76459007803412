import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectQuickQuote } from 'src/app/core/ngrx/selectors/quickQuote.selectors';
import { IQuickquote } from '../../interfaces/new/quickQuote';
import packageFeaturesJSON from 'src/assets/json/package-features.json';

@Component({
  selector: 'app-quick-quote',
  templateUrl: './quick-quote.component.html',
  styleUrls: ['./quick-quote.component.scss']
})
export class QuickQuoteComponent {
  @Input() grid: boolean = false;
  @Input() displayPackageIcons: boolean = false;
  @Input() displayPackageFeatures: boolean = false;
  features = packageFeaturesJSON;

  public readonly quickQuote$: Observable<IQuickquote> = this.store.select(selectQuickQuote);
  constructor(
    private readonly store: Store
  ) { }
}
