
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { RegistersteptwoActions, registerStepTwoAdd, registerStepTwoLoad, registerStepTwoRemove } from '../actions/registerStepTwo.actions';
import { RegistersteptwoService } from '../../services/new/registerStepTwo.service';

@Injectable()
export class RegistersteptwoEffects {
  load$ = createEffect(() => this.actions$.pipe(
    ofType(registerStepTwoLoad),
    mergeMap(() => this.registerStepTwoService.getRegistersteptwo().pipe(
      map(registerStepTwo => ({ type: RegistersteptwoActions.LoadSuccess, payload: registerStepTwo })),
      catchError(() => of({ type: RegistersteptwoActions.LoadError }))
    ))
  ));
  add$ = createEffect(() => this.actions$.pipe(
    ofType(registerStepTwoAdd),
    mergeMap((action) => this.registerStepTwoService.addRegistersteptwo(action.registerStepTwo).pipe(
      map(registerStepTwo => ({ type: RegistersteptwoActions.AddSuccess, payload: registerStepTwo })),
      catchError(() => of({ type: RegistersteptwoActions.AddError }))
    ))
  ));
  remove$ = createEffect(() => this.actions$.pipe(
    ofType(registerStepTwoRemove),
    mergeMap((action) => this.registerStepTwoService.removeRegistersteptwo().pipe(
      map(registerStepTwo => ({ type: RegistersteptwoActions.RemoveSuccess, payload: registerStepTwo })),
      catchError(() => of({ type: RegistersteptwoActions.RemoveError }))
    ))
  ));

  constructor(
    private actions$: Actions,
    private registerStepTwoService: RegistersteptwoService
  ) { }
}
