import { Component, Input, OnChanges } from '@angular/core';
import moduleListJSON from 'src/assets/json/product-bundle-modules.json';

import { ProductBundleModuleDetails, ProductBundleDetails } from '../../../index';

@Component({
  selector: 'app-product-bundle-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ProductBundleModulesComponent implements OnChanges {
  @Input() forBundle: ProductBundleDetails;

  moduleList: ProductBundleModuleDetails[];
  filteredModuleList: ProductBundleModuleDetails[];

  constructor() {
    this.moduleList = moduleListJSON as ProductBundleModuleDetails[];
    this.filteredModuleList = [];
  }

  ngOnChanges() {
    if ( this.forBundle ) {
      this.filteredModuleList = this.moduleList.filter(m => {
        return -1 !== m.bundles.indexOf(this.forBundle.name);
      });
    } else {
      this.filteredModuleList = [];
    }
  }

  getAltText(module: ProductBundleModuleDetails): string {
    return `${module.name}\n${module.description}`;
  }

  trackByFn(index: number, item: ProductBundleModuleDetails) {
    return index;
  }

}
