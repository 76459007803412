export interface CompaniesHouseCompanyDetails {
  id: string,
  systemId: string,
  systemName: string,
  companyNumber: string,
  addressFinder: string,
  route: string,
  street_number: string,
  companyName: string,
  addressLine1: string,
  addressLine2: string,
  addressLine3: string,
  area: string,
  postcode:string,
  postal_code: string,
  abNumber?:string,
  acNumber?:string,
  isExactMatchOnSystemId: boolean,
  isAusGstRegistered: boolean,
  longitude: string | null,
  latitude: string | null,
  town?:string|null,
  postal_town: string,
}
