<div class="inner_content" *ngIf="!isAus">
  <div class="card_text">
    <h4 class="h6"><strong>Risk Assessments and Method Statements</strong></h4>
    <p>
      Sign up to CHAS RAMS to easily create and manage <strong>risk assessments</strong> and
      <strong>method statements</strong>.</p>
      <p>Our content library covers all major construction activities which you can
      easily customise to your job.</p>
      <p><strong>CHAS members get one month free</strong>.</p>
  </div>
  <div class="card_buttons">
    <button class="btn btn-primary on_card" (click)="ramsModal.show()">
      Sign up
    </button>
    <a class="btn btn-secondary on_card" href="https://www.chas.co.uk/chas-rams-powered-by-handshq"
      target="_blank">Learn more about CHAS RAMS</a>
  </div>
</div>

<div class="inner_content" *ngIf="isAus">
  <div class="card_text">
    <h4 class="h6"><strong>Safe Working Method Statement</strong></h4>
    <p>CHAS will soon be launching SWMS, Safe Working Method Statement, to help you create and manage <strong>risk assessments</strong> and <strong>method statements</strong>.</p>
      <p>A SWMS is a document that sets out the high risk construction work activities to be carried out at a workplace, the hazards arising from these activities and the measures to be put in place to control the risks.</p>
      
      <p>CHAS members will get one month free</p>
  </div>
  <div class="card_buttons">
    <button class="btn btn-primary on_card">
      Coming soon
    </button>
  </div>
</div>



<div bsModal #ramsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-sizes-name1" class="modal-title pull-left">CHAS RAMS</h4>
        <button type="button" class="close pull-right" #closebutton (click)="ramsModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="alert--rams">CHAS members receive one month free*</div>
        <div class="row">
          <div *ngFor="let product of eramsProducts" class="col-md-4">
            <div class="rams-highlight" *ngIf="product.highlight">
              <div>POPULAR</div>
            </div>
            <div class="rams-box">
              <div class="rams-name">{{ product.name }}</div>
              <div class="rams-price">
                <span style="font-size: smaller">&pound;</span>{{ product.price }}
              </div>
              <div class="rams-term">{{ product.term }}</div>
              <div class="rams-detail">
                &pound;{{ product.withvat }} {{ product.term }} including VAT
              </div>
              <div class="rams-detail">
                <a [href]="product.link" class="btn btn-primary cta" target="_new" rel="noopener">
                  Sign up
                </a>
              </div>
              <div class="rams-detail">
                <p *ngFor="let benefit of product.benefits">
                  <i class="fa fa-check"></i> {{ benefit }}<br />
                </p>
                <p>{{ product.note }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="bottomline">*T&Cs apply. CHAS members can upgrade, downgrade or cancel at any stage during the month
          at no cost. Payments for CHAS RAMS are processed by Stripe on behalf of HandsHQ</div>
      </div>
      <div class="powered-by">
        <div class="row">
          <div class="col-md-6">
            <a class="btn btn-light btn-back" (click)="ramsModal.hide()">
              <i class="fa fa-angle-double-left"></i> Go back
            </a>
          </div>
          <div class="col-md-6">
            <img src="assets/img/logo/handshq_powered.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>