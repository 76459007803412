import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { CheckNewAuthenticationResponse } from '../../index';
import introJs from 'intro.js';
import { GtmUserCategory, GtmUserStatus } from 'src/app/core/services/gtm/gtm.enum';
import { GtmService } from '../../../core/services/gtm/gtm.service';
import { Store } from '@ngrx/store';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { MsalService } from '@azure/msal-angular';
@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit, OnDestroy {
  showLogout: boolean = false;
  public accountDetails: any;
  public authDetails: CheckNewAuthenticationResponse;
  private readonly data$ = this.store.select(selectCurrentUserState);
  private $destroyed = new Subject<void>();

  constructor(
    private authService: MsalService,
    private router: Router,
    private gtmService: GtmService,
    private readonly store: Store
  ) {}

  async ngOnInit() {
    this.data$.subscribe((data) => {
      if (data.loaded) {
        this.authDetails = data.currentAccount;
        this.accountDetails = data.allAccounts;

        this.router.events
          .pipe(filter((event: Event | RouterEvent): event is RouterEvent => event instanceof RouterEvent))
          .subscribe((event: RouterEvent) => {
            if (event instanceof NavigationEnd) {
              this.gtmService.pageView({
                type: 'content',
                category: GtmUserCategory.Contractor,
                name: event.url.replace('/', '').replace('-', ' '),
                uid: this.authDetails.accountId,
                status: GtmUserStatus.LoggedIn,
              });
            }
          });
      }
    });
  }

  ngOnDestroy() {
    this.$destroyed.next();
  }

  /**
   * Returns the username to display in the menu.
   */
  getUsername(): string {
    return this.authDetails ? this.authDetails.fullName : null;
    // TODO the mockup only displays a name, should we include the membership ID?
  }

  getAccounts(): any {
    return this.accountDetails;
  }

  /**
   * Logs the current user out, navigating to the login page.
   * TODO there will probably be some interception required (e.g. "you will lose your changes").
   */
  logout(): void {
    this.authService.loginRedirect();
  }

  startTour(): void {
    this.router
      .navigateByUrl('/dashboard')
      .then(() => {
        setTimeout(() => {
          introJs().start();
        }, 2000);
      })
      .catch((err) => console.error(err));
  }
}
