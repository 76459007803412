import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-fir-card',
  templateUrl: './fir-card.component.html',
  styleUrls: ['./fir-card.component.scss']
})
export class FirCardComponent {
  @Input() productNumber: string;
  @Input() name: string;
  @Input() image?: string;
  @Input() title?: string;
  @Input() headerImage?: string;
  @Input() headerTitle?: string;
  @Input() actionText?: string = 'Select';
  @Input() isSelected?: boolean = false;
  @Input() buttonBlock?: boolean = true;
  @Output() selected = new EventEmitter<string>();

  selectCard(): void {
    this.selected.emit(this.name);
  }
}
