import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { first, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountsService } from '../../../core/services/accounts.service';
import { RepositoryService } from '../../../core/services/repository.service';
import { WorkRegionsService } from '../../../core/services/work-regions.service';
import { select, Store } from '@ngrx/store';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { AccountResponse, AccountWorkRegion } from 'src/app/shared/index';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';
import { Environment, IEnvironment } from '../../classes/environment';
import { Territory } from '../../constants/territory.enum';

@Component({
  selector: 'app-work-areas-modal',
  templateUrl: './work-areas-modal.component.html',
  styleUrls: ['./work-areas-modal.component.scss'],
})
export class WorkAreasModalComponent implements OnInit {
  accountId: string;
  expandedRegions: Array<string> = [];
  error: string;
  saving: boolean;
  isLoading: boolean;
  saveConfirmationBsModalRef: BsModalRef = null;
  protected distanceUnit: string;

  workRegions: Array<any> = [];
  workAreasForm = new UntypedFormGroup({
    distance: new UntypedFormControl(''),
    areas: new UntypedFormGroup({}),
  });

  private readonly data$ = this.store.select(selectCurrentUserState);
  private currentUserState: CurrentUserState;
  private fgAreas: UntypedFormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private accountService: AccountsService,
    private Repository: RepositoryService,
    private WorkRegionService: WorkRegionsService,
    private readonly store: Store
  ) {
    const env = new Environment();
    const environment: IEnvironment = env.getConfigSync();
    this.distanceUnit = environment.territory === Territory.AUSTRALIA ? 'km' : 'miles';
  }

  ngOnInit() {
    this.fgAreas = this.workAreasForm.get('areas') as UntypedFormGroup;
    this.isLoading = true;

    this.data$.subscribe((data) => {
      if (data.loaded) {
        this.currentUserState = data;
        this.accountId = data.currentAccount.accountId;

        this.WorkRegionService.getAllWorkRegions().subscribe();
        this.WorkRegionService.getAllCachedWorkRegions().subscribe((allRegions) => {
          if (allRegions.length == 0) return;
          this.isLoading = false;
          this.workRegions = allRegions;
          this.populateWorkAreas();
          this.populateForm();
        });
      }
    });
  }

  /**
   * Add respective form controls for each area to the form.
   */
  populateWorkAreas(): void {
    this.workRegions.forEach((region) => {
      region.areas.forEach((area) => {
        this.fgAreas.addControl(area.code, new UntypedFormControl());
      });
    });
  }

  /**
   * Populate the form with account settings.
   */
  populateForm(): void {
    this.workAreasForm.get('distance').setValue(this.currentUserState.currentAccount.distance);

    // Preselect working areas.
    this.currentUserState.workRegions.forEach((row) => {
      const region: any = Object.assign({}, row);
      this.fgAreas.get(region.workRegionCode).setValue(true);
    });
  }

  /**
   * Closes the dialogue/modal window.
   */
  close(): void {
    this.bsModalRef.hide();
  }

  /**
   * Reveals areas for the specified region.
   */
  expandRegion(region: { code: string; areas: { code: string }[] }): void {
    if (this.isRegionExpanded(region)) {
      this.expandedRegions.splice(this.expandedRegions.indexOf(region.code), 1);
    } else {
      this.expandedRegions.push(region.code);
    }
  }

  getSelectedInRegion(region: { code: string }): number {
    return this.workRegions
      .find((row) => region.code === row.code)
      .areas.map((row) => row.code)
      .filter((code) => true === this.fgAreas.get(code).value).length;
  }

  isRegionExpanded(region: { code: string }): boolean {
    return this.expandedRegions.find((x) => x == region.code) != null;
  }

  /**
   *
   */
  canSave(): boolean {
    if (this.isLoading) return false;

    const miles = this.distanceInMiles;
    const areas = this.selectedAreas;
    if (
      miles === 0 ||
      (this.currentUserState.currentAccount.distance === miles && !this.compareValues(this.currentUserState.workRegions, areas))
    ) {
      return false;
    }

    return miles >= 0 && areas.length > 0;
  }

  compareValues(workAreas: any[], selection: string[]): boolean {
    let match: boolean = false;
    selection.forEach((code) => {
      const check = workAreas.find((area) => area.workRegionCode === code);
      if (!check) {
        match = true;
      }
    });

    workAreas.forEach((area) => {
      const check = selection.find((code) => area.workRegionCode === code);
      if (!check) {
        match = true;
      }
    });

    return match;
  }

  /**
   * Returns the entered distance in miles, as a number.
   */
  get distanceInMiles(): number {
    const distance = parseInt(this.workAreasForm.get('distance').value, 10);

    return isNaN(distance) ? 0 : distance;
  }

  /**
   * Returns a list of selected area codes.
   */
  get selectedAreas(): string[] {
    return Object.keys(this.fgAreas.value).filter((key) => true === this.fgAreas.get(key).value);
  }

  IsLocationAdded(): boolean {
    const workAreas: any[] = this.currentUserState.workRegions;
    const selection = this.selectedAreas;
    let match: boolean = false;

    selection.forEach((code) => {
      const check = workAreas.find((area) => area.workRegionCode === code);
      if (!check) {
        match = true;
      }
    });

    return match;
  }

  /**
   * Updates the account with the entered information.
   */
  updateWorkAreas(): void {
    if (this.saving) {
      return;
    }

    const updates: Observable<any>[] = [];
    const distance = this.distanceInMiles;

    // Update the account details with the distance in miles (if set).
    if (distance != this.currentUserState.currentAccount.distance) {
      updates.push(this.accountService.updateWorkDistance(this.accountId, this.distanceInMiles));
    }

    // Update the work areas.
    var selected = this.selectedAreas;
    if (selected.length) {
      var locations: any[] = [];
      selected.forEach((row) => {
        var loc = this.getWorkLocation(row);
        if (loc) locations.push(loc);
      });

      if (locations.length > 0) updates.push(this.WorkRegionService.saveWorkLocations(this.accountId, locations));
    }

    if (0 === updates.length) {
      return;
    }

    this.saving = true;
    this.workAreasForm.disable();
    forkJoin(updates)
      .pipe(
        first(),
        finalize(() => {
          this.workAreasForm.enable();
          this.saving = false;
        })
      )
      .subscribe({
        next: () => {
          this.accountService.refresh();
          this.close();
        },
        error: (response: HttpErrorResponse) => (this.error = response.error.Message),
      });
  }

  getWorkLocation(code: string): any {
    var loc: any = null;

    this.workRegions.forEach((region) => {
      region.areas.find((area) => {
        if (area.code === code) loc = { accountId: this.accountId, countryCode: region.code, stateCode: area.code };
        return;
      });
    });

    return loc;
  }

  /*
   * Set all sub works areas if checkbox is checked
   */
  setSubWorkareas(e, region) {
    region.areas.forEach((row) => {
      if (e.target.checked) this.fgAreas.get(row.code).setValue(true);
      else this.fgAreas.get(row.code).setValue(false);
    });

    this.expandRegion(region);
  }

  openSaveConfirmationModal(template: TemplateRef<any>) {
    if (this.IsLocationAdded()) {
      this.saveConfirmationBsModalRef = this.modalService.show(template, { class: 'modal-sm' });
      return;
    }

    this.updateWorkAreas();
  }

  confirm(): void {
    this.updateWorkAreas();

    this.saveConfirmationBsModalRef.hide();
  }

  decline(): void {
    this.saveConfirmationBsModalRef.hide();
  }
}
