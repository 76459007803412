import { WorkRegion } from 'src/app/shared/components/work-region-selecter/work-region-selecter.component';
import { CompanyDetails, UnspscCode } from '../../shared';

export class CompanyDetailsForm {

  constructor(response: CompanyDetails) {
    this.membership_id = response.id.membershipId;
    this.summary = response.summary;
    this.turnover = response.turnover;
    this.email_address = response.primaryContact?.email;
    this.company_house_registration_number = response.id.companyHouseNo;
    this.organisation_type = response.organisationType;
    this.trading_name = response.tradingName;
    this.workCategories = response.workCategories;
    this.distance = response.distance
    this.workRegions = response.workRegions;
    this.primary_trade = !response.primaryWorkCategory ? '' : response.primaryWorkCategory;
    this.vat_reg_number = response.vatRegistrationNumber;
    this.hear_chas = response.hearChas;
    this.hear_chas_text_option = response.hearChasText;
    this.mandated_client = response.mandatedClient;
    this.print_pack = response.printPack;

    this.number_of_direct_employees = response.numberOfDirectEmployees;
    this.number_of_labour_only_subcontractors = response.numberOfLabourOnlySubcontractors;
    this.number_of_effective_employees = response.numberOfEffectiveEmployees;
    this.number_of_bona_fide_subcontractors = response.numberOfBonaFideSubcontractors;

    this.primary_contact_id = response.primaryContact?.id;
    this.telephone = response.primaryContact?.telephone;
    this.website_url = response.website;

    this.ultimate_parent_account_id = response.ultimateParentAccount?.uid;
    this.ultimate_parent_account = response.ultimateParentAccount?.name;
    this.ultimate_parent_account_number = response.ultimateParentAccount?.companyHouseNo;

    this.parent_account_id = response.parentAccount?.uid;
    this.parent_account = response.parentAccount?.name;
    this.parent_account_number = response.parentAccount?.companyHouseNo;

    this.register_address_id= response.registerAddress?.id;
    this.register_address_city = response.registerAddress?.city;
    this.register_address_postcode = response.registerAddress?.postCode;
    this.register_address_street1 = response.registerAddress?.street1;
    this.register_address_street2 = response.registerAddress?.street2;
    this.register_address_country_code = response.registerAddress?.countryCode;
    this.register_primary_contact_name = response.primaryContact?.lastName;

    this.correspondence_address_id = response.correspondenceAddress?.id;
    this.correspondence_address_city = response.correspondenceAddress?.city;
    this.correspondence_address_postcode = response.correspondenceAddress?.postCode;
    this.correspondence_address_street1 = response.correspondenceAddress?.street1;
    this.correspondence_address_street2 = response.correspondenceAddress?.street2;
    this.correspondence_address_country_code = response.correspondenceAddress?.countryCode;

  }
  register_address_id: string;
  register_address_city: string;
  register_address_street1: string;
  register_address_street2: string;
  register_address_postcode: string;
  register_address_country_code: string;
  register_primary_contact_name: string;

  correspondence_address_id: string;
  correspondence_address_city: string;
  correspondence_address_street1: string;
  correspondence_address_street2: string;
  correspondence_address_postcode: string;
  correspondence_address_country_code: string;

  summary: string;
  membership_id: string;
  turnover: number;
  ultimate_parent_account_id: string;
  ultimate_parent_account_number: string;
  ultimate_parent_account: string;
  email_address: string;
  company_house_registration_number: string;
  organisation_type: string;
  trading_name: string;
  workCategories: UnspscCode[];
  primary_trade: string;
  vat_reg_number: string;
  hear_chas: string;
  hear_chas_text_option: string;
  mandated_client: string;
  print_pack: string;

  number_of_direct_employees: number;
  number_of_labour_only_subcontractors: number;
  number_of_effective_employees: number;
  number_of_bona_fide_subcontractors: number;

  parent_account_id: string;
  parent_account_number: string;
  parent_account: string;
  position: string;
  telephone: string;
  mobile: string;
  website_url: string;
  primary_contact_id: string;
  thirdPartyCasProvider: string;
  thirdPartyCasMembershipNumber: string;

  distance: number;
  workRegions:WorkRegion[];
}
