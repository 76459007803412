import { AccountsService, PaymentService } from '../../../core';
import { SnackBarClass } from '../../../shared/classes/snackbar.class';
import { Component, Input } from '@angular/core';
import { CheckoutSessionResponse } from '../../../shared/interfaces/new/register-data';
import { firstValueFrom, Observable } from 'rxjs';
import { IAccountContactQuoteResponse } from '../../../shared';
import { Environment, IEnvironment } from '../../../shared/classes/environment';
import { getCompanySummary } from '../../../core/ngrx/selectors/company-details.selectors';
import { CompanySummary } from '../../../shared/interfaces/company-summary';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-membership-upgrade-summary',
  templateUrl: './membership-upgrade-summary.component.html',
  styleUrls: ['./membership-upgrade-summary.component.scss', '../membership-upgrade/membership-upgrade.component.scss'],
})
export class MembershipUpgradeSummaryComponent {
  @Input() checkoutSession: CheckoutSessionResponse;
  @Input() opportunity: IAccountContactQuoteResponse;
  public account$: Observable<any> = this.store.select(getCompanySummary);
  public processingPayment = false;
  public termsAndConditions = false;
  public discountCode: string;
  public isDiscountCodeReadonly = false;
  public referenceCodeValidating: boolean;
  public referenceCodeAttempted: boolean;
  public referenceCodeValid: boolean;
  private config: IEnvironment;
  private companySummary: CompanySummary;

  constructor(
    private readonly store: Store,
    private readonly http: HttpClient,
    private readonly paymentService: PaymentService,
    private readonly accountService: AccountsService,
    private readonly snackbarClass: SnackBarClass
  ) {}

  async ngOnInit(): Promise<void> {
    const environment: Environment = new Environment();
    this.config = await environment.getConfig();
    this.companySummary = await firstValueFrom(this.account$);
    this.discountCode = await firstValueFrom(this.accountService.getExistingDiscountCode$(this.companySummary.companyAccountID));
    this.isDiscountCodeReadonly = this.discountCode !== null && this.discountCode !== '' && this.discountCode !== undefined;
  }

  public async payAndRedirect(): Promise<void> {
    const quote = this.opportunity.quotes[0];

    let opportunityObject: any = {
      name: quote.result.productName,
      accountId: this.opportunity.accountId,
      bundleId: quote.result.productId,
      price: quote.result.totalAmount,
      assessmentType: quote.assessmentType,
      variant: quote.applicationType,
      employeenum: quote.request.numberOfEmployees,
      NumberofDirectEmployees: quote.request.directEmployees,
      LabourOnlySubContractors: quote.request.labourOnlySubContractors,
      BonafideSubContractors: quote.request.bonafideSubContractors,
      fastTrack: quote.request.fastTrack,
      fastTrackFee: quote.result.fastTrackAmount,
      callbackSuccessUrl: quote.request.successUrl,
      callbackCancelUrl: quote.request.cancelUrl,
      IsdataSharingAllowed: quote.request.dataSharingAllowed,
    };
    try {
      await this.paymentService.upgradeMembershipStripe(this.checkoutSession, opportunityObject);
    } catch (e) {
      this.snackbarClass.displaySnackbar(e, 'x');
      console.error(e);
    } finally {
      this.processingPayment = false;
    }
  }

  public async validateReferenceCode(): Promise<void> {
    if (!this.discountCode || this.discountCode === '') return;

    this.referenceCodeAttempted = false;
    this.referenceCodeValidating = true;
    await this.constructCheckoutSession();
    this.referenceCodeValidating = false;
    this.referenceCodeAttempted = true;
    const selectedQuote = this.opportunity.quotes[0];
    this.referenceCodeValid = selectedQuote.result.discountCodeValid;
    //this.selectedProduct = selectedQuote;
  }

  public getReferenceCodeStatus(): string {
    return this.referenceCodeValid ? 'valid' : 'invalid';
  }

  private constructQuote(): Observable<IAccountContactQuoteResponse> {
    const quoteObject = {
      accountId: this.companySummary.companyAccountID,
      opportunityId: '',
      quote: {
        BundleNames: ['Elite'],
        accountId: this.companySummary.companyAccountID,
        applicationType: '885570000',
        cancelUrl: `${this.config.urls.environmentUrl}/membership/upgrade?index=0`,
        customerType: '870520001',
        dataSharingAllowed: false,
        discountCode: this.discountCode,
        fastTrack: false,
        firFee: false,
        joiningFee: false,
        opportunityId: null,
        priceLevelId: '',
        sectorType: 885570006,
        selectedBundle: 'Elite',
        successUrl: `${this.config.urls.environmentUrl}/membership/upgrade?index=2`,
      },
    };
    return this.http.post<IAccountContactQuoteResponse>(
      `${this.config.apiEndpoint.accounts}/registeraccountupgradeopportunity`,
      quoteObject
    );
  }

  private async constructCheckoutSession(): Promise<void> {
    try {
      this.opportunity = await firstValueFrom(this.constructQuote());
      if (this.opportunity) {
        const request = {
          accountId: this.companySummary.companyAccountID,
          opportunityId: this.opportunity.opportunityId,
          recreate: false,
          manualPayment: false,
          isDatashareAllowed: false,
          successUrl: `${this.config.urls.environmentUrl}/membership/upgrade?index=2`,
          cancelUrl: `${this.config.urls.environmentUrl}/membership/upgrade?index=0`,
        };
        this.checkoutSession = await this.paymentService.createCheckoutSession(request);
      }
    } catch (error) {
      console.log(`An error occurred -> constructCheckoutSession(): ${error.message}`);
    }
  }
}
