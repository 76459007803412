import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseCountdownComponent } from './response-countdown.component';

@NgModule({
  declarations: [ResponseCountdownComponent],
  exports: [ResponseCountdownComponent],
  imports: [
    CommonModule
  ]
})
export class ResponseCountdownModule { }
