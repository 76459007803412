import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsCardComponent } from './documents-card.component';
import { LoadingSpinnerModule } from '../../../shared/components/loading-spinner/loading-spinner.module';



@NgModule({
  declarations: [DocumentsCardComponent],
  exports: [DocumentsCardComponent],
  imports: [
    CommonModule,
    LoadingSpinnerModule
  ]
})
export class DocumentsCardModule { }
