export interface CreateAccountParameters {
  BundleId: string;
  accountId?: string;
  firFee?: boolean;
  ProductNumber?: string;
  CompanyName: string;
  RegAddressline1: string;
  RegAddressline2: string;
  RegCity: string;
  RegPostcode: string;
  RegCountry: string;
  RegCountryCode?: string;
  EmailAddress: string;
  CompanyHouseNo: string;
  NoOfEmployees: number;
  NumberofDirectEmployees: number;
  LabourOnlySubContractors: number;
  BonafideSubContractors: number;
  Telephone1: string;
  Mobile1: string;
  CustomerType: string;
  ContactFirstName: string;
  ContactLastName: string;
  AssessmentType: string;
  Variant: string;
  Password?: string;
  TradingAs: string;
  ClientReferenceCode: string;
  ignoreValidation?: string;
  SuccessUrl: string;
  CancelUrl: string;
  CorAddressline1?: string;
  CorAddressline2?: string;
  CorCity?: string;
  CorPostcode?: string;
  CorCountry?: string;
  CorCountryCode?: string;
  CreditSafeNumber?: string;
  SkipOpportunity?: boolean;
}
