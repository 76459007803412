import { createReducer, on } from '@ngrx/store';
import { getStorage } from 'src/app/shared/functions/getBasket.functions';
import { BasketQuoteAddSuccess, BasketQuoteLoadSuccess, BasketQuoteRemoveSuccess } from '../actions/basketQuote.actions';

export const initialState = getStorage('newBasketQuote');

const _basketQuoteReducer = createReducer(
  initialState,
  on(BasketQuoteAddSuccess, (state, action) => action.payload),
  on(BasketQuoteLoadSuccess, (state, action) => action.payload),
  on(BasketQuoteRemoveSuccess, (state, action) => action.payload),
);

export function basketQuoteReducer(state, action) {
  return _basketQuoteReducer(state, action);
}
