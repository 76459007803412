import { Component, EventEmitter, Output, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { IntroductionVideo } from '../../../shared/constants/introduction-video.const';
import Player from '@vimeo/player';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';
@Component({
  selector: 'app-intro-video-modal',
  templateUrl: './intro-video-modal.component.html',
  styleUrls: ['./intro-video-modal.component.scss']
})
export class IntroVideoModalComponent implements AfterViewInit {
  @ViewChild('player') player: ElementRef;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  videoTitle: string;
  pushTagEvents: Array<string> = ['play', 'pause'];
  constructor(
    private gtmService: GtmService
  ) {}

  async ngAfterViewInit() {
    this.setupEvents();
  }

  private async setupEvents() {
    const player = new Player(this.player.nativeElement);

    this.videoTitle = await player.getVideoTitle();

    for (const event of this.pushTagEvents) {
      player.on(event, () => this.pushTag(event));
    }
  }

  private pushTag(eventName: string) {
    this.gtmService.videoClick({
      action: eventName,
      title: this.videoTitle
    });
  }

  get videoUrl(): string {
    return IntroductionVideo.VideoUrl;
  }

  onClose(): void {
    this.close.emit();
  }

}
