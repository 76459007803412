import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();
import { tap } from 'rxjs/internal/operators/tap';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';


@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(private http: HttpClient) { }

  getPublishKey(): Observable<any> {
    return this.http.get(`${environment.apiEndpoint.payments}/public/payments/checkout-key`);
  }

  getCheckoutId(accountId: string): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = { accountId };
    return this.http.post(`${environment.apiEndpoint.payments}/public/payments/checkout`, body, options)
      .pipe(catchError(this.handleError<any>('getCheckoutId')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    }
  }
}
