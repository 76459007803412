import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardTier3CardComponent } from './tier3-card.component';
import { BootstrapComponentsModule } from '../../../../core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoadingSpinnerModule } from '../../../../shared/components/loading-spinner/loading-spinner.module';

@NgModule({
  declarations: [
    DashboardTier3CardComponent
  ],
  exports: [DashboardTier3CardComponent],
  imports: [
    CommonModule,
    BootstrapComponentsModule,
    ReactiveFormsModule,
    RouterModule,
    LoadingSpinnerModule
  ]
})
export class DashboardTier3CardModule { }
