import { Injectable } from '@angular/core';
import { AccountsService } from './accounts.service';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';
import { monitorService } from './monitor.service';
import { MonitorSeverity } from '../../shared/constants/monitor-severity.const';
import { CheckAuthenticationResponse } from '../../shared/interfaces/check-authentication-response';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { StripeService } from './stripe.service';
import { Store } from '@ngrx/store';
import { BasketItem } from 'src/app/shared';
import { BasketItemsActions } from '../ngrx/actions/basketItems.actions';
import { selectBasketItems } from '../ngrx/selectors/basket.selectors';
import { lastValueFrom } from 'rxjs';
import { CheckoutSessionResponse, ICheckoutSessionRequest, ICompletePayment } from 'src/app/shared/interfaces/new/register-data';
declare var Stripe: any;

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  error: string;
  authAccount: CheckAuthenticationResponse;
  basketItems$: Observable<BasketItem[]> = this.store.select(selectBasketItems);
  basketItems: BasketItem[];
  env = new Environment();
  environment: IEnvironment;

  constructor(
    private Accounts: AccountsService,
    private http: HttpClient,
    private route: Router,
    private Monitor: monitorService,
    private stripe: StripeService,
    private store: Store
  ) {
    this.environment = this.env.getConfigSync();
  }

  isPaymentComplete(accountId: string): Observable<boolean> {
    const url = `${this.environment.apiEndpoint.payments}/accounts/${accountId}/isPaymentComplete`;
    return this.http.get(url).pipe(
      map((response: boolean) => {
        return response;
      })
    );
  }

  private async completeFreePaymentAsync(opportunityObject, useLegacyApi?: boolean) {
    useLegacyApi = useLegacyApi ?? false;
    const emptyGuid = '00000000-0000-0000-0000-000000000000';
    opportunityObject.bundleId = opportunityObject.bundleId ?? emptyGuid;
    this.createBasketItem(opportunityObject, 'FREE');

    if (useLegacyApi) {
      let settings: { [key: string]: any } = {
        contactId: opportunityObject.contactId,
        chas_paymentamount: 0,
        chas_paymentdate: new Date(),
        chas_paymentsuccessful: true,
        chas_paymentresponsecode: '00000',
        chas_paymentresponsedescription: `${opportunityObject.bundleId} FREE`,
        chas_paymentreceiptnumber: `Account: ${opportunityObject.accountId} `,
      };
      return await this.Accounts.callCompletePaymentAsync(opportunityObject.accountId, opportunityObject.bundleId, settings);
    } else {
      opportunityObject.bundleId = emptyGuid;
      const url = `${this.environment.apiEndpoint.payments}/public/accounts/${opportunityObject.accountId}/products/${opportunityObject.bundleId}/free-payment`;
      return lastValueFrom(this.http.post(url, null));
    }
  }

  async activateAsync(amount: number | string, opportunityObject, useLegacyApi?: boolean) {
    if (!opportunityObject) return;

    useLegacyApi = useLegacyApi ?? false;

    try {
      //Add user accountId to storage for use in stripe landing page. This is to retain state of the account on return from stripe checkout
      sessionStorage.setItem('guidac', opportunityObject.accountId);
      opportunityObject.price = amount === 'FREE' ? 0 : amount;

      if (useLegacyApi) {
        await this.Accounts.createOpportunityLegacyAsync(opportunityObject);
      }

      if (opportunityObject.price === 0) {
        await this.completeFreePaymentAsync(opportunityObject, useLegacyApi).then(
          () => (window.location.href = opportunityObject.callbackSuccessUrl)
        );
      } else {
        sessionStorage.setItem('redirectUrl', this.route.url);
        this.stripeCheckout(opportunityObject);
      }
    } catch (error) {
      this.Monitor.exception(error.message, MonitorSeverity.CRITICAL);
      console.error(error.message);
      this.error = error.message;
    }
  }

  private stripeCheckout(opportunityObject) {
    let loaded = false;

    if (opportunityObject.accountId) {
      combineLatest([this.stripe.getPublishKey(), this.stripe.getCheckoutId(opportunityObject.accountId), this.basketItems$]).subscribe({
        next: (data) => {
          const sessionId = data[1].sessionId;
          const basketItem = data[2][0];

          if (!loaded) {
            loaded = true;
            this.createBasketItem(opportunityObject, sessionId, basketItem);

            const stripeCheckout = Stripe(data[0].publishKey);
            stripeCheckout
              .redirectToCheckout({
                sessionId: sessionId,
              })
              .then(function (result) {
                //post error to application insights
                this.Monitor.exception(result.error.message, MonitorSeverity.CRITICAL);
              });
          }
        },
        error: (response: HttpErrorResponse) => {
          this.error = response.error.Message;
        },
      });
    }
  }

  public async createCheckoutSession(request: ICheckoutSessionRequest): Promise<CheckoutSessionResponse> {
    return await this.http.post<CheckoutSessionResponse>(`${this.environment.apiEndpoint.payments}/checkout`, request).toPromise();
  }

  public async ProceedToStripeAsync(
    checkoutSession: CheckoutSessionResponse,
    amount: number | string,
    opportunityObject,
    useLegacyApi?: boolean
  ) {
    if (!opportunityObject) return;

    useLegacyApi = useLegacyApi ?? false;

    try {
      //Add user accountId to storage for use in stripe landing page. This is to retain state of the account on return from stripe checkout
      sessionStorage.setItem('guidac', opportunityObject.accountId);
      opportunityObject.price = amount === 'FREE' ? 0 : amount;

      if (useLegacyApi) {
        await this.Accounts.createOpportunityLegacyAsync(opportunityObject);
      }

      if (opportunityObject.price === 0) {
        await this.completeFreePaymentAsync(opportunityObject, useLegacyApi).then(
          () => (window.location.href = opportunityObject.callbackSuccessUrl)
        );
      } else {
        sessionStorage.setItem('redirectUrl', this.route.url);
        this.executeStripeCheckout(checkoutSession, opportunityObject);
      }
    } catch (error) {
      this.Monitor.exception(error.message, MonitorSeverity.CRITICAL);
      console.error(error.message);
      this.error = error.message;
    }
  }

  public async upgradeMembershipStripe(checkoutSession: CheckoutSessionResponse, opportunityObject: any): Promise<void> {
    const sessionId = checkoutSession.sessionId;

    if (opportunityObject.price === 0) {
      await this.completeFreePaymentAsync(opportunityObject, false);
      window.location.href = opportunityObject.callbackSuccessUrl;
    } else {
      const stripeCheckout = Stripe(checkoutSession.publishKey);
      await stripeCheckout.redirectToCheckout({
        sessionId: sessionId,
      });
    }
  }

  private executeStripeCheckout(checkoutSession: CheckoutSessionResponse, opportunityObject) {
    let loaded = false;
    let itemName = '';

    if (opportunityObject.accountId) {
      combineLatest([this.basketItems$]).subscribe({
        next: (data) => {
          const sessionId = checkoutSession.sessionId;
          const basketItem = data[0][0];

          if (itemName !== basketItem.name) {
            itemName = basketItem.name;
            loaded = true;
            this.createBasketItem(opportunityObject, sessionId, basketItem);

            const stripeCheckout = Stripe(checkoutSession.publishKey);
            stripeCheckout
              .redirectToCheckout({
                sessionId: sessionId,
              })
              .then(function (result) {
                //post error to application insights
                this.Monitor.exception(result.error.message, MonitorSeverity.CRITICAL);
              });
          }
        },
        error: (response: HttpErrorResponse) => {
          this.error = response.error.Message;
        },
      });
    }
  }

  private createBasketItem(opportunityObject, transactionId: string, basketItem?: BasketItem) {
    this.store.dispatch({
      type: BasketItemsActions.Update,
      change: {
        item: basketItem,
        field: {
          name: opportunityObject.name,
          transaction_id: transactionId,
          productID: opportunityObject.bundleId,
          units: opportunityObject.employeenum || opportunityObject.employeeNum,
          price: opportunityObject.price,
        },
      },
    });
  }
}
