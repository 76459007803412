<div class="row">
  <div class="col-3">
    <h1 class="h2 mb-5">My Documents</h1>
  </div>
  <div class="col-6"></div>
  <div class="col-3 text-right">
    <span class="mr-2">All Documents</span>
    <button class="btn">
      <i class="fa fa-th-list"></i>
    </button>
  </div>
</div>

<div class="row">
  <div class="col">
    <form class="form-group"
          (submit)="search()"
          [formGroup]="fgSearch">
      <div class="input-group">
        <input type="text" class="form-control" id="documentSearch"
               #documentSearch
               formControlName="term" placeholder="search for file" />
        <div class="input-group-append">
          <button class="btn" type="submit" id="btnSearch">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
    </form>
  </div>

  <div class="col">
    <select class="form-control" id="documentModules" [(ngModel)]="selectedModule" (change)="search()">
      <option hidden [ngValue]="null" selected>Filter by an assessment module</option>
      <option *ngFor="let module of documentModules">{{module}}</option>
    </select>
  </div>

  <div class="col">
    <button class="btn btn-primary" (click)="removeModuleFilter()">Remove filter</button>
  </div>

</div>

<div class="text-center" *ngIf="isLoading; else displayDocuments">
  <app-loading-spinner></app-loading-spinner>
</div>

<ng-template #displayDocuments>
  <alert type="danger" *ngIf="error">{{ error }}</alert>
  <tabset>
    <tab
      [active]="selectedTab === DocumentTabs.CurrentDocuments"
      heading="Current Application(s) Documents"
      customClass="headingCss"
      (selectTab)="onTabSelected($event)">

      <app-list-view [accountId]="accountId"
                     [documents]="pageOfResults(currentFilteredDocuments)"
                     [isAdmin]="isAdmin"
                     (sort)="sort($event, currentFilteredDocuments);">
      </app-list-view>
      <pagination *ngIf="hasEnoughRowsForPagination(currentFilteredDocuments)"
                  [itemsPerPage]="rowsPerPage"
                  [totalItems]="currentDocuments.length"
                  [(ngModel)]="currentDocPage"
                  (pageChanged)="pageChanged($event)"
                  [maxSize]="maxSize"
                  [boundaryLinks]="currentDocuments.length > boundaryLinksLimit">
      </pagination>
    </tab>
    <tab
      [active]="selectedTab === DocumentTabs.PreviousDocuments"
      heading="Old Application(s) Documents"
      customClass="headingCss"
      (selectTab)="onTabSelected($event)">

      <app-list-view [accountId]="accountId"
                     [documents]="pageOfResults(previousFilteredDocuments)"
                     [isAdmin]="isAdmin"
                     (sort)="sort($event, previousFilteredDocuments);">
      </app-list-view>
      <pagination *ngIf="hasEnoughRowsForPagination(previousFilteredDocuments)"
                  [itemsPerPage]="rowsPerPage"
                  [totalItems]="previousFilteredDocuments.length"
                  (pageChanged)="pageChanged($event)"
                  [(ngModel)]="previousDocPage"
                  [maxSize]="maxSize"
                  [boundaryLinks]="previousFilteredDocuments.length > boundaryLinksLimit">
      </pagination>
    </tab>
    <tab
      [active]="selectedTab === DocumentTabs.OtherDocuments"
      heading="Free To Me Documents"
      customClass="headingCss"
      (selectTab)="onTabSelected($event)">

      <app-list-view [accountId]="accountId"
                     [documents]="pageOfResults(otherFilteredDocuments)"
                     [isAdmin]="isAdmin"
                     (sort)="sort($event, otherFilteredDocuments);">
      </app-list-view>
      <pagination *ngIf="hasEnoughRowsForPagination(otherFilteredDocuments)"
                  [itemsPerPage]="rowsPerPage"
                  [totalItems]="otherDocuments.length"
                  (pageChanged)="pageChanged($event)"
                  [(ngModel)]="otherDocPage"
                  [maxSize]="maxSize"
                  [boundaryLinks]="otherDocuments.length > boundaryLinksLimit">
      </pagination>
    </tab>
    <tab
      [active]="selectedTab === DocumentTabs.LearningPathway"
      heading="Learning Pathway"
      customClass="headingCss"
      (selectTab)="onTabSelected($event)"
      *ngIf="isFoundation && foundationFeatureEnabled">

      <app-list-view [accountId]="accountId"
                     [documents]="pageOfResults(learningPathwayFilteredDocuments)"
                     [isAdmin]="isAdmin"
                     (sort)="sort($event, learningPathwayFilteredDocuments);">
      </app-list-view>
      <pagination *ngIf="hasEnoughRowsForPagination(learningPathwayFilteredDocuments)"
                  [itemsPerPage]="rowsPerPage"
                  [totalItems]="learningPathwayDocuments.length"
                  (pageChanged)="pageChanged($event)"
                  [(ngModel)]="learningPathwayDocPage"
                  [maxSize]="maxSize"
                  [boundaryLinks]="learningPathwayDocuments.length > boundaryLinksLimit">
      </pagination>
    </tab>
  </tabset>
</ng-template>
<ng-template #modalConfirmDelete>
  <div class="modal-header custom-header">
    <h5 class="modal-title" style="line-height: 1;">Delete File</h5>
  </div>
  <div class="modal-body text-center">
    <p class="modal-message">
      Are you sure you want to delete <b>{{ fileToDelete?.name }}</b>,
      this file cannot be recovered once deleted.
    </p>
  </div>
  <div class="modal-footer text-center justify-content-md-center">
    <button type="button"
            class="btn btn-green"
            (click)="deleteConfirmed()">
      <i class="fa fa-check"></i>
      Yes
    </button>
    <button type="button"
            class="btn btn-red"
            (click)="closeModal()">
      <i class="fa fa-times"></i>
      No
    </button>
  </div>
</ng-template>
