import { Injectable } from '@angular/core';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';

// const env = new Environment();
// const environment = env.getConfigSync();
import { HttpClient } from '@angular/common/http';
import { of, Observable, throwError, BehaviorSubject, ConnectableObservable } from 'rxjs';
import { WorkAreaUpdate } from '../../shared';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkRegionsService {
  environment: IEnvironment;
  
  constructor(private http: HttpClient) {
    const env = new Environment();
    this.environment = env.getConfigSync();
  }
private isWorkRegionsLoaded =false;
  private cachedWorkRegions = new BehaviorSubject<Array<any>>([]);

  getAllCachedWorkRegions(): Observable<Array<any>> {
    if (!this.isWorkRegionsLoaded)
    {
      this.getAllWorkRegions().subscribe();
    }
    return this.cachedWorkRegions.asObservable();
  }

  getAllWorkRegions_orig() : Observable<any> {
    return this.http.get(`${this.environment.apiEndpoint.workCategories}/work-regions`)
    .pipe(tap(data => this.cachedWorkRegions.next(data)));
  }

  getAllWorkRegions() : Observable<any> {
    this.isWorkRegionsLoaded =true;
    const countryCode = this.environment.countryCode;
    
    return this.http.get(`${this.environment.apiEndpoint.workLocations}/${countryCode}/locationviews`)
    .pipe(tap(data => this.cachedWorkRegions.next(data)));
  }

  getAllWorkLocations(countryCode: string = null) : Observable<any> {
    if(!countryCode) countryCode = this.environment.countryCode;
    
    return this.http.get(`${this.environment.apiEndpoint.workLocations}/${countryCode}/countrylocations`)
    .pipe(tap(data => this.cachedWorkRegions.next(data)));
  }

  public saveWorkLocations(accountId: string, workLocations: any[]): Observable<any> {
    if (workLocations && workLocations.length) {
      return this.http.post(
        `${this.environment.apiEndpoint.workLocations}/${accountId}/saveaccountworklocations`,
        workLocations
      );
    }

    return of(null);
  }

  setWorkAreas(accountId: string, areaCodes: string[]): Observable<any> {
    let payload = {
      WorkRegionCodes: areaCodes
    } as WorkAreaUpdate;

    // areaCodes.forEach(element => {
    //   console.log(element);
    // });
    // Only perform an update if there are work areas to save.
    // TODO should a user be able to remove all work areas?
    if (payload.WorkRegionCodes.length) {
      return this.http.post(
        `${this.environment.apiEndpoint.workLocations}/${accountId}/saveaccountworklocations`,
        payload
      );
    }

    return of(null);
  }
}
