import { Component, OnInit } from '@angular/core';
import { Environment } from 'src/app/shared/classes/environment';
import { HelplinePhoneNumber, RegisterUrl, HelpdeskEmailAddress, FAQUrl, MainUrl } from 'src/app/shared/components/register-carousel/register-carousel.component';
import { Territory } from 'src/app/shared/constants/territory.enum';

@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss']
})
export class SiteFooterComponent implements OnInit {

  public phoneNumber = HelplinePhoneNumber.UNITED_KINGDOM;
  public emailAddress = HelpdeskEmailAddress.UNITED_KINGDOM;
  public registerUrl = RegisterUrl.UNITED_KINGDOM;
  public faqUrl = FAQUrl.UNITED_KINGDOM;
  public mainUrl = MainUrl.UNITED_KINGDOM;

  async ngOnInit(): Promise<void> {
    const env = new Environment();
    const environment = await env.getConfig();
    let isAus = environment.territory === Territory.AUSTRALIA;

    if (isAus) {
      this.phoneNumber = HelplinePhoneNumber.AUSTRALIA;
      this.emailAddress = HelpdeskEmailAddress.AUSTRALIA;
      this.registerUrl = RegisterUrl.AUSTRALIA;
      this.faqUrl = FAQUrl.AUSTRALIA;
      this.mainUrl = MainUrl.AUSTRALIA;
    }
  }

  /**
   * Simply return the current year.
   */
  get currentYear(): string {
    return new Date().getFullYear().toString();
  }

}
