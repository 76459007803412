import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembershipCardComponent } from './membership-card.component';
import { ReferenceValidatorModule } from '../../../shared/components/reference-validator/reference-validator.module';
import { QuoteContainerModule } from '../../../shared/components/quote-container/quote-container.module';
import { BootstrapComponentsModule } from '../../../core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { IntroVideoModalComponent } from '../intro-video-modal/intro-video-modal.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';

@NgModule({
  declarations: [MembershipCardComponent,
    IntroVideoModalComponent],
  exports: [MembershipCardComponent],
  imports: [
    CommonModule,
    ReferenceValidatorModule,
    QuoteContainerModule,
    BootstrapComponentsModule,
    RouterModule,
    ReactiveFormsModule,
    PipesModule
  ]
})
export class MembershipCardModule {  }
