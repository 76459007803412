import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReferenceCodeService {
  isReferenceCodeForReturningCustomer(referenceCode) {
    const code = referenceCode.toUpperCase();
    if (referenceCode.lastIndexOf('TA', 0) === 0
      && code.indexOf('21R', code.length - '21R'.length) !== -1) {
      return true;
    }
    return false;
  }

  isReferenceCodeForNewCustomer(referenceCode) {
    const code = referenceCode.toUpperCase();
    if (referenceCode.lastIndexOf('TA', 0) === 0
      && code.indexOf('21N', code.length - '21N'.length) !== -1) {
      return true;
    }
    return false;
  }
  

  public async resolveName(bundle: any, referenceCode: string, isNew: boolean) {
    const code = referenceCode ? referenceCode.toUpperCase() : '';
    const refCodeEnding = isNew ? '21R' : '21N';

    if (code.lastIndexOf('TA', 0) === 0 &&
      code.indexOf(refCodeEnding, code.length - refCodeEnding.length) !== -1) {
      return bundle.name;
    }

    if (bundle.refName) {
      return bundle.refName;
    }
    return bundle.name;
  }
}


