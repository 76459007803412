<app-loading-spinner *ngIf="isLoading; else assessmentsMenu" small="1"></app-loading-spinner>

<ng-template #assessmentsMenu>
  <alert type="danger" *ngIf="error">{{ error }}</alert>

  <ul class="assessment-menu__categories" *ngIf="assessmentCategories?.length > 0">
    <li *ngFor="let category of assessmentCategories" class="assessment-menu__categories__item">
      <div *ngIf="category">
      <ng-template #tooltipTemplate>
        <div [innerHtml]="category.helpTip"></div>
      </ng-template>
      <p class="font-weight-bold my-2">
        <nobr>{{ category.name }} ({{ category.assessments.length | number }})&nbsp;
        <i class="fas fa-question-circle" [tooltip]="tooltipTemplate"></i></nobr>
      </p>

      <ul class="assessment-menu__bundles row">
        <li *ngFor="let bundle of category.bundles" class="assessment-menu__bundles__item nav-item col-md-6 col-lg-12">
          <span class="assessment-menu__bundlename">{{ bundle.name }}</span>

          <ul class="assessment-menu__assessments row">
            <li *ngFor="let assessment of bundle.assessments" class="assessment-menu__assessments__item nav-item col-md-6 col-lg-12">
              <a (click)="selectAssessment(assessment, category.name)"
                 [ngClass]="{active:isAssessmentSelected(assessment.id)}">
                <span *ngIf="assessmentMenuEnabled && assessment.id !== selectedAssessmentId" style="font-size: 0.6rem; vertical-align: middle;">&#9654;</span>
                <span *ngIf="assessmentMenuEnabled && assessment.id === selectedAssessmentId" style="font-size: 0.7rem; vertical-align: middle;">&#9660;</span>
                {{ assessment.name }} {{ assessment.assessmentType?.label }}
              </a>
              <ul class="assessment-menu__pages pt-2 pb-2" *ngIf="assessmentMenuEnabled && assessment.id === selectedAssessmentId">
                <li *ngFor="let page of assessmentPages; let i = index">
                  <a class="sub_link" class="pb-2"
                     [class.text-danger]="page.hasErrors"
                     (click)="selectAssessmentPage(page)"
                     [ngClass]="{'link-bold': page.responseRequired, active: isAssessmentPageSelected(page, i), complete: page.complete && !isAssessmentPageSelected(page, i) && !page.requestForMoreInfo}">
                    {{ page.title }}&nbsp;<span *ngIf="page.complete && !page.requestForMoreInfo" style="color: green;">✓</span>
                    <i class="fas fa-comment-dots" *ngIf="page.responseRequired"></i>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      </div>
    </li>
  </ul>

</ng-template>
