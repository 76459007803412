import { ErrorHandler, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

export class AppErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) { }
  
  handleError(error: any) {

    const router = this.injector.get(Router);

    // Handle HTTP errors.
    if (Error instanceof HttpErrorResponse) {
      console.error("Error handler: HTTP error", error.status);
    } else {
      console.error("Error handler: an error occurred...", error);
    }

    // Navigate to an error page(?)
    // router.navigate(['error']);
  }
}