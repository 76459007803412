import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteLayoutComponent } from './site-layout.component';
import { ChasLogoModule } from '../../chas-logo/chas-logo.module';
import { RouterModule } from '@angular/router';
import { SiteMenuModule } from '../../site-menu/site-menu.module';
import { UserMenuModule } from '../../user-menu/user-menu.module';
import { SiteFooterModule } from '../../site-footer/site-footer.module';
import { LoadingSpinnerModule } from '../../loading-spinner/loading-spinner.module';
import { TermsModalModule } from '../../terms-modal/terms-modal.module';
import { NgIdleModule } from '@ng-idle/core';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
  declarations: [SiteLayoutComponent],
  exports: [SiteLayoutComponent],
  imports: [
    CommonModule,
    ChasLogoModule,
    RouterModule,
    SiteFooterModule,
    SiteMenuModule,
    UserMenuModule,
    LoadingSpinnerModule,
    ChasLogoModule,
    TermsModalModule,
    NgIdleModule.forRoot(),
    MatButtonModule,
    MatToolbarModule,
  ],
})
export class SiteLayoutModule {}
