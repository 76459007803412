import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';

import { Regex, MonitorSeverity, GetQuoteRequest, GetQuoteResponse, UpdateAccountConfirmations, productTypes, BasketItem, GetOrderSummary } from '../../shared';
import { CompaniesHouseCompanyDetails, CreateAccountParameters, UserTypeCode, PreselectedProduct, MatchFields, FieldsShouldNotMatch } from '../../shared';
import {
  AccountsService,
  ProductService,
  monitorService,
  PaymentService,
  CountryService
} from '../../core';
import productBundlesJSON from 'src/assets/json/product-bundles.json';
import { ISOCountry } from 'src/app/shared/interfaces/iso-country';
import { GtmBrand, GtmListTypes, GtmUserCategory, GtmUserStatus } from 'src/app/core/services/gtm/gtm.enum';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';
import { GtmProduct } from 'src/app/core/services/gtm/gtm.interface';
import FastFill from 'src/app/shared/classes/fastFill.class';
import { Store } from '@ngrx/store';
import { BasketItemsActions } from 'src/app/core/ngrx/actions/basketItems.actions';
import { BasketAccountActions } from 'src/app/core/ngrx/actions/basketAccount.actions';
import { selectBasketAccount, selectBasketItems, selectBasketQuote, selectBasketSummary } from 'src/app/core/ngrx/selectors/basket.selectors';
import { BasketQuoteActions } from 'src/app/core/ngrx/actions/basketQuote.actions';
import { PriceService } from 'src/app/core/services/price.service';
import { BasketSummaryActions } from 'src/app/core/ngrx/actions/basketSummary.actions';
import { ProductName } from 'src/app/shared/classes/productName.class';

export enum AppInitialProduct {
  ApplicationTypeCode = '885570003', // Construction
  AssessmentTypeCode = '885570000', // Full Application
  ProductNumber = 'P-01103-F6H3P3' // CHAS Premium Plus
}
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterPageComponent implements OnInit, OnDestroy {
  basketItems$: Observable<BasketItem[]> = this.store.select(selectBasketItems);
  basketAccount$: Observable<CreateAccountParameters> = this.store.select(selectBasketAccount);
  basketQuote$: Observable<GetQuoteRequest> = this.store.select(selectBasketQuote);
  basketSummary$: Observable<GetOrderSummary> = this.store.select(selectBasketSummary);
  basketSummary: GetOrderSummary;
  basketAccount: CreateAccountParameters;
  basketItems: BasketItem[];
  basketQuote: GetQuoteRequest;
  basketItem: BasketItem;
  accountID: string;
  defaultCountryCode: string = 'GB';
  passwordInfo: string = `Default password complexity: Minimum 8 characters. Passwords must contain atleast 3 of the following components; uppercase, lowercase, number, special character`;

  employeesInfo: string = `How are employees defined?
    The number of employees is the total number of direct employees, PAYE, permanent, temporary, part-time and labour only subcontractors. According to UK labour law a labour-only subcontractor is considered an employee.
    This does not include bona-fide subcontractors who work with their own tools, insurance etc. and are usually paid by invoice.
    We recommend that you consider the total number of effective employees you may have/use at any one time during a 12-month rolling period.`;

  initialProduct: PreselectedProduct = {
    ApplicationTypeCode: '885570003', // Construction
    AssessmentTypeCode: '885570000', // Full Application
    ProductNumber: 'P-01103-F6H3P3' // CHAS Premium Plus
  }
  // Things to do on this page:
  // - log the user out (if one exists)
  // - fetch a list of product bundles
  // - build a list of application types
  // - build a list of assessment types
  // - preselect a product bundle (if available)
  // - prepopulate a reference code (if available)

  searchForm = new UntypedFormGroup({
    companyNumber: new UntypedFormControl('', Validators.required)
  });
  suggestions: Observable<any[]>;

  // Using a reactive form to represent the registration form.
  registerForm = new UntypedFormGroup({
    companyNumber: new UntypedFormControl('', []),
    companyName: new UntypedFormControl('', [Validators.required]),
    street_number: new UntypedFormControl('', [Validators.required]),
    route: new UntypedFormControl(''),
    postal_town: new UntypedFormControl('', [Validators.required]),
    postal_code: new UntypedFormControl('', [Validators.required]),
    country_code: new UntypedFormControl(this.defaultCountryCode, [Validators.required]),
    firstName: new UntypedFormControl('', [Validators.required]),
    lastName: new UntypedFormControl('', [Validators.required]),
    contactEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
    contactEmailConfirm: new UntypedFormControl('', [Validators.required, Validators.email]),
    contactPassword: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.PASSWORD)]),
    contactPasswordConfirm: new UntypedFormControl('', [Validators.required]),
    contactPhone: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.PHONE)]),
    number_of_direct_employees: new UntypedFormControl('', [Validators.required, Validators.min(1)]),
    number_of_labour_only_subcontractors: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
    employees: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
    number_of_bona_fide_subcontractors: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
    referenceCode: new UntypedFormControl('', []),
    Bundle: new UntypedFormControl('', [Validators.required]),
    applicationTypeCode: new UntypedFormControl('', [Validators.required]),
    assessmentTypeCode: new UntypedFormControl('', [Validators.required])
  },
    [
      MatchFields('contactPassword', 'contactPasswordConfirm'),
      MatchFields('contactEmail', 'contactEmailConfirm'),
      FieldsShouldNotMatch('contactEmail', 'contactPassword')
    ]
  );

  formInvalid: boolean;
  companyIsDissolved: boolean;
  isManualEntry: boolean = false;
  isCompanyHouse: boolean;
  isQuoting: boolean;
  companyNotFound: boolean;
  companySearchError: boolean;
  validatedReferenceCode: string;
  referenceCodeSuccess: boolean;
  referenceCodeError: boolean;
  quoteError: string;
  quoteAmount: number;
  ftsQuoteAmount: number;
  preselectedProductNumber: string;
  showQuote: boolean = false;
  autocompleteOptions: {};
  selectedCompany: any;
  lastQuoteResult: GetQuoteResponse;
  showFoundation: boolean = false;
  acceptTerms: any;
  checkingReferenceCode: boolean = false;
  countries: ISOCountry[];
  private selectors$: Subscription;
  private config: IEnvironment;

  constructor(
    private readonly gtmService: GtmService,
    private readonly router: Router,
    private readonly Accounts: AccountsService,
    private readonly Product: ProductService,
    private readonly Monitor: monitorService,
    private readonly paymentService: PaymentService,
    private readonly countryService: CountryService,
    private readonly priceService: PriceService,
    private readonly store: Store
  ) { }

  public async ngOnInit() {
    const env = new Environment();
    this.config = await env.getConfig();

    this.store.dispatch({ type: BasketItemsActions.Load });
    this.store.dispatch({ type: BasketAccountActions.Load });

    this.selectors$ = combineLatest([
      this.basketItems$,
      this.basketAccount$,
      this.basketQuote$,
      this.basketSummary$
    ]).subscribe(async(data) => {
      this.basketItems = data[0];
      this.basketAccount = data[1];
      this.basketQuote = data[2];
      this.basketSummary = data[3];

      if(this.basketItems) this.basketItem = this.basketItems[0];
      if(this.basketAccount) this.populateFormFromBasket();
    }, (error) => console.error(error));

    this.validatedReferenceCode = null;
    this.quoteAmount = null;
    this.quoteError = null;
    //set options for google place api element
    this.autocompleteOptions = {
      componentRestrictions: { country: this.defaultCountryCode }
    };

    if(this.router.url.includes('foundation') || this.router.url.includes('builduk')) {
      this.showFoundation = true;
    }

    this.countries = this.countryService.getAllCountries();

    this.recalculateEmployees();
  }

  ngOnDestroy() {
    this.selectors$.unsubscribe();
  }

  goToLogin() {
    // Browser navigate rather than using router so that
    // post-login will navigate to the dashboard correctly.
    window.location.href = "/";
  }

  handleAddressChange(address) {
    const fields = ['street_number', 'route', 'postal_town', 'postal_code'];
    fields.forEach(field => this.registerForm.get(field).setValue(''));

    for (var i = 0; i < address.address_components.length; i++) {
      const addressType = address.address_components[i].types[0];
      const addressShortValue = address.address_components[i]['short_name'];
      const addressName = address.name;

      if(addressType === 'country') {
        this.registerForm.get('country_code').setValue(addressShortValue);
      }
      else if (this.registerForm.contains(addressType)) {
        this.registerForm.get(addressType).setValue(addressShortValue);
      }
      else if (address.address_components[0].types[0] !== 'street_number' && address.address_components[0].types[0] !== 'postal_code') {
        this.registerForm
          .get('street_number')
          .setValue(addressName);
      }
    }
  }

  recalculateEmployees(): number {
    let newVal = this.registerForm.controls.number_of_direct_employees.value
      + this.registerForm.controls.number_of_labour_only_subcontractors.value;

    this.registerForm.controls.employees.setValue(newVal);
    this.registerForm.controls.employees.disable();

    if(this.registerForm.value.Bundle) {
      let item: GtmProduct = {
        id: this.registerForm.value.Bundle.productNumber,
        name: this.registerForm.value.Bundle.name,
        price: this.priceService.getPrice(this.registerForm.value.Bundle.level),
        brand: GtmBrand.CHAS,
        category: GtmUserCategory.Contractor,
      };

      this.gtmService.selectNumberOfDirectEmployees({
        actionField: { list: GtmListTypes.signup },
        products: [item]
      });
    }

    return newVal;
  }

  /**
   * Populates the registration form based on the contents of the basket.
   */
  populateFormFromBasket() {
    this.preselectedProductNumber = this.basketAccount.ProductNumber;
    this.validatedReferenceCode = this.basketAccount.ClientReferenceCode;

    this.registerForm.patchValue({
      assessmentTypeCode: this.basketAccount.AssessmentType,
      referenceCode: this.basketAccount.ClientReferenceCode,
      companyNumber: this.basketAccount.CompanyHouseNo,
      companyName: this.basketAccount.CompanyName,
      firstName: this.basketAccount.ContactFirstName,
      lastName: this.basketAccount.ContactLastName,
      contactEmail: this.basketAccount.EmailAddress,
      contactEmailConfirm: this.basketAccount.EmailAddress,
      employees: this.basketAccount.NoOfEmployees,
      contactPassword: this.basketAccount.Password,
      contactPasswordConfirm: this.basketAccount.Password,
      street_number: this.basketAccount.RegAddressline1,
      route: this.basketAccount.RegAddressline2,
      postal_town: this.basketAccount.RegCity,
      postal_code: this.basketAccount.RegPostcode,
      country_code: this.basketAccount.RegCountryCode,
      contactPhone: this.basketAccount.Telephone1,
      applicationTypeCode: this.basketAccount.Variant,
      number_of_direct_employees: this.basketAccount.NumberofDirectEmployees,
      number_of_labour_only_subcontractors: this.basketAccount.LabourOnlySubContractors,
      number_of_bona_fide_subcontractors: this.basketAccount.BonafideSubContractors,
      Bundle: productBundlesJSON.find(x => x.name === this.basketItem.name)
    });
    if (this.basketAccount.ClientReferenceCode) this.validateReferenceCode();
    this.isManualEntry = true;
  }

  onCompanySearchEvent(event: any): void {
    switch(event.eventType) {
      case "fastFill":
        this.fastFill();
      break;
      case "manualEntry":
        this.enterDetailsManually();
      break;
      case "companieshouse_found":
        this.selectedCompany = event.companySearchEventProperties.creditSafeMetaData;
        this.companyFound(event.companySearchEventProperties.companiesHouseCompanyDetails);
      break;
      case "companieshouse_notfound":
        this.companyUnavailable(event.companySearchEventProperties.companyHouseNumber);
      break;
    }
  }

  private companyFound(companyDetails: CompaniesHouseCompanyDetails):void {
    this.populateFormFromCompany(companyDetails);
    this.gtmService.pushTag({
      id: this.config.googleAnalytics.googleTagManagerId,
      event: 'companieshouse_found',
      search: companyDetails
    });
  }

  private companyUnavailable(companyDetails: string):void {
    this.gtmService.pushTag({
      id: this.config.googleAnalytics.googleTagManagerId,
      event: 'companieshouse_notfound',
      search: companyDetails
    });
    this.companyNotFound = true;
  }

  /**
   * Populate the registration form with company details.
   */
  populateFormFromCompany(companyDetails: CompaniesHouseCompanyDetails): void {
    // Check if the company status is Dissolved, if so prevent progress.

    this.preselectedProductNumber = this.initialProduct.ProductNumber; // CHAS Premium Plus
    // Populate the registration form.
    const patch = {
      companyName: companyDetails.companyName,
      companyNumber: companyDetails.systemId,
      street_number: companyDetails.addressLine1,
      route: companyDetails.addressLine2,
      postal_town: companyDetails.addressLine3,
      postal_code: companyDetails.postcode,
      country_code: this.defaultCountryCode, //todo - check with AUS
      applicationTypeCode: this.initialProduct.ApplicationTypeCode,
      assessmentTypeCode: this.initialProduct.AssessmentTypeCode
    };

    this.registerForm.patchValue(patch);
    this.isCompanyHouse = true;
    this.isManualEntry = true;
  }

  /**
   * Reveal the rest of the registration form.
   */
  enterDetailsManually(): void {
    this.registerForm.reset();
    this.registerForm.get('country_code').setValue(this.defaultCountryCode);
    this.registerForm.get('applicationTypeCode').setValue(this.initialProduct.ApplicationTypeCode);
    this.registerForm.get('assessmentTypeCode').setValue(this.initialProduct.AssessmentTypeCode);
    this.preselectedProductNumber = this.initialProduct.ProductNumber; // CHAS Premium Plus
    this.selectedCompany = undefined;
    this.isManualEntry = true;
  }

  /**
  * Hide the registration form and show the companies house lookup
  */
  closeManualEntry(e: Event): void {
    e.preventDefault();
    this.isManualEntry = false;
  }

  async validateReferenceCode(): Promise<void> {
    const referenceCode = this.registerForm.value.referenceCode;
    if (!referenceCode) {
      return;
    }

    this.referenceCodeError = false;
    this.referenceCodeSuccess = false;
    this.validatedReferenceCode = referenceCode;

    this.checkingReferenceCode = true;

    await this.getQuoteAsync();

    this.checkingReferenceCode = false;

    this.gtmService.pushTag({
      id: this.config.googleAnalytics.googleTagManagerId,
      event: 'reference_code',
      code: referenceCode
    });
  }


  buildCreateAccountParams(): CreateAccountParameters {
    let regDetails: any = {
      AssessmentType: this.registerForm.value.assessmentTypeCode,
      BundleId: this.registerForm.value.Bundle.id,
      ProductNumber: this.registerForm.value.Bundle.productNumber,
      ClientReferenceCode: this.validatedReferenceCode,
      CompanyHouseNo: this.registerForm.value.companyNumber,
      CompanyName: this.registerForm.value.companyName,
      ContactFirstName: this.registerForm.value.firstName,
      ContactLastName: this.registerForm.value.lastName,
      CustomerType: UserTypeCode.CONTRACTOR,
      EmailAddress: this.registerForm.value.contactEmail,
      NoOfEmployees: this.registerForm.value.employees,
      NumberofDirectEmployees: this.registerForm.value.number_of_direct_employees,
      LabourOnlySubContractors: this.registerForm.value.number_of_labour_only_subcontractors,
      BonafideSubContractors: this.registerForm.value.number_of_bona_fide_subcontractors,
      Password: this.registerForm.value.contactPassword,
      RegAddressline1: this.registerForm.value.street_number,
      RegAddressline2: this.registerForm.value.route,
      RegCity: this.registerForm.value.postal_town,
      RegPostcode: this.registerForm.value.postal_code != null && this.registerForm.value.postal_code.length > 0 ? this.registerForm.value.postal_code : null,
      RegCountry: this.countryService.getNameFromCode(this.registerForm.value.country_code),
      RegCountryCode: this.registerForm.value.country_code,
      Telephone1: this.registerForm.value.contactPhone,
      Mobile1: null,
      TradingAs: null,
      Variant: this.registerForm.value.applicationTypeCode,
      SuccessUrl: this.config.urls.environmentUrl + '/payment-success/register',
      CancelUrl: this.config.urls.environmentUrl + '/order-summary',
      SkipOpportunity: true
    };

    if (this.selectedCompany !== undefined) {
      regDetails.CreditSafeNumber = this.selectedCompany.id;
    }

    return regDetails;
  }

  /**
   * A convenience method for filling in the registration form.
   * This will not run on production sites.
   */
  fastFill(): void {
    if (!this.shouldShowFastFill()) { return; }

    this.selectedCompany = undefined;

    this.registerForm.setValue(new FastFill().getData());

    this.recalculateEmployees();

    this.preselectedProductNumber = this.initialProduct.ProductNumber; // CHAS Premium Plus
    this.isManualEntry = true;
  }

  async createAccountAsync(createAccountParams: CreateAccountParameters): Promise<boolean> {
    try {
      const accountId = await this.Accounts.create(createAccountParams, false).toPromise();
      sessionStorage.setItem('guidac', accountId);
      this.accountID = accountId;

    }
    catch(error) {
      this.quoteError = 'An unexpected error occurred. Please try again in a few minutes.';
      this.Monitor.exception(error.message, MonitorSeverity.CRITICAL);
      return false;
    }

    return true;
  }

  async getQuoteAsync(createOpportunity?: boolean): Promise<boolean> {
    try
    {
      createOpportunity = createOpportunity ?? false;
      this.quoteError = null;

      const matchingPriceList = await this.Product.searchForPriceListAsync(this.registerForm.value.Bundle.name, false);

      const quoteRequest: GetQuoteRequest = {
        accountId: this.accountID,
        priceLevelId: matchingPriceList.priceLevelId,
        sectorType: this.registerForm.value.applicationTypeCode,
        applicationType: this.registerForm.value.assessmentTypeCode,
        numberOfEmployees: this.recalculateEmployees(),
        directEmployees: this.registerForm.value.number_of_direct_employees,
        labourOnlySubContractors: this.registerForm.value.number_of_labour_only_subcontractors,
        bonafideSubContractors: this.registerForm.value.number_of_bona_fide_subcontractors,
        discountCode: this.validatedReferenceCode,
        fastTrack: false,
        upgrade: false,
        joiningFee: true,
        createOpportunity: createOpportunity,
        successUrl: this.config.urls.environmentUrl + '/payment-success/register',
        cancelUrl: this.config.urls.environmentUrl + '/order-summary',
        dataSharingAllowed: false,
        customerType: UserTypeCode.CONTRACTOR
      };

      const quote = await this.Product.getMembershipQuoteAsync(quoteRequest, true);

      if(quote.error) {
        this.quoteError = quote.error.message;
        this.Monitor.log(quote.error.message, MonitorSeverity.CRITICAL);
        return false;
      }

      this.ftsQuoteAmount = quote.result.fastTrackAmount;
      this.quoteAmount = quote.result.totalAmount;
      this.lastQuoteResult = quote;

      this.updateReferenceCodeState(quote);
    }
    catch (error) {
      this.quoteError = 'An unexpected error occurred. Please try again in a few minutes.';
      this.Monitor.exception(error.message, MonitorSeverity.CRITICAL);
      return false;
    }

    return true;
  }

  private updateReferenceCodeState(quote: GetQuoteResponse) {
    if (quote.request.discountCode && quote.result.discountCodeValid) {
      this.referenceCodeSuccess = true;
      this.referenceCodeError = false;
      this.validatedReferenceCode = quote.request.discountCode;
    }
    else if (quote.request.discountCode && !quote.result.discountCodeValid) {
      this.referenceCodeSuccess = false;
      this.referenceCodeError = true;
      this.validatedReferenceCode = null;
    }
  }

  async userOrderAsync() {
    this.formInvalid = false;

    if (this.registerForm.invalid) {
      this.formInvalid = true;
      this.registerForm.markAllAsTouched();
      return;
    }
    this.registerForm.disable();
    this.isQuoting = true;

    const createAccountParams = this.buildCreateAccountParams();
    const accountCreatedSuccessfully = await this.createAccountAsync(createAccountParams);

    if(!accountCreatedSuccessfully) {
      this.registerForm.enable();
      this.isQuoting = false;
      return;
    }

    const getQuoteSuccessful = await this.getQuoteAsync(true);

    if(!getQuoteSuccessful) {
      this.registerForm.enable();
      this.isQuoting = false;
      return;
    }

    await this.createOrderAsync();
  }

  /**
   * Add the details to the basket, then go to the next page.
   */
  async createOrderAsync(): Promise<void> {
    // Add customer information to the basket.
    const account = this.buildCreateAccountParams();
    account.accountId = this.accountID;

    const basketItem = {
      accountId: this.accountID,
      name: this.registerForm.value.Bundle.name,
      productID: this.lastQuoteResult.result.productId,
      applicationType: this.registerForm.value.applicationTypeCode,
      assessmentType: this.registerForm.value.assessmentTypeCode,
      units: this.registerForm.value.employees,
      price: this.quoteAmount,
      fastTrack: this.ftsQuoteAmount,
      joiningFee: this.lastQuoteResult.result.joiningFeeAmount,
      checkFastTrack: this.lastQuoteResult.request.fastTrack,
      account: account,
      renewal: false,
      upgrade: false,
      referenceCode: this.validatedReferenceCode,
      discountAmount: this.lastQuoteResult.result.discountPercentage,
      vatPercent: this.lastQuoteResult.result.vatPercent
    };

    delete account.Password;

    this.store.dispatch({
      type: BasketAccountActions.Add,
      account: account
    });

    this.store.dispatch({
      type: BasketItemsActions.Add,
      item: basketItem
    });

    this.store.dispatch({
      type: BasketQuoteActions.Add,
      quote: this.lastQuoteResult.request
    });

    this.store.dispatch({
      type: BasketSummaryActions.Add,
      orderSummary: this.lastQuoteResult.orderSummary
    });

    this.gtmService.addToCart({
      uid: this.accountID,
      effectiveEmployees: this.recalculateEmployees(),
      directEmployees: this.registerForm.value.number_of_direct_employees,
      labourOnlySubcontractors: this.registerForm.value.number_of_labour_only_subcontractors,
      bonafideSubContraactors: this.registerForm.value.number_of_bona_fide_subcontractors,
      ecommerce: {
        add: {
          actionField: { list: GtmListTypes.signup },
          products: this.basketItems.map(product => {
            return {
              id: product.productID,
              name: product.name,
              price: product.price,
              brand: GtmBrand.CHAS,
              category: GtmUserCategory.Contractor
            }
          })
        }
      }
    });

    this.gtmService.contractorSignUp({
      uid: this.accountID,
      status: GtmUserStatus.LoggedIn
    });

    this.router.navigate(['/order-summary']);
  }

  async processFoundationOrderAsync() {
    this.formInvalid = false;

    if (this.registerForm.invalid) {
      this.formInvalid = true;
      this.registerForm.markAllAsTouched();
      return;
    }
    this.registerForm.disable();
    this.isQuoting = true;

    if (this.registerForm.value.Bundle.level == 0) {

      const createAccountParams = this.buildCreateAccountParams();
      const accountCreatedSuccessfully = await this.createAccountAsync(createAccountParams);

      if(!accountCreatedSuccessfully) {
        this.registerForm.enable();
        this.isQuoting = false;
        return;
      }

      await this.acceptTermsAndConditionsAsync(this.accountID);

      const quoteRequest: GetQuoteRequest = {
        accountId: this.accountID,
        productId: productTypes.CHAS_FOUNDATION,
        numberOfEmployees: this.recalculateEmployees(),
        directEmployees: this.registerForm.value.number_of_direct_employees,
        labourOnlySubContractors: this.registerForm.value.number_of_labour_only_subcontractors,
        bonafideSubContractors: this.registerForm.value.number_of_bona_fide_subcontractors,
        createOpportunity: true,
        successUrl: this.config.urls.environmentUrl + '/payment-success/foundation',
        cancelUrl: this.config.urls.environmentUrl,
        dataSharingAllowed: false,
        customerType: UserTypeCode.CONTRACTOR
      };

      var quote = await this.Product.getIndividualQuoteAsync(quoteRequest);

      var opportunityObject = this.Product.convertQuoteRequestToOpportunityObject(quoteRequest);

      await this.paymentService.activateAsync(quote.result.grandTotal, opportunityObject);

      this.router.navigate(['/payment-success/register-foundation']);
    }
    this.isQuoting = false;
  }

  async acceptTermsAndConditionsAsync(accountId: string): Promise<void> {
    const confirmations: UpdateAccountConfirmations = {
      AcceptedTermsAndConditions: true
    };
    await this.Accounts.updateConfirmationsAsync(accountId, confirmations);
  }

  shouldShowFastFill(): boolean {
    return !this.config.production;
  }

  toggleTerms(event) {
    this.acceptTerms = event.target.checked;
  }

  isEliteBundle(): boolean {
    return this.registerForm.value.Bundle.name !== ProductName.level4;
  }

}
