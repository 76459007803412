
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { RegisterstepthreeActions, registerStepThreeAdd, registerStepThreeLoad, registerStepThreeRemove } from '../actions/registerStepThree.actions';
import { RegisterstepthreeService } from '../../services/new/registerStepThree.service';

@Injectable()
export class RegisterstepthreeEffects {
  load$ = createEffect(() => this.actions$.pipe(
    ofType(registerStepThreeLoad),
    mergeMap(() => this.registerStepThreeService.getRegisterstepthree().pipe(
      map(registerStepThree => ({ type: RegisterstepthreeActions.LoadSuccess, payload: registerStepThree })),
      catchError(() => of({ type: RegisterstepthreeActions.LoadError }))
    ))
  ));
  add$ = createEffect(() => this.actions$.pipe(
    ofType(registerStepThreeAdd),
    mergeMap((action) => this.registerStepThreeService.addRegisterstepthree(action.registerStepThree).pipe(
      map(registerStepThree => ({ type: RegisterstepthreeActions.AddSuccess, payload: registerStepThree })),
      catchError(() => of({ type: RegisterstepthreeActions.AddError }))
    ))
  ));
  remove$ = createEffect(() => this.actions$.pipe(
    ofType(registerStepThreeRemove),
    mergeMap((action) => this.registerStepThreeService.removeRegisterstepthree().pipe(
      map(registerStepThree => ({ type: RegisterstepthreeActions.RemoveSuccess, payload: registerStepThree })),
      catchError(() => of({ type: RegisterstepthreeActions.RemoveError }))
    ))
  ));

  constructor(
    private actions$: Actions,
    private registerStepThreeService: RegisterstepthreeService
  ) { }
}
