import { Component, Input } from '@angular/core';
import { CheckoutSessionResponse, IAccountContactQuote } from '../../../shared/interfaces/new/register-data';
import { PaymentService } from '../../../core';
import { SnackBarClass } from '../../../shared/classes/snackbar.class';
import { BundleNamesAus } from '../../../shared/components/select-membership/select-membership.component';
import { AccountContact, IAccountContactQuoteResponse, QuoteResponse } from '../../../shared';
import { Environment, IEnvironment } from '../../../shared/classes/environment';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { selectCurrentUserState } from '../../../core/ngrx/selectors/currentUser.selectors';
import { CurrentUserState } from '../../../core/ngrx/reducers/currentUser.reducer';
import { Store } from '@ngrx/store';
import { getCompanySummary } from '../../../core/ngrx/selectors/company-details.selectors';
import { CompanySummary } from '../../../shared/interfaces/company-summary';
import { RegisterCoreService } from '../../../core/services/register-core.service';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-membership-foundation-summary',
  templateUrl: './membership-foundation-summary.component.html',
  styleUrls: ['./membership-foundation-summary.component.scss'],
})
export class MembershipFoundationSummaryComponent {
  @Input() selectedProduct: QuoteResponse;
  public opportunity: IAccountContactQuoteResponse;
  public checkoutSession: CheckoutSessionResponse;
  public account$: Observable<any> = this.store.select(getCompanySummary);
  public companySummary: CompanySummary;
  public processingPayment = false;
  public termsAndConditions = false;
  public loading: boolean;
  public discountCode: string;
  public referenceCodeValidating: boolean;
  public referenceCodeAttempted: boolean;
  public referenceCodeValid: boolean;

  private config: IEnvironment;
  private readonly currentUser$ = this.store.select(selectCurrentUserState);
  private currentUser: CurrentUserState;
  private currentUserSubscription: Subscription;

  constructor(
    private readonly paymentService: PaymentService,
    private readonly snackbarClass: SnackBarClass,
    private readonly store: Store,
    private readonly registerService: RegisterCoreService
  ) {}

  async ngOnInit(): Promise<void> {
    const environment: Environment = new Environment();
    this.config = await environment.getConfig();

    this.currentUserSubscription = this.currentUser$.subscribe(async (data) => {
      this.currentUser = data;

      if (this.currentUser) {
        this.companySummary = await firstValueFrom(this.account$);
        this.loading = false;
      }
    });
  }

  public async payAndRedirect(): Promise<void> {
    try {
      const quote = this.opportunity.quotes[0];

      let opportunityObject: any = {
        name: quote.bundleName,
        accountId: this.opportunity.accountId,
        bundleId: quote.result.productId,
        price: quote.result.totalAmount,
        assessmentType: quote.assessmentType,
        variant: quote.applicationType,
        employeenum: quote.request.numberOfEmployees,
        NumberofDirectEmployees: quote.request.directEmployees,
        LabourOnlySubContractors: quote.request.labourOnlySubContractors,
        BonafideSubContractors: quote.request.bonafideSubContractors,
        fastTrack: quote.request.fastTrack,
        fastTrackFee: quote.result.fastTrackAmount,
        callbackSuccessUrl: quote.request.successUrl,
        callbackCancelUrl: quote.request.cancelUrl,
        IsdataSharingAllowed: quote.request.dataSharingAllowed,
      };

      this.processingPayment = true;
      this.opportunity = await this.constructQuote();
      await this.constructCheckoutSession();
      await this.paymentService.upgradeMembershipStripe(this.checkoutSession, opportunityObject);
    } catch (e) {
      this.snackbarClass.displaySnackbar(e, 'x');
      console.error(e);
    } finally {
      this.processingPayment = false;
    }
  }

  public async validateReferenceCode(): Promise<void> {
    if (!this.discountCode || this.discountCode === '') return;

    this.referenceCodeAttempted = false;
    this.referenceCodeValidating = true;
    this.opportunity = await this.constructQuote();
    this.referenceCodeValidating = false;
    this.referenceCodeAttempted = true;
    const selectedQuote = this.opportunity.quotes.find((x) => x.result.productName == this.selectedProduct.result.productName);
    this.referenceCodeValid = selectedQuote.result.discountCodeValid;
    this.selectedProduct = selectedQuote;
  }

  private async constructQuote(): Promise<IAccountContactQuoteResponse> {
    let account = {
      abNumber: this.currentUser.companyDetails.abNumber,
      acNumber: this.currentUser.companyDetails.acNumber,
      assessmentType: undefined,
      BonafideSubContractors: this.currentUser.companyDetails.numberOfBonaFideSubcontractors,
      companyHouseNo: this.currentUser.currentAccount.companyHouseNo,
      companyName: this.currentUser.currentAccount.accountName,
      customerType: this.currentUser.currentAccount.userType,
      isAusGstRegistered: undefined,
      LabourOnlySubContractors: this.currentUser.companyDetails.numberOfLabourOnlySubcontractors,
      NoOfEmployees: this.currentUser.companyDetails.numberOfEffectiveEmployees,
      variant: undefined,
      NumberofDirectEmployees: this.currentUser.companyDetails.numberOfDirectEmployees,
      tradingAs: this.currentUser.companyDetails.tradingName,
    };

    let accountContact: AccountContact = this.currentUser.contacts.find((x) => x.isPrimaryContact);

    let contact = {
      firstName: accountContact.firstname,
      lastName: accountContact.lastname,
      emailAddress: accountContact.email,
      telephone1: accountContact.telephone1,
      mobile1: accountContact.mobilephone,
      password: accountContact.password,
    };

    let quote = {
      BundleNames: [BundleNamesAus.VerifiedContractor, BundleNamesAus.Elite],
      accountId: this.companySummary.companyAccountID,
      assessmentType: undefined,
      applicationType: '885570000',
      cancelUrl: `${this.config.urls.environmentUrl}/membership/foundation?index=0`,
      customerType: '870520001',
      dataSharingAllowed: false,
      discountCode: this.discountCode,
      fastTrack: false,
      firFee: false,
      joiningFee: false,
      opportunityId: null,
      priceLevelId: '',
      sectorType: '885570006',
      selectedBundle: this.selectedProduct.result.productName,
      successUrl: `${this.config.urls.environmentUrl}/membership/foundation?index=2`,
    };

    const quoteObject: IAccountContactQuote = {
      account: account,
      address: this.companySummary.correspondenceAddress,
      clickDimensionId: '',
      contact: contact,
      opportunityId: '',
      quote: quote,
    };

    return await this.registerService.createAndGetQuotesAsync(quoteObject);
  }

  private async constructCheckoutSession(): Promise<void> {
    try {
      if (this.opportunity) {
        const request = {
          accountId: this.opportunity.accountId,
          opportunityId: this.opportunity.opportunityId,
          recreate: false,
          manualPayment: false,
          isDatashareAllowed: false,
          successUrl: `${this.config.urls.environmentUrl}/membership/foundation?index=2`,
          cancelUrl: `${this.config.urls.environmentUrl}/dashboard`,
        };
        this.checkoutSession = await this.paymentService.createCheckoutSession(request);
      }
    } catch (error) {
      console.log(`An error occurred -> constructCheckoutSession(): ${error.message}`);
    }
  }

  public getReferenceCodeStatus(): string {
    return this.referenceCodeValid ? 'valid' : 'invalid';
  }

  protected readonly BundleNamesAus = BundleNamesAus;
}
