export const productCodeTypes = {
  FULLAPPLICATION: "885570000",
  DTS: "885570002",
  ASSURED: "885570005",
  ONSITE: "885570004",
  CONSTRUCTION: "885570003",
  PRINCIBLECONTRACTOR: "885570000",
  DESIGNER: "885570002",
  PRINCIBLEDESIGNER: "885570001",
  NONCONSTRUCTION: "885570004",
  CARE: "885570005",
  FIR: "885570006"
};
