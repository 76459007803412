import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { first, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RepositoryService } from '../../../core/services/repository.service';
import { BranchAddress } from '../../index';

@Component({
  selector: 'app-branch-addresses-modal',
  templateUrl: './branch-addresses-modal.component.html',
  styleUrls: ['./branch-addresses-modal.component.scss']
})
export class BranchAddressesModalComponent implements OnInit {
  // Reactive form for the branch addresses.
  branchesForm = new UntypedFormGroup({
    addresses: new UntypedFormArray([])
  });

  accountId: string;
  isLoading: boolean;
  isSaving: boolean;
  error: string;

  constructor(public bsModalRef: BsModalRef, private Repository: RepositoryService) {}

  ngOnInit() {
    if (this.accountId) {
      this.fetchBranchAddresses();
    }
  }

  /**
   * Closes the dialogue/modal window.
   */
  close(): void {
    this.bsModalRef.hide();
  }

  /**
   * Obtain the branch addresses associated with the account.
   */
  fetchBranchAddresses(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.Repository.getBranchAddresses(this.accountId)
      .pipe(
        first(),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: data => this.populateBranchAddresses(data),
        error: (response: HttpErrorResponse) => this.error = response.error.Message
      });
  }

  /**
   * Update the branch addresses associated with an account.
   */
  updateBranchAddresses(): void {
    if (this.isSaving) {
      return;
    }

    const rows = this.branchesForm.value.addresses;
    this.isSaving = true;
    this.error = null;
    this.branchesForm.disable();
    this.Repository.updateBranchAddresses(this.accountId, rows)
      .pipe(
        first(),
        finalize(() => {
          this.isSaving = false;
          this.branchesForm.enable();
        })
      )
      .subscribe({
        next: () => this.close(),
        error: (response: HttpErrorResponse) => this.error = response.error.Message
      });
  }

  /**
   * Add a blank address row to the list.
   */
  addBranchAddress(): void {
    const addresses = this.branchesForm.get('addresses') as UntypedFormArray;
    addresses.push(this.createAddressRow());
  }

  /**
   * Remove and delete an address from the list.
   */
  removeBranchAddress(index: number) {
    const list = this.branchesForm.get('addresses') as UntypedFormArray;
    const group = list.at(index);
    group.disable();
    this.Repository.removeBranchAddress(this.accountId, group.value)
      .pipe(
        first(),
        finalize(() => group.enable())
      )
      .subscribe(() => {
        list.removeAt(index);
      });
  }

  /**
   * Populate the form with a list of existing addresses.
   */
  populateBranchAddresses(addresses: BranchAddress[]) {
    const addressesArray = this.branchesForm.get('addresses') as UntypedFormArray;
    addresses.forEach(address => {
      const row = this.createAddressRow();
      addressesArray.push(row);
      row.patchValue(address);
    });
  }

  /**
   * Create a blank address row.
   */
  private createAddressRow(): UntypedFormGroup {
    // TODO confirm validation requirements.
    return new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      line1: new UntypedFormControl('', [Validators.required]),
      line2: new UntypedFormControl(''),
      line3: new UntypedFormControl(''),
      city: new UntypedFormControl('', [Validators.required]),
      postalcode: new UntypedFormControl('', [Validators.required]),
      customeraddressid: new UntypedFormControl('')
    });
  }

}
