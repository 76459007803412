<div class="text-center" *ngIf="showLoadingSpinner">
  <app-loading-spinner></app-loading-spinner>
</div>
<div *ngIf="!isLoading">
  <h4 *ngIf="verificationCount.timeRemaining">Contractor response required by: {{verificationCount.timeRemaining | date}}</h4>
  <h4 *ngIf="assessmentCount.timeRemaining">Contractor response required by: {{assessmentCount.timeRemaining | date}}</h4>
</div>

<div *ngIf="!showLoadingSpinner && renewalCopyInProgress">
  <h4>{{ assessmentDetails.name }}</h4>
  <p>
    This assessment has recently been renewed. Please wait while the system copies your previous responses.<br/>
    This process usually takes around 5 minutes.
  </p>
  <p>
    <button (click)="reloadQuestionSet()" class="btn btn-primary">Reload</button>
  </p>
</div>

<div *ngIf="!showLoadingSpinner && !renewalCopyInProgress">
  <app-response-countdown [time]="timeToRespond"></app-response-countdown>
  <alert type="danger" *ngIf="error">{{ error }}</alert>
  <app-questionset-surveyjs #surveyJsComponent *ngIf="jsonData"
                            [questionsetData]="jsonData"
                            [questionSetAnswers]="answers"
                            [clientId]="clientId"
                            [assessmentId]="assessmentId"
                            [authUser]="authUser"
                            [account]="account"
                            [bundles]="bundles"
                            [roles]="userRoles"
                            [companyDetails]="companyDetails"
                            [assessmentState]="getAssessmentState"
                            [showCheckAnswersWarning]="clonedState"
                            [isCasAssessment]="isCasAssessment">
  </app-questionset-surveyjs>
</div>
