import * as AppState from '../../../app.state'
import { createReducer, on } from "@ngrx/store";
import { CourseProgress } from 'src/app/shared/interfaces/course';
import * as CourseActions from '../actions/course.actions';

export interface State extends AppState.State{
    course: CourseState;
}

export interface CourseState {
    courseProgress: CourseProgress[],
    loading: boolean,
    error: string
}


const initialState: CourseState = {
    courseProgress: [],
    loading: true,
    error: ''
}

export const courseReducer = createReducer<CourseState>(
    initialState,

    on(CourseActions.loadCourseProgress, (state, action): CourseState => {
        return {
            ...state,
            loading: true,
            error: ''
        };
    }),

    on(CourseActions.loadCourseProgressSuccess, (state, action): CourseState => {
        return {
            ...state,
            courseProgress: action.courseProgress,
            loading: false,
            error: ''
        };
    }),

    on(CourseActions.loadCourseProgressFailure, (state, action): CourseState => {
        return {
            ...state,
            loading: false,
            error: action.error
        };
    }),

    on(CourseActions.updateCourseProgressSuccess, (state, action): CourseState => {
        return {
            ...state,
            error: ''
        };
    }),

    on(CourseActions.updateCourseProgressFailure, (state, action): CourseState => {
        return {
            ...state,
            error: action.error
        };
    }),
);
