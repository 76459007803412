import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { CompaniesHouseCompanyDetails } from '../..';
import { ISOCountry } from '../../interfaces/iso-country';
import { ICompanySearch } from '../../interfaces/company-details-step';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent {
  @Input() public title: string;
  @Input() public name: string;
  @Input() public borderBottom?: boolean = false;
  @Input() public description?: string;
  @Input() public type?: string = 'text';
  @Input() public width?: string;
  @Input() public control?: UntypedFormControl;
  @Input() public items?: Array<any>;
  @Input() public tooltip?: string;
  @Input() public optionValueKey?: string = 'id';
  @Input() public optionNameKey?: string = 'name';
  @Input() public readonly: boolean = false;
  @Input() public showLabel: boolean = false;
  @Input() public showError: boolean = true;
  @Input() public block: boolean = true;
  @Input() public showTitle: boolean = true;
  @Input() public companyHouseSearch: boolean = false;
  @Input() public isCompanyPrePopulated: boolean = false;
  @Input() public countries?: ISOCountry;
  @Input() public disabled: boolean = false;
  @Input() public refCode: boolean = false;
  @Output() public found = new EventEmitter<ICompanySearch>();
  @Output() public onValidate = new EventEmitter<any>();
  public filteredCompanies$: Observable<CompaniesHouseCompanyDetails[]>;
  public hasError = false;

  public onCompany(company: ICompanySearch): void {
    this.found.emit(company);
  }

  public validate(): void {
    this.onValidate.emit(null);
  }
}
