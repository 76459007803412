import { CompaniesHouseCompanyDetails } from './companies-house-company-details';

export interface CompaniesHouseSearchResults {
  start_index: number;
  total_results: number;
  items: CompaniesHouseCompanyDetails[];
  page_number: number;
  items_per_page: number;
  kind: string;
}
