import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Bootstrap components, provided by the ngx-bootstrap package.
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';

// Configure the Datepicker controls (in ngx-bootstrap) to use British-formatted dates.
defineLocale('en-gb', enGbLocale);

@NgModule({
  imports: [
    CommonModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    TabsModule.forRoot()
  ],
  exports: [
    AlertModule,
    AccordionModule,
    BsDatepickerModule,
    BsDropdownModule,
    CollapseModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    TooltipModule,
    PopoverModule,
    TabsModule
  ]
})
export class BootstrapComponentsModule {}
