import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { first, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ContactsService } from '../../../core/services/contacts.service';
import { RolesMatrixComponent } from './roles-matrix/roles-matrix.component';
import { ContactRole, AccountContact, Regex, UpdateContactParameters } from '../../index';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { LoadingService } from '../../../core/services/loading-account.service';
import defaultRolesJson from '../../../../assets/json/default-roles.json';
import { Store } from '@ngrx/store';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';

// This component will display information about a specific user.
// NOTE: this component had to be added to the entryComponents array of the admin users module.

@Component({
  selector: 'app-user-information-modal',
  templateUrl: './user-information-modal.component.html',
  styleUrls: ['./user-information-modal.component.scss'],
})
export class UserInformationModalComponent implements OnInit {
  @ViewChild('modalUserInformation') modal: TemplateRef<any>;
  @ViewChild('rolesMatrix') rolesMatrix: RolesMatrixComponent;
  data: CurrentUserState;
  isResettingPassword: boolean;
  isLoadingRoles: boolean;
  isSavingRoles: boolean;
  error: string;
  saving: boolean;
  contact: AccountContact;
  contactRoles: ContactRole[];
  resetError: string;
  resetSuccess: boolean;
  userForm: UntypedFormGroup;
  onSave: () => void; // a reference to a method to call if a new contact is created.

  constructor(public bsModalRef: BsModalRef, private Contacts: ContactsService, public router: Router, private loading: LoadingService) {}

  ngOnInit() {
    this.getContactRoles();
    this.userForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(this.contact.firstname, [Validators.required]),
      lastName: new UntypedFormControl(this.contact.lastname, [Validators.required]),
      email: new UntypedFormControl(this.contact.email, [Validators.required, Validators.email]),
      telephone: new UntypedFormControl(this.contact.telephone, [Validators.pattern(Regex.PHONE)]),
      position: new UntypedFormControl(this.contact.position),
    });
  }

  /**
   * Fetches permissions for the current user.
   */
  get permissions() {
    /**
     * if contact is equal to current user get current users permission
     * if current user is viewing another users user management modal they are an admin
     */
    return this.data.currentAccount.contactId === this.contact.contactId
      ? this.data.roles.find((role) => role.name === 'Administrator').privilege
      : 'Full Access';
  }

  /**
   * Closes the dialogue/modal window without saving.
   */
  close(): void {
    this.bsModalRef.hide();
  }

  /**
   * Fetches a list of role privileges for the contact.
   */
  getContactRoles(): void {
    if (this.isLoadingRoles) {
      return;
    }

    this.isLoadingRoles = true;
    this.Contacts.getRoles(this.contact.contactId)
      .pipe(
        first(),
        finalize(() => {
          this.isLoadingRoles = false;
        })
      )
      .subscribe({
        next: (roles) => (roles.length > 0 ? (this.contactRoles = roles) : (this.contactRoles = defaultRolesJson)),
        error: (response: HttpErrorResponse) => (this.error = response.error.Message),
      });
  }

  /**
   * Updates role privileges for the contact.
   */
  updateRoles(): void {
    if (this.isSavingRoles) {
      return;
    }

    // Obtain the form values from the roles matrix component.
    const rows = this.rolesMatrix.rolesForm.get('roles').value;

    // Save the updated privileges.
    this.rolesMatrix.rolesForm.disable();
    this.isSavingRoles = true;
    this.error = null;
    this.Contacts.updateRoles(this.contact.contactId, rows)
      .pipe(
        first(),
        finalize(() => {
          this.isSavingRoles = false;
          this.rolesMatrix.rolesForm.enable();
          this.router.navigateByUrl('/admin/users').catch((err) => {
            console.error(err);
          });
        })
      )
      .subscribe({
        next: () => this.close(),
        error: (response: HttpErrorResponse) => (this.error = response.error.Message),
      });
  }

  /**
   * Updates an new contact, assigning the respective roles.
   */
  async updateContact(): Promise<any> {
    if (this.saving || this.userForm.invalid) {
      return;
    }

    // Create parameters for creating a new contact.
    const params: UpdateContactParameters = {
      ContactId: this.contact.contactId,
      AccountId: this.data.currentAccount.accountId,
      FirstName: this.userForm.value.firstName,
      LastName: this.userForm.value.lastName,
      Email: this.userForm.value.email,
      Telephone: this.userForm.value.telephone,
      Position: this.userForm.value.position,
    };
    // Obtain the form values from the roles matrix component.
    const rows = this.rolesMatrix.rolesForm.get('roles').value;
    const roles = this.rolesMatrix.rolesForm.get('roles');

    // First create the contact, then assign that contact the roles.
    this.userForm.disable();
    this.rolesMatrix.rolesForm.disable();
    this.saving = true;
    this.error = null;

    try {
      this.loading.changeContactsLoading(true);
      await this.Contacts.updateContactAsync(params);
      await this.Contacts.updateRolesAsync(this.contact.contactId, rows);

      this.saving = false;
      this.userForm.enable();
      this.rolesMatrix.rolesForm.enable();

      if (this.onSave) this.onSave();
      this.close();
    } catch (error) {
      console.error(error.Message);
      this.error = error.Message;
    }
  }
}
