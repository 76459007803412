import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nothing',
  templateUrl: './nothing.component.html',
  styleUrls: ['./nothing.component.scss']
})
export class NothingComponent {
  constructor() { }
}
