import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accreditationColourPipe',
})
export class AccreditationColourPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'CHAS_Standard':
      case 'Standard':
        return 'standard';
      case 'CHAS_Advanced':
      case 'Advanced':
        return 'advanced';
      case 'CHAS_Elite':
      case 'Elite':
        return 'elite';
      case 'CHAS_Foundation':
      case 'Foundation':
        return 'foundation';
      case 'CHAS_Supplier':
      case 'Supplier':
        return 'supplier';
      case 'CHAS_Verified_Supplier':
      case 'Verified_Supplier':
      case 'Verified Supplier':
        return 'verified-supplier';
      case 'CHAS_Verified_Contractor':
      case 'Verified_Contractor':
      case 'Verified Contractor':
        return 'verified-contractor';
      case 'Common_Assessment_Standard':
      case 'Common Assessment Standard':
      case 'CHAS_Common_Assessment_Standard':
        return 'common-assessment-standard';
      case 'None':
      case 'CHAS_None':
      case 'No Membership':
      case 'CHAS_NoMembership':
      case 'No_Membership':
        return 'no-membership';
      case 'Any Membership':
      case 'CHAS_AnyMembership':
      case 'Any_Membership':
        return 'any-membership';
      default:
        return 'unregistered';
    }
  }
}
