<ng-content></ng-content>

<div class="grid">
  <app-fir-card
    [productNumber]="cards.Individual.id"
    [name]="cards.Individual.name"
    [headerImage]="cards.Individual.headerImage"
    [headerTitle]="cards.Individual.headerTitle"
    [isSelected]="selectedMembership.id === cards.Individual.id"
    (selected)="onSelected($event)">
    <app-product-advantages-list [tickedItems]="cards.Individual.tickedItems" [hiddenItems]="cards.Individual.hiddenItems"></app-product-advantages-list>
    <div class="block">
      <div class="block__title">
        Prices starting from:
      </div>
      <div class="block__value">
        {{ cards.Individual.price }}
      </div>
    </div>
  </app-fir-card>
  <app-fir-card
    [productNumber]="cards.Standard.id"
    [name]="cards.Standard.name"
    [headerImage]="cards.Standard.headerImage"
    [headerTitle]="cards.Standard.headerTitle"
    [isSelected]="selectedMembership.id === cards.Standard.id"
    (selected)="onSelected($event)">
    <app-product-advantages-list [tickedItems]="cards.Standard.tickedItems" [hiddenItems]="cards.Standard.hiddenItems"></app-product-advantages-list>
    <div class="block">
      <div class="block__title">
        Prices starting from:
      </div>
      <div class="block__value">
        {{ cards.Standard.price | currency: 'GBP' }}
      </div>
    </div>
  </app-fir-card>
  <app-fir-card
    [productNumber]="cards.Advanced.id"
    [name]="cards.Advanced.name"
    [headerImage]="cards.Advanced.headerImage"
    [headerTitle]="cards.Advanced.headerTitle"
    [isSelected]="selectedMembership.id === cards.Advanced.id"
    (selected)="onSelected($event)">
    <app-product-advantages-list [unTickedItems]="cards.Advanced.unTickedItems"></app-product-advantages-list>
    <div class="block">
      <div class="block__title">
        Prices starting from:
      </div>
      <div class="block__value">
        {{ cards.Advanced.price | currency: 'GBP' }}
      </div>
    </div>
  </app-fir-card>
  <app-fir-card
    [productNumber]="cards.Elite.id"
    [name]="cards.Elite.name"
    [headerImage]="cards.Elite.headerImage"
    [headerTitle]="cards.Elite.headerTitle"
    [isSelected]="selectedMembership.id === cards.Elite.id"
    (selected)="onSelected($event)">
    <app-product-advantages-list [tickAll]="cards.Elite.tickAll"></app-product-advantages-list>
    <div class="block">
      <div class="block__title">
        Prices starting from:
      </div>
      <div class="block__value">
        {{ cards.Elite.price | currency: 'GBP' }}
      </div>
    </div>
  </app-fir-card>
</div>
