<!-- Search field. -->
<form class="input-group" [formGroup]="fgSearch" (submit)="search()" *ngIf="!readOnly">
  <input type="text" class="form-control" [id]="fieldID" formControlName="term" [attr.aria-describedby]="buttonID" />
  <div class="input-group-append">
    <button class="btn btn-secondary" type="submit" [id]="buttonID" [disabled]="fgSearch.invalid"
      [class.is-loading]="isSearching" title="Search">
      <i class="fa fa-fw fa-search"></i>
    </button>
  </div>
</form>

<!-- Display the currently selected company name. -->
<div class="text-danger" *ngIf="error">
  <small>{{ error }}</small>
</div>

<div class="row mt-2" *ngIf="details">
  <div class="col-sm-10">{{ details.name }}</div>
  <div class="col-sm-2" *ngIf="!readOnly">
    <!-- Remove button. -->
    <button class="btn btn-link" type="button" (click)="clear()" [disabled]="isDisabled">
      <i class="fa fa-fw fa-times"></i>
    </button>
  </div>
</div>
