import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentsPageComponent } from './documents.component';
import { BootstrapComponentsModule } from 'src/app/shared/components/bootstrap/bootstrap.module';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { RouterModule, Routes } from '@angular/router';
import { ListViewComponent } from './views/list-view/list-view.component';
import { PipesModule } from '../../shared/pipes/pipes.module';

const routes: Routes = [
  { path: '', component: DocumentsPageComponent }
]

@NgModule({
  declarations: [DocumentsPageComponent, ListViewComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BootstrapComponentsModule,
    LoadingSpinnerModule,
    PipesModule,
    RouterModule.forChild(routes),
  ],
  exports: [DocumentsPageComponent]
})
export class DocumentsPageModule { }
