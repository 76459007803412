import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AssessmentPageInfo } from 'src/app/shared/interfaces/assessment-page-info';

@Injectable({
  providedIn: 'root'
})
export class AssessmentMenuService {
  private selectedAssessmentPages: BehaviorSubject<AssessmentPageInfo[]>;
  private currentAssessmentPage: BehaviorSubject<AssessmentPageInfo>;
  private currentAssessmentId: string;

  private sectionSelected: BehaviorSubject<boolean>;

  constructor() {
    this.selectedAssessmentPages = new BehaviorSubject<AssessmentPageInfo[]>([]);
    this.currentAssessmentPage = new BehaviorSubject<AssessmentPageInfo>(null);
    this.currentAssessmentId = null;
    this.sectionSelected = new BehaviorSubject<boolean>(true);
  }

  public getSelectedAssessmentPagesSubscription(): BehaviorSubject<AssessmentPageInfo[]> {
    return this.selectedAssessmentPages;
  }

  public getCurrentAssessmentPage(): BehaviorSubject<AssessmentPageInfo> {
    return this.currentAssessmentPage;
  }

  public setSelectedAssessmentPages(pages: AssessmentPageInfo[]): void {
    this.selectedAssessmentPages.next(pages);
  }

  public setCurrentAssessmentPage(page: AssessmentPageInfo): void {
    this.currentAssessmentPage.next(page);
  }

  public setCurrentAssessmentId(assessmentId: string): void {
    this.currentAssessmentId = assessmentId;
  }

  public getCurrentAssessmentId(): string {
    return this.currentAssessmentId;
  }

  public setSectionSelected() {
    this.sectionSelected.next(false);
  }

  public getSectionSelected() {
    return this.sectionSelected;
  }

}

