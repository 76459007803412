import { Component, OnInit, forwardRef, Output, EventEmitter, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { first, catchError, finalize, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {CompanyLookup} from '../../index';
import {CompaniesService} from '../../../core/services/companies.service';

// This component can be used as a form control, within a reactive form.
// https://javascript-conference.com/blog/angular-reactive-forms-building-custom-form-controls/

let nextID = 0;

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CompanySearchComponent),
    multi: true
  }]
})
export class CompanySearchComponent implements ControlValueAccessor {
  @Output() found = new EventEmitter<CompanyLookup>();
  @Output() cleared = new EventEmitter();
  @Input() id = nextID++;
  @Input() readOnly: boolean;

  fgSearch = new UntypedFormGroup({
    term: new UntypedFormControl('', [Validators.required])
  });
  isSearching: boolean;
  details: CompanyLookup;
  error: string;

  private disabled: boolean;
  private onChange: Function;
  private onTouched: Function;

  constructor(private companiesService: CompaniesService) {
    this.onChange = (_: CompanyLookup) => { };
    this.onTouched = () => { };
    this.disabled = false;
    this.details = null;
  }

  writeValue(obj: CompanyLookup): void {
    this.details = obj;
    this.onChange(this.details);
  }

  registerOnChange(fn: (details: CompanyLookup) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if ( isDisabled ) {
      this.fgSearch.disable();
    } else {
      this.fgSearch.enable();
    }
  }

  get isDisabled(): boolean {
    return this.disabled;
  }

  search(): void {
    if (this.isSearching) {
      return;
    }

    const term = this.fgSearch.value.term;
    // we have to obtain this value before disabling the field, it seems.

    this.fgSearch.disable();
    this.isSearching = true;
    this.error = null;

    this.findMatchingCompany(term)
      .pipe(first(), finalize(() => {
        this.fgSearch.enable();
        this.isSearching = false;
      }))
      .subscribe(matchingCompany => {
        if (matchingCompany) {
          this.fgSearch.setValue({ term: null });
          this.setMatchingCompany(matchingCompany.id, matchingCompany.name, matchingCompany.companyNumber);
          this.onTouched();
          this.found.emit(this.details);
        } else {
          this.error = 'No matching company found.';
        }
      });
  }

  /**
   * Set the control's company search details.
   */
  setMatchingCompany(id: string, name: string, companyNumber: string): void {
    if ( id && name) {
      this.details = {id, name, companyNumber};
      this.writeValue(this.details);
    }
  }

  /**
   * Search Companies House and return the first matching company details.
   * @param term
   */
  findMatchingCompany(term: string): Observable<CompanyLookup | null> {
    if (!term) {
      return of(null);
    }

    return this.companiesService.lookupCompany(term.trim()).pipe(
      map(results => results),
      catchError(() => of(null))
    );
  }

  /**
   * Remove information about the immediate parent company.
   */
  clear(): void {
    this.fgSearch.setValue({ term: null });
    this.details = null;
    this.writeValue(this.details);
    this.onTouched();
    this.cleared.emit();
  }

  get buttonID(): string {
    return `csTermSearch-${this.id}`;
  }

  get fieldID(): string {
    return `csTerm-${this.id}`;
  }
}
