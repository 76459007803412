export enum GtmEvent {
  pageMetaData = 'pageMetaData',
  contractorRegistration = 'contractorRegistration',
  requestCallback = 'requestCallBack',
  video = 'video',
  completeAssessment = 'completeAssessment',
  redeemCode = 'redeemCode',
  viewList = 'view_item_list',
  viewItem = 'select_item',
  addToCart = 'add_to_cart',
  checkout = 'checkout',
  orderConfirmation = 'purchase'
}
export enum GtmUserStatus {
  LoggedIn = 'loggedIn',
  LoggedOff = 'loggedOff'
}

export enum GtmUserCategory {
  Contractor = 'contractor',
  Client = 'client',
  Assessor = 'assessor'
}

export enum GtmAssessmentDashboardOptions {
  dashboard = 'CHAS Dashboard'
}

export enum GtmBrand {
  CHAS = 'CHAS'
}

export enum GtmListTypes {
  signup = 'signup'
}

export enum GtmCurrency {
  EUR = 'EUR',
  GBP = 'GBP'
 }
