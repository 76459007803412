
export interface AssessmentPageInfo {
  id: string;
  title: string;
  complete: boolean;
  hasErrors: boolean;
  responseRequired: boolean;
  requestForMoreInfo: boolean;
}


