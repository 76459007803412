import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountContact, CheckNewAuthenticationResponse, ContactRole } from '../../../index';
import { Subject } from 'rxjs/internal/Subject';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';
import { MsalService } from '@azure/msal-angular';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { ProductName } from 'src/app/shared/classes/productName.class';

@Component({
  selector: 'app-user-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
})
export class AccountMenuComponent implements OnInit, OnDestroy {
  currentUser: CheckNewAuthenticationResponse;
  contact: AccountContact;
  isLoading: boolean;
  error: string;
  private $destroyed = new Subject<void>();
  enableDataSharingOption: boolean;
  dataSharingOptionOn = false;
  private readonly data$: Observable<CurrentUserState> = this.store.select(selectCurrentUserState);
  roles: ContactRole[];
  private config: IEnvironment;

  constructor(private readonly authService: MsalService, private readonly store: Store) {}

  async ngOnInit() {
    this.enableDataSharingOption = false;

    const environment = new Environment();
    this.config = await environment.getConfig();

    this.data$.subscribe((data) => {
      if (data.loaded) {
        this.currentUser = data.currentAccount;
        this.contact = data.contacts.filter((data) => data.contactId === this.currentUser.contactId)[0];
        const bundle = data.bundles[0];
        this.roles = data.roles;

        if (bundle && bundle.bundleName.toLowerCase().indexOf(ProductName.level4.toLowerCase()) > -1) {
          this.enableDataSharingOption = true;
        }
      }
    });
  }

  ngOnDestroy() {
    this.$destroyed.next();
  }

  get getUserPermission() {
    return this.currentUser ? this.roles.find((user) => user.name === 'Administrator').privilege : null;
  }

  async resetPassword() {
    window.location.href = `${this.config.msal.resetUrl}&client_id=${this.config.msal.auth.clientId}&nonce=defaultNonce&redirect_uri=${this.config.msal.auth.redirectUri}&scope=openid%20${this.config.guard.authRequest.scopes[1]}&response_type=id_token%20token&prompt=login`;
  }
}
