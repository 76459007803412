import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AssessmentsService } from '../../../core/services/assessments.service';
import { combineLatest, Subject } from 'rxjs';
import { first } from 'rxjs/internal/operators/first';
import { finalize } from 'rxjs/internal/operators/finalize';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountResponse, CheckAuthenticationResponse, CheckNewAuthenticationResponse } from '../../index';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-document-modal',
  templateUrl: './document-modal.component.html',
  styleUrls: ['./document-modal.component.scss'],
})
export class DocumentModalComponent implements OnInit, OnDestroy {
  @ViewChild('modalPrivacy') modal: TemplateRef<any>;
  authUser: CheckNewAuthenticationResponse;
  assessmentId: string;
  notificationId: string;
  questionsetData: any;
  answers: any;
  isLoading: boolean;
  questionSetNo: string;
  questionNo: string;
  error: string;
  clientId: string;
  dismissNotificationFn: any;
  account: AccountResponse;
  private $destroyed = new Subject<void>();
  private readonly data$ = this.store.select(selectCurrentUserState);

  constructor(public bsModalRef: BsModalRef, private assessments: AssessmentsService, private readonly store: Store) {}

  ngOnInit(): void {
    this.data$.subscribe((data) => {
      if (data.loaded) {
        this.authUser = data.currentAccount;

        this.loadData(this.assessmentId, this.questionSetNo, this.questionNo);
      }
    });
  }

  ngOnDestroy() {
    this.$destroyed.next();
  }

  //Close modal
  close(): void {
    this.bsModalRef.hide();
  }

  //Get document expired related question and answers
  loadData(assessmentId: string, questionSetNo: string, questionNo: string) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.clientId = this.authUser.accountId;

    combineLatest([
      this.assessments.getQuestionSetAnswers(this.clientId, assessmentId),
      this.assessments.getQuestionSetSection(this.assessmentId, questionSetNo, questionNo),
    ])
      .pipe(
        first(),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: ([answers, questions]) => {
          this.answers = answers;
          this.questionsetData = questions;
        },
        error: (response: HttpErrorResponse) => {
          this.error = response.error.Message;
        },
      });
  }

  clearNotification(): void {
    if (this.dismissNotificationFn == null) return;
    this.dismissNotificationFn();
  }
}
