import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RegisterCoreService } from 'src/app/core/services/register-core.service';
import { GetQuoteResponse, UserTypeCode } from '../..';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';
import { Environment, IEnvironment } from '../../classes/environment';

@Component({
  selector: 'app-reference-code',
  templateUrl: './reference-code.component.html'
})
export class ReferenceCodeComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  public validatedReferenceCode: string = null;
  public referenceCodeSuccess: boolean = false;
  public referenceCodeError: boolean = false;
  public checkingReferenceCode: boolean = false;
  public referenceForm: UntypedFormGroup = new UntypedFormGroup({
    referenceCode: new UntypedFormControl('', []),
  });
  private config: IEnvironment;

  constructor(
    private readonly gtmService: GtmService,
    private readonly registerCoreService: RegisterCoreService
  ) {
  }

  public async ngOnInit() {
    const env = new Environment();
    this.config = await env.getConfig();

    const referenceCode = this.form.value.referenceCode;

    if(referenceCode) {
      this.referenceForm.patchValue({
        referenceCode: this.form.value.referenceCode
      });

      this.validateReferenceCode();
    }
  }

  public async validateReferenceCode(): Promise<void> {
    const referenceCode: string = this.referenceForm.value.referenceCode;
    if (!referenceCode) {
      return;
    }

    this.validatedReferenceCode = referenceCode;
    this.checkingReferenceCode = true;
    const quote = await this.registerCoreService.getQuote({
      formValue: this.form.value,
      createOp: false,
      referenceCode: this.validatedReferenceCode,
      dataSharingAllowed: false,
      customerType: UserTypeCode.CONTRACTOR
    });
    this.updateReferenceCodeState(quote.lastQuoteResult);

    this.checkingReferenceCode = false;

    this.gtmService.pushTag({
      id: this.config.googleAnalytics.googleTagManagerId,
      event: 'reference_code',
      code: referenceCode
    });
  }

  private updateReferenceCodeState(quote: GetQuoteResponse) {
    if (quote.request.discountCode && quote.result.discountCodeValid) {
      this.referenceCodeSuccess = true;
      this.referenceCodeError = false;
      this.validatedReferenceCode = quote.request.discountCode;
      this.form.patchValue({
        referenceCode: this.validatedReferenceCode
      })
    }
    else if (quote.request.discountCode && !quote.result.discountCodeValid) {
      this.referenceCodeSuccess = false;
      this.referenceCodeError = true;
      this.validatedReferenceCode = null;
    }
  }
}
