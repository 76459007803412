import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import * as CourseActions from "../actions/course.actions";
import { CourseService } from "../../services/course.service";
import { Progress } from "src/app/shared/interfaces/course";
import { Router } from "@angular/router";

@Injectable()
export class CourseEffects {
  constructor(
    private actions$: Actions,
    private courseService: CourseService,
    private router: Router,
  ) { }

  loadCourses$ = createEffect(() => this.actions$.pipe(
    ofType(CourseActions.loadCourseProgress),
    mergeMap((action) => this.courseService.getCourseProgress(action.accountId).pipe(
        map(courseProgress => CourseActions.loadCourseProgressSuccess({courseProgress})),
        catchError((error) => of( CourseActions.loadCourseProgressFailure({ error })))
      )
    )));

  updateCourses$ = createEffect(() => this.actions$.pipe(
    ofType(CourseActions.updateCourseProgress),
    mergeMap((action) => this.courseService.updateCourseProgress(action.courseProgress).pipe(
        map(courseProgress => {
          if (action.courseProgress.state === Progress.Completed) {
            this.router.navigateByUrl('/learning-pathway');
          }
          return CourseActions.updateCourseProgressSuccess({courseProgress})
        },
        catchError((error) => of( CourseActions.updateCourseProgressFailure({ error })))
      ))
    )));
 }
