
import { createReducer, on } from '@ngrx/store';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();
import { getStorage } from 'src/app/shared/functions/getBasket.functions';
import { registerStepThreeAddSuccess, registerStepThreeLoadSuccess, registerStepThreeRemoveSuccess } from '../actions/registerStepThree.actions';

export const initialState = getStorage('registerStepThree');

const _registerStepThreeReducer = createReducer(
  initialState,
  on(registerStepThreeAddSuccess, (state, action) => action.payload),
  on(registerStepThreeLoadSuccess, (state, action) => action.payload),
  on(registerStepThreeRemoveSuccess, (state, action) => action.payload),
);

export function registerStepThreeReducer(state, action) {
  return _registerStepThreeReducer(state, action);
}
