import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { first, finalize, switchMap, concatMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { PrivacyModalComponent } from '../../shared/components/privacy-modal/privacy-modal.component';
import {
  SecuritySettingsModalComponent,
  UserInformationModalComponent,
  UserCreateModalComponent,
  RepositoryService,
  ContactsService,
  LoadingService,
} from '../../core';
import { ContactRole, AccountStatus, AccountContact, CheckNewAuthenticationResponse } from '../../shared';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss'],
})
export class AdminUsersPageComponent implements OnInit, OnDestroy {
  @ViewChild('settingsModal', { static: true }) settingsModal: SecuritySettingsModalComponent;
  @ViewChild('modalConfirmLock', { static: true }) lockModal: TemplateRef<any>;
  @ViewChild('modalConfirmUnlock', { static: true }) unlockModal: TemplateRef<any>;
  @ViewChild('modalConfirmDelete', { static: true }) deleteModal: TemplateRef<any>;

  currentUser: CheckNewAuthenticationResponse;
  currentUserType: string;
  contacts: AccountContact[];
  roles: ContactRole[];
  isLoading: boolean;
  modalRef: BsModalRef;
  contactToLock: AccountContact;
  contactToDelete: AccountContact;
  userSearchForm: UntypedFormGroup;
  userSearchTerm: string;
  error: string;
  isLocking: AccountContact;
  isDeleting: AccountContact;
  direction: string;
  column: string;
  sortIcon: string;
  rowsPerPage: number = 12;
  currentPage: number = 1;
  hasFetchedContacts: boolean = false;
  private readonly data$ = this.store.select(selectCurrentUserState);
  private currentUserState: CurrentUserState;
  private $destroyed = new Subject<void>();

  constructor(
    private contactsService: ContactsService,
    private modalService: BsModalService,
    private activeRoute: ActivatedRoute,
    private Repository: RepositoryService,
    private loading: LoadingService,
    private readonly store: Store
  ) {
    this.error = null;
  }

  // TODO check whether the user has Admin privileges - if not, hide the buttons.

  ngOnInit() {
    this.data$.subscribe({
      next: (data) => {
        if (data.loaded) {
          this.currentUserState = data;
          this.currentUser = data.currentAccount;
          this.currentUserType = this.currentUser.userType;
          this.fetchContacts(this.currentUser.accountId);
        }
      },
      error: (response: HttpErrorResponse) => {
        this.error = response.error.Message;
      },
    });

    this.userSearchForm = new UntypedFormGroup({
      term: new UntypedFormControl(''),
    });
    // subscribe to loading service to fetch contacts on changes made in user modals
    this.loading.isContactsLoading.subscribe((load) => {
      this.isLoading = load;
      if (load) {
        setTimeout(() => {
          this.loading.changeContactsLoading(false);
          this.fetchContacts(this.currentUser.accountId);
        }, 2500);
      }
    });
  }

  ngOnDestroy() {
    this.$destroyed.next();
  }

  pageChanged($event: any): void {
    this.currentPage = $event.page;
  }

  sortBy(column) {
    this.direction = this.direction === 'asc' ? 'desc' : 'asc';
    this.sortIcon = this.direction === 'asc' ? 'up' : 'down';
    this.column = column;
    const nameSort = (d1, d2) => {
      if (d1[column].toUpperCase() > d2[column].toUpperCase()) {
        return this.direction === 'asc' ? 1 : -1;
      }
      if (d1[column].toUpperCase() < d2[column].toUpperCase()) {
        return this.direction === 'asc' ? -1 : 1;
      }
      return 0;
    };
    this.contacts = this.contacts.sort(nameSort);
  }

  /**
   * Fetch a list of contacts associated with the specified account.
   */
  fetchContacts(accountID: string): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    combineLatest([this.contactsService.getContacts(accountID), this.activeRoute.data])
      .pipe(
        first(),
        finalize(() => {
          this.getUserType(this.contacts);
          this.isLoading = false;
        })
      )
      .subscribe({
        next: ([contacts, data]) => {
          this.contacts = contacts;
          // If we came here from the "My Details" link in the user menu, open the dialogue for the current user.
          this.detectShowMyDetails(data);
          this.hasFetchedContacts = true;
        },
        error: (response: HttpErrorResponse) => {
          this.error = response.error.Message;
        },
      });
  }

  /**
   *
   */
  detectShowMyDetails(data: any) {
    if (this.hasFetchedContacts) return;
    let contact;
    if (this.contacts.length > 1) contact = this.contacts.find((c) => c.contactId === this.currentUser.contactId);
    else contact = this.contacts[0];
    if (data.showDialogue === 'users') {
      if (contact) this.openUserInformationModal(contact, this.currentUserState);
    } else if (data.showDialogue === 'privacy') {
      if (contact) this.openPrivacyModal();
    }
  }

  /**
   * Returns TRUE if the delete button for a contact should be shown.
   */
  shouldShowDeleteButton(contact: AccountContact): boolean {
    return this.currentUser ? this.currentUser.contactId !== contact.contactId && !contact.isPrimaryContact : false;
  }

  shouldShowEditButton(contact: AccountContact): boolean {
    return true;
  }

  /**
   * Opens the security settings dialogue.
   */
  openSecuritySettingsModal(): void {
    this.settingsModal.open();
  }

  /**
   * Displays information about privacy and sharing.
   */
  openPrivacyModal() {
    this.modalRef = this.modalService.show(PrivacyModalComponent, {
      animated: false,
      backdrop: 'static',
      class: 'modal-lg',
    });
  }

  /**
   * Displays information about a user.
   */
  openUserInformationModal(contact: AccountContact, data: CurrentUserState) {
    this.modalRef = this.modalService.show(UserInformationModalComponent, {
      animated: false,
      backdrop: 'static',
      class: 'modal-lg',
      initialState: {
        contact: contact,
        data: data,
      },
    });
  }

  /**
   * Displays the create user dialogue.
   */
  openCreateUserModal(): void {
    this.modalRef = this.modalService.show(UserCreateModalComponent, {
      animated: false,
      backdrop: 'static',
      class: 'modal-lg',
      initialState: { accountId: this.currentUser.accountId, onSave: () => this.fetchContacts(this.currentUser.accountId) },
    });
  }

  /**
   * "Searches" for users matching the search term.
   * This doesn't actually perform a search, but rather filters the existing results.
   */
  searchForUsers(): void {
    this.userSearchTerm = this.userSearchForm.value.term;
    this.userSearchForm.markAsPristine();
  }

  private getUserType(contacts) {
    this.currentUserType = contacts.find((con) => con.contactId === this.currentUser.contactId).userType;
  }

  /**
   * Returns TRUE if the current user has admin permission set on Adminstrator in user management.
   */
  get isAdmin() {
    if (this.currentUserState.roles) {
      return this.currentUserState.roles.find((role) => role.name === 'Administrator').privilege === 'Full Access';
    }
    return false;
  }

  /**
   * Returns the current page of results.
   */
  get pageOfResults(): AccountContact[] {
    if (this.filteredContacts) {
      const startRow = this.rowsPerPage * (this.currentPage - 1);
      return this.filteredContacts.slice(startRow, startRow + this.rowsPerPage);
    }
    return [];
  }

  /**
   * Returns a list of contacts filtered by the search term.
   */
  get filteredContacts(): AccountContact[] {
    if (this.userSearchTerm) {
      const searchTerm = this.userSearchTerm.toLowerCase();
      return this.contacts.filter(
        (contact) => -1 !== contact.contactname.toLowerCase().indexOf(searchTerm) || -1 !== contact.email.toLowerCase().indexOf(searchTerm)
      );
    }
    return this.contacts;
  }

  get hasEnoughRowsForPagination(): boolean {
    if (this.contacts) {
      return this.contacts.length > this.rowsPerPage && this.filteredContacts.length >= this.rowsPerPage;
    }
    return false;
  }

  get contactsCount(): number {
    return this.filteredContacts ? this.filteredContacts.length : 0;
  }

  /**
   * Determine whether to lock or unlock the specified contact's account.
   */
  handleContactLock(contact: AccountContact): void {
    if (this.isContactLocked(contact)) {
      this.confirmUnlockContact(contact);
    } else {
      this.confirmLockContact(contact);
    }
  }

  /**
   * Prompt the user to confirm locking the specified contact's account.
   */
  confirmLockContact(contact: AccountContact): void {
    this.contactToLock = contact;
    this.modalRef = this.modalService.show(this.lockModal, {
      animated: false,
      backdrop: 'static',
    });
  }

  /**
   * Prompt the user to confirm locking the specified contact's account.
   */
  confirmUnlockContact(contact: AccountContact): void {
    this.contactToLock = contact;
    this.modalRef = this.modalService.show(this.unlockModal, {
      animated: false,
      backdrop: 'static',
    });
  }

  /**
   * User has confirmed they want to lock the respective contact's account.
   */
  lockConfirmed(): void {
    this.setLockedStatus(this.contactToLock, true);
    this.closeModal();
  }

  /**
   * User has confirmed they want to unlock the respective contact's account.
   */
  unlockConfirmed(): void {
    this.setLockedStatus(this.contactToLock, false);
    this.closeModal();
  }

  /**
   * Set the locked status of the specified contact, reloading the list of contacts.
   */
  setLockedStatus(contact: AccountContact, locked: boolean): void {
    const $process = locked ? this.Repository.lockUser(contact.contactId) : this.Repository.unlockUser(contact.contactId);

    this.isLocking = contact;
    this.error = null;

    $process
      .pipe(
        switchMap(() => this.contactsService.getContacts(this.currentUser.accountId)),
        finalize(() => {
          this.isLocking = null;
        })
      )
      .subscribe({
        next: (contacts) => {
          this.contacts = contacts;
        },
        error: (response: HttpErrorResponse) => {
          this.error = response.error.Message;
        },
      });
  }

  /**
   * Close the current dialogue.
   */
  closeModal(): void {
    this.modalRef.hide();
    this.contactToLock = null;
    this.contactToDelete = null;
  }

  /**
   * Returns TRUE if the specific contact is being locked or unlocked.
   */
  isBeingLocked(contact: AccountContact): boolean {
    return this.isLocking && this.isLocking === contact;
  }

  /**
   * Returns TRUE if the contact is locked.
   */
  isContactLocked(contact: AccountContact): boolean {
    return AccountStatus.LOCKED === contact.accountStatus;
  }

  /**
   * Prompt the user to confirm deleting the specified contact's account.
   */
  confirmDelete(contact: AccountContact): void {
    this.contactToDelete = contact;
    this.modalRef = this.modalService.show(this.deleteModal, {
      animated: false,
      backdrop: 'static',
    });
  }

  /**
   * User has confirmed they want to delete the respective contact's account.
   */
  deleteConfirmed(): void {
    this.isDeleting = this.contactToDelete;

    this.contactsService
      .deactivateContact(this.isDeleting.contactId)
      .pipe(
        concatMap(() => this.contactsService.getContacts(this.currentUser.accountId)),
        concatMap((contacts) => {
          if (contacts.length === 1) {
            return this.contactsService.grantFullAccess(contacts[0].contactId).pipe(map(() => contacts));
          }
          return of(contacts);
        }),
        finalize(() => {
          this.isDeleting = null;
        })
      )
      .subscribe({
        next: (contacts: AccountContact[]) => {
          this.contacts = contacts;
        },
        error: (response: HttpErrorResponse) => {
          this.error = response.error.Message;
        },
      });

    this.closeModal();
  }

  /**
   * Returns TRUE if the specific contact is being deleted.
   */
  isBeingDeleted(row: AccountContact): boolean {
    return this.isDeleting && this.isDeleting.contactId === row.contactId;
  }
}
