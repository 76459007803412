import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { NotificationDismiss } from "src/app/core/ngrx/actions/notification.actions";
import { State } from "src/app/core/ngrx/reducers/notification.reducer";
import { NotificationsService } from "src/app/core/services/notifications.service";
import { NotificationResponse } from "src/app/shared/index";

export abstract class NotificationDisplayComponent {
    public data: NotificationResponse;

    protected constructor(
        protected router: Router,
        protected notifications: NotificationsService,
        protected store: Store<State>
    ) { }

    dismissNotification(notification: NotificationResponse) {
        this.store.dispatch(NotificationDismiss({notification}));
    }
}
