import { Injectable } from '@angular/core';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompaniesHouseCompanyDetails } from 'src/app/shared';
import { Territory } from 'src/app/shared/constants/territory.enum';

@Injectable({
  providedIn: 'root'
})
export class CompanyAutoCompleteService {
  private environment: IEnvironment;
  private isAus = false;

  constructor(private http: HttpClient) {
    const env = new Environment();
    this.environment = env.getConfigSync();
    this.isAus = this.environment.territory === Territory.AUSTRALIA;
  }

  public searchCompanyByHouseNumber(companyHouseNo: string) : Observable<any> {
    return this.http.get(`${this.environment.apiEndpoint.newChasAPIv1}/companysearch/gbr/search?searchText=${companyHouseNo}`);
  }

  public searchCompanyByName(companyName: string): Observable<CompaniesHouseCompanyDetails[]> {
    let url = `${this.environment.apiEndpoint.newChasAPIv1}/companysearch/gbr/search?searchText=${companyName}`;

    if(this.isAus) url = `${this.environment.apiEndpoint.newChasAPIv1}/companysearch/au/search?searchText=${companyName}`;
    return this.http.get<CompaniesHouseCompanyDetails[]>(url);
  }

  public getDetails(company: CompaniesHouseCompanyDetails): Observable<CompaniesHouseCompanyDetails> {
    const url = `${this.environment.apiEndpoint.newChasAPIv1}/companysearch/au/getdetail`;
    return this.http.post<CompaniesHouseCompanyDetails>(url, company);
  }
}
