import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { BasketAccountActions, basketAccountAdd, basketAccountLoad, basketAccountRemove } from '../actions/basketAccount.actions';
import { NewBasketService } from '../../services/new-basket/basket.service';

@Injectable()
export class BasketAccountEffects {
  load$ = createEffect(() => this.actions$.pipe(
    ofType(basketAccountLoad),
    mergeMap(() => this.basketService.getAccount().pipe(
      map(account => ({ type: BasketAccountActions.LoadSuccess, payload: account })),
      catchError(() => of({ type: BasketAccountActions.LoadError }))
    ))
  ));
  add$ = createEffect(() => this.actions$.pipe(
    ofType(basketAccountAdd),
    mergeMap((action) => this.basketService.addAccount(action.account).pipe(
      map(account => ({ type: BasketAccountActions.AddSuccess, payload: account })),
      catchError(() => of({ type: BasketAccountActions.AddError }))
    ))
  ));
  remove$ = createEffect(() => this.actions$.pipe(
    ofType(basketAccountRemove),
    mergeMap((action) => this.basketService.removeAccount().pipe(
      map(basket => ({ type: BasketAccountActions.RemoveSuccess, payload: basket })),
      catchError(() => of({ type: BasketAccountActions.RemoveError }))
    ))
  ));

  constructor(
    private actions$: Actions,
    private basketService: NewBasketService
  ) { }
}

