import { Injectable } from '@angular/core';
import { FeatureFlag } from '../feature-flag';
import { Observable, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { selectAllFeatureFlags } from '../../ngrx/selectors/feature-flag.selectors';
import { HttpClient } from '@angular/common/http';
import { Environment, IEnvironment } from '../../../shared/classes/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private readonly env: Environment = new Environment();
  private config: IEnvironment = this.env.getConfigSync();

  constructor(
    private readonly store: Store,
    private readonly http: HttpClient
  ) {}

  private featureFlagCache = new Map<string, FeatureFlag>([['', { name: '', enabled: false }]]);

  public isFlagEnabled$(flagRequestName: string): Observable<boolean> {
    return this.store.select(selectAllFeatureFlags).pipe(
      map((flag: Array<FeatureFlag>) => {
        return !!flag.find((featureFlag: FeatureFlag) => featureFlag.name === flagRequestName);
      }),
      take(1)
    );
  }

  public getApplicationFlags$(applicationName: string): Observable<FeatureFlag[]> {
    return this.http.get(`${this.config.apiEndpoint.featureFlag}/section/${applicationName}/featureFlags`).pipe<FeatureFlag[]>( // ${this.config.url
      map((flags: any) => {
        this.featureFlagCache.clear();
        flags.forEach((flag: any) => this.featureFlagCache.set(flag.name, { name: flag.name, enabled: flag.enabled }));
        return Array.from(this.featureFlagCache.values());
      })
    );
  }

  public getFeatureFlagsFromState$(): Observable<FeatureFlag[]> {
    return this.store.select(selectAllFeatureFlags);
  }

  public getCachedFlags(): FeatureFlag[] {
    return Array.from(this.featureFlagCache.values());
  }
}
