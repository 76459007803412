
import { createReducer, on } from '@ngrx/store';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();
import { getStorage } from 'src/app/shared/functions/getBasket.functions';
import { quickQuoteAddSuccess, quickQuoteLoadSuccess, quickQuoteRemoveSuccess } from '../actions/quickQuote.actions';

export const initialState = getStorage('quickQuote');

const _quickQuoteReducer = createReducer(
  initialState,
  on(quickQuoteAddSuccess, (state, action) => action.payload),
  on(quickQuoteLoadSuccess, (state, action) => action.payload),
  on(quickQuoteRemoveSuccess, (state, action) => action.payload),
);

export function quickQuoteReducer(state, action) {
  return _quickQuoteReducer(state, action);
}
