import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminUsersPageComponent } from './admin-users.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { SecuritySettingsModalModule } from 'src/app/shared/components/security-settings-modal/security-settings-modal.module';
import { UserInformationModalModule } from 'src/app/shared/components/user-information-modal/user-information-modal.module';
import { BootstrapComponentsModule } from 'src/app/shared/components/bootstrap/bootstrap.module';

import { UserInformationModalComponent, UserCreateModalComponent } from '../../core';

const routes: Routes = [
  {
    path: 'users',
    component: AdminUsersPageComponent,
    data: {
      showDialogue: null,
    },
  },
  {
    path: 'users/my-details',
    component: AdminUsersPageComponent,
    data: {
      showDialogue: 'users',
    },
  },
  {
    path: 'users/privacy-sharing',
    component: AdminUsersPageComponent,
    data: {
      showDialogue: 'privacy',
    },
  },
];

@NgModule({
  declarations: [AdminUsersPageComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BootstrapComponentsModule,
    LoadingSpinnerModule,
    SecuritySettingsModalModule,
    UserInformationModalModule,
    RouterModule.forChild(routes),
  ],
  exports: [AdminUsersPageComponent],
})
export class AdminUsersModule {}
