export const Regex = {
  PHONE: '^[+]*[(]{0,1}[0-9 ]{1,4}[)]{0,1}[-\s\./0-9 ].{8,12} *$',
  PASSWORD: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])|(?=.*?[a-z])(?=.*?[#?!@$%^&*-])(?=.*?[0-9])|(?=.*?[A-Z])(?=.*?[#?!@$%^&*-])(?=.*?[0-9]).{8,}$',
  EMAIL: /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,10}$/
}

//PASSWORD regex contains the follwoing logic:
//uppercase + lowercase + special
//(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-])
//or uppercase + lowercase + number
//|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])
//or lowercase + special + number
//|(?=.*?[a-z])(?=.*?[#?!@$%^&*-])(?=.*?[0-9])
//or uppercase + special + number
//|(?=.*?[A-Z])(?=.*?[#?!@$%^&*-])(?=.*?[0-9])
//and atleast 8 digits
//.{ 8,} $
