<!-- Product bundle badge (semantically appearing first). -->
<div class="product-bundle__badge">
  <img [src]="bundleImage" [attr.alt]="bundleName">
</div>


<div class="product-bundle__info" *ngIf="foundation">
  <div *ngIf="authAccount">
    <h1>{{ companyDetails.id.name }}</h1>
    <p>Membership No: {{authAccount.membershipId}}</p>   
  </div>
  <br />
  <button class="btn btn-pink" *ngIf="canUpgrade" [routerLink]="['/membership']" [state]="{data:{renewal:true, foundation: true}}">Upgrade Membership</button>
  <!--<button class="btn btn-primary" (click)="productsModal.show()" data-intro="Some of our clients may send you codes to unlock specific assessments - these can be entered here." data-step="3">Redeem a code</button>-->
</div>

<div class="product-bundle__info" *ngIf="!foundation">
  <div *ngIf="authAccount">
    <h1>{{ companyDetails.id.name }}</h1>
    <p>Membership No: {{authAccount.membershipId}}</p>  
    <div class="product-bundle__expiry">
      Membership {{expiryText}}: {{ expiryDate }}
    </div>
    <ng-container *ngIf="(!isThirdParty && permissions === 'Full Access') && !assessmentFailed">
      <button class="btn btn-primary" *ngIf="canUpgrade" [routerLink]="['/membership']" [state]="{data:{renewal:false}}">{{upgradeLabel}}</button>
      <!--<button class="btn btn-primary" (click)="productsModal.show()" data-intro="Some of our clients may send you codes to unlock specific assessments - these can be entered here." data-step="3">Redeem a code</button>-->
    </ng-container>
    <ng-container *ngIf="isThirdParty">
      <p>{{ bundle.numEmployees | number }} credits</p>
    </ng-container>
  </div>
  <button *ngIf="isThirdParty" type="button" class="btn btn-secondary" [class.is-loading]="isLoggingIn" (click)="bundleLogin()">
    Login
  </button>
  <span class="text-danger" *ngIf="error">{{ error }}</span>
</div>

<div bsModal #productsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-sizes-name1" class="modal-title pull-left">Redeem a code</h4>
        <button type="button" class="close pull-right" (click)="productsModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <img class="bundle-image" [src]="productImage">

        <p>If you have been given a code by one of your clients, you can enter it here to unlock any additional assessments they require you to do.</p>
        <br />
        <p>Simply type in your code, select the product you require, and click Pay Now</p>
        <br />

        <form [formGroup]="redeemCodeForm" class="redeem_form" *ngIf="companyDetails">

          <label>Get Your Quote:</label>
          <app-reference-validator (emitReferenceCode)="applyReferenceCode($event)"></app-reference-validator>

          <div class="form-group">
            <label>Number of direct employees:</label>
            <div class="form-group">
              <input class="form-control" type="number" formControlName="number_of_direct_employees" (change)="recalculateEmployeesAsync()" placeholder="Enter here" min="0" step="1" oninput="validity.valid||(value='');" />
            </div>
          </div>

          <div class="form-group">
            <label>Number of Labour Only Subcontractors:</label>
            <div class="form-group">
              <input class="form-control" type="number" formControlName="number_of_labour_only_subcontractors" placeholder="Enter here" (change)="recalculateEmployeesAsync()" min="0" step="1" oninput="validity.valid||(value='');" />
            </div>
          </div>

          <div class="form-group">
            <label>Number of effective employees:</label>
            <div class="form-group">
              <input class="form-control" type="number" formControlName="noEmployees" placeholder="Enter here" tooltip="We have changed the way we collect the number of employees information. The direct employees and labour only subcontractors numbers are now separated out. When you complete these boxes, it will calculate the total number of effective employees for you."
                     readonly min="0" />
            </div>
          </div>

          <div class="form-group">
            <label>Number of Bona Fide Subcontractors:</label>
            <div class="form-group">
              <input class="form-control" type="number" formControlName="number_of_bona_fide_subcontractors" placeholder="Enter here" min="0" step="1" oninput="validity.valid||(value='');" />
            </div>
          </div>

          <div class="form-group">
            <label>Select a product:</label>
            <select class="form-control" id="allProducts" formControlName="product" (change)="changeProductAsync()">
              <option hidden [ngValue]="null" selected>Please select a product</option>
              <option *ngFor="let product of allProducts" [ngValue]="product">{{product.name}}</option>
            </select>
          </div>
        </form>


        <div class="quote-container">
          <app-quote-container [resetQuote]="resetQuote" [amount]="quoteTotal" [loading]="loadingQuote" [showVatLabel]="showVatLabel"></app-quote-container>
          <button type="button"
                  class="btn btn-primary"
                  (click)="activateAync()"
                  [class.is-loading]="modalbtnLoading"
                  [disabled]="payButtonDisabled">
            Pay now
          </button>
        </div>


      </div>
    </div>
  </div>
</div>