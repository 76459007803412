<div class="layoutmainsection">
  <div class="layout">
    <!-- SITE HEADER -->
    <header
      data-intro='Welcome to "My CHAS", the membership portal. This tour will take you through some of the features you can access from the dashboard.'
      data-step="1"
    >
      <div class="logo">
        <!-- SITE LOGO -->
        <app-chas-logo (click)="goToDashboard()"></app-chas-logo>

        <!-- Menu toggle button (hidden at "desktop" screen widths.) -->
        <button class="menuButton" type="button" (click)="toggleMenu()" title="Show/hide menu">
          <i class="fa fa-fw fa-bars" aria-hidden="true"></i>
        </button>
      </div>

      <!-- SITE MENU -->
      <div class="menu">
        <app-user-menu *ngIf="!isLoading"></app-user-menu>
      </div>
    </header>

    <!-- NAVIGATION -->
    <nav>
      <app-site-menu></app-site-menu>
    </nav>

    <!-- MAIN CONTENT AREA -->
    <section role="main" id="chas-main-content-area">
      <div *ngIf="isOpsSession" class="d-flex flex-row justify-content-center mb-3" style="background-color: yellow">
        <mat-toolbar-row class="flex-d flex-row justify-content-center">
          <span
            >OPS PORTAL SESSION ACTIVE: CLICK HERE TO END SESSION
            <button mat-flat-button color="primary" class="sessionButton" (click)="endOpsUserSession()">End Session</button></span
          >
        </mat-toolbar-row>
      </div>

      <div class="center" *ngIf="isLoading">
        <app-loading-spinner></app-loading-spinner>
      </div>

      <router-outlet *ngIf="!isLoading"></router-outlet>

      <app-terms-modal *ngIf="!isLoading"></app-terms-modal>
    </section>
  </div>

  <div id="layoutfooter">
    <app-site-footer></app-site-footer>
  </div>
</div>

<ng-template bsModal #sessionTimeoutModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Session Timeout</h4>
  </div>
  <div class="modal-body">Your session is about to expire. You will be logged out in {{ sessionTimeoutSeconds }} seconds.</div>
  <div class="modal-footer clearfix">
    <button type="button" (click)="logout()" class="btn btn-secondary float-right mr-4" style="margin: 0">Log out</button>
    <button type="button" (click)="stayLoggedIn()" class="btn btn-primary float-right" style="margin: 0">Stay logged in</button>
  </div>
</ng-template>
