import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource } from '@angular/material/tree';
import { CollectionViewer, DataSource, SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, forkJoin, merge, Observable, first, finalize, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { WrappedNodeExpr } from '@angular/compiler';
import { ThemePalette } from '@angular/material/core';
import { WorkRegionsService } from '../../../core/services/work-regions.service';
import { FormControl, NumberValueAccessor } from '@angular/forms';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';
import { AccountsService } from '../../../core/services/accounts.service';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { Store } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Environment, IEnvironment } from '../../classes/environment';
import { Territory } from '../../constants/territory.enum';
export interface WorkRegion {
  id: string;
  name: string;
  code: string;
  level: number;
  areas: WorkRegion[];
  parentCode?: string;
  hasChildrem: boolean;
}

/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[];
  item: WorkRegion;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: WorkRegion;
  level: number;
  expandable: boolean;
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  setData(x: WorkRegion[]) {
    this.dataChange.next(
      x.map((item) => {
        return {
          item: item,
          children: item.areas.map((child) => {
            return {
              item: child,
              children: child.areas.map((gchild) => {
                return { item: gchild, children: [] };
              }),
            };
          }),
        };
      })
    );
  }
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  constructor() { }
}

@Component({
  selector: 'app-work-region-selecter',
  templateUrl: './work-region-selecter.component.html',
  styleUrls: ['./work-region-selecter.component.scss'],
  providers: [ChecklistDatabase],
})
export class WorkRegionSelecterComponent implements OnInit, OnDestroy {
  @Input() selectedWorkRegions: WorkRegion[] | null;
  @Input() distance: number = 0;
  @Input() onChange: (selectedRegions: WorkRegion[], selectedStates: WorkRegion[], distnace: number) => void;

  saveConfirmationBsModalRef: BsModalRef = null;
  accountId: string;
  hasSelectedWorkRegion: boolean;
  workRegionsToBroadCast: WorkRegion[];
  workStatesToBroadCast: WorkRegion[];
  error: string;
  isLoaded: boolean = false;
  theme: ThemePalette = 'primary';
  protected distanceUnit: string;

  saving: boolean = false;

  private $destroyed = new Subject<void>();

  constructor(
    private _database: ChecklistDatabase,
    private WorkRegionService: WorkRegionsService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService
  ) {
    const env = new Environment();
    const environment: IEnvironment = env.getConfigSync();
    this.distanceUnit = environment.territory === Territory.AUSTRALIA ? 'km' : 'miles';

    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
  }
  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  ngOnInit(): void {
    this.isLoaded = false;

    this.WorkRegionService.getAllCachedWorkRegions()
      .pipe(takeUntil(this.$destroyed))
      .subscribe((allRegions) => {
        if (allRegions.length == 0) return;
        this.isLoaded = true;
        this._database.setData(allRegions);
        if (this.selectedWorkRegions.length > 0) {
          this.selectedWorkRegions.forEach((preSelected) => {
            this.nestedNodeMap.forEach((element) => {
              if (element.item.code === preSelected.code && !this.checklistSelection.isSelected(element)) {
                this.todoItemSelectionToggle(element);
              }
            });
          });
        }
      });
  }

  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
    this.broadcastSelection();
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.broadcastSelection();
  }

  broadcastSelection() {
    let selectionNodes: TodoItemFlatNode[] = this.checklistSelection.selected.sort((a, b) => (a.level < b.level ? -1 : 1));
    let nodesToBroadCast: TodoItemFlatNode[] = [];
    let stateNodesToBroadcast: TodoItemFlatNode[] = [];
    selectionNodes.forEach((x) => {
      if (x.level === 0) {
        nodesToBroadCast.push(x);
        stateNodesToBroadcast.push(x);
      }
      if (x.level === 1) {
        if (nodesToBroadCast.filter((node) => node.item.code === x.item.parentCode).length === 0) {
          nodesToBroadCast.push(x);
          let parent = this.getParentNode(x);
          if (stateNodesToBroadcast.filter((s) => s.item.id === parent.item.id).length === 0) {
            stateNodesToBroadcast.push(parent);
          }
        }
      }
    });
    this.hasSelectedWorkRegion = nodesToBroadCast.length > 0;
    this.workRegionsToBroadCast = nodesToBroadCast.map((x) => x.item);

    this.workStatesToBroadCast = stateNodesToBroadcast.map((x) => x.item);
  }
  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
  public close(): void {
    this.bsModalRef.hide();
  }
  getTreeIcon(node: TodoItemFlatNode): string {
    return this.treeControl.isExpanded(node) ? 'fa-chevron-down' : 'fa-chevron-right';
  }
  updateDistance($event: any) {
    $event.preventDefault();
    this.distance = $event.target.value;
  }
  get canSave(): boolean {
    return this.hasSelectedWorkRegion && this.distance > 0;
  }

  IsLocationAdded(): boolean {
    let states = this.selectedWorkRegions.filter((x) => x.parentCode === null);
    let hasNewStates =
      this.workRegionsToBroadCast.filter(
        (x) => states.filter((s) => s.code === x.code).length > 0 || states.filter((s) => s.code === x.parentCode).length > 0
      ).length > 0;

    return hasNewStates;
  }

  openSaveConfirmationModal(template: TemplateRef<any>) {
    if (this.IsLocationAdded()) {
      this.saveConfirmationBsModalRef = this.modalService.show(template, { class: 'modal-sm' });
      return;
    }

    this.updateWorkAreas();
  }

  confirm(): void {
    this.updateWorkAreas();

    this.saveConfirmationBsModalRef.hide();
  }

  decline(): void {
    this.saveConfirmationBsModalRef.hide();
  }

  /**
   * Updates the account with the entered information.
   */
  updateWorkAreas(): void {
    if (this.saving) {
      return;
    }

    const distance = this.distance;
    let states = [...this.workRegionsToBroadCast.filter((x) => x.level === 0), ...this.workStatesToBroadCast];
    this.onChange(this.workRegionsToBroadCast, this.workStatesToBroadCast, distance);
    this.saving = false;
    this.close();
  }
}
