import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompaniesHouseCompanyDetails } from 'src/app/shared';
import {  CompanyAutoCompleteService } from '..';

@Injectable({
  providedIn: 'root'
})
export class CompanySearchService {
  constructor(
    private companyAutoCompleteService: CompanyAutoCompleteService
  ) { }

  public searchByName(companyName: string): Observable<CompaniesHouseCompanyDetails[]> {
    return this.companyAutoCompleteService.searchCompanyByName(companyName);
  }

  public getDetails(company: CompaniesHouseCompanyDetails): Observable<CompaniesHouseCompanyDetails> {
    return this.companyAutoCompleteService.getDetails(company);
  }
}
