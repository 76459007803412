import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CompaniesHouseSearchResults, CompaniesHouseCompanyDetails } from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class CompanySearchService {
  private environment: IEnvironment;

  constructor(private http: HttpClient) {
    const env = new Environment();
    this.environment = env.getConfigSync();
  }

  /**
   * Search for company details by a company registration number.
   * NOTE: this can also be used to search by company name!
   */
  
  search(searchTerm: string): Observable<CompaniesHouseSearchResults> {
  

    return this.http
      .get(`${this.environment.apiEndpoint.companySearch}/`, {
        params: {
          // The Companies House API does not find a match on lowercase letters in a company number,
          // so it must be converted to uppercase.
          reqeust: searchTerm.toUpperCase()
        }
      })
      .pipe(map(data => data as CompaniesHouseSearchResults));
  }
  
}
