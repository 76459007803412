<div class="card-deck">

  <ng-container *ngFor="let bundle of bundles; let i = index">

    <div class="card" [class.selected]="isSelected(bundle)" *ngIf="shouldShowBundle(bundle)">

      <div class="card-body">
        <div>
          <div class="card-title">
            <img class="product-bundles__image" [src]="bundle.image" [attr.alt]="bundle.name" [attr.title]="bundle.name" />
            <h5>{{bundle.name}}</h5>
          </div>
          <div class="card-text" *ngIf="insuranceFeatureFlag()">
            <div class="advantage_list">
              <ul>
                <li *ngIf="bundle.level === 0">Free Learning Pathway</li>
                <li *ngIf="bundle.level !== 0">Member Benefits & Discounts</li>
                <li *ngIf="bundle.level !== 0">Health and Safety</li>
                <li *ngIf="bundle.level === 1">Insurance (Self Certify)</li>
                <li *ngIf="bundle.level === 3 || bundle.level === 4">Insurance</li>
                <li *ngIf="bundle.level === 3 || bundle.level === 4">Financial</li>
                <li *ngIf="bundle.level === 3 || bundle.level === 4">Identity</li>
                <li *ngIf="bundle.level === 3 || bundle.level === 4">Corporate & Professional Standing</li>
                <li *ngIf="bundle.level === 3 || bundle.level === 4">Environmental</li>
                <li *ngIf="bundle.level === 3 || bundle.level === 4">Quality</li>
                <li *ngIf="bundle.level === 3 || bundle.level === 4">Equality Diversity and Inclusion (EDI)</li>
                <li *ngIf="bundle.level === 3 || bundle.level === 4">Modern Slavery</li>
                <li *ngIf="bundle.level === 3 || bundle.level === 4">Anti-Bribery & Corruption</li>
                <li *ngIf="bundle.level === 4">Corporate Social Responsibility</li>
                <li *ngIf="bundle.level === 4">Information Security</li>
                <li *ngIf="bundle.level === 4">Information Management</li>
              </ul>
            </div>
            <div class="disadvantage_list">
              <ul>
                <li *ngIf="bundle.level === 0">Health and Safety</li>
                <li *ngIf="bundle.level === 0">Insurance (Self Certify)</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1">Financial</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1">Identity</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1">Corporate & Professional Standing</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1">Environmental</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1">Quality</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1">Equality Diversity and Inclusion (EDI)</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1">Modern Slavery</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1">Anti-Bribery & Corruption</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1 || bundle.level === 3">Corporate Social Responsibility</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1 || bundle.level === 3">Information Security</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1 || bundle.level === 3">Information Management</li>
              </ul>
            </div>

          </div>

          <div class="card-text" *ngIf="!insuranceFeatureFlag()">
            <div class="advantage_list">
              <ul>
                <li *ngIf="bundle.level === 0">Limited Benefits &  Discounts</li>
                <li *ngIf="bundle.level !== 0">Member Benefits & Discounts</li>
                <li *ngIf="bundle.level !== 0">Health & Safety</li>
                <li *ngIf="bundle.level === 1">Financial (Self Certify)</li>
                <li *ngIf="bundle.level === 2 || bundle.level === 3">Financial</li>
                <li *ngIf="bundle.level === 4">Financial and Business Standing</li>
                <li *ngIf="bundle.level === 2 || bundle.level === 3|| bundle.level === 4">Environmental</li>
                <li *ngIf="bundle.level === 2 || bundle.level === 3|| bundle.level === 4">Quality Management</li>
                <li *ngIf="bundle.level === 2 || bundle.level === 3|| bundle.level === 4">Equality Diversity and Inclusion (EDI)</li>
                <li *ngIf="bundle.level === 3|| bundle.level === 4">Anti-Bribery & Corruption</li>
                <li *ngIf="bundle.level === 3|| bundle.level === 4">Modern Slavery</li>
                <!--CHAS premier-->
                <li *ngIf="bundle.level === 4">Corporate & Professional Standing</li>
                <li *ngIf="bundle.level === 4">Corporate Social Responsibility</li>
                <li *ngIf="bundle.level === 4">Information Management</li>
                <li *ngIf="bundle.level === 4">Identity</li>
                <li *ngIf="bundle.level === 4">Information Security</li>
              </ul>
            </div>

            <div class="disadvantage_list">
              <ul>
                <li *ngIf="bundle.level === 0">Health & Safety</li>
                <li *ngIf="bundle.level === 0">Financial (Self Certify)</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1">Environmental</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1">Quality Management</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1">Equality Diversity and Inclusion (EDI)</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1 || bundle.level === 2">Anti-Bribery & Corruption</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1 || bundle.level === 2">Modern Slavery</li>
                <!--CHAS premier-->
                <li *ngIf="bundle.level === 0 || bundle.level === 1 || bundle.level === 2 || bundle.level === 3">Corporate & Professional Standing</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1 || bundle.level === 2 || bundle.level === 3">Corporate Social Responsibility</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1 || bundle.level === 2 || bundle.level === 3">Information Management</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1 || bundle.level === 2 || bundle.level === 3">Identity</li>
                <li *ngIf="bundle.level === 0 || bundle.level === 1 || bundle.level === 2 || bundle.level === 3">Information Security</li>
              </ul>
            </div>


          </div>
        </div>

        <div class="center">
          <small class="text-muted">Prices starting from:</small>
          <h2>{{ priceService.getPrice(bundle.level) | currency:'GBP' }} </h2>

          <a *ngIf="!foundation" href="https://www.chas.co.uk/chas-products-packages/#tabs-scroll-package" target="_blank">
            <div class="btn btn-outline-primary">Find out more</div>
          </a>

          <button type="button" class="btn btn-primary" (click)="selectBundle(bundle, i)" [disabled]="disabled" *ngIf="!isSelected(bundle)">Select</button>
          <button type="button" class="btn btn-primary" (click)="selectBundle(bundle, i)" [disabled]="disabled" *ngIf="isSelected(bundle)">Selected</button>
        </div>


      </div>
    </div>

  </ng-container>

</div>




<ng-template #noBundles>
    <alert type="danger" *ngIf="error">Could not fetch product bundles.</alert>
    <div class="text-center" *ngIf="!error">
        <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
        <app-nothing *ngIf="!isLoading">No product bundles available!</app-nothing>
    </div>
</ng-template>
