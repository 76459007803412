import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { first, finalize, takeUntil, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ProductService, SSOService, PaymentService, AccountsService } from '../../../core';
import {
  LatestBundleResponse,
  SSOUrlParameters,
  DateHelpers,
  dateCheck,
  RetrieveProductBundlesResponse,
  RetrieveProductsResponse,
  ApplicationTypeDetails,
  AssessmentTypeDetails,
  TradeAssociations,
  productCodeTypes,
  CompanyDetails,
  GetQuoteRequest,
  IntroductionVideo,
  UserTypeCode,
  CheckNewAuthenticationResponse,
} from '../../../shared';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { CreateOpportunityParameters } from '../../../shared/interfaces/create-opportunity-parameters';
import applicationTypesJSON from 'src/assets/json/application-types.json';
import assessmentTypesJSON from 'src/assets/json/assessment-types.json';
import { Subject } from 'rxjs/internal/Subject';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { ProductName } from 'src/app/shared/classes/productName.class';

declare var Stripe: any;

@Component({
  selector: 'app-membership-card',
  templateUrl: './membership-card.component.html',
  styleUrls: ['./membership-card.component.scss'],
  providers: [DatePipe],
})
export class MembershipCardComponent implements OnInit, OnDestroy {
  @Input() foundation: boolean;
  @Input() bundle: LatestBundleResponse;
  @Input() permissions: string;
  private readonly data$ = this.store.select(selectCurrentUserState);
  public authAccount: CheckNewAuthenticationResponse;
  expiry: string;
  isLoggingIn: boolean;
  quoteTotal: number;
  loadingQuote = false;
  error: string;
  isLoading: boolean;
  selectedProduct: any;
  resetQuote = 0;
  referenceCode: string;
  companyDetails: CompanyDetails;
  allProducts: RetrieveProductsResponse[];
  selectedBundle: RetrieveProductBundlesResponse = null;
  applicationTypes: ApplicationTypeDetails[];
  assessmentTypes: AssessmentTypeDetails[];
  modalbtnLoading: boolean;
  opportunityObject: CreateOpportunityParameters;
  redeemCodeForm: UntypedFormGroup;
  showAppType = false;
  showAppOption = false;
  tradeAssociation: boolean = false;
  isFreeMembership: boolean = true;
  private $destroyed = new Subject<void>();
  quoteError: string;
  showVatLabel: boolean;
  videoModalShown = false;
  public canUpgrade = false;
  @ViewChild('videoModal', { static: false }) videoModal: ModalDirective;
  private config: IEnvironment;

  constructor(
    private date: DatePipe,
    private Product: ProductService,
    private SSO: SSOService,
    private Payment: PaymentService,
    private gtmService: GtmService,
    private readonly store: Store
  ) {
    this.error = null;
  }

  async ngOnInit(): Promise<void> {
    const env = new Environment();
    this.config = await env.getConfig();

    this.applicationTypes = applicationTypesJSON;

    this.data$.subscribe({
      next: (data) => {
        if (data.loaded) {
          this.companyDetails = data.companyDetails;
          this.authAccount = data.currentAccount;
          this.expiry = new DateHelpers(data.currentAccount.membershipExpiry).expiry();

          this.getAssessmentTypes(false);

          this.redeemCodeForm = new UntypedFormGroup({
            noEmployees: new UntypedFormControl(this.companyDetails.numberOfEffectiveEmployees),
            number_of_direct_employees: new UntypedFormControl(
              this.companyDetails.numberOfDirectEmployees
                ? this.companyDetails.numberOfDirectEmployees
                : this.companyDetails.numberOfEffectiveEmployees
            ),
            number_of_labour_only_subcontractors: new UntypedFormControl(this.companyDetails.numberOfLabourOnlySubcontractors),
            number_of_bona_fide_subcontractors: new UntypedFormControl(this.companyDetails.numberOfBonaFideSubcontractors),
            product: new UntypedFormControl(''),
            appType: new UntypedFormControl(''),
            appOption: new UntypedFormControl(this.getApplicationType(this.bundle ? this.bundle.productAssessmentType.toString() : null)),
          });
          this.recalculateEmployeesAsync();
          this.redeemCodeForm.get('product').disable();
          this.fetchAllProducts(null);
          this.isFreeMembership = data.isFreeMembership;
        }
      },
      error: (response: any) => {
        console.error('failed to load user', response.error);
      },
    });
  }

  ngOnDestroy() {
    this.$destroyed.next();
  }

  get assessmentFailed(): boolean {
    const status = sessionStorage.getItem('failed_assessment');
    if (status === 'true') return true;
    else return false;
  }

  get productImage(): string {
    return `assets/img/chas-logo-purple.png`;
  }

  public checkCanUpgrade(): boolean {
    if (this.authAccount.userType.toString() === UserTypeCode.SUPPLIER) return false;

    let isAssured =
      this.bundle?.productAssessmentType?.toString() == productCodeTypes.ONSITE ||
      this.bundle?.productAssessmentType?.toString() == productCodeTypes.ASSURED;

    if (isAssured) {
      return false;
    } else {
      return this.foundation || (!this.renewNow && !this.bundleName.includes(ProductName.level4));
    }
  }

  get renewNow(): boolean {
    return this.expiry === dateCheck.EXPIRING || this.expiry === dateCheck.EXPIRED ? true : false;
  }

  get bundleName(): string {
    return this.bundle ? this.bundle.bundleName : null;
  }

  get bundleImage(): string {
    // if (this.foundation) return `assets/img/product-cards/FDN-Badge.png`;
    return this.bundle ? this.Product.getProductBundleImage(this.bundle) : null;
  }

  get expiryText(): string {
    return this.expiry === dateCheck.EXPIRING || this.expiry === dateCheck.VALID ? 'Expires' : 'Expired';
  }

  get expiryDate(): string {
    return this.bundle ? this.date.transform(this.bundle.bundleExpiry, 'dd/MM/yyyy') : null;
  }

  get isThirdParty(): boolean {
    if (this.bundle) {
      switch (this.bundle.bundleName) {
        case 'CHAS People':
        case 'Fairness, Inclusion and Respect Growth Assessment':
        case 'CHAS Plant':
          return true;
        default:
          return false;
      }
    }
    return false;
  }

  get videoThumbnailUrl(): string {
    return IntroductionVideo.ThumbnailUrl;
  }

  openIntroVideoModal() {
    this.videoModalShown = true;
  }

  closeIntroVideoModal() {
    this.videoModal?.hide();
    this.videoModalShown = false;
  }

  getApplicationType(appType) {
    if (appType === productCodeTypes.ONSITE) return productCodeTypes.ASSURED;
    else {
      return this.assessmentTypes.find((x) => x.code === appType)
        ? this.assessmentTypes.find((x) => x.code === appType).code
        : productCodeTypes.FULLAPPLICATION;
    }
  }

  applyReferenceCode($refCode) {
    this.resetForm();
    if ($refCode) {
      this.redeemCodeForm.get('product').enable();
      this.fetchAllProducts($refCode);
      for (let prop in TradeAssociations) {
        if ($refCode.toUpperCase().includes(prop)) {
          this.tradeAssociation = true;
          return this.getAssessmentTypes(true);
        } else {
          this.getAssessmentTypes(false);
        }
      }
    }
  }

  getAppOptions() {
    this.showAppOption = false;
    if (this.tradeAssociation) {
      this.getAssessmentTypes(true);
    } else if (this.redeemCodeForm.value.appType.code === productCodeTypes.CARE) {
      this.assessmentTypes = assessmentTypesJSON.filter((type) => type.code !== productCodeTypes.DTS);
    } else {
      this.assessmentTypes = assessmentTypesJSON;
    }
    this.showAppOption = true;
  }

  getAssessmentTypes(trade) {
    if (trade) this.assessmentTypes = assessmentTypesJSON.filter((a) => a.code === productCodeTypes.FULLAPPLICATION);
    else this.assessmentTypes = assessmentTypesJSON;
  }

  trackByFn(index: number, item: RetrieveProductBundlesResponse) {
    return index;
  }

  async recalculateEmployeesAsync() {
    let newVal =
      this.redeemCodeForm.controls.number_of_direct_employees.value +
      this.redeemCodeForm.controls.number_of_labour_only_subcontractors.value;

    this.redeemCodeForm.controls.noEmployees.setValue(newVal);

    await this.getQuoteAsync();
  }

  async changeProductAsync() {
    this.selectedProduct = this.redeemCodeForm.controls.product.value;
    await this.getQuoteAsync();
    this.showAppType = true;
  }

  async getQuoteAsync(createOpportunity?: boolean): Promise<void> {
    createOpportunity = createOpportunity ?? false;

    const productId = this.selectedProduct?.productId;
    if (!productId) {
      return;
    }

    var query: GetQuoteRequest = {
      accountId: this.authAccount.accountId,
      contactId: this.authAccount.contactId,
      productId: this.selectedProduct?.productId,
      numberOfEmployees: this.redeemCodeForm.controls.noEmployees.value,
      directEmployees: this.redeemCodeForm.controls.number_of_direct_employees.value,
      labourOnlySubContractors: this.redeemCodeForm.controls.number_of_labour_only_subcontractors.value,
      bonafideSubContractors: this.redeemCodeForm.controls.number_of_bona_fide_subcontractors.value,
      createOpportunity: createOpportunity,
      successUrl: this.config.urls.environmentUrl + '/payment-success/membership',
      cancelUrl: this.config.urls.environmentUrl,
      dataSharingAllowed: false,
      customerType: UserTypeCode.CONTRACTOR,
    };

    if (!createOpportunity) {
      this.loadingQuote = true;
    }

    try {
      var quote = await this.Product.getIndividualQuoteAsync(query);
      this.quoteTotal = quote.result.totalAmount;
      this.showVatLabel = quote.result.vatAmount > 0;
    } catch {
      this.quoteError = 'Could not get quote, please try again.';
    } finally {
      this.loadingQuote = false;
    }
  }

  /**
   *
   */
  bundleLogin(): string {
    if (this.isLoggingIn) {
      return;
    }
    // Obtain a URL to redirect to.
    const service = this.bundle.bundleName.replace('CHAS ', '');

    const params: SSOUrlParameters = {
      ContractorId: this.authAccount.accountId,
      ContactId: this.authAccount.contactId,
    };

    this.isLoggingIn = true;
    this.error = null;
    this.SSO.url(params)
      .pipe(
        first(),
        finalize(() => {
          this.isLoggingIn = false;
        })
      )
      .subscribe({
        next: (url) => {
          // Open a new browser tab with the URL.
          window.open(url);
        },
        error: (response: HttpErrorResponse) => {
          this.error = response.error.Message;
        },
      });
  }

  /**
   * Obtain a list of product bundles to choose from.
   */
  async fetchAllProducts(referenceCode: string): Promise<void> {
    if (this.isLoading) {
      return;
    }

    this.allProducts = null;
    this.isLoading = true;
    this.error = null;

    try {
      this.allProducts = await this.Product.getAddonProductsAsync(referenceCode);
    } catch {
      this.error = 'Error getting addon products.';
    } finally {
      this.isLoading = false;
    }
  }

  private resetForm() {
    try {
      this.resetQuote++;
      this.tradeAssociation = false;
      this.opportunityObject = null;
      this.redeemCodeForm.get('product').disable();
      this.redeemCodeForm.get('product').setValue(null);
      this.redeemCodeForm.get('appType').setValue(null);
      this.showAppType = false;
      this.showAppOption = false;
    } catch (err) {
      console.error(err);
    }
  }

  async activateAync() {
    this.modalbtnLoading = true;
    await this.getQuoteAsync(true);
    const opportunity = this.createOpportunityObject();
    await this.Payment.activateAsync(this.quoteTotal, opportunity);
    this.gtmService.redeemCode({
      name: opportunity.referenceCode,
    });
  }

  private createOpportunityObject() {
    this.opportunityObject = {
      accountId: this.authAccount.accountId,
      contactId: this.authAccount.contactId,
      bundleId: this.selectedProduct.productId,
      name: this.selectedProduct.name,
    };
    return this.opportunityObject;
  }

  get upgradeLabel(): string {
    if (this.bundleName.includes(ProductName.level3)) {
      return 'Upgrade to ' + ProductName.level4;
    } else {
      return 'Upgrade';
    }
  }

  get payButtonDisabled(): boolean {
    const productId: string = this.selectedProduct?.productId;
    const employees: number = this.redeemCodeForm?.controls.noEmployees.value;
    const appType: any = this.redeemCodeForm?.controls.appType.value;
    const appOption: any = this.redeemCodeForm?.controls.appOption.value;

    if (!productId || !employees || employees === 0) {
      return true;
    } else {
      return false;
    }
  }
}
