import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssessmentsMenuComponent } from './assessments-menu.component';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { BootstrapComponentsModule } from '../bootstrap/bootstrap.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [AssessmentsMenuComponent],
  exports: [AssessmentsMenuComponent],
  imports: [
    CommonModule,
    RouterModule,
    BootstrapComponentsModule,
    LoadingSpinnerModule
  ]
})
export class AssessmentsMenuModule { }
