<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <div>
        <span>Welcome – Confirm Your Company Details</span>
      </div>
    </div>
    <div class="modal-body privacy-body">
      <p class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</p>
      <div class="text-center" style="height:50vh" *ngIf="isLoading">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <section *ngIf="!isLoading">
        <p>Welcome {{currentUser.fullName}}. Thank you for registering with CHAS.</p>
        <p>Please check and complete your company information below ahead of starting your assessments.</p>
        <p>{{tooltipText}}</p>

        <form [formGroup]="detailsForm" (submit)="updateCompanyDetails()" novalidate>
          <div>
            <label class="col-form-label" for="detailsOrganisationType">
              Organisation type:
            </label>
            <span>
              <span *ngIf="!organisationType; else showOrganisationType">
                <select id="detailsOrganisationType" class="custom-select" formControlName="organisation_type">
                  <option hidden [ngValue]="null" selected>Please select</option>
                  <option *ngFor="let row of options.organisationTypes" [value]="row.id">{{ row.label }}</option>
                </select>
              </span>
              <ng-template #showOrganisationType>
                {{ organisationType }}
              </ng-template>
            </span>
          </div>

          <div class="text-danger"
          *ngIf="detailsForm.controls.organisation_type.touched && detailsForm.controls.organisation_type.errors?.required">
            *Organisation type is required
        </div>

        <div class="c-employees-grid">
          <div>
            <label class="col-form-label" for="detailsNumDirectEmployees">
              Employees:
            </label>
            <span>
              <span>
                {{companyDetails.numberOfDirectEmployees}}
              </span>
            </span>
          </div>

            <div *ngIf="!isAus">
              <label class="col-form-label" for="detailsNumLabouOnlySubcontractors">
                Labour Only Subcontractors<span class="text-danger">*</span>
              </label>
              <div>
                <div *ngIf="!readOnly; else showSubContractors">
                  <input id="detailsNumLabouOnlySubcontractors"
                    class="form-control"
                    type="number"
                    formControlName="number_of_labour_only_subcontractors"
                    min="0"
                    step="1"
                    oninput="validity.valid||(value='');" />
                </div>
                <ng-template #showSubContractors>
                  {{companyDetails.numberOfLabourOnlySubcontractors}}
                </ng-template>
              </div>
              <span class="text-danger"
                    *ngIf="detailsForm.controls.number_of_labour_only_subcontractors.touched && detailsForm.controls.number_of_labour_only_subcontractors.errors?.required">
                *Labour Only Subcontractors is required
              </span>
            </div>

            <div *ngIf="!isAus">
              <label class="col-form-label" for="detailsTotalEffectiveEmployees">
                Total Effective Employees<span class="text-danger">*</span>
              </label>
            <div>
                <div *ngIf="!readOnly; else showEffectiveEmployees">
                  <input id="detailsTotalEffectiveEmployees"
                    class="form-control"
                    type="number"
                    formControlName="number_of_effective_employees"
                    min="0" />
                </div>
                <ng-template #showEffectiveEmployees>
                  {{companyDetails.numberOfEffectiveEmployees}}
                </ng-template>
              </div>
              <span class="text-danger"
                *ngIf="detailsForm.controls.number_of_effective_employees.touched && detailsForm.controls.number_of_effective_employees.errors?.required">
                *Total Effective Employees is required
              </span>
            </div>

            <div *ngIf="!isAus">
              <label class="col-form-label" for="detailsNumBonaFideSubcontractors">
                Bona Fide
                Subcontractors<span class="text-danger">*</span>
              </label>
            <div>
              <div *ngIf="!readOnly; else showBonaFideSubcontractors">
                <input id="detailsNumBonaFideSubcontractors"
                      class="form-control"
                      type="number"
                      formControlName="number_of_bona_fide_subcontractors"
                      min="0"
                      step="1"
                      oninput="validity.valid||(value='');" />
              </div>
              <ng-template #showBonaFideSubcontractors>
                {{companyDetails.numberOfBonaFideSubcontractors}}
              </ng-template>
            </div>

            <span class="text-danger"
                  *ngIf="detailsForm.controls.number_of_bona_fide_subcontractors.touched && detailsForm.controls.number_of_bona_fide_subcontractors.errors?.required">
              *Labour Only Subcontractors is required
            </span>
            </div>
        </div>

        
        <div>
          <label class="col-form-label">
            Registered Address:
          </label>
          <ng-container>
            <div>
              {{ registeredAddress }}
            </div>
          </ng-container>
        </div>


          <!-- Work categories. -->
          <div>
            <label class="col-form-label">Work Categories<span class="text-danger">*</span>:</label>
              <p *ngIf="!isAus">These are the work categories that your company will be assessed by.</p>
            <div>
              <app-work-categories
                *ngIf="currentUser"
                [accountId]="currentUser.AccountId"
                [selectedWorkCategories]="detailsForm.controls.workCategories.value">
                
              </app-work-categories>
            </div>
            <div>
              <button class="btn btn-secondary btn-block py-0" type="button" [disabled]="detailsForm.disabled" (click)="showWorkCategories()">
                Edit
              </button>
            </div>
          </div>

          <!-- Primary Work category. -->
          <div>
            <label class="col-form-label" for="detailsWhereHeard">Primary Work Category</label>
            <div>
              <div>
                <select id="primaryWorkCategory" class="custom-select" formControlName="primary_trade"
                [attr.disabled]="hasHealthAndSafetySubmitted ? true : null">
                  <option hidden value="" selected>Please select</option>
                  <option *ngFor="let row of detailsForm.controls.workCategories.value;trackBy:primaryTradeTrackBy" [Selected]="row.id===detailsForm.controls.primary_trade.value" [value]="row.id" >{{ row.fullName }}</option>   
                </select>
                <span class="text-warning" *ngIf="hasHealthAndSafetySubmitted ? true : null">
                  *Primary Work Category is no longer editable once any assessments have been submitted
                </span>
              </div>
            </div>
          </div> 
           <!-- Work areas. -->
          <div class="form-group mt-3 mb-3">
            <label class="col-form-label">{{ workAreaHeadingText }}<span class="text-danger">*</span>:</label>
            <div>
              <div>
                <app-work-areas *ngIf="currentUser" [accountId]="currentUser.AccountId" [workRegions]="detailsForm.controls.workRegions.value" [workStates]="selectedStates" [distance]="detailsForm.controls.distance.value" ></app-work-areas>
              </div>
            </div>
            <div *ngIf="!readOnly">
              <button class="btn btn-secondary btn-block py-0" type="button" [disabled]="detailsForm.disabled" (click)="showWorkAreas()">
                Edit
              </button>
            </div>
          </div>

            <label class="col-form-label" for="detailsSummary">
              Business Summary<span class="text-danger">*</span>:
            </label>
            <div>
              <div>
                <textarea id="detailsSummary" class="form-control" formControlName="summary" rows="5"
                          cols="30"></textarea>
                <!-- Character count. -->
                <div class="character-count mt-1 mb-2" *ngIf="detailsForm.controls.summary.valid">
                  <b>{{ detailsForm.value.summary.length | number }}</b> /
                  5000 character(s)
                </div>
              </div>
      
              <span class="text-danger"
                    *ngIf="detailsForm.controls.summary.touched && detailsForm.controls.summary.errors?.required">
                *Business Summary is required
              </span>
      
              <span class="text-danger"
                    *ngIf="detailsForm.controls.summary.touched && detailsForm.controls.summary.errors?.maxlength">
                *Business Summary has a max length of 5000 characters
              </span>
      
              <p class="business-summary-help">
                *Please provide a brief description of the services your business
                provides. This will be visible to Buyers when looking at your company.
              </p>
              <p>To edit further information about your company, please navigate to the <a (click)="close(true)">Company Details</a> page.</p>
            </div>

          <!-- Submit button. -->
          <div class="form-group text-right">
            <button type="submit"
              class="btn btn-lg btn-primary"
              [class.is-loading]="isSaving">
              Confirm
            </button>
        </div>
        </form>
      </section>
    </div>
  </div>
</div>
