<div class="order-summary" *ngIf="!isPageLoading">
  <h1 class="h2 mb-3">Thank you for choosing CHAS!</h1>

  <!-- Products ordered. -->
  <h2 class="h4">Please confirm your order</h2>
  <form [formGroup]="orderForm" (submit)="activateOpportunityAsync()">
    <table class="table table-borderless order-summary__basket mb-5">
      <tbody>
        <tr scope="row" *ngFor="let row of basketSummary.orderLines">
          <td>
            <span class="font-weight-bold"
              >{{ row.productName }}</span
            >
            <div class="application-types" *ngIf="row.discountMessage">
              {{ row.discountMessage }} <br />
            </div>
            <div class="application-types" *ngIf="!isPremier && !isFoundation && row.priceLevelName">
              Application type: {{ applicationType(basketItem.applicationType) }}
              <br />
            </div>
            <div class="application-types" *ngIf="!isFoundation && row.priceLevelName">
              Assessment type: {{ assessmentType(basketAccount.AssessmentType) }} <br />
            </div>
          </td>
          <td class="text-right">{{ row.subTotalAmount | currency: 'GBP' }}</td>
        </tr>
        <tr>
          <td>
            <b>Member benefits package</b><br /><span class="font-size: small"
              >Including extensive online support & guidance, access to a
              24-hour response line for H&S questions, discounts with Speedy
              Hire, Tradepoint and Checkatrade, exclusive offers on ERAMS,
              Fuelcard, and online workforce training</span
            >
          </td>
          <td class="text-right">Included</td>
        </tr>
        <tr scope="row" *ngIf="basketItem.fastTrack">
          <td>
            <b>IN A HURRY?</b><br />
            Upgrade to Fast Track and gain accreditation in under 2 working days
            *<br />
            Cost: £{{ basketItem.fastTrack }}
          </td>
          <td class="text-right">
            <div class="custom-control custom-checkbox nowrap">
              <input
                id="checkFastTrack"
                class="custom-control-input"
                type="checkbox"
                aria-describedby="checkFastTrack"
                formControlName="checkFastTrack"
                (change)="toggleFastTrack($event)"
              />
              <label
                id="checkFastTrackLabel"
                class="custom-control-label"
                for="checkFastTrack"
                >Add to order</label
              >
            </div>
          </td>
        </tr>
        <tr scope="row" *ngIf="basketSummary.totalDiscountAmount">
          <td>Discount</td>
          <td class="text-right">{{ basketSummary.totalDiscountAmount | currency: 'GBP' }}</td>
        </tr>
        <tr scope="row" *ngIf="basketSummary.totalTaxAmount > 0">
          <td>VAT</td>
          <td class="text-right">{{ basketSummary.totalTaxAmount | currency: 'GBP' }}</td>
        </tr>
        <tr scope="row" class="border-top font-weight-bold">
          <td>Total</td>
          <td class="text-right">{{ basketSummary.totalGrossAmount | currency: 'GBP' }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Checkboxes. -->
    <div class="form-group row justify-content-between mb-5">
      <div class="col-md-12 mb-3">
        <div class="custom-control custom-checkbox" *ngIf="isPremier">
          <input
            id="checkBuildUk"
            class="custom-control-input"
            type="checkbox"
            aria-describedby="checkBuildUk"
            formControlName="confirmBuildUk"
          />
          <label
            id="checkBuildUkLabel"
            class="custom-control-label"
            for="checkBuildUk"
          >
            I agree to relevant information held by CHAS being shared with other
            Recognised Assessment Bodies for the Common Assessment Standard. I
            am an authorised representative with sufficient delegated powers to
            provide the consent specified above.<br />
            Click
            <a
              href="/assets/static/Key-Questions-for-Data-Sharing-Version-2.pdf"
              target="_blank"
              >here</a
            >
            for more information on the Common Assessment Standard and data
            sharing. <br /><br />
          </label>
        </div>

        <div class="custom-control custom-checkbox">
          <input
            id="checkContact"
            class="custom-control-input"
            type="checkbox"
            aria-describedby="checkContact"
            formControlName="confirmContact"
          />
          <label
            id="checkContactLabel"
            class="custom-control-label"
            for="checkContact"
          >
            By placing this order I confirm that I have read and accepted the
            CHAS
            <a
              href="https://www.chas.co.uk/contractor-terms-conditions/" target="_blank">
              Terms & Conditions
            </a>
            and
            <a href="https://www.chas.co.uk/privacy-policy/" target="_blank">
              Privacy Policy
            </a>
          </label>
        </div>
        <br />
        <p>Payments are processed by Stripe.</p>

        <div *ngIf="basketItem.fastTrack && !isAssuredProduct">
          <span style="font-size: smaller">
            <b> * FAST TRACK: 2 working days from submission. </b><br />
            If not all questions are correctly answered, further information may
            be required which could delay the process.<br />
            Any verifications &
            DTS applications will be completed within 1 working day.<br />
            CHAS Standard £{{ hsFastTrackPrice }}, CHAS Advanced £{{ ppFastTrackPrice }},
            CHAS Elite £{{ prFastTrackPrice }}
          </span>
        </div>
      </div>
    </div>

    <!-- Buttons. -->
    <div class="form-group row">
      <button
        class="btn btn-lg btn-primary"
        type="submit"
        [disabled]="!isOrderFormValid()"
        [class.is-loading]="isLoading"
        style="margin: 0 auto"
      >
        Pay now by Credit or Debit Card
      </button>
    </div>
    <div class="form-group row">
      <a class="btn btn-link" (click)="goBack()" style="margin: 0 auto"
        >Change my product selection</a
      >
    </div>

    <!-- Error message. -->
    <alert type="danger" *ngIf="error">There was an error processing your order, please try again later.</alert>
  </form>

  <!-- Company details. -->
  <h2 class="h4">Here is the information you've given us</h2>
  <div class="order-summary__details m-2 mb-5" *ngIf="account">
    <div class="form-group row">
      <div class="col-md-4 font-weight-bold">Company Registration Number</div>
      <div class="col-md-8">{{ account.CompanyHouseNo }}</div>
    </div>
    <div class="form-group row">
      <div class="col-md-4 font-weight-bold">Company Name</div>
      <div class="col-md-8">{{ account.CompanyName }}</div>
    </div>
    <div class="form-group row">
      <div class="col-md-4 font-weight-bold">Registered Office address</div>
      <div class="col-md-8">{{ companyAddress }}</div>
    </div>
    <div class="form-group row">
      <div class="col-md-4 font-weight-bold">
        Trading / Correspondence address
      </div>
      <div class="col-md-8" *ngIf="!editingAddress">
        {{ companyAddress }}
        <button
          class="btn btn-sm btn-secondary"
          type="button"
          (click)="beginEditTradingAddress()"
        >
          Edit
        </button>
      </div>
      <form [formGroup]="addressForm" *ngIf="editingAddress" class="col-md-8">
        <div class="form-group">
          <label class="sr-only" for="taLine1">Address Line 1</label>
          <input
            type="text"
            id="taLine1"
            class="form-control"
            placeholder="Address Line 1"
            formControlName="Line1"
          />
        </div>
        <div class="form-group">
          <label class="sr-only" for="taLine2">Address Line 2</label>
          <input
            type="text"
            id="taLine1"
            class="form-control"
            placeholder="Address Line 2"
            formControlName="Line2"
          />
        </div>
        <div class="form-group">
          <label class="sr-only" for="taCity">City</label>
          <input
            type="text"
            id="taCity"
            class="form-control"
            placeholder="City"
            formControlName="City"
          />
        </div>
        <div class="form-group">
          <label class="sr-only" for="taPostcode">Postcode</label>
          <input
            type="text"
            id="taPostcode"
            class="form-control"
            placeholder="Postcode"
            formControlName="Postcode"
          />
        </div>
        <div class="form-group">
          <label class="sr-only" for="taCountry">Country</label>
          <select
            id="taCountry"
            formControlName="CountryCode"
            class="form-control"
            placeholder="Country"
          >
            <option value="" disabled selected>Country</option>
            <option *ngFor="let country of countries" [value]="country.code">
              {{ country.shortName }}
            </option>
          </select>
        </div>
        <button
          class="btn btn-secondary btn-sm"
          type="button"
          (click)="cancelEditTradingAddress()"
        >
          <i class="fa fa-times"></i> Same as Registered Office
        </button>
      </form>
    </div>
    <div class="form-group row">
      <div class="col-md-4 font-weight-bold">Contact Name</div>
      <div class="col-md-8">
        {{ account.ContactFirstName }} {{ account.ContactLastName }}
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4 font-weight-bold">Contact Email Address</div>
      <div class="col-md-8">{{ account.EmailAddress }}</div>
    </div>
    <div class="form-group row">
      <div class="col-md-4 font-weight-bold">Contact Phone Number</div>
      <div class="col-md-8">{{ account.Telephone1 }}</div>
    </div>
    <div class="form-group row">
      <div class="col-md-4 font-weight-bold">Effective number of Employees</div>
      <div class="col-md-8">{{ account.NoOfEmployees }}</div>
    </div>
    <div>
      <p>Your figure should include the following:</p>
      <ul>
        <li>
          Directly employed office staff including managers, directors, clerical
          etc.
        </li>
        <li>Directly employed site staff.</li>
        <li>
          Labour only workers.
          <i class="fas fa-info-circle" [tooltip]="employeeInfo"></i>
        </li>
      </ul>
    </div>
  </div>
</div>
