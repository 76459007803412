export class ProductName {
    public static level1 = "Standard";
  
    public static level2 = "CHAS Premium";
    public static level2Assured = "CHAS Premium Assured";
  
    public static level3 = "Advanced";
    public static level3Assured = "Advanced Assured";
  
    public static level4 = "Elite";
    public static level4Assured = "Elite Assured";
  }
  