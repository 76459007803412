export interface Assessment {
  id: string;
  accountId: string;
  paymentAmount: number;
  assessmentNumber: string;
  name: string;
  FIRAverageAccreditationLevel: number;
  product: {
    id: string;
    name: string
    description: string;
    value: string;
    family: string;
  };
  status:{
    code: number;
    label: string;
  };
  displayStatus:{
    code: number;
    label: string;
  };
  percentageComplete: number;
  numberOfEmployees: number;
  assessmentType:{
    code: number;
    label: string;
  };
  variant: {
    code: number;
    label: string;
  };
  created: string; // DD-MM-YYYY
  decisionDate: string; // DD-MM-YYYY
  targetDecisionDate: string; // DD-MM-YYYY
  submittedDate: string; // DD-MM-YYYY
  resubmittedDate: string; // DD-MM-YYYY
  expiryDate: string; // DD-MM-YYYY
  dateEntered: string; // DD-MM-YYYY
  acceptedDate: string; // DD-MM-YYYY
  dateStarted: string; // DD-MM-YYYY
  assignedDate: string; // DD-MM-YYYY
  assessedDate: string; // DD-MM-YYYY
  returnedToContractor: string; // DD-MM-YYYY
  requiredToRespond: string; // DD-MM-YYYY
  certificateIssued: string; // DD-MM-YYYY
  initialDateRequiredToRespond: string; // DD-MM-YYYY
  membershipExpiry: string; // DD-MM-YYYY
  orderType: string;
  contractorName: string;
  questionSetIDs: string[];
  validForRenewal: boolean;
  clonedHistoricalAnswers: boolean;
  userApprovedHistoricalAnswers: boolean;
  hasAlerts: boolean;
  fastTrack: boolean;
  priceLevel: string;
  bundleName: string;
}
