export interface AccountContact {
  accountId: string;
  contactId: string;
  firstname: string;
  lastname: string;
  email: string;
  telephone: string;
  telephone1?: string;
  mobilephone?: string;
  contactname: string;
  isPrimaryContact: boolean;
  position: string;
  password: string; // hashed password!
  userType: string;
  accountStatus: string;
  organisationId: string;
  chasAlertFrequency: string;
  isLogMeInContact: boolean;
}
