import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../../../app.state';

export const selectCompanyDetailsFeatureState = createFeatureSelector<State>('data');

export const getCompanyAccountId = createSelector(selectCompanyDetailsFeatureState, (state) => state.companyDetails?.id.uid);

export const getCompanySummary = createSelector(selectCompanyDetailsFeatureState, (state) => {
  return {
    companyAccountID: state.companyDetails.id.uid,
    registrationNumber: state.companyDetails.id.companyHouseNo,
    companyName: state.companyDetails.id.name,
    registeredAddress: state.companyDetails.registerAddress,
    correspondenceAddress: state.companyDetails.correspondenceAddress,
  };
});
