export const hsFromPrice = "239";
export const ppFromPrice = "389";
export const prFromPrice = "539";

export const hsFastTrackPrice = "129";
export const ppFastTrackPrice = "189";
export const prFastTrackPrice = "239";

export const hsFromPrice092021 = "279";
export const ppFromPrice092021 = "449";
export const prFromPrice092021 = "629";

export const hsFastTrackPrice092021 = "149";
export const ppFastTrackPrice092021 = "219";
export const prFastTrackPrice092021 = "279";
