import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  UploadRequest,
  DeleteRequest,
  DocumentResponse,
  DocumentCategoryResponse,
  DocumentExpiryResponse,
  QuestionInfo,
  ValidationResult,
} from '../../shared';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { AuthService } from './auth.service';
import { CertificateNameResponse } from '../../shared/interfaces/certificate-name-response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  environment: IEnvironment;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    const env = new Environment();
    this.environment = env.getConfigSync();
  }

  public delete(request: DeleteRequest) {
    const url = `${this.environment.apiEndpoint.documents}/accounts/${request.ContractorId}/documents`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: request,
    };
    return this.http.delete(url, options);
  }

  public upload(request: UploadRequest) {
    if (request && request.Files) {
      const url = `${this.environment.apiEndpoint.documents}/accounts/${request.ContractorId}/documents`;

      const formData = new FormData();
      if (request.AssessmentId) {
        formData.append('AssessmentIds', request.AssessmentId);
      }
      if (request.Module) {
        formData.append('Module', request.Module);
      }

      if (request.Answer) {
        formData.append('AnswerIds', request.Answer);
      }

      if (request.DocumentType) {
        formData.append('DocumentType', request.DocumentType);
      }

      if (request.DocumentSource) {
        formData.append('DocumentSource', request.DocumentSource);
      }

      request.Files.map((f) => {
        formData.append(f.name, f);
      });

      return this.http.post<any[]>(url, formData);
    }
  }

  public getAll(contractorId) {
    const url = `${this.environment.apiEndpoint.documents}/accounts/${contractorId}/documents`;
    return this.http.get(url).pipe(map((data) => data as DocumentCategoryResponse[]));
  }

  public getFor(contractorId, numberOfDays) {
    let url = `${this.environment.apiEndpoint.documents}/accounts/${contractorId}/numberOfDays/${numberOfDays}/documents`;
    if (numberOfDays === 0) url = `${this.environment.apiEndpoint.documents}/accounts/${contractorId}/documents/numberOfDays`;

    return this.http.get(url).pipe(map((data) => data as DocumentResponse[]));
  }

  public canDelete(document: DocumentResponse) {
    if (document.type) {
      return document.type.toLowerCase().indexOf('shared') === -1;
    }
    return true;
  }

  public expiring(contractorId: string) {
    const url = `${this.environment.apiEndpoint.documents}/accounts/${contractorId}/documents/expiring`;
    return this.http.get(url).pipe(map((data) => data as DocumentExpiryResponse[]));
  }

  public expired(contractorId: string) {
    const url = `${this.environment.apiEndpoint.documents}/accounts/${contractorId}/documents/expired`;
    return this.http.get(url).pipe(map((data) => data as DocumentExpiryResponse[]));
  }

  public download(documentUri: string) {
    return this.http.get(documentUri, { responseType: 'blob' });
  }

  public downloadInvoice(accountId: string) {
    const url = `${this.environment.apiEndpoint.documents}/accounts/${accountId}/invoice`;
    return this.download(url);
  }

  public rewriteUrl(accountId: string, documentUri: string): string {
    const fileNamePotentiallyEncoded = documentUri.split('/').pop();
    let fileName = fileNamePotentiallyEncoded;

    if (this.isBase64(fileNamePotentiallyEncoded)) {
      fileName = atob(fileNamePotentiallyEncoded);
    }

    return `${this.environment.apiEndpoint.documents}/accounts/${accountId}/documents/${fileName}`;
  }

  public getExtension(fileName: string): string {
    return fileName.split('.').pop();
  }

  public getCertificateName(accountId: string, bundleId: string): Observable<CertificateNameResponse> {
    const url = `${this.environment.apiEndpoint.documents}/accounts/${accountId}/bundles/${bundleId}/certificates`;
    return this.http.get<CertificateNameResponse>(url);
  }

  viewDocumentLink(documentLink: string) {
    const fileName = documentLink.split('/').pop();
    const extension = this.getExtension(fileName);
    let contentType = 'octet/stream';
    let options = '';

    if (extension.toLowerCase() === 'pdf') {
      contentType = 'application/pdf';
      options = 'width=700, height=480';
    }
    this.download(documentLink).subscribe((data) => this.downloadBlob(data, contentType, options, fileName));
  }

  viewDocument(accountId: string, document: DocumentResponse) {
    let contentType = '';
    let options = 'width=700, height=480';
    switch (document.extension) {
      case 'pdf':
        contentType = 'application/pdf';
        break;
      case 'txt':
        contentType = 'text/plain';
        break;
      case 'png':
        contentType = 'image/png';
        break;
      case 'jpg':
        contentType = 'image/jpg';
        break;
      case 'jpeg':
        contentType = 'image/jpeg';
        break;
      default:
        contentType = 'application/octet-stream';
        options = '';
        break;
    }
    this.downloadDocument(accountId, document, contentType, options);
  }

  downloadDocument(accountId: string, document: DocumentResponse, contentType: string, options: string) {
    const contentUrl = document.uri.indexOf('downloadfile') > -1 ? this.rewriteUrl(accountId, document.uri) : document.uri;
    const fileName = document.name ?? contentUrl.split('/').pop();
    this.download(contentUrl).subscribe((data) => this.downloadBlob(data, contentType, options, fileName));
  }

  downloadBlob(data: any, contentType: string, options: string, fileName: string) {
    const blob = new Blob([data], { type: contentType });
    const url = window.URL.createObjectURL(blob);

    if (!options) {
      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = url;
      anchor.style.visibility = 'hidden';
      anchor.click();
      window.URL.revokeObjectURL(url);
      anchor.remove();
    } else {
      window.open(url, fileName, options);
    }
  }

  getQuestionInfo(options: any): QuestionInfo {
    const parentQuestionId = options.cellQuestion ? options.question.name : null;
    const questionId = options.cellQuestion ? options.cellQuestion.name : options.question.name;

    if (options.question.getType() === 'matrixdynamic') {
      const uploadedFiles = options.cellQuestion.value ? options.cellQuestion.value : [];
      const rowIndex = options.row.index;
      return {
        rowIndex: rowIndex,
        parentQuestionId: parentQuestionId,
        questionId: questionId,
        isMatrixQuestion: true,
        uploadedFiles: uploadedFiles,
      } as QuestionInfo;
    } else if (options.question.getType() === 'file') {
      const uploadedFiles = options.question.value ? options.question.value : [];
      return {
        rowIndex: null,
        parentQuestionId: parentQuestionId,
        questionId: questionId,
        isMatrixQuestion: false,
        uploadedFiles: uploadedFiles,
      } as QuestionInfo;
    }
  }

  private isBase64(str): boolean {
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }
}
