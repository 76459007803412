import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserMenuComponent } from './user-menu.component';
import { UsernameComponent } from './username/username.component';
import { AccountMenuComponent } from './account-menu/account-menu.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RouterModule } from '@angular/router';
import { DocumentModalModule } from '../document-modal/document-modal.module';
import { PrivacyModalModule } from '../privacy-modal/privacy-modal.module';
import { NotifyDirectiveDirective } from './notifications/directive/notify-directive.directive';
import { DocumentNotificationDisplayComponent } from './notifications/display/document-notification-display/document-notification-display.component';
import { NotificationDisplayContainerComponent } from './notifications/display/notification-display-container/notification-display-container.component';
import { AccountMatchNotificationDisplayComponent } from './notifications/display/account-match-notification-display/account-match-notification-display.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    UserMenuComponent,
    UsernameComponent,
    AccountMenuComponent,
    NotificationsComponent,
    NotifyDirectiveDirective,
    DocumentNotificationDisplayComponent,
    NotificationDisplayContainerComponent,
    AccountMatchNotificationDisplayComponent
  ],
  exports: [UserMenuComponent],
  imports: [
    CommonModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    DocumentModalModule,
    PrivacyModalModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatIconModule
  ]
})
export class UserMenuModule {}
