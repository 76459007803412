import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DocumentResponse } from '../../../../shared';
import { DocumentService } from '../../../../core';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent {

  constructor(private documentService: DocumentService) {
  }

  @Input() documents: DocumentResponse[];
  @Input() isAdmin: boolean;
  @Output() delete = new EventEmitter<DocumentResponse>();
  @Output() sort = new EventEmitter<any>();
  @Input() accountId: string;

  direction: string;
  column: string;
  sortIcon: string;

  sortBy(column) {
    this.direction = this.direction === 'asc' ? 'desc' : 'asc';
    this.sortIcon = this.direction === 'asc' ? 'up' : 'down';
    this.column = column;
    this.sort.emit({ column: column, direction: this.direction });
  }

  deleteDocument(document: DocumentResponse) {
    this.delete.emit(document);
  }

  viewDocument(document: DocumentResponse) {
    this.documentService.viewDocument(this.accountId, document);
  }

  displayDelete(document: DocumentResponse) {
    return this.documentService.canDelete(document);
  }
}
