<div class="inner_content">
  <div class="card_text" *ngIf="!activeCard.active && !activeCard.credits">
    <p>Manage your workers' credentials, skills and training in one easy-to-use dashboard.</p>
    <p>Reduce risk, save time and money and simplify the entire compliance process. </p>
  </div>

  <div class="card_text" *ngIf="activeCard.credits">
    <ul>
      <li>
        {{activeCard.credits}} Workers
        <i class="fas fa-info-circle" tooltip="date of expiry" style="font-size: 12px;"></i>
      </li>
    </ul>
  </div>

  <div class="card_buttons" *ngIf="!activeCard.active && !activeCard.credits">
    <button class="btn btn-primary not_active" (click)="peopleModal.show();pushGoogleTag();">Buy credits and get
      started</button>
  </div>

  <div class="card_buttons" *ngIf="activeCard.credits">
    <button class="btn btn-primary on_card" [class.is-loading]="isLoading" (click)="loginAsync()"
      *ngIf="activeCard.active">Log in</button>
    <button class="btn btn-primary on_card" (click)="peopleModal.show();pushGoogleTag();">Buy credits</button>
  </div>
</div>



<div bsModal #peopleModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-sizes-name1" class="modal-title pull-left">CHAS People – The smarter way of working</h4>
        <button type="button" class="close pull-right" (click)="peopleModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <img class="bundle-image" [src]="productImage">

        <h5>Features</h5>
        <ul>
          <li>ID verification</li>
          <li>Collection & storage of credentials</li>
          <li>Expiry date reminders</li>
          <li>Highlights missing credentials</li>
          <li>Credential management on 1 easy-to-use dashboard</li>
          <li>COVID-19 health status tracking</li>
        </ul>
        <h5>Benefits</h5>
        <ul>
          <li>Helps to comply with the Management of Health and safety at Work Regulations</li>
          <li>Helps to comply with Health and Safety at Work Act S.2</li>
          <li>Resident and right to work status checks assist with the Modern Slavery Act</li>
          <li>For construction activities, this product helps to comply with the Construction (Design and Management)
            regulations around skills, knowledge, training and experience</li>
        </ul>


        <form [formGroup]="purchasePeopleForm">
          <div class="form-group">
            <label>Get Your Quote:</label>
            <div class="form-group">
              <input class="form-control" type="number" formControlName="numWorkers"
                placeholder="How many employees do you have?" (change)="getQuoteAsync()" (keyup)="getQuoteAsync()"
                min="1" step="1" oninput="validity.valid||(value='');" />
            </div>
          </div>
          <div class="custom-control custom-checkbox">
            <input id="checkPeople" class="custom-control-input" type="checkbox" aria-describedby="checkPeople"
              formControlName="confirmPeople">
            <label id="checkPeopleLabel" class="custom-control-label" for="checkPeople">
              By placing this order I confirm that I have read and accepted the CHAS People
              <a href="{{links.PEOPLE_TERMS}}" target="_blank">Terms & Conditions</a> and
              <a href="{{links.PEOPLE_PRIVACY}}" target="_blank">Privacy Policy</a>
            </label>
          </div>
        </form>

        <div class="quote-container">
          <app-quote-container [amount]="quotePrice" [loading]="loadingQuote" [title]="'Price:'"
            [showVatLabel]="quoteHasVat"></app-quote-container>
          <button type="button" class="btn btn-primary" (click)="activateAync()" [class.is-loading]="modalbtnLoading"
            [disabled]="!purchasePeopleForm.valid">
            Pay now
          </button>
        </div>

      </div>
    </div>
  </div>
</div>