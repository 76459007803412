<div class="dropdown" *ngIf="contact" dropdown>
  <!-- Dropdown button representing a menu. -->
  <button dropdownToggle
          id="dropdownAccountButton"
          class="account-menu__button btn"
          aria-controls="dropdownAccountMenu"
          type="button">

    <span>{{contact.contactname}}</span>
    <span class="small-font dropdown-toggle">({{contact.email}})</span>
  </button>

  <!-- Dropdown menu. -->
  <div *dropdownMenu id="dropdownAccountMenu" class="dropdown-menu" aria-labelledby="dropdownAccountButton">
    <a class="dropdown-item" routerLink="/admin/users/my-details">
      <i class="fa fa-lg fa-fw fa-user"></i>
      My Details
    </a>
   <a class="dropdown-item pointer" (click)="resetPassword()">
      <i class="fa fa-lg fa-fw fa-lock"></i>
      Change my password
    </a>
    <a class="dropdown-item" routerLink="/admin/users" *ngIf="getUserPermission !== 'No Access'">
      <i class="fa fa-lg fa-fw fa-users"></i>
      User Management
    </a>
    <a class="dropdown-item pointer" *ngIf="enableDataSharingOption" (click)="peopleModal.show();">
      <i class="fa fa-lg fa-fw fa-building"></i>
      Data Sharing Preferences
    </a>
  </div>
</div>

<div bsModal #peopleModal="bs-modal" class="modal fade"
     tabindex="-1" role="dialog" aria-labelledby="data-sharing-cas-dialog">
  <app-privacy-modal *ngIf="currentUser" [modalRef]="peopleModal" [accountId]="currentUser.accountId"></app-privacy-modal>
</div>
