
import { createAction, props } from '@ngrx/store';
import { IRegisterstepfour } from 'src/app/shared/interfaces/new/registerStepFour';

export enum RegisterstepfourActions {
  Add = '[Registerstepfour] Add',
  AddSuccess = '[Registerstepfour] Add Successful',
  AddError = '[Registerstepfour] Add Error',
  Remove = '[Registerstepfour] Remove',
  RemoveSuccess = '[Registerstepfour] Remove Successful',
  RemoveError = '[Registerstepfour] Remove Error',
  Load = '[Registerstepfour] Load',
  LoadSuccess = '[Registerstepfour] Load Successful',
  LoadError = '[Registerstepfour] Load Error'
}

export const registerStepFourAdd = createAction(RegisterstepfourActions.Add, props<{ registerStepFour: IRegisterstepfour }>());
export const registerStepFourAddSuccess = createAction(RegisterstepfourActions.AddSuccess, props<{ payload: IRegisterstepfour }>());
export const registerStepFourAddError = createAction(RegisterstepfourActions.AddError);

export const registerStepFourRemove = createAction(RegisterstepfourActions.Remove);
export const registerStepFourRemoveSuccess = createAction(RegisterstepfourActions.RemoveSuccess, props<{ payload: IRegisterstepfour }>());
export const registerStepFourRemoveError = createAction(RegisterstepfourActions.RemoveError);

export const registerStepFourLoad = createAction(RegisterstepfourActions.Load);
export const registerStepFourLoadSuccess = createAction(RegisterstepfourActions.LoadSuccess, props<{ payload: IRegisterstepfour }>());
export const registerStepFourLoadError = createAction(RegisterstepfourActions.LoadError);
