import { Component, OnInit } from '@angular/core';
import { Environment } from '../../classes/environment';
import { Territory } from '../../constants/territory.enum';

export enum HelplinePhoneNumber {
  AUSTRALIA = '+61 8 6186 3674',
  UNITED_KINGDOM = '0345 521 9222'
}

export enum SalesEmailAddress {
  AUSTRALIA = 'sales@chas.net.au',
  UNITED_KINGDOM = 'sales@chas.co.uk'
}

export enum HelpdeskEmailAddress {
  AUSTRALIA = 'helpdesk@chas.net.au',
  UNITED_KINGDOM = 'admin@chas.co.uk'
}

export enum RegisterUrl {
  AUSTRALIA = 'https://my.chas.net.au/register',
  UNITED_KINGDOM = 'https://my.chas.co.uk/register'
}

export enum MainUrl {
  AUSTRALIA = 'https://www.chas.net.au',
  UNITED_KINGDOM = 'https://www.chas.co.uk'
}

export enum FAQUrl {
  AUSTRALIA = 'https://www.chas.net.au/faq',
  UNITED_KINGDOM = 'https://www.chas.co.uk/faq'
}

export enum OperatingHours {
  AUSTRALIA = '8:00am - 6:00pm AEST Monday - Friday',
  UNITED_KINGDOM = '8:00am - 5:30pm Monday - Friday'
}

@Component({
  selector: 'app-register-carousel',
  templateUrl: './register-carousel.component.html',
  styleUrls: ['./register-carousel.component.scss']
})
export class RegisterCarouselComponent implements OnInit {
  public carouselIndex: number = 0;
  private carouselInterval;
  public isAus = false;
  public phoneNumber = HelplinePhoneNumber.UNITED_KINGDOM;
  public emailAddress = SalesEmailAddress.UNITED_KINGDOM;
  public registerUrl = RegisterUrl.UNITED_KINGDOM;
  public faqUrl = FAQUrl.UNITED_KINGDOM;
  public mainUrl = MainUrl.UNITED_KINGDOM;
  public operatingHours = OperatingHours.UNITED_KINGDOM;
  private amountOfSlidesIndex = 2;

  async ngOnInit(): Promise<void> {
    const env = new Environment();
    const environment = await env.getConfig();
    this.isAus = environment.territory === Territory.AUSTRALIA;

    if(this.isAus) {
      this.phoneNumber = HelplinePhoneNumber.AUSTRALIA;
      this.emailAddress = SalesEmailAddress.AUSTRALIA;
      this.registerUrl = RegisterUrl.AUSTRALIA;
      this.faqUrl = FAQUrl.AUSTRALIA;
      this.mainUrl = MainUrl.AUSTRALIA;
      this.operatingHours = OperatingHours.AUSTRALIA;
      this.amountOfSlidesIndex = 1;
    }

    this.carouselInterval = setInterval(() => {
      if(this.carouselIndex === this.amountOfSlidesIndex) {
        this.carouselIndex = 0;
      }  else {
        this.carouselIndex += 1;
      }
    }, 20000);
  }

  public setCarouselIndex(index: number): void {
    clearInterval(this.carouselInterval);
    this.carouselIndex = index;
  }
}
