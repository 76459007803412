import { createReducer, on } from '@ngrx/store';
import { msalAuthAddSuccess, msalAuthLoadSuccess, msalAuthRemoveSuccess } from '../actions/msalAuth.actions';

export const initialState = { loaded: false, payload: null };

const _msalAuthReducer = createReducer(
  initialState,
  on(msalAuthAddSuccess, (state, action) => action.payload),
  on(msalAuthLoadSuccess, (state, action) => action.payload),
  on(msalAuthRemoveSuccess, (state, action) => action.payload),
);

export function msalAuthReducer(state, action) {
  return _msalAuthReducer(state, action);
}
