import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoadFeatureFlags, LoadFeatureFlagsError, LoadFeatureFlagsSuccess } from '../actions/feature-flag.actions';
import { FeatureFlagService } from '../../services/feature-flags/feature-flag.service';
import { FeatureFlag } from '../../services/feature-flag';

@Injectable()
export class FeatureFlagEffects {
  loadFeatureFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFeatureFlags),
      mergeMap((action) =>
        this.featureFlagService.getApplicationFlags$(action.applicationName).pipe(
          map((featureFlags) => {
            const enabledFlags: FeatureFlag[] = featureFlags.filter((flag) => flag.enabled == true);
            return LoadFeatureFlagsSuccess({
              featureFlags: enabledFlags,
            });
          }),
          catchError((error) => of(LoadFeatureFlagsError({ error: error })))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly featureFlagService: FeatureFlagService
  ) {}
}
