import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardPageModule } from './dashboard/dashboard.module';
import { AssessmentQuestionsetPageModule } from './assessments/questionset/questionset.module';
import { DocumentsPageModule } from './documents/documents.module';
import { CompanyDetailsPageModule } from './company-details/company-details.module';
import { MembershipPageModule } from './membership/membership.module';
import { RegisterPageModule } from './register/register.module';
import { AdminUsersModule } from './admin-users/admin-users.module';
import { AssessmentsPageModule } from './assessments/assessments.module';
import { OrderSummaryPageModule } from './order-summary/order-summary.module';
import { RegisterExistingPageModule } from './register-existing/register-existing.module';
import { QuestionsetTestPageModule } from './assessments/test/test.module';
import { StripeLandingModule } from './stripe-landing/stripe-landing.module';
import { OAuthCallBackComponent } from './oauth-call-back/oauth-call-back.component';
import { LoadingSpinnerModule } from '../shared/components/loading-spinner/loading-spinner.module';
import { ChasAdminComponent } from './chas-admin/chas-admin.component';
import { SplashPageComponent } from './splash-page/splash-page.component';

// A module that contains all the page components.

@NgModule({
  declarations: [OAuthCallBackComponent, ChasAdminComponent, SplashPageComponent],
  imports: [
    CommonModule,
    AdminUsersModule,
    CompanyDetailsPageModule,
    DashboardPageModule,
    DocumentsPageModule,
    MembershipPageModule,
    AssessmentsPageModule,
    RegisterPageModule,
    RegisterExistingPageModule,
    AssessmentQuestionsetPageModule,
    OrderSummaryPageModule,
    QuestionsetTestPageModule,
    StripeLandingModule,
    LoadingSpinnerModule,
  ],
})
export class FeaturesModule {}
