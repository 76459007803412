<ng-template #modalSecuritySettings>
  <form [formGroup]="settingsForm" class="modal-content" (submit)="save()">
    <div class="modal-header">
      <h4 id="dialog-static-name" class="modal-title pull-left">Security Settings</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group row" *ngFor="let row of fields; trackBy: trackByFn">
        <div class="col-md-5">
          <label [attr.for]="row.key" class="form-label mt-1">
            {{ row.label }}
            <app-helper>{{ row.helper }}</app-helper>
          </label>
        </div>
        <div class="col-md-7">
          <select class="custom-select" [id]="row.key" [formControlName]="row.key">
            <option *ngFor="let option of row.options; trackBy: trackByFn" [value]="option.id">
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <alert type="danger" *ngIf="error">{{ error }}</alert>
      <button class="btn btn-link" type="button" (click)="close()">Cancel</button>
      <button class="btn btn-primary" type="submit" [disabled]="isSaving">Update Security Settings</button>
    </div>
  </form>
</ng-template>
