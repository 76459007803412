<div>
  <app-user-username></app-user-username>
  <div class="user-menu__right">
    <div>
      <!-- The current user's account menu. -->
      <app-user-account-menu></app-user-account-menu>

      <!-- A list of notifications for the user. -->
      <app-user-notifications></app-user-notifications>
    </div>
    <button class="btn" type="button" (click)="startTour()" title="Help">
      <i class="fa fa-lg fa-fw fa-question-circle"></i>
    </button>
    <button class="btn" type="button" (click)="logout()" title="Log out" *ngIf="showLogout">
      <i class="fa fa-lg fa-fw fa-sign-out"></i>
    </button>
  </div>
</div>
