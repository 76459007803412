<div class="inner_content">
  <div class="card-text">
    <p>{{ description }}</p>
    <div *ngIf="hasFir && assessment">
    <p><strong>Status: {{status}}</strong></p>
    <img
      class="accreditation-image" 
      *ngIf="assessment.firAverageAccreditationLevel"
      [src]="
        '/assets/img/accreditation/fir-accredited-' +
        assessment.firAverageAccreditationLevel +
        '.png'
      " 
      alt="FIR Accredited"
    />
    </div>
  </div>
  <div 
    class="card-buttons"
    *ngIf="
      hasFir &&
      assessment
    "
    [ngSwitch]="status">
    <div *ngSwitchCase="AssessmentStatus.SUBMITTED">
      Your assessment is currently {{ status }}
    </div>
    <div *ngSwitchCase="AssessmentStatus.RESUBMITTED">
      Your assessment is currently {{ status }}
    </div>
    <div *ngSwitchCase="AssessmentStatus.IN_PROGRESS">
      Your assessment is currently {{ status }}
    </div>
    <div *ngSwitchCase="AssessmentStatus.REJECTED">
      Your assessment is currently {{ status }}
    </div>
    <div *ngSwitchCase="AssessmentStatus.ACCEPTED">
      Your assessment is currently {{ status }}
    </div>
    <div *ngSwitchCase="AssessmentStatus.INFORMATION_REQUIRED">
      Your assessment is currently {{ status }}
    </div>
    <div *ngSwitchCase="AssessmentStatus.COMPLETE">
      <div *ngIf="documents$ | async as documents">
        <div *ngIf="findReport(documents) as document">
          <button class="btn btn-primary not_active" (click)="download(document)">
            Download Report
          </button>
        </div>
        <div *ngIf="documents[documentNames.Certificate] as document">
          <button class="btn btn-primary not_active" (click)="download(document)">
            Download Certificate
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchDefault>There was an error retrieving your FIR status</div>
  </div>
  <div
    class="card_buttons"
    *ngIf="!hasFir">
    <button class="btn btn-primary on_card" (click)="firModal.show(); pushGoogleTag()">
      Get started
    </button>
    <a class="btn btn-secondary on_card" href="https://www.chas.co.uk/chas-fir-accreditation" target="_blank">Learn more</a>
  </div>
  <div
    class="card-buttons"
    *ngIf="hasFir && (
      status !== AssessmentStatus.COMPLETE &&
      status !== AssessmentStatus.SUBMITTED
    )">
    <button class="btn btn-primary on_card" [routerLink]="['/assessments']">
      Complete my assessment
    </button>
  </div>
</div>

<div bsModal #firModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-sizes-name1" class="modal-title pull-left">
          Fairness Inclusion and Respect Growth Assessment
        </h4>
        <button type="button" class="close pull-right" (click)="firModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img class="bundle-image" [src]="productImage" alt="Fairness, Inclusion and Respect Growth Assessment" />

        <p>
          Embed the principles and business benefits of a fairer, more inclusive
          and respectful workplace:
        </p>

        <ul>
          <li>Attract, recruit and retain a diverse work force</li>
          <li>Increase employee engagement</li>
          <li>Become more innovative, collaborative and productive</li>
          <li>Address workplace cultural challenges</li>
          <li>Enhance reputation by being a part of an industry-wide initiative.</li>
        </ul>

        <form [formGroup]="purchasePeopleForm">
          <div class="form-group">
            <label>Get Your Quote:</label>
            <div class="form-group">
              <input class="form-control" type="number" formControlName="numWorkers"
                placeholder="How many employees do you have?" (change)="getQuoteAsync()" (keyup)="getQuoteAsync()"
                min="1" step="1" oninput="validity.valid||(value='');" />
            </div>
          </div>
        </form>

        <div class="quote-container">
          <app-quote-container [amount]="quotePrice" [loading]="loadingQuote" [title]="'Price:'"
            [showVatLabel]="quoteHasVat">
          </app-quote-container>
          <button type="button" class="btn btn-primary" (click)="activateAync()" [class.is-loading]="modalbtnLoading"
            [disabled]="!purchasePeopleForm.valid">
            Pay now
          </button>
        </div>
      </div>
    </div>
  </div>
</div>