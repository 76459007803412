import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { RegisterExistingPageComponent } from './register-existing.component';
import { BootstrapComponentsModule } from 'src/app/shared/components/bootstrap/bootstrap.module';


const routes: Routes = [
  { path: '', component: RegisterExistingPageComponent }
]

@NgModule({
  declarations: [RegisterExistingPageComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    BootstrapComponentsModule
  ],
  exports: [RegisterExistingPageComponent]
})
export class RegisterExistingPageModule { }
