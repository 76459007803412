import { dateCheck } from './../constants/date-check.const';

export class DateHelpers {
  inputDate: Date;
  dateNow: Date; 
  year: any;
  month: any;
  day: any;   

  constructor(inputDate: string) {
    this.inputDate = new Date(inputDate);
    this.dateNow = new Date(Date.now());
    this.year = this.dateNow.getFullYear();
    this.month = this.dateNow.getMonth();
    this.day = this.dateNow.getDate();    
  }

  
  expiry(): string {    
    if (this.inputDate < this.dateNow) return dateCheck.EXPIRED;
    else if (this.inputDate < new Date(this.year, this.month + 2, this.day) && this.inputDate > this.dateNow) return dateCheck.EXPIRING;
    else if (this.inputDate > new Date(this.year, this.month + 2, this.day) && this.inputDate > this.dateNow) return dateCheck.VALID;
    else return;
  }

  foundation(): boolean {
    if (this.inputDate < new Date(this.year, this.month - 6, this.day)) return true;
    else return false;
  }
}
