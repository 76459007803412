import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuestionsetTestPageComponent } from './test.component';
import { QuestionsetSurveyJSModule } from 'src/app/shared/components/questionset-surveyjs/questionset-surveyjs.module';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', component: QuestionsetTestPageComponent }
]

@NgModule({
  declarations: [QuestionsetTestPageComponent],
  imports: [
    CommonModule,
    QuestionsetSurveyJSModule,
    RouterModule.forChild(routes)
  ],
  exports: [QuestionsetTestPageComponent]
})
export class QuestionsetTestPageModule { }
