export interface IntroductionText {
  id: string;
  title: string;
  assessment: string;
  content: IntroContent[];
}

export interface IntroContent {
  paragraph: string;
}
