import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppInitialProduct } from 'src/app/features';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import applicationTypesJSON from 'src/assets/json/application-types.json';
import { ApplicationTypeDetails, UserTypeCode } from '../../index';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';

// This component can be used as a form control, within a reactive form.
// https://javascript-conference.com/blog/angular-reactive-forms-building-custom-form-controls/

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ApplicationTypesComponent),
  multi: true
};

@Component({
  selector: 'app-application-types',
  templateUrl: './application-types.component.html',
  styleUrls: ['./application-types.component.scss'],
  providers: [CUSTOM_VALUE_ACCESSOR]
})
export class ApplicationTypesComponent implements OnInit {
  private config: IEnvironment;
  selectedCode: string;
  selectedApplicationType: ApplicationTypeDetails;

  @Input() public readonly form: UntypedFormGroup;
  @Input() public customerType: string = UserTypeCode.CONTRACTOR;

  applicationTypes: ApplicationTypeDetails[];
  private disabled: boolean;
  private onChange: Function;
  private onTouched: Function;

  constructor(private readonly gtmService: GtmService) {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
    this.disabled = false;
    this.selectedApplicationType = null;
  }

  writeValue(obj: string): void {
    this.selectedCode = obj;
    this.selectedApplicationType = this.applicationTypes.find(row => row.code === obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  async ngOnInit() {
    const env = new Environment();
    this.config = await env.getConfig();

    this.applicationTypes = applicationTypesJSON.filter(x => x.feesCode !== null && x.customerType === this.customerType);
    this.selectedCode = AppInitialProduct.ApplicationTypeCode;
    this.selectedApplicationType = null;
  }

  trackByFn(index: number, item: ApplicationTypeDetails) {
    return index;
  }

  /**
   * Set the selected product bundle.
   */
  selectType(applicationType: ApplicationTypeDetails): void {
    this.selectedCode = applicationType.code;
    this.selectedApplicationType = applicationType;
    this.onChange(this.selectedCode);
    this.onTouched();
    this.form.patchValue({ applicationTypeCode: applicationType.code });

    this.gtmService.pushTag({
      id: this.config.googleAnalytics.googleTagManagerId,
      event: 'select_applicationType',
      applicationType: applicationType.name
    });
  }

  /**
   * Returns TRUE if the specified bundle is selected.
   */
  isSelected(applicationType: ApplicationTypeDetails): boolean {
    return this.selectedCode === applicationType.code;
  }
}
