import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService, PaymentService } from '../../core';
import { first } from 'rxjs/internal/operators/first';
import { finalize } from 'rxjs/internal/operators/finalize';
import { BasketItem } from 'src/app/shared';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { selectBasketItems } from 'src/app/core/ngrx/selectors/basket.selectors';
import { BasketQuoteActions } from 'src/app/core/ngrx/actions/basketQuote.actions';
import { BasketAccountActions } from 'src/app/core/ngrx/actions/basketAccount.actions';
import { BasketItemsActions } from 'src/app/core/ngrx/actions/basketItems.actions';
import { GtmOrderConfirmationProductItem } from 'src/app/core/services/gtm/gtm.interface';
import { BasketSummaryActions } from 'src/app/core/ngrx/actions/basketSummary.actions';
import { RegistersteponeActions } from 'src/app/core/ngrx/actions/registerStepOne.actions';
import { RegistersteptwoActions } from 'src/app/core/ngrx/actions/registerStepTwo.actions';
import { RegisterstepthreeActions } from 'src/app/core/ngrx/actions/registerStepThree.actions';
import { QuickquoteActions } from 'src/app/core/ngrx/actions/quickQuote.actions';
import { RegisterstepfourActions } from 'src/app/core/ngrx/actions/registerStepFour.actions';
import { RegisterstepActions } from 'src/app/core/ngrx/actions/registerStep.actions';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { ProductName } from 'src/app/shared/classes/productName.class';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';

@Component({
  selector: 'app-stripe-landing',
  templateUrl: './stripe-landing.component.html',
  styleUrls: ['./stripe-landing.component.scss']
})
export class StripeLandingComponent implements OnInit, OnDestroy {
  @ViewChild('plantModal', { static: false }) public plantModalRef: TemplateRef<any>;
  private basketItems$: Observable<BasketItem[]> = this.store.select(selectBasketItems);
  public isLoading: boolean = true;
  private modalRef: BsModalRef;
  public purchased: string;
  private refreshTimeout: any;
  private count = 0;
  public error: string;
  private basketSubscription: Subscription;
  private config: IEnvironment;

  constructor(
    private readonly gtm: GtmService,
    private readonly router: Router,
    private readonly activeRoute: ActivatedRoute,
    private readonly modalService: BsModalService,
    private readonly authService: AuthService,
    private readonly payments: PaymentService,
    private readonly store: Store
  ) {

    this.activeRoute.data
      .subscribe((data) => {
        this.purchased = data.purchased;
        //Redirects to dashboard on first login
        const isLoggedIn = this.authService.isLoggedIn();
        if (
          (
            isLoggedIn &&
            this.purchased === 'register') ||
            this.purchased === 'foundation'
          ) {
            setTimeout(() => {
              this.redirect();
            }, 3500);
          }
        })
    }

  public async ngOnInit(): Promise<void> {
    const env = new Environment();
    this.config = await env.getConfig();

    setTimeout(() => {
      this.modalRef = this.modalService.show(this.plantModalRef, {
        animated: false,
        backdrop: 'static'
      });
    }, 100);

    this.store.dispatch({ type: BasketItemsActions.Load });

    this.basketSubscription = this.basketItems$.subscribe((items) => {
      if (items) this.ecommerce(items);
    });

    this.isPaymentComplete();
  }

  private clearStore(): void {
    this.store.dispatch({ type: BasketItemsActions.Clear });
    this.store.dispatch({ type: BasketAccountActions.Remove });
    this.store.dispatch({ type: BasketQuoteActions.Remove });
    this.store.dispatch({ type: BasketSummaryActions.Remove });
    this.store.dispatch({ type: RegisterstepActions.Remove });
    this.store.dispatch({ type: RegistersteponeActions.Remove });
    this.store.dispatch({ type: RegistersteptwoActions.Remove });
    this.store.dispatch({ type: RegisterstepthreeActions.Remove });
    this.store.dispatch({ type: RegisterstepfourActions.Remove });
    this.store.dispatch({ type: QuickquoteActions.Remove });
  }

  public ngOnDestroy(): void {
    this.basketSubscription.unsubscribe();
    clearTimeout(this.refreshTimeout);
  }

  private ecommerce_item(item: BasketItem): GtmOrderConfirmationProductItem {
    let price, units;
    if (item.name === ProductName.level1 || item.name.includes(ProductName.level3) || item.name.includes(ProductName.level4)) {
      price = item.price;
      units = 1;
    } else {
      price = item.price / item.units;
      units = item.units;
    }

    return {
      sku: item.productID,
      name: item.name,
      price: price.toFixed(2),
      quantity: units
    };
  }

  private ecommerce(basketItems: BasketItem[]): void {
    if (!(basketItems) || this.purchased === 'foundation') return;

    const item = basketItems.find(basketItem => Boolean(basketItem.transaction_id));
    const transactionArray = basketItems.filter(transaction => Boolean(transaction.transaction_id));
    const items = transactionArray.map(this.ecommerce_item);
    this.gtm.pushTag({
      event: 'purchase',
      ecommerce: {
        'payment_method': 'debit card',
        'purchase': {
          'actionField': {
            'id': item.transaction_id,
            'revenue': item.price,
            'coupon': item.referenceCode
          },
          'products': items
        }
      }
    });
  }

  private isPaymentComplete(): void {
    //get accountId from storage as this page is hit with the auth resolver inteseption
    const id = sessionStorage.getItem('guidac')
    this.payments.isPaymentComplete(id)
      .pipe(first(),
        finalize(() => {
          //If payment is successful delete redirectUrl as this is used only when selecting back from stripe
          sessionStorage.removeItem('redirectUrl');
          this.refreshTimeout = setTimeout(() => this.isPaymentComplete(), 1000 * this.config.poll.payments)
        }))
      .subscribe((res) => {
        if (this.count < 10) {
          if (res) {
            setTimeout(() => {
                this.clearStore();
                this.isLoading = false;
                this.redirect();
              },
              7000);
          } else this.count++;
        } else {
          this.isLoading = false;
          this.error = 'Something went wrong! click here';
          this.redirect();
        }
      });
  }

  public redirect(): void {
    this.router.navigateByUrl('/dashboard');
    this.modalRef.hide();
  }
}
