import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { Store } from '@ngrx/store';
import { CheckNewAuthenticationResponse } from 'src/app/shared/index';
import { AuthLoginResolver } from 'src/app/shared/resolvers/auth-login.resolver';

@Component({
  selector: 'app-user-username',
  templateUrl: './username.component.html',
  styleUrls: ['./username.component.scss'],
})
export class UsernameComponent implements OnInit {
  public accounts: CheckNewAuthenticationResponse[] = [];
  public selectedAccount: CheckNewAuthenticationResponse;
  private readonly data$ = this.store.select(selectCurrentUserState);

  constructor(private router: Router, private readonly store: Store, private readonly auth: AuthLoginResolver) {}

  ngOnInit() {
    this.data$.subscribe((data) => {
      if (data.loaded) {
        this.selectedAccount = data.currentAccount;
        this.accounts = data.allAccounts;
      }
    });
  }

  selectAccount(account) {
    this.selectedAccount = account;
    this.auth.switchAccount(account.accountId);
    this.router.navigateByUrl('/dashboard');
  }
}
