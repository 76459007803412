import { createFeatureSelector } from '@ngrx/store';
import { IRegisterstepfour } from 'src/app/shared/interfaces/new/registerStepFour';
import { IRegisterstepone } from 'src/app/shared/interfaces/new/registerStepOne';
import { IRegisterstepthree } from 'src/app/shared/interfaces/new/registerStepThree';
import { IRegistersteptwo } from 'src/app/shared/interfaces/new/registerStepTwo';

export const selectRegisterStep = createFeatureSelector<number>('registerStep');
export const selectRegisterOne = createFeatureSelector<IRegisterstepone>('registerStepOne');
export const selectRegisterTwo = createFeatureSelector<IRegistersteptwo>('registerStepTwo');
export const selectRegisterThree = createFeatureSelector<IRegisterstepthree>('registerStepThree');
export const selectRegisterFour = createFeatureSelector<IRegisterstepfour>('registerStepFour');
