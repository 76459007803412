import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize, first } from 'rxjs/operators';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { ProductService } from '../../../../core';
import { CheckAuthenticationResponse } from '../../../../shared';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';

@Component({
  selector: 'app-credit-tile',
  templateUrl: './credit-tile.component.html',
  styleUrls: ['./credit-tile.component.scss']
})
export class DashboardCreditTileComponent implements OnInit {
  @Output() emitRagClass = new EventEmitter();
  @Input() authAccount: CheckAuthenticationResponse;
  isLoading: boolean;
  score: {};
  private config: IEnvironment;
  constructor(private readonly gtmService: GtmService, private products: ProductService) { }

  async ngOnInit(): Promise<void> {

    const env = new Environment();
    this.config = await env.getConfig();
    this.emitRagClass.emit(['white', false])
  }


  get productImage(): string {
    return `assets/img/product-cards/credit_safe.png`;
  }

  pushGoogleTag() {
    this.gtmService.pushTag({
      id: this.config.googleAnalytics.googleTagManagerId,
      event: 'open_product_card_modal',
      product_card: 'CreditSafe'
    });
  }

  getCreditScore() {
    this.isLoading = true;
    this.products.getCreditScore(this.authAccount.AccountId)
      .pipe(
        first(),
        finalize(() => {
          setTimeout(() => { this.isLoading = false }, 2000);
        })
      )
      .subscribe(credit => {
      this.score = credit;
    })
  }



}
