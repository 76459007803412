import { WorkRegion } from 'src/app/shared/components/work-region-selecter/work-region-selecter.component';
import internal from 'stream';
import { AccountListItem } from './account-list-item';
import { ContactRole } from './contact-role';
import { UnspscCode } from './work-category';

export interface CheckAuthenticationResponse {
  AccountName: string;
  AccountId: string;
  AccountList: AccountListItem[] | null;
  ContactId: string;
  Address: string;
  FirstTimeLogin: boolean;
  PasswordExpired: boolean;
  FullName: string;
  MembershipId: string;
  Position: string;
  ReadOnlyLogin: boolean;
  UserType: string;
  UserRoles?: any[];
}

export interface CheckNewAuthenticationResponse {
  accountName: string;
  accountId: string;
  accountList: AccountListItem[] | null;
  contactId: string;
  address: string;
  firstTimeLogin: boolean;
  passwordExpired: boolean;
  fullName: string;
  position: string;
  membershipId: string;
  membershipTypeId: number;
  aquipaUserKey: string;
  tikforceUserKey: string;
  thirdPartyCasProvider: string;
  casDataSharingEnabled: boolean;
  acceptedTermsAndConditions: string;
  accreditationExpiry: string;
  membershipExpiry: string;
  readOnlyLogin: boolean;
  userType: string;
  dateCasDataUpdated: string;
  incompleteAssessments: number;
  companyHouseNo: string;
  distance: number;
  currentContactId: string;
  currentContactFullName: string;
  isActive: boolean;
  isLogMeInContact: boolean;
}

export interface ICompanyDetailsAccountUpdateCommand
{
    accountId: string,
    name: string,
    primaryTrade: string,
    turnover: number,
    summary: string,
    vatRegistrationNumber: string,
    hearChas: string,
    hearChasText: string,
    mandatedClient: string,
    printPack: string,
    tradingName: string,
    website: string,
    numberOfDirectEmployees: number,
    numberOfLabourOnlySubcontractors: number,
    numberOfEffectiveEmployees: number,
    numberOfBonaFideSubcontractors: number,
    ultimateParentAccountId: string,
    parentAccountId: string,
    primaryContactId: string,
    primaryContactPosition: string,
    registerAddress: IAddress,
    correspondenceAddress: IAddress,
    organisationType: string,
    primaryWorkCategory: string,
    telephone: string,
    distance:number,
    workRegions: WorkRegion[];
    workCategories: UnspscCode[];
}

export interface IAddress {
  Id: string;
  accountId: string;
  latitude: number;
  longitude: number;
  street1: string;
  street2: string;
  street3: string;
  postCode: string;
  county: string;
  town: string;
  country: string;
  countryCode?: string;
  city: string;
}
