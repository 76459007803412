import { NgModule, LOCALE_ID, ErrorHandler, InjectionToken, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SiteLayoutModule } from '../shared/components/layout/site-layout/site-layout.module';
import { AuthLayoutModule } from '../shared/components/layout/auth-layout/auth-layout.module';
import { FormsModule } from '@angular/forms';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorService, monitorService } from '../core';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { ConfigService } from './services/config.service';
import { AuthService } from './services/auth.service';
import { AuthInterceptor } from './services/auth-inteceptor.service';
import { HttpInterceptService } from '../core';

const AUTH_CONFIG_URL_TOKEN = new InjectionToken<string>('AUTH_CONFIG_URL');

export function initializerFactory(env: ConfigService, configUrl: string): any {
    // APP_INITIALIZER, except a function return which will return a promise
    // APP_INITIALIZER, angular doesnt starts application untill it completes
    const promise = env.init(configUrl).then((value) => {
        console.log('finished getting configurations dynamically.');
    });
    return () => promise;
}

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log(message);
}
export function MSALInstanceFactory(config: ConfigService): IPublicClientApplication {
  return new PublicClientApplication({
    auth: config.getSettings('msal').auth,
    cache: config.getSettings('msal').cache,
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(config: ConfigService): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>(config.getSettings('interceptor').protectedResourceMap)

  return {
    interactionType: config.getSettings('interceptor').interactionType,
    protectedResourceMap
  };
}

  export function MSALGuardConfigFactory(config: ConfigService): MsalGuardConfiguration {
    return {
      interactionType: config.getSettings('guard').interactionType,
      authRequest: config.getSettings('guard').authRequest,
      loginFailedRoute: config.getSettings('guard').loginFailedRoute
    };
  }

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    NoopAnimationsModule, // disables animation
    AuthLayoutModule,
    SiteLayoutModule,
    MsalModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptService, multi: true, deps: [monitorService] },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, deps: [AuthService] },
    { provide: ErrorHandler, useClass: ErrorService, deps: [monitorService] }
  ],
})
export class CoreModule {
  static forRoot(configFile: string) {
    return {
        ngModule: CoreModule,
        providers: [
            ConfigService,
            { provide: AUTH_CONFIG_URL_TOKEN, useValue: configFile },
            { provide: APP_INITIALIZER, useFactory: initializerFactory,
                 deps: [ConfigService, AUTH_CONFIG_URL_TOKEN], multi: true },
            {
                provide: MSAL_INSTANCE,
                useFactory: MSALInstanceFactory,
                deps: [ConfigService]
            },
            {
                provide: MSAL_GUARD_CONFIG,
                useFactory: MSALGuardConfigFactory,
                deps: [ConfigService]
            },
            MsalService,
            MsalGuard,
            MsalBroadcastService
        ]
    };
}
}
