import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssessmentQuestionsetPageComponent } from './questionset.component';
import { QuestionsetSurveyJSModule } from 'src/app/shared/components/questionset-surveyjs/questionset-surveyjs.module';
import { RouterModule, Routes } from '@angular/router';
import { BootstrapComponentsModule } from 'src/app/shared/components/bootstrap/bootstrap.module';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { ResponseCountdownModule } from 'src/app/shared/components/response-countdown/response-countdown.module';
import { MatCardModule } from '@angular/material/card';

const routes: Routes = [{ path: '', component: AssessmentQuestionsetPageComponent }];

@NgModule({
  declarations: [AssessmentQuestionsetPageComponent],
  exports: [AssessmentQuestionsetPageComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    BootstrapComponentsModule,
    LoadingSpinnerModule,
    QuestionsetSurveyJSModule,
    ResponseCountdownModule,
    MatCardModule,
  ],
})
export class AssessmentQuestionsetPageModule {}
