import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Environment, IEnvironment } from '../../../shared/classes/environment';
import { ClientData, ClientDataList } from '../../../shared/interfaces/clients.model';

@Injectable({
  providedIn: 'root',
})
export class MyClientsService {
  private env: Environment = new Environment();
  private environment: IEnvironment = this.env.getConfigSync();

  constructor(private readonly http: HttpClient) {}

  public retrieveClientList$(companyAccountNumber: string): Observable<ClientDataList[]> {
    // companyAccountNumber = '4BB8107F-1A56-EA11-A811-000D3A2CB36F'; Leaving commented accountID for development. To be removed once development completed - Lewis 15/08/23
    return this.http.get<ClientDataList[]>(`${this.environment.apiEndpoint.accounts}/${companyAccountNumber}/connectedclients`);
  }

  public retrieveClientData$(clientAccountUUID: string): Observable<ClientData> {
    return this.http.get<ClientData>(`${this.environment.apiEndpoint.accounts}/clients/${clientAccountUUID}`);
  }
}
