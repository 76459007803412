<div class="input" [hidden]="isLoadingDetails">
<mat-form-field appearance="fill">
    <mat-label>{{title}}</mat-label>
    <input
        matInput
        [type]="type"
        [name]="name"
        [placeholder]="title"
        [readonly]="readonly"
        [formControl]="control"
        [matAutocomplete]="auto"/>
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option disabled *ngIf="hasEntered && !filteredCompanies">
                <div style="display: flex; align-items: center;">
                    <mat-spinner diameter="35"></mat-spinner>
                    <span style="margin-left:0.5rem;">Loading companies</span>
                </div>
            </mat-option>
            <mat-option disabled *ngIf="hasEntered && filteredCompanies && filteredCompanies.length === 0">
                No company found
            </mat-option>
            <mat-option
                *ngFor="let option of filteredCompanies"
                (click)="chooseCompany(option)">
                {{option.displayName}}
            </mat-option>
        </mat-autocomplete>
    <button
        matSuffix
        tabindex="-1"
        *ngIf="control.value && !readonly"
        mat-icon-button
        aria-label="Clear"
        (click)="control.setValue('')">
        <mat-icon>close</mat-icon>
    </button>
    <mat-icon
        matSuffix
        *ngIf="
            readonly &&
            control.value !== '' &&
            control.value !== null
        "
        class="u-green">
        done
    </mat-icon>
    <mat-error *ngIf="control.touched && control.invalid">
      {{title}} is <strong>required</strong>
    </mat-error>
  </mat-form-field>
    <button
        *ngIf="tooltip"
        tabindex="-1"
        mat-icon-button
        [matTooltip]="tooltip"
        >
        <mat-icon>
        info
        </mat-icon>
    </button>
    <a
        *ngIf="refCode"
        mat-raised-button
        (click)="validate()">
        VALIDATE
    </a>
</div>

<div *ngIf="isLoadingDetails">
    <mat-spinner></mat-spinner>
</div>