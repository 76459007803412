import { Store } from '@ngrx/store';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { getCompanySummary } from '../../../core/ngrx/selectors/company-details.selectors';
import { ActivatedRoute, Params } from '@angular/router';
import { Environment, IEnvironment } from '../../../shared/classes/environment';
import { CheckoutSessionResponse } from '../../../shared/interfaces/new/register-data';
import { HttpClient } from '@angular/common/http';
import { PaymentService } from '../../../core';
import { Component } from '@angular/core';
import { IAccountContactQuoteResponse } from '../../../shared';
import { CompanySummary } from '../../../shared/interfaces/company-summary';

@Component({
  selector: 'app-membership-upgrade',
  templateUrl: './membership-upgrade.component.html',
  styleUrls: ['./membership-upgrade.component.scss'],
})
export class MembershipUpgradeComponent {
  public stepIndex = 0;
  public account$: Observable<any> = this.store.select(getCompanySummary);
  private subscriptions$ = new Subscription();
  public loading = true;
  public companySummary: CompanySummary;
  public termsAndConditions = false;
  public opportunity: IAccountContactQuoteResponse;
  public checkoutSession: CheckoutSessionResponse;
  private config: IEnvironment;

  constructor(
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
    private readonly http: HttpClient,
    private readonly paymentService: PaymentService
  ) {
    this.subscriptions$ = this.activatedRoute.queryParams.subscribe((params: Params) => (this.stepIndex = params.index));
  }

  async ngOnInit(): Promise<void> {
    const environment: Environment = new Environment();
    this.config = await environment.getConfig();

    // fragile - refactor later Lewis -29/11/2023
    if (this.stepIndex == 2) {
      this.loading = false;
    } else {
      this.companySummary = await firstValueFrom(this.account$);
      await this.constructCheckoutSession();
      this.loading = false;
    }
  }

  private constructQuote(): Observable<IAccountContactQuoteResponse> {
    const quoteObject = {
      accountId: this.companySummary.companyAccountID,
      opportunityId: '',
      quote: {
        BundleNames: ['Elite'],
        accountId: this.companySummary.companyAccountID,
        applicationType: '885570000',
        cancelUrl: `${this.config.urls.environmentUrl}/membership/upgrade?index=0`,
        customerType: '870520001',
        dataSharingAllowed: false,
        discountCode: null,
        fastTrack: false,
        firFee: false,
        joiningFee: false,
        opportunityId: null,
        priceLevelId: '',
        sectorType: 885570006,
        selectedBundle: 'Elite',
        successUrl: `${this.config.urls.environmentUrl}/membership/upgrade?index=2`,
      },
    };
    return this.http.post<IAccountContactQuoteResponse>(
      `${this.config.apiEndpoint.accounts}/registeraccountupgradeopportunity`,
      quoteObject
    );
  }

  private async constructCheckoutSession(): Promise<void> {
    try {
      this.opportunity = await firstValueFrom(this.constructQuote());
      if (this.opportunity) {
        const request = {
          accountId: this.companySummary.companyAccountID,
          opportunityId: this.opportunity.opportunityId,
          recreate: false,
          manualPayment: false,
          isDatashareAllowed: false,
          successUrl: `${this.config.urls.environmentUrl}/membership/upgrade?index=2`,
          cancelUrl: `${this.config.urls.environmentUrl}/membership/upgrade?index=0`,
        };
        this.checkoutSession = await this.paymentService.createCheckoutSession(request);
      }
    } catch (error) {
      console.log(`An error occurred -> constructCheckoutSession(): ${error.message}`);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
