import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { finalize, first, map } from 'rxjs/operators';
import { DocumentService } from '../../../core';
import { CheckAuthenticationResponse, DocumentCategoryResponse, DocumentResponse, foundationDocuments, DocumentCategory } from '../../../shared';

@Component({
  selector: 'app-documents-card',
  templateUrl: './documents-card.component.html',
  styleUrls: ['./documents-card.component.scss']
})
export class DocumentsCardComponent implements OnInit {
  @Input() authAccount: CheckAuthenticationResponse;
  otherDocuments: DocumentResponse[];
  error: string;
  isLoading: boolean;

  constructor(private documentService: DocumentService) { }

  ngOnInit(): void {
    this.fetchDocuments();
  }

  fetchDocuments(): void {
    this.isLoading = true;
    this.error = null;

    this.documentService.getAll(this.authAccount.AccountId)
      .pipe(
        first(),
        map(categories => {
          categories.forEach(category => {
            category.documents.forEach(d => {
              d.uploaded = new Date(d.uploaded);
            });
          });

          return categories;
        }),
        finalize(() => {
          setTimeout(() => { this.isLoading = false }, 4000);
        })
      )
      .subscribe({
        next: categories => {
          let otherDocsCategory = categories.find(c => c.name === DocumentCategory.OtherDocuments);
          if (!otherDocsCategory) {
            otherDocsCategory = {
              documents: []
            } as DocumentCategoryResponse;
          }
          this.otherDocuments = [];
          foundationDocuments.forEach(name => {
            for (let i = 0; i < otherDocsCategory.documents.length; i++) {
              if (name === otherDocsCategory.documents[i].name) {
                this.otherDocuments.push(otherDocsCategory.documents[i])
              }
            }
          })
        },
        error: (response: HttpErrorResponse) => {
          this.error = response.error.Message;
        }
      });
  }


  downloadDocument(document: DocumentResponse, contentType: string, options: string) {
    const fileName = document.name ?? document.uri.split('/').pop();
    this.documentService.download(document.uri)
      .subscribe(data => this.download(data, contentType, options, fileName));
  }

  download(data: any, contentType: string, options: string, fileName: string) {
    const blob = new Blob([data], { type: contentType });
    const url = window.URL.createObjectURL(blob);

    if (!options) {
      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = url;
      anchor.style.visibility = 'hidden';
      anchor.click();
      window.URL.revokeObjectURL(url);
      anchor.remove();
    } else {
      window.open(url, '_blank', options);
    }
  }

}
