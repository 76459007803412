import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { CompanyDetails, productTypes, CheckNewAuthenticationResponse, AccountContact, CompanyDetailsForm } from '../../index';
import jsonOrganisationTypes from 'src/assets/json/organisation-types-au.json';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { Store } from '@ngrx/store';
import { TradeService } from '../../../core/services/trade.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AccountsService } from '../../../core/services/accounts.service';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize, first } from 'rxjs';
import { CountryService } from '../../../core/services/country.service';
import { ContactsService } from '../../../core/services/contacts.service';
import { Territory } from 'src/app/shared/constants/territory.enum';
import { HelplinePhoneNumber } from 'src/app/shared/components/register-carousel/register-carousel.component';
import { Router } from '@angular/router';
import { WorkAreaSelecterComponent } from '../work-area-selecter/work-area-selecter.component';
import { WorkRegion, WorkRegionSelecterComponent } from '../work-region-selecter/work-region-selecter.component';

@Component({
  selector: 'app-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss'],
})
export class WelcomeModalComponent implements OnInit {
  @Input() accountId: string;
  @Input() contactId: string;
  workAreaHeadingText: string = 'Work Areas';
  isLoading = true;
  foundationBundleId = productTypes.CHAS_FOUNDATION;

  private config: IEnvironment;
  public currentUser: CheckNewAuthenticationResponse;
  public companyDetails: CompanyDetails;
  public errorMessage: string;
  public detailsForm: UntypedFormGroup = new UntypedFormGroup({
    register_address_id: new UntypedFormControl(''),
    register_address_city: new UntypedFormControl(''),
    register_address_street1: new UntypedFormControl(''),
    register_address_street2: new UntypedFormControl(''),
    register_address_postcode: new UntypedFormControl(''),
    register_address_country_code: new UntypedFormControl(''),
    correspondence_address_id: new UntypedFormControl(''),
    correspondence_address_city: new UntypedFormControl('', []),
    correspondence_address_street1: new UntypedFormControl('', []),
    correspondence_address_street2: new UntypedFormControl(''),
    correspondence_address_postcode: new UntypedFormControl('', []),
    correspondence_address_country_code: new UntypedFormControl('', []),
    summary: new UntypedFormControl('', [Validators.required, Validators.maxLength(5000)]),
    membership_id: new UntypedFormControl('', [Validators.required]),
    turnover: new UntypedFormControl(''),

    ultimate_parent_account_number: new UntypedFormControl(''),
    ultimate_parent_account: new UntypedFormControl(''),
    ultimate_parent_account_id: new UntypedFormControl(''),

    primary_contact_id: new UntypedFormControl(''),
    email_address: new UntypedFormControl(''),
    company_house_registration_number: new UntypedFormControl(''),
    organisation_type: new UntypedFormControl('', [Validators.required]),
    trading_name: new UntypedFormControl(''),
    trades: new UntypedFormControl([], []),
    workCategories: new UntypedFormControl([], [Validators.required, Validators.minLength(1)]),
    primary_trade: new UntypedFormControl(''),
    vat_reg_number: new UntypedFormControl(''),
    hear_chas: new UntypedFormControl('', []),
    hear_chas_text_option: new UntypedFormControl(''),
    print_pack: new UntypedFormControl(''),

    number_of_direct_employees: new UntypedFormControl(0),
    number_of_labour_only_subcontractors: new UntypedFormControl(0),
    number_of_effective_employees: new UntypedFormControl(0),
    number_of_bona_fide_subcontractors: new UntypedFormControl(0),

    parent_account_number: new UntypedFormControl(''),
    parent_account: new UntypedFormControl(''),
    parent_account_id: new UntypedFormControl(''),

    primaryContactControl: new UntypedFormControl(''),
    position: new UntypedFormControl(''),
    telephone: new UntypedFormControl(''),
    website_url: new UntypedFormControl(''),

    distance: new UntypedFormControl(100, [Validators.min(1)]),
    workRegions: new UntypedFormControl([], [Validators.required, Validators.minLength(1)]),
  });
  public options = {
    organisationTypes: jsonOrganisationTypes,
  };
  private readonly data$ = this.store.select(selectCurrentUserState);
  private currentUserState: CurrentUserState;
  private error: string | undefined;
  private success = false;
  private primaryContact: AccountContact;
  public isSaving = false;
  public readOnly = false;
  public isAus = false;
  private phoneNumber = HelplinePhoneNumber.AUSTRALIA;
  public tooltipText = `
  If the information provided during your registration is incorrect, please contact us on ${this.phoneNumber}. Any changes made after your submission may incur an administration fee.`;
  selectedStates: WorkRegion[] = [];

  constructor(
    private readonly ModalService: BsModalService,
    private readonly accountService: AccountsService,
    private readonly countryService: CountryService,
    private readonly elementRef: ElementRef,
    private readonly contactService: ContactsService,
    private readonly store: Store,
    private readonly router: Router,
    public readonly bsModalRef: BsModalRef
  ) {}

  async ngOnInit(): Promise<void> {
    const env = new Environment();
    this.config = await env.getConfig();
    this.isAus = this.config.territory === Territory.AUSTRALIA;
    this.isLoading = false;

    if (this.isAus) {
      this.workAreaHeadingText = 'States';
    }

    this.loadCurrentUserState();
  }
  i;
  private populateCompanyDetails(): void {
    this.enableDetailsForm(!this.readOnly);

    if (this.currentUser) {
      this.isLoading = true;
      this.error = null;
      this.companyDetails = this.currentUserState.companyDetails;
      let toPatched = new CompanyDetailsForm(this.companyDetails);
      if (toPatched.organisation_type === '') toPatched.organisation_type = null;
      this.detailsForm.patchValue(toPatched);
      this.detailsForm.controls.workRegions.patchValue(this.currentUserState.workRegions);
      this.detailsForm.controls.distance.patchValue(this.companyDetails.distance);
      this.selectedStates = this.currentUserState.states;
      this.recalculateTotalEmployees();
      this.isLoading = false;
    }
  }

  get organisationType() {
    return this.companyDetails && this.companyDetails.organisationType
      ? this.options.organisationTypes.find((o) => o.id === this.companyDetails.organisationType)?.label
      : null;
  }

  get registeredAddress(): string {
    if (!this.companyDetails || !this.companyDetails.registerAddress) {
      return null;
    }
    const parts = [
      this.companyDetails.registerAddress.street1,
      this.companyDetails.registerAddress.street2,
      this.companyDetails.registerAddress.city,
      this.companyDetails.registerAddress.postCode,
      this.companyDetails.registerAddress.country,
    ];

    return parts.filter((part) => null !== part).join(', ');
  }

  private recalculateTotalEmployees() {
    let newTotal =
      this.detailsForm.controls.number_of_direct_employees.value + this.detailsForm.controls.number_of_labour_only_subcontractors.value;

    this.detailsForm.controls.number_of_effective_employees.setValue(newTotal);
  }

  private async loadCurrentUserState() {
    await this.data$.subscribe({
      next: (data) => {
        if (data.loaded) {
          this.currentUserState = data;
          this.currentUser = { ...data.currentAccount };
          this.companyDetails = { ...data.companyDetails };
          this.populateCompanyDetails();
        }
      },
    });
  }
  primaryTradeTrackBy(index, row) {
    return row.id;
  }
  /**
   * Show a dialogue for viewing and updating work categories.
   */
  public async showWorkCategories(): Promise<void> {
    this.ModalService.show(WorkAreaSelecterComponent, {
      animated: false,
      backdrop: 'static',
      class: 'modal-xl',
      initialState: {
        //@ts-ignore
        data: this.detailsForm.controls.workCategories.value,
        onChange: (selectedWorkCategories) => {
          this.detailsForm.controls.workCategories.patchValue(selectedWorkCategories);
          this.detailsForm.controls.primary_trade.patchValue(
            selectedWorkCategories.filter((x) => x.id === this.detailsForm.controls.primary_trade.value)[0]?.id
          );
        },
      },
    });

    await this.ModalService.onHide;
  }

  /**
   * Show a dialogue for viewing and updating work areas.
   */
  public async showWorkAreas(): Promise<void> {
    this.ModalService.show(WorkRegionSelecterComponent, {
      animated: false,
      backdrop: 'static',
      class: 'modal-xl',
      initialState: {
        selectedWorkRegions: this.detailsForm.controls.workRegions.value,
        distance: this.detailsForm.controls.distance.value,
        onChange: (selectedWorkRegions, selectedWorkStates, distance) => {
          this.detailsForm.controls.workRegions.patchValue(selectedWorkRegions);
          this.selectedStates = selectedWorkStates;
          this.detailsForm.controls.distance.patchValue(distance);
        },
      },
    });

    await this.ModalService.onHide;
  }

  private scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  private scrollToError(): void {
    const firstElementWithError = this.elementRef.nativeElement.querySelector('form .ng-invalid');
    this.scrollTo(firstElementWithError);
  }

  private async scrollIfFormHasErrors(form: UntypedFormGroup): Promise<any> {
    await form.invalid;
    this.scrollToError();
  }

  /**
   * Save or update company details.
   */
  async updateCompanyDetails(): Promise<void> {
    if (!this.currentUser || this.isSaving) {
      return;
    }

    const contact = { ...this.primaryContact };
    contact.accountId = this.currentUserState.currentAccount.accountId;
    contact.position = this.detailsForm.value.position;
    contact.telephone = this.detailsForm.value.telephone;
    const updateParams = this.contactService.convertToUpdateParams(contact);

    this.detailsForm.valid;

    if (!this.detailsForm.valid) {
      if (this.currentUserState.companyDetails.workCategories.length === 0) {
        setTimeout(() => {
          this.scrollIfFormHasErrors(this.detailsForm);
        }, 500);
        return;
      }
      this.scrollIfFormHasErrors(this.detailsForm);
      return;
    }

    this.setIsSaving(true);
    this.success = false;
    this.error = null;

    const { position, ...params } = this.detailsForm.value;

    const companyDetails = this.accountService.toCompanyDetails(
      this.currentUserState.currentAccount.accountId,
      this.companyDetails.id?.name,
      params,
      this.countryService
    );

    companyDetails.primaryContactPosition = contact.position;
    companyDetails.telephone = contact.telephone;

    this.accountService
      .updateAccount(this.currentUser.accountId, companyDetails)
      .pipe(
        first(),
        finalize(() => {
          this.setIsSaving(false);
        })
      )
      .subscribe({
        next: () => {
          this.success = true;

          // TODO reload the current user's details from somewhere.
          this.currentUser.position = this.detailsForm.value.position;

          this.accountService.refresh();
          this.close();
        },
        error: (response: HttpErrorResponse) => {
          if (response.error != null && response.error.length == 0) {
            this.error = response.error.Message;
          } else {
            this.error = 'Something went wrong. Please try again.';
          }
        },
      });
  }

  public close(toCompanyDetails = false): void {
    this.bsModalRef.hide();
    if (toCompanyDetails) this.router.navigateByUrl('/company-details');
  }

  private setIsSaving(isSaving: boolean): void {
    this.isSaving = isSaving;
    this.enableDetailsForm(!isSaving);
  }

  private enableDetailsForm(enabled: boolean): void {
    if (enabled) {
      this.detailsForm.enable();

      this.detailsForm.markAllAsTouched();
    } else {
      this.detailsForm.disable();
    }

    this.detailsForm.controls.number_of_effective_employees.disable();
    this.detailsForm.controls.number_of_direct_employees.disable();
    this.detailsForm.controls.number_of_labour_only_subcontractors.disable();
    this.detailsForm.controls.number_of_bona_fide_subcontractors.disable();
  }
}
