import { Injectable } from '@angular/core';
import { AuthService, monitorService, LoadingService } from '../../core';
import { MonitorSeverity, DateHelpers, dateCheck } from '..';
import membershipExpiredRoles from '../../../assets/json/membership-exipred-roles.json';
import { Action, ActionsSubject, Store } from '@ngrx/store';
import { CurrentUserActionTypes } from 'src/app/core/ngrx/actions/currentUser.actions';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';
import { selectMsalAuth } from 'src/app/core/ngrx/selectors/msalAuth.selectors';
import { firstValueFrom, lastValueFrom, Subject, Subscription, switchMap } from 'rxjs';
import { getCompanyAccountId } from '../../core/ngrx/selectors/company-details.selectors';
import { CountdownTimerActionsEnum, countdownTimerLoad } from '../../core/ngrx/actions/countdownTimerActionsEnum';
import { IMsalAuth } from '../../core/ngrx/actions/msalAuth.actions';
import { storeDispatchAsync } from '../functions/store-dispatch-async';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class AuthLoginResolver {
  private readonly data$ = this.store.select(selectCurrentUserState);
  private readonly msalAuth$ = this.store.select(selectMsalAuth);
  public error: string;
  public accountId: string;
  private destroyed$ = new Subject();

  constructor(
    private readonly authService: AuthService,
    private readonly loggingService: monitorService,
    private readonly loadingService: LoadingService,
    private readonly store: Store,
    protected readonly actions: ActionsSubject
  ) {
    this.error = null;
    this.accountId = sessionStorage.getItem('guidac') ? sessionStorage.getItem('guidac') : null;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  public async switchAccount(accountId: string): Promise<void> {
    if (accountId == this.accountId) {
      return;
    }
    this.loadingService.changeAccountLoading(true);
    sessionStorage.setItem('guidac', accountId);
    this.accountId = accountId;
    await this.loadCurrentUser();
  }

  public async resolve(): Promise<void> {
    this.msalAuth$
      .pipe(
        switchMap(async (data: IMsalAuth): Promise<void> => {
          if (data.loaded && data.payload) {
            this.loadingService.changeAccountLoading(true);
            await this.loadCurrentUser();
            this.actions.subscribe(async (action: Action): Promise<void> => {
              if (action.type === CurrentUserActionTypes.loadSuccess) {
                const accountId = await firstValueFrom(this.store.select(getCompanyAccountId));
                this.store.dispatch(countdownTimerLoad({ accountId: accountId }));
              }
            });
          }
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  private async loadCurrentUser(): Promise<void> {
    const currentUserSubscription: CurrentUserState = await firstValueFrom(this.data$);
    await storeDispatchAsync(this.store, this.actions, CurrentUserActionTypes.loadSuccess, {
      type: CurrentUserActionTypes.load,
      accountId: this.accountId,
    });

    try {
      if (currentUserSubscription.loaded) {
        this.setRoles(currentUserSubscription);
        if (this.accountId == null || currentUserSubscription.currentAccount.accountId == this.accountId) {
          this.loadingService.changeAccountLoading(false);
        }
      }
    } catch (e) {
      this.error = e.error?.Message;
      this.loggingService.log('AuthLoginResolver: Failed to get accounts or to acquire auth token.', MonitorSeverity.ERROR);
      if (e.toString().toLowerCase().includes('clientautherror')) {
        await this.authService.azureLogout();
      }
    }
  }

  private setRoles(data: CurrentUserState): void {
    let bundleExpires;

    if (data.bundles && data.bundles.length) {
      bundleExpires = new DateHelpers(data.bundles[0].bundleExpiry);
    } else return;

    if (!bundleExpires || bundleExpires.expiry() === dateCheck.EXPIRED) {
      let roles = membershipExpiredRoles;
      let companyDetailsRole = data.roles.find((role) => role.name.toLowerCase() === 'company details');
      if (companyDetailsRole) {
        roles.push(companyDetailsRole);
      }

      if (bundleExpires.foundation()) {
        let objIndex = roles.findIndex((role) => role.name == 'Assessments');
        roles[objIndex].privilege = 'No Access';

        objIndex = roles.findIndex((role) => role.name === 'My Documents');
        roles[objIndex].privilege = 'Full Access';
      }
    }
  }
}
