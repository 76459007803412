import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { MonitorSeverity } from '../../shared';
import { monitorService } from '../../core';
import { tap } from 'rxjs/internal/operators/tap';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-oauth-call-back',
  templateUrl: './oauth-call-back.component.html',
  styleUrls: ['./oauth-call-back.component.scss'],
})
export class OAuthCallBackComponent implements OnInit, OnDestroy {
  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly monitorService: monitorService
  ) {}

  ngOnInit(): void {
    const lastPath: string = sessionStorage.getItem('lastPath');

    this.authService.loginSuccessSubject
      .pipe(
        tap(() => console.warn('Login successful')),
        catchError((error) => {
          this.monitorService.exception(error.message, MonitorSeverity.ERROR);
          throw new Error(error);
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(async (_): Promise<void> => {
        if (lastPath && lastPath !== '/') {
          await this.router.navigateByUrl(lastPath);
        } else await this.router.navigateByUrl('/dashboard');
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }
}
