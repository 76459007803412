import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyModalComponent } from './privacy-modal.component';


@NgModule({
  declarations: [PrivacyModalComponent],
  imports: [
    CommonModule
  ],
  exports: [
    PrivacyModalComponent
  ]
})
export class PrivacyModalModule { }
