import { Injectable } from '@angular/core';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();
import { BasketItem, CreateAccountParameters, GetQuoteRequest } from '../../shared';

const basketId = 'basket';

class BasketState {
  basketItems: BasketItem[];
  lastQuoteRequest: GetQuoteRequest;
  vatPercent: number;
}

@Injectable({
  providedIn: 'root'
})
export class BasketService {

  account: CreateAccountParameters;
  accountID: string;
  basketItems: BasketItem[];
  basketId = basketId;
  private _vatPercent: number;
  private _lastQuoteRequest: GetQuoteRequest;

  constructor() {
    this.load();
  }

  add(item: BasketItem): void {
    this.basketItems = [];
    this.basketItems.push(item);
    this.save();
  }

  addTransaction(item): void {
    this.basketItems.push(item);
    this.save();
  }

  remove(item: BasketItem): void {
    const index = this.basketItems.findIndex(row => row == item);
    if ( -1 !== index) {
      this.basketItems.splice(index, 1);
    }
    this.save();
  }


  update(item: BasketItem, property: string, newVal: any): void {
    const index = this.basketItems.findIndex(row => row == item);
    if (-1 !== index) {
      this.basketItems[index][property] = newVal;
    }
    this.save();
  }

  clear(): void {
    this.account = null;
    this.accountID = null;
    this.basketItems = [];
    this.save();
  }

  saveLastQuoteRequest(request: GetQuoteRequest) {
    this._lastQuoteRequest = request;
    this.save();
  }

  setVatPercent(percent: number): void {
    if(percent === undefined || percent === null) return;
    this._vatPercent = percent;
    this.save();
  }

  public load(): void {
    this.basketItems = [];
    const cart: BasketState = JSON.parse(sessionStorage.getItem(this.basketId));
    if ( cart ) {
      this.basketItems = cart.basketItems;
      this._lastQuoteRequest = cart.lastQuoteRequest;
      this._vatPercent = cart.vatPercent;
    }
  }

  private save(): void {
    const state: BasketState = { basketItems: this.basketItems, lastQuoteRequest: this._lastQuoteRequest, vatPercent: this._vatPercent };
    sessionStorage.setItem(this.basketId, JSON.stringify(state));
  }

  get items(): BasketItem[] {
    return this.basketItems;
  }

  get subTotal(): number {
    let total = 0;
    let arr = this.basketItems.filter(item => !item.transaction_id);
    arr.forEach(item => {
      total += item.price;
    })
    return total;
  }

  get vat(): number {
    return this.subTotal * (this._vatPercent);
  }

  get grandTotal(): number {
    return this.subTotal + this.vat;
  }

  get lastQuoteRequest(): GetQuoteRequest {
    return this._lastQuoteRequest;
  }
}
