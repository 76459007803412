import { createAction, props } from '@ngrx/store';
import { CountdownTimerModel } from '../../../shared/interfaces/countdown-timer-response';

export enum CountdownTimerActionsEnum {
  Load = '[CountdownTimer] Load',
  LoadSuccess = '[CountdownTimer] Load Successful',
  LoadError = '[CountdownTimer] Load Error'
}

export const countdownTimerLoad = createAction(CountdownTimerActionsEnum.Load, props<{accountId: string}>());
export const countdownTimerLoadSuccess = createAction(CountdownTimerActionsEnum.LoadSuccess, props<{ assessment: CountdownTimerModel, verification: CountdownTimerModel }>());
export const countdownTimerLoadError = createAction(CountdownTimerActionsEnum.LoadError, props<{error: {}}>());

