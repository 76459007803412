import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { MsalAuthActions, msalAuthAdd, msalAuthLoad, msalAuthRemove } from '../actions/msalAuth.actions';
import { NewBasketService } from '../../services/new-basket/basket.service';

@Injectable()
export class MsalAuthEffects {
  load$ = createEffect(() => this.actions$.pipe(
    ofType(msalAuthLoad),
    mergeMap(() => this.basketService.getAccount().pipe(
      map(account => ({ type: MsalAuthActions.LoadSuccess, payload: account })),
      catchError(() => of({ type: MsalAuthActions.LoadError }))
    ))
  ));
  add$ = createEffect(() => this.actions$.pipe(
    ofType(msalAuthAdd),
    mergeMap((action) => of({
      loaded: action.payload.loaded,
      payload: action.payload.payload
    }).pipe(
      map(basket => ({ type: MsalAuthActions.AddSuccess, payload: basket })),
      catchError(() => of({ type: MsalAuthActions.AddError }))
    ))
  ));
  remove$ = createEffect(() => this.actions$.pipe(
    ofType(msalAuthRemove),
    mergeMap((action) => this.basketService.removeAccount().pipe(
      map(basket => ({ type: MsalAuthActions.RemoveSuccess, payload: basket })),
      catchError(() => of({ type: MsalAuthActions.RemoveError }))
    ))
  ));

  constructor(
    private actions$: Actions,
    private basketService: NewBasketService
  ) { }
}

