<div class="modal-header">
  <h4 class="modal-title pull-left">User Profile</h4>
</div>

<div class="modal-body">

  <form [formGroup]="userForm" class="bg-light mb-3 p-2">
    <div class="row">

      <!-- The new user's name. -->
      <div class="col-md-6">
        <label for="newFirstrName" class="form-label">First Name</label>
        <input id="newFirstrName" type="text" class="form-control" formControlName="firstName">
      </div>

      <div class="col-md-6">
        <label for="newLastName" class="form-label">Last Name</label>
        <input id="newLastName" type="text" class="form-control" formControlName="lastName">
      </div>

      <!-- The new user's email address. -->
      <div class="col-md-6">
        <label for="newUserEmail" class="form-label">Email</label>
        <input id="newUserEmail" type="text" class="form-control" formControlName="email">
      </div>

      <!-- The new user's Mobile phone. -->
      <div class="col-md-6">
        <label for="newUserMobile" class="form-label">Mobile</label>
        <input id="newUserMobile" type="text" class="form-control" formControlName="mobile">
      </div>

      <!-- The new user's company position. -->
      <div class="col-md-6">
        <label for="newUserPosition" class="form-label">Position</label>
        <input id="newUserPosition" type="text" class="form-control" formControlName="position">
      </div>

    </div>
  </form>

  <!-- User roles. -->
  <app-user-roles-matrix #rolesMatrix></app-user-roles-matrix>
</div>

<div class="modal-footer">
  <alert type="danger" *ngIf="error">{{ error }}</alert>
  <button class="btn btn-link" type="button" (click)="close()">Close</button>
  <button class="btn btn-primary"
          type="button"
          (click)="createUser()"
          [disabled]="saving"
          [class.is-loading]="saving">Create New User</button>
</div>
