import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { ReactiveFormsModule } from '@angular/forms';
import { BootstrapComponentsModule } from 'src/app/shared/components/bootstrap/bootstrap.module';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { BranchAddressesModalModule } from 'src/app/shared/components/branch-addresses-modal/branch-addresses-modal.module';
import { WorkCategoriesModalModule } from 'src/app/shared/components/work-categories-modal/work-categories-modal.module';
import { WorkCategoriesModule } from 'src/app/shared/components/work-categories/work-categories.module';
import { WorkAreasModalModule } from 'src/app/shared/components/work-areas-modal/work-areas-modal.module';
import { WorkAreasModule } from 'src/app/shared/components/work-areas/work-areas.module';
import { ManageContactsModalModule } from 'src/app/shared/components/manage-contacts-modal/manage-contacts-modal.module';
import { CompanySearchModule } from 'src/app/shared/components/company-search/company-search.module';

import { CompanyDetailsPageComponent } from '../../features';
import {
  BranchAddressesModalComponent,
  WorkCategoriesModalComponent,
  WorkAreasModalComponent,
  ManageContactsModalComponent
} from '../../core';
import { WorkRegionSelecterModule } from 'src/app/shared/components/work-region-selecter/work-region-selecter.module';
import { WorkAreaSelecterModule } from 'src/app/shared/components/work-area-selecter/work-area-selecter.module';


const routes: Routes = [
  { path: '', component: CompanyDetailsPageComponent }
]

@NgModule({
    declarations: [CompanyDetailsPageComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        BootstrapComponentsModule,
        CompanySearchModule,
        LoadingSpinnerModule,
        BranchAddressesModalModule,
        WorkCategoriesModule,
        WorkCategoriesModalModule,
        WorkAreasModule,
        WorkAreasModalModule,
        ManageContactsModalModule,
        WorkRegionSelecterModule,
        WorkAreaSelecterModule,
        RouterModule.forChild(routes)
    ],
    exports: [CompanyDetailsPageComponent]
})
export class CompanyDetailsPageModule {}
