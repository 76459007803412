import * as AppState from '../../../app.state'
import { createReducer, on } from "@ngrx/store";
import * as  CountdownTimerActions from '../actions/countdownTimerActionsEnum';
import { CountdownTimerModel } from '../../../shared/interfaces/countdown-timer-response';

export interface State extends AppState.State{
  countDown: CountdownState;
}
export interface CountdownState {
  assessment: CountdownTimerModel,
  verification: CountdownTimerModel
}

export interface CountdownErrorState extends CountdownState{
  loading: boolean,
  error: object
}

const initialState: CountdownState = {
  assessment: {
    bundleId: '',
    bundleName: '',
    respondByDateTime: null,
    assessmentIds: []
  },
  verification: {
    bundleId: '',
    bundleName: '',
    respondByDateTime: null,
    assessmentIds: []
  },
}

export const countdownTimerReducer = createReducer<CountdownState>(
  initialState,
  on(CountdownTimerActions.countdownTimerLoad, (state, action): CountdownState => {
    return {
      ...state,
    };
  }),
  on(CountdownTimerActions.countdownTimerLoadSuccess, (state, action): CountdownState => {
    return {
      ...state,
      assessment: {...action.assessment},
      verification: { ...action.verification }
    };
  }),
  on(CountdownTimerActions.countdownTimerLoadError, (state, action): CountdownErrorState => {
    return {
      ...state,
      loading: false,
      error: action.error
    };
  }),
);
