export interface UpdateContactParameters {
  ContactId: string;
  AccountId?: string;
  Contactname?: string;
  FirstName?: string;
  LastName?: string;
  Email?: string;
  Telephone?: string; 
  Mobilephone?: string;
  Position?: string;
}
