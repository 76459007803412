import { LatestBundleResponse } from "..";
export interface UnspscCode {
  segmentId: number;
  segmentTitle: string;
  familyId: number;
  familyTitle: string;
  classId: number;
  classTitle: string;
  level: number;
  parentId?: number;
  children: UnspscCode[];
  hasChildrem:boolean;
  id: string;
  fullName: string;
}
export interface ParentWorkCategory {
  id: number,
  name: string,
  subCategories: WorkCategory[]
}

export interface WorkCategory {
  parentName?: string;
  chasSectors: Array<number> | null;
  cpv: unknown | null;
  id: string;
  matchesAccountVariant: boolean;
  name: string;
  parentId: number;
  proClass: unknown | null;
  workCode: number;
  isSelected: boolean;
}

export interface WorkCategoryView {
  id: string;
  parentId: number;
  workCode: number;
  name: string;
  subHeader: string | null;
  workType: string;
  isSelected: boolean;
  matchesAccountVariant: boolean;
  chasSectors: Array<number> | null;
}

export interface CheckBundlesMatchCategoriesResult {
  bundle?: LatestBundleResponse;
  result?: WorkCategory[];
}

export interface CheckBundlesMatchCategories {
  [key: number]: CheckBundlesMatchCategoriesResult;
}
