import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable()
export class SnackBarClass {
  constructor(private readonly _snackbar: MatSnackBar) {}

  public displaySnackbar(
    message: string,
    action: string,
    horizontalPosition?: MatSnackBarHorizontalPosition,
    verticalPosition?: MatSnackBarVerticalPosition,
    panelClass?: string
  ) {
    this._snackbar.open(message, action, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      panelClass: [panelClass],
    });
  }

  public dismissSnackbar() {
    this._snackbar.dismiss();
  }
}
