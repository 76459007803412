import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LoadingService {

  private loadingAccount = new BehaviorSubject<boolean>(false);
  private loadingAssessment = new BehaviorSubject<boolean>(false);
  private loadingContacts = new BehaviorSubject<boolean>(false);

  isAccountLoading = this.loadingAccount.asObservable();
  isAssessmentLoading = this.loadingAssessment.asObservable();
  isContactsLoading = this.loadingContacts.asObservable();

  constructor() { }

  changeAccountLoading(bool: boolean) {
    this.loadingAccount.next(bool);
  }

  changeAssessmentLoading(bool: boolean) {
    this.loadingAssessment.next(bool);
  }

  changeContactsLoading(bool: boolean) {
    this.loadingContacts.next(bool);
  }

}
