export const featureFlagNames = {
  TAD_DISCOUNT_CODE: 'ContractorPortal__TADiscountCodes',
  CERTIFICATE_OPTIONS: 'ContractorPortal__CertificateOptions',
  LHS_ASSESSMENT_MENU: 'ContractorPortal__LHSAssessmentMenu',
  HUMAN_FOCUS: 'ContractorPortal__HumanFocus',
  ADDITIONAL_VERIFICATION_SECTION: 'ContractorPortal__AdditionalVerificationSection',
  MANDATED_CLIENT_FIELD: 'ContractorPortal__MandatedClientField',
  EXISTING_FILE_UPLOAD: 'ContractorPortal__ExistingFileUpload',
  SESSION_TIMEOUT: 'ContractorPortal__SessionTimeout',
  RESUBMIT_VERIFICATION: 'ContractorPortal__ResubmitVerification',
  SURVEY_ASSIST: 'ContractorPortal__SurveyAssist',
  INSURANCE_MODULES: 'ContractorPortal__InsuranceModules',
  DASHBOARD_ALERTTEXTBOX: 'ContractorPortal__DashboardAlertTextbox',
  INTRO_VIDEO: 'ContractorPortal__IntroductionVideo',
  NEW_DATA_LAYER: 'ContractorPortal__NewDataLayer',
  FOUNDATION_V2: 'ContractorPortal__FoundationV2',
  DATA_SHARING_CQMS: 'ContractorPortal_DataSharing_CQMS',
  SUPPLIER: 'ContractorPortal__Supplier'
}
