import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductBundlesComponent } from './product-bundles.component';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { NothingModule } from 'src/app/shared/components/nothing/nothing.module';
import { HttpClientModule } from '@angular/common/http';
import { ProductBundleModulesComponent } from './modules/modules.component';
import { BootstrapComponentsModule } from '../bootstrap/bootstrap.module';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [ProductBundlesComponent, ProductBundleModulesComponent],
  imports: [CommonModule, HttpClientModule, BootstrapComponentsModule, LoadingSpinnerModule, NothingModule, PipesModule],
  exports: [ProductBundlesComponent]
})
export class ProductBundlesModule {}
