import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { first, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountsService } from '../../../core/services/accounts.service';
import { ContactsService } from '../../../core/services/contacts.service';
import { RepositoryService } from '../../../core/services/repository.service';
import { UpdateContactParameters, CreateContactParameters } from '../../index';

@Component({
  selector: 'app-manage-contacts-modal',
  templateUrl: './manage-contacts-modal.component.html',
  styleUrls: ['./manage-contacts-modal.component.scss']
})
export class ManageContactsModalComponent implements OnInit {
  // TODO possible support for choosing a primary contact.

  accountId: string;
  contactsForm: UntypedFormGroup;
  contactsArray: UntypedFormArray;
  error: string;
  isLoading: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    private Accounts: AccountsService,
    private Contacts: ContactsService,
    private Repository: RepositoryService
  ) {}

  ngOnInit() {
    this.contactsForm = new UntypedFormGroup({
      contacts: new UntypedFormArray([])
    });
    this.contactsArray = this.contactsForm.get('contacts') as UntypedFormArray;
    this.fetchContacts();
  }

  /**
   * Closes the dialogue/modal window.
   */
  close(): void {
    this.bsModalRef.hide();
  }

  /**
   *
   */
  fetchContacts(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.contactsForm.disable();
    this.Contacts.getContacts(this.accountId)
      .pipe(
        first(),
        finalize(() => {
          this.contactsForm.enable();
          this.isLoading = false;
        })
      )
      .subscribe({
        next: contacts => {
          contacts.forEach(contact => {
            const fg = this.createContactRow(false);
            fg.patchValue({
              ContactId: contact.contactId,
              FirstName: contact.firstname,
              LastName: contact.lastname,
              Position: contact.position,
              Email: contact.email,
              Telephone1: contact.telephone,
              IsPrimaryContact: contact.isPrimaryContact
            });
            this.contactsArray.controls.push(fg);
          });
        },
        error: (response: HttpErrorResponse) => this.error = response.error.Message
      });
  }

  /**
   *
   */
  addContactRow(): void {
    this.contactsArray.controls.push(this.createContactRow(true));
  }

  /**
   *
   */
  createContactRow(editing: boolean = false): UntypedFormGroup {
    return new UntypedFormGroup({
      ContactId: new UntypedFormControl(null),
      FirstName: new UntypedFormControl('', [Validators.required]),
      LastName:  new UntypedFormControl('', [Validators.required]),
      Email: new UntypedFormControl('', [Validators.required]),
      Telephone1: new UntypedFormControl(''),
      Position: new UntypedFormControl(''),
      IsPrimaryContact: new UntypedFormControl(false),
      _editing: new UntypedFormControl(editing)
    });
  }

  /**
   *
   */
  editRow(row: UntypedFormGroup): void {
    row.get('_editing').setValue(true);
  }

  /**
   *
   */
  saveRow(row: UntypedFormGroup): void {
    if (row.invalid) {
      return;
    }

    // If a contact ID is present, update an existing contact - otherwise create one.
    if (row.value.ContactId) {
      this.updateExistingContact(row);
    } else {
      this.createNewContact(row);
    }
  }

  /**
   *
   */
  updateExistingContact(row: UntypedFormGroup): void {
    const params = row.value as UpdateContactParameters;
    row.disable();
    this.Contacts.updateContact(params)
      .pipe(
        first(),
        finalize(() => row.enable())
      )
      .subscribe({
        next: () => {
          //row.patchValue({ ContactId: newContactId });
          this.postRowUpdate(row);
        },
        error: (response: HttpErrorResponse) => this.error = response.error.Message
      });
  }

  /**
   *
   */
  createNewContact(row: UntypedFormGroup): void {
    const params = row.value as CreateContactParameters;
    params.AccountId = this.accountId;

    row.disable();
    this.Contacts.createContact(params)
      .pipe(
        first(),
        finalize(() => row.enable())
      )
      .subscribe({
        next: response => {
          row.patchValue({ ContactId: response });

          this.postRowUpdate(row);
        },
        error: (response: HttpErrorResponse) => this.error = response.error.Message
      });
  }

  /**
   *
   */
  private postRowUpdate(row: UntypedFormGroup): void {
    // If we've edited the primary contact, refresh the account details.
    if (row.value.IsPrimaryContact) {
      this.Accounts.refresh();
    }
    // Take the row out of editing mode.
    row.get('_editing').setValue(false);
  }

  /**
   *
   */
  deleteRow(index: number): void {
    const row = this.contactsArray.at(index);
    row.disable();
    if (row.value.ContactId) {
      // Actually delete the row.
      this.Contacts.deactivateContact(row.value.ContactId)
        .pipe(
          first(),
          finalize(() => row.enable())
        )
        .subscribe({
          next: () => this.contactsArray.removeAt(index),
          error: (response: HttpErrorResponse) => this.error = response.error.Message
        });
    } else {
      // Just remove it from the list.
      this.contactsArray.removeAt(index);
    }
  }

  /**
   *
   */
  isEditing(row: UntypedFormGroup): boolean {
    return row.get('_editing').value;
  }

  /**
   *
   */
  isNew(row: UntypedFormGroup): boolean {
    return null === row.get('ContactId').value;
  }

  /**
   *
   */
  getContactName(row: UntypedFormGroup): string {
    let firstName = row.get('FirstName').value ?? '';
    let lastName = row.get('LastName').value ?? '';
    return (firstName + ' ' + lastName).trim();
  }

  /**
   *
   */
  getContactPosition(row: UntypedFormGroup): string {
    return row.get('Position').value;
  }

  /**
   *
   */
  getContactEmail(row: UntypedFormGroup): string {
    return row.get('Email').value;
  }

  /**
   *
   */
  getContactPhone(row: UntypedFormGroup): string {
    return row.get('Telephone1').value;
  }

  /**
   *
   */
  isPrimaryContact(row: UntypedFormGroup): string {
    return row.value.IsPrimaryContact ? 'Yes' : 'No';
  }
}
