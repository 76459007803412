import { createAction, props } from '@ngrx/store';
import { GetQuoteRequest } from 'src/app/shared';

export enum BasketQuoteActions {
  Add = '[Basket Quote] Add',
  AddSuccess = '[Basket Quote] Add Successful',
  AddError = '[Basket Quote] Add Error',
  Remove = '[Basket Quote] Remove',
  RemoveSuccess = '[Basket Quote] Remove Successful',
  RemoveError = '[Basket Quote] Remove Error',
  Load = '[Basket Quote] Load',
  LoadSuccess = '[Basket Quote] Load Successful',
  LoadError = '[Basket Quote] Load Error'
}

export const BasketQuoteAdd = createAction(BasketQuoteActions.Add, props<{ quote: GetQuoteRequest }>());
export const BasketQuoteAddSuccess = createAction(BasketQuoteActions.AddSuccess, props<{ payload: GetQuoteRequest }>());
export const BasketQuoteAddError = createAction(BasketQuoteActions.AddError);

export const BasketQuoteRemove = createAction(BasketQuoteActions.Remove);
export const BasketQuoteRemoveSuccess = createAction(BasketQuoteActions.RemoveSuccess, props<{ payload: GetQuoteRequest }>());
export const BasketQuoteRemoveError = createAction(BasketQuoteActions.RemoveError);

export const BasketQuoteLoad = createAction(BasketQuoteActions.Load);
export const BasketQuoteLoadSuccess = createAction(BasketQuoteActions.LoadSuccess, props<{ payload: GetQuoteRequest }>());
export const BasketQuoteLoadError = createAction(BasketQuoteActions.LoadError);
