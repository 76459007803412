import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { azureB2c } from '../../shared';
import * as msal from '@azure/msal-browser';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { IPublicClientApplication } from '@azure/msal-browser';
import { MonitorSeverity } from '../../shared';
import { monitorService } from './monitor.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public account: any = undefined;
  public accountResult: msal.AuthenticationResult;
  public loginSuccessSubject: Subject<boolean> = new Subject();
  public setFlowTask: Promise<void>;

  private clientApplication: IPublicClientApplication;
  private config: IEnvironment;
  private readonly authorityFlow: string;

  constructor(private readonly monitorService: monitorService) {
    const env: Environment = new Environment();
    this.config = env.getConfigSync();

    if (sessionStorage.getItem('Chaslogin')) {
      this.authorityFlow = this.config.msal.auth.chasauthority;
    } else {
      this.authorityFlow = this.config.msal.auth.authority;
    }

    this.setFlowTask = this.SetFlow(this.authorityFlow);
  }

  public isLoggedIn(): boolean {
    return this.account !== undefined;
  }

  public async loginSignupRedirect(): Promise<void> {
    await this.setFlowTask;
    await this.clientApplication.loginRedirect({ scopes: this.config.scopes });
  }

  public async azureLogout(): Promise<void> {
    localStorage.removeItem(azureB2c.ACCESS_TOKEN_KEY);
    localStorage.removeItem('expiresOn');
    sessionStorage.removeItem('Chaslogin');
    if (this.clientApplication) await this.clientApplication.logoutRedirect();
  }

  private async resetPassword(): Promise<void> {
    let authErr = { errorMessage: azureB2c.ERROR_CODE_FORGOTTON_PASSWORD };
    if (this.checkIfForgotPassword(authErr)) {
      try {
        await this.SetFlow(this.config.msal.auth.resetAuthority);
        await this.loginSignupRedirect();
      } catch (authErr) {
        this.monitorService.exception(authErr, MonitorSeverity.ERROR);
      }
    }
  }

  private checkIfForgotPassword(authErr: { errorMessage: any }): boolean {
    return authErr.errorMessage.indexOf(azureB2c.ERROR_CODE_FORGOTTON_PASSWORD) > -1;
  }

  public async loginWithCHAS(): Promise<void> {
    sessionStorage.setItem('Chaslogin', 'true');
    await this.SetFlow(this.config.msal.auth.chasauthority);
  }

  public async forgetPassword(): Promise<void> {
    sessionStorage.setItem('Chaslogin', 'false');
    await this.SetFlow(this.config.msal.auth.resetAuthority);
  }

  public async externalLogin(): Promise<void> {
    sessionStorage.setItem('Chaslogin', 'false');
    await this.SetFlow(this.config.msal.auth.authority);
  }

  private async SetFlow(flowUrl: string): Promise<void> {
    this.clientApplication = await msal.PublicClientApplication.createPublicClientApplication({
      auth: {
        clientId: this.config.msal.auth.clientId,
        authority: flowUrl,
        redirectUri: this.config.msal.auth.redirectUri,
        navigateToLoginRequestUrl: this.config.msal.auth.navigateToLoginRequestUrl,
        knownAuthorities: this.config.msal.auth.knownAuthorities,
      },
    });

    this.clientApplication.addEventCallback((eventMessage: msal.EventMessage): void => {
      if (eventMessage.eventType === msal.EventType.LOGIN_SUCCESS || eventMessage.eventType === msal.EventType.ACQUIRE_TOKEN_SUCCESS) {
        this.loginSuccessSubject.next(true);
      }
    });

    this.clientApplication
      .handleRedirectPromise()
      .then((tokenResponse: msal.AuthenticationResult): void => {
        if (tokenResponse !== null) {
          this.account = tokenResponse;
          this.accountResult = tokenResponse;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
