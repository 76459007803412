<div class="inner_content" *ngIf="foundation; else paidMember">
  <div class="card_text">
    If you're hoping to gain accreditation, demonstrate your compliance to clients and meet construction and related
    industries' pre-qualification requirements, you can find a CHAS accreditation package perfectly suited to the size
    of your business and your desired level of accreditation.
  </div>
  <div class="card_buttons">
    <button class="btn btn-primary on_card" [routerLink]="['/membership']">
      Get Accredited
    </button>
  </div>
</div>

<ng-template #paidMember>
  <div class="inner_content ">
    <div class="card_text">
      <div class="mb-2">
        <div *ngIf="showBundleReferenceCode">
          <p>Reference Code: <b>{{bundleReferenceCode}}</b> </p>
        </div>
      </div>
      <div class="mt-4">
        <!-- check if accreditation and whether expired or not -->
        <div *ngIf="authAccount && !isAccreditationInProgress">
          <span *ngIf="authAccount.accreditationExpiry && !accreditationExpired">
            <em class="fas fa-check-circle" style="color:#608A32"></em>
            Valid until:
          </span>
          <span *ngIf="accreditationExpired">
            <em class="fas fa-times-circle" style="color:red"></em>
            Expired:
          </span>

          <span *ngIf="authAccount.accreditationExpiry">
            {{ authAccount.accreditationExpiry | date: 'dd/MM/yyyy'}}
            <em class="fas fa-info-circle" tooltip="date of expiry" style="font-size: 12px;"></em>
          </span>
          <br />
        </div>

        <!-- No accreditation display message -->
        <div *ngIf="authAccount && isAccreditationInProgress && authAccount.membershipexpiry">
          <span>
            <em
              class="fas fa-exclamation-circle"
              style="color:#fcba03">
            </em>
            <span *ngIf="getCustomerType(customerTypes.CONTRACTOR)">
              Accreditation:
            </span>
            <span *ngIf="getCustomerType(customerTypes.SUPPLIER)">
              Verification:
            </span>
            <span>Incomplete</span>
          </span>
          <em
            class="fas fa-info-circle"
            tooltip="To complete your accreditation complete and submit your assessments">
          </em>
          <br />
        </div>

        <div
          *ngIf="authAccount && authAccount.accreditationExpiry && !isAccreditationInProgress && !accreditationExpired && displayCertificateLink">
          <em class="fa fa-download"></em>
          <a class="ml-1" [routerLink]="[]" (click)="!!downloadCertificate()">Download certificate</a>
        </div>


        <div *ngIf="document">
          <em class="fas fa-{{docIcon}}" [style]="docIconColor"></em> {{document.productName}} expires: {{
          document.expiryDate | date: 'dd/MM/yyyy' }}
          <em class="fas fa-info-circle" tooltip="date of expiry"></em>
          <br />
        </div>

      </div>
    </div>

    <div class="card_buttons">
      <a class="on_card" [routerLink]="[]" (click)="!!downloadInvoice()"><em class="fa fa-download"></em> Download invoice</a>
      <button class="btn btn-primary on_card" [routerLink]="['/assessments']" *ngIf="!document && !membershipExpired">
        <span *ngIf="authAccount && isAccreditationInProgress">Complete my assessment</span>
        <span *ngIf="authAccount && !accreditationExpired && !isAccreditationInProgress">View my assessment</span>
      </button>

      <button class="btn btn-primary on_card" (click)="goToDocument()" *ngIf="document && !accreditationExpired"
        [disabled]="membershipExpired">
        Update my documents
      </button>

      <button class="btn btn-primary on_card" [routerLink]="['/membership']"
        *ngIf="accreditationExpired && !isAccreditationInProgress" [disabled]="!membershipExpired">
        Get Accredited
      </button>
    </div>
  </div>
</ng-template>
