import { createReducer, on } from '@ngrx/store';
import { getStorage } from 'src/app/shared/functions/getBasket.functions';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();
import { BasketSummaryAddSuccess, BasketSummaryLoadSuccess, BasketSummaryRemoveSuccess } from '../actions/basketSummary.actions';

export const initialState = getStorage('newBasketSummary');

const _basketSummaryReducer = createReducer(
  initialState,
  on(BasketSummaryAddSuccess, (state, action) => action.payload),
  on(BasketSummaryLoadSuccess, (state, action) => action.payload),
  on(BasketSummaryRemoveSuccess, (state, action) => action.payload),
);

export function basketSummaryReducer(state, action) {
  return _basketSummaryReducer(state, action);
}
