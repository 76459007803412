import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import applicationTypesJSON from 'src/assets/json/application-types.json';
import assessmentTypesJSON from 'src/assets/json/assessment-types.json';
import productBundlesJSON from 'src/assets/json/product-bundles.json';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';

import {
  LatestBundleResponse,
  ThirdPartyProduct,
  MembershipResponse,
  GetQuoteRequest,
  GetQuoteResponse,
  ApplicationTypeDetails,
  AssessmentTypeDetails,
  PriceList,
  RetrieveProductsResponse,
  UserTypeCode,
} from '../../shared';
import { IFirPriceList } from 'src/app/shared/interfaces/fir-price-list';
import { Store } from '@ngrx/store';
import { Territory } from 'src/app/shared/constants/territory.enum';
import { ProductModules } from 'src/app/shared/interfaces/verification-type';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private allPriceLists: PriceList[];
  private readonly data$ = this.store.select(selectCurrentUserState);
  private accountDetails: any;
  private membership: MembershipResponse = null;
  private environment: IEnvironment;
  private isAus = false;

  constructor(private http: HttpClient, private readonly store: Store) {
    this.data$.subscribe((data) => {
      if (data.loaded) {
        this.accountDetails = data.allAccounts;
      }
    });

    const env = new Environment();
    this.environment = env.getConfigSync();
  }

  public getDashboardConfig(type: string): Observable<any> {
    let config = '';
    this.isAus = this.environment.territory === Territory.AUSTRALIA;

    if (this.isAus) {
      config = this.getDashboardConfigUrlAus(type);
    } else {
      config = this.getDashboardConfigUrl(type);
    }

    return this.http.get<any>(config);
  }

  private getDashboardConfigUrl(type: string): string {
    let config = '';

    switch (type) {
      case 'supplier':
        config = `${this.environment.apiEndpoint.blobProducts}/dashboard-config-supplier.json`;
        break;
      case 'chas foundation':
        config = `${this.environment.apiEndpoint.blobProducts}/dashboard-config-foundation.json`;
        break;
      default:
        config = `${this.environment.apiEndpoint.blobProducts}/dashboard-config-contractor.json`;
        break;
    }

    return config;
  }

  private getDashboardConfigUrlAus(type: string): string {
    let config = '';

    switch (type) {
      case 'verified supplier':
        config = `${this.environment.apiEndpoint.blobProducts}/dashboard-config-verified-supplier.json`;
        break;
      default:
        config = `${this.environment.apiEndpoint.blobProducts}/dashboard-config-verified-contractor.json`;
        break;
    }

    return config;
  }

  /**
   * Returns the price for FIR based on number of employees
   */
  public async firPriceList(numberOfEmployees: number): Promise<IFirPriceList> {
    return this.http
      .get<IFirPriceList>(`${this.environment.apiEndpoint.products}/public/products/fir?numberOfEmployees=${numberOfEmployees}`)
      .toPromise();
  }

  //Returns a list of thrid party products
  getThirdPartyProducts(): Observable<any> {
    return this.http.get(`${this.environment.apiEndpoint.thirdParty}`).pipe(map((response) => response as ThirdPartyProduct[]));
  }

  /**
   * Returns the URL of the image to use for a product bundle.
   */
  getProductBundleImage(bundle: LatestBundleResponse): string {
    // TODO there should be a far more straightforward way of determining the image to use.
    const filename = bundle.bundleName.replace('(CAS)', '').replace('Assured', '').trim().replace(/\s/g, '-').toLowerCase();

    return `assets/img/bundle/${filename}.svg`;
  }

  getEmployeeBands(num: number) {
    if (num <= 1) return 'Sole Trader';
    else if (num > 1 && num < 5) return '2-4 Employees';
    else if (num > 4 && num < 16) return '5-15 Employees';
    else if (num > 15 && num < 31) return '16-30 Employees';
    else if (num > 30 && num < 51) return '31-50 Employees';
    else if (num > 50 && num < 101) return '51-100 Employees';
    else if (num > 100 && num < 201) return '101-200 Employees';
    else if (num > 200 && num < 501) return '201-500 Employees';
    else if (num > 500 && num < 1001) return '501-1000 Employees';
    else return '1001+ Employees';
  }

  /**
   * Returns the relevant joining fee for this purchase
   * @param employeeNumber
   */
  getJoiningFee(employeeNumber: number, basketTotalPrice: number) {
    const params = new HttpParams().set('employeeNumber', employeeNumber.toString()).set('totalBasketPrice', basketTotalPrice.toString());
    return this.http.get(`${this.environment.apiEndpoint.products}/public/products/joiningfee`, { params });
  }

  /**
   * Returns boolean based on if customer is upgrading from a free membership
   * @param accountId
   */
  isFreeMembership(accountId: string) {
    const params = new HttpParams().set('accountId', accountId);
    return this.http.get<boolean>(`${this.environment.apiEndpoint.products}/public/products/upgrade`, { params });
  }

  getMembership(accountId: string, contactId: string): MembershipResponse {
    if (this.membership) return this.membership;

    this.data$.subscribe((data) => {
      if (data.loaded) {
        this.accountDetails = data.allAccounts;

        const account = this.accountDetails.find((x) => x.contactId === contactId && x.accountId === accountId);
        if (account) {
          this.membership = {
            type: account.membershipTypeId,
            expirationDate: account.membershipExpiry,
          };
        }
      }
    });

    return this.membership;
  }

  getCreditScore(accountId: string): Observable<any> {
    return this.http.get(`${this.environment.apiEndpoint.credits}/company/report/${accountId}`);
  }

  getVerificationTypes(bundleName: string): Observable<ProductModules> {
    return this.http
      .get(`${this.environment.apiEndpoint.products}/verificationtypes/${bundleName}`)
      .pipe(map((response) => response as ProductModules));
  }

  async searchForPriceListAsync(name: string, upgrade: boolean, currentLevel?: number): Promise<PriceList> {
    let priceLists = this.allPriceLists ?? (await this.getPriceListsAsync());
    this.allPriceLists = priceLists;

    if (!currentLevel || currentLevel < 1) {
      currentLevel = 1;
    }
    const currentPackageName: string = productBundlesJSON.find((x) => x.level === currentLevel).name;

    if (upgrade) {
      priceLists = priceLists.filter((x) => x.name.toLowerCase().includes('upgrade from ' + currentPackageName.toLowerCase()));
    } else {
      priceLists = priceLists.filter((x) => !x.name.toLowerCase().includes('upgrade'));
    }
    return priceLists.find((x) => x.name === name || x.name.startsWith(name));
  }

  async getPriceListsAsync(): Promise<Array<PriceList>> {
    return await this.http.get<Array<PriceList>>(`${this.environment.apiEndpoint.products}/public/pricelists`).toPromise();
  }

  async getMembershipQuoteAsync(query: GetQuoteRequest, mapFeeCodes?: boolean): Promise<GetQuoteResponse> {
    if (!query.accountId) {
      delete query.accountId;
    }
    mapFeeCodes = mapFeeCodes ?? false;

    if (mapFeeCodes && query.customerType !== UserTypeCode.SUPPLIER) {
      query.applicationType = this.convertToFeeCode(query.applicationType, assessmentTypesJSON);
    }

    return await this.http.post<GetQuoteResponse>(`${this.environment.apiEndpoint.products}/public/fees/membership`, query).toPromise();
  }

  async getIndividualQuoteAsync(query: GetQuoteRequest): Promise<GetQuoteResponse> {
    if (!query.accountId) {
      delete query.accountId;
    }

    return await this.http.post<GetQuoteResponse>(`${this.environment.apiEndpoint.products}/public/fees/individual`, query).toPromise();
  }

  async getAddonProductsAsync(code: string): Promise<Array<RetrieveProductsResponse>> {
    return await this.http
      .get<Array<RetrieveProductsResponse>>(`${this.environment.apiEndpoint.products}/getaddonproducts/${code}`)
      .toPromise();
  }

  convertToFeeCode(code: any, types: Array<AssessmentTypeDetails | ApplicationTypeDetails>): string {
    const match = types.find((x) => x.code == code);
    if (!match) {
      return null;
    }
    if (!match.feesCode) {
      return null;
    }
    return match.feesCode;
  }

  convertApplicationTypeToFeeCode(code: string) {
    return this.convertToFeeCode(code, assessmentTypesJSON);
  }

  convertSectorTypeToFeeCode(code: string) {
    return this.convertToFeeCode(code, applicationTypesJSON);
  }

  convertQuoteRequestToOpportunityObject(quoteRequest: GetQuoteRequest): any {
    if (!quoteRequest) {
      return null;
    }
    return {
      name: 'Individual Product',
      accountId: quoteRequest.accountId,
      bundleId: quoteRequest.productId,
      contactId: quoteRequest.contactId,
      employeeNum: quoteRequest.numberOfEmployees,
      NumberofDirectEmployees: quoteRequest.directEmployees,
      LabourOnlySubContractors: quoteRequest.labourOnlySubContractors,
      BonafideSubContractors: quoteRequest.bonafideSubContractors,
      callbackSuccessUrl: quoteRequest.successUrl,
      callbackCancelUrl: quoteRequest.cancelUrl,
      dataSharingAllowed: quoteRequest.dataSharingAllowed,
    };
  }
}
