
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { RegistersteponeActions, registerStepOneAdd, registerStepOneLoad, registerStepOneRemove } from '../actions/registerStepOne.actions';
import { RegistersteponeService } from '../../services/new/registerStepOne.service';

@Injectable()
export class RegistersteponeEffects {
  load$ = createEffect(() => this.actions$.pipe(
    ofType(registerStepOneLoad),
    mergeMap(() => this.registerStepOneService.getRegisterstepone().pipe(
      map(registerStepOne => ({ type: RegistersteponeActions.LoadSuccess, payload: registerStepOne })),
      catchError(() => of({ type: RegistersteponeActions.LoadError }))
    ))
  ));
  add$ = createEffect(() => this.actions$.pipe(
    ofType(registerStepOneAdd),
    mergeMap((action) => this.registerStepOneService.addRegisterstepone(action.registerStepOne).pipe(
      map(registerStepOne => ({ type: RegistersteponeActions.AddSuccess, payload: registerStepOne })),
      catchError(() => of({ type: RegistersteponeActions.AddError }))
    ))
  ));
  remove$ = createEffect(() => this.actions$.pipe(
    ofType(registerStepOneRemove),
    mergeMap((action) => this.registerStepOneService.removeRegisterstepone().pipe(
      map(registerStepOne => ({ type: RegistersteponeActions.RemoveSuccess, payload: registerStepOne })),
      catchError(() => of({ type: RegistersteponeActions.RemoveError }))
    ))
  ));

  constructor(
    private actions$: Actions,
    private registerStepOneService: RegistersteponeService
  ) { }
}
