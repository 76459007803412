<div class="host">

  <div class="product-bundle__badge">
    <img [src]="productImage" [attr.alt]="title">
  </div>

  <div class="product-bundle__info">
    <h3 class="h5">
      {{ title }}
    </h3>
    <div class="content">
      <div class="inner_content">
        <div class="card_text">
          <h4 class="h6"><strong>{{ subtitle }}</strong></h4>
          <p *ngFor="let paragraph of getContent">{{ paragraph }}</p>
        </div>
        <div class="card_buttons">
          <a class="btn btn-primary on_card" [ngClass]="{ 'disabled': disableButton }" (click)="doAnalytics()" [href]="ctaUrl"
            target="_new">{{ cta }}</a>
          <a *ngIf="showCta2" class="btn btn-secondary on_card" [ngClass]="{ 'disabled': disableButton }" (click)="doAnalytics()"
            [href]="ctaUrl2 " target="_new">{{ cta2 }}</a>
        </div>
      </div>
      </div>
  </div>
</div>