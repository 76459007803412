import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Assessment, AssessmentStatus } from '../../../shared';

@Component({
  selector: 'app-dashboard-accreditation-icons',
  templateUrl: './accreditation-icons.component.html',
  styleUrls: ['./accreditation-icons.component.scss']
})
export class DashboardAccreditationIconsComponent {
  @Input() assessments: Assessment[];

  constructor() { }

  trackByFn(index: number, item: Assessment) {
    return item.id;
  }


  /**
   *
   */
  getStatusClass(row: Assessment): string {
    switch (row.status?.label) {
      case AssessmentStatus.COMPLETE:
        return 'accreditation-icons__status accreditation-icons__status--success';

      default:
        return 'accreditation-icons__status';
    }
  }

  /**
   *
   */
  getStatusText(row: Assessment): string {
    switch (row.status?.label) {
      case AssessmentStatus.SUBMITTED:
      case AssessmentStatus.RESUBMITTED:
        return 'Under Review';

      default:
        return row.status?.label;
    }
  }

  /**
   *
   */
  getIconUrl(row: Assessment): string {
    // TODO this should ideally be provided by the API.
    const name = row.product?.name
      .replace(' Assured', '')
      .replace(' (BIM)', '')
      .replace(' & Diversity', '')
      .replace(' & ', '-')
      .replace(/\s/g, '-')
      .toLowerCase();
    return `/assets/img/accreditation/${name}.png`;
  }
}
