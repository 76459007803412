
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IRegistersteptwo } from 'src/app/shared/interfaces/new/registerStepTwo';
import { getStorage, setStorage } from 'src/app/shared/functions/getBasket.functions';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();

@Injectable({
  providedIn: 'root'
})
export class RegistersteptwoService {
  private readonly Registersteptwo_STORAGE_KEY: string = 'registerStepTwo';

  public getRegistersteptwo(): Observable<IRegistersteptwo> {
    return of(getStorage(this.Registersteptwo_STORAGE_KEY));
  }

  public addRegistersteptwo(newRegistersteptwo: IRegistersteptwo): Observable<IRegistersteptwo> {
    return of(setStorage(newRegistersteptwo, this.Registersteptwo_STORAGE_KEY));
  }

  public removeRegistersteptwo(): Observable<undefined> {
    this.removeStorage(this.Registersteptwo_STORAGE_KEY);
    return of(undefined);
  }

  private removeStorage(storageKey: string): void {
    return sessionStorage.removeItem(storageKey);
  }
}
