import { Component, OnInit, OnDestroy } from '@angular/core';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { AssessmentsService, SSOService, ProductService } from '../../core';
import {
  Assessment,
  LatestBundleResponse,
  SSOUrlParametersGenerator,
  ActiveCard,
  ThirdPartyProduct,
  AccountResponse,
  DateHelpers,
  dateCheck,
  MembershipResponse,
  UserTypeCode,
  CheckNewAuthenticationResponse,
  CompanyDetails,
  AccountContact,
} from '../../shared';
import { HttpErrorResponse } from '@angular/common/http';
import { Tier3Products } from 'src/app/shared/constants/tier3-products.const';
import { Router } from '@angular/router';
import { round } from 'lodash';
import { DatePipe } from '@angular/common';
import applicationTypesJSON from 'src/assets/json/application-types.json';
import assessmentTypesJSON from 'src/assets/json/assessment-types.json';
import dashboardConfigFoundation from 'src/assets/json/dashboard-config-foundation.json';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { Store } from '@ngrx/store';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';
import { Environment } from 'src/app/shared/classes/environment';
import { Territory } from 'src/app/shared/constants/territory.enum';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { WelcomeModalComponent } from 'src/app/shared/components/welcome-modal/welcome-modal.component';
import {
  HelplinePhoneNumber,
  HelpdeskEmailAddress,
  FAQUrl,
  MainUrl,
} from 'src/app/shared/components/register-carousel/register-carousel.component';
import { ProductName } from 'src/app/shared/classes/productName.class';
import { FeatureFlagService } from '../../core/services/feature-flags/feature-flag.service';
import { FeatureFlag } from '../../shared/interfaces/feature-flag';

interface IProductCards {
  compliance: string;
  people: string;
  fir: string;
  plant: string;
  shield: string;
  humanFocus: string;
  learningPathway: string;
  credit: string;
  rams: string;
  casThirdParty: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DatePipe],
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  private readonly data$ = this.store.select(selectCurrentUserState);
  private data: CurrentUserState;
  public isWelcomeModalShown = false;
  bundles: LatestBundleResponse[];
  bundle: LatestBundleResponse;
  authAccount: CheckNewAuthenticationResponse;
  companyDetails: CompanyDetails;
  assessmentList: Assessment[];
  account: AccountResponse;
  error: string;
  isLoading: boolean;
  productcards: IProductCards;
  thirdPartyProducts: ThirdPartyProduct[];
  typeAndVariant: { assessmentType: string; variant: string };
  card_config: any;
  isFoundation: boolean;
  activeCards: ActiveCard[] = [{ name: 'Business_Shield', active: null, credits: null }];
  isFoundationModalShown: boolean;
  showDashboardCarousel: boolean = true;
  membership: MembershipResponse = { type: '', expirationDate: '' };
  customerTypes = UserTypeCode;
  public firstName = 'test';
  private isAus = false;
  private welcomeModalRef: BsModalRef;
  public contact: AccountContact;
  public canUpgrade = false;

  public phoneNumber = HelplinePhoneNumber.UNITED_KINGDOM;
  public emailAddress = HelpdeskEmailAddress.UNITED_KINGDOM;
  public faqUrl = FAQUrl.UNITED_KINGDOM;
  public mainUrl = MainUrl.UNITED_KINGDOM;

  constructor(
    private assessmentsService: AssessmentsService,
    private Products: ProductService,
    private SSO: SSOService,
    private router: Router,
    private date: DatePipe,
    public modalService: BsModalService,
    private readonly store: Store,
    private readonly featureFlagService: FeatureFlagService
  ) {}

  private $destroyed = new Subject<void>();

  async ngOnInit() {
    const env = new Environment();
    const environment = await env.getConfig();

    this.isAus = environment.territory === Territory.AUSTRALIA;

    if (this.isAus) {
      this.phoneNumber = HelplinePhoneNumber.AUSTRALIA;
      this.emailAddress = HelpdeskEmailAddress.AUSTRALIA;
      this.faqUrl = FAQUrl.AUSTRALIA;
      this.mainUrl = MainUrl.AUSTRALIA;
    }

    const self = this;
    self.isLoading = false;

    let init = true;

    this.data$.subscribe({
      next: async (data) => {
        if (data.loaded) {
          this.data = data;
          this.authAccount = data.currentAccount;
          this.account = data.crm;
          this.bundles = data.bundles;
          this.companyDetails = data.companyDetails;
          this.isWelcomeModalShown = this.companyDetails.isWelcomeModalShown;
          this.contact = data.contacts[0];

          this.isFoundation = this.getIsFoundation(data.currentAccount);
          this.canUpgrade = this.checkCanUpgrade();
          if (init && this.isWelcomeModalShown) {
            init = false;
            if (this.modalService.getModalsCount() === 0) {
              this.welcomeModalRef = this.modalService.show(WelcomeModalComponent, {
                animated: true,
                backdrop: 'static',
                class: 'modal-dialog-scrollable modal-lg',
                initialState: {
                  accountId: this.authAccount.accountId,
                  contactId: this.authAccount.contactId,
                },
              });

              this.welcomeModalRef.onHide.pipe(take(1)).subscribe((x) => {
                init = false;
              });
              init = false;
            }
          }

          this.initDashboard();

          await this.initFoundationAndUnregisteredModal();
        }
      },
      error: (response: any) => {
        console.error('failed to load user', response.error);
        this.router.navigateByUrl('/dashboard');
      },
    });
  }

  async initDashboard() {
    if (this.authAccount) {
      this.membership.type = this.data.currentAccount.userType.toString() === UserTypeCode.SUPPLIER ? 'Supplier' : 'Contractor';
      await this.getConfig();

      this.assessmentsService
        .get(this.authAccount.accountId)
        .pipe(takeUntil(this.$destroyed))
        .subscribe((list: Assessment[]) => (this.assessmentList = list));

      this.activeCards.forEach((card: ActiveCard) => this.cardsActivated(card));

      this.Products.getThirdPartyProducts().subscribe((data: ThirdPartyProduct[]) => {
        this.thirdPartyProducts = data;
      });

      this.getBundles();
    }
  }

  loadingComplete() {
    this.isLoading = this.membership.type === '' && this.bundles == null;
  }

  async getConfig() {
    const dashboardConfig = await firstValueFrom(this.Products.getDashboardConfig(this.membership.type.toLowerCase()));
    if (this.isFoundation) {
      if (this.authAccount.companyHouseNo) {
        this.card_config = dashboardConfigFoundation.data.find((i) => i.credits === true).display;
      } else {
        this.card_config = dashboardConfigFoundation.data.find((i) => i.credits === false).display;
      }
    } else {
      this.card_config = dashboardConfig;
    }
    await this.isFeatureEnabled();
  }

  public async isFeatureEnabled(): Promise<void> {
    const featureFlagsArray: FeatureFlag[] = await firstValueFrom(this.featureFlagService.getFeatureFlagsFromState$());
    this.filterAndRemoveProductSections(featureFlagsArray);
    this.filterAndRemoveCards(featureFlagsArray);
  }

  private filterAndRemoveCards(featureFlagsArray: FeatureFlag[]): void {
    this.card_config.Blocks.forEach((block) => {
      block.Rows.forEach((row) => {
        row.Cards = row.Cards.filter((card) => {
          return featureFlagsArray.find((el: any) => card.FeatureFlag == el.name);
        });
      });
    });
  }

  private filterAndRemoveProductSections(featureFlagsArray: FeatureFlag[]): void {
    this.card_config.Blocks = this.card_config.Blocks.filter((card) => {
      return featureFlagsArray.some((flag: FeatureFlag): boolean => flag.name === card.FeatureFlagID);
    });
  }

  getProduct(name) {
    return this.thirdPartyProducts ? this.thirdPartyProducts.find((product) => product.hierarchyPath === name) : null;
  }

  getCardSize(size) {
    if (size === '4') return 4;
    else if (size === '6') return 6;
    else return;
  }

  ngOnDestroy() {
    this.$destroyed.next();
  }

  get foundationFeatureEnabled(): boolean {
    return true;
  }

  get accountId(): string {
    if (this.authAccount) {
      return this.authAccount.accountId;
    }
    return '';
  }

  get contactId(): string {
    if (this.authAccount) {
      return this.authAccount.contactId;
    }
    return '';
  }

  private async initFoundationAndUnregisteredModal(): Promise<void> {
    // if(this.isFoundation)
    //   this.router.navigate(['/membership/foundation']);
    //
    const membership: MembershipResponse = this.Products.getMembership(this.authAccount.accountId, this.authAccount.contactId);
  }

  get assessmentFailed(): boolean {
    const status = sessionStorage.getItem('failed_assessment');
    if (status === 'true') return true;
    else return false;
  }

  get covidVerification() {
    const disabledCode = true;
    if (disabledCode) {
      return false;
    } else {
      //this code could may be reinstated for CAS
      if (sessionStorage.getItem('covid_verification')) {
        const x = JSON.parse(sessionStorage.getItem('covid_verification'));
        if (x.status.label === 'In Progress' || x.status.label === 'Additional Information Required') return x;
      } else {
        return null;
      }
    }
  }

  get productPermissions() {
    if (this.data.roles) {
      return this.data.roles.find((b) => b.name === 'My Products')?.privilege;
    }
    return '';
  }

  private getIsFoundation(account: CheckNewAuthenticationResponse): boolean {
    if (account === null) return false;
    if (!account.membershipExpiry) return true;
    else if (this.isMembershipFoundation === true) {
      return true;
    } else {
      const foundationMember = new DateHelpers(this.data.companyDetails?.primaryContact?.accountId.membershipExpiry).foundation();
      if (foundationMember || !this.bundles[0]) return true;
      else return false;
    }
  }

  private getBundles() {
    const data = this.bundles;

    this.bundles = data;
    this.typeAndVariant = {
      assessmentType: data.length
        ? data[0]?.productAssessmentType?.toString()
        : applicationTypesJSON.find((x) => x.name === 'Construction').code,
      variant: data.length ? data[0]?.variant?.toString() : assessmentTypesJSON.find((x) => x.name === 'Full Application').code,
    };
    this.productcards = {
      compliance: this.bundles[0]?.bundleName ?? null,
      people: 'CHAS People',
      fir: this.membership.type !== 'Supplier' ? 'Fairness, Inclusion and Respect Growth Assessment' : null,
      plant: 'CHAS Plant',
      shield: 'Business Shield',
      humanFocus: 'Online Training',
      learningPathway: 'Learning Pathway',
      credit: 'Credit Safe',
      rams: 'CHAS RAMS',
      casThirdParty: this.membership.type !== 'Supplier' ? 'Common Assessment Standard Third Party' : null,
    };

    if (this.isAus) this.productcards.rams = 'CHAS SWMS Coming Soon';
    this.loadingComplete();
  }

  getProductType(type: string) {
    if (this.productcards) return this.productcards[type];
  }

  private cardsActivated(object) {
    if (this.isAus) return;

    let params = new SSOUrlParametersGenerator(this.authAccount.accountId, this.authAccount.contactId);
    this.SSO.getCredits(params.switchProvider(object.name))
      .pipe(takeUntil(this.$destroyed))
      .subscribe({
        next: (res: number) => {
          if (object.name === 'Business_Shield') {
            if (res === -1) object.active = false;
            else object.active = true;
          } else {
            object.credits = res;
          }
        },
        error: (response: HttpErrorResponse) => {
          if (response.status === 404) {
            object.active = false;
          }
        },
      });
  }

  getTier3Cards() {
    return Tier3Products;
  }

  get membershipExpiryDateString(): string {
    return this.bundles[0] ? this.date.transform(this.bundle.bundleExpiry, 'dd/MM/yyyy') : null;
  }

  get daysToExpiry(): number {
    if (this.bundles[0] == null) return 0;
    var diffInMs = Math.abs(new Date().valueOf() - new Date(this.bundles[0].bundleExpiry).valueOf());
    return round(diffInMs / (1000 * 60 * 60 * 24), 0);
  }

  get membershipExpiryStatus() {
    if (this.bundles[0] == null) return null;
    return new DateHelpers(this.bundles[0].bundleExpiry);
  }

  get membershipExpired(): boolean {
    if (this.membershipExpiryStatus == null) return true;
    return this.membershipExpiryStatus.expiry() == dateCheck.EXPIRED;
  }

  get membershipExpiring() {
    if (this.membershipExpiryStatus == null || this.membershipExpired) return false;
    return this.membershipExpiryStatus.expiry() == dateCheck.EXPIRING;
  }

  async showHumanFocus(): Promise<boolean> {
    return true;
  }

  get showCasThirdPartyTile(): boolean {
    return true;
  }

  get isMembershipCas(): boolean {
    if (this.bundles === null) return false;
    return this.bundles[0].bundleName.includes(ProductName.level4);
  }
  get isMembershipFoundation(): boolean {
    if (this.bundles === null) return false;
    return this.bundles[0]?.bundleName.includes('Foundation');
  }

  public checkCanUpgrade(): boolean {
    return (
      this.isFoundation ||
      ((this.isMembershipFoundation || !this.bundles[0]?.bundleName.includes(ProductName.level4)) && this.membership.type !== 'Supplier')
    );
  }

  get isSupplierMembership(): boolean {
    return this.membership.type === 'Supplier';
  }

  public async shouldShow(cardName: string): Promise<boolean> {
    switch (cardName) {
      case 'Human Focus':
        return await this.showHumanFocus();
      default:
        return true;
    }
  }

  get firstTime(): boolean {
    return false;
  }

  get greeting(): string {
    var today = new Date();
    var hour = today.getHours();

    if (hour < 12) {
      return 'Good morning';
    } else if (hour < 18) {
      return 'Good afternoon';
    } else {
      return 'Good evening';
    }
  }

  get membershipNavigationState() {
    return this.isFoundation ? { data: { foundation: true } } : { data: { renewal: false } };
  }

  upgradeRedirect() {
    this.router.navigateByUrl('/membership/upgrade?index=0');
  }
}
