<div class="fast-track" *ngIf="quickQuote$ | async; let quickQuote">
    <div class="fast-track__content">
        <img 
          class="fast-track__content-image"
          src="/assets/img/bundle/{{bundleName.toLowerCase()}}.png"
          [alt]="bundleName"
        />
        <div class="fast-track__content-text">
            <div class="fast-track__content-title">{{bundleName}}</div>
            <div class="fast-track__content-price" *ngIf="quickQuote">{{
                getFastTrackAmount(quickQuote) |
                currency: 'GBP'
            }}</div>
            <div class="fast-track__content-vat">+ VAT</div>
        </div>
    </div>
    <div class="fast-track__action" *ngIf="quickQuote">
        <strong>Optional Extras:</strong>
        <p class="fast-track__action-description">
            In a rush? Achieve your accreditation in 2 days with a fast track application for a one off fee of {{
                getFastTrackAmount(quickQuote) |
                currency: 'GBP'
            }}.
        </p>
        <mat-checkbox
          [formControl]="control"
          color="primary"
          class="fast-track__action-checkbox">
          Fast track my application
        </mat-checkbox>
    </div>
</div>