export interface BranchAddress {
  'parentid_account@odata.bind'?: string;
  addresstypecode: string;
  city: string;
  country: string;
  county: string;
  customeraddressid: string;
  line1: string;
  line2: string;
  line3: string;
  name: string;
  postalcode: string;
  stateorprovince: string;
}
