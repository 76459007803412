import { APP_INITIALIZER, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { Location } from '@angular/common';
import { ActionsSubject, Store } from '@ngrx/store';
import { selectMsalAuth } from './core/ngrx/selectors/msalAuth.selectors';
import { MsalAuthActions } from './core/ngrx/actions/msalAuth.actions';
import { Environment, IEnvironment } from './shared/classes/environment';
import { InteractionRequiredAuthError } from 'msal';
import { azureB2c } from './shared';
import { storeDispatchAsync } from './shared/functions/store-dispatch-async';
import { FeatureFlagActions } from './core/ngrx/actions/feature-flag.actions';

interface Payload extends AuthenticationResult {
  idTokenClaims: {
    tfp?: string;
  };
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Contractor Portal Client';
  readCookie: boolean;
  private $destroyed = new Subject<void>();
  private readonly _destroying$ = new Subject<void>();
  loginDisplay = false;
  isIframe = false;
  private authUser$ = this.store.select(selectMsalAuth);
  private config: IEnvironment;
  private publicRoutes = [
    '/register',
    '/register-aus',
    '/register-supplier',
    '/register-foundation',
    '/fir',
    '/vip',
    '/payment-success/register',
    '/membership/upgrade?index=2',
  ];

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(APP_INITIALIZER) public configData,
    private authService: MsalService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
    private location: Location,
    private readonly store: Store,
    private readonly actions: ActionsSubject
  ) {}

  async ngOnInit() {
    const environment = new Environment();
    this.config = await environment.getConfig();

    // Commented out as it stops the app from working, currently WIP until backend is implemented
    // await this.featureFlagService.retrieveFeatureFlags();

    if (this.router.url.includes(azureB2c.ERROR_CODE_FORGOTTON_PASSWORD)) {
      window.location.href = `${this.config.msal.resetUrl}&client_id=${this.config.msal.auth.clientId}&nonce=defaultNonce&redirect_uri=${this.config.msal.auth.redirectUri}&scope=openid%20${this.config.guard.authRequest.scopes[1]}&response_type=id_token%20token&prompt=login`;
    }

    if (!this.isPublicRoute(this.location.path())) {
      this.authUser$.subscribe((data) => {
        if (data.loaded && data.payload) {
          this.router.navigateByUrl('/dashboard');
        } else if (data.loaded && !data.payload) {
          this.clearPreviousData();
          this.login();
        }
      });

      this.isIframe = window !== window.parent && !window.opener;

      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this._destroying$)
        )
        .subscribe(async () => {
          await this.checkAndSetActiveAccount();
        });

      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
          takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
          let payload: Payload = <AuthenticationResult>result.payload;
          localStorage.setItem(azureB2c.ACCESS_TOKEN_KEY, payload.accessToken);
          this.setPayload(payload.accessToken);
        });
    }
    await storeDispatchAsync(this.store, this.actions, FeatureFlagActions.LoadSuccess, {
      applicationName: 'ContractorPortal',
      type: FeatureFlagActions.Load,
    });
  }

  ngOnDestroy() {
    this.$destroyed.next();
  }

  private isPublicRoute(currentRoute: string) {
    const found = this.publicRoutes.filter((route) => {
      const path = currentRoute.split('/')[1];
      return route.startsWith(`/${path}`);
    });

    return found.length !== 0;
  }

  private async checkAndSetActiveAccount(): Promise<void> {
    const accounts = this.authService.instance.getAllAccounts();
    const activeAccount = this.authService.instance.getActiveAccount();

    if (activeAccount) {
      const getToken = await this.acquireTokenSilent(activeAccount);

      if (getToken) {
        this.setPayload(getToken.accessToken);
        return;
      }
    } else if (accounts.length > 0) {
      const account = accounts[0];
      const getToken = await this.acquireTokenSilent(account);

      this.authService.instance.setActiveAccount(account);

      if (getToken) this.setPayload(getToken.accessToken);
      return;
    }

    this.setPayload();
  }

  private setPayload(payload = null): void {
    this.store.dispatch({
      type: MsalAuthActions.Add,
      payload: { loaded: true, payload: payload },
    });
  }

  private async acquireTokenSilent(account: AccountInfo) {
    const silentRequest: SilentRequest = {
      scopes: this.config.guard.authRequest.scopes,
      account: account,
      forceRefresh: false,
    };

    const tokenResponse = await this.authService.instance.acquireTokenSilent(silentRequest).catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        console.log(error);
      }
    });

    return tokenResponse;
  }

  private login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  private clearPreviousData() {
    sessionStorage.clear();
    localStorage.clear();

    document.cookie.split(';').forEach((c) => {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
  }
}
