import { Component, OnInit, OnDestroy, ViewChild, AfterViewChecked, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import jsonOrganisationTypes from 'src/assets/json/organisation-types-au.json';
import jsonWhereHeard from 'src/assets/json/where-heard.json';
import jsonWhereHeardAu from 'src/assets/json/where-heard-au.json';
import { first, finalize, take } from 'rxjs/operators';
import { firstValueFrom, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import applicationTypesJSON from 'src/assets/json/application-types.json';
import certificateOptions from 'src/assets/json/certificate-options.json';
import {
  AccountsService,
  BranchAddressesModalComponent,
  WorkAreasModalComponent,
  ManageContactsModalComponent,
  ContactsService,
  CompanySearchComponent,
  CountryService,
  AssessmentsService,
} from '../../core';
import {
  CheckAuthenticationResponse,
  AccountResponse,
  LatestBundleResponse,
  ApplicationTypeDetails,
  CompanyDetailsForm,
  CompanyDetails,
  CompanyLookup,
  AccountContact,
  featureFlagNames,
  Assessment,
  CheckNewAuthenticationResponse,
} from '../../shared';
import { ISOCountry } from 'src/app/shared/interfaces/iso-country';

import { TradeService } from 'src/app/core/services/trade.service';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { Store } from '@ngrx/store';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { Territory } from 'src/app/shared/constants/territory.enum';
import { WorkRegion, WorkRegionSelecterComponent } from 'src/app/shared/components/work-region-selecter/work-region-selecter.component';
import { WorkAreaSelecterComponent } from 'src/app/shared/components/work-area-selecter/work-area-selecter.component';
import { BundleNamesAus } from 'src/app/shared/components/select-membership/select-membership.component';
@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsPageComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('immediateParent') fieldImmediateParent: CompanySearchComponent;
  @ViewChild('ultimateParent') fieldUltimateParent: CompanySearchComponent;

  public options = {
    organisationTypes: jsonOrganisationTypes,
    whereHeard: undefined,
  };

  public BundleNamesAus = BundleNamesAus;

  public detailsForm = new UntypedFormGroup({
    register_address_city: new UntypedFormControl(''),
    register_address_street1: new UntypedFormControl(''),
    register_address_street2: new UntypedFormControl(''),
    register_address_postcode: new UntypedFormControl(''),
    register_address_country_code: new UntypedFormControl(''),

    correspondence_address_id: new UntypedFormControl(''),
    correspondence_address_city: new UntypedFormControl('', [Validators.required]),
    correspondence_address_street1: new UntypedFormControl('', [Validators.required]),
    correspondence_address_street2: new UntypedFormControl(''),
    correspondence_address_postcode: new UntypedFormControl('', [Validators.required]),
    correspondence_address_country_code: new UntypedFormControl('', [Validators.required]),
    summary: new UntypedFormControl('', [Validators.required, Validators.maxLength(5000)]),
    membership_id: new UntypedFormControl('', [Validators.required]),
    turnover: new UntypedFormControl(''),

    ultimate_parent_account_number: new UntypedFormControl(''),
    ultimate_parent_account: new UntypedFormControl(''),
    ultimate_parent_account_id: new UntypedFormControl(''),

    primary_contact_id: new UntypedFormControl(''),
    email_address: new UntypedFormControl(''),
    company_house_registration_number: new UntypedFormControl(''),
    organisation_type: new UntypedFormControl('', [Validators.required]),
    trading_name: new UntypedFormControl(''),
    workCategories: new UntypedFormControl([], [Validators.required, Validators.minLength(1)]),
    primary_trade: new UntypedFormControl(''),
    vat_reg_number: new UntypedFormControl(''),
    hear_chas: new UntypedFormControl('', [Validators.required]),
    hear_chas_text_option: new UntypedFormControl(''),
    print_pack: new UntypedFormControl(''),

    number_of_direct_employees: new UntypedFormControl(0, [Validators.required, Validators.min(1)]),
    number_of_labour_only_subcontractors: new UntypedFormControl(0, [Validators.required]),
    number_of_effective_employees: new UntypedFormControl(0, [Validators.required]),
    number_of_bona_fide_subcontractors: new UntypedFormControl(0, [Validators.required]),

    parent_account_number: new UntypedFormControl(''),
    parent_account: new UntypedFormControl(''),
    parent_account_id: new UntypedFormControl(''),

    primaryContactControl: new UntypedFormControl('', [Validators.required]),
    position: new UntypedFormControl('', [Validators.required]),
    telephone: new UntypedFormControl(''),
    website_url: new UntypedFormControl(''),

    distance: new UntypedFormControl(0, [Validators.min(1)]),
    workRegions: new UntypedFormControl([], [Validators.required, Validators.minLength(1)]),
  });
  selectedStates: WorkRegion[] = [];
  applicationTypes: ApplicationTypeDetails[];
  currentUser: CheckNewAuthenticationResponse;
  companyDetails: CompanyDetails;
  bundles: LatestBundleResponse[];
  isLoading: boolean;
  isSaving: boolean;
  error: string;
  success: boolean;
  isSearchingForImmediateParent: boolean;
  isSearchingForUltimateParent: boolean;
  editingRegisteredAddress: boolean;
  editingTradingAddress: boolean;
  parentFieldsSet: boolean;
  contacts: AccountContact[];
  primaryContact: AccountContact;
  defaultCertificateOption: string = '885570000';

  whereHeardOptionsError: string;
  whereHearInputEnabled = false;

  private $destroyed = new Subject<void>();
  public applicationType: string;
  public hearChas: string | null = null;

  // Modal/dialogue references.
  branchModalRef: BsModalRef;
  categoriesModalRef: BsModalRef;
  areasModalRef: BsModalRef;

  countries: ISOCountry[];

  processedAssessmentsList: boolean = false;
  anyAssessmentsSubmitted: boolean = null;
  public healthAndSafetySubmitted = false;

  private readonly data$ = this.store.select(selectCurrentUserState);
  private currentUserState: CurrentUserState;
  private environment: IEnvironment;
  workAreaHeadingText: string = 'Work Areas';

  showPrimaryWorkCategoryError: boolean = false;
  public isAus = false;
  public apples = [
    { id: 'red', label: 'red' },
    { id: 'green', label: 'green' },
  ];

  constructor(
    private readonly accountService: AccountsService,
    private readonly assessmentService: AssessmentsService,
    private readonly Contacts: ContactsService,
    private readonly ModalService: BsModalService,
    private readonly el: ElementRef,
    private readonly countryService: CountryService,
    private readonly store: Store
  ) {}

  async ngOnInit() {
    const env = new Environment();
    this.environment = await env.getConfig();

    this.isAus = this.environment.territory === Territory.AUSTRALIA;

    await this.loadCurrentUserState();
    this.healthAndSafetySubmitted = await this.checkHasHealthAndSafetySubmitted();

    if (this.isAus) {
      this.workAreaHeadingText = 'States';
      this.options.whereHeard = jsonWhereHeardAu;
    } else {
      this.options.whereHeard = jsonWhereHeard;
    }

    this.countries = this.countryService.getAllCountries();
    this.bundles = null;
    this.error = null;

    this.populateCompanyDetails();
    this.applicationTypes = applicationTypesJSON;
    this.getApplicationType();
    this.updateNumberOfEmployeesOnChange();
    this.updatePrimaryContactOnChange();
    this.updateWhereHeardOnChange();
  }

  ngOnDestroy() {
    this.$destroyed.next();
  }

  ngAfterViewChecked(): void {
    if (!this.parentFieldsSet && this.fieldImmediateParent && this.fieldUltimateParent) {
      this.setParentCompanyFields();
    }
  }

  async loadCurrentUserState() {
    this.data$.subscribe({
      next: (data) => {
        if (data.loaded) {
          this.currentUserState = data;
          this.currentUser = { ...data.currentAccount };
          this.companyDetails = { ...data.companyDetails };
          this.contacts = { ...data.colleagues };
        }
      },
    });
  }

  /**
   * Obtain the necessary information to populate the form.
   */
  populateCompanyDetails(): void {
    this.enableDetailsForm(!this.readOnly);
    if (this.currentUser) {
      this.isLoading = true;
      this.error = null;

      this.companyDetails = this.currentUserState.companyDetails;
      this.contacts = [...this.currentUserState.colleagues].sort((a: any, b: any) => a.contactname.localeCompare(b.contactname));
      this.getHearChas();

      this.detailsForm.patchValue({
        primaryContactControl: [...this.contacts].find((c) => c.isPrimaryContact === true)?.contactId,
      });

      this.primaryContact = {
        ...this.contacts.find((c) => c.isPrimaryContact === true),
      };

      this.bundles = this.currentUserState.bundles;

      // If the form has been updated, we want to merge the updated account details -
      // otherwise we want to replace what's in the form.
      // This is for the benefit of updating work categories and areas, which refresh account details.
      if (this.detailsForm.dirty) {
        const details = new CompanyDetailsForm(this.companyDetails);

        const keys = Object.keys(details);
        const patch = {};

        keys.forEach((key: string) => {
          const control = this.detailsForm.get(key);
          if (control && !control.dirty) {
            patch[key] = details[key];
          }
        });
        this.detailsForm.patchValue(patch);
      } else {
        let toPatched = new CompanyDetailsForm(this.companyDetails);
        this.detailsForm.patchValue(toPatched);
      }
      this.detailsForm.controls.workRegions.patchValue(this.currentUserState.workRegions);
      this.detailsForm.controls.distance.patchValue(this.companyDetails.distance);
      this.selectedStates = this.currentUserState.states;
      if (!this.detailsForm.get('position').dirty) {
        this.detailsForm.patchValue({
          position: this.primaryContact?.position,
        });
      }

      this.recalculateTotalEmployees();
      this.isLoading = false;
    }
  }

  /**
   *
   */
  setParentCompanyFields(): void {
    this.fieldImmediateParent.setMatchingCompany(
      this.detailsForm.value.parent_account_id,
      this.detailsForm.value.parent_account,
      this.detailsForm.value.parent_account_number
    );
    this.fieldUltimateParent.setMatchingCompany(
      this.detailsForm.value.ultimate_parent_account_id,
      this.detailsForm.value.ultimate_parent_account,
      this.detailsForm.value.ultimate_parent_account_number
    );
    this.parentFieldsSet = true;
  }

  // ---------------------------------------------------------------------
  // UPDATE DETAILS
  // ---------------------------------------------------------------------

  /**
   * Save or update company details.
   */
  async updateCompanyDetails(): Promise<void> {
    if (!this.currentUser || this.isSaving) {
      return;
    }

    const contact = { ...this.primaryContact };
    contact.accountId = this.currentUserState.currentAccount.accountId;
    contact.position = this.detailsForm.value.position;
    contact.telephone = this.detailsForm.value.telephone;
    const updateParams = this.Contacts.convertToUpdateParams(contact);

    if (!this.detailsForm.valid) {
      if (
        !this.detailsForm.controls.correspondence_address_street1.valid ||
        !this.detailsForm.controls.correspondence_address_postcode.valid ||
        !this.detailsForm.controls.correspondence_address_city.valid ||
        !this.detailsForm.controls.correspondence_address_country_code.valid ||
        this.detailsForm.controls.workCategories.valid ||
        this.detailsForm.controls.workRegions.valid ||
        this.detailsForm.controls.distance.valid
      ) {
        this.editTradingAddress();
        setTimeout(() => {
          this.scrollIfFormHasErrors(this.detailsForm);
        }, 500);
        return;
      }
      this.scrollIfFormHasErrors(this.detailsForm);
      return;
    }

    this.showPrimaryWorkCategoryError = false;

    this.setIsSaving(true);
    this.success = false;
    this.error = null;

    const { position, ...params } = this.detailsForm.value;

    const companyDetails = this.accountService.toCompanyDetails(
      this.currentUserState.currentAccount.accountId,
      this.companyDetails.id?.name,
      params,
      this.countryService
    );

    companyDetails.primaryContactPosition = contact.position;
    companyDetails.telephone = contact.telephone;

    companyDetails.hearChasText = this.getHearChasText(companyDetails.hearChas);

    this.accountService
      .updateAccount(this.currentUser.accountId, companyDetails)
      .pipe(
        first(),
        finalize(() => {
          this.setIsSaving(false);
        })
      )
      .subscribe({
        next: () => {
          this.success = true;

          // TODO reload the current user's details from somewhere.
          this.currentUser.position = this.detailsForm.value.position;

          this.accountService.refresh();
        },
        error: (response: HttpErrorResponse) => {
          if (response.error != null && response.error.length == 0) {
            this.error = response.error.Message;
          } else {
            this.error = 'Something went wrong. Please try again.';
          }
        },
      });
  }

  get primaryContactName(): string {
    if (this.primaryContact) return this.primaryContact.contactname;

    return null;
  }
  primaryTradeTrackBy(index, row) {
    return row.id;
  }

  setIsSaving(isSaving: boolean): void {
    this.isSaving = isSaving;
    this.enableDetailsForm(!isSaving);
    this.fieldImmediateParent.setDisabledState(isSaving);
    this.fieldUltimateParent.setDisabledState(isSaving);
  }

  getHearChasText(hearChasId: string): string {
    const jsonData = this.isAus ? jsonWhereHeardAu : jsonWhereHeard;
    const foundItem = jsonData.find((item) => item.id === hearChasId);
    return foundItem ? foundItem.label : '';
  }

  async updatePrimaryContact(): Promise<boolean> {
    const contact = { ...this.primaryContact };
    contact.accountId = this.currentUser.accountId;
    contact.position = this.detailsForm.value.position;
    contact.telephone = this.detailsForm.value.telephone;
    const updateParams = this.Contacts.convertToUpdateParams(contact);
    let success = false;

    try {
      await this.Contacts.updateContactAsync(updateParams);
      success = true;
    } catch {
      success = false;
    }

    return success;
  }

  /**
   * Used to set form to read only if role is set to No Access.
   */
  get readOnly(): boolean {
    const companyDetailsRole = this.currentUserState.roles.find((b) => {
      return b.name?.toLowerCase() === 'company details';
    });
    const isViewOnly = companyDetailsRole ? companyDetailsRole.privilege === 'View Only' : true;

    return isViewOnly;
  }

  /**
   * Used to set work categories to read only for an assessment after the contractor has submitted
   */
  private async checkHasHealthAndSafetySubmitted(): Promise<boolean> {
    let isHealthAndSafetySubmitted = false;

    const assessmentsList = await firstValueFrom(this.assessmentService.get(this.currentUser.accountId));

    const inProgressHealthAndSafetyAssessment = assessmentsList.filter((assessment) => {
      return assessment.product?.name === 'Health & Safety' && assessment.status?.label !== 'In Progress';
    });

    isHealthAndSafetySubmitted = inProgressHealthAndSafetyAssessment.length !== 0;

    return isHealthAndSafetySubmitted;
  }

  /**
   * Used to set work categories to read only for an assessment after the contractor has submitted
   */
  get hasAnyAssessmentsSubmitted() {
    if (this.anyAssessmentsSubmitted != null) {
      return this.anyAssessmentsSubmitted;
    }

    this.anyAssessmentsSubmitted = false;

    this.assessmentService.get(this.currentUser.accountId).subscribe({
      next: (data: Assessment[]) => {
        let assessmentsList = data;
        assessmentsList.forEach((x) => {
          if (x.status?.label != 'In Progress') {
            this.anyAssessmentsSubmitted = true;
          }
        });
      },
      error: (response: HttpErrorResponse) => {
        this.error = response.error.Message;
      },
    });

    return this.anyAssessmentsSubmitted;
  }

  get printPack() {
    if (!this.companyDetails.printPack) {
      return certificateOptions.find((o) => o.code === this.defaultCertificateOption).description;
    } else {
      return certificateOptions.find((o) => o.code === this.companyDetails.printPack).description;
    }
  }

  private getApplicationType(): string {
    if (!this.bundles.length) return null;

    const app = this.applicationTypes.find(
      (x) => x.code === this.bundles[0].variant.toString() && x.customerType === this.currentUser.membershipTypeId.toString()
    );

    return app ? app.name : null;
  }

  /**
   * Returns the string value for orgaisation type, if available.
   */
  get organisationType() {
    return this.companyDetails && this.companyDetails.organisationType
      ? this.options.organisationTypes.find((o) => o.id === this.companyDetails.organisationType).label
      : null;
  }

  private getHearChas(): string {
    return this.companyDetails && this.companyDetails.hearChas
      ? this.options.whereHeard.find((w) => w.id === this.companyDetails.hearChas).label
      : null;
  }

  /**
   * Returns the account's membership ID, if available.
   */
  get membershipId(): string {
    return this.companyDetails && this.companyDetails.id ? this.companyDetails.id.membershipId : null;
  }

  /**
   * Returns the account's company name, if available.
   */
  get companyName(): string {
    return this.companyDetails && this.companyDetails.id ? this.companyDetails.id.name : null;
  }

  /**
   * Returns TRUE if the account has a CAS bundle.
   */
  get hasCASBundle(): boolean {
    if (this.bundles) {
      const matchingBundles = this.bundles.filter((row) => -1 !== row.bundleName.indexOf('CAS'));
      return matchingBundles.length > 0;
    }
    return false;
  }

  /**
   * Returns text representing the company size for the account, if available.
   */
  get businessSize(): string {
    return this.companyDetails.size;
    // return this.companyDetails
    //   ? this.accountService.getBusinessSizeLabel(
    //     this.companyDetails,
    //     this.hasCASBundle
    //   )
    //   : null;
  }

  /**
   * Returns the account's registered address as a line of text, if available.
   */
  get registeredAddress(): string {
    if (!this.companyDetails || !this.companyDetails.registerAddress) {
      return null;
    }
    const parts = [
      this.companyDetails.registerAddress.street1,
      this.companyDetails.registerAddress.street2,
      this.companyDetails.registerAddress.city,
      this.companyDetails.registerAddress.postCode,
      this.companyDetails.registerAddress.country,
    ];

    return parts.filter((part) => null !== part).join(', ');
  }

  /**
   * Returns the account's trading/correspondence address as a line of text, if available.
   */
  get tradingAddress(): string {
    if (!this.companyDetails || !this.companyDetails.correspondenceAddress) {
      return null;
    }
    const parts = [
      this.companyDetails.correspondenceAddress.street1,
      this.companyDetails.correspondenceAddress.street2,
      this.companyDetails.correspondenceAddress.city,
      this.companyDetails.correspondenceAddress.postCode,
      this.companyDetails.correspondenceAddress.country,
    ];

    return parts.filter((part) => null !== part).join(', ');
  }

  get primaryWorkCategoryName() {
    if (!this.companyDetails || !this.companyDetails.primaryWorkCategory || !this.readOnly) {
      return null;
    }
    return this.companyDetails.workCategories.find((wc) => wc.id === this.detailsForm.value.primary_trade).fullName;
  }

  updateWhereHeardOnChange(): void {
    this.detailsForm.controls.hear_chas.valueChanges.subscribe((selectedValue) => {
      const option = jsonWhereHeard.find((i) => i.id === selectedValue);
      if (option && option.useTextInput) {
        this.detailsForm.controls.hear_chas_text_option.setValidators(Validators.required);
        this.whereHearInputEnabled = true;
        this.updateWhereHeardError(option);
      } else {
        this.detailsForm.controls.hear_chas_text_option.setValidators(null);
        this.whereHearInputEnabled = false;
        this.detailsForm.controls.hear_chas_text_option.setValue('');
      }
    });
  }

  updateWhereHeardError(whereHeardOption: any) {
    if (whereHeardOption.label === 'Other') {
      this.whereHeardOptionsError = '*Please tell us where you heard about CHAS (Other)';
    }
    if (whereHeardOption.label === 'Client - With Code') {
      this.whereHeardOptionsError = '*Please enter client code';
    }

    if (whereHeardOption.label === 'Client - No Code') {
      this.whereHeardOptionsError = '*Please enter client name';
    }
  }
  // ---------------------------------------------------------------------
  // NUMBER OF EMPLOYEES
  // ---------------------------------------------------------------------

  updateNumberOfEmployeesOnChange() {
    this.detailsForm.controls.number_of_direct_employees.valueChanges.subscribe(() => this.recalculateTotalEmployees());

    this.detailsForm.controls.number_of_labour_only_subcontractors.valueChanges.subscribe(() => this.recalculateTotalEmployees());

    this.recalculateTotalEmployees();
  }

  recalculateTotalEmployees() {
    let newTotal =
      this.detailsForm.controls.number_of_direct_employees.value + this.detailsForm.controls.number_of_labour_only_subcontractors.value;

    this.detailsForm.controls.number_of_effective_employees.setValue(newTotal);
  }

  // ---------------------------------------------------------------------
  // PARENT COMPANIES
  // ---------------------------------------------------------------------

  changedImmediateParent(details: CompanyLookup): void {
    this.detailsForm.patchValue({
      parent_account_id: details.id,
      parent_account: details.name,
      parent_account_number: details.companyNumber,
    });
  }

  changedUltimateParent(details: CompanyLookup): void {
    this.detailsForm.patchValue({
      ultimate_parent_account_id: details.id,
      ultimate_parent_account: details.name,
      ultimate_parent_account_number: details.companyNumber,
    });
  }

  removeImmediateParent() {
    this.detailsForm.patchValue({
      parent_account_id: null,
      parent_account: null,
      parent_account_number: null,
    });
  }

  removeUltimateParent() {
    this.detailsForm.patchValue({
      ultimate_parent_account_id: null,
      ultimate_parent_account: null,
      ultimate_parent_account_number: null,
    });
  }

  // ---------------------------------------------------------------------
  // ADDRESSES
  // ---------------------------------------------------------------------

  /**
   * Begin to edit the registered address.
   */
  editRegisteredAddress(): void {
    this.editingRegisteredAddress = true;
  }

  /**
   * Begin to edit the trading address.
   */
  editTradingAddress(): void {
    this.editingTradingAddress = true;
  }

  /**
   * Show a dialogue for viewing and updating branch addresses.
   */
  showBranchAddresses(): void {
    this.branchModalRef = this.ModalService.show(BranchAddressesModalComponent, {
      animated: false,
      backdrop: 'static',
      class: 'modal-xl',
      initialState: { accountId: this.currentUser.accountId },
    });
  }

  // ---------------------------------------------------------------------
  // Primary Contact
  // ---------------------------------------------------------------------

  updatePrimaryContactOnChange() {
    this.detailsForm.controls.primaryContactControl.valueChanges.subscribe((value) => {
      if (value && this.contacts) {
        this.primaryContact = this.contacts.find((c) => c.contactId === value);
        this.detailsForm.patchValue({
          primary_contact_id: value,
        });

        if (!this.detailsForm.get('position').dirty) {
          this.detailsForm.patchValue({
            position: this.primaryContact.position,
          });
        }
      }
    });
  }

  // ---------------------------------------------------------------------
  // WORK CATEGORIES/AREAS
  // ---------------------------------------------------------------------

  /**
   * Show a dialogue for viewing and updating work categories.
   */
  async showWorkCategories(): Promise<void> {
    this.ModalService.show(WorkAreaSelecterComponent, {
      animated: false,
      backdrop: 'static',
      class: 'modal-xl',
      initialState: {
        //@ts-ignore
        data: this.detailsForm.controls.workCategories.value,
        onChange: (selectedWorkCategories) => {
          this.detailsForm.controls.workCategories.patchValue(selectedWorkCategories);
          this.detailsForm.controls.primary_trade.patchValue(
            selectedWorkCategories.filter((x) => x.id === this.detailsForm.controls.primary_trade.value)[0]?.id
          );
        },
      },
    });
  }

  /**
   * Show a dialogue for viewing and updating work areas.
   */
  async showWorkAreas(): Promise<void> {
    this.ModalService.show(WorkRegionSelecterComponent, {
      animated: false,
      backdrop: 'static',
      class: 'modal-xl',
      initialState: {
        selectedWorkRegions: this.detailsForm.controls.workRegions.value,
        distance: this.detailsForm.controls.distance.value,
        onChange: (selectedWorkRegions, selectedStates, distance) => {
          this.detailsForm.controls.workRegions.patchValue(selectedWorkRegions);
          this.selectedStates = selectedStates;
          this.detailsForm.controls.distance.patchValue(distance);
        },
      },
    });
  }

  // ---------------------------------------------------------------------
  // CONTACTS
  // ---------------------------------------------------------------------

  /**
   * Show a dialogue for managing contacts.
   */
  showManageContacts(): void {
    this.areasModalRef = this.ModalService.show(ManageContactsModalComponent, {
      animated: false,
      backdrop: 'static',
      class: 'modal-xl',
      initialState: { accountId: this.currentUser.accountId },
    });
  }

  findInvalidControls() {
    const invalid = [];
    const controls = this.detailsForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  ////Scroll to invalid controls
  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = this.el.nativeElement.querySelector('form .ng-invalid');
    this.scrollTo(firstElementWithError);
  }

  async scrollIfFormHasErrors(form: UntypedFormGroup): Promise<any> {
    await form.invalid;
    this.scrollToError();
  }

  enableDetailsForm(enabled: boolean): void {
    if (enabled) {
      this.detailsForm.enable();
      this.detailsForm.markAllAsTouched();
    } else {
      this.detailsForm.disable();
    }

    if (this.healthAndSafetySubmitted) this.detailsForm.controls.primary_trade.disable();
    this.detailsForm.controls.number_of_effective_employees.disable();
    this.detailsForm.controls.number_of_direct_employees.disable();
    this.detailsForm.controls.number_of_labour_only_subcontractors.disable();
    this.detailsForm.controls.number_of_bona_fide_subcontractors.disable();
  }
}
