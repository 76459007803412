<div class="application-types mb-3" *ngIf="applicationTypes; else noTypes">
  <button type="button"
    class="application-types__button"
    *ngFor="let aType of applicationTypes; trackBy: trackByFn"
    [ngClass]="{
      'selected': isSelected(aType)
    }"
    (click)="selectType(aType)"
    [disabled]="disabled">
    <img
      class="application-types__image"
      [src]="aType.image"
      [attr.alt]="aType.name"
      [attr.title]="aType.name"
    />
  </button>
</div>

<div class="application-types__info mb-3" *ngIf="selectedApplicationType">
  <h4 class="h5 application-types__info__name">{{ selectedApplicationType.name }}</h4>
  <p class="application-types__info__description">{{ selectedApplicationType.description }}</p>
</div>


<ng-template #noTypes>
  <app-nothing>No application types available!</app-nothing>
</ng-template>
