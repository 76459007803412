export const Tier3Products = [
    {
      title: "Checkatrade – Where reputation matters",
      type: "Checkatrade",
      content: JSON.stringify(["All CHAS Members receive 2 months free membership with Checkatrade.com, where they can advertise their business to 2 million unique users a month."
      ]),
      cta: "Get 2 months free membership",
      ctaUrl: "https://join.checkatrade.com/CHAS"
    },
    {
      title: "TradePoint - 10% off at B&Q ",
      type: "tradepoint",
      content: JSON.stringify(["Not only do CHAS members benefit from everyday low prices in B&Q, members now get 10% off almost everything. Remember that is off almost EVERYTHING in B&Q, in-store or online including promotional lines giving you access to discounts on over 40,000 discounted products across the full range."]),
      cta: "Download the application form",
      ctaUrl: "/assets/static/CHAS-card-request-form.docx",
      cta2: "Find out more",
      ctaUrl2: "https://www.trade-point.co.uk"
    }, 
    {
      title: "Marsh Commercial – Great-value insurance cover ",
      type: "Insurance",
      content: JSON.stringify(["CHAS-accredited members can use Marsh Commercial to get great-value insurance cover from a range of leading UK insurers."]),
      cta: "Get a free quote",
      ctaUrl: "http://www.marshcommercial.co.uk/chas"
    },      
    {
      title: "The Fuelcard People",
      type: "Fuel Card",
      content: JSON.stringify(["CHAS members get access to discount fuel cards that are valid at BP, Esso, Shell and Texaco sites as well as motorway services, supermarkets, independent retailers and specialist diesel networks."]),
      cta: "Get a discounted fuel card",
      ctaUrl: "https://www.thefuelcardpeople.co.uk/chas/"
    },
    {
      title: "Risk assessments & method statements",
      type: "eRAMS",
      content: JSON.stringify([
        "The eRAMS service allow​s you to create, amend, store and print activity-based risk assessments (RAs), method statements (MSs), and construction phase plans (CPPs)."
      ]),
      cta: "Sign-Up for eRAMS",
      ctaUrl: "https://www.chas.co.uk/lp-erams/"
    },      
    {
      title: "Lightfoot - Rewarding Better Drivers",
      type: "Fleet Management",
      content: JSON.stringify(["Lightfoot helps drivers use less fuel, reduce harmful emissions and stay safe every time they drive."
      ]),
      cta: "Find out more",
      ctaUrl: "https://www.lightfoot.co.uk/risk/chas"
    },
    {
      title: "Powered Now - Invoicing, Quoting & Job Management App",
      type: "Powered Now",
      content: JSON.stringify([
        "Powered Now is an app built for the trade which enables you to quickly generate and send quotes and invoices – critical to improving cash flow."
      ]),
      cta: "Find out more",
      ctaUrl: "https://www.powerednow.com/chas"
    },
    {
      title: "Lighthouse - The Construction Industry Charity",
      type: "lighthouse",
      content: JSON.stringify([
        "Lighthouse is dedicated to the physical, mental and financial wellbeing of construction workers and their families in the UK and Ireland.",
        "Our mission is that no construction worker or their family should feel alone in a crisis"
      ]),
      cta: "Find out more",
      ctaUrl: "/assets/static/Lighthouse-Construction-Industry-Charity.pdf",
      cta2: "Construction Industry Helpline",
      ctaUrl2: "tel:+443456051956"
    }
  ];
