import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CarouselCard, CarouselConfig } from 'src/app/shared';
import DashboardCarouselHelpers from './dashboard-carousel-helpers';
import { FeatureFlag } from '../../../core/services/feature-flag';

@Component({
  selector: 'app-dashboard-carousel',
  templateUrl: './dashboard-carousel.component.html',
  styleUrls: ['./dashboard-carousel.component.scss'],
})
export class DashboardCarouselComponent implements OnInit, OnDestroy {
  ready: boolean = false;
  config: CarouselConfig;
  slideIndex: number = 0;
  timer: any;
  userHiddenIds: Array<string> = [];
  hiddenIdCookieName: string = 'hidden_carousel_items';
  private featureFlagsArray: FeatureFlag[];

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.setupCarouselAsync();
  }

  ngOnDestroy(): void {
    this.stopTimer();
  }

  async setupCarouselAsync(): Promise<void> {
    this.loadCookies();

    try {
      this.config = await this.loadConfigAsync();
    } catch {
      console.error('Could not load dashboard carousel');
      return;
    }

    if (!this.config.enabled) {
      return;
    }

    if (this.config.randomise) {
      DashboardCarouselHelpers.shuffleArray(this.config.cards);
    }

    if (this.config.firstShowDelaySeconds === 0) {
      this.startTimer();
      this.ready = true;
    } else {
      setTimeout(() => {
        this.startTimer();
        this.ready = true;
      }, this.config.firstShowDelaySeconds * 1000);
    }
  }

  startTimer(): void {
    const intervalMilliseconds = this.config.secondsPerCard * 1000;
    this.timer = setInterval(() => this.nextCard(), intervalMilliseconds);
  }

  stopTimer(): void {
    clearInterval(this.timer);
    this.timer = null;
  }

  restartTimer(): void {
    this.stopTimer();
    this.startTimer();
  }

  async loadConfigAsync(): Promise<CarouselConfig> {
    return this.http.get<CarouselConfig>('/assets/json/dashboard-carousel-config.json').toPromise();
  }

  getSafeHtml(htmlStr: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlStr);
  }

  nextCard(): void {
    this.slideIndex++;
    this.recalculateSlideIndex();
  }

  recalculateSlideIndex(): void {
    if (this.slideIndex < 0 || this.slideIndex >= this.cards.length) {
      this.slideIndex = 0;
    }
  }

  isSlide(index: number): boolean {
    return index === this.slideIndex;
  }

  close(cardId: string): void {
    this.userHiddenIds.push(cardId);
    this.saveCookies();

    if (!this.config.onCloseChangeSlide) {
      this.slideIndex = -1;
    } else {
      this.recalculateSlideIndex();
    }
  }

  loadCookies() {
    const userHiddenIdCookieValue = DashboardCarouselHelpers.getCookie(this.hiddenIdCookieName);

    if (userHiddenIdCookieValue !== null) {
      this.userHiddenIds = JSON.parse(userHiddenIdCookieValue);
    }
  }

  saveCookies() {
    const userHiddenIds = JSON.stringify(this.userHiddenIds);
    DashboardCarouselHelpers.setCookie(this.hiddenIdCookieName, userHiddenIds, this.config.cookieExpireDays);
  }

  get cards(): Array<CarouselCard> {
    if (this.config === null || this.config.cards === null) {
      return [];
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const notUserHidden = this.config.cards.filter((x) => !this.userHiddenIds.includes(x.id));
    const notUserHiddenAndOnDashboard = notUserHidden.filter((x) => !x.onlyIfProductCardShown || document.querySelector('#' + x.id));
    const notUserHiddenAndOnDashboardAndInDate = notUserHiddenAndOnDashboard.filter(
      (x) => new Date(x.validFrom) <= today && new Date(x.validTo) >= today
    );

    return this.featureEnabled(notUserHiddenAndOnDashboardAndInDate);
  }

  public featureEnabled(card: CarouselCard[]): CarouselCard[] {
    try {
      return card.filter((CarouselCard: CarouselCard) => {
        return this.featureFlagsArray.some((flag: FeatureFlag): boolean => flag.name === CarouselCard.featureFlagId);
      });
    } catch (error) {
      console.error('Error processing feature flags:', error);
    }
  }

  callToActionIsAnchor(url: string) {
    return url.startsWith('#');
  }

  navigateToAnchor(card: CarouselCard): void {
    const cardElement = document.querySelector(card.callToActionUrl);

    const cardElementButtons = Array.prototype.slice.call(cardElement.querySelectorAll('.btn')) as Array<HTMLButtonElement>;
    const matchedButton = cardElementButtons.find((x) => x.innerText === card.callToActionText);
    const matchedButtonIsHidden = matchedButton?.offsetParent === null;

    if (matchedButton && !matchedButtonIsHidden) {
      cardElement.scrollIntoView();
      matchedButton.click();
    } else if (cardElement) {
      cardElement.scrollIntoView();
    } else {
      alert('Could not navigate');
    }
  }

  goToIndex(index: number): void {
    this.slideIndex = index;
    this.restartTimer();
  }
}
