import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-questionset-helper',
  templateUrl: './questionset-helper.component.html',
  styleUrls: ['./questionset-helper.component.scss']
})
export class QuestionsetHelperComponent {

  @Input() helpText: string;

  constructor() { }
}
