import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();
import { CourseGroup, CourseProgress, Course } from '../../shared/interfaces/course';
import coursesJSON from 'src/assets/json/courses.json';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  courseGroups: CourseGroup[] = coursesJSON;

  constructor(private http: HttpClient) {
  }

  getCourseProgress(accountId: string): Observable<CourseProgress[]> {
    const url = `${environment.apiEndpoint.course}/accounts/${accountId}/progress`;
    return this.http.get<CourseProgress[]>(url);
  }

  updateCourseProgress(courseProgress: CourseProgress): Observable<CourseProgress> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${environment.apiEndpoint.course}/progress`;
    return this.http.put<CourseProgress>(url, courseProgress, { headers });
  }

  getCourseGroup(courseGroupId: string): CourseGroup {
    return this.courseGroups.find(cg => cg.id === courseGroupId);
  }

  getCourse(courseGroupId: string, courseId: string): Course {
    return this.getCourseGroup(courseGroupId).courses.find(c => c.id === courseId);
  }
}