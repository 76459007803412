<div class="text-center" *ngIf="isLoading; else loaded">
  <app-loading-spinner></app-loading-spinner>
</div>

<ng-template #loaded>
  <h1 class="h2 mb-5">User Management</h1>

  <alert type="danger" *ngIf="error; else displayContacts">{{ error }}</alert>

  <ng-template #displayContacts>

    <div class="row justify-content-end mb-3">

      <form class="col-lg-5" [formGroup]="userSearchForm">
        <div class="input-group">
          <input type="search" id="userSearch" class="form-control" placeholder="User Search" aria-label="User Search"
                 aria-describedby="btnUserSearch" formControlName="term" (keyup)="searchForUsers()">
          <div class="input-group-append">
            <button class="btn" type="submit" id="btnUserSearch" (click)="searchForUsers()"
                    [disabled]="userSearchForm.pristine">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </form>

      <div class="col-lg-7 text-right" style="font-weight: bold;">
        <!-- Create new user button. -->
        <button class="btn btn-light mr-1"
                type="button"
                (click)="openCreateUserModal()"
                *ngIf="isAdmin">
        Add New User
        </button>

      </div>



    </div>

    <!-- laupitODO should this be a component? -->
    <div class="table-responsive-md">
      <table class="table" *ngIf="contacts">
        <colgroup>
          <col style="width: 35%;">
          <col style="width: 25%;">
          <col style="width: 15%;">
          <col style="width: 15%;">
          <col style="width: 10%;">
        </colgroup>
        <thead class="thead">
          <tr>
            <th (click)="sortBy('Email')">
              Email Address
              <i class="fa fa-sort-{{sortIcon}} {{ sortIcon === 'down' ? 'align-text-top' : 'align-middle'}}" *ngIf="column === 'Email'"></i>
            </th>
            <th (click)="sortBy('Contactname')">
              Portal User Name
              <i class="fa fa-sort-{{sortIcon}} {{ sortIcon === 'down' ? 'align-text-top' : 'align-middle'}}" *ngIf="column === 'Contactname'"></i>
            </th>

            <th (click)="sortBy('Position')">
              Position
              <i class="fa fa-sort-{{sortIcon}} {{ sortIcon === 'down' ? 'align-text-top' : 'align-middle'}}" *ngIf="column === 'Position'"></i>
            </th>

            <th>
              Mobile Phone
            </th>

            <th (click)="sortBy('UserType')">
              Access Rights
              <i class="fa fa-sort-{{sortIcon}} {{ sortIcon === 'down' ? 'align-text-top' : 'align-middle'}}" *ngIf="column === 'UserType'"></i>
            </th>
            <th (click)="sortBy('AccountStatus')" class="text-center">
              Status
              <i class="fa fa-sort-{{sortIcon}} {{ sortIcon === 'down' ? 'align-text-top' : 'align-middle'}}" *ngIf="column === 'AccountStatus'"></i>
            </th>
            <th class="text-center" *ngIf="isAdmin">Control</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of pageOfResults;">

            <td>
              <a [href]="'mailto:' + row.email">{{ row.email }}</a>
            </td>

            <td>{{ row.contactname }}</td>

            <td>{{ row.position }}</td>

            <td>{{ row.mobilephone }}</td>

            <td>{{ row.userType }}</td>

            <td class="text-center">{{ row.accountStatus }}</td>

            <td class="flex" *ngIf="isAdmin">

              <button class="btn btn-sm" type="button" *ngIf="shouldShowDeleteButton(row)"
                      [class.is-loading]="isBeingLocked(row)" (click)="handleContactLock(row)">
                <i class="fa fa-fw fa-lg" [class.fa-user]="!isContactLocked(row)" [class.fa-user-alt-slash]="isContactLocked(row)" [tooltip]="isContactLocked(row) ? 'Unlock account' : 'Lock account'"></i>
              </button>

              <button class="btn btn-sm" type="button" *ngIf="shouldShowEditButton(row)"
                      (click)="openUserInformationModal(row, currentUserState)">
                <i class="fa fa-fw fa-pen fa-lg" tooltip="Edit account"></i>
              </button>

              <button class="btn btn-sm" type="button" *ngIf="shouldShowDeleteButton(row)"
                      [class.is-loading]="isBeingDeleted(row)" (click)="confirmDelete(row)">
                <i class="fas fa-times-circle fa-lg" tooltip="Deactivate account"></i>
              </button>

            </td>
          </tr>
        </tbody>
      </table>

      <pagination *ngIf="hasEnoughRowsForPagination"
                  [itemsPerPage]="rowsPerPage"
                  [totalItems]="contacts.length"
                  (pageChanged)="pageChanged($event)"></pagination>
    </div>
  </ng-template>
</ng-template>

<app-security-settings-modal #settingsModal></app-security-settings-modal>

<!-- Dialogue to confirm locking a contact. -->
<ng-template #modalConfirmLock>
  <div class="modal-body text-center">
    <p>
      Are you sure you want to lock the account for <b *ngIf="contactToLock">{{ contactToLock.contactname }}</b>?
    </p>
  </div>
  <div class="modal-footer clearfix">
    <button type="button" class="btn btn-danger float-right" (click)="lockConfirmed()">
      Yes
    </button>
    <button type="button" class="btn btn-secondary float-left" (click)="closeModal()">
      No
    </button>
  </div>
</ng-template>

<!-- Dialogue to confirm unlocking a contact. -->
<ng-template #modalConfirmUnlock>
  <div class="modal-body text-center">
    <p>
      Are you sure you want to unlock the account for <b *ngIf="contactToLock">{{ contactToLock.contactname }}</b>?
    </p>
  </div>
  <div class="modal-footer clearfix">
    <button type="button" class="btn btn-danger float-right" (click)="unlockConfirmed()">
      Yes
    </button>
    <button type="button" class="btn btn-secondary float-left" (click)="closeModal()">
      No
    </button>
  </div>
</ng-template>

<!-- Dialogue to confirm deactivating a contact. -->
<ng-template #modalConfirmDelete>
  <div class="modal-body text-center">
    <p>
      Are you sure you want to deactivate the account for <b *ngIf="contactToDelete">{{ contactToDelete.contactname }}</b>?
    </p>
  </div>
  <div class="modal-footer clearfix">
    <button type="button" class="btn btn-danger float-right" (click)="deleteConfirmed()">
      Yes
    </button>
    <button type="button" class="btn btn-secondary float-left" (click)="closeModal()">
      No
    </button>
  </div>
</ng-template>
