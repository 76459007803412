<div class="dropdown" dropdown
     (click)="$event.stopPropagation()"
     data-intro="This notification bell will have a number next to it when there are items you need to look at."
     data-step="8"
     data-position="bottom-right-aligned">

  <!-- Dropdown button representing a menu. -->
  <button dropdownToggle id="dropdownNotificationButton" class="account-menu__button btn" [class.dropdown-toggle]="hasAlerts" aria-controls="dropdownNotificationMenu" type="button">

    <i class="fa fa-lg fa-fw" [class.fa-bell]="hasAlerts" [class.fa-bell-o]="!hasAlerts"></i>
    <span class="badge badge-pill badge-primary" *ngIf="hasAlerts">
      {{ filteredAlerts.length | number }}
    </span>
  </button>

  <!-- Dropdown menu. -->
  <div *dropdownMenu id="dropdownNotificationMenu" class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownNotificationButton">
    <!--news, updates-->
    <div *ngIf="this.isNotificationVisible">
      <div class="dropdown-item-text notification__item" *ngFor="let alert of filteredAlerts">
        <app-notification-display-container
        [notification]="alert"
        [user]="user">
        </app-notification-display-container>
      </div>
    </div>
    <div class="dropdown-item-text notification__item notification__item--nothing" *ngIf="!hasAlerts && !publicNotification">
      <em>No alerts.</em>
    </div>
  </div>

  <div
    *ngIf="alertsExpiringInFourWeeks"
    class="alert__notification"
    [hidden]="!showExpiringInFourWeeksAlert">
    <div class="notification__arrow">
      <span>&#8593;</span>
    </div>
    <div class="notification__box">
      <div class="notification__box-close">
        <a (click)="closeExpiringInFourWeeksAlert()">
          <em class="fas fa-times"></em>
        </a>
      </div>
      <p>
        Click here to action your alerts. Please update to avoid affecting your accreditation status
      </p>
    </div>
  </div>

</div>
