<section>
  <p>Activate the CHAS learning path for free and start your journey to ensuring your company is up to date with ever changing legal requirements and making the world of work safer.</p>

  <p>Our helpful guides and resources will teach you how to improve standards and get assessment ready.</p>

  <div class="btn_container">
    <button class="btn btn-primary cta on_card"  [routerLink]="['/learning-pathway']">Find out more</button>
  </div>

</section>
