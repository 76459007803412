<div class="header">
  <div class="logo">
    <app-chas-logo></app-chas-logo>
    <span>My CHAS</span>
  </div>
</div>


<div id="footer">
  <app-site-footer></app-site-footer>
</div>

<ng-template #plantModal class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name1">
    <div class="modal-header">
      <h5 class="modal-title">Welcome to CHAS</h5>
    </div>
    <div class="modal-body">
      <p *ngIf="purchased === 'register' || purchased === 'foundation'">You can now sign into your CHAS account</p>
      <p *ngIf="purchased === 'membership'">Now please wait while we redirect you to your CHAS account</p>

      <div class="center">
        <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
        <span *ngIf="!error">If you are not redirected after a few seconds <a (click)="redirect()">click here</a></span>
        <a (click)="redirect()"><alert *ngIf="error" type="danger">{{error}}</alert></a>
      </div>
    </div>
</ng-template>
