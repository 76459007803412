import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../../../app.state'

export const selectCompanyDetailsFeatureState = createFeatureSelector<State>("countdownTimer");

export const getCountdownState = createSelector(
  selectCompanyDetailsFeatureState,
  (state) => state
);

