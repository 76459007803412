import { Component, Input, OnChanges, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-response-countdown',
  templateUrl: './response-countdown.component.html',
  styleUrls: ['./response-countdown.component.scss']
})
export class ResponseCountdownComponent implements OnChanges, OnDestroy {

  @Input() time: string;

  showCountdown: boolean = false;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  timer: any;

  constructor() {
    this.timer = setInterval(() => { this.recalculateTimeLeft() }, 500);
  }

  ngOnChanges() {
    this.recalculateTimeLeft();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  }

  recalculateTimeLeft(): any {
    if(this.time == null) {
      this.showCountdown = false;
      return;
    }

    const endTime = new Date(this.time);
    const now = new Date();

    if(endTime < now) {
      this.showCountdown = false;
      return;
    }

    let  totalSeconds = Math.abs(endTime.getTime() - now.getTime()) / 1000;

    let days = Math.floor(totalSeconds / 86400);
    totalSeconds -= days * 86400;

    let hours = Math.floor(totalSeconds / 3600) % 24;
    totalSeconds -= hours * 3600;

    let minutes = Math.floor(totalSeconds / 60) % 60;
    totalSeconds -= minutes * 60;

    let seconds = Math.floor(totalSeconds % 60);

    this.days = days;
    this.hours = hours;
    this.minutes = minutes;
    this.seconds = seconds;

    this.showCountdown = true;
  }

}
