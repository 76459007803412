import { Component, ElementRef, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {
  constructor(private me: ElementRef) {}

  @Input() small: boolean;

  @HostBinding('class.small')
  get shouldBeSmall(): boolean {
    return !!(this.small);
  }

}
