import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkCategoriesModalComponent } from './work-categories-modal.component';
import { BootstrapComponentsModule } from '../bootstrap/bootstrap.module';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

@NgModule({
  declarations: [WorkCategoriesModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BootstrapComponentsModule,
    LoadingSpinnerModule,
    TypeaheadModule
  ],
  exports: [WorkCategoriesModalComponent]
})
export class WorkCategoriesModalModule {}
