import { NgModule } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CheckAuthenticationResponse } from '../../shared';

// Mock classes for testing.

export class MockRouter {
  navigate(path) {}
}

export class MockActivatedRouteSnapshot {
  private _data: any;
  get data() {
    return this._data;
  }
}

export class MockRouterStateSnapshot {
  url = '/';
}

export class MockAuthService {
  currentUser = new BehaviorSubject<CheckAuthenticationResponse>(null);
  isLoggedIn() {
    return this.currentUser ? true : false;
  }
}
