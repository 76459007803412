import { Injectable } from '@angular/core';
import * as countryData from 'i18n-iso-countries';
import { ISOCountry } from 'src/app/shared/interfaces/iso-country';
import langEn from 'i18n-iso-countries/langs/en.json';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private defaultLanguage: string = 'en';

  constructor() { 
    countryData.registerLocale(langEn);
  }

  getAllCountries(): Array<ISOCountry> {
    const countryDictionary = countryData.getNames(this.defaultLanguage, {select: "official"});
    
    var countries = Object.keys(countryDictionary)
      .map((key) => { return { code: key, longName: countryDictionary[key], shortName: countryDictionary[key]} as ISOCountry });
    
    countries.sort((a, b) => a.shortName.localeCompare(b.shortName));

    return countries;
  }

  getNameFromCode(countryCode: string): string {
    const name = countryData.getName(countryCode, this.defaultLanguage, {select: "official"});
    if (!name) return null;
    return name;
  }
}
