<div class="text-center" *ngIf="isLoading; else displayAssessments">
  <app-loading-spinner></app-loading-spinner>
</div>

<ng-template #displayAssessments>

  <alert type="danger" *ngIf="error">{{ error }}</alert>
  <div class="row">
    <div class="col-md-12 assessment-panel">
      <div class="row">
        <div class="col-md-6"><strong>Assessment</strong></div>
        <div class="col-md-2 bullet"><strong>In Progress</strong></div>
        <div class="col-md-2 bullet"><strong>Being Assessed</strong></div>
        <div class="col-md-2 bullet"><strong>Complete</strong></div>
      </div>
    </div>
  </div>

  <div class="row" *ngFor="let row of assessmentsList; trackBy: trackByFn">
    <!-- The assessment name. -->
    <div class="col-md-12 assessment-panel">
      <div class="row">
        <div class="col-md-4">
          <a (click)="selectAssessment(row.id, getStatusText(row))" class="pointer">
              <img 
                class="assessment-badge"
                src="/assets/img/accreditation/{{getAssessmentBadge(row)}}.png"
                alt="Assessment Badge">
              {{ row.product?.name }} <small>{{ row.assessmentType?.label }}</small> </a>
        </div>
        <div class="col-md-2">
          <span>{{ getStatusText(row) }}</span>
        </div>

        <div class="col-md-2 bullet" *ngFor="let row of getStatusIconArray(row)">
          <em class="fas {{row}}"></em>
        </div>
      </div>

    </div>
  </div>
</ng-template>
