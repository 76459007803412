<div class="modal-header">
  <h4 class="modal-title pull-left">Work Categories</h4>
</div>
<div class="modal-body">
  <div *ngIf="isLoaded">
    <div *ngIf="!isAus">
      <p>Please be aware, qualifications and Safe Systems of Work (SSoW) / RAMS will be required by the CHAS Assessor for each work category selected.
        If you do not have the information to support the selection, please remove them prior to saving.</p>
    </div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Search</mat-label>
        <input matInput placeholder="Search" class="flex-fill" (input)="filterChanged($event.target.value)">
      </mat-form-field>
    </div>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>        
        <mat-checkbox class="checklist-leaf-node"
                [color]="theme"
                [checked]="checklistSelection.isSelected(node)"
                (change)="todoLeafItemSelectionToggle(node)">   {{node.item.classTitle}}</mat-checkbox>
      
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <i matTreeNodeToggle class="fas pe-2 treearrow" [class]="getTreeIcon(node)"></i>        
        <mat-checkbox [checked]="descendantsAllSelected(node)"
                [color]="theme"
                [indeterminate]="descendantsPartiallySelected(node)"
                (change)="todoItemSelectionToggle(node)">{{node.item.classTitle}}</mat-checkbox>
      </mat-tree-node>
    </mat-tree>
  </div>
  <div class="text-center m-5" *ngIf="!isLoaded">
    <app-loading-spinner></app-loading-spinner>
  </div>
</div>

<div class="modal-footer">
  <div *ngIf="selectedLargeAmount">
    <b class="text-danger">Please be aware you have now selected a large number of work categories and 
      you will be required to provide qualifications and Safe Systems of Work / RAMS for each category. 
      If you do not have the information to support the selected work categories, please remove them.</b>
  </div>
  <button
    class="btn btn-primary"
    type="button"
    (click)="updateWorkCategories()"
    [class.is-loading]="saving"
  >
    Save
  </button>
</div>
