import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-register-employees',
  templateUrl: './register-employees.component.html',
  styleUrls: ['./register-employees.component.scss']
})
export class RegisterEmployeesComponent implements OnInit {
  @Input() public form: UntypedFormGroup;
  private effectiveEmployees: AbstractControl;
  private directEmployees: AbstractControl;
  private labourOnlySubContractors: AbstractControl;
  public readonly employeesInfo: string = `
  The number of employees is the total number of direct employees including, permanent, temporary, part-time and labour only subcontractors.
  UK Labour Law states a labour-only subcontractor is also considered an employee. 
  This does not include Bona Fide subcontractors. We recommend that you consider the total number of effective employees you may have/use at any one time during a 12-month rolling period.`;
  public readonly bonaFideInfo: string = `
  Bona Fide are subcontractors who work with their own tools, insurance etc.
  And are usually paid by invoice. We recommend that you consider the total number of effective employees you may have/use at any one time during a 12-month rolling period.`;
  public readonly labourInfo: string = `
  Labour only subcontractors contribute to the total number of direct employees as stated by UK Labour Law.
  We recommend that you consider the total number of effective employees you may have/use at any one time during a 12-month rolling period.
  `;
  public ngOnInit(): void {
    this.effectiveEmployees = this.form.get('employees');
    this.directEmployees = this.form.get('number_of_direct_employees');
    this.labourOnlySubContractors = this.form.get('number_of_labour_only_subcontractors');

    this.onEmployeesUpdate();

    this.addTwoFields(
      this.directEmployees.value,
      this.labourOnlySubContractors.value
    );
  }

  private onEmployeesUpdate(): void {
    this.directEmployees.valueChanges.subscribe((value: string) => this.addTwoFields(
      value,
      this.labourOnlySubContractors.value
    ));
    this.labourOnlySubContractors.valueChanges.subscribe((value: string) => this.addTwoFields(
      value,
      this.directEmployees.value
    ));
  }

  private addTwoFields(value: string, addition: string): void {
    let mainValue = 0;
    let additionValue = 0;

    if(value) mainValue = parseInt(value);
    if(addition) additionValue = parseInt(addition);

    const effectiveEmployees = mainValue + additionValue;
    this.effectiveEmployees.setValue(effectiveEmployees);
  }
}
