<form [formGroup]="rolesForm">
  <table class="table table-sm">
    <thead class="thead-dark">
      <tr>
        <th>Function</th>
        <th class="text-center">No Access</th>
        <th class="text-center">View Only</th>
        <th class="text-center">Full Access</th>
      </tr>
    </thead>
    <tbody formArrayName="roles">
      <tr *ngFor="let role of getControls(); let i = index" [formGroupName]="i">
        <td>{{ role.value.name }}</td>
        <td class="text-center">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" formControlName="privilege" [id]="'role-no-access-' + i" [value]="privileges.NO_ACCESS"/>
            <label class="custom-control-label" [attr.for]="'role-no-access-' + i">&nbsp;</label>
          </div>
        </td>
        <td class="text-center">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" formControlName="privilege" [id]="'role-view-only-' + i" [value]="privileges.VIEW_ONLY"/>
            <label class="custom-control-label" [attr.for]="'role-view-only-' + i">&nbsp;</label>
          </div>
        </td>
        <td class="text-center">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" formControlName="privilege" [id]="'role-full-access-' + i" [value]="privileges.FULL_ACCESS"/>
            <label class="custom-control-label" [attr.for]="'role-full-access-' + i">&nbsp;</label>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</form>
