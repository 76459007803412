import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  public newBackground: boolean = false;
  public isNewRegisterPage: boolean = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.isFir(this.router.url);

    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) return;
      this.scrollToTop();
    });
  }

  private isFir(url: string): boolean {
    const isVipPage = url.includes('/vip');
    this.newBackground = url.includes('/fir') || url.includes('/order-summary') || isVipPage;
    this.isNewRegisterPage = url.includes('/register') || isVipPage;
    return this.newBackground || this.isNewRegisterPage;
  }

  scrollToTop() {
    window.scrollTo(0, 0);
    var mainContentArea = document.getElementById('chas-main-content-area');
    mainContentArea.scrollTo(0, 0);
  }
}
