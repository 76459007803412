<div class="form-group row mt-5" [formGroup]="referenceForm">
  <div class="col-md-4">
      <label for="regReference" class="form-label">Enter Reference Code</label>
      <p style="font-size:12px">Optional: if you have been supplied a reference code by a Client or Trade Association please enter here</p>
  </div>
  <div class="col-md-5">
      <div class="input-group">
          <input type="text" id="regReference" class="form-control" formControlName="referenceCode" />
          <div class="input-group-append">
              <button class="btn btn-secondary" type="button" (click)="validateReferenceCode()" [disabled]="checkingReferenceCode">{{ checkingReferenceCode ? 'Checking...' : 'Validate' }}</button>
          </div>
          <span class="text-danger" *ngIf="referenceForm.controls.referenceCode.invalid">referenceCode</span>
      </div>
      <div class="mt-3">
        <alert type="success" *ngIf="referenceCodeSuccess">The reference code '{{ validatedReferenceCode.toUpperCase() }}' is valid.</alert>
        <alert type="warning" *ngIf="referenceCodeError">
            This reference code is not valid. please enter a valid reference code.
        </alert>
      </div>
    </div>
</div>
