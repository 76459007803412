<div class="modal-header">
  <h4 class="modal-title pull-left">Work States</h4>
</div>

  <div class="modal-body">

        <div class="form-group row">

        <label for="areaDistance" class="form-label col-lg-8 pt-2">Distance willing to work from registered office</label>
        <div class="col-lg-3">
          <div class="input-group">
            <input type="number"
                   id="areaDistance"
                   min="0"
                   step="100"
                   oninput="this.value=(parseInt( Math.abs(this.value))||0)"
                   class="form-control"
                   (input)="updateDistance($event)"
                   value="{{distance}}"
                   aria-describedby="distance-addon" />
            <div class="input-group-append">
              <span class="input-group-text" id="distance-addon">{{distanceUnit}}</span>
            </div>
          </div>
        </div>
      </div>


      <p style="margin: 0px,0px;">AND</p>

      <p style="margin-top: 0px;">within the following States:</p>
      <!-- <p style="font-size: 8; margin-top: 0px; color: black;"><i>Note you will need to provide supporting documentation for each region</i></p> -->
      <p style="font-size: 8; font-weight: bold; font-style: italic; margin-top: 0px; color: black;">
        Note you will need to provide supporting documentation for each State
      </p>
      <!-- <p style="font-size: 8; margin-top: 0px; color: red;"><i>Note you will need to provide supporting documentation for each region</i></p>
      <p style="font-size: 8; margin-top: 0px; color: red;"><b><i>Note you will need to provide supporting documentation for each region</i></b></p> -->
      <ng-container >

        <div class="text-center" *ngIf="!isLoaded">
          <app-loading-spinner></app-loading-spinner>
        </div>

        <div class="form-group mb-0" [hidden]="!isLoaded">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
          <mat-checkbox class="checklist-leaf-node"
                  [color]="theme"
                  [checked]="checklistSelection.isSelected(node)"
                  (change)="todoLeafItemSelectionToggle(node)">   {{node.item.name}}</mat-checkbox>

        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
          <i matTreeNodeToggle class="fas pe-2 treearrow" [class]="getTreeIcon(node)"></i>
          <mat-checkbox [checked]="descendantsAllSelected(node)"
                  [color]="theme"
                  [indeterminate]="descendantsPartiallySelected(node)"
                  (change)="todoItemSelectionToggle(node)">{{node.item.name}}</mat-checkbox>
        </mat-tree-node>
      </mat-tree>

    </div>
  </ng-container>



  </div>

  <div class="modal-footer">
    <alert type="danger" *ngIf="error">{{ error }}</alert>

    <button
      class="btn btn-primary"
      type="button"
      (click)="openSaveConfirmationModal(saveConfirmationTemplate)"
      [class.is-loading]="saving"
      [disabled]="!canSave">
      Save
    </button>
  </div>



<ng-template #saveConfirmationTemplate style="border: 2px solid purple; width: 100%;">
  <div style="border: 5px solid purple; width: 100%;">
    <div class="modal-header" style="background-color: white;">
      <h5 class="modal-title" id="modal-title" style="color: purple;">Changing State Selections could Impact Assessment Status!</h5>
    </div>
    <div class="modal-body">
      <p>
        You have added a state that you work in, and this requires an assessment relating to that state. Please check your assessments to ensure you are up to date.
      </p>
      <br>
      <p>Are you sure you wish to continue with the changes made?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="decline()">Cancel</button>
      <button type="button" class="btn btn-danger" (click)="confirm()" style="margin-left: 10px;">Yes</button>
    </div>
  </div>
</ng-template>

