import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';

import { Injectable, OnDestroy } from '@angular/core';
import { Router, ActivatedRouteSnapshot, ResolveEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class monitorService implements OnDestroy {
  private routerSubscription: Subscription;
  private monitor: ApplicationInsights = new ApplicationInsights({ config: environment.monitorConfig });

  constructor(private readonly router: Router) {
    this.monitor.loadAppInsights();
    this.routerSubscription = this.router.events.pipe(filter((event) => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
      const activatedComponent = this.getActivatedComponent(event.state.root);
      if (activatedComponent && environment.production) {
        this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  setUserId(userId: string) {
    this.monitor.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    this.monitor.clearAuthenticatedUserContext();
  }

  logPageView(name?: string, uri?: string) {
    this.monitor.trackPageView({ name, uri });
  }

  log(message: string, logLevel: SeverityLevel): void {
    this.monitor.trackTrace({ message: message, severityLevel: logLevel });
  }

  event(eventName: string): void {
    this.monitor.trackEvent({ name: eventName });
  }

  exception(exception: Error, severityLevel: SeverityLevel) {
    this.monitor.trackException({ exception, severityLevel });
  }

  metric(name: string, value: number) {
    this.monitor.trackMetric({ name: name, average: value });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
