
import { createReducer, on } from '@ngrx/store';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();

import { getStorage } from 'src/app/shared/functions/getBasket.functions';
import { registerStepOneAddSuccess, registerStepOneLoadSuccess, registerStepOneRemoveSuccess } from '../actions/registerStepOne.actions';

export const initialState = getStorage('registerStepOne');

const _registerStepOneReducer = createReducer(
  initialState,
  on(registerStepOneAddSuccess, (state, action) => action.payload),
  on(registerStepOneLoadSuccess, (state, action) => action.payload),
  on(registerStepOneRemoveSuccess, (state, action) => action.payload),
);

export function registerStepOneReducer(state, action) {
  return _registerStepOneReducer(state, action);
}
