<div class="modal-header">
  <h4 class="modal-title pull-left">User Profile</h4>
</div>

<div class="modal-body">
  <form [formGroup]="userForm" class="bg-light mb-3 p-2"  *ngIf="permissions === 'Full Access'">
    <div class="row">

      <!-- The new user's name. -->
      <div class="col-md-6">
        <label for="newFirstrName" class="form-label">First Name</label>
        <input id="newFirstrName" type="text" class="form-control" formControlName="firstName">
      </div>

      <div class="col-md-6">
        <label for="newLastName" class="form-label">Last Name</label>
        <input id="newLastName" type="text" class="form-control" formControlName="lastName">
      </div>

      <!-- The new user's email address. -->
      <div class="col-md-6">
        <label for="newUserEmail" class="form-label">Email</label>
        <input id="newUserEmail" type="text" class="form-control" formControlName="email">
      </div>

      <!-- The new user's Mobile phone. -->
      <div class="col-md-6">
        <label for="newUserMobile" class="form-label">Mobile</label>
        <input id="newUserMobile" type="text" class="form-control" formControlName="telephone">
      </div>

      <!-- The new user's company position. -->
      <div class="col-md-6">
        <label for="newUserPosition" class="form-label">Position</label>
        <input id="newUserPosition" type="text" class="form-control" formControlName="position">
      </div>

    </div>
  </form>




  <div class="bg-light mb-3 p-2"  *ngIf="permissions !== 'Full Access'">
    <div class="row">
      <div class="col-md-9">
        <!-- Display name, email and accoun status. -->
        <table>
          <tbody>
            <tr>
              <th class="pr-3">Name</th>
              <td>{{ contact.Firstname }} {{ contact.Lastname }}</td>
            </tr>
            <tr>
              <th class="pr-3">Email</th>
              <td>{{ contact.Email }}</td>
            </tr>
            <tr>
              <th class="pr-3">Status</th>
              <td>{{ contact.AccountStatus }}</td>
            </tr>
            <tr>
              <th class="pr-3">Mobile</th>
              <td>{{ contact.Telephone }}</td>
            </tr>
            <tr>
              <th class="pr-3">Status</th>
              <td>{{ contact.Position }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-3">
        <!-- Reset password link. -->
        <button class="btn btn-link"
                type="button"
                [class.is-loading]="isResettingPassword">
          <i class="fa fa-fw fa-refresh"></i>
          Reset Password
        </button>
      </div>
    </div>
  </div>

  <alert type="success" *ngIf="resetSuccess">
    A password reset link has been sent to the portal user email address.
  </alert>
  <alert type="danger" *ngIf="resetError">{{ resetError }}</alert>

  <!-- User roles. -->
  <div *ngIf="permissions !== 'No Access'">
    <div class="text-center my-2" *ngIf="isLoadingRoles; else displayUserRoles">
      <app-loading-spinner></app-loading-spinner>
    </div>
    <ng-template #displayUserRoles>
      <app-user-roles-matrix #rolesMatrix
                             [permissions]="permissions"
                             [roles]="contactRoles"
                             [contact]="contact"></app-user-roles-matrix>
    </ng-template>
  </div>

</div>

<div class="modal-footer">
  <alert type="danger" *ngIf="error">{{ error }}</alert>
  <button class="btn btn-link" type="button" (click)="close()">Close</button>
  <button
    class="btn btn-primary"
    type="button"
    (click)="updateContact()"
    [disabled]="saving"
    [class.is-loading]="saving">
    Update
  </button>
</div>
