import { Component, OnInit } from '@angular/core';
import { FormFieldTextComponent } from '../form-field-text/form-field-text.component';

@Component({
  selector: 'app-form-field-places',
  templateUrl: './form-field-places.component.html',
  styleUrls: ['./form-field-places.component.scss']
})
export class FormFieldPlacesComponent extends FormFieldTextComponent implements OnInit {
  results: any;
  hasEntered = false;

  public onInputChange() {
    if(!this.hasEntered) {
      this.hasEntered = true;
    } else if(this.hasEntered && this.results) {
      this.resetResults();
    }
    
    this.registerCoreService.getAddresses(this.control.value).subscribe((data) => {
      this.results = data;
    });
  }

  public resetResults() {
    this.hasEntered = false;
    this.results = undefined;
  }

  public chooseAddress(address) {
    this.isLoadingDetails = true;

    this.registerCoreService.getAddressDetails(address).subscribe((result) => {
      this.isLoadingDetails = false;
      this.output.emit({
        companyNumber: result.systemId,
        abNumber: result.abNumber,
        acNumber: result.acNumber,
        companyName: result.companyName,
        addressFinder: result.addressLine1,
        route: result.addressLine2,
        street_number: result.addressLine1,
        postal_code: result.postcode,
        postal_town: result.town,
        isAusGstRegistered: result.isAusGstRegistered,
        longitude: result.longitude,
        latitude: result.latitude
      });
    });
  }
}
