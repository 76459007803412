import { CreateAccountParameters } from './create-account-parameters';

export interface BasketItem {
  id?: string;
  accountId?: string;
  productID?: string;
  transaction_id?: string;
  applicationType?: string;
  assessmentType?: string;
  name?: string;
  units?: number; // number of employees, etc.
  price?: number; // total price of the item.
  fastTrack?: number;
  joiningFee?: number;
  checkFastTrack?: boolean;
  account?: CreateAccountParameters;
  renewal?: boolean;
  upgrade?: boolean;
  foundation?: boolean;
  freeMembership?: boolean;
  referenceCode?: string;
  discountAmount?: number;
  brand?: string;
  category?: string;
  vatPercent?: number;
}

