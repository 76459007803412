import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AccountsService } from '../../../core';
import { first } from 'rxjs/internal/operators/first';
import { ReferenceCodeType } from '../../constants/reference-code-type.const';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-reference-validator',
  templateUrl: './reference-validator.component.html',
  styleUrls: ['./reference-validator.component.scss']
})
export class ReferenceValidatorComponent {
  @Output() emitReferenceCode = new EventEmitter();
  validatedReferenceCode: string;
  referenceCodeSuccess: boolean;
  referenceCodeError: boolean;
  referenceForm = new UntypedFormGroup({
    refCode: new UntypedFormControl('')
  });

  constructor(private Accounts: AccountsService) { }

  inputChange() {
    if (!this.referenceForm.value.refCode.length) this.emitReferenceCode.emit(null);
  }

  /**
   *
   */
  validateReferenceCode(): void {
    const referenceCode = this.referenceForm.value.refCode;
    if (!referenceCode) {
      return;
    }

    this.referenceCodeSuccess = false;
    this.referenceCodeError = false;
    this.Accounts.validateReferenceCode(referenceCode)
      .pipe(first())
      .subscribe(outcome => {
        if (ReferenceCodeType.INVALID === outcome) {
          this.emitReferenceCode.emit(null);
          this.referenceCodeError = true;
          return;
        }

        // Get Reference Code Products / Bundles
        // Only do this if Validate Reference Code returns 'Bundle or Discount Code'.
        // If it's a Campaign code, returns 'Campaign Code' - only used for tracking purposes.

        this.referenceCodeSuccess = true;
        this.emitReferenceCode.emit(referenceCode);
        if (ReferenceCodeType.BUNDLE === outcome) {
          this.validatedReferenceCode = referenceCode;
        } else {
          this.validatedReferenceCode = null;
        }
      });
  }

}
