import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AccountResponse, ActiveCard, ThirdPartyCasProvider, LatestBundleResponse, featureFlagNames, CheckNewAuthenticationResponse }
  from '../../../../shared';
import { AccountsService } from '../../../../core';
import { HttpErrorResponse } from '@angular/common/http';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { ProductName } from 'src/app/shared/classes/productName.class';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';

@Component({
  selector: 'app-cas-third-party-tile',
  templateUrl: './cas-third-party-tile.component.html',
  styleUrls: ['./cas-third-party-tile.component.scss']
})

export class DashboardCASThirdPartyTileComponent implements OnInit {
  @ViewChild('closebutton', { static: false }) closebutton;
  @ViewChild('selectedProviderInput', { static: false }) selectedProviderInput;
  @ViewChild('membershipNumberInput', { static: false }) membershipNumberInput;
  @Output() emitRagClass = new EventEmitter();
  @Output() emitOpenModal = new EventEmitter();
  @Output() emitLogin = new EventEmitter();
  @Input() authAccount: CheckNewAuthenticationResponse;
  @Input() account: AccountResponse;
  @Input() productName: string;
  @Input() typeAndVariant: { assessmentType: string, variant: string };
  @Input() membershipExpired: boolean;
  @Input() foundation: boolean;
  @Input() bundle: LatestBundleResponse;

  activeCard: ActiveCard = { name: 'CAS_Third_Party', active: null, credits: null };
  isLoading: boolean = false;
  modalbtnLoading: boolean;

  casThirdPartyForm: UntypedFormGroup;

  thirdPartyRegistered: boolean = false;
  thirdPartyCasProvider: string;
  thirdPartyCasMembershipNumber: string;
  dateCasDataUpdated: string;
  membershipNumberMaxLength: number = 20;
  private config: IEnvironment;
  public isAchilles = false;

  constructor(
    private gtmService: GtmService,
    private accountService: AccountsService
  ) { }

  async ngOnInit(): Promise<void> {
    const env = new Environment();
    this.config = await env.getConfig();

    this.resetModalForm();
    
    this.setThirdPartyInfo();

    this.isAchilles = this.checkIsAchilles();
  }

  resetModalForm(): void {
    this.casThirdPartyForm = new UntypedFormGroup({
      selectedProviderInput: new UntypedFormControl(''),
      membershipNumberInput: new UntypedFormControl(''),
      confirmationCheck: new UntypedFormControl(false),
    });
  }

  get productImage(): string {
    return `assets/img/product-cards/common_assessment_standard_third_party.png`;
  }

  setRagClass(active) {
    if (active) this.emitRagClass.emit(['green', false]);
    else this.emitRagClass.emit(['white', false]);
  }

  pushGoogleTag(): void {
    this.gtmService.pushTag({
      id: this.config.googleAnalytics.googleTagManagerId,
      event: 'open_product_card_modal',
      product_card: this.activeCard.name
    });
  }

  get saveButtonDisabled() {
    const provider = this.casThirdPartyForm.value.selectedProviderInput;
    const regNumber = this.casThirdPartyForm.value.membershipNumberInput;
    const confirmationCheck = this.casThirdPartyForm.value.confirmationCheck;
    return !provider || !regNumber || !confirmationCheck;
  }

  closeModal(): void {
    this.closebutton.nativeElement.click();
  }

  async updateAccountCasDataAsync(clearCasFields: boolean) {
    var casData = {
      clearFields: clearCasFields,
      thirdPartyCasProvider: null,
      thirdPartyCasMembershipNumber: null
    }

    if (!clearCasFields) {
      this.modalbtnLoading = true;
      casData.thirdPartyCasProvider = this.casThirdPartyForm.value.selectedProviderInput;
      casData.thirdPartyCasMembershipNumber = this.casThirdPartyForm.value.membershipNumberInput;
    }

    await this.accountService.updateCasThirdPartyData(this.authAccount.accountId, casData).subscribe(() => {
      if (clearCasFields) {
        this.thirdPartyCasProvider = null;
        this.thirdPartyRegistered = false;
        this.refreshAccountInfoAsync();
        this.resetModalForm();
      } else {
        this.refreshAccountInfoAsync();
        this.modalbtnLoading = false;
        this.closeModal();
      }
    }, (response: HttpErrorResponse) => {
        console.error(response);
    });
  }

  async refreshAccountInfoAsync(): Promise<void> {
    this.setThirdPartyInfo();
  }

  setThirdPartyInfo(): void {
    const thirdPartyCasProvider = this.authAccount.thirdPartyCasProvider;
    if (thirdPartyCasProvider !== null) {
      this.thirdPartyCasProvider = ThirdPartyCasProvider[thirdPartyCasProvider];
      this.thirdPartyCasMembershipNumber = this.authAccount.membershipId;
      this.thirdPartyRegistered = true;
      const dateCasDataUpdated = this.authAccount.dateCasDataUpdated ? this.authAccount.dateCasDataUpdated: null;

      if (dateCasDataUpdated !== null) {
        this.dateCasDataUpdated = this.formatDate(new Date(dateCasDataUpdated));
      }
      this.setRagClass(true);
    } else {
      this.setRagClass(false);
    }
  }

  formatDate(dateToFormat: Date): string {
    var dd = String(dateToFormat.getDate()).padStart(2, '0');
    var mm = String(dateToFormat.getMonth() + 1).padStart(2, '0'); //January is 0
    var yyyy = dateToFormat.getFullYear();
    return dd + '/' + mm + '/' + yyyy;
  }

  setSelectedProvider(): void {
    this.thirdPartyCasProvider = this.selectedProviderInput.nativeElement.selectedOptions[0].text;
  }

  setMembershipNumber(): void {
    this.thirdPartyCasMembershipNumber = this.membershipNumberInput.nativeElement.value;
  }

  private checkIsAchilles(): boolean {
    return this.thirdPartyCasProvider == 'Achilles';
  }

  isConstructionline(): boolean {
    if (this.thirdPartyCasProvider == 'Constructionline') {
      return true;
    } else {
      return false
    }
  }

  isCQMS(): boolean {
    return this.thirdPartyCasProvider == 'CQMS';
  }

  numberOnly(event): boolean {
    if(this.thirdPartyCasProvider == 'CQMS') { return true; }
    
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  dateCasDataUpdatedIsPopulated(): boolean {
    return this.dateCasDataUpdated != null && this.dateCasDataUpdated != undefined;
  }

  get isVerified(): boolean {
    return this.dateCasDataUpdatedIsPopulated();
  }

  get confirmationRABName(): string {
    return this.thirdPartyCasProvider ?? 'this Recognised Assessment Body';
  }

  get HasEliteMembership(): boolean {
    return this.bundle !== undefined && (this.bundle.bundleName === ProductName.level4 || this.bundle.bundleName === ProductName.level4Assured);
  }
}
