<!-- Distance from registered office. -->
<div class="form-group row">
  <div class="col-lg-6">
    Distance from registered office
  </div>
  <div class="col-lg-6">
    <ng-container *ngIf="distance>0; else noDistance">
      {{ distanceText }}
    </ng-container>
    <ng-template #noDistance>
      <span class="text-danger">*Distance from office is required</span>
    </ng-template>
  </div>
</div>
<!-- Work States. -->
<div class="form-group row" *ngIf="showStates">
  <div class="col-lg-6">
    {{ stateHeadingText }}
  </div>
  <div class="col-lg-6">
    <ng-container *ngIf="regionsAreSet; else noRegions">
      <ul class="list-unstyled">
        <li *ngFor="let row of workStates">
          {{ row.name }}
        </li>
      </ul>
    </ng-container>
    <ng-template #noRegions>
      <span class="text-danger">*No States defined. At least one State is required</span>
    </ng-template>
  </div>
</div>
<!-- Work regions. -->
<div class="form-group row">
  <div class="col-lg-6">
    {{ headingText }}
  </div>
  <div class="col-lg-6">
    <ng-container >
      <ul class="list-unstyled">
        <li *ngFor="let row of workRegions">
          {{ row.name }}
        </li>
      </ul>
    </ng-container>
  </div>
</div>