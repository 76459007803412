import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferenceValidatorComponent } from './reference-validator.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BootstrapComponentsModule } from '../bootstrap/bootstrap.module';



@NgModule({
  declarations: [ReferenceValidatorComponent],
  exports: [ReferenceValidatorComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BootstrapComponentsModule
  ]
})
export class ReferenceValidatorModule { }
