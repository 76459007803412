import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map, shareReplay, catchError } from 'rxjs/operators';
import { Environment } from 'src/app/shared/classes/environment';

import {
  LatestBundleResponse,
  AccountResponse,
  AccountUpdateSecurityParameters,
  AccountUpdateSecurityResponse,
  CreateAccountParameters,
  ReferenceCodeType,
  CheckAuthenticationResponse,
  CompanyDetails,
  AccountIdentifier,
  Contact,
  Address,
  CompanyDetailsForm,
  UpdateAccountConfirmations,
  CheckNewAuthenticationResponse,
  ICompanyDetailsAccountUpdateCommand,
  IAddress,
} from '../../shared';
import { CountryService } from './country.service';
import { loadCurrentUser } from 'src/app/core/ngrx/actions/currentUser.actions';
import { Store } from '@ngrx/store';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  constructor(
    private http: HttpClient,
    private readonly store: Store
  ) {}

  private accountId: string;
  private accountSubject = new ReplaySubject<AccountResponse>(1);
  private env = new Environment();
  private environment = this.env.getConfigSync();

  /**
   * Fetch a list of product bundles associated with the specified account.
   */
  getLatestBundle(account: CheckAuthenticationResponse | string, contactId?: string): Observable<LatestBundleResponse[]> {
    let accountId = account;
    let accountResponse = account as CheckAuthenticationResponse;

    if (typeof accountId !== 'string') {
      accountId = accountResponse.AccountId;
      contactId = accountResponse.ContactId;
    }

    const url = `${this.environment.apiEndpoint.products}/contractors/${accountId}/contacts/${contactId}/bundles`;

    return this.http.get(url).pipe(
      map((data: []) => {
        let arr: LatestBundleResponse[] = new Array();
        data.forEach((product: any) => {
          arr.push({
            bundleName: product.name,
            bundleId: product.id,
            bundleExpiry: product.expiry,
            numberOfDirectEmployees: product.numberOfDirectEmployees,
            numberOfLabourOnlySubcontractors: product.numberOfLabourOnlySubcontractors,
            numEmployees: product.numberOfEffectiveEmployees,
            numberOfBonaFideSubcontractors: product.numberOfBonaFideSubcontractors,
            variant: product.variant === null ? 0 : product.variant,
            productAssessmentType: parseInt(product.productAssessmentType),
            referenceCode: product.referenceCode,
          });
        });
        return arr;
      }),
      shareReplay(1)
    );
  }

  cachedAccount(): Observable<AccountResponse> {
    return this.accountSubject.asObservable();
  }

  public refresh(): void {
    this.updateAccountIdFromStorage();
    this.store.dispatch(
      loadCurrentUser({
        accountId: this.accountId,
      })
    );
  }

  private updateAccountIdFromStorage(): void {
    this.accountId = sessionStorage.getItem('guidac') ? sessionStorage.getItem('guidac') : null;
  }

  getCurrentUser(accountId: string): Observable<CurrentUserState> {
    let url = `${this.environment.apiEndpoint.newChasAPIv1}/currentuser`;

    if (accountId != null && accountId.length > 0) {
      url = `${this.environment.apiEndpoint.newChasAPIv1}/currentuser/${accountId}`;
    }

    return this.http.get<CurrentUserState>(url);
  }

  public getExistingDiscountCode$(accountId: string): Observable<string> {
    let url = `${this.environment.apiEndpoint.accounts}/${accountId}/getexistingdiscountcode`;

    return this.http.get(url, { responseType: 'text' }).pipe(
      map((response) => {
        return response.toString();
      })
    );
  }

  /*
   * Attempts to validate a provided reference code.
   * The API will return a 400 HTTP error if the code is invalid.
   *
   * @param referenceCode the reference code to validate.
   */
  validateReferenceCode(referenceCode: string): Observable<string> {
    const url = `${this.environment.apiEndpoint.products}/public/products/reference-codes/${referenceCode}`;
    return this.http.get(url).pipe(
      map((result: any) => result.value),
      catchError(() => of(ReferenceCodeType.INVALID))
    );
  }

  /**
   * Update the security settings for the associated account.
   */
  updateSecuritySettings(accountId: string, params: AccountUpdateSecurityParameters): Observable<AccountUpdateSecurityResponse> {
    const url = `${this.environment.apiEndpoint.catalyst}/Accounts/UpdateAccount/${accountId}`;
    return this.http.post(url, params).pipe(map((data) => data as AccountUpdateSecurityResponse));
  }

  /**
   * Attempts to create a new user account, returning the new account ID.
   */
  create(params: CreateAccountParameters, ignoreValidation: boolean = false): Observable<string> {
    const url = `${this.environment.apiEndpoint.catalyst}/account`;
    params.ignoreValidation = ignoreValidation ? 'true' : 'false';
    return this.http.post(url, params).pipe(
      map((response) => {
        return response.toString();
      })
    );
  }

  /**
   * Updates an account with new information.
   */
  updateAccount(accountId: string, companyDetails: ICompanyDetailsAccountUpdateCommand) {
    const url = `${this.environment.apiEndpoint.accounts}/companydetailsupdate`;
    return this.http.patch(url, companyDetails);
  }

  /**
   * Returns the size of the account's business as a term.
   */
  getBusinessSizeLabel(companyDetails: CompanyDetails, hasCASBundle: boolean = false) {
    const turnover = companyDetails.turnover;
    const employees = companyDetails.numberOfEffectiveEmployees;

    const bands = [
      {
        infoRequired: true,
        maxEmployees: 5,
        maxTurnover: 500000,
        name: 'Micro',
      },
      {
        infoRequired: true,
        maxEmployees: 10,
        maxTurnover: 1800000,
        name: 'Micro',
      },
      {
        infoRequired: true,
        maxEmployees: 50,
        maxTurnover: 9000000,
        name: 'Small',
      },
      {
        infoRequired: true,
        maxEmployees: 250,
        maxTurnover: 45000000,
        name: 'Medium',
      },
      {
        infoRequired: false,
        maxEmployees: 10,
        maxTurnover: null,
        name: 'Micro',
      },
      {
        infoRequired: false,
        maxEmployees: 50,
        maxTurnover: null,
        name: 'Small',
      },
      {
        infoRequired: false,
        maxEmployees: 250,
        maxTurnover: null,
        name: 'Medium',
      },
    ];

    const matchingBand = bands.find((band) => {
      const matchRequiredInfo = band.infoRequired === (hasCASBundle && null !== turnover);
      const matchTurnover = hasCASBundle && null !== turnover ? turnover < band.maxTurnover : true;
      const matchEmployees = employees < band.maxEmployees;
      return matchRequiredInfo && matchTurnover && matchEmployees;
    });
    return matchingBand ? matchingBand.name : 'Large';
  }

  async createOpportunityLegacyAsync(object): Promise<any> {
    let url = `${this.environment.apiEndpoint.catalyst}/v2/checkout`;
    return await this.http.post(url, object).toPromise();
  }

  completePayment(accountId: string, bundleId: string, settings: { [key: string]: string }) {
    return this.http.post(`${this.environment.apiEndpoint.catalyst}/accounts/${accountId}/payment`, settings, { params: { bundleId } });
  }

  async callCompletePaymentAsync(accountId: string, bundleId: string, settings: { [key: string]: string }): Promise<any> {
    return await this.completePayment(accountId, bundleId, settings).toPromise();
  }

  saveCertificateOptionsPreference(accountId: string, code: string) {
    const url = `${this.environment.apiEndpoint.newChasAPIv1}/accounts/${accountId}/certificate-option`;
    return this.http.put(url, { certificateOption: code });
  }

  saveDataSharingPreference(accountId: string, isEnabled: boolean) {
    const url = `${this.environment.apiEndpoint.accounts}/accounts/${accountId}/cas-data-sharing`;
    return this.http.put(url, { dataSharingEnabled: isEnabled });
  }

  updateCasThirdPartyData(accountId: string, data: any) {
    const url = `${this.environment.apiEndpoint.accounts}/accounts/${accountId}/cas-third-party`;
    return this.http.put(url, data);
  }

  getCompanyDetails(accountId: string) {
    const url = `${this.environment.apiEndpoint.accounts}/account/${accountId}`;
    return this.http.get(url).pipe(
      map((data) => data as CompanyDetails),
      shareReplay(1)
    );
  }

  async updateConfirmationsAsync(accountId: string, payload: UpdateAccountConfirmations) {
    const url = `${this.environment.apiEndpoint.accounts}/secure/${accountId}/updateconfirmations`;
    return this.http.post(url, payload).toPromise();
  }

  /**
   * Update the working distance (in miles) for an account.
   */
  public updateWorkDistance(accountId: string, distance: number): Observable<any> {
    return this.http.post(`${this.environment.apiEndpoint.accounts}/updateworkdistance`, { accountId, object: distance });
  }

  toCompanyDetails(
    accountId: string,
    name: string,
    form: CompanyDetailsForm,
    countryService: CountryService
  ): ICompanyDetailsAccountUpdateCommand {
    const companyDetails: ICompanyDetailsAccountUpdateCommand = {
      accountId: accountId,
      name: name,
      summary: form.summary,
      numberOfDirectEmployees: form.number_of_direct_employees,
      numberOfBonaFideSubcontractors: form.number_of_bona_fide_subcontractors,
      numberOfEffectiveEmployees: form.number_of_effective_employees,
      numberOfLabourOnlySubcontractors: form.number_of_labour_only_subcontractors,
      organisationType: form.organisation_type,
      primaryWorkCategory: form.primary_trade,
      tradingName: form.trading_name,
      primaryTrade: form.primary_trade,
      turnover: form.turnover,
      vatRegistrationNumber: form.vat_reg_number,
      hearChas: form.hear_chas,
      hearChasText: form.hear_chas_text_option,
      mandatedClient: form.mandated_client,
      website: form.website_url,
      primaryContactId: form.primary_contact_id,
      primaryContactPosition: form.position,
      telephone: form.telephone,
      parentAccountId: form.parent_account_id,
      ultimateParentAccountId: form.ultimate_parent_account_id,
      printPack: form.print_pack,
      registerAddress: null,
      distance: form.distance,
      workCategories: form.workCategories,
      workRegions: form.workRegions,
      // registerAddress: {
      //   street1: form.register_address_street1,
      //   street2: form.register_address_street2,
      //   postCode: form.register_address_postcode,
      //   city: form.register_address_city,
      //   country: countryService.getNameFromCode(form.register_address_country_code),
      //   countryCode: form.register_address_country_code
      // } as IAddress,

      correspondenceAddress: {
        Id: form.correspondence_address_id,
        street1: form.correspondence_address_street1,
        street2: form.correspondence_address_street2,
        postCode: form.correspondence_address_postcode,
        city: form.correspondence_address_city,
        country: countryService.getNameFromCode(form.correspondence_address_country_code),
        countryCode: form.correspondence_address_country_code,
      } as IAddress,
    };

    return companyDetails;
  }
}
