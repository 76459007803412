import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../core';

@Injectable({
  providedIn: 'root',
})
export class MustNotBeLoggedInGuard {
  constructor(private Auth: AuthService) {}

  /**
   * Returns TRUE if the user should be able to access the route.
   */
  async canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isLoggedIn = this.Auth.isLoggedIn();

    if (!isLoggedIn) {
      await this.Auth.loginSignupRedirect();
    }

    return isLoggedIn;
  }
}
