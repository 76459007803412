<!--<div class="text-center" *ngIf="isLoading">-->
<!--  <app-loading-spinner></app-loading-spinner>-->
<!--</div>-->

<div  class="d-flex flex-column">
  <div class="flex-row">
      <h3>Upgrade Your Membership From Verified Contractor</h3>
      <p>
        Show your commitment to creating a safer risk free and more compliant world of working for all of your employees by upgrading your
        membership.

        CHAS Elite is the elite standard of CHAS accreditation and
        includes all assessments under Verified Contractor and seven
        additional risk areas: health and safety (H&S), quality,
        environmental, equality. diversity and inclusion (EDI), anti-bribery
        and corruption, corporate social responsibility (CSR) and
        information security
      </p>
  </div>
    <div  class="d-flex flex-column align-content-center justify-content-center productCardContainer">
      <div *ngIf="loading; else loaded">
        <mat-progress-bar mode="query"></mat-progress-bar>
      </div>
      <ng-template #loaded>
        <mat-stepper orientation="horizontal"  [disableRipple]="true" [headerPosition]="'bottom'" [animationDuration]="'600'" [(selectedIndex)]="stepIndex" #stepper>
          <mat-step>
            <div class="card justify-content-around p-2 col-6 mx-auto" *ngFor="let quote of opportunity.quotes">
              <div class="align-content-center header">
                <h4 class="mx-auto pt-2">
                  <b> {{ quote.result.productName.replace(' (Upgrade)', '')}}</b>
                </h4>
              </div>

              <div class="d-flex flex-row mx-auto mt-4">
                <img style="width: 70px" src="assets/img/product-cards/elite.png" />

              </div>
              <div class="d-flex flex-column mx-auto col-10">
                <div class="text-center price mb-2">
                  <h4>Price ${{ quote.result.grandTotal }}</h4>
                </div>
                <p class="text-center">
                  CHAS Elite is the elite standard of CHAS accreditation and
                  includes all assessments under Verified Contractor and seven
                  additional risk areas: health and safety (H&S), quality,
                  environmental, equality. diversity and inclusion (EDI), anti-bribery
                  and corruption, corporate social responsibility (CSR) and
                  information security
                </p>
              </div>

              <div class="flex-column col-10 mx-auto mt-3 text-center">
                <button
                  class="mx-auto eliteButton"
                  mat-raised-button
                  style="color: white"
                  matStepperNext>
                  SELECT THIS PACKAGE
                </button>
                <p class="mt-2" style="font-size: x-small">Not registered for GST</p>
              </div>

            </div>

          </mat-step>
          <mat-step >
            <app-membership-upgrade-summary  [opportunity]="opportunity" [checkoutSession]="checkoutSession" ></app-membership-upgrade-summary>
          </mat-step>
          <mat-step>
            <app-membership-upgrade-successful></app-membership-upgrade-successful>
          </mat-step>
        </mat-stepper>

      </ng-template>

    </div>
</div>


