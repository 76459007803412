import { Component, OnInit, Input, Output, EventEmitter, forwardRef, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import productBundlesJSON from 'src/assets/json/product-bundles.json';
import productModulesJSON from 'src/assets/json/product-modules.json';
import { featureFlagNames, ProductBundleDetails } from '../../index';
import { GtmBrand, GtmCurrency, GtmListTypes, GtmUserCategory } from 'src/app/core/services/gtm/gtm.enum';
import { GtmService } from '../../../core/services/gtm/gtm.service';
import { PriceService } from '../../../core/services/price.service';
import { GtmProduct } from '../../../core/services/gtm/gtm.interface';
import { RegisterCoreService } from '../../../core/services/register-core.service';
import { BundleNames } from 'src/app/shared/components/select-membership/select-membership.component';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ProductBundlesComponent),
  multi: true
};

@Component({
  selector: 'app-product-bundles',
  templateUrl: './product-bundles.component.html',
  styleUrls: ['./product-bundles.component.scss'],
  providers: [CUSTOM_VALUE_ACCESSOR]
})
export class ProductBundlesComponent implements OnInit, ControlValueAccessor,  AfterViewInit {
  @Input() referenceCode: string;
  @Input() preselectedProductNumber: string;
  @Input() foundation: boolean;

  @Output() bundleSelected = new EventEmitter<ProductBundleDetails>();

  bundles: ProductBundleDetails[] = productBundlesJSON;
  isLoading: boolean = false;
  error: boolean = false;
  moduleList: any;
  moduleIcon: [string];
  isSupplierEnabled = false;

  private disabled: boolean = false;
  private selectedBundle: ProductBundleDetails = null;
  private onChange: Function = (_: any) => { };;
  private onTouched: Function = () => { };

  constructor(
    private readonly gtmService: GtmService,
    private readonly priceService: PriceService,
    private readonly registerCoreService: RegisterCoreService
  ) {

  }

  ngAfterViewInit(): void {
    let preselectedBundle = this.bundles.find(x => x.productNumber == this.preselectedProductNumber);
    const defaultBundle = this.foundation ? this.bundles[0] : preselectedBundle ?? this.bundles[2];
    setTimeout(() => this.selectBundle(defaultBundle, 0, true));
  }

  async ngOnInit() {
    this.isSupplierEnabled = await this.registerCoreService.checkSupplierEnabled();

    this.moduleList = productModulesJSON;

    let impressions: GtmProduct[] = [];

    for (let index = 0; index < this.bundles.length; index++) {
      const bundle = this.bundles[index];

      impressions.push({
        id: bundle.productNumber,
        name: bundle.name,
        price: this.priceService.getPrice(bundle.level),
        brand: GtmBrand.CHAS,
        category: GtmUserCategory.Contractor,
        list: GtmListTypes.signup,
        position: index
      });
    }

    this.gtmService.productList({
      currencyCode: GtmCurrency.GBP,
      impressions: impressions
    });
  }

  /**
   * Set the component value.
   * We want to return the details of the selected product bundle as well as its ID.
   */
  writeValue(obj: ProductBundleDetails): void {
    this.selectedBundle = obj;
  }

  get value(): ProductBundleDetails {
    return this.selectedBundle;
  }

  public insuranceFeatureFlag(): boolean {
    return true;
  }

  checkModule(level, name) {
    let module = productModulesJSON.find(m => m.name === name);
    if (module.levels.includes(level)) {
      this.moduleIcon = ['advantage_icon'];
      return true;
    } else if (module.exclude.includes(level)) {
      this.moduleIcon = ['disadvantage_icon'];
      return true;
    } else {
      return false;
    }
  }


  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Set the selected product bundle.
   */
  selectBundle(bundle: ProductBundleDetails, index: number, initialBundle?: boolean): void {
    this.selectedBundle = bundle;
    this.updateControl();

    if(index && !initialBundle) {
      this.gtmService.productItem({
        actionField: { list: GtmListTypes.signup },
        product: {
          id: bundle.productNumber,
          name: bundle.name,
          price: this.priceService.getRawPrice(bundle.level),
          brand: GtmBrand.CHAS,
          category: GtmUserCategory.Contractor,
          list: GtmListTypes.signup,
          position: index
        }
      });
    }

    this.bundleSelected.emit(this.selectedBundle);
  }

  /**
   * Clears the selected product bundle.
   */
  resetSelectedBundle(): void {
    this.selectedBundle = null;
    this.updateControl();
  }

  private updateControl(): void {
    this.onChange(this.selectedBundle);
    this.onTouched();
  }

  /**
   * Returns TRUE if the specified product bundle is selected.
   */
  isSelected(bundle: ProductBundleDetails): boolean {
    if(this.selectedBundle === undefined || this.selectedBundle === null) { return false; }
    if(bundle === undefined || bundle === null) { return false; }
    return this.selectedBundle.level === bundle.level;
  }

  shouldShowBundle(bundle: ProductBundleDetails) {
    if(bundle.level === 0) {
      return this.foundation;
    } else if(!this.isSupplierEnabled && bundle.name === BundleNames.Supplier) {
      return false;
    }

    return true;
  }

  get filteredBundles(): ProductBundleDetails[] {
    return this.bundles.filter(x => this.shouldShowBundle(x));
  }

}
