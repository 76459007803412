import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-register-employees-aus',
  templateUrl: './register-employees.component.html',
  styleUrls: ['./register-employees.component.scss']
})
export class RegisterEmployeesAusComponent implements OnInit {
  @Input() public form: UntypedFormGroup;
  private directEmployees: AbstractControl;

  public ngOnInit(): void {
    this.directEmployees = this.form.get('number_of_direct_employees');
  }
}
