
import { createReducer, on } from '@ngrx/store';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();
import { getStorage } from 'src/app/shared/functions/getBasket.functions';
import { registerStepTwoAddSuccess, registerStepTwoLoadSuccess, registerStepTwoRemoveSuccess } from '../actions/registerStepTwo.actions';

export const initialState = getStorage('registerStepTwo');

const _registerStepTwoReducer = createReducer(
  initialState,
  on(registerStepTwoAddSuccess, (state, action) => action.payload),
  on(registerStepTwoLoadSuccess, (state, action) => action.payload),
  on(registerStepTwoRemoveSuccess, (state, action) => action.payload),
);

export function registerStepTwoReducer(state, action) {
  return _registerStepTwoReducer(state, action);
}
