import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { LatestBundleResponse, AssistConfiguration, AssistType } from '../../shared';
import { ApplicationType } from 'src/app/shared/classes/applicationType.class';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';


@Injectable({
  providedIn: 'root'
})
export class SurveyAssistService {
  private config: IEnvironment;
  private $configurationData: Observable<AssistConfiguration[]>;
  private $bundleData: Observable<LatestBundleResponse[]>;

  constructor(
    private http: HttpClient) {

      const env = new Environment();
      this.config = env.getConfigSync();

    this.$configurationData = this.getConfig();
  }

  getAssistConfiguration(): Observable<AssistConfiguration[]> {
    return this.$configurationData;
  }

  getLatestBundle(): Observable<LatestBundleResponse[]> {
    return this.$bundleData;
  }

  setLatestBundle(bundleData: Observable<LatestBundleResponse[]>) {
    if (!this.$bundleData) {
      this.$bundleData = bundleData;
    }
  }

  private getConfig(): Observable<AssistConfiguration[]> {
    const url = `${this.config.apiEndpoint.documents}/assist/configuration`;
    return this.http.get<AssistConfiguration[]>(url).pipe(shareReplay(1));
  }

  getSurveyAssist(bundle: LatestBundleResponse, surveyName: string, config: AssistConfiguration[]): AssistConfiguration {
    let match: AssistConfiguration = null;
    let appType: AssistType;

    if (surveyName.indexOf('Health & Safety') > -1) appType = this.getApplicationType(bundle);

    for (let i = 0; i < config.length; i++) {
      const assistType = config[i];
      let isMatch = surveyName.indexOf(assistType.assessment) > -1;
      let isBundleMatch = false;
      let isAppTypeMatch = true;
      let isDTS = (appType && appType.isDTS);

      if (isDTS) {
        isMatch = assistType.assessmentType && assistType.assessmentType === 'DTS';
      }

      if (isMatch) {
        isBundleMatch = assistType.displayInProducts.indexOf(bundle.bundleName) > -1;
        if (assistType.applicationTypes && !isDTS) {
          isAppTypeMatch = assistType.applicationTypes.indexOf(appType.applicationType) > -1;
        }
      }

      if (isMatch && isBundleMatch && isAppTypeMatch) {
        match = { ...assistType };
        match.documentLink = `${this.config.apiEndpoint.documents}/${assistType.documentLink}`;
        break;
      }
    }
    return match;
  }

  public getApplicationType(bundle: LatestBundleResponse): AssistType {
    return new ApplicationType().getType(bundle);
  }
}

