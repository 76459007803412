import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssessmentTypesComponent } from './assessment-types.component';
import { NothingModule } from '../nothing/nothing.module';

@NgModule({
  declarations: [AssessmentTypesComponent],
  imports: [
    CommonModule,
    NothingModule
  ],
  exports: [AssessmentTypesComponent]
})
export class AssessmentTypesModule { }
