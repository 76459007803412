import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();
import { AuthService } from './auth.service';
import { azureB2c } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let updatedRequest: HttpRequest<any>;
    let authToken = localStorage.getItem(azureB2c.ACCESS_TOKEN_KEY);

    updatedRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`
      }
    });
    updatedRequest.headers.append("Authorization", `Bearer ${authToken}`);
    return next.handle(updatedRequest);            
  }
}
