import { Component, Output, EventEmitter, Input, ViewChild, OnChanges, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AccountResponse, ActiveCard, ThirdPartyProduct, SSOUrlParametersGenerator, termsAndConditions, GetQuoteRequest, GetQuoteResponse, UserTypeCode, CheckNewAuthenticationResponse } from '../../../../shared';
import { CreateOpportunityParameters } from '../../../../shared/interfaces/create-opportunity-parameters';
import { SSOService, PaymentService, ProductService } from '../../../../core';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/internal/operators/finalize';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';

@Component({
  selector: 'app-plant-tile',
  templateUrl: './plant-tile.component.html',
  styleUrls: ['./plant-tile.component.scss']
})
export class DashboardPlantTileComponent implements OnInit, OnChanges {
  @ViewChild('closebutton', { static: false }) closebutton;
  @Output() emitRagClass = new EventEmitter();
  @Output() emitLogin = new EventEmitter();
  @Input() authAccount: CheckNewAuthenticationResponse;
  @Input() account: AccountResponse;
  @Input() product: ThirdPartyProduct;
  @Input() typeAndVariant: { assessmentType: string, variant: string };
  @Input() membershipExpired: boolean;
  @Input() foundation: boolean;
  activeCard: ActiveCard = { name: 'CHAS_Plant', active: null, credits: null };
  modalRef: BsModalRef;
  private $destroyed = new Subject();
  opportunityObject: CreateOpportunityParameters;
  error: string;
  loadingQuote: boolean = false;
  isLoading: boolean = false;
  modalbtnLoading: boolean;
  credits: number;
  quotePrice: any;
  links: any;
  purchasePlantForm = new UntypedFormGroup({
    numAssets: new UntypedFormControl('', [Validators.required]),
    confirmPlant: new UntypedFormControl('', [Validators.requiredTrue])
  });
  quoteHasVat: boolean = true;
  private config: IEnvironment;

  constructor(
    private readonly gtmService: GtmService,
    private readonly SSO: SSOService,
    private readonly Payment: PaymentService,
    private readonly ProductService: ProductService
  ) { }

  async ngOnInit() {
    const env = new Environment();
    this.config = await env.getConfig();
  }

  ngOnChanges(): void {
    this.links = termsAndConditions;
    this.getCredits();
    this.getSSO();
  }

  get productImage(): string {
    return `assets/img/product-cards/chas_plant.png`;
  }

  getRagClass(active) {
    if (active) this.emitRagClass.emit(['green', false]);
    else this.emitRagClass.emit(['white', false]);
  }

  async loginAsync(): Promise<void> {
    if (this.isLoading) { return; }

    const params = new SSOUrlParametersGenerator(
      this.authAccount.accountId, this.authAccount.contactId
    ).chasPlant();

    try {
      this.error = null;
      this.isLoading = true;
      let redirectUrl: string = await this.SSO.urlAsync(params);
      window.open(redirectUrl, "_blank");
    }
    catch(error) {
      this.error = error.message;
    }
    finally {
      this.isLoading = false;
    }
  }

  pushGoogleTag() {
    this.gtmService.pushTag({
      id: this.config.googleAnalytics.googleTagManagerId,
      event: 'open_product_card_modal',
      product_card: this.activeCard.name
    });
  }

  closeModal(): void {
    this.closebutton.nativeElement.click();
  }

  getCredits() {
    let params = new SSOUrlParametersGenerator(this.authAccount.accountId, this.authAccount.contactId);
    this.SSO.getCredits(params.switchProvider(this.activeCard.name))
      .pipe(
        takeUntil(this.$destroyed),
        finalize(() => {
          if (this.activeCard.credits > 0) this.getRagClass(true);
          else this.getRagClass(false);
        })
      ).subscribe(res => {
        this.activeCard.credits = res;
      });
  }

  getSSO() {
    let params = new SSOUrlParametersGenerator(this.authAccount.accountId, this.authAccount.contactId);
    this.SSO.isActivated(params.switchProvider(this.activeCard.name))
      .pipe(takeUntil(this.$destroyed))
      .subscribe(res => {
        this.activeCard.active = res;
      }, (response: HttpErrorResponse) => {
        if (response.status === 404) {
          this.activeCard.active = false;
        }
      });
  }


  async activateAync() {
    this.modalbtnLoading = true;
    const quote = await this.getQuoteAsync(true);
    await this.Payment.activateAsync(quote.result.grandTotal, this.opportunityObject);
  }

  quoteAmount($event) {
    this.quotePrice = $event;
  }

  async getQuoteAsync(createOpportunity?: boolean): Promise<GetQuoteResponse> {
    createOpportunity = createOpportunity ?? false;

    if(!this.purchasePlantForm.value.numAssets) { return null; }

    try {
      if(!createOpportunity) { this.loadingQuote = true; }

      const quoteRequest: GetQuoteRequest = {
        accountId: this.authAccount.accountId,
        contactId: this.authAccount.contactId,
        productId: this.product.id,
        numberOfEmployees: this.purchasePlantForm.value.numAssets,
        directEmployees: this.account.chas_numberofdirectemployees !== null? this.account.chas_numberofdirectemployees : 0,
        labourOnlySubContractors: this.account.chas_labouronlysubcontractors !== null? this.account.chas_labouronlysubcontractors: 0,
        bonafideSubContractors: this.account.chas_bonafidesubcontractors  !== null? this.account.chas_bonafidesubcontractors : 0,
        createOpportunity: createOpportunity,
        successUrl: this.config.urls.environmentUrl + '/payment-success/membership',
        cancelUrl: this.config.urls.environmentUrl,
        dataSharingAllowed: false,
        customerType: UserTypeCode.CONTRACTOR
      };

      const quote = await this.ProductService.getIndividualQuoteAsync(quoteRequest);

      this.quotePrice = quote.result.totalAmount;
      this.quoteHasVat = quote.result.vatPercent > 0;
      this.opportunityObject = this.ProductService.convertQuoteRequestToOpportunityObject(quoteRequest);
      this.loadingQuote = false;

      return quote;
    }
    catch (error) {
      this.error = 'An unexpected error occurred. Please try again in a few minutes.';
      this.loadingQuote = false;
      this.quotePrice = null;
      return null;
    }
  }
}
