import { createAction, props } from '@ngrx/store';
import { BasketItem } from 'src/app/shared';

export enum BasketItemsActions {
  Add = '[Basket Items] Add',
  AddSuccess = '[Basket Items] Add Successful',
  AddError = '[Basket Items] Add Error',
  Update = '[Basket Items] Update',
  UpdateSuccess = '[Basket Items] Update Successful',
  UpdateError = '[Basket Items] Update Error',
  Remove = '[Basket Items] Remove',
  RemoveSuccess = '[Basket Items] Remove Successful',
  RemoveError = '[Basket Items] Remove Error',
  Clear = '[Basket Items] Clear',
  ClearSuccess = '[Basket Items] Clear Successful',
  ClearError = '[Basket Items] Clear Error',
  Load = '[Basket Items] Load',
  LoadSuccess = '[Basket Items] Load Successful',
  LoadError = '[Basket Items] Load Error'
}

export const basketItemsAdd = createAction(BasketItemsActions.Add, props<{ item: BasketItem }>());
export const basketItemsAddSuccess = createAction(BasketItemsActions.AddSuccess, props<{ payload: BasketItem[] }>());
export const basketItemsAddError = createAction(BasketItemsActions.AddError);

export const basketItemsRemove = createAction(BasketItemsActions.Remove, props<{ item: BasketItem }>());
export const basketItemsRemoveSuccess = createAction(BasketItemsActions.RemoveSuccess, props<{ payload: BasketItem[] }>());
export const basketItemsRemoveError = createAction(BasketItemsActions.RemoveError);

export const basketItemsUpdate = createAction(
  BasketItemsActions.Update,
  props<{
    change: {
      item: BasketItem,
      field: Object
    }
  }>()
);
export const basketItemsUpdateSuccess = createAction(BasketItemsActions.UpdateSuccess, props<{ payload: BasketItem[] }>());
export const basketItemsUpdateError = createAction(BasketItemsActions.UpdateError);

export const basketItemsClear = createAction(BasketItemsActions.Clear);
export const basketItemsClearSuccess = createAction(BasketItemsActions.ClearSuccess, props<{ payload: undefined }>());
export const basketItemsClearError = createAction(BasketItemsActions.ClearError);

export const basketItemsLoad = createAction(BasketItemsActions.Load);
export const basketItemsLoadSuccess = createAction(BasketItemsActions.LoadSuccess, props<{ payload: BasketItem[] }>());
export const basketItemsLoadError = createAction(BasketItemsActions.LoadError);
