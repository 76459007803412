import { createReducer, on } from '@ngrx/store';
import { getStorage } from 'src/app/shared/functions/getBasket.functions';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();

import { basketAccountAddSuccess, basketAccountLoadSuccess, basketAccountRemoveSuccess } from '../actions/basketAccount.actions';

export const initialState = getStorage('newBasketAccount');

const _basketAccountReducer = createReducer(
  initialState,
  on(basketAccountAddSuccess, (state, action) => action.payload),
  on(basketAccountLoadSuccess, (state, action) => action.payload),
  on(basketAccountRemoveSuccess, (state, action) => action.payload),
);

export function basketAccountReducer(state, action) {
  return _basketAccountReducer(state, action);
}
