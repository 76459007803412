import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { BasketSummaryActions, BasketSummaryAdd, BasketSummaryLoad, BasketSummaryRemove } from '../actions/basketSummary.actions';
import { NewBasketService } from '../../services/new-basket/basket.service';

@Injectable()
export class BasketSummaryEffects {
  load$ = createEffect(() => this.actions$.pipe(
    ofType(BasketSummaryLoad),
    mergeMap(() => this.basketService.getOrderSummary().pipe(
      map(account => ({ type: BasketSummaryActions.LoadSuccess, payload: account })),
      catchError(() => of({ type: BasketSummaryActions.LoadError }))
    ))
  ));
  add$ = createEffect(() => this.actions$.pipe(
    ofType(BasketSummaryAdd),
    mergeMap((action) => this.basketService.addOrderSummary(action.orderSummary).pipe(
      map(account => ({ type: BasketSummaryActions.AddSuccess, payload: account })),
      catchError(() => of({ type: BasketSummaryActions.AddError }))
    ))
  ));
  remove$ = createEffect(() => this.actions$.pipe(
    ofType(BasketSummaryRemove),
    mergeMap((action) => this.basketService.removeOrderSummary().pipe(
      map(basket => ({ type: BasketSummaryActions.RemoveSuccess, payload: basket })),
      catchError(() => of({ type: BasketSummaryActions.RemoveError }))
    ))
  ));

  constructor(
    private actions$: Actions,
    private basketService: NewBasketService
  ) { }
}

