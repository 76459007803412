import { Component } from '@angular/core';

@Component({
  selector: 'app-current-year',
  templateUrl: './current-year.component.html',
  styleUrls: ['./current-year.component.scss']
})
export class CurrentYearComponent {

  get year(): string {
    return new Date().getFullYear().toString();
  }

}
