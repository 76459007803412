import { faker } from '@faker-js/faker/locale/en_AU';
import { productCodeTypes } from '..';
import { Territory } from '../constants/territory.enum';

export default class FastFill {
  isNewForm: boolean = false;

  constructor(isNewForm?: boolean) {
    this.isNewForm = isNewForm;
  }

  public getData(territory = Territory.UNITEDKINGDOM) {
    const token = 'Chas1234';
    const date = new Date();
    const today = date.getFullYear().toString() + date.getMonth().toString() + date.getDay().toString();
    const firstName = this.removeSpecialCharacters(faker.person.firstName());
    const lastName = this.removeSpecialCharacters(faker.person.lastName());
    const email = `devtest+${firstName}.${lastName}.${faker.number.int(9999999999)}@chas.co.uk`;
    let data = {
      companyNumber: faker.number
        .int({
          min: 10000000,
          max: 99999999,
        })
        .toString(),
      companyName: `${faker.company.name()} ${today}`,
      street_number: faker.number.int({
        min: 1,
        max: 30,
      }),
      route: faker.location.streetAddress(),
      postal_town: faker.location.city(),
      postal_code: faker.location.zipCode(),
      country_code: territory === Territory.UNITEDKINGDOM ? 'GB' : 'AU',
      firstName: firstName,
      lastName: lastName,
      contactEmail: email,
      contactEmailConfirm: email,
      contactPassword: token,
      contactPasswordConfirm: token,
      contactPhone: faker.phone.number(),
      employees:
        territory === Territory.UNITEDKINGDOM
          ? faker.number.int({
              min: 1,
              max: 30,
            })
          : 0,
      number_of_direct_employees: faker.number.int({
        min: 1,
        max: 30,
      }),
      number_of_labour_only_subcontractors:
        territory === Territory.UNITEDKINGDOM
          ? faker.number.int({
              min: 1,
              max: 30,
            })
          : 0,
      number_of_bona_fide_subcontractors:
        territory === Territory.UNITEDKINGDOM
          ? faker.number.int({
              min: 1,
              max: 30,
            })
          : 0,
      referenceCode: null,
      Bundle: null,
      applicationTypeCode: territory === Territory.UNITEDKINGDOM ? productCodeTypes.CONSTRUCTION : '885570000',
      assessmentTypeCode: '885570000',
    };

    if (this.isNewForm) {
      data['addressFinder'] = faker.location.streetAddress();
      data['canSubmit'] = true;
    }

    return data;
  }

  private removeSpecialCharacters(input: string): string {
    return input.replace(/[^a-zA-Z]/g, '');
  }
}
