import { AccountTrade } from './account-trade';
import { AccountWorkRegion } from './account-work-region';

export interface AccountResponse {
  '@odata.etag': string;
  _chas_accountassessmentsid_value: string;
  _chas_accreditationlevel_value: string;
  _chas_bdm_value: string;
  _chas_clientid_value: string;
  _chas_currentopportunityowner_value: string;
  _chas_membershiptypeid_value: string;
  _chas_ultimateparentaccount_value: string;
  _createdby_value: string;
  _createdbyexternalparty_value: string;
  _createdonbehalfby_value: string;
  _data8_companyid_value: string;
  _defaultpricelevelid_value: string;
  _masterid_value: string;
  _modifiedby_value: string;
  _modifiedbyexternalparty_value: string;
  _modifiedonbehalfby_value: string;
  _msdyn_billingaccount_value: string;
  _msdyn_preferredresource_value: string;
  _msdyn_salestaxcode_value: string;
  _msdyn_serviceterritory_value: string;
  _new_associatedopportunities_value: string;
  _new_bookableresourceassociated_value: string;
  _new_salesowner_value: string;
  _originatingleadid_value: string;
  _ownerid_value: string;
  _owningbusinessunit_value: string;
  _owningteam_value: string;
  _owninguser_value: string;
  _parentaccountid_value: string;
  _preferredequipmentid_value: string;
  _preferredserviceid_value: string;
  _preferredsystemuserid_value: string;
  _primarycontactid_value: string;
  _slaid_value: string;
  _slainvokedid_value: string;
  _territoryid_value: string;
  _transactioncurrencyid_value: string;
  accountcategorycode: string;
  accountclassificationcode: number;
  accountid: string;
  accountnumber: string;
  accountratingcode: number;
  address1_addressid: string;
  address1_addresstypecode: string;
  address1_city: string;
  address1_composite: string;
  address1_country: string;
  address1_county: string;
  address1_fax: string;
  address1_freighttermscode: string;
  address1_latitude: number;
  address1_line1: string;
  address1_line2: string;
  address1_line3: string;
  address1_longitude: number;
  address1_name: string;
  address1_postalcode: string;
  address1_postofficebox: string;
  address1_primarycontactname: string;
  address1_shippingmethodcode: string;
  address1_stateorprovince: string;
  address1_telephone1: string;
  address1_telephone2: string;
  address1_telephone3: string;
  address1_upszone: string;
  address1_utcoffset: string;
  address2_addressid: string;
  address2_addresstypecode: number;
  address2_city: string;
  address2_composite: string;
  address2_country: string;
  address2_county: string;
  address2_fax: string;
  address2_freighttermscode: number;
  address2_latitude: number;
  address2_line1: string;
  address2_line2: string;
  address2_line3: string;
  address2_longitude: number;
  address2_name: string;
  address2_postalcode: string;
  address2_postofficebox: string;
  address2_primarycontactname: string;
  address2_shippingmethodcode: number;
  address2_stateorprovince: string;
  address2_telephone1: string;
  address2_telephone2: string;
  address2_telephone3: string;
  address2_upszone: string;
  address2_utcoffset: string;
  aging30_base: string;
  aging30: string;
  aging60_base: string;
  aging60: string;
  aging90_base: string;
  aging90: string;
  businesstypecode: number;
  chas_accountreference: string;
  chas_accountstatus: string;
  chas_accreditationexpiry: string;
  chas_alertfrequency: string;
  chas_antibriberycorruptionpolicy: boolean;
  chas_applicationstatus: string;
  chas_applicationtype: string;
  chas_assessingclient: boolean;
  chas_assessmentscount_date: string;
  chas_assessmentscount_state: number;
  chas_assessmentscount: number;
  chas_assessorsince: string;
  chas_assessortype: string;
  chas_availableinday: boolean;
  chas_bonafidesubcontractors: number;
  chas_businesssummary: string;
  chas_chasmarketing: boolean;
  chas_clientlist: string;
  chas_clientlist2: string;
  chas_clientlist3: string;
  chas_cloneparentassessments: boolean;
  chas_companystatus: string;
  chas_contractorhidden: boolean;
  chas_creditsafekey: string;
  chas_creditscore: string;
  chas_currentaccreditation: string;
  chas_datecasdataupdated: string;
  chas_dateentered: string;
  chas_department: string;
  chas_disqualifieddirectors: boolean;
  chas_distance: number;
  chas_estimatedturnover_base: string;
  chas_estimatedturnover: string;
  chas_experianscore: string;
  chas_expirydate: string;
  chas_financesystemaccountnumber: string;
  chas_howdidyouhearaboutchas: string;
  chas_hsair: string;
  chas_labouronlysubcontractors: number;
  chas_incorporationdate: string;
  chas_invoicefrequency: string;
  chas_lastaccountsfiled: string;
  chas_lastassesseddate: string;
  chas_lastcertificateemailed: string;
  chas_lastchastraining: string;
  chas_lastupdateddate: string;
  chas_latestcreditlimit_base: number;
  chas_latestcreditlimit: number;
  chas_liquidityratio: string;
  chas_marketo_score: string;
  chas_membershipexpiry: string;
  chas_membershipid: string; 
  chas_minimumpasswordlength: number;
  chas_modernslaverypolicy: boolean;
  chas_nrwstatus: string;
  chas_numberofcontacts_date: string;
  chas_numberofcontacts_state: number;
  chas_numberofcontacts: number;
  chas_numberofdirectemployees: number;
  chas_numberofsuppliers: string;
  chas_openopportunitiescount_date: string;
  chas_openopportunitiescount_state: number;
  chas_openopportunitiescount: number;
  chas_operatingprofit_base: string;
  chas_operatingprofit: string;
  chas_passwordcomplexity: number;
  chas_passwordexpiry: number;
  chas_passwordlock: number;
  chas_passwordreuseprevention: number;
  chas_phonetic: string;
  chas_portaladvisories: boolean;
  chas_preferredssipscheme: string;
  chas_rollupfield_activeassessmentscount_date: string;
  chas_rollupfield_activeassessmentscount_state: number;
  chas_rollupfield_activeassessmentscount: number;
  chas_rollupfield_assessmentsreccount_date: string;
  chas_rollupfield_assessmentsreccount_state: number;
  chas_rollupfield_assessmentsreccount: number;
  chas_rollupfield_inactiveassessmentscount_date: string;
  chas_rollupfield_inactiveassessmentscount_state: number;
  chas_rollupfield_inactiveassessmentscount: number;
  chas_scope: string;
  chas_siccode: string;
  chas_sicdescription: string;
  chas_temppasswordstore: string;
  chas_termsandconditions: string;
  chas_thirdpartycasprovider: string;
  chas_thirdpartymembershipid: string;
  chas_thirdpartymarketing: boolean;
  chas_tradeassociation: boolean;
  chas_turnover: number;
  chas_ultimateparentaccount_number: string;
  chas_ultimateparentaccount: string;
  chas_updatedbyssispackage: boolean;
  chas_useraccountexpiry: number;
  chas_vatregistered: boolean;
  createdon: string;
  creditlimit_base: string;
  creditlimit: string;
  creditonhold: boolean;
  customersizecode: number;
  customertypecode: string;
  description: string;
  donotbulkemail: boolean;
  donotbulkpostalmail: boolean;
  donotemail: boolean;
  donotfax: boolean;
  donotphone: boolean;
  donotpostalmail: boolean;
  donotsendmm: boolean;
  emailaddress1: string;
  emailaddress2: string;
  emailaddress3: string;
  entityimage_timestamp: string;
  entityimage_url: string;
  entityimage: string;
  entityimageid: string;
  exchangerate: number;
  fax: string;
  followemail: boolean;
  ftpsiteurl: string;
  gdit_askedbyclient: boolean;
  gdit_businessnamechanged: boolean;
  gdit_charityregnum: string;
  gdit_clientsname: string;
  gdit_companyhouseregistrationnumber: string;
  gdit_companynumber: string;
  gdit_customertype: number;
  gdit_dateofnamechange: string;
  gdit_datetermsaccepted: string;
  gdit_debtornumber: string;
  gdit_designer: boolean;
  gdit_dunsnumber: string;
  gdit_employersliabilitydocument: string;
  gdit_employersliabilityexcess: string;
  gdit_employersliabilityexpirydate: string;
  gdit_employersliabilityinsurance: string;
  gdit_employersliabilityinsurer: string;
  gdit_employersliabilitylimitforsingleevent: string;
  gdit_employersliabilitylimitofindemnity: string;
  gdit_employersliabilitypolicynumber: string;
  gdit_enforcementorprosecutiondetails: string;
  gdit_enforcementorprosecutions: boolean;
  gdit_hearaboutchas: number;
  gdit_industry: boolean;
  gdit_joiningdate: string;
  gdit_membershipexpiry: string;
  gdit_numberofcontractors: string;
  gdit_numberofsubcontractors: number;
  gdit_organisationtype: number;
  gdit_previousbusinessname: string;
  gdit_principalcontractor: boolean;
  gdit_principaldesigner: boolean;
  gdit_productliabilitydocument: string;
  gdit_productliabilityexcess: string;
  gdit_productliabilityexpirydate: string;
  gdit_productliabilityinsurance: string;
  gdit_productliabilityinsurer: string;
  gdit_productliabilitylimitofindemnity: string;
  gdit_productliabilitypolicynumber: string;
  gdit_professionalindemnitydocument: string;
  gdit_professionalindemnityexcess: string;
  gdit_professionalindemnityexpirydate: string;
  gdit_professionalindemnityinsurance: string;
  gdit_professionalindemnityinsurer: string;
  gdit_professionalindemnitylimitofindemnity: string;
  gdit_professionalindemnitypolicynumber: string;
  gdit_publicliabilitydocument: string;
  gdit_publicliabilityexcess: string;
  gdit_publicliabilityexpirydate: string;
  gdit_publicliabilityinsurance: string;
  gdit_publicliabilityinsurer: string;
  gdit_publicliabilitylimitforsingleevent: string;
  gdit_publicliabilitylimitofindemnity: string;
  gdit_publicliabilitypolicynumber: string;
  gdit_tradeorg: string;
  gdit_tradingas: string;
  gdit_vatregnumber: string;
  gdit_workcategory: string;
  importsequencenumber: string;
  industrycode: string;
  lastonholdtime: string;
  lastusedincampaign: string;
  marketcap_base: string;
  marketcap: string;
  marketingonly: boolean;
  merged: boolean;
  mkt_birthmark: string;
  mkt_watermark: string;
  modifiedon: string;
  msdyn_externalaccountid: string;
  msdyn_taxexempt: boolean;
  msdyn_taxexemptnumber: string;
  msdyn_travelcharge_base: string;
  msdyn_travelcharge: string;
  msdyn_travelchargetype: number;
  msdyn_workorderinstructions: string;
  name: string;
  new_assessmentscnt_date: string;
  new_assessmentscnt_state: number;
  new_assessmentscnt: number;
  new_assessmentscount: string;
  new_assessmentstatusrecords_date: string;
  new_assessmentstatusrecords_state: number;
  new_assessmentstatusrecords: number;
  new_bdmam: string;
  new_clientstatus: string;
  new_countofassessments_date: string;
  new_countofassessments_state: number;
  new_countofassessments: number;
  new_migratedclient: boolean;
  numberofemployees: number;
  onholdtime: string;
  opendeals_date: string;
  opendeals_state: number;
  opendeals: number;
  openrevenue_base: number;
  openrevenue_date: string;
  openrevenue_state: number;
  openrevenue: number;
  overriddencreatedon: string;
  ownershipcode: string;
  parentaccountid_number: string;
  parentaccountid: string;
  participatesinworkflow: boolean;
  paymenttermscode: string;
  preferredappointmentdaycode: string;
  preferredappointmenttimecode: string;
  preferredcontactmethodcode: number;
  primarysatoriid: string;
  primarytwitterid: string;
  processid: string;
  revenue_base: string;
  revenue: string;
  sharesoutstanding: string;
  shippingmethodcode: number;
  sic: string;
  stageid: string;
  statecode: number;
  statuscode: number;
  stockexchange: string;
  teamsfollowed: string;
  telephone1: string;
  telephone2: string;
  telephone3: string;
  territorycode: number;
  tickersymbol: string;
  timespentbymeonemailandmeetings: string;
  timezoneruleversionnumber: number;
  trades: AccountTrade[];
  traversedpath: string;
  utcconversiontimezonecode: string;
  versionnumber: number;
  websiteurl: string;
  workRegions: AccountWorkRegion[];
  yominame: string;
  chas_casdatasharing: boolean;
  incompleteAssessmentCount: number;
};
