<ng-container *ngIf="!displayPackageFeatures">
  <div *ngIf="quickQuote$ | async as quickQuote; else loading">
    <div class="list--products" [ngClass]="{
      'as-grid': grid,
      'display-icons': displayPackageIcons
    }">
      <div class="list__item" *ngIf="quickQuote.standard">
        <span class="list__item-name color--standard">Standard:</span>
        <span class="list__item-value color--standard">{{
          quickQuote.standard.quoteAmount | currency: 'GBP'
        }}*</span>
      </div>
      <div class="list__item" *ngIf="quickQuote.advanced">
        <span class="list__item-name color--advanced">Advanced:</span>
        <span class="list__item-value color--advanced">{{
          quickQuote.advanced.quoteAmount | currency: 'GBP'
        }}*</span>
      </div>
      <div class="list__item" *ngIf="quickQuote.elite">
        <span class="list__item-name color--elite">Elite:</span>
        <span class="list__item-value color--elite">{{
          quickQuote.elite.quoteAmount | currency: 'GBP'
        }}*</span>
      </div>
    </div>
  </div>
  <ng-template #loading>
    <div class="list--products" [ngClass]="{
      'as-grid': grid,
      'display-icons': displayPackageIcons
    }">
      <div class="list__item">
        <span class="list__item-name color--standard">Standard:</span>
        <span class="list__item-value color--standard">{{849 | currency: 'GBP'}}*</span>
      </div>
      <div class="list__item">
        <span class="list__item-name color--advanced">Advanced:</span>
        <span class="list__item-value color--advanced">{{1299 | currency: 'GBP'}}*</span>
      </div>
      <div class="list__item">
        <span class="list__item-name color--elite">Elite:</span>
        <span class="list__item-value color--elite">{{1499 | currency: 'GBP'}}*</span>
      </div>
    </div>
  </ng-template>

</ng-container>

<ng-container *ngIf="displayPackageFeatures">
  <div *ngIf="quickQuote$ | async as quickQuote; else loading">
    <div class="list--products" [ngClass]="{
      'as-grid': grid ,
      'display-features': displayPackageFeatures
    }">
      <span class="list__item-name"></span>
      <span class="list__item-name color--standard">Standard</span>
      <span class="list__item-name color--advanced">Advanced</span>
      <span class="list__item-name color--elite">Elite</span>

      <ng-container *ngFor="let feature of features">
        <span class="list__item-feature">{{feature.name}}</span>
        <span class="list__item-check"><em [ngClass]="feature.standard ? 'fa fa-check' : 'fa fa-times'"></em></span>
        <span class="list__item-check"><em [ngClass]="feature.advanced ? 'fa fa-check' : 'fa fa-times'"></em></span>
        <span class="list__item-check"><em [ngClass]="feature.elite ? 'fa fa-check' : 'fa fa-times'"></em></span>
      </ng-container>

      <span class="list__item-from" *ngFor="let i of [1,2,3,4]; let first = first">{{first ? '' : 'From:'}}</span>

      <span class="list__item-name"></span>
      <span class="list__item-value color--standard" *ngIf="quickQuote.standard">{{quickQuote.standard.quoteAmount | currency: 'GBP'}}*</span>
      <span class="list__item-value color--advanced" *ngIf="quickQuote.advanced">{{quickQuote.advanced.quoteAmount | currency: 'GBP'}}*</span>
      <span class="list__item-value color--elite" *ngIf="quickQuote.elite">{{quickQuote.elite.quoteAmount | currency: 'GBP'}}*</span>
    </div>
  </div>
  <ng-template #loading>
    <div class="list--products" [ngClass]="{
      'as-grid': grid,
      'display-features': displayPackageFeatures
    }">
      <span class="list__item-name"></span>
      <span class="list__item-name color--standard">Standard</span>
      <span class="list__item-name color--advanced">Advanced</span>
      <span class="list__item-name color--elite">Elite</span>

      <ng-container *ngFor="let feature of features">
        <span class="list__item-feature">{{feature.name}}</span>
        <span class="list__item-check"><em [ngClass]="feature.standard ? 'fa fa-check' : 'fa fa-times'"></em></span>
        <span class="list__item-check"><em [ngClass]="feature.advanced ? 'fa fa-check' : 'fa fa-times'"></em></span>
        <span class="list__item-check"><em [ngClass]="feature.elite ? 'fa fa-check' : 'fa fa-times'"></em></span>
      </ng-container>

      <span class="list__item-from" *ngFor="let i of [1,2,3,4]; let first = first">{{first ? '' : 'From:'}}</span>

      <span class="list__item-name"></span>
      <span class="list__item-value color--standard">{{849 | currency: 'GBP'}}*</span>
      <span class="list__item-value color--advanced">{{1299 | currency: 'GBP'}}*</span>
      <span class="list__item-value color--elite">{{1499 | currency: 'GBP'}}*</span>
    </div>
  </ng-template>

</ng-container>