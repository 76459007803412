import { Component, OnInit, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Environment } from 'src/app/shared/classes/environment';
import { EramsProducts } from 'src/app/shared/constants/erams-products.const';
import { Territory } from 'src/app/shared/constants/territory.enum';

@Component({
  selector: 'app-rams-tile',
  templateUrl: './rams-tile.component.html',
  styleUrls: ['./rams-tile.component.scss']
})
export class DashboardRamsTileComponent implements OnInit {
  @ViewChild('closebutton', { static: false }) closebutton;
  @Output() emitRagClass = new EventEmitter();
  modalRef: BsModalRef;
  modalbtnLoading: boolean;
  error: string;
  isLoading: boolean;
  eramsProducts = EramsProducts;
  public isAus = false;

  constructor(
    private modalService: BsModalService
  ) {}

  async ngOnInit(): Promise<void> {
    const env = new Environment();
    const environment = await env.getConfig();
    this.isAus = environment.territory === Territory.AUSTRALIA;

    this.error = null;
    this.emitRagClass.emit(['white', false]);
  }

  get productImage(): string {
    return 'assets/img/product-cards/erams.png';
  }

  doNav(location: string): void {
    window.location.href = location;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal(): void {
    this.closebutton.nativeElement.click();
  }

}
