import { ILead } from '../../features/register-vip/services/vip.service';

export interface GetQuoteResponse {
  orderSummary?: GetOrderSummary;
  request: GetQuoteRequest;
  result: GetQuoteResult;
  error: GetQuoteError;
  lead: ILead;
}

export interface GetQuoteRequest {
  accountId?: string;
  contactId?: string;
  priceLevelId?: string;
  productId?: string;
  sectorType?: string;
  applicationType?: string;
  numberOfEmployees: number;
  directEmployees?: number;
  labourOnlySubContractors?: number;
  bonafideSubContractors?: number;
  discountCode?: string;
  fastTrack?: boolean;
  firFee?: boolean;
  upgrade?: boolean;
  joiningFee?: boolean;
  createOpportunity?: boolean;
  successUrl?: string;
  cancelUrl?: string;
  dataSharingAllowed: boolean;
  customerType: string;
}

export interface GetQuoteResult {
  opportunityId: string;
  productId: string;
  priceLevelName: string;
  productName: string;
  productNumber: string;
  verificationTypes: string[];
  discountCodeValid?: boolean;
  discountPercentage: number;
  productAmount: number;
  discountAmount: number;
  fastTrackAmount: number;
  joiningFeeAmount: number;
  totalAmount: number;
  vatAmount: number;
  vatPercent: number;
  grandTotal: number;
}

export interface GetOrderSummary {
  discountCodeValid: boolean;
  isFree: boolean;
  opportunityId: boolean;
  optionalExtras: boolean;
  orderLines: GetOrderSummaryOrderLine[];
  taxRates: number[];
  totalDiscountAmount: number;
  totalGrossAmount: number;
  totalNetAmount: number;
  totalTaxAmount: number;
}

export interface GetOrderSummaryOrderLine {
  discountAmount: number;
  discountPercent: number;
  grossAmount: number;
  netAmount: number;
  priceLevelId: string;
  priceLevelName: string;
  productId: string;
  productName: string;
  productNumber: string;
  quantity: number;
  subTotalAmount: number;
  taxAmount: number;
  taxRate: number;
  type: number;
  unitAmount: number;
}

export interface GetQuoteError {
  code: number;
  message: string;
}

export interface IAccountContactQuoteResponse {
  accountId: string;
  contactId: string;
  opportunityId: string;
  quotes: QuoteResponse[];
}

export interface QuoteResponse {
  accountId: string;
  contactId: string;
  applicationType: string;
  assessmentType: string;
  sectorType: string;
  bundleName: string;
  orderSummary?: GetOrderSummary;
  request?: GetQuoteRequest;
  result: QuoteResult;
  error?: GetQuoteError;
}

export interface QuoteResult {
  accountId: string;
  contactId: string;
  opportunityId: string;
  productId: string;
  priceLevelName: string;
  productNumber: string;
  productName: string;
  verificationTypes: string[];
  discountCodeValid?: boolean;
  discountPercentage: number;
  productAmount: number;
  discountAmount: number;
  fastTrackAmount: number;
  joiningFeeAmount: number;
  totalAmount: number;
  vatAmount: number;
  vatPercent: number;
  grandTotal: number;
}
