<div class="modal-header">
  <h4 class="modal-title pull-left">Work States</h4>
</div>

<div class="modal-body">
  <form [formGroup]="workAreasForm">
    <!-- Distance from work office. -->
    <div class="form-group row">

      <label for="areaDistance" class="form-label col-lg-8 pt-2">Distance willing to work from registered office</label>
      <div class="col-lg-3">
        <div class="input-group">
          <input type="text"
                 id="areaDistance"
                 formControlName="distance"
                 class="form-control"
                 aria-describedby="distance-addon" />
          <div class="input-group-append">
            <span class="input-group-text" id="distance-addon">{{distanceUnit}}</span>
          </div>
        </div>
      </div>
    </div>

    <p style="margin: 0px,0px;">OR</p>

    <p style="margin-top: 0px;">within the following States:</p>
    <!-- <p style="font-size: 8; margin-top: 0px; color: black;"><i>Note you will need to provide supporting documentation for each region</i></p> -->
    <p style="font-size: 8; font-weight: bold; font-style: italic; margin-top: 0px; color: black;">
      Note you will need to provide supporting documentation for each State
    </p>
    <!-- <p style="font-size: 8; margin-top: 0px; color: red;"><i>Note you will need to provide supporting documentation for each region</i></p>
    <p style="font-size: 8; margin-top: 0px; color: red;"><b><i>Note you will need to provide supporting documentation for each region</i></b></p> -->

    <ng-container formGroupName="areas">

      <div class="text-center" *ngIf="isLoading">
        <app-loading-spinner></app-loading-spinner>
      </div>

      <div class="form-group mb-0" *ngFor="let region of workRegions" [hidden]="isLoading">

        <div class="custom-control custom-checkbox">
          <input type="checkbox"
                 class="custom-control-input"
                 [id]="'region-' + region.code"
                 (change)="setSubWorkareas($event, region)"
                 [checked]="getSelectedInRegion(region) === region.areas.length" />
          <label class="custom-control-label" [attr.for]="'region-' + region.code"></label>
          <button class="btn btn-link text-left p-1"
                  type="button"
                  (click)="expandRegion(region)">
            <i class="fas fa-caret-right" *ngIf="!isRegionExpanded(region)"></i>
            <i class="fas fa-caret-down" *ngIf="isRegionExpanded(region)"></i>
            {{ region.name }}
          </button>
          <small>({{ getSelectedInRegion(region) }})</small>
        </div>
        <div class="mx-4 mb-3" *ngIf="isRegionExpanded(region)">
          <div class="custom-control custom-checkbox"
               *ngFor="let area of region.areas">
            <input type="checkbox"
                   [id]="'area-' + area.code"
                   class="custom-control-input"
                   [formControlName]="area.code"
                   [value]="false" />
            <label class="custom-control-label"
                   [attr.for]="'area-' + area.code">{{ area.name }}</label>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</div>

<div class="modal-footer">
  <alert type="danger" *ngIf="error">{{ error }}</alert>
  <button class="btn btn-link" type="button" (click)="close()">Close</button>
  <button
    class="btn btn-primary"
    type="button"
    (click)="openSaveConfirmationModal(saveConfirmationTemplate)"
    [class.is-loading]="saving"
    [disabled]="!canSave()">
    Save
  </button>
</div>

<ng-template #saveConfirmationTemplate style="border: 2px solid purple; width: 100%;">
  <div style="border: 5px solid purple; width: 100%;">
    <div class="modal-header" style="background-color: white;">
      <h5 class="modal-title" id="modal-title" style="color: purple;">Changing State Selections could Impact Assessment Status!</h5>
    </div>
    <div class="modal-body">
      <p>
        You have added a state that you work in, and this requires an assessment relating to that state. Please check your assessments to ensure you are up to date.
      </p>
      <br>
      <p>Are you sure you wish to continue with the changes made?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="decline()">Cancel</button>
      <button type="button" class="btn btn-danger" (click)="confirm()" style="margin-left: 10px;">Yes</button>
    </div>
  </div>
</ng-template>
