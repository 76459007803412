import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemoveExtensionPipe } from './remove-extension.pipe';
import { ExtensionIconPipe } from './extension-icon.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { AccreditationColourPipe } from './accreditation-colour-pipe';
import { ComplianceStatusColoursPipe } from './compliance-status-colours.pipe';
import { ComplianceStatusPipe } from './compliance-status.pipe';

@NgModule({
  declarations: [
    RemoveExtensionPipe,
    ExtensionIconPipe,
    SafeUrlPipe,
    AccreditationColourPipe,
    ComplianceStatusColoursPipe,
    ComplianceStatusPipe,
  ],
  exports: [
    RemoveExtensionPipe,
    ExtensionIconPipe,
    SafeUrlPipe,
    AccreditationColourPipe,
    ComplianceStatusColoursPipe,
    ComplianceStatusPipe,
  ],
  imports: [CommonModule],
})
export class PipesModule {}
