<a *ngFor="let row of assessments; trackBy: trackByFn" [routerLink]="['/assessment', row.AssessmentID]"
  class="accreditation-icons__link">

  <!-- The assessment image (icon). -->
  <img class="accreditation-icons__image" [src]="getIconUrl(row)" [attr.alt]="row.FullAssessmentName">

  <!-- The status of the assessment. -->
  <span [class]="getStatusClass(row)">
    {{ row.Status }}
  </span>
</a>