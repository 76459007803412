export interface AssessmentQuestion {
  AssessmentId: string;
  QuestionSet: string;
  Version: number;
  QuestionNumber: number;
  QuestionId: string;
  QuestionDescription: string;
  QuestionSection: string;
  AssessorFinding: string; // ?
  QuestionType: string;
  QuestionOrder: number;
  Response: string | null;
  Instance: string | number; // sometimes a string, sometimes a number?
  AnswerId: string | null;
  UploadLink: string | null;
  CreatedOn: string | null;
  DateSent: string | null;
  FileName: string | null;
}
