import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BasketItem, CreateAccountParameters, GetOrderSummary, GetQuoteRequest } from 'src/app/shared';
import { getStorage } from 'src/app/shared/functions/getBasket.functions';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();
@Injectable({
  providedIn: 'root'
})
export class NewBasketService {
  private readonly ITEMS_STORAGE_KEY: string = "newBasketItems";
  private readonly ACCOUNT_STORAGE_KEY: string = "newBasketAccount";
  private readonly QUOTE_STORAGE_KEY: string = "newBasketQuote";
  private readonly SUMMARY_STORAGE_KEY: string = "newBasketSummary";

  public getItems(): Observable<BasketItem[]> {
    return of(this.getStorage(this.ITEMS_STORAGE_KEY));
  }

  public addItem(newItem: BasketItem): Observable<BasketItem[]> {
    let items: BasketItem[] = this.getStorage(this.ITEMS_STORAGE_KEY);
    if(!items) items = [];

    if(items.length === 0) {
      items.push(newItem);
    } else {
      items = [newItem];
    }

    return of(this.setStorage(items, this.ITEMS_STORAGE_KEY));
  }

  public updateItem(updateItem: BasketItem, field: Object ): Observable<BasketItem[]> {
    let items: BasketItem[] = this.getStorage(this.ITEMS_STORAGE_KEY);
    if(!items) return undefined;

    const fieldKeys = Object.keys(field);

    items = items.map((item) => {
      let tmpItem = Object.assign({}, item);
      if(tmpItem.id === updateItem.id) {
        for (const key of fieldKeys) {
          tmpItem[key] = field[key];
        }
      }
      return tmpItem;
    });

    return of(this.setStorage(items, this.ITEMS_STORAGE_KEY));
  }

  public removeItem(removeItem: BasketItem ): Observable<BasketItem[]> {
    let items: BasketItem[] = this.getStorage(this.ITEMS_STORAGE_KEY);
    if(!items) return undefined;

    items = items.filter((item) => item.id === removeItem.id);

    return of(this.setStorage(items, this.ITEMS_STORAGE_KEY));
  }

  public clearItems(): Observable<undefined> {
    this.removeStorage(this.ITEMS_STORAGE_KEY);
    return of(undefined);
  }

  public getAccount(): Observable<CreateAccountParameters> {
    return of(this.getStorage(this.ACCOUNT_STORAGE_KEY));
  }

  public addAccount(newAccount: CreateAccountParameters): Observable<CreateAccountParameters> {
    return of(this.setStorage(newAccount, this.ACCOUNT_STORAGE_KEY));
  }

  public removeAccount(): Observable<undefined> {
    this.removeStorage(this.ACCOUNT_STORAGE_KEY);
    return of(undefined);
  }

  public getQuote(): Observable<GetQuoteRequest> {
    return of(this.getStorage(this.QUOTE_STORAGE_KEY));
  }

  public addQuote(newQuote: GetQuoteRequest): Observable<GetQuoteRequest> {
    return of(this.setStorage(newQuote, this.QUOTE_STORAGE_KEY));
  }

  public removeQuote(): Observable<undefined> {
    this.removeStorage(this.QUOTE_STORAGE_KEY);
    return of(undefined);
  }

  public getOrderSummary(): Observable<GetOrderSummary> {
    return of(this.getStorage(this.SUMMARY_STORAGE_KEY));
  }

  public addOrderSummary(newOrderSummary: GetOrderSummary): Observable<GetOrderSummary> {
    return of(this.setStorage(newOrderSummary, this.SUMMARY_STORAGE_KEY));
  }

  public removeOrderSummary(): Observable<undefined> {
    this.removeStorage(this.SUMMARY_STORAGE_KEY);
    return of(undefined);
  }

  private removeStorage(storageKey: string): void {
    return sessionStorage.removeItem(storageKey);
  }

  private setStorage(item: any, storageKey: string): any {
    sessionStorage.setItem(storageKey, JSON.stringify(item));
    return this.getStorage(storageKey);
  }

  private getStorage(storageKey: string): any {
    return getStorage(storageKey);
  }
}
