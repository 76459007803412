import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, Observable, of } from 'rxjs';
import { RegisterCoreService } from 'src/app/core/services/register-core.service';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { Territory } from 'src/app/shared/constants/territory.enum';
import { CompaniesHouseCompanyDetails, CompaniesHouseCompanyStatus } from '../../../../';
import { IAddressComponent } from '../../form-field.enum';
import { ICompanySearch } from 'src/app/shared/interfaces/company-details-step';

@Component({
  selector: 'app-form-field-text',
  templateUrl: './form-field-text.component.html',
  styleUrls: ['./form-field-text.component.scss']
})
export class FormFieldTextComponent implements OnInit {
  @Input() public title: string;
  @Input() public name: string;
  @Input() public borderBottom?: boolean = false;
  @Input() public description?: string;
  @Input() public type?: string = 'text';
  @Input() public width?: string;
  @Input() public tooltip?: string;
  @Input() public control?: UntypedFormControl;
  @Input() public items?: Array<any>;
  @Input() public optionValueKey?: string = 'id';
  @Input() public optionNameKey?: string = 'name';
  @Input() public readonly: boolean = false;
  @Input() public showLabel: boolean = false;
  @Input() public showError: boolean = true;
  @Input() public refCode: boolean = false;
  @Input() public block: boolean = true;
  @Input() public showTitle: boolean = true;
  @Input() public companyHouseSearch: boolean = false;
  @Input() public isCompanyPrePopulated: boolean = false;
  @Input() public disabled: boolean = false;
  @Output() public found = new EventEmitter<ICompanySearch>();
  @Output() public output = new EventEmitter<any>();
  @Output() public onValidate = new EventEmitter<void>();
  public CompaniesHouseCompanyStatus = CompaniesHouseCompanyStatus;
  public filteredCompanies$: Observable<CompaniesHouseCompanyDetails[]>;
  public hasError = false;
  private environment: IEnvironment;
  private isAus = false;
  public hasEntered = false;
  public filteredCompanies: CompaniesHouseCompanyDetails[];
  public isLoadingDetails = false;

  constructor(public readonly registerCoreService: RegisterCoreService) { }


  public async ngOnInit(): Promise<void> {
    const env = new Environment();
    this.environment = await env.getConfig();
    this.isAus = this.environment.territory === Territory.AUSTRALIA;

    this.isError();

    if(this.companyHouseSearch) {
      if(this.isCompanyPrePopulated) this.filterCompanies();

      this.control.valueChanges.subscribe(() => this.getCompanies())
    }
  }

  public validate(): void {
    this.onValidate.emit(null);
  }

  public isError(): void {
    if(this.title && this.control && this.control.dirty && this.control.invalid) {
      this.showError = true;
    }
  }
  
  private getCompanies() {
    if(this.control.value) {
      if(!this.hasEntered) {
        this.hasEntered = true;
      } else if(this.hasEntered && this.filteredCompanies) {
        this.filteredCompanies = undefined;
        this.hasEntered = false;
      }
      
      this.registerCoreService.getCompanyHouseData(this.control.value).subscribe((data) => {
        this.filteredCompanies = data;
      })
    }
  }

  private filterCompanies() {
    this.registerCoreService.getCompanyHouseData(this.control.value)
    .pipe(map((data) => {
      return data[0];
    })).subscribe((company) => this.chooseCompany(company));
  }


  public chooseCompany(company: CompaniesHouseCompanyDetails): void {
    this.isLoadingDetails = true;
    let companySub = of(company);

    if(this.isAus) {
      companySub = this.registerCoreService.getCompanyDetails(company);
    }

    const subscription = companySub.subscribe((company) => {
      subscription.unsubscribe();

      this.isLoadingDetails = false;

      const result = {
        companyNumber: company.systemId,
        abNumber: company.abNumber,
        acNumber: company.acNumber,
        companyName: company.companyName,
        addressFinder: company.addressLine1,
        route: company.addressLine2,
        street_number: company.addressLine1,
        postal_code: company.postcode,
        postal_town: company.town,
        isAusGstRegistered: company.isAusGstRegistered,
        longitude: company.longitude,
        latitude: company.latitude
      };
      
      this.found.emit(result);
    })
  }
}
