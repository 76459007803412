export const AssessmentQuestionType = {
  CHECKBOXES: 'Checkbox',
  FINISH: 'Finish',
  LABEL: 'Label',
  NEXT: 'Next',
  SUBMIT: 'Submit',
  RADIO_BUTTONS: 'Radio',
  TEXT: 'Text',
  TEXTAREA: 'Textarea',
  FILE_UPLOAD: 'Upload',
  DATE: 'Date',
};
