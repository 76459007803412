import { variants, AssistType, LatestBundleResponse, productCodeTypes } from "..";

export class ApplicationType {
  public getType(bundle: LatestBundleResponse): AssistType {
    let appType = null;
    let isDTS = false;

    const variant = bundle.variant ? bundle.variant.toString() : '';
    switch (variant) {
      case variants.CONTRACTOR_CONSTRUCTION:
        appType = 'Construction';
        break;
      case variants.CONTRACTOR_NON_CONSTRUCTION:
        appType = 'Non-Construction';
        break;
      case variants.DESIGNER:
        appType = 'Designer';
        break;
      case variants.GENERAL:
        appType = 'General';
        break;
      case variants.PRINCIPAL_CONTRACTOR:
        appType = 'Principal Contractor';
        break;
      case variants.PRINCIPAL_DESIGNER:
        appType = 'Principal Designer';
        break;
      case variants.CARE:
        appType = 'Care';
        break;
    }

    isDTS = bundle.productAssessmentType
      && bundle.productAssessmentType.toString() === productCodeTypes.DTS;

    return {
      applicationType: appType,
      isDTS: isDTS
    } as AssistType;
}
}
