<div>
  <mat-form-field appearance="fill" [hidden]="isLoadingDetails">
    <mat-label>{{title}}</mat-label>
    <input
      matInput
      [matAutocomplete]="auto"
      (keyup)="resetResults()"
      [placeholder]="title"
      [formControl]="control"
      [value]="control.value"
      [readonly]="readonly">
    <a mat-raised-button (click)="onInputChange()" color="primary" matSuffix *ngIf="!readonly">
      <span style="display: flex; align-items: center; justify-content: cente;">
        <mat-icon>search</mat-icon>
        <span style="text-transform: uppercase;">search</span>
      </span>
    </a>
    <mat-icon matSuffix *ngIf="readonly">done</mat-icon>
    <mat-error *ngIf="control.touched && control.invalid">
      {{title}} is <strong>required</strong>
    </mat-error>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option disabled *ngIf="hasEntered && !results">
        <div style="display: flex; align-items: center;">
          <mat-spinner diameter="35"></mat-spinner>
          <span style="margin-left:0.5rem;">Loading addresses</span>
        </div>
      </mat-option>
      <mat-option disabled *ngIf="hasEntered && results && results.length === 0">
        No address found
      </mat-option>
      <ng-container *ngIf="hasEntered && results && results.length !== 0">
        <mat-option
          *ngFor="let result of results"
          (click)="chooseAddress(result)">
            {{result.addressSummary}}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>

  <div *ngIf="isLoadingDetails">
    <mat-spinner></mat-spinner>
  </div>
</div>