import { createAction, props } from '@ngrx/store';

import * as AppState from 'src/app/app.state';
import { FeatureFlag } from '../../services/feature-flag';

export interface State extends AppState.State {
  featureFlags: FeatureFlagState[];
}

export interface FeatureFlagState {
  loaded: boolean;
  enabledFeatures: FeatureFlag[];
}

export const initialState: FeatureFlagState = {
  loaded: false,
  enabledFeatures: [],
};

export enum FeatureFlagActions {
  Load = '[Feature Flags] Load',
  LoadSuccess = '[Feature Flags] Load Successful',
  LoadError = '[Feature Flags] Load Error',
}

export const LoadFeatureFlags = createAction(FeatureFlagActions.Load, props<{ applicationName: string }>());
export const LoadFeatureFlagsSuccess = createAction(FeatureFlagActions.LoadSuccess, props<{ featureFlags: FeatureFlag[] }>());
export const LoadFeatureFlagsError = createAction(FeatureFlagActions.LoadError, props<{ error: any }>());
