
export interface UploadRequest {
  ContractorId: string;
  AssessmentId: string;
  Module?: string;
  Answer: string;
  DocumentType: string;
  DocumentSource: string;
  Files: File[];
}

