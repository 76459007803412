<div class="text-center center_loading-spinner" *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>

<section [hidden]="isLoading">

  <div class="row">
    <div class="col-md-12 p3">
      <div class="card_border">

        <div class="row">

          <div class="col-md-4 mt-3 mb-3" *ngFor="let document of otherDocuments;">
            <div class="document_item" (click)="downloadDocument(document)">
              <div class="inner_text">
                <div class="title" name="documents">
                  {{document.name.split(".").slice(0, -1).join(".")}}
                </div>
                <div class="icon">
                  <i class="fas fa-file-download download_icon"></i>
                </div>
              </div>
        </div>
      </div>
    </div>



      </div>
      
    </div>
    </div>


  

</section>