export interface ProductBundleDetails {
  name: string;
  description: string;
  shortDescription: string;
  image: string;
  level: number;
  priceLevelId?: string;
  productNumber?: string;
  benefits?: string[];
  highlight?: string;
}
