
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IRegisterstepfour } from 'src/app/shared/interfaces/new/registerStepFour';
import { getStorage, setStorage } from 'src/app/shared/functions/getBasket.functions';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();

@Injectable({
  providedIn: 'root'
})
export class RegisterstepfourService {
  private readonly Registerstepfour_STORAGE_KEY: string  = 'registerStepFour';

  public getRegisterstepfour(): Observable<IRegisterstepfour> {
    return of(getStorage(this.Registerstepfour_STORAGE_KEY));
  }

  public addRegisterstepfour(newRegisterstepfour: IRegisterstepfour): Observable<IRegisterstepfour> {
    return of(setStorage(newRegisterstepfour, this.Registerstepfour_STORAGE_KEY));
  }

  public removeRegisterstepfour(): Observable<undefined> {
    this.removeStorage(this.Registerstepfour_STORAGE_KEY);
    return of(undefined);
  }

  private removeStorage(storageKey: string): void {
    return sessionStorage.removeItem(storageKey);
  }
}
