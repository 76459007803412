import { Component, OnInit, Input, OnChanges } from '@angular/core';
import jsonUserRoles from 'src/assets/json/user-roles.json';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, AbstractControl } from '@angular/forms';
import { BehaviorSubject, Subject, ReplaySubject } from 'rxjs';
import { last } from 'rxjs/operators';

import { UserRolePrivilege, ContactRole, AccountContact } from '../../../index';

@Component({
  selector: 'app-user-roles-matrix',
  templateUrl: './roles-matrix.component.html',
  styleUrls: ['./roles-matrix.component.scss']
})
export class RolesMatrixComponent implements OnInit, OnChanges {
  @Input() roles: ContactRole[];
  @Input() contact: AccountContact;
  @Input() permissions: string;
  rolesForm: UntypedFormGroup;
  userRoles: string[];
  privileges: { FULL_ACCESS: string; VIEW_ONLY: string; NO_ACCESS: string };

  private formPopulated: Subject<void>;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.userRoles = jsonUserRoles;
    this.privileges = UserRolePrivilege;
    this.rolesForm = this.formBuilder.group({
      roles: this.formBuilder.array([])
    });
    this.formPopulated = new ReplaySubject<void>(1);
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges() {
    this.populateForm();
  }

  /**
   * Creates a reactive form, based on the defined list of roles.
   */
  createForm(): void {
    const rolesRows = this.rolesForm.get('roles') as UntypedFormArray;
    this.userRoles.forEach(role => {
      rolesRows.push(
        this.formBuilder.group({
          name: role,
          privilege: UserRolePrivilege.NO_ACCESS // by default
        })
      );
    });

    this.formPopulated.next();
    this.formPopulated.complete();
  }

  /**
   * Populates the reactive form based on the roles passed to the control.
   */
  populateForm(): void {
    this.formPopulated.pipe(last()).subscribe(() => {
      const rolesRows = this.rolesForm.get('roles') as UntypedFormArray;
      rolesRows.controls.forEach((row: UntypedFormControl) => {
        // Set the respective value.
        const matchingRole = this.roles ? this.roles.find(role => role.name?.toLowerCase() === row.value.name?.toLowerCase()) : null;
        row.patchValue({
          privilege: matchingRole ? matchingRole.privilege : UserRolePrivilege.NO_ACCESS
        });
        //Disable radio button if user has View Only Administartor permissions
        if (this.permissions === 'View Only' || (this.contact && this.contact.isPrimaryContact)) row.disable();
      });
      this.rolesForm.markAsPristine();
    });
  }

  getControls(): AbstractControl[] {
    const fg = this.rolesForm.get('roles') as UntypedFormArray;
    return fg ? fg.controls : null;
  }
}
