import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DocumentResponse } from '../../index';
import { DocumentService } from '../../../core';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent {

  constructor(private documentService: DocumentService) {  }

  @Input() documents: DocumentResponse[];
  @Input() accountId: string;

  @Output() sort = new EventEmitter<any>();

  direction: string;
  column: string;
  sortIcon: string;

  selectedDocuments: any[] = [];

  sortBy(column) {
    this.direction = this.direction === 'asc' ? 'desc' : 'asc';
    this.sortIcon = this.direction === 'asc' ? 'up' : 'down';
    this.column = column;
    this.sort.emit({ column: column, direction: this.direction });
  }

  updateSelection(document, event) {
    if(event.target.checked){
      this.selectedDocuments.push(document);
    } else {
      const index = this.selectedDocuments.indexOf(document);
      this.selectedDocuments.splice(index, 1);
    }
  }

  viewDocument(document: DocumentResponse) {
    this.documentService.viewDocument(this.accountId, document);
  }
}
