import { Address } from '..';

export interface UpdateConfirmationsParams {
  chas_termsandconditions?: string;
  chas_scope?: string;
  chas_chasmarketing?: boolean;
  chas_datasharingallowed?: boolean;
  chas_thirdpartymarketing?: boolean;
  address2_line1?: string;
  address2_line2?: string;
  address2_city?: string;
  address2_postalcode?: string;
}

export interface UpdateAccountConfirmations {
  AcceptedTermsAndConditions?: boolean;
  AcceptedDataSharing?: boolean;
  CorrespondenceAddress?: Address;
}
