import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteMenuComponent } from './site-menu.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AssessmentsMenuModule } from '../assessments-menu/assessments-menu.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [SiteMenuComponent],
  exports: [SiteMenuComponent],
  imports: [AppRoutingModule, CommonModule, AssessmentsMenuModule, SharedModule]
})
export class SiteMenuModule {}
