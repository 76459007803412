import { Injectable } from '@angular/core';
import { monitorService } from './monitor.service';
import { MonitorSeverity } from '../../shared/constants/monitor-severity.const';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  environment: IEnvironment;

  constructor(private Monitor: monitorService) {
    const env = new Environment();
    this.environment = env.getConfigSync();
  }

  handleError(error: any) {
    // const router = this.injector.get(Router);
    if (!this.environment.production) {
      console.error("Error handler:", error);
    }
      
    this.Monitor.exception(error, MonitorSeverity.ERROR);

    // Navigate to an error page(?)
    // router.navigate(['error']);
  }

}
