<div class="modal-header">
    <h4 class="modal-title pull-left">
        Please select documents to be uploaded
    </h4>
</div>
<div class="modal-body">
    <div class="row search-options">
        <div class="col-6">
            <form class="form-group" (submit)="search()" [formGroup]="fgSearch">
                <div class="input-group">
                    <input type="text" class="form-control" id="documentSearch" #documentSearch formControlName="term"
                        placeholder="search for file" />
                    <div class="input-group-append">
                        <button class="btn" type="submit" id="btnSearch">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-4">
            <select class="form-control" id="documentModules" [(ngModel)]="selectedModule" (change)="search()">
                <option hidden [ngValue]="null" selected>Filter by an assessment module</option>
                <option *ngFor="let module of documentModules">{{module}}</option>
            </select>
        </div>
        <div class="col">
            <button class="btn btn-primary" (click)="removeModuleFilter()">Remove filter</button>
        </div>
    </div>
    <app-document-list #documentList [documents]="pageOfResults()" [accountId]="accountId" (sort)="sort($event)">
    </app-document-list>

    <div class="paging">
      <pagination *ngIf="hasEnoughRowsForPagination()"
                  [itemsPerPage]="rowsPerPage"
                  [totalItems]="filteredDocuments.length"
                  (pageChanged)="pageChanged($event)"
                  [(ngModel)]="currentPage"
                  [maxSize]="10"
                  [boundaryLinks]="filteredDocuments.length > boundaryLinksLimit">
        </pagination>
    </div>
</div>
<div class="modal-footer clearfix">
    <button type="button" class="btn btn-secondary float-right mr-4" (click)="upload()" style="margin:0;">
        Upload
    </button>
    <button type="button" class="btn btn-primary float-right" (click)="onClose()" style="margin:0;">
        Cancel
    </button>
</div>
