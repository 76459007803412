import { Component, OnInit, Input } from '@angular/core';
import {
  CompanyDetails,
  productTypes,
  FDN_TBL_DATA,
  UpdateAccountConfirmations,
  GetQuoteRequest,
  UserTypeCode,
  UserTypeCodeAus,
} from '../../index';
import { PaymentService, AccountsService } from '../../../core';
import { Router } from '@angular/router';
import { ProductService } from '../../../core/services/product.service';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { PriceService } from '../../../core/services/price.service';
import { IGetQuoteCreateOpportunityCommand } from '../../interfaces/new/register-data';

@Component({
  selector: 'app-foundation-modal',
  templateUrl: './foundation-modal.component.html',
  styleUrls: ['./foundation-modal.component.scss'],
})
export class FoundationModalComponent implements OnInit {
  tableData: any;
  fdn_loading: boolean;
  acr_loading: boolean;
  acceptTerms: boolean = false;
  errorMessage: string;

  constructor(
    private router: Router,
    private paymentService: PaymentService,
    private accountService: AccountsService,
    private productService: ProductService,
    private priceService: PriceService
  ) {}

  @Input() accountId: string;
  @Input() contactId: string;

  private companyDetails: CompanyDetails;

  isLoading: boolean;
  foundationBundleId = productTypes.CHAS_FOUNDATION;
  private config: IEnvironment;

  async ngOnInit(): Promise<void> {
    const env = new Environment();
    this.config = await env.getConfig();
    this.isLoading = true;
    this.tableData = FDN_TBL_DATA;
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
    if (this.accountId) {
      this.accountService.getCompanyDetails(this.accountId).subscribe((details) => (this.companyDetails = details));
    }
  }

  async handleSelection(foundation) {
    if (foundation) this.fdn_loading = true;
    else this.acr_loading = true;

    if (foundation) {
      const quoteRequest: IGetQuoteCreateOpportunityCommand = {
        applicationType: 885570000,
        assessmentType: '',
        bundleNames: ['CHAS Foundation'],
        checkoutSessionId: '',
        clickDimensionId: '',
        contactId: this.contactId,
        country: '',
        countryCode: '',
        discountCode: '',
        fastTrack: false,
        firFee: false,
        isFIRFee: false,
        isUpgrade: false,
        joiningFee: false,
        membershipLevel: '',
        priceLevelId: '',
        sectorType: 885570006,
        selectedBundle: 'CHAS Foundation',
        accountId: this.accountId,
        numberOfEmployees: this.companyDetails ? this.companyDetails.numberOfEffectiveEmployees : 1,
        directEmployees: this.companyDetails ? this.companyDetails.numberOfDirectEmployees : 1,
        labourOnlySubContractors: this.companyDetails ? this.companyDetails.numberOfLabourOnlySubcontractors : 0,
        bonafideSubContractors: this.companyDetails ? this.companyDetails.numberOfBonaFideSubcontractors : 0,
        createOpportunity: true,
        successUrl: this.config.urls.environmentUrl + '/payment-success/membership',
        cancelUrl: this.config.urls.environmentUrl,
        dataSharingAllowed: false,
        customerType: parseInt(UserTypeCodeAus.CONTRACTOR),
      };

      try {
        await this.acceptTermsAsync();

        var quote = await this.priceService.getQuoteAsync(quoteRequest);
        await this.paymentService.activateAsync(quote.result.grandTotal, quote);

        this.router.navigateByUrl('/dashboard');
      } catch {
        this.errorMessage = 'Something went wrong, please refresh the page and try again.';
        this.isLoading = false;
      }
    } else {
      this.router.navigateByUrl('/membership/foundation');
    }
  }

  toggleTerms(event) {
    this.acceptTerms = event.target.checked;
  }

  async acceptTermsAsync(): Promise<void> {
    const payload: UpdateAccountConfirmations = {
      AcceptedTermsAndConditions: true,
    };
    await this.accountService.updateConfirmationsAsync(this.accountId, payload);
  }
}
