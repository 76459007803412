<ul class="nav flex-column" [class.menu--open]="isMenuVisible">
  <li class="nav-item" *ngIf="showKitchenSink">
    <a class="nav-link" (click)="navigate('/kitchen-sink', 'kitchen-sink')" [ngClass]="{ active: isActive('kitchen-sink') }"
      >Kitchen Sink</a
    >
  </li>

  <li class="nav-item">
    <a class="nav-link" (click)="navigate('/dashboard', 'dashboard')" [ngClass]="{ active: isActive('dashboard') }"
      ><i class="fas fa-th"></i> Dashboard</a
    >
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      (click)="navigate('/company-details', 'companyDetails')"
      *ngIf="accessCompanyDetails"
      [ngClass]="{ active: isActive('companyDetails') }"
    >
      <i class="far fa-building"></i> Company Details
    </a>
  </li>
  <li *ngIf="accessMyClients" class="nav-item">
    <a class="nav-link" (click)="navigate('/my-clients', 'myClients')" [ngClass]="{ active: isActive('myClients') }">
      <i class="far fa-building"></i> My Clients
    </a>
  </li>
  <li *ngIf="isUK" class="nav-item" data-intro="Your Learning Pathway can be accessed using this menu option.">
    <a class="nav-link" (click)="navigate('/learning-pathway', 'learning-pathway')" [ngClass]="{ active: isActive('learning-pathway') }">
      <i class="fas fa-chalkboard-teacher" aria-hidden="true"></i> Learning Pathway
    </a>
  </li>
  <li class="nav-item" *ngIf="accessAssessments" data-intro="Your assessments can be accessed using this menu option." data-step="6">
    <a class="nav-link" (click)="navigate('/assessments', 'assessments')" [ngClass]="{ active: isActive('assessments') }">
      <i class="far fa-edit"></i> My Assessments
    </a>
    <app-assessments-menu [accountId]="accountId" [customerType]="customerType"> </app-assessments-menu>
  </li>
  <li class="nav-item" data-intro="Your uploaded documents can be accessed using this menu option." data-step="7">
    <a class="nav-link" (click)="navigate('/documents', 'documents')" [ngClass]="{ active: isActive('documents') }" *ngIf="accessDocuments">
      <i class="far fa-file"></i> My Documents
    </a>
  </li>

  <li class="nav-item upgrade" data-intro="Upgrade your membership using this menu option." data-step="8" *ngIf="upgradeEnabled">
    <a class="nav-link" (click)="upgradeButtonClick()"> <i class="fas fa-level-up-alt" aria-hidden="true"></i> Upgrade Membership </a>
    <p>
      Become an accredited member today and unlock access to our full suite of membership benefits. Based on the number of employees you
      told us you have, your price to become an accredited member is:
    </p>
    <div>
      <app-quick-quote [grid]="false" [displayPackageIcons]="true"></app-quick-quote>
    </div>
    <div><button class="btn btn-primary" (click)="upgradeButtonClick()">Upgrade Account</button></div>
    <p style="font-size: 0.7rem">
      *Prices exclude VAT, CHAS uses a dynamic pricing system and prices vary depending on accreditation level, the number of employees your
      business has and application type. A joining fee will be applicable for new members.
    </p>
  </li>

  <li class="nav-item">
    <a class="nav-link" *ngIf="!isOpsUserSession" (click)="logout()"><i class="fa fa-sign-out"></i> Log out</a>
  </li>
</ul>
