import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { throwError, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { monitorService } from './monitor.service';
import { MonitorSeverity } from '../../shared/constants/monitor-severity.const';
import { Environment } from 'src/app/shared/classes/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptService implements HttpInterceptor {

  constructor(private Monitor: monitorService) { }
  /**
   *
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const env = new Environment();
    const environment = env.getConfigSync();

    return next.handle(req)
      .pipe(
        catchError((response: HttpErrorResponse) => {

          if (!environment.production) {
            console.log("HTTP intercept:", response.status, response.statusText);
          }

          if (response.status >= 500 || response.status === 0) {
            // Server error (5xx), or connection closed (0).
            this.Monitor.exception(response.error, MonitorSeverity.CRITICAL);
          } else {
            // Client error, most likely a 4xx.
            this.Monitor.exception(response.error, MonitorSeverity.ERROR);
          }
          
          return throwError(response);
        })
      )
  };

}
