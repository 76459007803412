<div class="modal-header">
  <h4 class="modal-title pull-left">Manage Contacts</h4>
</div>

<div class="modal-body">
  <div class="text-center" *ngIf="isLoading; else displayContacts">
    <app-loading-spinner></app-loading-spinner>
  </div>

  <ng-template #displayContacts>
    <form [formGroup]="contactsForm" class="table-responsive">
      <table class="table table-sm">
        <colgroup>
          <col style="width: 3em;" />
          <col style="width: 15em;" />
          <col style="width: 10em;" />
          <col style="width: 12em;" />
          <col style="width: 8em;" />
          <col style="width: 4em;" />
          <col style="width: 2em;" />
          <col style="width: 5em;" />
        </colgroup>
        <thead class="thead-dark">
          <tr>
            <th>Primary?</th>
            <th>Name</th>
            <th>Position</th>
            <th>Email</th>
            <th>Phone</th>
            <th></th>
            <th></th>
            <th class="text-center">Account</th>
          </tr>
        </thead>
        <tbody formArrayName="contacts">
          <tr
            *ngFor="
              let row of contactsForm.get('contacts').controls;
              let i = index
            "
            [formGroupName]="i"
          >
            <th scope="row" class="text-center">
              {{ isPrimaryContact(row) }}
            </th>
            <td>
              <input
                type="text"
                class="form-control form-control-sm name-field"
                formControlName="FirstName"
                *ngIf="isEditing(row); else displayName"
                placeholder="First name"
              />
              <input
                type="text"
                class="form-control form-control-sm name-field"
                formControlName="LastName"
                *ngIf="isEditing(row);"
                placeholder="Last name"
              />
              <ng-template #displayName>{{ getContactName(row) }}</ng-template>
            </td>
            <td>
              <input
                type="text"
                class="form-control form-control-sm"
                formControlName="Position"
                *ngIf="isEditing(row); else displayPosition"
                placeholder="Position"
              />
              <ng-template #displayPosition>{{
                getContactPosition(row)
              }}</ng-template>
            </td>
            <td>
              <input
                type="text"
                class="form-control form-control-sm"
                formControlName="Email"
                *ngIf="isEditing(row) && isNew(row); else displayEmail"
                placeholder="Email"
              />
              <ng-template #displayEmail>{{
                getContactEmail(row)
              }}</ng-template>
            </td>
            <td>
              <input
                type="text"
                class="form-control form-control-sm"
                formControlName="Telephone1"
                *ngIf="isEditing(row); else displayPhone"
                placeholder="Telephone"
              />
              <ng-template #displayPhone>{{
                getContactPhone(row)
              }}</ng-template>
            </td>
            <td>
              <ng-container
                *ngIf="isEditing(row); then editingActions; else displayActions"
              ></ng-container>
              <ng-template #displayActions>
                <!-- Edit the row. -->
                <button
                  class="btn btn-link btn-sm btn-block"
                  type="button"
                  (click)="editRow(row)"
                >
                  Edit
                </button>
              </ng-template>
              <ng-template #editingActions>
                <!-- Save the row. -->
                <button
                  class="btn btn-link btn-sm btn-block"
                  type="button"
                  [disabled]="row.disabled"
                  (click)="saveRow(row)"
                >
                  Save
                </button>
              </ng-template>
            </td>
            <td>
              <!-- Delete the row. -->
              <button
                class="btn btn-danger btn-sm btn-block"
                type="button"
                *ngIf="isEditing(row) && !isNew(row)"
                [disabled]="row.disabled"
                (click)="deleteRow(i)"
              >
                &times;
              </button>
            </td>
            <td>
              <!-- TODO Edit security settings on the user management page. -->
              <button
                class="btn btn-info btn-sm btn-block"
                type="button"
                [disabled]="row.disabled"
                *ngIf="!isNew(row)"
              >
                Manage
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="text-center" *ngIf="isLoading">
        <app-loading-spinner></app-loading-spinner>
      </div>

      <div class="form-group text-right">
        <!-- Add a new contact. -->
        <button
          class="btn btn-secondary"
          type="button"
          (click)="addContactRow()"
          [disabled]="contactsForm.disabled"
        >
          Add contact
        </button>
      </div>
    </form>
  </ng-template>
</div>

<div class="modal-footer">
  <alert type="danger" *ngIf="error">{{ error }}</alert>
  <button class="btn btn-link" type="button" (click)="close()">Close</button>
</div>
