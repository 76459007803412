import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormFieldTextComponent } from '../form-field-text/form-field-text.component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-form-field-companies',
  templateUrl: './form-field-companies.component.html',
  styleUrls: ['./form-field-companies.component.scss'],
})
export class FormFieldCompaniesComponent extends FormFieldTextComponent implements OnInit {
  public results = [];
  public hasEntered = false;
  public isLoadingDetails = false;

  public async onInputChange() {
    if (!this.hasEntered) {
      this.hasEntered = true;
    } else if (this.hasEntered && this.results) {
      this.resetResults();
    }

    this.results = await firstValueFrom(this.registerCoreService.getCompanyHouseData(this.control.value));
    if (this.results.length <= 0) this.output.emit(false);
  }

  public resetResults() {
    this.hasEntered = false;
    this.results = undefined;
  }

  public chooseAddress(address) {
    this.isLoadingDetails = true;

    this.registerCoreService.getCompanyDetails(address).subscribe((result) => {
      this.isLoadingDetails = false;
      this.output.emit({
        companyNumber: result.systemId,
        abNumber: result.abNumber,
        acNumber: result.acNumber,
        companyName: result.companyName,
        addressFinder: result.addressLine1,
        route: result.addressLine2,
        street_number: result.addressLine1,
        postal_code: result.postcode,
        postal_town: result.town,
        isAusGstRegistered: result.isAusGstRegistered,
        longitude: result.longitude,
        latitude: result.latitude,
      });
    });
  }
}
