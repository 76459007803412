import { Store, ActionsSubject } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';

type Action<T = Record<string, any>> = T & {
  type: string;
};

export async function storeDispatchAsync(
  store: Store,
  actionsSubject: ActionsSubject,
  actionTypeSuccess: string,
  action: Action
): Promise<void> {
  store.dispatch(action);
  await firstValueFrom(actionsSubject.pipe(filter((a) => a.type === actionTypeSuccess)));
}
