import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkAreasModalComponent } from './work-areas-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BootstrapComponentsModule } from '../bootstrap/bootstrap.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

@NgModule({
  declarations: [WorkAreasModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BootstrapComponentsModule,
    LoadingSpinnerModule
  ],
  exports: [WorkAreasModalComponent]
})
export class WorkAreasModalModule { }
