
import { createReducer, on } from '@ngrx/store';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();
import { getStorage } from 'src/app/shared/functions/getBasket.functions';
import { registerStepAddSuccess, registerStepLoadSuccess, registerStepRemoveSuccess } from '../actions/registerStep.actions';

export const initialState = getStorage('registerStep');

const _registerStepReducer = createReducer(
  initialState,
  on(registerStepAddSuccess, (state, action) => action.payload),
  on(registerStepLoadSuccess, (state, action) => action.payload),
  on(registerStepRemoveSuccess, (state, action) => action.payload),
);

export function registerStepReducer(state, action) {
  return _registerStepReducer(state, action);
}
