
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { RegisterstepActions, registerStepAdd, registerStepLoad, registerStepRemove } from '../actions/registerStep.actions';
import { RegisterstepService } from '../../services/new/registerStep.service';

@Injectable()
export class RegisterstepEffects {
  load$ = createEffect(() => this.actions$.pipe(
    ofType(registerStepLoad),
    mergeMap(() => this.registerStepService.getRegisterstep().pipe(
      map(registerStep => ({ type: RegisterstepActions.LoadSuccess, payload: registerStep })),
      catchError(() => of({ type: RegisterstepActions.LoadError }))
    ))
  ));
  add$ = createEffect(() => this.actions$.pipe(
    ofType(registerStepAdd),
    mergeMap((action) => this.registerStepService.addRegisterstep(action.registerStep).pipe(
      map(registerStep => ({ type: RegisterstepActions.AddSuccess, payload: registerStep })),
      catchError(() => of({ type: RegisterstepActions.AddError }))
    ))
  ));
  remove$ = createEffect(() => this.actions$.pipe(
    ofType(registerStepRemove),
    mergeMap((action) => this.registerStepService.removeRegisterstep().pipe(
      map(registerStep => ({ type: RegisterstepActions.RemoveSuccess, payload: registerStep })),
      catchError(() => of({ type: RegisterstepActions.RemoveError }))
    ))
  ));

  constructor(
    private actions$: Actions,
    private registerStepService: RegisterstepService
  ) { }
}
