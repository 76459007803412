
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IQuickquote } from 'src/app/shared/interfaces/new/quickQuote';
import { getStorage, setStorage } from 'src/app/shared/functions/getBasket.functions';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();

@Injectable({
  providedIn: 'root'
})
export class QuickquoteService {
  private readonly Quickquote_STORAGE_KEY: string = 'newQuickQuote';

  public getQuickquote(): Observable<IQuickquote> {
    return of(getStorage(this.Quickquote_STORAGE_KEY));
  }

  public addQuickquote(newQuickquote: IQuickquote): Observable<IQuickquote> {
    return of(setStorage(newQuickquote, this.Quickquote_STORAGE_KEY));
  }

  public removeQuickquote(): Observable<undefined> {
    this.removeStorage(this.Quickquote_STORAGE_KEY);
    return of(undefined);
  }

  private removeStorage(storageKey: string): void {
    return sessionStorage.removeItem(storageKey);
  }
}
