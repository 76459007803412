<h1>Membership</h1>

<div class="mt-3" style="max-width: 900px; font-size: 1.1em;">
  <p>Please contact us to upgrade, renew or activate your membership.</p>
  <p>Telephone: {{ phoneNumber }}</p>
  <p>Your membership ID: {{ membershipId }}</p>
</div>

<hr />

<div style="background-color: #EFEFEF; padding: 20px; max-width: 900px;">
  <p class="mb-0">Become a CHAS Member — Ensure your business stays up-to-date on ever-changing risk management. Compliance with CHAS helps get you noticed by top clients, showcasing your quality and care towards your business and workers. Help build your trustworthy and reputable reputation today by becoming a CHAS member and gaining accreditation. Our award-winning team is always on hand to talk you through the process.</p>
</div>
