<!--<div class="text-center" *ngIf="isLoading">-->
<!--  <app-loading-spinner></app-loading-spinner>-->
<!--</div>-->

<div  class="d-flex flex-column">
  <div class="flex-row">
    <h3>Complete Your Membership</h3>
    <p>
      Show your commitment to creating a safer risk free and more compliant world of working for all of your employees by completing your
      membership.
    </p>
  </div>
  <div  class="d-flex flex-column align-content-center justify-content-center productCardContainer">
    <div *ngIf="loading; else loaded">
      <mat-progress-bar mode="query"></mat-progress-bar>
      <p class="w-100 text-center">Generating Quotes... Please Wait.</p>
    </div>
    <ng-template #loaded>
      <mat-stepper orientation="horizontal"  [disableRipple]="true" [headerPosition]="'bottom'" [animationDuration]="'600'" [(selectedIndex)]="stepIndex" #stepper>
        <mat-step>
          <div class="products-wrapper d-flex justify-content-between">
            <div class="card col-5 position-relative" *ngFor="let quote of opportunity.quotes">
              <div class="align-content-center header {{ quote.result.productName === BundleNamesAus.VerifiedContractor ? 'contractor-header' : 'elite-header' }}">
                <h4 class="mx-auto pt-2">
                  <b> {{ quote.result.productName }}</b>
                </h4>
              </div>

              <div class="d-flex flex-row mx-auto mt-4">
                <img *ngIf="quote.result.productName === BundleNamesAus.Elite" style="width: 70px" src="assets/img/product-cards/elite.png" />
                <img *ngIf="quote.result.productName === BundleNamesAus.VerifiedContractor" style="width: 70px" src="assets/img/product-cards/verified_contractor.svg" />
              </div>
              <div class="d-flex flex-column mx-auto col-10" style="min-height:24.5rem;">
                <div class="text-center price mb-2">
                  <h4>Price ${{ quote.result.grandTotal }}</h4>
                </div>
                <p *ngIf="quote.result.productName === BundleNamesAus.VerifiedContractor">Verified Contractor is our entry-level CHAS assessment package, offering contractor accreditation in three areas of risk management: identity, insurance and modern slavery. Gain accreditation, begin prequalifying for contracts and win more work.</p>
                <p *ngIf="quote.result.productName === BundleNamesAus.Elite" class="text-center">
                  CHAS Elite is the elite standard of CHAS accreditation and
                  includes all assessments under Verified Contractor and seven
                  additional risk areas: health and safety (H&S), quality,
                  environmental, equality. diversity and inclusion (EDI), anti-bribery
                  and corruption, corporate social responsibility (CSR) and
                  information security
                </p>
              </div>

              <div class="col-10 text-center position-absolute" style="bottom:0.5rem; left:2rem;">
                <button
                  (click)="selectProduct(quote)"
                  class="mx-auto {{ quote.result.productName === BundleNamesAus.VerifiedContractor ? 'contractorButton' : 'eliteButton' }}"
                  mat-raised-button
                  style="color: white"
                  matStepperNext>
                  SELECT THIS PACKAGE
                </button>
                <p class="mt-2" style="font-size: x-small">Not registered for GST</p>
              </div>

            </div>
          </div>
        </mat-step>
        <mat-step >
          <app-membership-foundation-summary [selectedProduct]="selectedProduct"></app-membership-foundation-summary>
        </mat-step>
        <mat-step>
          <app-membership-foundation-success></app-membership-foundation-success>
        </mat-step>
      </mat-stepper>

    </ng-template>

  </div>
</div>


