export interface AlertQuestion {
  AnswerId: string;
  AssessmentCreatedOn: string; // UTC timestamp.
  AssessmentId: string;
  AssessmentName: string;
  DataType: string;
  DisplayOnly: boolean;
  DisplayType: string;
  FileType: string | null;
  Instance: number;
  Label: string;
  List: null; // ???
  Order: number;
  Question: number;
  QuestionSet: string; // which is a number.
  RelatedQuestion: string;
  Response: boolean,
  Section: string; // which is a number.
  TimeCritical: boolean;
}