/**
 * Validation methods pulled from Ebase, used for validating questionset answers.
 * Each method returns TRUE or FALSE.
 */

export function validateEmail(email: any): boolean {
  var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

/**
 * Validate a postcode (UK format).
 */
export function valid_postcode(postcode: any): boolean {
  var regex = /^[A-Z]{1,2}(([0-9][A-Z]?)|([0-9]{2}))\s[0-9][A-Z]{2}$/i;
  return regex.test(postcode);
}

/**
 * Validate a telephone number.
 */
export function validatePhone(phone: any): boolean {
  let phoneNumber: string = (Array.isArray(phone) ? phone[0] : phone);
  if(!phoneNumber || phoneNumber === null) return false;

  phoneNumber = phoneNumber.toString().replace(/[ -]/g, ''); // remove spaces and hyphens.

  // Then check the format if the number has 10 digits
  var regex = /^(\+?[0-9]{1,})$/;

  return regex.test(phoneNumber);
}

/**
 * Validate that a string only contains letters (and spaces).
 */
export function validatePosition(position: any): boolean {
  // Check the string has letters only
  var regex = /^[-.\/&+\w\s]*$/;

  return regex.test(position);
}

/**
 * Validate that a string only contains letters.
 */
export function validateAlpha(alpha: any): boolean {
  // Check the string has letters only
  var regex = /^[a-z]+$/i;

  return regex.test(alpha);
}

/**
 * Validate that a number contains a maximum number of digits.
 */
export function validateNumber(number: any): boolean {
  var regexPattern = '^[0-9]{1,' + 11 + '}$';
  var regex = new RegExp(regexPattern);

  return regex.test(number);
}

/**
 * Validates that a password meets default requirements.
 * Minimum 8 characters including 3 of one of the following:
 * - Uppercase
 * - Lowercase
 * - Number
 * - Special Character
 */
export function validatePassword(password: any): boolean {
  var regex = /^(?=.{8,})((?=.*[^a-zA-Z\s])(?=.*[a-z])(?=.*[A-Z])|(?=.*[^a-zA-Z0-9\s])(?=.*\d)(?=.*[a-zA-Z])).*$/;
  return regex.test(password);
}

/**
 * Validate that a date is in the future.
 */
export function futureDate(date: any): boolean {
  var valid = true;
  var currentDate = new Date();
  var enterdDate = new Date(date);

  // Check if date selected is greater than the current date
  if (enterdDate < currentDate) {
    valid = false;
  }

  return valid;
}

/**
 * Validate that a date is in the past.
 **/
export function pastDate(dateString: any): boolean {

  // Ensure dateString is actually a string
  dateString = dateString + '';

  // Split date into [0]yyyy [1]mm  [2]dd
  var dateParts = dateString.split("-");

  // month is 0-based, that's why we need dataParts[1] - 1
  var dateValue = new Date(+dateParts[0], parseInt(dateParts[1], 10) - 1, +dateParts[2]);

  var valid = true;
  var currentDate = new Date();

  // Check if date selected is greater than the current date
  if (dateValue > currentDate) {
    valid = false;
  }

  return valid;
}