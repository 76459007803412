import {Injectable} from '@angular/core';
import {CompanyLookup} from '../../shared';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';


@Injectable({
    providedIn: 'root'
})
export class CompaniesService {
  private config: IEnvironment;

  constructor(private httpClient: HttpClient) {
    const env = new Environment();
    this.config = env.getConfigSync();
  }

  lookupCompany(query: string): Observable<CompanyLookup> {
    return this.httpClient
      .get<CompanyLookup>(`${this.config.apiEndpoint.companySearch}/search?request=${query}`);
  }

}
