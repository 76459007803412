import { WorkRegion } from 'src/app/shared/components/work-region-selecter/work-region-selecter.component';
import { UnspscCode } from './work-category';
import { Address } from './address';
import { Contact } from './contact';

export interface AccountUpdateParameters {
  address1_line1?: string;
  address1_line2?: string;
  address1_city?: string;
  address1_postalcode?: string;

  address2_line1?: string;
  address2_line2?: string;
  address2_city?: string;
  address2_postalcode?: string;

  chas_businesssummary?: string;
  chas_ultimateparentaccount_number?: string;
  chas_ultimateparentaccount?: string; // company name
  gdit_hearaboutchas?: string;
  gdit_numberofsubcontractors?: number;
  gdit_organisationtype?: string;
  gdit_tradingas?: string;
  gdit_vatregnumber?: string;
  numberofemployees?: string;
  parentaccountid_number?: string;
  parentaccountid?: string; // company name
  telephone1?: string;
  websiteurl?: string;
}

export interface AccountIdentifier {
  uid: string;
  companyHouseNo: string;
  membershipId: string;
  name: string;
}

export class UpdateAccountParameters {
  constructor(accountId: string, parameters: any) {
    this.Id = <AccountIdentifier>{
      uid: accountId,
      companyHouseNo: parameters.gdit_companyhouseregistrationnumber,
      name: parameters.gdit_tradingas,
      membershipId: parameters.chas_membershipid,
    };
    this.PrimaryTrade = parameters.gdit_primarytrade;
    this.Turnover = parameters.chas_turnover;
    this.Summary = parameters.chas_businesssummary;
    this.VatRegistrationNumber = parameters.gdit_vatregnumber;
    this.HearChas = parameters.gdit_hearaboutchas;
    this.TradingName = parameters.gdit_tradingas;
    this.Website = parameters.websiteurl;
    this.NumberOfEmployees = parameters.numberofemployees;
    this.NumberOfSubContractors = parameters.gdit_numberofsubcontractors;
    this.RegisterAddress = <Address>{
      street1: parameters.address1_line1,
      street2: parameters.address1_line2,
      city: parameters.address1_city,
      postCode: parameters.address1_postalcode,
    };

    this.CorrespondenceAddress = <Address>{
      street1: parameters.address2_line1,
      street2: parameters.address2_line2,
      postCode: parameters.address2_postalcode,
      city: parameters.address2_city,
    };
  }
  Id: AccountIdentifier;
  PrimaryTrade: string;
  Turnover: number;
  Summary: string;
  VatRegistrationNumber: string;
  HearChas: string;
  TradingName: string;
  Website: string;
  NumberOfEmployees: number;
  NumberOfSubContractors: number;
  UltimateParentAccount: AccountIdentifier;
  ParentAccount: AccountIdentifier;
  PrimaryContact: Contact;
  RegisterAddress: Address;
  CorrespondenceAddress: Address;
}

interface IPrimaryContact {
  accountId: {
    uid: string;
    companyHouseNo: string | null;
    creditSafeKey: string | null;
    membershipId: string | null;
    name: string | null;
    membershipType: string | null;
    membershipExpiry: string | null;
    membershipStatusId: string | null;
    accreditationLevelId: string | null;
    accreditationExpiry: string | null;
  };
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  name: string;
}

export interface CompanyDetails {
  id: AccountIdentifier;
  primaryTrade: string;
  turnover: number;
  summary: string;
  vatRegistrationNumber: string;
  hearChas: string;
  hearChasText: string;
  mandatedClient: string;
  printPack: string;
  tradingName: string;
  website: string;
  numberOfDirectEmployees: number;
  numberOfLabourOnlySubcontractors: number;
  numberOfEffectiveEmployees: number;
  numberOfBonaFideSubcontractors: number;
  ultimateParentAccount: AccountIdentifier;
  parentAccount: AccountIdentifier;
  primaryContact: IPrimaryContact;
  registerAddress: Address;
  correspondenceAddress: Address;
  organisationType: string;
  primaryWorkCategory: string;
  workCategories: UnspscCode[];
  size: string;
  abNumber: string;
  acNumber: string;
  isWelcomeModalShown: boolean;
  distance: number;
  workRegions: WorkRegion[];
  states: WorkRegion[];
}
