import { createAction, props } from '@ngrx/store';
import { CurrentUserState } from '../reducers/currentUser.reducer';


export enum CurrentUserActionTypes {
  load = '[Data] Load CurrentUser',
  loadSuccess = '[Data] Load CurrentUser Success',
  updateCurrentUser = '[Data] Update CurrentUser',
  loadCurrentUserFailure = '[Data] Load CurrentUser Failure'
}
export const loadCurrentUser = createAction(
  CurrentUserActionTypes.load,
  props<{ accountId: string }>()
);

export const updateCurrentUser = createAction(
  CurrentUserActionTypes.updateCurrentUser,
  props<{ accountId: string }>()
);

export const loadCurrentUserSuccess = createAction(
  CurrentUserActionTypes.loadSuccess,
  props<{ data: CurrentUserState }>()
);

export const loadCurrentUserFailure = createAction(
  CurrentUserActionTypes.loadCurrentUserFailure,
  props<{ error: any }>()
);
