import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthLoginResolver } from './resolvers/auth-login.resolver';
import { SelectMembershipComponent } from './components/select-membership/select-membership.component';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { FirCardComponent } from './components/fir-card/fir-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BoxComponent } from './components/box/box.component';
import { ButtonComponent } from './components/button/button.component';
import { ProductAdvantagesListComponent } from './components/product-advantages-list/product-advantages-list.component';
import { ReferenceCodeComponent } from './components/reference-code/reference-code.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { QuickQuoteComponent } from './components/quick-quote/quick-quote.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RegisterSelectionComponent } from './components/register-selection/register-selection.component';
import { MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RegisterFastTrackComponent } from './register-fast-track/register-fast-track.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RegisterCarouselComponent } from './components/register-carousel/register-carousel.component';
import { RegisterEmployeesComponent } from './components/register-employees/register-employees.component';
import { RegisterCoreService } from '../core/services/register-core.service';
import { FormFieldTextComponent } from './components/form-field/fields/form-field-text/form-field-text.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FormFieldPlacesComponent } from './components/form-field/fields/form-field-places/form-field-places.component';
import { RegisterSelectionAusComponent } from './components/register-selection-aus/register-selection.component';
import { RegisterEmployeesAusComponent } from './components/register-employees-aus/register-employees.component';
import { FormFieldCompaniesComponent } from './components/form-field/fields/form-field-companies/form-field-companies.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { LoadingSpinnerModule } from './components/loading-spinner/loading-spinner.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    FormFieldComponent,
    SelectMembershipComponent,
    FirCardComponent,
    SelectMembershipComponent,
    BoxComponent,
    ButtonComponent,
    ProductAdvantagesListComponent,
    ReferenceCodeComponent,
    RegisterCarouselComponent,
    RegisterEmployeesComponent,
    RegisterEmployeesAusComponent,
    QuickQuoteComponent,
    RegisterSelectionComponent,
    RegisterSelectionAusComponent,
    RegisterFastTrackComponent,
    FormFieldTextComponent,
    FormFieldPlacesComponent,
    FormFieldCompaniesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRippleModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatListModule,
    LoadingSpinnerModule,
    MatSnackBarModule,
  ],
  providers: [AuthLoginResolver, RegisterCoreService],
  exports: [
    FormFieldComponent,
    FirCardComponent,
    SelectMembershipComponent,
    BoxComponent,
    ButtonComponent,
    ProductAdvantagesListComponent,
    ReferenceCodeComponent,
    QuickQuoteComponent,
    MatProgressSpinnerModule,
    RegisterSelectionComponent,
    RegisterSelectionAusComponent,
    RegisterFastTrackComponent,
    RegisterEmployeesComponent,
    RegisterEmployeesAusComponent,
    RegisterCarouselComponent,
    MatCheckboxModule,
    MatSnackBarModule,
  ],
})
export class SharedModule {}
