import { Component, OnChanges, OnDestroy, Input, OnInit, SimpleChanges } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AccountResponse } from '../../index';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { Store } from '@ngrx/store';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';
import { IEnvironment, Environment } from 'src/app/shared/classes/environment';
import { Territory } from 'src/app/shared/constants/territory.enum';
import { WorkRegion } from '../work-region-selecter/work-region-selecter.component';
import { WorkRegionsService } from '../../../core/services/work-regions.service';
import { state } from '@angular/animations';

@Component({
  selector: 'app-work-areas',
  templateUrl: './work-areas.component.html',
  styleUrls: ['./work-areas.component.scss'],
})
export class WorkAreasComponent {
  @Input() accountId: string;
  @Input() workRegions: WorkRegion[] = [];
  @Input() workStates: WorkRegion[] = [];
  @Input() distance: number = 0;
  private environment: IEnvironment;
  headingText: string = 'Regions';
  stateHeadingText: string = 'States';
  showStates: boolean;
  constructor() {
    const env = new Environment();
    this.environment = env.getConfigSync();
    if (this.environment.territory === Territory.AUSTRALIA) this.showStates = true;
  }

  /**
   * Returns text to use for displaying the working distance.
   */
  get distanceText(): string {
    if (this.environment.territory === Territory.AUSTRALIA) {
      return `${this.distance} km`;
    } else {
      return this.distance === 1 ? `${this.distance} mile` : `${this.distance} miles`;
    }
  }

  /**
   * Returns TRUE if a working distance has been defined.
   */
  get distanceIsSet(): boolean {
    return null !== this.distance;
  }

  /**
   * Returns TRUE if work regions have been defined.
   */
  get regionsAreSet(): boolean {
    return this.workRegions.length > 0;
  }
}
