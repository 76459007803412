import { Injectable } from '@angular/core';
import { ValidationResult } from 'src/app/shared';
import documentValidationJSON from 'src/assets/json/document-allowed-extensions.json';

@Injectable({
  providedIn: 'root',
})
export class DocumentValidationService {
  private maxFileSizeMB: number;
  private allowedExtensions: Set<string>;

  constructor() {
    this.initializeSettings();
  }

  private initializeSettings(): void {
    const { allowedExtensions, fileSizeLimitMb } = documentValidationJSON;
    this.allowedExtensions = new Set(allowedExtensions);
    this.maxFileSizeMB = fileSizeLimitMb;
  }

  async validateFiles(filesData: any): Promise<ValidationResult> {
    const fileDataArray = await Promise.all(
      filesData.map(async (f) => {
        const content = await this.readFileContent(f);
        return { name: f.name, content };
      })
    );

    if (!fileDataArray || fileDataArray.length === 0) {
      return null;
    }

    const validationResult = new ValidationResult();

    for (const file of fileDataArray) {
      this.validateFileExtension(file, validationResult);
      this.validateFileSize(file, validationResult);
    }

    return validationResult;
  }

  private validateFileExtension(file: { name: string; content: string }, validationResult: ValidationResult): void {
    const extension = this.getFileExtension(file.name).toLowerCase();
    if (!this.allowedExtensions.has(extension)) {
      validationResult.addError(`${extension} is an invalid extension for file ${file.name}`);
    }
  }

  private validateFileSize(file: { name: string; content: ArrayBuffer }, validationResult: ValidationResult): void {
    const fileSizeMB: number = this.getFileSizeInMB(file);
    if (fileSizeMB > this.maxFileSizeMB) {
      validationResult.addError(`${file.name} exceeds the maximum file size of ${this.maxFileSizeMB} MB`);
    }
  }

  private getFileExtension(fileName: string): string {
    const parts = fileName.split('.');
    return parts.length > 1 ? parts[parts.length - 1] : '';
  }

  private getFileSizeInMB(file: { name: string; content: ArrayBuffer }): number {
    const fileSizeBytes: number = file.content.byteLength;
    return fileSizeBytes / (1024 * 1024);
  }

  private async readFileContent(file: File): Promise<string | ArrayBuffer> {
    let isBinary = false;
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader: FileReader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (typeof event.target?.result === 'string') {
          // text files (e.g .doc)
          resolve(event.target.result);
        } else if (event.target?.result instanceof ArrayBuffer) {
          // binary files (e.g PDF's)
          isBinary = true;
          resolve(event.target.result);
        } else {
          reject(new Error('Error reading file'));
        }
      };

      reader.onerror = () => {
        reject(new Error('Error reading file'));
      };

      isBinary ? reader.readAsArrayBuffer(file) : reader.readAsText(file);
    });
  }

  getMaximumFileSizeMB(): number {
    return this.maxFileSizeMB;
  }

  getValidationErrorMessage(): string {
    return `Validation error. Please upload only allowed types of files and file size up to ${this.maxFileSizeMB} MB: text files (.txt, .csv), Microsoft Word, Microsoft Excel, Microsoft PowerPoint, PDF, and image files.`;
  }
}
