<div class="modal-dialog modal-video-size">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Introduction to MY CHAS</h4>
      <button type="button" class="btn-close btn-close-white close pull-right" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <iframe #player [src]="videoUrl | safeUrl"
              width="640" height="360"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              title="CHAS Post Purchase Video Oct 2021 V4">
      </iframe>
    </div>
  </div>
</div>
