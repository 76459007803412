import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CheckAuthenticationResponse, NotificationResponse } from 'src/app/shared/index';
import { NotifyDirectiveDirective } from '../../directive/notify-directive.directive';
import { AccountMatchNotificationDisplayComponent } from '../account-match-notification-display/account-match-notification-display.component';
import { DocumentNotificationDisplayComponent } from '../document-notification-display/document-notification-display.component';
import { NotificationDisplayComponent } from '../notification-display-component';

@Component({
  selector: 'app-notification-display-container',
  template: '<ng-template appNotifyDirective></ng-template>',
  styles: [' ']
})
export class NotificationDisplayContainerComponent implements OnInit {

  ngOnInit(): void {
    this.loadComponent();
  }

  @Input() notification: NotificationResponse;
  @Input() user: CheckAuthenticationResponse;

  @ViewChild(NotifyDirectiveDirective, { static: true }) appNotifyDirective: NotifyDirectiveDirective;

  loadComponent(): void {
    const viewContainerRef = this.appNotifyDirective.viewContainerRef;
    viewContainerRef.clear();

    switch(this.notification.area) {
      case 'Account':
        let componentRefs = viewContainerRef.createComponent<NotificationDisplayComponent>(AccountMatchNotificationDisplayComponent);
        componentRefs.instance.data = this.notification;
        break;

      case 'Documents':
      default:
        let componentRef = viewContainerRef.createComponent<NotificationDisplayComponent>(DocumentNotificationDisplayComponent);
        componentRef.instance.data = this.notification;
        break;

    }
  }

}
