import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RolesMatrixComponent } from './roles-matrix/roles-matrix.component';
import { first, finalize, switchMap, catchError, flatMap, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ContactsService } from '../../../core/services/contacts.service';
import { CreateContactParameters, Regex } from '../../index';
import { Router } from '@angular/router';
import { LoadingService } from '../../../core/services/loading-account.service';

@Component({
  selector: 'app-user-information-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class UserCreateModalComponent implements OnInit {
  @ViewChild('rolesMatrix', { static: true }) rolesMatrix: RolesMatrixComponent;

  accountId: string;
  saving: boolean;
  error: string;
  userForm: UntypedFormGroup;
  onSave: () => void; // a reference to a method to call if a new contact is created.

  constructor(
    public bsModalRef: BsModalRef,
    private Contacts: ContactsService,
    public router: Router,
    private loading: LoadingService) {}

  ngOnInit() {
    this.userForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      mobile: new UntypedFormControl('', [Validators.pattern(Regex.PHONE)]),
      position: new UntypedFormControl('')
    });
  }

  /**
   * Closes the dialogue/modal window without saving.
   */
  close(): void {
    this.bsModalRef.hide();
  }

  private readonly DuplicateEmailRepsonse = 'ERR0001';

  /**
   * Create a new contact, assigning the respective roles.
   */
  async createUser(): Promise<void> {
    if (this.saving || this.userForm.invalid) {
      this.userForm.markAllAsTouched();
      this.setErrorMessageToValidation();
      return;
    }

    // Create parameters for creating a new contact.
    const params: CreateContactParameters = {
      AccountId: this.accountId,
      FirstName: this.userForm.value.firstName,
      LastName: this.userForm.value.lastName,
      Email: this.userForm.value.email,
      Telephone: this.userForm.value.mobile,
      Position: this.userForm.value.position
    };

    // Obtain the form values from the roles matrix component.
    const rows = this.rolesMatrix.rolesForm.get('roles').value;

    // First create the contact, then assign that contact the roles.
    this.userForm.disable();
    this.rolesMatrix.rolesForm.disable();
    this.saving = true;
    this.error = null;

    try {
      this.loading.changeContactsLoading(true);
      let contactId = await this.Contacts.createContactWithRolesAsync(params, rows);

      // If the email address used already exists for a contact, error code ERR0001 is returned.
      if (contactId === this.DuplicateEmailRepsonse) {
        throw new Error('The Email address already exists for another contact.');
      }

      await this.Contacts.updateRolesAsync(contactId, rows);

      this.saving = false;
      this.userForm.enable();
      this.rolesMatrix.rolesForm.enable();

      if (this.onSave) this.onSave();
      this.close();
      this.router.navigateByUrl('/admin/users').catch(err => {
        console.error(err);
      });
    }
    catch(error)
    {
      this.error = error.message;
    }
    finally {
      this.userForm.enable();
      this.rolesMatrix.rolesForm.enable();
      this.saving = false;
    }
  }
  setErrorMessageToValidation() {
    this.error = 'Validation failed';
    if(this.userForm.controls.firstName.errors?.required) this.error = "First name cannot be empty.";
    else if(this.userForm.controls.lastName.errors?.required) this.error = "Last name cannot be empty.";
    else if(this.userForm.controls.email.errors?.required) this.error = "Email cannot be empty.";
    else if(this.userForm.controls.email.errors?.email) this.error = "Email is invalid.";
    else if(this.userForm.controls.mobile.errors?.pattern) this.error = "Mobile number is invalid";
  }
}
