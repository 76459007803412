import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeExtension' })
export class RemoveExtensionPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      const dotPosition = value.lastIndexOf('.');
      return dotPosition === -1
        ? value
        : value.substring(0, dotPosition);
    }
    return '';
  }
}

