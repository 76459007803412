import { QuestionInfo } from './question-info';

export interface AccountDocumentResponse {
  AccountId: string;
  AssessmentId: string;
  AssessmentStatus: string;
  DocumentType: string;
  ExpiryDate: string;
  FileDscription: string;
  FileName: string;
  Filelink: string;
  Import_Status: string;
  Instance: string;
  ProductID: string;
  ProductName: string;
  Question: string;
  QuestionSet: string;
  uploaded: string;
}

export interface DocumentResponse {
  name: string;
  safeName: string;
  extension: string;
  type: string;
  uri: string;
  contractorId: string;
  module?: string;
  assessmentIds: string[];
  answerIds: string[];
  uploaded: Date;
  contentType: string;
  isSelected: boolean;
}

export interface DocumentCategoryResponse {
  name: string;
  documents: DocumentResponse[];
}

export interface AddedFiles {
  questionInfo: QuestionInfo;
  uploadedFiles: any[];
}
