<div class="d-flex flex-column align-content-center justify-content-center productCardContainer">
  <div mat-ripple class="card justify-content-around p-2 col-6 mx-auto">
    <div class="align-content-center header">
      <h4 class="mx-auto pt-2">
        <b>Thank you for choosing CHAS!</b>
      </h4>
    </div>

    <div class="d-flex flex-column mx-auto col-12">
      <div class="text-center price mb-2">
        <p>Please wait whilst we finish updating your account.</p>
      </div>
      <button class="mb-2" mat-raised-button color="primary" [routerLink]="['/dashboard']">Return to dashboard</button>
    </div>
  </div>
</div>
