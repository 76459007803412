<div class="modal-header">
  <h4 class="modal-title pull-left">Manage Branches</h4>
</div>

<div class="modal-body">

  <div class="text-center" *ngIf="isLoading; else displayBranchesForm">
    <app-loading-spinner></app-loading-spinner>
  </div>

  <ng-template #displayBranchesForm>
    <form [formGroup]="branchesForm">
      <table class="table table-sm">
        <thead class="thead-dark">
          <tr>
            <th>Address 1</th>
            <th>Address 2</th>
            <th>Address 3</th>
            <th>Town/City</th>
            <th>Postcode</th>
            <th>Site name</th>
            <th></th>
          </tr>
        </thead>
        <tbody formArrayName="addresses">
          <tr *ngFor="let row of branchesForm.get('addresses').controls; let i = index" [formGroupName]="i">
            <td>
              <input type="text" class="form-control form-control-sm" formControlName="line1">
            </td>
            <td>
              <input type="text" class="form-control form-control-sm" formControlName="line2">
            </td>
            <td>
              <input type="text" class="form-control form-control-sm" formControlName="line3">
            </td>
            <td>
              <input type="text" class="form-control form-control-sm" formControlName="city">
            </td>
            <td>
              <input type="text" class="form-control form-control-sm" formControlName="postalcode">
            </td>
            <td>
              <input type="text" class="form-control form-control-sm" formControlName="name">
            </td>
            <td>
              <!-- Delete the address. -->
              <button type="button" class="btn btn-sm btn-danger" (click)="removeBranchAddress(i)">
                <i class="fa fa-fw fa-times"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="form-group text-right">
        <button type="button" class="btn btn-sm btn-secondary" (click)="addBranchAddress()">Add address</button>
      </div>
    </form>
  </ng-template>
</div>

<div class="modal-footer">
  <alert type="danger" *ngIf="error">{{ error }}</alert>
  <button class="btn btn-link" type="button" (click)="close()">Close</button>
  <button class="btn btn-primary" type="button" (click)="updateBranchAddresses()" [class.is-loading]="isSaving" [disabled]="branchesForm.invalid">Save</button>
</div>
