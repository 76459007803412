// Borrowed from https://stackoverflow.com/a/37786142
// This abstract class will allow us to easily add support for ngModel to our own components.

import { forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export abstract class AbstractValueAccessor implements ControlValueAccessor {
    private myValue: any = '';

    get value(): any {
        return this.myValue;
    }

    set value(v: any) {
        if (v !== this.myValue) {
            this.myValue = v;
            this.onChange(v);
        }
    }

    writeValue(value: any) {
        this.myValue = value;
        // warning: comment below if only want to emit on user intervention
        this.onChange(value);
    }

    onChange = _ => {};

    onTouched = () => {};

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
}

export function MakeProvider(type: any) {
    return {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => type),
        multi: true
    };
}
