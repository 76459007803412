import { Component, OnInit, OnDestroy, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { Subject, fromEvent, EMPTY, combineLatest } from 'rxjs';
import { finalize, filter, debounceTime, distinctUntilChanged, map, catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DocumentService, FoundationService } from '../../core';
import { TabDirective } from 'ngx-bootstrap/tabs';
import {
  DocumentResponse,
  DocumentCategoryResponse,
  DocumentCategory,
  DocumentTabs,
  featureFlagNames,
  CheckNewAuthenticationResponse,
} from '../../shared';
import { Store } from '@ngrx/store';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsPageComponent implements OnInit, OnDestroy {
  @ViewChild('modalConfirmDelete', { static: true })
  confirmDelete: TemplateRef<any>;
  @ViewChild('documentSearch', { static: true })
  documentSearch: ElementRef;
  public readonly DocumentTabs = DocumentTabs;

  currentUser: CheckNewAuthenticationResponse;
  error: string;
  isLoading: boolean;
  accountId: string;
  contactId: string;

  currentDocuments: DocumentResponse[];
  currentFilteredDocuments: DocumentResponse[];
  currentDocPage = 1;

  previousDocuments: DocumentResponse[];
  previousFilteredDocuments: DocumentResponse[];
  previousDocPage = 1;

  otherDocuments: DocumentResponse[];
  otherFilteredDocuments: DocumentResponse[];
  otherDocPage = 1;

  learningPathwayDocuments: DocumentResponse[];
  learningPathwayFilteredDocuments: DocumentResponse[];
  learningPathwayDocPage = 1;

  filterTerm: string;
  rowsPerPage: number = 12;
  boundaryLinksLimit = 180;
  maxSize = 15;
  documentModules: any[];
  selectedModule: string = null;

  selectedTab: string;
  isFoundation: boolean = false;

  fgSearch = new UntypedFormGroup({
    term: new UntypedFormControl(''),
  });

  private $destroyed = new Subject<void>();
  private readonly data$ = this.store.select(selectCurrentUserState);
  private currentUserState: CurrentUserState;

  constructor(
    private readonly documentService: DocumentService,
    private readonly foundationService: FoundationService,
    private readonly store: Store
  ) {}

  ngOnInit() {
    fromEvent(this.documentSearch.nativeElement, 'keyup')
      .pipe(filter(Boolean), debounceTime(250), distinctUntilChanged())
      .subscribe((_) => {
        this.search();
      });

    this.data$.subscribe({
      next: (data) => {
        if (data.loaded) {
          this.currentUserState = data;
          this.currentUser = data.currentAccount;
          this.accountId = data.currentAccount.accountId;
          this.contactId = data.currentAccount.contactId;

          combineLatest([this.initSelectedTab(), this.fetchDocuments()]).subscribe((data) => {
            console.log(data);
          });
        }
      },
      error: (response: HttpErrorResponse) => {
        this.error = response.error.Message;
      },
    });
  }

  ngOnDestroy() {
    this.$destroyed.next();
  }

  private initSelectedTab() {
    return this.foundationService.isFoundation(this.accountId, this.contactId).pipe(
      map((value) => {
        this.isFoundation = value;
        if (value) {
          this.selectedTab = DocumentTabs.OtherDocuments;
        } else {
          this.selectedTab = DocumentTabs.CurrentDocuments;
        }
      }),
      catchError((err) => {
        this.selectedTab = DocumentTabs.CurrentDocuments;
        return EMPTY;
      })
    );
  }

  fetchDocuments() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.error = null;

    return this.documentService.getAll(this.currentUser.accountId).pipe(
      map((categories) => {
        categories.forEach((category) => {
          category.documents.forEach((d) => {
            d.uploaded = new Date(d.uploaded);
          });
        });

        return categories;
      }),
      map((categories) => {
        let currentDocsCategory = categories.find((c) => c.name === DocumentCategory.CurrentDocuments);
        if (!currentDocsCategory) {
          currentDocsCategory = {
            documents: [],
          } as DocumentCategoryResponse;
        }
        this.currentDocuments = currentDocsCategory.documents;
        this.currentFilteredDocuments = this.currentDocuments;

        let previousDocsCategory = categories.find((c) => c.name === DocumentCategory.PreviousDocuments);
        if (!previousDocsCategory) {
          previousDocsCategory = {
            documents: [],
          } as DocumentCategoryResponse;
        }
        this.previousDocuments = previousDocsCategory.documents;
        this.previousFilteredDocuments = this.previousDocuments;

        let otherDocsCategory = categories.find((c) => c.name === DocumentCategory.OtherDocuments);
        if (!otherDocsCategory) {
          otherDocsCategory = {
            documents: [],
          } as DocumentCategoryResponse;
        }
        this.otherDocuments = otherDocsCategory.documents;
        this.otherFilteredDocuments = this.otherDocuments;

        let learningPathwayDocsCategory = categories.find((c) => c.name === DocumentCategory.LearningPathway);
        if (!learningPathwayDocsCategory) {
          learningPathwayDocsCategory = {
            documents: [],
          } as DocumentCategoryResponse;
        }
        this.learningPathwayDocuments = learningPathwayDocsCategory.documents;
        this.learningPathwayFilteredDocuments = this.learningPathwayDocuments;

        if (this.selectedTab === DocumentTabs.CurrentDocuments) {
          this.documentModules = this.createModuleFilter(this.currentDocuments);
        }
      }),
      finalize(() => {
        this.isLoading = false;
      })
    );
  }

  createModuleFilter(documents: DocumentResponse[]) {
    const modules = [...new Set(documents.filter((d) => d.module).map((d) => d.module))];
    return modules.sort((a, b) => {
      return a.localeCompare(b);
    });
  }

  removeModuleFilter() {
    this.selectedModule = null;
    this.search();
  }

  pageOfResults(documents: DocumentResponse[]): DocumentResponse[] {
    if (documents) {
      let currentPage = 1;
      if (this.selectedTab === DocumentTabs.CurrentDocuments) {
        currentPage = this.currentDocPage;
      } else if (this.selectedTab === DocumentTabs.PreviousDocuments) {
        currentPage = this.previousDocPage;
      } else if (this.selectedTab === DocumentTabs.OtherDocuments) {
        currentPage = this.otherDocPage;
      } else if (this.selectedTab === DocumentTabs.LearningPathway) {
        currentPage = this.learningPathwayDocPage;
      }
      const startRow = this.rowsPerPage * (currentPage - 1);
      return documents.slice(startRow, startRow + this.rowsPerPage);
    }
    return [];
  }

  onTabSelected($event: TabDirective): void {
    this.selectedTab = $event.heading;

    this.currentFilteredDocuments = this.currentDocuments;
    this.previousFilteredDocuments = this.previousDocuments;
    this.otherFilteredDocuments = this.otherDocuments;

    this.resetPageNumber();

    this.filterTerm = null;
    this.fgSearch.patchValue({ term: null });
    this.selectedModule = null;

    if (this.selectedTab === DocumentTabs.CurrentDocuments) {
      this.documentModules = this.createModuleFilter(this.currentDocuments);
    } else if (this.selectedTab === DocumentTabs.PreviousDocuments) {
      this.documentModules = this.createModuleFilter(this.previousDocuments);
    } else {
      this.documentModules = [];
    }
  }

  /**
   * Called when the user selects a different page.
   */
  pageChanged(event: any): void {
    if (this.selectedTab === DocumentTabs.CurrentDocuments) {
      this.currentDocPage = event.page;
    } else if (this.selectedTab === DocumentTabs.PreviousDocuments) {
      this.previousDocPage = event.page;
    } else if (this.selectedTab === DocumentTabs.OtherDocuments) {
      this.otherDocPage = event.page;
    } else if (this.selectedTab === DocumentTabs.LearningPathway) {
      this.learningPathwayDocPage = event.page;
    }
  }

  /**
   * Returns TRUE if the current user has admin permission set on My Documents.
   */
  get isAdmin() {
    return this.currentUserState.roles.find((role) => role.name === 'My Documents').privilege === 'Full Access';
  }

  hasEnoughRowsForPagination(documents: DocumentResponse[]): boolean {
    if (documents) {
      return documents.length > this.rowsPerPage;
    }
    return false;
  }

  search() {
    this.currentFilteredDocuments = this.currentDocuments;
    this.previousFilteredDocuments = this.previousDocuments;
    this.otherFilteredDocuments = this.otherDocuments;

    this.filterTerm = this.fgSearch.value.term;
    if (this.filterTerm && this.filterTerm.length) {
      this.filterBySearchTerm();
    }

    if (this.selectedModule) {
      this.filterBySelectedModule();
    }
  }

  filterBySearchTerm() {
    this.resetPageNumber();
    if (this.selectedTab === DocumentTabs.CurrentDocuments) {
      this.currentFilteredDocuments = this.currentDocuments.filter(
        (row) => -1 !== row.name.toLowerCase().indexOf(this.filterTerm.toLowerCase())
      );
    } else if (this.selectedTab === DocumentTabs.PreviousDocuments) {
      this.previousFilteredDocuments = this.previousDocuments.filter(
        (row) => -1 !== row.name.toLowerCase().indexOf(this.filterTerm.toLowerCase())
      );
    } else if (this.selectedTab === DocumentTabs.OtherDocuments) {
      this.otherFilteredDocuments = this.otherDocuments.filter(
        (row) => -1 !== row.name.toLowerCase().indexOf(this.filterTerm.toLowerCase())
      );
    } else if (this.selectedTab === DocumentTabs.LearningPathway) {
      this.learningPathwayFilteredDocuments = this.learningPathwayDocuments.filter(
        (row) => -1 !== row.name.toLowerCase().indexOf(this.filterTerm.toLowerCase())
      );
    } else {
      this.currentFilteredDocuments = this.currentDocuments;
      this.previousFilteredDocuments = this.previousDocuments;
      this.otherFilteredDocuments = this.otherDocuments;
    }
  }

  filterBySelectedModule() {
    this.resetPageNumber();

    if (this.selectedTab === DocumentTabs.CurrentDocuments) {
      this.currentFilteredDocuments = this.currentFilteredDocuments.filter((d) => d.module === this.selectedModule);
    }

    if (this.selectedTab === DocumentTabs.PreviousDocuments) {
      this.previousFilteredDocuments = this.previousFilteredDocuments.filter((d) => d.module === this.selectedModule);
    }

    if (this.selectedTab === DocumentTabs.OtherDocuments) {
      this.otherFilteredDocuments = this.otherFilteredDocuments.filter((d) => d.module === this.selectedModule);
    }

    if (this.selectedTab === DocumentTabs.LearningPathway) {
      this.learningPathwayFilteredDocuments = this.learningPathwayFilteredDocuments.filter((d) => d.module === this.selectedModule);
    }
  }

  sort($event) {
    this.resetPageNumber();
    const sortFn = this.getSort($event.column, $event.direction);
    if (this.selectedTab === DocumentTabs.CurrentDocuments) {
      this.currentFilteredDocuments = this.currentFilteredDocuments.sort(sortFn);
    }
    if (this.selectedTab === DocumentTabs.PreviousDocuments) {
      this.previousFilteredDocuments = this.previousFilteredDocuments.sort(sortFn);
    }
    if (this.selectedTab === DocumentTabs.OtherDocuments) {
      this.otherFilteredDocuments = this.otherFilteredDocuments.sort(sortFn);
    }
    if (this.selectedTab === DocumentTabs.LearningPathway) {
      this.learningPathwayFilteredDocuments = this.learningPathwayFilteredDocuments.sort(sortFn);
    }
  }

  private getSort(column, direction) {
    if (column === 'name' || column === 'module' || column === 'type') {
      return (d1, d2) => {
        if (d1[column] === d2[column]) {
          return 0;
        }
        if (d1[column] === null) {
          return 1;
        }
        if (d2[column] === null) {
          return -1;
        }
        if (d1[column]?.toUpperCase() > d2[column]?.toUpperCase()) {
          return direction === 'asc' ? 1 : -1;
        }
        if (d1[column]?.toUpperCase() < d2[column]?.toUpperCase()) {
          return direction === 'asc' ? -1 : 1;
        }
        return 0;
      };
    }

    if (column === 'date') {
      return (d1, d2) => {
        return direction === 'asc' ? d1.uploaded.getTime() - d2.uploaded.getTime() : d2.uploaded.getTime() - d1.uploaded.getTime();
      };
    }
    return null;
  }

  private resetPageNumber(): void {
    this.currentDocPage = 1;
    this.previousDocPage = 1;
    this.otherDocPage = 1;
  }

  get foundationFeatureEnabled(): boolean {
    return true;
  }
}
