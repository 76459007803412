import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BootstrapComponentsModule } from '../bootstrap/bootstrap.module';
import { FoundationModalComponent } from './foundation-modal.component';
import { ProductBundlesModule } from '../product-bundles/product-bundles.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

@NgModule({
  declarations: [FoundationModalComponent],
  imports: [CommonModule, BootstrapComponentsModule, ProductBundlesModule, LoadingSpinnerModule],
  exports: [FoundationModalComponent]
})
export class FoundationModalModule { }


