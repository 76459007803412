<div class="modal-header">
  <h4 class="modal-title pull-left">Upload document</h4>
</div>

<div class="modal-body">
  <div class="text-center" *ngIf="isLoading; else displayQuestions">
    <app-loading-spinner></app-loading-spinner>
  </div>

  <ng-template #displayQuestions>
    <app-questionset-surveyjs *ngIf="questionsetData && answers"
                              [questionsetData]="questionsetData"
                              [questionSetAnswers]="answers"
                              [clientId]="clientId"
                              [assessmentId]="assessmentId"
                              [authUser]="authUser"
                              [overriddenEditable]="true"
                              [progressiveSave]="false"
                              [account]="account"
                              [checkWorkCategories]="false"
                              [isCase]="true"
                              (assessmentClosed)="close()"
                              [notificationId]="notificationId"
                              (assessmentSubmitted)="clearNotification()">
    </app-questionset-surveyjs>
  </ng-template>     
</div>

<div class="modal-footer">
  <button class="btn btn-link" type="button" (click)="close()">Close</button>
</div>
