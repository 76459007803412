<div class="d-flex flex-column align-content-center justify-content-center productCardContainer">
  <div mat-ripple class="card justify-content-around p-2 col-6 mx-auto">
    <div class="align-content-center header">
      <h4 class="mx-auto pt-2">
        <b>Thank you for choosing CHAS!</b>
      </h4>
    </div>

    <div class="d-flex flex-column mx-auto col-12">
      <div class="text-center price mb-2">
        <h4>Please confirm your order:</h4>
      </div>
      <p class="text-center">Membership Package: Elite</p>
      <p class="text-center">Total: {{ opportunity?.quotes[0].orderSummary.totalNetAmount | currency: 'AUD' }}</p>
      <p class="text-center xsmText">Not registered for GST</p>
      <div class="d-flex flex-column mx-auto col-12">
        <div>
          <div>
            <p>
              <strong>Optional:</strong>
              <span>
          If you have been supplied a reference code by a Client or Trade Association please enter here.
        </span>
            </p>
          </div>
          <div class="reference-code">
            <mat-form-field class="w-100">
              <mat-label>Reference Code</mat-label>
              <input matInput type="text" [(ngModel)]="discountCode" [readonly]="isDiscountCodeReadonly">
              <button matSuffix aria-label="Validate" class="chas-button" (click)="validateReferenceCode()">
                Validate
              </button>
            </mat-form-field>
            <p *ngIf="referenceCodeValidating" class="bg-warning text-white w-100 text-center">
              Validating Code...Please Wait.
            </p>
            <p *ngIf="referenceCodeAttempted" class=" w-100 text-center text-white {{ this.referenceCodeValid ? 'bg-success' : 'bg-danger' }}">
              Reference code is {{getReferenceCodeStatus()}}
            </p>
          </div>
        </div>
      </div>
      <p>
        <mat-checkbox class="example-margin" [(ngModel)]="termsAndConditions">
          By placing this order I confirm that I have read and accepted the CHAS
          <a href="https://www.chas.co.uk/contractor-terms-conditions/" target="_blank"> Terms & Conditions </a>
          and
          <a href="https://www.chas.co.uk/privacy-policy/" target="_blank"> Privacy Policy </a>
        </mat-checkbox>
      </p>
    </div>

    <div class="flex-column col-10 mx-auto mt-3 text-center">
      <div>
        <button
          [disabled]="!termsAndConditions"
          mat-raised-button
          color="primary"
          (click)="payAndRedirect()"
          class=""
          type="submit"
          style="margin: 0 auto"
        >
          Pay now by Credit or Debit Card
          <mat-progress-bar *ngIf="processingPayment" mode="buffer" color="primary"></mat-progress-bar>
        </button>
        <br />
        <p class="text-center text-sm-center xsmText">Payments are processed by Stripe</p>
      </div>
      <div class="mx-auto text-center"></div>
    </div>
  </div>
</div>
