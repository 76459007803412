import { Component, EventEmitter, Injectable, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource } from '@angular/material/tree';
import { CollectionViewer, DataSource, SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { map, switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { WrappedNodeExpr } from '@angular/compiler';
import { ThemePalette } from '@angular/material/core';
import { TradeService } from '../../../core/services/trade.service';
import { UnspscCode } from 'src/app/shared/index';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { AccountsService } from '../../../core/services/accounts.service';
import { Store } from '@ngrx/store';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';
import { first, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Territory } from 'src/app/shared/constants/territory.enum';
/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[];
  item: UnspscCode;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: UnspscCode;
  level: number;
  expandable: boolean;
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  setData(x: UnspscCode[]) {
    this.treeData = x.map((item) => {
      return {
        item: item,
        children: item.children.map((child) => {
          return {
            item: child,
            children: child.children.map((gchild) => {
              return { item: gchild, children: [] };
            }),
          };
        }),
      };
    });
    this.dataChange.next([...this.treeData]);
  }
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);
  treeData: TodoItemNode[];
  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }
  public reset() {
    this.dataChange.next(this.treeData);
  }
  public filter(nodes: UnspscCode[]) {
    // Notify the change.
    this.dataChange.next(
      this.treeData
        .filter((leaf) => nodes.filter((node) => node.segmentId === leaf.item.segmentId).length > 0)
        .map((x) => {
          return {
            item: x.item,
            children: x.children
              .filter((child) => nodes.filter((node) => node.familyId === child.item.familyId).length > 0)
              .map((x) => {
                return {
                  item: x.item,
                  children: x.children.filter((grandchild) => nodes.filter((node) => node.classId === grandchild.item.classId).length > 0),
                };
              }),
          };
        })
    );
  }
  constructor() {}
}

@Component({
  selector: 'app-work-area-selecter',
  templateUrl: './work-area-selecter.component.html',
  styleUrls: ['./work-area-selecter.component.scss'],
  providers: [ChecklistDatabase],
})
export class WorkAreaSelecterComponent implements OnInit {
  @Input() data: UnspscCode[] | null;
  @Input() onChange: (selectedState: UnspscCode[]) => void;

  public isAus = false;
  accountId: string;
  error: string;
  saving: boolean;
  isLoaded: boolean = false;
  selectedLargeAmount: boolean = false;
  selectedDescendants: number = 0;
  theme: ThemePalette = 'primary';

  private subject: Subject<string> = new Subject();
  private environment: IEnvironment;
  constructor(private _database: ChecklistDatabase, public bsModalRef: BsModalRef, private tradeService: TradeService) {
    const env = new Environment();
    this.environment = env.getConfigSync();
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  ngOnInit(): void {
    this.isAus = this.environment.territory === Territory.AUSTRALIA;

    this.subject
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        switchMap((value: string) => {
          this.isLoaded = false;
          this.updateFilter(value);
          this.isLoaded = true;
          return value;
        })
      )
      .subscribe((data) => (this.isLoaded = true));
    this.tradeService.getCategories().subscribe((x) => {
      this._database.setData(x);
      if (this.data.length > 0) {
        this.data.forEach((preSelected) => {
          this.nestedNodeMap.forEach((element) => {
            if (element.item.classId === preSelected.classId) {
              this.todoItemSelectionToggle(element);
            }
          });
        });
      }
      this.isLoaded = true;
    });
    // this.data$.subscribe(data => {
    //   if(data.loaded) {
    //     this.isLoaded = false;
    //     this.currentUserState = data;
    //     this.accountId = this.currentUserState.currentAccount.accountId;
    //     this.tradeService.getCategories().subscribe((x) => {
    //       this._database.setData(x);
    //       if(this.currentUserState.companyDetails.workCategories.length>0)
    //       {

    //         this.currentUserState.companyDetails.workCategories.forEach(preSelected=>{
    //           this.nestedNodeMap.forEach(element => {
    //             if(element.item.classId === preSelected.workCode)
    //             {
    //               this.todoItemSelectionToggle(element);
    //             }
    //           });

    //         });

    //     }
    //     this.isLoaded = true;
    //       });
    //   }
    // })
  }

  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);

    if (!this.isAus) {
      this.isLargeAmountSelected(node);
    }
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);

    if (!this.isAus) {
      this.isLargeAmountSelected(node);
    }
  }

  /**
   * Update work categories for the account.
   */
  public updateWorkCategories(): void {
    if (this.saving) {
      return;
    }
    this.error = null;
    this.saving = true;

    let selectionNodes: TodoItemFlatNode[] = this.checklistSelection.selected.sort((a, b) => (a.level < b.level ? -1 : 1));
    let nodesToBroadCase: TodoItemFlatNode[] = [];

    selectionNodes.forEach((x) => {
      if (x.level === 0) {
        nodesToBroadCase.push(x);
      }
      if (x.level === 1) {
        if (nodesToBroadCase.filter((node) => node.item.classId === x.item.segmentId).length === 0) {
          nodesToBroadCase.push(x);
        }
      }
      if (x.level === 2) {
        if (
          nodesToBroadCase.filter((node) => node.item.classId === x.item.segmentId).length === 0 &&
          nodesToBroadCase.filter((node) => node.item.classId === x.item.familyId).length === 0
        ) {
          nodesToBroadCase.push(x);
        }
      }
    });
    let itemsToSave = nodesToBroadCase.map((x) => x.item);
    this.onChange(itemsToSave);
    this.saving = false;
    this.close();
    // this.tradeService.updateAccount(this.accountId, itemsToSave)
    //   .pipe(
    //     first(),
    //     finalize(() => {
    //       this.accountService.refresh();
    //       this.saving = false;
    //     })
    //   )
    //   .subscribe({
    //     next: () => {
    //       this.close();
    //     },
    //     error: (response: HttpErrorResponse) => {
    //       this.error = response.error.Message;
    //     }
    //   });
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
  public close(): void {
    this.bsModalRef.hide();
  }
  getTreeIcon(node: TodoItemFlatNode): string {
    return this.treeControl.isExpanded(node) ? 'fa-chevron-down' : 'fa-chevron-right';
  }

  updateFilter(searchTerm: string) {
    if (!searchTerm || searchTerm.length === 0) {
      this._database.reset();
      return;
    }
    let nodesToShow = [];
    for (let item of this.flatNodeMap.keys()) {
      if (item.item.classTitle.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1) {
        nodesToShow.push(item.item);
      }
    }

    this._database.filter(nodesToShow);
    if (nodesToShow.length > 0) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
      // this.treeControl.expandAll();
    }
  }

  filterChanged(filterText: string) {
    this.subject.next(filterText);
  }

  public isLargeAmountSelected(node: TodoItemFlatNode): void {
    let descendants: TodoItemFlatNode[];

    if (node.level != 2) {
      descendants = this.treeControl.getDescendants(node);
      descendants.forEach((child) => {
        if (this.checklistSelection.isSelected(child)) {
          this.selectedDescendants += 1;
        } else {
          this.selectedDescendants -= 1;
        }
      });
    } else {
      if (this.checklistSelection.isSelected(node)) {
        this.selectedDescendants += 1;
      } else {
        this.selectedDescendants -= 1;
      }
    }

    if (!this.isAus) {
      this.selectedDescendants >= 5 ? (this.selectedLargeAmount = true) : (this.selectedLargeAmount = false);
    } else {
      this.selectedLargeAmount = false;
    }
  }
}
