import { Component, OnInit } from '@angular/core';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';

@Component({
  selector: 'app-splash-page',
  templateUrl: './splash-page.component.html',
  styleUrls: ['./splash-page.component.scss']
})
export class SplashPageComponent implements OnInit {
  private config: IEnvironment;

  constructor(
    private readonly tagManager: GtmService) { }

  ngOnInit(): void {
    const env = new Environment();
    this.config = env.getConfigSync();

    this.tagManager.pushTag({
      id: this.config.googleAnalytics.googleTagManagerId,
      event: 'start My CHAS',
    });
  }

}
