import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionsetSurveyJSComponent } from './questionset-surveyjs.component';
import { SurveyIntroductionComponent } from './survey-introduction/survey-introduction.component';
import { BootstrapComponentsModule } from '../bootstrap/bootstrap.module';
import { DocumentListModalModule} from '../document-list-modal/document-list-modal.module';
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { WorkCategoriesModalModule } from '../work-categories-modal/work-categories-modal.module';
import { QuestionsetSurveyJSDirective } from './questionset-surveyjs.directive';

@NgModule({
  declarations: [
    QuestionsetSurveyJSComponent,
    QuestionsetSurveyJSDirective,
    SurveyIntroductionComponent
  ],
  exports: [QuestionsetSurveyJSComponent],
  imports: [
    DocumentListModalModule,
    CommonModule,
    BootstrapComponentsModule,
    PipesModule,
    WorkCategoriesModalModule
  ]
})
export class QuestionsetSurveyJSModule {}
