<div class="assessment-types mb-3" *ngIf="getAssessmentTypes(); else noTypes">
  <button type="button"
    class="assessment-types__button"
    *ngFor="let aType of assessmentTypes; trackBy: trackByFn"
    (click)="selectType(aType)"
    [class.selected]="isSelected(aType)"
    [disabled]="disabled"
  >
    <img
      class="assessment-types__image"
      [src]="aType.image"
      [attr.alt]="aType.name"
      [attr.title]="aType.name"
    />
  </button>
</div>

<div class="assessment-types__info mb-3" *ngIf="selectedApplicationType">
  <h4 class="h5 assessment-types__info__name">{{ selectedApplicationType.name }}</h4>
  <p class="assessment-types__info__description">{{ selectedApplicationType.description }}</p>
</div>

<ng-template #noTypes>
  <app-nothing>No assessment types available!</app-nothing>
</ng-template>
