import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { BasketQuoteActions, BasketQuoteAdd, BasketQuoteLoad, BasketQuoteRemove } from '../actions/basketQuote.actions';
import { NewBasketService } from '../../services/new-basket/basket.service';

@Injectable()
export class BasketQuoteEffects {
  load$ = createEffect(() => this.actions$.pipe(
    ofType(BasketQuoteLoad),
    mergeMap(() => this.basketService.getQuote().pipe(
      map(quote => ({ type: BasketQuoteActions.LoadSuccess, payload: quote })),
      catchError(() => of({ type: BasketQuoteActions.LoadError }))
    ))
  ));
  add$ = createEffect(() => this.actions$.pipe(
    ofType(BasketQuoteAdd),
    mergeMap((action) => this.basketService.addQuote(action.quote).pipe(
      map(quote => ({ type: BasketQuoteActions.AddSuccess, payload: quote })),
      catchError(() => of({ type: BasketQuoteActions.AddError }))
    ))
  ));
  remove$ = createEffect(() => this.actions$.pipe(
    ofType(BasketQuoteRemove),
    mergeMap((action) => this.basketService.removeQuote().pipe(
      map(basket => ({ type: BasketQuoteActions.RemoveSuccess, payload: basket })),
      catchError(() => of({ type: BasketQuoteActions.RemoveError }))
    ))
  ));

  constructor(
    private actions$: Actions,
    private basketService: NewBasketService
  ) { }
}
