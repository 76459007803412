import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkAreasComponent } from './work-areas.component';

@NgModule({
  declarations: [WorkAreasComponent],
  imports: [
    CommonModule
  ],
  exports: [WorkAreasComponent]
})
export class WorkAreasModule { }
