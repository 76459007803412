
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IRegisterstep } from 'src/app/shared/interfaces/new/registerStep';
import { getStorage, setStorage } from 'src/app/shared/functions/getBasket.functions';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();

@Injectable({
  providedIn: 'root'
})
export class RegisterstepService {
  private readonly Registerstep_STORAGE_KEY: string = 'registerStep';

  public getRegisterstep(): Observable<IRegisterstep> {
    return of(getStorage(this.Registerstep_STORAGE_KEY));
  }

  public addRegisterstep(newRegisterstep: IRegisterstep): Observable<IRegisterstep> {
    return of(setStorage(newRegisterstep, this.Registerstep_STORAGE_KEY, false));
  }

  public removeRegisterstep(): Observable<undefined> {
    this.removeStorage(this.Registerstep_STORAGE_KEY);
    return of(undefined);
  }

  private removeStorage(storageKey: string): void {
    return sessionStorage.removeItem(storageKey);
  }
}
