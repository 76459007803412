import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { QuickquoteActions } from 'src/app/core/ngrx/actions/quickQuote.actions';
import { selectQuickQuote } from 'src/app/core/ngrx/selectors/quickQuote.selectors';
import { Environment, IEnvironment } from '../../classes/environment';
import { Territory } from '../../constants/territory.enum';
import { getCurrency } from '../../functions/currency.functions';
import { IQuickquote } from '../../interfaces/new/quickQuote';
import { BundleNames } from '../select-membership/select-membership.component';

@Component({
  selector: 'app-register-selection',
  templateUrl: './register-selection.component.html',
  styleUrls: ['./register-selection.component.scss']
})
export class RegisterSelectionComponent {
  @Input() public readonly reduced: boolean = false;
  @Input() public readonly form: UntypedFormGroup;
  @Input() public readonly bundles: any;
  @Input() public readonly bundleName: string;
  @Input() public readonly isCare: boolean = false;
  public readonly quickQuote$: Observable<IQuickquote> = this.store.select(selectQuickQuote);
  public readonly bundleNames = BundleNames;
  public readonly loading: boolean = true;
  public showStandardBundle: boolean = false;
  public showAdvancedBundle: boolean = false;
  public showEliteBundle: boolean = false;
  public quoteAmounts: any = { Standard: '0.00', Advanced: '0.00', Elite: '0.00' }
  public environment: IEnvironment;
  public Territory = Territory;
  public currencyCode = 'GBP';

  constructor(private readonly store: Store) { }

  async ngOnInit(): Promise<void> {
    const env = new Environment();
    this.environment = await env.getConfig();

    this.currencyCode = getCurrency(this.environment.territory);

    this.store.dispatch({
      type: QuickquoteActions.Load
    });

    this.quickQuote$.subscribe((bundles) => {
      if(!bundles) return;

      this.showEliteBundle = (bundles && bundles[this.bundleNames.Elite.toLocaleLowerCase()]) ? true : false;
      this.showAdvancedBundle = bundles && bundles[this.bundleNames.Advanced.toLocaleLowerCase()] ? true : false;
      this.showStandardBundle = bundles && bundles[this.bundleNames.Standard.toLocaleLowerCase()] ? true : false;

      if(bundles && bundles[this.bundleNames.Elite.toLocaleLowerCase()] && !this.isCare) {
        this.showEliteBundle = true;
      }

      this.quoteAmounts.Standard = this.showStandardBundle ? bundles[this.bundleNames.Standard.toLocaleLowerCase()].lastQuoteResult.result.totalAmount : '0.00';
      this.quoteAmounts.Advanced = this.showAdvancedBundle ? bundles[this.bundleNames.Advanced.toLocaleLowerCase()].lastQuoteResult.result.totalAmount : '0.00';
      this.quoteAmounts.Elite = this.showEliteBundle ? bundles[this.bundleNames.Elite.toLocaleLowerCase()].lastQuoteResult.result.totalAmount : '0.00';
    });
  }

  public selectBundle(bundle: string) {
    this.form.patchValue({
      BundleName: bundle
    });
  }
}
