<div class="register-form">
  <div class="mb-5">
    <h1 class="h2 mb-1">New CHAS Contractor Registration</h1>
    <a routerLink="/login">Already registered with CHAS?</a>
  </div>

  <div class="row mb-4">
    <div class="col-lg-9">
      <h2 class="h4">First time using the new system?</h2>
      <p>Simply follow the steps below to register your account:</p>

      <ol>
        <li>Enter your previously registered email address in the field below (Email that you used for your most
          recent application).</li>
        <li>Click COMPLETE REGISTRATION. (This will send an email to your registered address with a link to set a new
          password).</li>
        <li>Open the verification email and click the link to set a new password.</li>
        <li>Set new password.</li>
        <li>Login to the new system with your email address and the new password.</li>
      </ol>
    </div>

    <div class="col-lg-3">
      <div class="panel bg-primary text-white p-2">
        <h3 class="h5">Need Help?</h3>
        <p class="mb-0">Contact a member of the team on 020 8545 3838, who will be happy to help.</p>
      </div>
    </div>
  </div>

  <!-- The registration form. -->
  <form class="row justify-content-md-center border-top pt-5" [formGroup]="registerForm"
    (submit)="completeRegistration()">
    <div class="col-lg-8">

      <alert type="danger" *ngIf="errorMessage">{{ errorMessage }}</alert>
      <alert type="info" *ngIf="infoMessage">{{ infoMessage }}</alert>

      <!-- Just one field, the email address. -->
      <div class="form-group row">
        <label class="col-sm-5 col-md-4 form-label pt-2" for="username">Email Address</label>
        <div class="col-sm-7 col-md-8">
          <input id="username" formControlName="username" class="form-control" type="text" maxlength="255">
        </div>
      </div>

      <!-- Submit button. -->
      <div class="form-group text-right">
        <button class="btn btn-primary btn-lg" [class.is-loading]="isCompleting" type="submit">Complete Registration</button>
      </div>

    </div>
  </form>

</div>