import { SSOUrlParameters } from './../interfaces/sso-url-parameters';
import { Environment, IEnvironment } from './environment';

export class SSOUrlParametersGenerator {
  params: SSOUrlParameters;
  contractorId: string;
  contactId: string;
  private config: IEnvironment;

  constructor(contractorId: string, contactId: string) {
    const env = new Environment();
    this.config = env.getConfigSync();

    this.contractorId = contractorId;
    this.contactId = contactId;    
  }


  businessShield(): SSOUrlParameters {
    return {
      Type: 'Business_Shield',
      ContractorId: this.contractorId,
      ContactId: this.contactId,
      SSOUrl: `${this.config.apiEndpoint.businessShield}/sso`,
      CreditsUrls: `${this.config.apiEndpoint.businessShield}/accounts/${this.contractorId}/contacts/${this.contactId}/credits`      
    }
  }


  chasPeople(): SSOUrlParameters {
    return {
      Type: 'CHAS_People',
      ContractorId: this.contractorId,
      ContactId: this.contactId,      
      SSOUrl: `${this.config.apiEndpoint.chasPeople}/sso`,
      CreditsUrls: `${this.config.apiEndpoint.chasPeople}/accounts/${this.contractorId}/contacts/${this.contactId}/credits`
    }
  }

  humanFocus(): SSOUrlParameters {
    return {
      Type: 'CHAS_Human_Focus',
      ContractorId: this.contractorId,
      ContactId: this.contactId,      
      SSOUrl: `${this.config.apiEndpoint.humanFocus}/sso`,
      CreditsUrls: `${this.config.apiEndpoint.humanFocus}/accounts/${this.contractorId}/contacts/${this.contactId}/credits`
    }
  }

  chasPlant(): SSOUrlParameters {
    return {
      Type: 'CHAS_Plant',
      ContractorId: this.contractorId,
      ContactId: this.contactId,
      SSOUrl: `${this.config.apiEndpoint.chasPlant}/sso`,
      CreditsUrls: `${this.config.apiEndpoint.chasPlant}/accounts/${this.contractorId}/contacts/${this.contactId}/credits`
    }
  }

  casThirdParty(): SSOUrlParameters {
    return {
      Type: 'CAS_Third_Party',
      ContractorId: this.contractorId,
      ContactId: this.contactId,
      SSOUrl: null,
      CreditsUrls: null
    }
  }

  switchProvider(provider): SSOUrlParameters {
    switch (provider) {
      case 'Business_Shield': return this.businessShield();
      case 'CHAS_People': return this.chasPeople();
      case 'CHAS_Plant': return this.chasPlant();
      case 'CHAS_Human_Focus': return this.humanFocus();
      case 'CAS_Third_Party': return this.casThirdParty();
    }
  }
}
