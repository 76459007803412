import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map, shareReplay} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {RetrieveProductBundlesResponse, RetrieveProductsResponse, BranchAddress, UpdateConfirmationsParams} from '../../shared';
import { IEnvironment, Environment } from 'src/app/shared/classes/environment';
import { Territory } from 'src/app/shared/constants/territory.enum';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {
  private environment: IEnvironment;
  private isAus = false;
  
  constructor(private http: HttpClient) {
    const env = new Environment();
    this.environment = env.getConfigSync();
    this.isAus = this.environment.territory === Territory.AUSTRALIA;
  }

  /**
   * Fetches a list of available product bundles from the API.
   */
  getProductBundles(referenceCode?: string): Observable<RetrieveProductBundlesResponse[]> {
    let url = `${this.environment.apiEndpoint.products}/public/products/bundles`;
    if (referenceCode) {
      url = url + `?referenceCode=${referenceCode}`;
    }
    return this.http.get(url).pipe(
      map(data => data as RetrieveProductBundlesResponse[]),
      shareReplay(1)
    );
  }

  /**
   * Fetches a list of all available product bundles from the API.
   */
  getAllProducts(
    referenceCode?: string
  ): Observable<RetrieveProductsResponse[]> {
    const params = {referenceCode};
    return this.http
      .get(`${this.environment.apiEndpoint.products}/products`, {
        params
      })
      .pipe(
        map(data => data as RetrieveProductsResponse[]),
        shareReplay(1)
      );
  }

  /**
   * Fetches a list of branch addresses for the specified account.
   */
  getBranchAddresses(accountId: string): Observable<BranchAddress[]> {
    const apiUrl = `${this.environment.apiEndpoint.newChasAPIv1}/accounts/${accountId}/branch-addresses`;
    return this.http
      .get(apiUrl)
      .pipe(map((data: any[]) => data.map(address => {
        return {
          customeraddressid: address.id,
          name: address.name,
          line1: address.street1,
          line2: address.street2,
          line3: address.street3,
          city: address.city,
          county: address.county,
          stateorprovince: address.state,
          postalcode: address.postCode,
          country: address.country
        } as BranchAddress;
      }) as BranchAddress[]));
  }

  /**
   * Update branch addresses for an account.
   */
  updateBranchAddresses(
    accountId: string,
    addresses: BranchAddress[]
  ): Observable<any> {
    const observables = addresses.map(address => {
      return {
        id: address.customeraddressid,
        street1: address.line1,
        street2: address.line2,
        street3: address.line3,
        name: address.name,
        city: address.city,
        county: address.county,
        state: address.stateorprovince,
        postCode: address.postalcode,
        country: address.country
      };
    });
    // If there is a customer address ID for the address, perform an update - otherwise create the address.
    const url = `${this.environment.apiEndpoint.newChasAPIv1}/accounts/${accountId}/branch-addresses`;
    return this.http.put(url, observables);
  }


  /**
   * Remove a specific branch address.
   */
  removeBranchAddress(accountId: string, address: BranchAddress): Observable<any> {
    if (address.customeraddressid) {
      return this.http.delete(
        `${this.environment.apiEndpoint.newChasAPIv1}/accounts/${accountId}/branch-addresses/${address.customeraddressid}`,
        {}
      );
    }
    return of(null);
  }

  /**
   * Update the working distance (in miles) for an account.
   */
  updateWorkDistance(accountId: string, distance: number): Observable<any> {
    return this.http.put(
      `${this.environment.apiEndpoint.accounts}/accounts/${accountId}/work-distance`,
      {distance}
    );
  }

  /**
   * Used to "remove" a contact.
   */
  deactivateContact(contactId: string): Observable<any> {
    return this.http.put(`${this.environment.apiEndpoint.newChasAPIv1}/contacts/contact/${contactId}/deactivate`, {});
  }

  /**
   * Used to "lock" a contact.
   */
  lockUser(contactId: string): Observable<any> {
    return this.http.put(`${this.environment.apiEndpoint.newChasAPIv1}/contacts/contact/${contactId}/lock`, {});
  }

  /**
   * Used to "unlock" a contact.
   */
  unlockUser(contactId: string): Observable<any> {
    return this.http.put(`${this.environment.apiEndpoint.newChasAPIv1}/contacts/contact/${contactId}/unlock`, {});
  }
}
