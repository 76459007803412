import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { first, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountsService } from '../../../core/services/accounts.service';
import { TradeService } from '../../../core/services/trade.service';
import {
  ParentWorkCategory,
  WorkCategory,
  CheckBundlesMatchCategoriesResult,
  AccountResponse,
  UserTypeCode,
  WorkCategoryView,
  UnspscCode,
} from '../../index';
import { Store } from '@ngrx/store';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { Territory } from 'src/app/shared/constants/territory.enum';

@Component({
  selector: 'app-work-categories-modal',
  templateUrl: './work-categories-modal.component.html',
  styleUrls: ['./work-categories-modal.component.scss'],
})
export class WorkCategoriesModalComponent implements OnInit {
  accountId: string;
  applicationType: string;
  showAlertBox: boolean = false;
  autoRemoveCategories: CheckBundlesMatchCategoriesResult;
  error: string;
  saving: boolean;
  searchWorkCategory: string;
  typeaheadList: any;
  isLoading: boolean;
  workCategories: ParentWorkCategory[] = [];
  searchableWorkCategories: WorkCategory[] = [];
  searchedWorkCategories: WorkCategory[] = [];
  nonMatchingCategories: WorkCategory[] = [];
  showAllCategories: boolean;

  allWorkCategories: WorkCategoryView[] = [];
  workCategoriesView: WorkCategoryView[] = [];
  selectedCategoryViews: WorkCategoryView[] = [];
  initialCategoriesSelection: UnspscCode[] = [];
  allSelectedCategory: WorkCategoryView[] = [];
  searchResult: WorkCategoryView[] = [];
  searchText: string = '';
  showSelectedPanel: boolean = false;
  htmlContent: string = null;

  searchForm = new UntypedFormGroup({
    term: new UntypedFormControl(),
  });
  workCategoriesForm = new UntypedFormGroup({
    categories: new UntypedFormGroup({}),
    subcategories: new UntypedFormGroup({}),
    categoryviews: new UntypedFormGroup({}),
  });

  fgCategories = this.workCategoriesForm.get('categories') as UntypedFormGroup;
  fgSubcategories = this.workCategoriesForm.get('subcategories') as UntypedFormGroup;

  fgCategoryViews = this.workCategoriesForm.get('categoryviews') as UntypedFormGroup;

  showClearFilter: boolean = false;
  supplierWorkCategoryType: number = 885570007;
  private readonly data$ = this.store.select(selectCurrentUserState);

  private currentUserState: CurrentUserState;

  pagesScroll: number = 0;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = '';
  private environment: IEnvironment;

  constructor(
    public bsModalRef: BsModalRef,
    private accountService: AccountsService,
    private tradeService: TradeService,
    private readonly store: Store
  ) {
    const env = new Environment();
    this.environment = env.getConfigSync();
  }

  ngOnInit(): void {
    this.data$.subscribe((data) => {
      if (data.loaded) {
        this.isLoading = true;
        this.currentUserState = data;
        this.accountId = this.currentUserState.currentAccount.accountId;
        this.getWorkCategories();
      }
    });
  }

  public onSearchTextChanged(event: any) {
    this.searchText = event;
    const searchterm: string = event.toLocaleLowerCase();

    this.searchResult = [];
    if (!searchterm) return;

    const items: WorkCategoryView[] = this.workCategoriesView.filter((item) => {
      const fullText: string = `${item.workCode} ${item.workType} ${item.subHeader} ${item.name}`.toLocaleLowerCase();
      if (fullText.includes(searchterm)) return item;
    });

    if (items.length >= 50) {
      this.searchResult.push(...items.splice(0, 50));
    } else if (items.length < 50) {
      this.searchResult.push(...items);
    }
  }

  get hasWorkCategories(): boolean {
    return this.workCategoriesView.length !== 0;
  }

  filterSupplierWorkCategories(categories: ParentWorkCategory[]): any {
    let items: ParentWorkCategory[] = new Array();
    categories.forEach((parent: ParentWorkCategory) => {
      let newParent: ParentWorkCategory = Object.assign({}, parent);
      newParent.subCategories = [];
      parent.subCategories.forEach((category: WorkCategory) => {
        if (category.chasSectors.some((id) => id.toString() === this.supplierWorkCategoryType.toString())) {
          newParent.subCategories.push(category);
        }
      });

      if (newParent.subCategories.length > 0) items.push(newParent);
    });

    return items;
  }

  filterSupplierWorkCategoryViews(categories: WorkCategoryView[]): any {
    let items: WorkCategoryView[] = new Array();
    categories.forEach((category: WorkCategoryView) => {
      if (category.chasSectors.some((id) => id.toString() === this.supplierWorkCategoryType.toString())) {
        items.push(category);
      }
    });

    return items;
  }

  public getWorkCategories(): void {
    this.isLoading = true;
    this.tradeService
      .getAllWorkCategoryViewsByAccountId(this.accountId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((categories) => {
        const filter = this.environment.territory !== Territory.AUSTRALIA;
        if (filter && this.currentUserState.currentAccount.membershipTypeId.toString() === UserTypeCode.SUPPLIER) {
          this.allWorkCategories = this.filterSupplierWorkCategoryViews(categories);
        } else {
          this.allWorkCategories = categories;
        }

        this.workCategoriesView = this.allWorkCategories;

        let item: WorkCategoryView;
        this.currentUserState.companyDetails.workCategories.forEach((cat: UnspscCode) => {
          this.initialCategoriesSelection.push(cat);
          item = this.getWorkCategoryViewByWorkCode(cat.classId);
          if (item) this.allSelectedCategory.push(item);
        });
      });
  }

  getWorkCategoryViewByWorkCode(workCode: number): WorkCategoryView {
    return this.workCategoriesView.find((x) => x.workCode === workCode);
  }

  selectCategory(event: Event | boolean, id: string, workCode: number): void {
    let categoriesValue = {};
    let subCategoriesValue = {};
    let isChecked = event as boolean;

    if (typeof event !== 'boolean') {
      isChecked = (event.target as HTMLInputElement).checked;
    }

    categoriesValue[id] = isChecked;
    subCategoriesValue[workCode] = isChecked;

    this.fgCategories.patchValue(categoriesValue);
    this.fgSubcategories.patchValue(subCategoriesValue);
  }

  onCategorySelected(event: Event | boolean, category: WorkCategoryView): void {
    let isChecked = event as boolean;

    if (typeof event !== 'boolean') {
      isChecked = (event.target as HTMLInputElement).checked;
    }

    const catView = this.currentUserState.companyDetails.workCategories.find((x) => x.classId === category.workCode);
    if (catView) {
      if (!isChecked) {
        var i = this.initialCategoriesSelection.indexOf(catView);
        this.initialCategoriesSelection.splice(i, 1);
      } else {
        this.initialCategoriesSelection.push(catView);
      }
    } else {
      const item = this.selectedCategoryViews.find((x) => x.workCode === category.workCode);
      if (item) {
        const i = this.selectedCategoryViews.indexOf(item);
        this.selectedCategoryViews.splice(i, 1);
      } else this.selectedCategoryViews.push(category);
    }

    this.addRemoveCategory(category.workCode, isChecked);
  }

  onCategoryDeselected(event: Event | boolean, category: WorkCategoryView): void {
    let isChecked = event as boolean;

    if (typeof event !== 'boolean') {
      isChecked = (event.target as HTMLInputElement).checked;
    }

    const deselected = this.allSelectedCategory.find((x) => x.workCode === category.workCode);
    if (!deselected) return;

    const i = this.allSelectedCategory.indexOf(deselected);
    this.allSelectedCategory.splice(i, 1);
  }

  addRemoveCategory(workCode: number, status: boolean): void {
    const item = this.getWorkCategoryViewByWorkCode(workCode);
    if (!item) return;

    const selected = this.allSelectedCategory.find((x) => x.workCode === workCode);
    if (status) {
      if (!selected) this.allSelectedCategory.push(item);
    } else {
      if (selected) {
        const i = this.allSelectedCategory.indexOf(selected);
        this.allSelectedCategory.splice(i, 1);
      }
    }
  }

  showSelectedWorkCategories(value: boolean): void {
    this.showSelectedPanel = value;
    if (!value) this.onSearchTextChanged(this.searchText);
  }

  isCategorySelected(event: Event | boolean, item: WorkCategoryView): number {
    let isChecked = event as boolean;

    if (typeof event !== 'boolean') {
      isChecked = (event.target as HTMLInputElement).checked;
    }

    return item.isSelected ? 1 : 0;
  }

  getColour(item: WorkCategoryView): string {
    if (item.isSelected) return 'color: red';

    return 'color: red';
  }

  /**
   * Returns a list of selected work category IDs (as numbers).
   */
  get selectedCategories(): number[] {
    return this.selectedCategoryViews.map((category) => category.workCode);
  }

  /**
   * Returns a list of selected work subcategory IDs (as numbers).
   */
  get selectedSubcategories(): number[] {
    return Object.keys(this.fgSubcategories.value)
      .filter((key) => true === this.fgSubcategories.get(key).value)
      .map((id) => parseInt(id, 10));
  }
  /**
   * Returns a corresponding category name.
   */
  public getCategoryName(categoryId: number): string {
    const category = this.workCategories.find((row) => row.id === categoryId);
    return category ? category.name : null;
  }

  /**
   * Returns TRUE if the user can save changes.
   */
  get canSave(): boolean {
    if (this.isLoading) return false;

    let match: boolean = false;
    const selection = this.selectedCategories;
    if (selection.length === 0) return false;

    selection.forEach((code) => {
      const check = this.currentUserState.companyDetails.workCategories.find((area) => area.classId === code);
      if (!check) {
        match = true;
      }
    });

    this.currentUserState.companyDetails.workCategories.forEach((category) => {
      const check = this.initialCategoriesSelection.find((code) => code.classId === category.classId);
      if (!check) {
        match = true;
      }
    });

    return match;
  }

  /**
   * Closes the dialogue/modal window.
   */
  public close(): void {
    this.bsModalRef.hide();
  }
  /**
   * Update work categories for the account.
   */
  public updateWorkCategories(): void {
    if (this.saving || this.selectedCategoryViews.length < 1) return;

    this.error = null;
    this.saving = true;
    this.workCategoriesForm.disable();

    const ids = this.getCategoriesToSave();
    this.tradeService
      .updateAccount(this.accountId, ids)
      .pipe(
        first(),
        finalize(() => {
          this.workCategoriesForm.enable();
          this.accountService.refresh();
          this.saving = false;
        })
      )
      .subscribe({
        next: () => {
          this.close();
        },
        error: (response: HttpErrorResponse) => {
          this.error = response.error.Message;
        },
      });
  }

  getCategoriesToSave(): number[] {
    const ids: number[] = [];
    const selection = this.selectedCategories;

    selection.forEach((code) => {
      const check = this.initialCategoriesSelection.find((area) => area.classId === code);
      if (!check) {
        ids.push(code);
      }
    });

    this.initialCategoriesSelection.forEach((category) => {
      const check = selection.find((code) => code === category.classId);
      if (!check) {
        ids.push(category.classId);
      }
    });

    return ids;
  }

  selectionReport(): string {
    if (this.selectedCategoryViews.length > 0)
      return `Selected: ${this.getCategoriesToSave().length} (${this.selectedCategoryViews.length} new)`;
    else return 'Selected: ' + this.getCategoriesToSave().length;
  }

  //#region "Infinite Scroll"

  appendWorkCategories() {
    // add another 20 items
    const i = 20;
    let more: WorkCategoryView[];
    if (this.pagesScroll + i < this.workCategoriesView.length) more = this.workCategoriesView.slice(this.pagesScroll, i);
    else more = this.workCategoriesView.slice(this.pagesScroll);

    this.searchResult.push(...more);

    this.pagesScroll += i + 1;
  }

  onScrollDown(ev) {
    console.log('scrolled down!!', ev);

    this.appendWorkCategories();

    this.direction = 'down';
  }

  //#endregion
}
