<div class="loader">
  <div class="loader__content-spinner" *ngIf="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <p>Generating Quotes... Please Wait.</p>
  </div>
  <div class="loader__content" *ngIf="quickQuote$ | async; let quickQuote;">
    <div
      *ngIf="quickQuote"
       class="selection"
       [ngClass]="{'reduced': reduced }">
      <div class="selection__items">
        <div class="selection__items-container">
          <div class="selection__item-wrapper" *ngIf="showSupplierBundle">
          <div
          matRipple
          class="selection__item advanced"
          [ngClass]="{
            selected: form.value.BundleName === bundleNames.VerifiedSupplier
          }"
          (click)="selectBundle(bundleNames.VerifiedSupplier)">
          <h4>VERIFIED SUPPLIER</h4>
          <div class="details">
            <i (mouseenter)="show_supplier_modules=true" (mouseleave)="show_supplier_modules=false" class="fas fa-info-circle"></i>
            <img src="assets/img/bundle/verified-supplier.svg" />
            <span class="price">Price {{ quoteAmounts.VerifiedSupplier | currency: currencyCode }}</span>
            <p>Verified Supplier is our risk management verification service specifically tailored for material suppliers. It is a streamlined and comprehensive risk management solution that allows suppliers to highlight their compliance with supply-chain best practices, regulatory controls and international standards.</p>
          </div>
          <a class="select-membership-button">
            {{ form.value.BundleName === bundleNames.VerifiedSupplier ? 'selected' : 'select' }}
          </a>
          </div>
          <div class="modules-box supplier-modules" *ngIf="show_supplier_modules">
            <img src="assets/img/bundle/verified-supplier.svg" />
            <mat-list>
              <mat-list-item *ngFor="let module of supplierModules">
                <i class="fas fa-check" *ngIf="module.isAvailable"></i>
                <i class="fas fa-times" *ngIf="!module.isAvailable"></i>
                <span>{{ module.name }}</span>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
        <div class="selection__item-wrapper" *ngIf="showStandardBundle">
          <div
            matRipple
            (click)="selectBundle(bundleNames.VerifiedContractor)"
            class="selection__item standard"
            [ngClass]="{
              selected: form.value.BundleName === bundleNames.VerifiedContractor
            }">
            <h4>VERIFIED CONTRACTOR</h4>
            <div class="details">
              <i (mouseenter)="show_contractor_modules=true" (mouseleave)="show_contractor_modules=false" class="fas fa-info-circle"></i>
                <img src="assets/img/bundle/verified-contractor.svg" />
                <span class="price">Price {{ quoteAmounts.Standard | currency: currencyCode }}</span>
                <p>Verified Contractor is our entry-level CHAS assessment package, offering contractor accreditation in three areas of risk management: identity, insurance and modern slavery. Gain accreditation, begin prequalifying for contracts and win more work.</p>
            </div>
            <a class="select-membership-button" (click)="selectBundle(bundleNames.VerifiedContractor)">
              {{ form.value.BundleName === bundleNames.VerifiedContractor ? 'selected' : 'select' }}
            </a>
          </div>

          <div class="modules-box contractor-modules" *ngIf="show_contractor_modules">
            <img src="assets/img/bundle/verified-contractor.svg" />
            <mat-list>
              <mat-list-item *ngFor="let module of contractorModules">
                <i class="fas fa-check" *ngIf="module.isAvailable"></i>
                <i class="fas fa-times" *ngIf="!module.isAvailable"></i>
                <span>{{ module.name }}</span>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
        <div class="selection__item-wrapper" *ngIf="showEliteBundle">
          <div class="selection__item elite" matRipple [ngClass]="{
            selected: form.value.BundleName === bundleNames.Elite
          }" (click)="selectBundle(bundleNames.Elite)">
            <h4>ELITE</h4>
            <div class="details">
              <i (mouseenter)="show_elite_modules=true" (mouseleave)="show_elite_modules=false" class="fas fa-info-circle"></i>
              <img src="assets/img/bundle/chas-elite.svg" />
              <span class="price" *ngIf="showEliteBundle">Price {{ quoteAmounts.Elite | currency: currencyCode }}</span>
              <p>CHAS Elite is the elite standard of CHAS accreditation and includes all assessments under Verified Contractor and seven additional risk areas: health and safety (H&S), quality, environmental, equality, diversity and inclusion (EDI), anti-bribery and corruption, corporate social responsibility (CSR) and information security.</p>
            </div>
           <a class="select-membership-button" (click)="selectBundle(bundleNames.Elite)">
            {{ form.value.BundleName === bundleNames.Elite ? 'selected' : 'select' }}
           </a>
          </div>

          <div class="modules-box elite-modules" *ngIf="show_elite_modules">
            <img src="assets/img/bundle/chas-elite.svg" />
            <mat-list>
              <mat-list-item *ngFor="let module of eliteModules;">
                <i class="fas fa-check" *ngIf="module.isAvailable"></i>
                <i class="fas fa-times" *ngIf="!module.isAvailable"></i>
                <span>{{ module.name }}</span>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div>
  </div>
</div>
