import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chas-logo',
  templateUrl: './chas-logo.component.html',
  styleUrls: ['./chas-logo.component.scss']
})
export class ChasLogoComponent {
  constructor() { }
}
