import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DocumentModalComponent } from 'src/app/shared/components/document-modal/document-modal.component';
import { State } from 'src/app/core/ngrx/reducers/notification.reducer';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { NotificationResponse } from 'src/app/shared/index';
import { NotificationDisplayComponent } from '../notification-display-component';

@Component({
  selector: 'app-document-notification-display',
  template: `
  <div class="notification__display">
    <span>
      <i class="fas" [ngClass]="getIconClass(data.type)"></i>
    <span *ngIf="data.area === 'Documents'"><strong> {{data.metadata[0].value.ProductName}}:</strong></span> {{ data.content }}
    </span>
    <button class="btn btn-primary" (click)="action(data)">
      <span>{{data.actions[0].type}}</span>
    </button>
  </div>`,
  styleUrls: ['./document-notification-display.component.scss']
})
export class DocumentNotificationDisplayComponent extends NotificationDisplayComponent {

  constructor(
    protected router: Router,
    protected notifications: NotificationsService,
    protected store: Store<State>,
    private modalService: BsModalService
  ) {
    super(router, notifications, store);
  }
  modalRef: BsModalRef;

  getIconClass(exp: string) {
    if (exp === 'Expired') {
      return ['fa-times-circle', 'red'];
    } else if (exp === 'Expiring') {
      return ['fa-plus-circle','amber'];
    }
  }

  openDocumentModal(assessmentId: string, questionSet: string, question: string, notification: any) {
    this.modalRef = this.modalService.show(DocumentModalComponent, {
      animated: false,
      backdrop: 'static',
      class: 'modal-xl',
      initialState: {
        assessmentId: assessmentId,
        questionSetNo: questionSet,
        questionNo: question,
        notificationId: notification.id,
        dismissNotificationFn: () => { this.dismissNotification(notification) }
      }
    });
  }

  action(notification: NotificationResponse) {
    switch (notification.actions[0].type) {
      case 'Dismiss':
        return this.dismissNotification(notification);
      case 'Action':
      return this.router.navigateByUrl(`/assessment/${notification.metadata[0].value.AssessmentId}`).catch(err => {
        console.error(err);
      });
      case 'Update':
        this.router.navigateByUrl('/dashboard').then(() => {
          this.openDocumentModal(
            notification.metadata[0].value.AssessmentId,
            notification.metadata[0].value.QuestionSet,
            notification.metadata[0].value.Question,
            notification
          );
        }).catch(err => {
          console.error(err);
        });
        return;
      case 'Renew':
        return this.router.navigateByUrl('/membership', { state: { data: { renewal: true } } }).catch(err => {
          console.error(err);
        });
      case 'GetAccredited':
        return this.router.navigate([`./membership`]);
    }
  }
}
