import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { BasketItemsActions, basketItemsAdd, basketItemsClear, basketItemsLoad, basketItemsRemove, basketItemsUpdate } from '../actions/basketItems.actions';
import { NewBasketService } from '../../services/new-basket/basket.service';

@Injectable()
export class BasketItemsEffects {
  load$ = createEffect(() => this.actions$.pipe(
    ofType(basketItemsLoad),
    mergeMap(() => this.basketService.getItems().pipe(
      map(basket => ({ type: BasketItemsActions.LoadSuccess, payload: basket })),
      catchError(() => of({ type: BasketItemsActions.LoadError }))
    ))
  ));
  add$ = createEffect(() => this.actions$.pipe(
    ofType(basketItemsAdd),
    mergeMap((action) => this.basketService.addItem(action.item).pipe(
      map(basket => ({ type: BasketItemsActions.AddSuccess, payload: basket })),
      catchError(() => of({ type: BasketItemsActions.AddError }))
    ))
  ));
  update$ = createEffect(() => this.actions$.pipe(
    ofType(basketItemsUpdate),
    mergeMap((action) => this.basketService.updateItem(action.change.item, action.change.field).pipe(
      map(basket => ({ type: BasketItemsActions.UpdateSuccess, payload: basket })),
      catchError(() => of({ type: BasketItemsActions.UpdateError }))
    ))
  ));
  remove$ = createEffect(() => this.actions$.pipe(
    ofType(basketItemsRemove),
    mergeMap((action) => this.basketService.removeItem(action.item).pipe(
      map(basket => ({ type: BasketItemsActions.RemoveSuccess, payload: basket })),
      catchError(() => of({ type: BasketItemsActions.RemoveError }))
    ))
  ));
  clear$ = createEffect(() => this.actions$.pipe(
    ofType(basketItemsClear),
    mergeMap((action) => this.basketService.clearItems().pipe(
      map(basket => ({ type: BasketItemsActions.ClearSuccess, payload: basket })),
      catchError(() => of({ type: BasketItemsActions.ClearError }))
    ))
  ));

  constructor(
    private actions$: Actions,
    private basketService: NewBasketService
  ) { }
}

