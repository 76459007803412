import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './loading-spinner.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';

@NgModule({
    declarations: [LoadingSpinnerComponent, LoadingOverlayComponent],
    exports: [LoadingSpinnerComponent, LoadingOverlayComponent],
    imports: [CommonModule]
})
export class LoadingSpinnerModule {}
