import { Component, OnInit } from '@angular/core';
import { first, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AssessmentsService } from '../../core';
import { AssessmentStatus, Assessment, CheckNewAuthenticationResponse } from '../../shared';
import { AssessmentMenuService } from 'src/app/core/services/assessment-menu.service';
import { Store } from '@ngrx/store';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';

@Component({
  selector: 'app-assessments',
  templateUrl: './assessments.component.html',
  styleUrls: ['./assessments.component.scss'],
})
export class AssessmentsPageComponent implements OnInit {
  accountId: string;
  assessmentsList: Assessment[];
  error: string;
  isLoading: boolean;
  authUser: CheckNewAuthenticationResponse;
  private readonly data$ = this.store.select(selectCurrentUserState);

  constructor(
    private assessmentService: AssessmentsService,
    private assessmentMenuService: AssessmentMenuService,
    private router: Router,
    private readonly store: Store
  ) {}

  ngOnInit() {
    this.data$.subscribe((data) => {
      if (data.loaded) {
        this.authUser = data.currentAccount;
        this.accountId = data.currentAccount.accountId;
        this.loadAssessments();
      }
    });
  }

  /**
   * Fetches assessments for the contractor.
   */
  loadAssessments(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.assessmentService
      .get(this.accountId)
      .pipe(
        first(),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (data: Assessment[]) => {
          this.assessmentsList = data;
        },
        error: (response: HttpErrorResponse) => {
          this.error = response.error.Message;
        },
      });
  }

  trackByFn(index: number): number {
    return index;
  }

  /**
   *
   */
  getStatusText(row: Assessment): string {
    switch (row.displayStatus?.label) {
      case AssessmentStatus.REJECTED:
        // If status is Assessment Rejected, display as Awaiting Assignment
        return 'Awaiting Assignment';

      default:
        return row.displayStatus?.label;
    }
  }

  /**
   *
   */
  getStatusClass(row: Assessment): string {
    switch (row.displayStatus?.label) {
      case AssessmentStatus.COMPLETE:
        return 'success';
      case AssessmentStatus.RESUBMITTED:
      case AssessmentStatus.SUBMITTED:
        return 'primary';
      case AssessmentStatus.INFORMATION_REQUIRED:
        return 'warning';
      case AssessmentStatus.REJECTED:
        return 'danger';
      default:
        return 'secondary';
    }
  }

  /**
   *
   */
  getStatusBadgeClass(row: Assessment): string {
    return ['badge', 'badge-pill', 'badge-' + this.getStatusClass(row)].join(' ');
  }

  getAssessmentBadge(row: Assessment): string {
    const name = row.product.name
      .toLocaleLowerCase()
      .replace('supplier ', '')
      .replace(/[^0-9a-z ]/gi, ' ');
    return name
      .split(' ')
      .filter((n) => n)
      .join('-')
      .toLowerCase();
  }

  selectAssessment(id: string, status: string): void {
    if (id === this.assessmentMenuService.getCurrentAssessmentId()) {
      return;
    }
    this.assessmentMenuService.setSelectedAssessmentPages([]);
    this.assessmentMenuService.setCurrentAssessmentId(id);
    this.assessmentMenuService.setCurrentAssessmentPage(null);
    this.router.navigate(['/assessment', id], { state: { data: status } });
  }

  getStatusIconArray(row: Assessment): string[] {
    switch (row.displayStatus?.label) {
      case AssessmentStatus.COMPLETE:
        return ['fa-check-circle', 'fa-check-circle', 'fa-check-circle'];
      case AssessmentStatus.RESUBMITTED:
        return ['fa-check-circle', 'fa-play-circle', 'fa-circle'];
      case AssessmentStatus.SUBMITTED:
        return ['fa-check-circle', 'fa-play-circle', 'fa-circle'];
      case AssessmentStatus.ACCEPTED:
        return ['fa-check-circle', 'fa-play-circle', 'fa-circle'];
      case AssessmentStatus.INFORMATION_REQUIRED:
        return ['fa-play-circle', 'fa-exclamation-circle', 'fa-circle'];
      case AssessmentStatus.REJECTED:
        return ['fa-check-circle', 'fa-times-circle', 'fa-times-cicle'];
      default:
        return ['fa-play-circle', 'fa-circle', 'fa-circle'];
    }
  }
}
