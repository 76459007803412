

<div *ngIf="bundles && authAccount && card_config && contact" [hidden]="isLoading">

  <section id="welcome" *ngIf="!firstTime">
    <div class="row">
      <div _ngcontent-tnf-c149="" class="col-xl-12 pb-3">
        <div class="help-panel" *ngIf="!membershipExpired && !membershipExpiring || isFoundation">
          <div>
            <h2>{{ greeting }}, {{ contact.firstname }}. What can we help you with today?</h2>
            <button class="btn btn-primary" [routerLink]="['/assessments']" *ngIf="!isFoundation">Complete my assessment</button> &nbsp;
            <button class="btn btn-primary" (click)="upgradeRedirect()" *ngIf="canUpgrade" [state]="membershipNavigationState" >Upgrade my membership</button> &nbsp;
            <button class="btn btn-primary" [routerLink]="['/company-details']">Update my company details</button> &nbsp;
            <button class="btn btn-primary" [routerLink]="['/admin/users']">Add a colleague to My CHAS</button> &nbsp;
            <a class="btn btn-primary" [href]="'tel:' + phoneNumber"> <em class="fas fa-phone"></em> Call the team on {{phoneNumber}}</a>
            <app-dashboard-carousel *ngIf="showDashboardCarousel"></app-dashboard-carousel>
          </div>
        </div>
        <div *ngIf="membershipExpired && !isFoundation" class="expired-help-panel">
          <div>
            <h2>Your membership has expired, {{ contact.firstname }}: it's time to renew.</h2>
            <p>
              If you do not renew, you'll lose access to your membership benefits and
              you will also be unable to maintain your accredited status.</p>
            <p> Take action now - renew your membership
              and
              your accreditation.
            </p>
            <br />
            <button class="btn btn-clear-light" [routerLink]="['/membership']" [state]="{data:{renewal:true}}">Renew
              now</button>
          </div>
        </div>
        <div *ngIf="membershipExpiring && !isFoundation" class="expiring-help-panel">
          <div>
            <h2>Your membership will expire soon, {{ contact.firstname }}: it's time to renew.</h2>
            <p>
              Your CHAS membership will expire in <strong>{{ daysToExpiry }} days</strong>. If you do not renew before then you'll
              lose access to your membership benefits and you will also be unable to maintain your accredited status. This
              means you'll no longer
              be visible to over 1,400 CHAS clients.
            </p>
            <br />
            <button class="btn btn-clear-dark" [routerLink]="['/membership']" [state]="{data:{renewal:true}}">Renew
              now</button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="row">
    <div class="col-xl-12 p-3">
      <app-membership-card *ngIf="productPermissions !== 'No Access'" [bundle]="bundles[0]" [account]="account"
        [permissions]="productPermissions" [authAccount]="authAccount" [foundation]="isFoundation">
      </app-membership-card>
    </div>
  </div>
  <div *ngIf="!isFoundation">
    <div *ngIf="assessmentFailed" class="row mb-3" style="margin-bottom: 0rem !important;">
      <div class="col-xl-12 p-3">
        <div class="dashboard__expired">
          <h2>Your health & safety application was unsuccessful</h2>
          <p>
            If you wish re-apply or query the outcome, please call 0345 521 9111 to speak to us...
          </p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="covidVerification && !membershipExpired">
    <div class="dashboard__statementOfBestPractice">
      <h2>Statement of Best Practice (COVID-19) Verification</h2>
      <p>As part of our commitment to a safe return to work for all of our contractors, we have introduced the new
        Statement of Best Practice (COVID-19) verification.</p>
      <p>Please complete this as soon as possible in order to evidence your COVID-19 planning and risk assessments to
        your clients.</p>
      <br />
      <button class="btn btn-primary" [routerLink]="['/assessment', covidVerification.id]">
        Complete the verification
        now
      </button>
    </div>
  </div>

  <div class="pt-3">
    <div *ngFor="let block of card_config.Blocks" class="card-block">
      <h2 *ngIf="block.Title" class="card-block-text">{{block.Title}}</h2>
      <p *ngIf="block.Description" class="card-block-text">{{block.Description}}</p>
      <div class="row mb-3" *ngFor="let row of block.Rows">
        <ng-container *ngFor="let card of row.Cards">
          <div class="col-xl-{{card.Size}} d-flex p-3" [attr.id]="card.Type" *ngIf="shouldShow(card.Name)">
            <div class="w-100" *ngIf="card.Type === 'compliance'"
              data-intro="This card will give you all the information you need to manage your accreditation - if it's not green, it's time to update your accreditation."
              data-step="4">
              <app-product-card *ngIf="card.Component === 'Product Card'" [type]="getProductType(card.Type)"
                [product]="getProduct(card.Name)" [active]="activeCards[0]" [bundle]="bundles[0]"
                [authAccount]="authAccount" [account]="account" [typeAndVariant]="typeAndVariant"
                [membershipExpired]="membershipExpired" [foundation]="isFoundation" [companyDetails]="companyDetails">
              </app-product-card>
            </div>

            <div class="w-100" *ngIf="card.Type === 'shield'"
              data-intro="As you use more CHAS Services, these cards will show you information to help you use them, or to log into our partner services. Business Shield is a free benefit which you can activate now!"
              data-step="5">
              <app-product-card *ngIf="card.Component === 'Product Card'" [type]="getProductType(card.Type)"
                [product]="getProduct(card.Name)" [active]="activeCards[0]" [bundle]="bundles[0]"
                [authAccount]="authAccount" [account]="account" [typeAndVariant]="typeAndVariant"
                [membershipExpired]="membershipExpired" [foundation]="isFoundation" [companyDetails]="companyDetails">
              </app-product-card>
            </div>

            <div class="w-100" *ngIf="card.Type === 'casThirdParty' && !isMembershipCAS && !isSupplierMembership">
              <app-product-card *ngIf="card.Component === 'Product Card'" [type]="getProductType(card.Type)"
                [product]="getProduct(card.Name)" [active]="activeCards[0]" [bundle]="bundles[0]"
                [authAccount]="authAccount" [account]="account" [typeAndVariant]="typeAndVariant"
                [membershipExpired]="membershipExpired" [foundation]="isFoundation" [companyDetails]="companyDetails">
              </app-product-card>
            </div>

            <app-product-card class="w-100"
              *ngIf="card.Component === 'Product Card' && (card.Type !== 'compliance' && card.Type !== 'shield' && card.Type !== 'casThirdParty')"
              [type]="getProductType(card.Type)" [product]="getProduct(card.Name)" [active]="activeCards[0]"
              [bundle]="bundles[0]" [authAccount]="authAccount" [account]="account" [typeAndVariant]="typeAndVariant"
              [membershipExpired]="membershipExpired" [foundation]="isFoundation" [companyDetails]="companyDetails">
            </app-product-card>


            <app-tier3-card class="w-100" *ngIf="card.Component === 'Tier 3 Card'" [size]="getCardSize(card.Size)"
              [title]="card.title" [subtitle]="card.subtitle" [type]="card.type" [content]="card.content"
              [cta]="card.cta" [ctaUrl]="card.ctaUrl" [cta2]="card.cta2" [ctaUrl2]="card.ctaUrl2"
              [membershipExpired]="membershipExpired" [enabledForAll]="card.enabledForAll" [foundation]="isFoundation">
            </app-tier3-card>

            <div *ngIf="card.Component === 'Documents Card'">
              <app-documents-card [authAccount]="authAccount"></app-documents-card>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
</div>

<div
  *ngIf="isFoundationModalShown"
  [config]="{ show: true, backdrop: 'static' }"
  bsModal
  #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="dialog-foundation-modal">
  <app-foundation-modal
    [accountId]="authAccount.accountId"
    [contactId]="authAccount.contactId">
  </app-foundation-modal>
</div>
