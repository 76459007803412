import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-advantages-list',
  templateUrl: './product-advantages-list.component.html',
  styleUrls: ['./product-advantages-list.component.scss']
})
export class ProductAdvantagesListComponent {
  @Input() hiddenItems: Array<number> = [0, 3];
  @Input() tickedItems: Array<number> = [1];
  @Input() tickAll?: boolean = false;
  @Input() unTickedItems?: Array<number> = [];

  public list: Array<string> = [
    'Free Learning Pathway',
    'Member Benefits & Discounts',
    'Health and Safety',
    'Insurance (Self Certify)',
    'Insurance',
    'Financial',
    'Identity',
    'Corporate & Professional Standing',
    'Environmental',
    'Quality',
    'Equality Diversity and Inclusion (EDI)',
    'Modern Slavery',
    'Anti-Bribery & Corruption',
    'Corporate Social Responsibility',
    'Information Security',
    'Information Management'
  ]
  public shouldBeTicked(index: number): boolean {
    let result = this.tickAll || this.tickedItems.includes(index);

    if(this.unTickedItems.length !== 0) result = !this.unTickedItems.includes(index);

    return result;
  }

  public shouldBeHidden(index: number): boolean {
    return this.hiddenItems.includes(index);
  }
}
