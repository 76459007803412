<section >


  <h4>{{introText ? introText.assessment : ''}}</h4>

  <p>{{introText ? introText.title : ''}}</p>

  <accordion id="instructions">
    <accordion-group [isOpen]="expanded" [panelClass]="customClass" #accordianRef>
      <h5 class="m-0" accordion-heading>
        Introduction
        <i class="float-right fas" [ngClass]="{'fa-minus': accordianRef?.isOpen, 'fa-plus': !accordianRef?.isOpen}"></i>
      </h5>
      <div *ngIf="!displaySurveyAssist">
        <div *ngFor="let para of introText.content" [innerHTML]="para.paragraph"></div>
      </div>

      <div *ngIf="displaySurveyAssist" class="assist-component">
        <div class="px-2 py-2 assist-info">
          <h5 class="assist-title">{{ surveyAssistInfo.title }}</h5>
          <p class="assist-body">
            A guide to help you understand what information <br/> and documents are
            required to complete your assessment
          </p>
        </div>
        <div class="pt-4 px-3 assist-download" (click)="viewDocument(surveyAssistInfo.documentLink)">
          <i class="fa fa-download fa-2x icon-color"></i>
          <p class="pt-1 download-label">Download</p>
        </div>
      </div>
    </accordion-group>
  </accordion>

</section>
