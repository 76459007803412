
import { createAction, props } from '@ngrx/store';
import { IRegistersteptwo } from 'src/app/shared/interfaces/new/registerStepTwo';

export enum RegistersteptwoActions {
  Add = '[Registersteptwo] Add',
  AddSuccess = '[Registersteptwo] Add Successful',
  AddError = '[Registersteptwo] Add Error',
  Remove = '[Registersteptwo] Remove',
  RemoveSuccess = '[Registersteptwo] Remove Successful',
  RemoveError = '[Registersteptwo] Remove Error',
  Load = '[Registersteptwo] Load',
  LoadSuccess = '[Registersteptwo] Load Successful',
  LoadError = '[Registersteptwo] Load Error'
}

export const registerStepTwoAdd = createAction(RegistersteptwoActions.Add, props<{ registerStepTwo: IRegistersteptwo }>());
export const registerStepTwoAddSuccess = createAction(RegistersteptwoActions.AddSuccess, props<{ payload: IRegistersteptwo }>());
export const registerStepTwoAddError = createAction(RegistersteptwoActions.AddError);

export const registerStepTwoRemove = createAction(RegistersteptwoActions.Remove);
export const registerStepTwoRemoveSuccess = createAction(RegistersteptwoActions.RemoveSuccess, props<{ payload: IRegistersteptwo }>());
export const registerStepTwoRemoveError = createAction(RegistersteptwoActions.RemoveError);

export const registerStepTwoLoad = createAction(RegistersteptwoActions.Load);
export const registerStepTwoLoadSuccess = createAction(RegistersteptwoActions.LoadSuccess, props<{ payload: IRegistersteptwo }>());
export const registerStepTwoLoadError = createAction(RegistersteptwoActions.LoadError);
