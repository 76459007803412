import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardPageComponent } from './dashboard.component';
import { DashboardAccreditationIconsComponent } from './accreditation-icons/accreditation-icons.component';
import { DashboardAccreditationListComponent } from './accreditation-list/accreditation-list.component';
import { BootstrapComponentsModule } from 'src/app/shared/components/bootstrap/bootstrap.module';
import { NothingModule } from 'src/app/shared/components/nothing/nothing.module';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { RouterModule, Routes } from '@angular/router';
import { DashboardProductCardModule } from './product-card/product-card.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardTier3CardModule } from './product-card/tier3-card/tier3-card.module';
import { MembershipCardModule } from './membership-card/membership-card.module';
import { DocumentsCardModule } from './documents-card/documents-card.module';
import { FoundationModalModule } from '../../shared/components/foundation-modal/foundation-modal.module';
import { DashboardCarouselComponent } from './dashboard-carousel/dashboard-carousel.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { WelcomeModalModule } from 'src/app/shared/components/welcome-modal/welcome-modal.module';

const routes: Routes = [
  {path: '', component: DashboardPageComponent }
]

@NgModule({
  declarations: [
    DashboardPageComponent,
    DashboardAccreditationIconsComponent,
    DashboardAccreditationListComponent,
    DashboardCarouselComponent
  ],
  exports: [DashboardPageComponent],
  imports: [
    CommonModule,
    BootstrapComponentsModule,
    RouterModule.forChild(routes),
    LoadingSpinnerModule,
    NothingModule,
    DashboardProductCardModule,
    MembershipCardModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardTier3CardModule,
    DocumentsCardModule,
    FoundationModalModule,
    WelcomeModalModule,
    PipesModule
  ]
})
export class DashboardPageModule { }
