import { createReducer, on } from '@ngrx/store';
import { getStorage } from 'src/app/shared/functions/getBasket.functions';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();
import { basketItemsAddSuccess, basketItemsClearSuccess, basketItemsLoadSuccess, basketItemsRemoveSuccess, basketItemsUpdateSuccess } from '../actions/basketItems.actions';

export const initialState = getStorage('newBasketItems');

const _basketItemsReducer = createReducer(
  initialState,
  on(basketItemsAddSuccess, (state, action) => action.payload),
  on(basketItemsLoadSuccess, (state, action) => action.payload),
  on(basketItemsUpdateSuccess, (state, action) => action.payload),
  on(basketItemsRemoveSuccess, (state, action) => action.payload),
  on(basketItemsClearSuccess, (state, action) => action.payload)
);

export function basketItemsReducer(state, action) {
  return _basketItemsReducer(state, action);
}
