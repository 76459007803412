import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SiteMenuService, LoadingService, ContactsService } from '../../../../core';
import { NavigationEnd, Router } from '@angular/router';
import { BsModalService, ModalOptions, BsModalRef } from 'ngx-bootstrap/modal';
import { MsalService } from '@azure/msal-angular';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { firstValueFrom, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCurrentUserState } from '../../../../core/ngrx/selectors/currentUser.selectors';
import { map, takeUntil } from 'rxjs/operators';
import { CurrentUserState } from '../../../../core/ngrx/reducers/currentUser.reducer';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss'],
})
export class SiteLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('sessionTimeoutModal', { static: true }) sessionTimeoutModal;

  private idleTime = 0;
  private originalSessionTimeoutSeconds = 20;
  private sessionModalIdleInterval: NodeJS.Timeout;
  private idleInterval: NodeJS.Timeout;
  private modalRef: BsModalRef;
  private config: IEnvironment;
  private destroyed$ = new Subject<void>();

  private routerEventsSubscription: Subscription = new Subscription();
  private loadingServiceSubscription: Subscription = new Subscription();
  public isLoading = false;
  public isOpsSession: boolean = false;
  public opsUserId: string;
  public sessionTimeoutSeconds = this.originalSessionTimeoutSeconds;

  constructor(
    private readonly siteMenuService: SiteMenuService,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly authService: MsalService,
    private readonly modalService: BsModalService,
    private readonly store: Store,
    private readonly contactsService: ContactsService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.loadingServiceSubscription = this.loadingService.isAccountLoading.subscribe(() => (this.isLoading = false));
    const environment = new Environment();
    this.config = await environment.getConfig();

    this.routerEventsSubscription = this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) return;
      this.scrollToTop();
      this.isLoading = false;
    });

    this.store
      .select(selectCurrentUserState)
      .pipe(
        map(async (currentUser: CurrentUserState): Promise<void> => {
          if (currentUser.currentAccount) {
            await this.isOpsUserSession(currentUser);
          }
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => console.warn(`Ops user session invoked ${this.isOpsSession}`));

    this.registerIdleEvents();
  }

  public ngOnDestroy(): void {
    const subscriptions: Subscription[] = [this.loadingServiceSubscription, this.routerEventsSubscription];
    subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private async isOpsUserSession(data: Partial<CurrentUserState>): Promise<void> {
    if (data.currentAccount?.isLogMeInContact) {
      this.isOpsSession = true;
      this.opsUserId = data.currentAccount.currentContactId;
    }
  }

  public async endOpsUserSession(closeSession: boolean): Promise<void> {
    await firstValueFrom(this.contactsService.deactivateContact(this.opsUserId));
    if (closeSession) window.top.close();
  }

  private openSessionModal(): void {
    const modalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      ignoreBackdropClick: true,
    };
    this.modalRef = this.modalService.show(this.sessionTimeoutModal, modalOptions);
  }

  private timerIncrement(): void {
    this.idleTime += 1;

    if (this.idleTime > this.config.session.idleTimeout) {
      this.openSessionModal();
      this.sessionTimeoutSeconds = this.originalSessionTimeoutSeconds;

      this.sessionModalIdleInterval = setInterval(async () => {
        if (this.sessionTimeoutSeconds > 0) {
          this.sessionTimeoutSeconds -= 1;
        } else {
          this.isOpsSession ? await this.endOpsUserSession(false) : null;
          this.logout();
        }
      }, 1000);

      clearInterval(this.idleInterval);
    }
  }

  private registerIdleEvents(): void {
    this.idleInterval = setInterval(() => {
      this.timerIncrement();
    }, 60000);
    const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

    window.addEventListener(
      'load',
      () => {
        this.idleTime = 0;
      },
      true
    );

    events.forEach((name) => {
      document.addEventListener(
        name,
        () => {
          this.idleTime = 0;
        },
        true
      );
    });
  }

  public toggleMenu(): void {
    this.siteMenuService.toggle();
  }

  public async goToDashboard(): Promise<void> {
    await this.router.navigateByUrl('/dashboard');
  }

  public scrollToTop(): void {
    window.scrollTo(0, 0);
    const mainContentArea = document.getElementById('chas-main-content-area');
    mainContentArea.scrollTo(0, 0);
  }

  public logout(): void {
    clearInterval(this.sessionModalIdleInterval);
    this.authService.logout();
    this.modalRef.hide();
  }

  public stayLoggedIn(): void {
    clearInterval(this.sessionModalIdleInterval);
    this.registerIdleEvents();
    this.modalRef.hide();
  }
}
