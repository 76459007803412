import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BootstrapComponentsModule } from '../bootstrap/bootstrap.module';
import { WelcomeModalComponent } from './welcome-modal.component';
import { ProductBundlesModule } from '../product-bundles/product-bundles.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { ReactiveFormsModule } from '@angular/forms';
import { WorkCategoriesModule } from '../work-categories/work-categories.module';
import { WorkAreasModule } from '../work-areas/work-areas.module';

@NgModule({
  declarations: [WelcomeModalComponent],
  imports: [CommonModule, WorkCategoriesModule, ReactiveFormsModule, BootstrapComponentsModule, ProductBundlesModule, LoadingSpinnerModule,WorkAreasModule],
  exports: [WelcomeModalComponent]
})
export class WelcomeModalModule { }
