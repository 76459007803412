import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { SiteLayoutComponent, AuthLayoutComponent } from './core';
import { AuthLoginResolver } from './shared/resolvers/auth-login.resolver';
import { ChasAdminComponent } from './features/chas-admin/chas-admin.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'chasadmin',
        component: ChasAdminComponent,
        children: [
          {
            path: '**',
            component: ChasAdminComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'forgetpassword',
        component: ChasAdminComponent,
        children: [
          {
            path: '**',
            component: ChasAdminComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'register',
        loadChildren: () =>
          import('src/app/features/register-new-container/register-new-container.module').then((m) => m.RegisterNewContainerModule),
      },
      {
        path: 'vip/:leadId',
        loadChildren: () =>
          import('src/app/features/register-new-container/register-new-container.module').then((m) => m.RegisterNewContainerModule),
      },
      {
        path: 'register-supplier',
        loadChildren: () => import('src/app/features/register-new/register-new.module').then((m) => m.RegisterNewModule),
      },
      {
        path: 'fir',
        loadChildren: () => import('src/app/features/fir/fir.module').then((m) => m.FirPageModule),
      },
      {
        path: 'register-foundation',
        loadChildren: () => import('src/app/features/register/register.module').then((m) => m.RegisterPageModule),
      },
      {
        path: 'builduk',
        loadChildren: () => import('src/app/features/register/register.module').then((m) => m.RegisterPageModule),
      },
      {
        path: 'register-existing',
        loadChildren: () => import('src/app/features/register-existing/register-existing.module').then((m) => m.RegisterExistingPageModule),
      },
      {
        path: 'order-summary',
        loadChildren: () => import('src/app/features/order-summary/order-summary.module').then((m) => m.OrderSummaryPageModule),
      },
      {
        path: 'reset-password',
        loadChildren: () => import('src/app/features/reset-password/reset-password.module').then((m) => m.ResetPasswordPageModule),
      },
    ],
  },
  {
    path: 'payment-success',
    loadChildren: () => import('src/app/features/stripe-landing/stripe-landing.module').then((m) => m.StripeLandingModule),
  },
  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'my-clients',
        loadChildren: () => import('src/app/features/my-clients/my-clients.module').then((m) => m.MyClientsModule),
      },
      {
        path: 'assessment/test',
        loadChildren: () => import('src/app/features/assessments/test/test.module').then((m) => m.QuestionsetTestPageModule),
      },
    ],
  },
  {
    path: '',
    component: SiteLayoutComponent,
    resolve: { account: AuthLoginResolver },
    canActivateChild: [MsalGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('src/app/features/dashboard/dashboard.module').then((m) => m.DashboardPageModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('src/app/features/admin-users/admin-users.module').then((m) => m.AdminUsersModule),
      },
      {
        path: 'assessment/:assessmentID',
        loadChildren: () =>
          import('src/app/features/assessments/questionset/questionset.module').then((m) => m.AssessmentQuestionsetPageModule),
      },
      {
        path: 'assessments',
        loadChildren: () => import('src/app/features/assessments/assessments.module').then((m) => m.AssessmentsPageModule),
      },
      {
        path: 'documents',
        loadChildren: () => import('src/app/features/documents/documents.module').then((m) => m.DocumentsPageModule),
      },
      {
        path: 'membership',
        loadChildren: () => import('src/app/features/membership/membership.module').then((m) => m.MembershipPageModule),
      },
      {
        path: 'company-details',
        loadChildren: () => import('src/app/features/company-details/company-details.module').then((m) => m.CompanyDetailsPageModule),
      },
      {
        path: 'ordersummary',
        loadChildren: () => import('src/app/features/order-summary/order-summary.module').then((m) => m.OrderSummaryPageModule),
      },
      {
        path: 'learning-pathway',
        loadChildren: () => import('src/app/features/learning-pathway/learning-pathway.module').then((m) => m.LearningPathwayPageModule),
      },
      {
        path: 'courseGroup/:courseGroupId/course/:courseId',
        loadChildren: () =>
          import('src/app/shared/components/courses/course-detail/course-detail.module').then((m) => m.CourseDetailModule),
      },
      {
        path: '**',
        redirectTo: '/dashboard',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
