import { BundleNames, IMembershipCards, ProductNumbers, ProductPrices } from "./select-membership.component";

export default class {
  data: IMembershipCards = {
    "Individual": {
      "id": ProductNumbers.Individual,
      "headerImage": "/assets/img/product-cards/FDN-Badge.png",
      "headerTitle": "Individual",
      "hiddenItems": [1, 3],
      "tickedItems": [0],
      "name": BundleNames.Individual,
      "price": ProductPrices.Individual
    },
    "Standard": {
      "id": ProductNumbers.Standard,
      "headerImage": "/assets/img/bundle/standard.png",
      "headerTitle": "Standard",
      "hiddenItems": [0, 4],
      "tickedItems": [1, 2, 3],
      "name": BundleNames.Standard,
      "price": ProductPrices.Standard
    },
    "Advanced": {
      "id": ProductNumbers.Advanced,
      "headerImage": "/assets/img/bundle/advanced.png",
      "headerTitle": "Advanced",
      "unTickedItems": [12, 13, 14],
      "name": BundleNames.Advanced,
      "price": ProductPrices.Advanced
    },
    "Elite": {
      "id": ProductNumbers.Elite,
      "headerImage": "/assets/img/bundle/elite.png",
      "headerTitle": "Elite",
      "tickAll": true,
      "name": BundleNames.Elite,
      "price": ProductPrices.Elite
    }
  };

  build(): IMembershipCards {
    return this.data;
  }
}
