import { Component, Input, OnChanges } from '@angular/core';
import { Assessment, AssessmentStatus, GroupedAssessments } from '../../../shared';

@Component({
  selector: 'app-dashboard-accreditation-list',
  templateUrl: './accreditation-list.component.html',
  styleUrls: ['./accreditation-list.component.scss']
})
export class DashboardAccreditationListComponent implements OnChanges {
  @Input() assessments: Assessment[];

  assessmentGroups: GroupedAssessments[];

  constructor() {}

  ngOnChanges() {
    this.groupAssessments();
  }

  groupAssessments(): void {
    // TODO put this functionality in a service, as it would also be useful for the assessments menu.
    const statuses = [
      { label: 'Incomplete Assessments', class: 'incomplete', statuses: [] },
      { label: 'Pending Action', class: 'pending', statuses: [] },
      { label: 'Under Review', class: 'review', statuses: [AssessmentStatus.SUBMITTED, AssessmentStatus.RESUBMITTED] },
      { label: 'Completed Assessments', class: 'complete', statuses: [AssessmentStatus.COMPLETE] }
    ];

    if (!this.assessments) {
      this.assessmentGroups = [];
      return;
    }

    this.assessmentGroups = statuses.map(row => {
      return {
        name: row.label,
        class: row.class,
        list: this.assessments.filter(assessment => -1 !== row.statuses.indexOf(assessment.status.label)),
        isOpen: false
      };
    });
  }

  trackByFn(index: number, item: Assessment) {
    return item.id;
  }

  toggleGroup(row: GroupedAssessments): void {
    row.isOpen = !row.isOpen;
  }
}
