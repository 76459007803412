import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembershipPageComponent } from './membership.component';
import { RouterModule, Routes } from '@angular/router';
import { BootstrapComponentsModule } from 'src/app/shared/components/bootstrap/bootstrap.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingSpinnerModule } from '../../shared/components/loading-spinner/loading-spinner.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ReferenceValidatorModule } from '../../shared/components/reference-validator/reference-validator.module';
import { MembershipUpgradeComponent } from './membership-upgrade/membership-upgrade.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
import { MembershipUpgradeSummaryComponent } from './membership-upgrade-summary/membership-upgrade-summary.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SnackBarClass } from '../../shared/classes/snackbar.class';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MembershipUpgradeSuccessfulComponent } from './membership-upgrade-successful/membership-upgrade-successful.component';
import { MembershipFoundationComponent } from './membership-foundation/membership-foundation.component';
import { MembershipFoundationSummaryComponent } from './membership-foundation-summary/membership-foundation-summary.component';
import { SharedModule } from '../../shared/shared.module';
import { MembershipFoundationSuccessComponent } from './membership-foundation-success/membership-foundation-success.component';

const routes: Routes = [
  { path: '', component: MembershipPageComponent },
  { path: 'upgrade', component: MembershipUpgradeComponent },
  { path: 'foundation', component: MembershipFoundationComponent },
];

@NgModule({
  declarations: [
    MembershipPageComponent,
    MembershipUpgradeComponent,
    MembershipUpgradeSummaryComponent,
    MembershipUpgradeSuccessfulComponent,
    MembershipFoundationComponent,
    MembershipFoundationSummaryComponent,
    MembershipFoundationSuccessComponent,
  ],
  providers: [SnackBarClass],
  imports: [
    CommonModule,
    FormsModule,
    BootstrapComponentsModule,
    ReactiveFormsModule,
    ReferenceValidatorModule,
    LoadingSpinnerModule,
    RouterModule.forChild(routes),
    TooltipModule.forRoot(),
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatRippleModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatStepperModule,
    SharedModule,
  ],
  exports: [MembershipPageComponent, MembershipUpgradeComponent],
})
export class MembershipPageModule {}
