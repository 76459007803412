
export interface AssistConfiguration {
  assessment: string;
  title: string;
  displayInProducts: string[];
  documentLink: string;
  videoLink: string;
  applicationTypes: string[]|null;
  assessmentType: string|null;
}

export interface AssistType {
  applicationType: string;
  isDTS: boolean;
}
