<div class="direct-employees-text">
  Your figure should include all directly employed office staff including managers, directors, clerical etc.
</div>

<div class="grid">
  <app-form-field
    name="number_of_direct_employees"
    title="Direct Employees"
    [showError]="false"
    [showLabel]="false"
    [control]="form.controls.number_of_direct_employees"
    [tooltip]="employeesInfo"
    type="number">
  </app-form-field>

  <app-form-field
    name="number_of_bona_fide_subcontractors"
    title="Bona fide subcontractors"
    [showError]="false"
    [showLabel]="false"
    [control]="form.controls.number_of_bona_fide_subcontractors"
    [tooltip]="bonaFideInfo"
    type="number">
  </app-form-field>

  <app-form-field
    name="number_of_labour_only_subcontractors"
    title="Labour Only"
    [showError]="false"
    [showLabel]="false"
    [control]="form.controls.number_of_labour_only_subcontractors"
    [tooltip]="labourInfo"
    type="number">
  </app-form-field>

  <div class="grid__total">{{form.controls.employees.value}} Total number of employees</div>
</div>
