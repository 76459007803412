import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BootstrapComponentsModule } from '../bootstrap/bootstrap.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { UserCreateModalComponent } from './create.component';

import { UserInformationModalComponent, RolesMatrixComponent } from '../../../core';

@NgModule({
  declarations: [UserInformationModalComponent, RolesMatrixComponent, UserCreateModalComponent],
  imports: [CommonModule, BootstrapComponentsModule, LoadingSpinnerModule, ReactiveFormsModule],
  exports: [UserInformationModalComponent, UserCreateModalComponent]
})
export class UserInformationModalModule {}
