import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperComponent } from './helper.component';
import { QuestionsetHelperComponent } from './questionset-helper.component';
import { BootstrapComponentsModule } from '../bootstrap/bootstrap.module';

@NgModule({
    declarations: [HelperComponent, QuestionsetHelperComponent],
    exports: [HelperComponent],
    imports: [CommonModule, BootstrapComponentsModule]
})
export class HelperModule {}
