import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import MembershipData from './membership-data';

export interface IMembershipCard {
  id: string;
  name: string;
  headerImage?: string;
  image?: string;
  headerTitle?: string;
  title?: string;
  price: number | string;
  hiddenItems?: number[];
  tickedItems?: number[];
  unTickedItems?: number[];
  tickAll?: boolean;
}
export interface IMembershipCards {
  Individual: IMembershipCard;
  Standard: IMembershipCard;
  Advanced: IMembershipCard;
  Elite: IMembershipCard;
}

export enum BundleNamesAus {
  VerifiedContractor = 'Verified Contractor',
  Elite = 'Elite',
  VerifiedSupplier = 'Verified Supplier'
}

export enum BundleNames {
  Individual = 'Individual',
  Standard = 'Standard',
  Advanced = 'Advanced',
  Elite = 'Elite',
  Supplier = 'Supplier'
}

export enum ProductNumbers {
  Individual = 'FD BUNDLE',
  Standard = 'P-01101-J8J7M4',
  Advanced = 'P-01103-F6H3P3',
  Elite = 'P-01105-W8C9Y7',
  Supplier = 'CH-SUP-5-15'
}

export enum ProductNumbersAus {
  VerifiedContractor = 'CH-AU-VC',
  Elite = 'CH-AU-E',
  VerifiedSupplier = 'CH-AU-VS'
}

export enum ProductPrices {
  Individual = 'FREE',
  Standard = 279,
  Advanced = 449,
  Elite = 1399,
  Supplier = '???'
}

export enum ProductPricesAus {
  VerifiedContractor = 279,
  Elite = 1399,
  VerifiedSupplier = '???'
}

@Component({
  selector: 'app-select-membership',
  templateUrl: './select-membership.component.html',
  styleUrls: ['./select-membership.component.scss']
})
export class SelectMembershipComponent implements OnInit {
  @Input() public bundleName: string;
  @Output() public output = new EventEmitter<IMembershipCard>();
  public cards: IMembershipCards = new MembershipData().build();
  public selectedMembership: IMembershipCard = this.cards.Individual;

  public ngOnInit(): void {
    this.selectedMembership = this.cards[this.bundleName];
  }

  public onSelected(name: string): void {
    this.selectedMembership = this.findCard(name);
    this.output.emit(this.selectedMembership)
  }

  private findCard(name: string): IMembershipCard {
    return this.cards[name];
  }
}
