import { Component, Input, OnInit } from '@angular/core';
import { IntroductionText, AssistConfiguration } from '../../../index';
import { AssessmentMenuService } from 'src/app/core/services/assessment-menu.service';
import { DocumentService } from 'src/app/core/services/document.service';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';

@Component({
  selector: 'app-survey-introduction',
  templateUrl: './survey-introduction.component.html',
  styleUrls: ['./survey-introduction.component.scss']
})
export class SurveyIntroductionComponent implements OnInit{
  @Input() introText: IntroductionText;
  @Input() displaySurveyAssist: boolean;
  @Input() surveyAssistInfo: AssistConfiguration;
  customClass = 'customClass';
  private config: IEnvironment;
  expanded = true;
  constructor(
    private gtmService: GtmService,
    private assessmentMenuService: AssessmentMenuService,
    private documentService: DocumentService) { }

  async ngOnInit() {

    const env = new Environment();
    this.config = await env.getConfig();

    this.gtmService.pushTag({
      id: this.config.googleAnalytics.googleTagManagerId,
      event: 'assessment_module_loaded',
      title: this.introText.title
    });

    this.assessmentMenuService.getSectionSelected()
      .subscribe(selected => {
        this.expanded = selected;
      });
  }

  viewDocument(documentLink: string) {
    this.documentService.viewDocumentLink(documentLink);
  }

}



