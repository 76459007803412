import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CourseConfig } from 'src/app/shared/interfaces/course';
import courseConfigJSON from 'src/assets/json/courses-config.json';

@Component({
  selector: 'app-learning-pathway-tile',
  templateUrl: './learning-pathway-tile.component.html',
  styleUrls: ['./learning-pathway-tile.component.scss']
})
export class DashboardLearningPathwayTileComponent implements OnInit {
  @Output() emitRagClass = new EventEmitter();
  courseConfig: CourseConfig = courseConfigJSON;

  ngOnInit(): void {
    this.emitRagClass.emit(['white', false])
  }
}
