import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { Store } from '@ngrx/store';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';
import { UnspscCode } from 'src/app/shared/index';
@Component({
  selector: 'app-work-categories',
  templateUrl: './work-categories.component.html',
  styleUrls: ['./work-categories.component.scss'],
})
export class WorkCategoriesComponent implements OnInit, OnChanges {
  @Input() accountId: string;
  @Input() selectedWorkCategories: UnspscCode[];
  private currentUserState: CurrentUserState;
  public hasWorkCategories = false;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    this.hasWorkCategories = this.selectedWorkCategories.length !== 0;
  }

  public ngOnInit(): void {
    this.hasWorkCategories = this.selectedWorkCategories.length !== 0;
  }

  getDisplayString(workCategory: UnspscCode) {
    if (workCategory.classId === workCategory.segmentId) {
      return '';
    }
    if (workCategory.classId === workCategory.familyId) {
      return ` > ${workCategory.familyTitle}`;
    }
    return ` > ${workCategory.familyTitle} > ${workCategory.classTitle}`;
  }
}
