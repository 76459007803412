
export interface MembershipResponse {
  type: string;
  expirationDate: string;
}

export interface MembershipSummary{
  accountId: string;
	membershipId: string;
	membershipType: string;
	membershipExpiry: string;
	membershipStatus: string;
	currentOppourtunityOwner: string;
}

