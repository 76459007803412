import { Component } from '@angular/core';

@Component({
  selector: 'app-membership-foundation-success',
  templateUrl: './membership-foundation-success.component.html',
  styleUrls: ['./membership-foundation-success.component.scss']
})
export class MembershipFoundationSuccessComponent {

}
