import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectQuickQuote } from 'src/app/core/ngrx/selectors/quickQuote.selectors';
import { IQuickquote } from '../interfaces/new/quickQuote';

@Component({
  selector: 'app-register-fast-track',
  templateUrl: './register-fast-track.component.html',
  styleUrls: ['./register-fast-track.component.scss']
})
export class RegisterFastTrackComponent {
  @Input() bundleName: string;
  public readonly quickQuote$: Observable<IQuickquote> = this.store.select(selectQuickQuote);
  @Input() public control: AbstractControl;

  constructor(private readonly store: Store) {}

  public getFastTrackAmount(quickQuote: IQuickquote) {
    if(!quickQuote[this.bundleName.toLowerCase()]) { return '0.00'; }
    return quickQuote[
      this.bundleName.toLowerCase()
    ].lastQuoteResult.result.fastTrackAmount
  }
}
