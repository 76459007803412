import { Component, Input, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import {
    filter,
    debounceTime,
    distinctUntilChanged,
  } from 'rxjs/operators';
import { DocumentResponse, AddedFiles, QuestionInfo } from '../../index';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DocumentListComponent } from '../document-list/document-list.component';

@Component({
    selector: 'app-document-list-modal',
    templateUrl: './document-list-modal.component.html',
    styleUrls: ['./document-list-modal.component.scss']
})
export class DocumentListModalComponent implements OnInit {

    constructor() { }

    @ViewChild('documentSearch', { static: true })   documentSearch: ElementRef;
    @ViewChild(DocumentListComponent, { static: true}) documentList: DocumentListComponent;

    @Input() accountId: string;
    @Input() documents: DocumentResponse[];
    @Input() uploadedFiles: any[] = [];
    @Input() selectedQuestion: QuestionInfo;

    @Output() public closeModal = new EventEmitter<any>();
    @Output() public filesAdded = new EventEmitter<AddedFiles>();

    direction: string;
    column: string;
    sortIcon: string;

    rowsPerPage = 5;
    currentPage = 1;
    boundaryLinksLimit = 50;

    filteredDocuments: DocumentResponse[];

    error = '';
    isLoading = false;

    fgSearch = new UntypedFormGroup({
        term: new UntypedFormControl('')
    });
    filterTerm: string;
    selectedModule: string = null;
    documentModules: any[];

    ngOnInit() {
        if(!this.uploadedFiles){
            this.uploadedFiles = [];
        }
        this.documents = this.documents
          .map(d => {
            if (d.module) {
              const module = d.module.toLowerCase();
              if (module.indexOf('financial') > -1) {
                d.module = 'Financial';
              } else if (module.indexOf('verification') > -1) {
                d.module = d.module.replace('Verification', '').trim();
              } else if (module.indexOf('assessment') > -1) {
                d.module = d.module.replace('Assessment', '').trim();
              }
            }
            return d;
          });
        this.filteredDocuments = this.documents;
        this.documentModules = this.createModuleFilter(this.documents);

        fromEvent(this.documentSearch.nativeElement, 'keyup')
        .pipe(
            filter(Boolean),
            debounceTime(250),
            distinctUntilChanged()
        ).subscribe(_ => {
            this.search();
        });
    }

    upload(): void {
        const addedFiles = this.documentList.selectedDocuments;
        this.filesAdded.emit({
            questionInfo: this.selectedQuestion,
            uploadedFiles: addedFiles
        });
        
        this.documents = [...this.documents, ...addedFiles];
    
        this.closeModal.emit();
    }
    
    sort(event) {
        this.currentPage = 1;
        const sortFn = this.getSort(event.column, event.direction);
        this.filteredDocuments = this.filteredDocuments.sort(sortFn);
    }

    private getSort(column, direction) {
        if (column === 'name' || column === 'module' || column === 'type') {
            return (d1, d2) => {
                if (d1[column] === d2[column]) {
                    return 0;
                }
                if (d1[column] === null) {
                    return 1;
                }
                if (d2[column] === null) {
                    return -1;
                }
                if (d1[column]?.toUpperCase() > d2[column]?.toUpperCase()) {
                    return direction === 'asc' ? 1 : -1;
                }
                if (d1[column]?.toUpperCase() < d2[column]?.toUpperCase()) {
                    return direction === 'asc' ? -1 : 1;
                }
                return 0;
            };
        }

        if (column === 'date') {
            return (d1, d2) => {
                return direction === 'asc'
                    ? (d1.uploaded.getTime() - d2.uploaded.getTime())
                    : (d2.uploaded.getTime() - d1.uploaded.getTime());
            };
        }
        return null;
    }

    hasEnoughRowsForPagination(): boolean {
        if (this.filteredDocuments) {
            return this.filteredDocuments.length > this.rowsPerPage;
        }
        return false;
    }

    pageOfResults() {
        if (this.filteredDocuments) {
            const startRow = this.rowsPerPage * (this.currentPage - 1);
            return this.filteredDocuments.slice(
                startRow,
                startRow + this.rowsPerPage
            );
        }
    }

    pageChanged(event: any): void {
        this.currentPage = event.page;
    }

    onClose() {
        this.closeModal.emit();
    }

    search() {
      this.filteredDocuments = this.documents;
      this.currentPage = 1;

        this.filterTerm = this.fgSearch.value.term;
        if (this.filterTerm && this.filterTerm.length) {
          this.filterBySearchTerm();
        }

        if (this.selectedModule) {
          this.filterBySelectedModule();
        }
    }

    filterBySearchTerm() {
        this.filteredDocuments = this.documents.filter(
            row => -1 !== row.name.toLowerCase().indexOf(this.filterTerm.toLowerCase())
        );
    }

    filterBySelectedModule() {
          this.filteredDocuments = this.filteredDocuments
            .filter(d => d.module === this.selectedModule);
    }

    removeModuleFilter() {
        this.selectedModule = null;
        this.search();
    }

    createModuleFilter(documents: DocumentResponse[]) {
      const modules = [...new Set(documents.filter(d => d.module).map(d => d.module))];
      return modules.sort((a, b) => {
        return a.localeCompare(b);
      });
    }
}
