export interface LatestBundleResponse {
  bundleName: string;
  bundleId: string;
  bundleExpiry: string; // UTC timestamp.
  numEmployees: number;
  numberOfDirectEmployees: number;
  numberOfLabourOnlySubcontractors: number;
  numberOfBonaFideSubcontractors: number;
  variant: number;
  productAssessmentType: number;
  referenceCode?: string;
}