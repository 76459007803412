<div class="host" [ngClass]="ragStyle">
  <div class="text-center center_loading-spinner" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>

  <div class="product-bundle__badge" *ngIf="!isLoading">
    <img [src]="productImage" [attr.alt]="type">
  </div>

  <div class="product-bundle__info" [hidden]="isLoading">
    <h3 class="h5">
      {{ type ? type : 'Get CHAS Accredited' }}
      <span *ngIf="type === 'CHAS RAMS'">– Receive One Month Free</span>
    </h3>
    <div class="content">
      <app-compliance-tile *ngIf="checkComplianceCard"
                           [bundle]="bundle"
                           [authAccount]="authAccount"
                           [account]="account"
                           [membershipExpired]="membershipExpired"
                           [foundation]="foundation"
                           (emitRagClass)="getRagClass($event)">
      </app-compliance-tile>
      <app-business-shield-tile *ngIf="type === 'Business Shield'"
                                [bundle]="bundle"
                                [account]="account"
                                [activeCard]="active"
                                [authAccount]="authAccount"
                                [product]="product"
                                [typeAndVariant]="typeAndVariant"
                                [membershipExpired]="membershipExpired"
                                [foundation]="foundation"
                                (emitRagClass)="getRagClass($event)">
      </app-business-shield-tile>
      <app-rams-tile *ngIf="type === 'CHAS RAMS' || type === 'CHAS SWMS Coming Soon'"
                                [bundle]="bundle"
                                [account]="account"
                                [activeCard]="false"
                                [authAccount]="authAccount"
                                [product]="null"
                                [typeAndVariant]="null"
                                [membershipExpired]="null"
                                (emitRagClass)="getRagClass($event)">
      </app-rams-tile>
      <app-people-tile *ngIf="type === 'CHAS People'"
                       [authAccount]="authAccount"
                       [account]="account"
                       [product]="product"
                       [typeAndVariant]="typeAndVariant"
                       [membershipExpired]="membershipExpired"
                       [foundation]="foundation"
                       (emitRagClass)="getRagClass($event)">
      </app-people-tile>
      <app-fir-tile *ngIf="type === 'Fairness, Inclusion and Respect Growth Assessment'"
            [authAccount]="authAccount"
            [account]="account"
            [product]="product"
            [typeAndVariant]="typeAndVariant"
            [membershipExpired]="membershipExpired"
            [foundation]="foundation"
            (emitRagClass)="getRagClass($event)"
            [companyDetails]="companyDetails">
      </app-fir-tile>
      <app-plant-tile *ngIf="type === 'CHAS Plant'"
                      [authAccount]="authAccount"
                      [account]="account"
                      [product]="product"
                      [typeAndVariant]="typeAndVariant"
                      [membershipExpired]="membershipExpired"
                      [foundation]="foundation"
                      (emitRagClass)="getRagClass($event)"
                      [companyDetails]="companyDetails">
      </app-plant-tile>

      <app-human-focus-tile *ngIf="type === 'Online Training'"
                            [authAccount]="authAccount"
                            [account]="account"
                            [productName]="type"
                            [typeAndVariant]="typeAndVariant"
                            [membershipExpired]="membershipExpired"
                            [foundation]="foundation"
                            (emitRagClass)="getRagClass($event)"
                            [companyDetails]="companyDetails">
      </app-human-focus-tile>

      <app-learning-pathway-tile *ngIf="type === 'Learning Pathway'"
                            [authAccount]="authAccount"
                            [account]="account"
                            [productName]="type"
                            [typeAndVariant]="typeAndVariant"
                            [membershipExpired]="membershipExpired"
                            [foundation]="foundation"
                            (emitRagClass)="getRagClass($event)"
                            [companyDetails]="companyDetails">
      </app-learning-pathway-tile>

      <app-cas-third-party-tile *ngIf="type === 'Common Assessment Standard Third Party'"
                            [authAccount]="authAccount"
                            [account]="account"
                            [productName]="type"
                            [typeAndVariant]="typeAndVariant"
                            [membershipExpired]="membershipExpired"
                            [foundation]="foundation"
                            [bundle]="bundle"
                            (emitRagClass)="getRagClass($event)">
      </app-cas-third-party-tile>

      <app-credit-tile *ngIf="type === 'Credit Safe'"
                       [authAccount]="authAccount"
                       (emitRagClass)="getRagClass($event)">
      </app-credit-tile>
    </div>
  </div>

</div>

