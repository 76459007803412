
import { createReducer, on } from '@ngrx/store';
import { Environment } from 'src/app/shared/classes/environment';

const env = new Environment();
const environment = env.getConfigSync();
import { getStorage } from 'src/app/shared/functions/getBasket.functions';
import { registerStepFourAddSuccess, registerStepFourLoadSuccess, registerStepFourRemoveSuccess } from '../actions/registerStepFour.actions';

export const initialState = getStorage('registerStepFour');

const _registerStepFourReducer = createReducer(
  initialState,
  on(registerStepFourAddSuccess, (state, action) => action.payload),
  on(registerStepFourLoadSuccess, (state, action) => action.payload),
  on(registerStepFourRemoveSuccess, (state, action) => action.payload),
);

export function registerStepFourReducer(state, action) {
  return _registerStepFourReducer(state, action);
}
