import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import {
  CheckAuthenticationResponse,
  AccountResponse,
  ActiveCard,
  ThirdPartyProduct,
  GetQuoteRequest,
  GetQuoteResponse,
  AssessmentStatus,
  UserTypeCode,
} from '../../../../shared';
import { CreateOpportunityParameters } from '../../../../shared/interfaces/create-opportunity-parameters';
import { AssessmentsService, PaymentService, ProductService, DocumentService } from '../../../../core';
import { Observable } from 'rxjs';
import { BasketItemsActions } from 'src/app/core/ngrx/actions/basketItems.actions';
import { Store } from '@ngrx/store';
import { Assessment, DocumentCategory, DocumentCategoryResponse, DocumentResponse } from '../../../../shared';
import { map } from 'rxjs/operators';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';

const productName: string = 'Fairness, Inclusion and Respect Growth Assessment';

const FirDocumentNames = {
  Report: `FIR_Assessment_Report_`,
  Certificate: 'Certificate.pdf',
};

@Component({
  selector: 'app-fir-tile',
  templateUrl: './fir-tile.component.html',
  styleUrls: ['./fir-tile.component.scss'],
})
export class DashboardFirTileComponent implements OnInit {
  @Output() emitRagClass = new EventEmitter();
  @Output() emitLogin = new EventEmitter();
  @Input() authAccount: CheckAuthenticationResponse;
  @Input() account: AccountResponse;
  @Input() product: ThirdPartyProduct;
  @Input() typeAndVariant: { assessmentType: string; variant: string };
  @Input() membershipExpired: boolean;
  @Input() foundation: boolean;
  activeCard: ActiveCard = {
    name: productName,
    active: null,
    credits: null,
  };
  opportunityObject: CreateOpportunityParameters;
  loadingQuote: boolean = false;
  isLoading: boolean = false;
  modalbtnLoading: boolean;
  error: string;
  credits: number;
  status: string;
  quotePrice: any;
  links: any;
  purchasePeopleForm = new UntypedFormGroup({
    numWorkers: new UntypedFormControl('', [Validators.required]),
  });
  quoteHasVat: boolean = true;
  public hasFir: boolean = false;
  public assessment: Assessment;
  public documents$: Observable<any>;
  public description: string = `
    The ${productName},
    developed in partnership with the Supply Chain Sustainability School,
    demonstrates your commitment to making workplaces better for everyone.
  `;
  public documentNames = FirDocumentNames;
  public AssessmentStatus = AssessmentStatus;
  private readonly data$ = this.store.select(selectCurrentUserState);
  private config: IEnvironment;

  constructor(
    private readonly gtmService: GtmService,
    private readonly paymentService: PaymentService,
    private readonly productService: ProductService,
    private readonly assessmentsService: AssessmentsService,
    private readonly store: Store,
    private readonly documentService: DocumentService
  ) {}

  async ngOnInit() {
    const env = new Environment();
    this.config = await env.getConfig();

    this.data$.subscribe((data) => {
      this.assessmentsService.get(data.currentAccount.accountId).subscribe((assessments) => {
        this.assessment = assessments.find((assessment) => assessment.name === productName);

        if (this.assessment) {
          this.hasFir = true;

          switch (this.assessment.status.label) {
            case AssessmentStatus.IN_PROGRESS:
              this.status = AssessmentStatus.IN_PROGRESS;
              break;
            case AssessmentStatus.RESUBMITTED:
              this.status = AssessmentStatus.RESUBMITTED;
              break;
            case AssessmentStatus.SUBMITTED:
              this.status = AssessmentStatus.SUBMITTED;
              break;
            case AssessmentStatus.COMPLETE:
              this.status = AssessmentStatus.COMPLETE;
              break;
            case AssessmentStatus.IN_PROGRESS:
              this.status = AssessmentStatus.IN_PROGRESS;
              break;
            case AssessmentStatus.REJECTED:
              this.status = AssessmentStatus.REJECTED;
              break;
            case AssessmentStatus.ACCEPTED:
              this.status = AssessmentStatus.ACCEPTED;
              break;
            case AssessmentStatus.INFORMATION_REQUIRED:
              this.status = AssessmentStatus.INFORMATION_REQUIRED;
              break;
            default:
              break;
          }

          if (this.status === AssessmentStatus.COMPLETE) {
            this.documents$ = this.getDocuments();
          }
        }
      });

      this.getRagClass(false);
      this.pushGoogleTag();
    });
  }

  public get productImage(): string {
    return `assets/img/product-cards/fairness_inclusion_and_respect_growth_assessment.png`;
  }

  public findReport(documents: Object) {
    const documentKeys = Object.keys(documents);

    for (const key of documentKeys) {
      if (key.startsWith(FirDocumentNames.Report)) {
        return documents[key];
      }
    }
  }

  private getCurrentDocuments(categories: DocumentCategoryResponse[]): DocumentResponse[] {
    let currentDocuments = categories.find((c) => c.name === DocumentCategory.CurrentDocuments);
    if (!currentDocuments) return;
    return currentDocuments.documents;
  }

  private getFIRDocuments(documents: DocumentResponse[]) {
    return documents
      .filter((document) => {
        const isFirReport = document.name.startsWith(FirDocumentNames.Report);
        const isFirCertificate = document.name === FirDocumentNames.Certificate;

        if (isFirReport || isFirCertificate) return document;
      })
      .reduce(
        (obj, document) => ({
          ...obj,
          [document.name]: document,
        }),
        {}
      );
  }

  private getDocuments(): Observable<any> {
    return this.documentService.getAll(this.authAccount.AccountId).pipe(
      map((categories) => this.getCurrentDocuments(categories)),
      map((documents) => this.getFIRDocuments(documents))
    );
  }

  public download(document: DocumentResponse) {
    this.documentService.viewDocument(this.authAccount.AccountId, document);
  }

  private getRagClass(active: boolean): void {
    if (active) this.emitRagClass.emit(['green', false]);
    else this.emitRagClass.emit(['white', false]);
  }

  private pushGoogleTag(): void {
    this.gtmService.pushTag({
      id: this.config.googleAnalytics.googleTagManagerId,
      event: 'open_product_card_modal',
      product_card: this.activeCard.name,
    });
  }

  public async activateAync() {
    this.modalbtnLoading = true;
    const quote = await this.getQuoteAsync(true);
    await this.paymentService.activateAsync(quote.result.grandTotal, this.opportunityObject);
  }

  quoteAmount($event) {
    this.quotePrice = $event;
  }

  public async getQuoteAsync(createOpportunity?: boolean): Promise<GetQuoteResponse> {
    createOpportunity = createOpportunity ?? false;

    if (!this.purchasePeopleForm.value.numWorkers) {
      return null;
    }

    try {
      if (!createOpportunity) {
        this.loadingQuote = true;
      }

      const firData = await this.productService.firPriceList(this.purchasePeopleForm.value.numWorkers);

      const quoteRequest: GetQuoteRequest = {
        accountId: this.authAccount.AccountId,
        contactId: this.authAccount.ContactId,
        productId: firData.productId,
        numberOfEmployees: this.purchasePeopleForm.value.numWorkers,
        directEmployees: this.account.chas_numberofdirectemployees !== null ? this.account.chas_numberofdirectemployees : 0,
        labourOnlySubContractors: this.account.chas_labouronlysubcontractors !== null ? this.account.chas_labouronlysubcontractors : 0,
        bonafideSubContractors: this.account.chas_bonafidesubcontractors !== null ? this.account.chas_bonafidesubcontractors : 0,
        createOpportunity: createOpportunity,
        successUrl: this.config.urls.environmentUrl + '/payment-success/membership',
        cancelUrl: this.config.urls.environmentUrl,
        dataSharingAllowed: false,
        customerType: UserTypeCode.CONTRACTOR,
      };

      this.store.dispatch({
        type: BasketItemsActions.Add,
        item: quoteRequest,
      });

      const quote = await this.productService.getIndividualQuoteAsync(quoteRequest);

      this.quotePrice = quote.result.totalAmount;
      this.quoteHasVat = quote.result.vatPercent > 0;
      this.opportunityObject = this.productService.convertQuoteRequestToOpportunityObject(quoteRequest);
      this.loadingQuote = false;

      return quote;
    } catch (error) {
      this.error = 'An unexpected error occurred. Please try again in a few minutes.';
      this.loadingQuote = false;
      this.quotePrice = null;
      return null;
    }
  }
}
