export interface NotificationResponse {
  accountId: string;
  actions?: any[];
  area: string;
  contactId: string;
  content?: string;
  expiryDate?: string;
  hasBeenViewed?: boolean;
  id: string;
  isPrivate?: boolean;
  metadata?: any[];
  type?: string;
}
