import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/ngrx/reducers/notification.reducer';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { CheckAuthenticationResponse, NotificationResponse } from 'src/app/shared/index';
import { NotificationDisplayComponent } from '../notification-display-component';

@Component({
  selector: 'app-account-match-notification-display',
  template: `
  <strong><span>
  {{ data.content }}
  </span></strong>
  <div class="account-match-notification">
    <div class="item">
    	<strong><span>Old</span></strong><br>
        <span *ngFor="let oldData of data.metadata[0].value.Old.Details">{{oldData.Key}}: {{oldData.Value}}<br></span>
    </div>
    <div class="item">
      	<strong><span>New</span></strong><br>
        <span *ngFor="let newData of data.metadata[0].value.New.Details">{{newData.Key}}: {{newData.Value}}<br></span>
    </div>
  </div>
  <button class="btn btn-primary" (click)="dismissNotification(data)">
    <span>{{data.actions[0].type}}</span>
  </button>
  `,
  styleUrls: ['./account-match-notification-display.component.scss']
})
export class AccountMatchNotificationDisplayComponent extends NotificationDisplayComponent {

  constructor(
    protected router: Router,
    protected notifications: NotificationsService,
    protected store: Store<State>
  ) {
    super(router, notifications, store);
   }
  data: NotificationResponse;
  user: CheckAuthenticationResponse;
}
