import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NotificationResponse } from '../../../index';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { DocumentService } from '../../../../core/services/document.service';
import { Observable, Subscription } from 'rxjs';
import { DocumentModalComponent } from '../../document-modal/document-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AssessmentsService } from '../../../../core/services/assessments.service';
import { Store } from '@ngrx/store';
import { NotificationLoad } from 'src/app/core/ngrx/actions/notification.actions';
import { State } from 'src/app/core/ngrx/reducers/notification.reducer';
import { getCurrentNotification } from 'src/app/core/ngrx/selectors/notification.selectors';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';

@Component({
  selector: 'app-user-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @ViewChild('documentModal', { static: true }) settingsModal: DocumentModalComponent;
  private readonly notifications$: Observable<NotificationResponse[]> = this.store.select(getCurrentNotification);
  alerts: NotificationResponse[];
  isNotificationVisible: boolean = true;
  user: CurrentUserState;
  modalRef: BsModalRef;
  isFetching: boolean;
  refreshTimeout: any;
  docIcon: string;
  docIconColor: string;
  count: number = 0;
  error: string;
  assessmentIds: Array<string>;
  assessmentIdSubscription: Subscription;
  private getNotificationSubscription: Subscription;
  public showExpiringInFourWeeksAlert = false;
  private readonly data$ = this.store.select(selectCurrentUserState);
  public alertsExpiringInFourWeeks = false;

  constructor(
    public notifications: NotificationsService,
    public Documents: DocumentService,
    private assessmentsService: AssessmentsService,
    private store: Store<State>
  ) {
    this.alerts = [];
  }

  ngOnInit() {
    this.getCurrentUser();
    this.assessmentIdSubscription = this.assessmentsService.cachedAssessments().subscribe((assessments) => {
      this.assessmentIds = assessments.map((x) => x.id);
    });

    this.getNotificationSubscription = this.notifications$.subscribe({
      next: (notification) => {
        this.alerts = notification;
      },
      error: (error) => console.error(error),
    });
  }

  private getCurrentUser(): void {
    this.data$.subscribe(async (data) => {
      if (data.loaded) {
        this.user = data;

        this.getNotifications();
        this.alertsExpiringInFourWeeks = await this.checkAlertsExpiringInFourWeeks();
        if (this.alertsExpiringInFourWeeks) this.showExpiringInFourWeeksAlert = true;
      }
    });
  }

  ngOnDestroy() {
    clearTimeout(this.refreshTimeout);
    this.assessmentIdSubscription.unsubscribe();
    this.getNotificationSubscription.unsubscribe();
  }

  get hasAlerts(): boolean {
    return this.filteredAlerts.length > 0;
  }

  private async checkAlertsExpiringInFourWeeks(): Promise<boolean> {
    if (this.hasAlerts) {
      const alertsExpiringInNextMonth = this.alerts.filter((x) => this.getWeeksBetween(new Date(x.expiryDate), new Date()) < 4);
      const result = this.alerts?.length > 0 && alertsExpiringInNextMonth?.length > 0;
      return result;
    }
    return false;
  }

  public closeExpiringInFourWeeksAlert() {
    this.showExpiringInFourWeeksAlert = false;
  }

  getWeeksBetween(date1, date2) {
    // The number of milliseconds in one week
    var ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
    // Convert both dates to milliseconds
    var date1_ms = date1.valueOf();
    var date2_ms = date2.valueOf();
    // Calculate the difference in milliseconds
    var difference_ms = Math.abs(date1_ms - date2_ms);
    // Convert back to weeks and return hole weeks
    return Math.floor(difference_ms / ONE_WEEK);
  }

  getNotifications() {
    this.store.dispatch(
      NotificationLoad({
        accountId: this.user.currentAccount.accountId,
        contactId: this.user.currentAccount.contactId,
      })
    );
  }

  get filteredAlerts(): NotificationResponse[] {
    if (this.assessmentIds === null || this.assessmentIds === undefined || this.assessmentIds.length === 0) {
      return this.alerts;
    }

    const searchResult: NotificationResponse[] = [];
    // Remove alerts, where assessment is not shown
    const result = this.alerts.filter((alert) => {
      if (alert.metadata !== null) {
        const assessmentId = alert.metadata[0]?.value?.AssessmentId;
        if (assessmentId === undefined || assessmentId === null) {
          return true;
        }

        searchResult.push(alert);
        return this.assessmentIds.includes(assessmentId);
      }
    });

    return searchResult;
  }
}
