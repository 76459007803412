import {Injectable} from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import jsonUserRoles from 'src/assets/json/user-roles.json';
import { AccountContact } from '../../shared/interfaces/account-contact';
import {  ContactRole } from '../../shared/interfaces/contact-role';
import {  CreateContactParameters} from '../../shared/interfaces/create-contact-parameters';
import {  PrivilegeUpdate } from '../../shared/interfaces/privilege-update';
import {  UpdateContactParameters } from '../../shared/interfaces/update-contact-parameters';
import {  UserRolePrivilege } from '../../shared/constants/user-role-privilege.const';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { Territory } from 'src/app/shared/constants/territory.enum';
import { LastValueFromConfig } from 'rxjs/internal/lastValueFrom';
import { AccountStatus } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  private environment: IEnvironment;
  private isAus = false;

  constructor(private http: HttpClient) {
    const env = new Environment();
    this.environment = env.getConfigSync();
    this.isAus = this.environment.territory === Territory.AUSTRALIA;
  }

  /**
   * Fetch a list of roles associated with a contact ID.
   */
  getRoles(contactId: string): Observable<ContactRole[]> {
    return this.http
      .get(`${this.environment.apiEndpoint.newChasAPIv1}/contacts/contact/${contactId}/roles`)
      .pipe(map(data => data as ContactRole[]));
  }

  /**
   * Fetch a list of contacts associated with an account ID.
   */
  getContacts(accountId: string): Observable<AccountContact[]> {
    return this.http
      .get(
        `${this.environment.apiEndpoint.newChasAPIv1}/contacts/account/${accountId}/contacts`)
      .pipe(map((data: []) => {
        let mapped = new Array<AccountContact>();

        if(data == null) return mapped;

        data.forEach((contact: any) => {
          mapped.push({
            accountId: contact.accountId.uid,
            contactId: contact.id,
            firstname: contact.firstName,
            lastname: contact.lastName,
            email: contact.email,
            telephone: contact.telephone,
            mobilephone: contact.mobilePhone,
            contactname: contact.firstName + ' ' + contact.lastName,
            isPrimaryContact: contact.isPrimaryContact,
            position: contact.position,
            password: null,
            userType: contact.customerTypeId,
            accountStatus: this.getContactStatus(contact),
            organisationId: null,
            chasAlertFrequency: null,
            isLogMeInContact: contact.isLogMeInContact
          });
        });

        return mapped;
      }));
  }

  getContactStatus(contact): string {
    if(contact.accountLocked && contact.accountLocked) return AccountStatus.LOCKED;

    if(!contact.firstTimeLogin && !contact.isPrimaryContact) return AccountStatus.AWAITING_LOGIN;

    //if(contact.accountStatus && contact.accountStatus) return 'OK';

    return AccountStatus.OK;
  }


  /**
   * Update privileges associated with a contact ID for a role.
   */
  updateRoles(
    contactId: string,
    privileges: PrivilegeUpdate[]
  ): Observable<any> {
    // Privileges have to be saved individually, so we create a forkJoin to resolve everything at once.
     return this.http.post(
        `${this.environment.apiEndpoint.newChasAPIv1}/contacts/contact/${contactId}/roles`,
        privileges
      );
  }

  grantFullAccess(contactId: string){
    return this.updateRoles(contactId, this.getFullAccess());
  }

  private getFullAccess() {
    const fullAccess = [];
    for (const userRole of jsonUserRoles) {
      fullAccess.push({
        name: userRole,
        privilege: UserRolePrivilege.FULL_ACCESS
      } as PrivilegeUpdate);
    }
    return fullAccess;
  }

  /**
   * Create a new contact associated with an account.
   * If successful, a contact ID is returned.
   */
  createContact(params: CreateContactParameters): Observable<string> {
    const contact = {
      ...params,
      password: this.generateBasicFirstTimePassword(12),
      resetPasswordOnSignIn: true
    };

    let reqBody = {
      contact: contact
    };
    return this.http
      .post(`${this.environment.apiEndpoint.newChasAPIv1}/contacts/savecontact`, contact)
      .pipe(
        map(data => {
          // If the email address used already exists for a contact, error code ERR0001 is returned.
          if ('ERR0001' === data) {
            throw new Error(
              'The Email address already exists for another contact.'
            );
          }
          return data ? data.toString() : null;
        })
      );
  }

  /**
   * Create a new contact associated with an account.
   * If successful, a contact ID is returned.
   */
  public async createContactWithRolesAsync(params: CreateContactParameters, privileges: PrivilegeUpdate[]): Promise<string> {
    const reqBody = {
      ...params,
      password: this.generateBasicFirstTimePassword(12),
      resetPasswordOnSignIn: true,
      roles: privileges
    };

    let contactId: string = null;
    try{
      const result: string = await lastValueFrom<string>(this.http
        .post<string>(`${this.environment.apiEndpoint.newChasAPIv1}/contacts/savecontact`, reqBody)
        .pipe(
          map((data: any) => {
            return data;
          })
        )
      );

      // If the email address used already exists for a contact, error code ERR0001 is returned.
      if (result === 'ERR0001') {
        throw new Error('The Email address already exists for another contact.');
      }

      contactId = result;
    }
    catch(error)
    {
      if(error.status !== 200)
      {
        console.log(error);
        throw new Error(error.message);
      }

      contactId = error.error.text;
    }
    finally {

    }

    return contactId;
  }

  /**
   *
   */
  updateContact(params: UpdateContactParameters): Observable<any> {
    return this.http
      .patch(`${this.environment.apiEndpoint.newChasAPIv1}/contacts/updatecontactsummaryandsetprimary`, params)
      .pipe(
        map(data => {
          // If the email address used already exists for a[nother] contact, error code ERR0001 is returned.
          if ('ERR0001' === data) {
            throw new Error(
              'The Email address already exists for another contact.'
            );
          }
          return data ? data.toString() : null;
        })
      );
  }

  deactivateContact(contactId: string) :Observable<any> {
    return this.http.put(`${this.environment.apiEndpoint.newChasAPIv1}/contacts/contact/${contactId}/deactivate`, {})
  }

  reactivateContact(contactId: string) :Observable<any> {
    return this.http.put(`${this.environment.apiEndpoint.newChasAPIv1}/contacts/contact/${contactId}/reactivate`, {})
  }


  async updateRolesAsync(contactId: string, privileges: PrivilegeUpdate[]): Promise<void> {
    return await this.updateRoles(contactId, privileges).toPromise();
  }

  async createContactAsync(params: CreateContactParameters): Promise<string> {
    return await this.createContact(params).toPromise();
  }

  async updateContactAsync(params: UpdateContactParameters): Promise<any> {
    return await this.updateContact(params).toPromise();
  }

  private generateBasicFirstTimePassword(length): string {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 public convertToUpdateParams(contact: AccountContact): UpdateContactParameters {
  const params: UpdateContactParameters = {
    ContactId: contact.contactId,
    AccountId: contact.accountId,
    FirstName: contact.firstname,
    LastName: contact.lastname,
    Email: contact.email,
    Telephone: contact.telephone,
    Position: contact.position
  };

  return params;
 }

 public async getContactAsync(accountId: string, contactId: string): Promise<AccountContact> {
  var contacts = await this.getContacts(accountId).toPromise();
  return contacts.filter(data => data.contactId === contactId)[0];
 }
}
