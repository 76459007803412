import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, shareReplay, catchError } from 'rxjs/operators';
import { Observable, ReplaySubject, forkJoin, of, Subject, BehaviorSubject } from 'rxjs';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';

import { Assessment } from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class AssessmentsService {
  environment: IEnvironment;
  
  constructor(private http: HttpClient) {
    const env = new Environment();
    this.environment = env.getConfigSync();
  }

  private accountId: string;
  private $assessments = new Subject<Assessment[]>();
  private $assessmentsCache = new BehaviorSubject<Assessment[]>([]);

  cachedAssessments() {
    return this.$assessmentsCache.asObservable();
  }

  private setAssessments(assessments: Array<Assessment>) {
    this.$assessments.next(assessments);
    this.$assessmentsCache.next(assessments);
  }

  /**
   * Fetch a list of assessments associated with the specified account ID.
   */
  get(accountId: string): Observable<Assessment[]> {
    this.accountId = accountId;
    this.refresh();
    return this.$assessments.asObservable();
  }

  /**
   * Refresh the list of assessments for the currently used account ID.
   */
  refresh(): void {
    if (this.accountId) {
      const url = `${this.environment.apiEndpoint.assessments}/account/${this.accountId}/assessments`;
      this.http.get(url, { params: { viewerType: 1 }})
        .pipe(map(data => data as Assessment[]))
        .subscribe(
          assessments => this.setAssessments(assessments),
          response => this.$assessments.error(response)
        );
    } else {
      this.setAssessments(null);
    }
  }

  /**
   * Fetch SurveyJS JSON data for a specific questionset.
   */
  getQuestionSet(assessmentId: string) {

    return this.http.get(
      `${this.environment.apiEndpoint.newChasAPIv1}/questionsets/assessments/${assessmentId}/questionsets`
    );
  }

  /**
   * Fetch SurveyJS JSON data for a specific questionset section.
   */
  getQuestionSetSection(assessmentId: string, questionSet: string, question: string) {
    return this.http.get(
      `${this.environment.apiEndpoint.newChasAPIv1}/answers/${assessmentId}/${questionSet}/${question}`);
  }

  getQuestionSetAnswers(clientId, assessmentId) {
    return this.http.get(
      `${this.environment.apiEndpoint.newChasAPIv1}/answers/${assessmentId}/answers`);
  }

  saveQuestionSetAnswers(accountId, answers) {
    const assessmentId = answers.assessmentId;

    return this.http.put(
      `${this.environment.apiEndpoint.newChasAPIv1}/answers/account/${accountId}/assessment/${assessmentId}/answers`, answers);
  }

  createCase(accountId: string, notificationId: string, answers) {
    const assessmentId = answers.assessmentId;

    return this.http.post(
      `${this.environment.apiEndpoint.newChasAPIv1}/notifications/account/${accountId}/notification/${notificationId}/case`, answers);
    }

  saveCompletedQuestionSetAnswers(accountId, answers, verificationSectionComplete){
    const assessmentId = answers.assessmentId;

    let url = `${this.environment.apiEndpoint.newChasAPIv1}/answers/account/${accountId}/assessment/${assessmentId}/completed/answers`;
    if (verificationSectionComplete) {
      url += '?verificationSectionComplete=true';
    } else {
      url += '?verificationSectionComplete=false';
    }
    return this.http.put(
      url, answers);
  }

  getQuestionSetWithAnswers(clientId, assessmentId) {
    return forkJoin([
      this.getQuestionSet(assessmentId)
        .pipe(
          catchError((error: HttpErrorResponse) => of({}))),
      this.getQuestionSetAnswers(clientId, assessmentId)
        .pipe(
          catchError((error: HttpErrorResponse) => of({})))
    ]);
  }

  onCreateOpportunity(createOpportunityFn) {
    return this.get(this.accountId)
      .pipe(map(assessments => {
        let variantId = -1;

        const assessment = assessments
          .find(a => a.name.toLowerCase().indexOf('health') > -1);
        if (assessment) {
          variantId = assessment.variant.code;
        } else {
          variantId = assessments[0].variant.code;
        }

        return createOpportunityFn(variantId);
      }));
  }
}
