import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationResponse } from '../../shared';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private config: IEnvironment;
  constructor(private http: HttpClient) {
    const env = new Environment();
    this.config = env.getConfigSync();
  }


  getPublicNotifications() {
    const url = `${this.config.apiEndpoint.notifications}/shared/getpublicnotifications`;
    return this.http
      .get(url)
      .pipe(map(data => data as NotificationResponse[]));
  }


  getContractorNotifications(accountId: string, contractorId: string): Observable<NotificationResponse[]> {
    const url = `${this.config.apiEndpoint.notifications}/accounts/${accountId}/contacts/${contractorId}/getusernotifications`;
    return this.http
      .get(url)
      .pipe(map(data => data as NotificationResponse[]));
  }


  getNotifications(accountId: string, contactId: string): Observable<NotificationResponse[]> {
    const url = `${this.config.apiEndpoint.notifications}/accounts/${accountId}/contacts/${contactId}/getusernotifications`;
    return this.http
      .get(url)
      .pipe(map(data => {
        return data as NotificationResponse[];
      }));
  }


  dismissNotification(params: NotificationResponse) {
    const url = `${this.config.apiEndpoint.notifications}/accounts/${params.accountId}/contacts/${params.contactId}/saveusernotification`;
    return this.http
      .post(url, params);
  }

}


