import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { first, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-register-existing',
  templateUrl: './register-existing.component.html',
  styleUrls: ['./register-existing.component.scss']
})
export class RegisterExistingPageComponent {

  errorMessage: string;
  infoMessage: string;
  isCompleting: boolean;

  registerForm: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  completeRegistration(): void {
    if ( this.isCompleting ) {
      return;
    }

    this.resetMessages();
    const email = this.registerForm.value.username;

    if (email && this.registerForm.get('username').valid) {
      this.isCompleting = true;
      this.registerForm.disable();
      
    } else {
      this.errorMessage = 'Please enter your email address.';
    }
  }

  /**
   * 
   */
  private resetMessages(): void {
    this.errorMessage = null;
    this.infoMessage = null;
  }
}
