import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { ProductBundlesModule } from 'src/app/shared/components/product-bundles/product-bundles.module';
import { ApplicationTypesModule } from 'src/app/shared/components/application-types/application-types.module';
import { AssessmentTypesModule } from 'src/app/shared/components/assessment-types/assessment-types.module';
import { BootstrapComponentsModule } from 'src/app/shared/components/bootstrap/bootstrap.module';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';

import { RegisterPageComponent } from '../../features';
import { RouterModule, Routes } from '@angular/router';
import { HelperModule } from 'src/app/shared/components/helper/helper.module';
import { CompanySearchFieldModule } from 'src/app/shared/components/company-search-field/company-search-field.module';

const routes: Routes = [{ path: '', component: RegisterPageComponent }];

@NgModule({
  declarations: [RegisterPageComponent],
  exports: [RegisterPageComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CompanySearchFieldModule,
    BootstrapComponentsModule,
    ApplicationTypesModule,
    AssessmentTypesModule,
    LoadingSpinnerModule,
    ProductBundlesModule,
    HelperModule,
    RouterModule.forChild(routes),
    TypeaheadModule.forRoot(),
  ],
})
export class RegisterPageModule {}
