import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { SSOUrlParameters, CreditsResponse } from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class SSOService {

  constructor(private http: HttpClient) { }


  url(params: SSOUrlParameters): Observable<string> {
    return this.http.post(params.SSOUrl, params)
      .pipe(map((response: { url: string }) => response.url));
  }

  async urlAsync(params: SSOUrlParameters): Promise<string> {
    return await this.url(params).toPromise();
  }


  isActivated(params: SSOUrlParameters): Observable<boolean> {
    return this.http.post(params.SSOUrl, params).pipe(
      map((res: any) => {
          if (res.url && res.url.indexOf("success") === -1) return true;
          else return false;
        }
      ));
  }


  getCredits(params: SSOUrlParameters): Observable<number> {
    return this.http.get(params.CreditsUrls)
      .pipe(map((response: number) => {
        return response;
      }));    
  }

  getCreditBreakdown(params: SSOUrlParameters): Observable<CreditsResponse> {
    return this.http.get(params.CreditsUrls)
      .pipe(map((response: CreditsResponse) => {
        return response;
      }));    
  }

}
