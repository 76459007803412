import * as AppState from '../../../app.state'
import { createReducer, on } from "@ngrx/store";
import { NotificationResponse } from "src/app/shared";
import { NotificationDismiss, NotificationLoad, NotificationLoadSucess } from "../actions/notification.actions";

export interface State extends AppState.State{
    notifications: NotificationState;
}

export interface NotificationState {
    current: NotificationResponse[];
}

const InitialState: NotificationState = {
    current: []
}

export const notificationReducer = createReducer<NotificationState>(
    InitialState,
    on(NotificationDismiss, (state, payload) : NotificationState => {
        let notifications = [];
        state.current.forEach((value, index) => {
            if(value.id !== payload.notification.id) {
                notifications.push(value);
            }
        });
        return {
            ...state,
            current: notifications
        };
    }),
    on(NotificationLoad, (state) : NotificationState => {
        return {
            ...state
        }
    }),
    on(NotificationLoadSucess, (state, payload) : NotificationState => {
        return {
            ...state,
            current: payload.notifications,
        }
    })
);
