import { Injectable } from '@angular/core';
import { BasketService } from 'src/app/core';
import { GtmBrand, GtmListTypes, GtmUserCategory } from 'src/app/core/services/gtm/gtm.enum';
import { GtmProduct } from 'src/app/core/services/gtm/gtm.interface';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';
import { BasketItem } from 'src/app/shared';
import { IAccountFormValue } from 'src/app/shared/classes/createAccount.class';

@Injectable({
  providedIn: 'root'
})
export class RegisterTrackingService {
  constructor(
    private readonly basketService: BasketService,
    private readonly gtmService: GtmService
  ) { }


  public getQuote(formValue: IAccountFormValue) {
    const employees = parseInt(formValue.number_of_direct_employees);
    const labourSubs = parseInt(formValue.number_of_labour_only_subcontractors);

    let items: BasketItem[] = [];

    for (const product of this.basketService.items) {
      items.push({
        id: product.productID,
        name: product.name,
        price: product.price,
        brand: GtmBrand.CHAS,
        category: GtmUserCategory.Contractor,
      });
    }

    this.gtmService.addToCart({
      uid: this.basketService.accountID,
      effectiveEmployees: this.recalculateEmployees(
        employees,
        labourSubs
      ),
      directEmployees: employees,
      labourOnlySubcontractors: labourSubs,
      bonafideSubContraactors: parseInt(formValue.number_of_bona_fide_subcontractors),
      ecommerce: {
        add: {
          actionField: { list: GtmListTypes.signup },
          products: items
        }
      }
    });
  }

  public trackDirectEmployees() {
    const product = this.basketService.items[0];
    let item: GtmProduct = {
      id: product.productID,
      name: product.name,
      price: product.price,
      brand: GtmBrand.CHAS,
      category: GtmUserCategory.Contractor,
    };


    this.gtmService.selectNumberOfDirectEmployees({
      actionField: { list: GtmListTypes.signup },
      products: [item]
    });
  }

  public recalculateEmployees(
    employees: number,
    labourSubs: number
  ): number {
    let newVal = employees + labourSubs;

    if(this.basketService.items.length !== 0) {
      this.trackDirectEmployees();
    }

    return newVal;
  }
}
