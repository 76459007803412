import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuoteResponse } from 'src/app/shared';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { hsFromPrice092021, ppFromPrice092021, prFromPrice092021 } from 'src/app/shared/constants/baseline-prices.const';
import { IGetQuoteCreateOpportunityCommand, IInternalQuoteRequest, IQuoteRequest } from 'src/app/shared/interfaces/new/register-data';

@Injectable({
  providedIn: 'root',
})
export class PriceService {
  private environment: IEnvironment;

  constructor(private readonly http: HttpClient) {
    const env = new Environment();
    this.environment = env.getConfigSync();
  }

  public getRawPrice(level: number): number {
    switch (level) {
      case 0:
        return 0;
      case 1:
        return parseInt(hsFromPrice092021);
      case 2:
        return null;
      case 3:
        return parseInt(ppFromPrice092021);
      case 4:
        return parseInt(prFromPrice092021);
      default:
        return null;
    }
  }

  public getPrice(level: number): string {
    switch (level) {
      case 0:
        return '0';
      case 1:
        return `${hsFromPrice092021}`;
      case 2:
        return 'N/A';
      case 3:
        return `${ppFromPrice092021}`;
      case 4:
        return `${prFromPrice092021}`;
      default:
        return 'N/A';
    }
  }

  public async getQuoteAsync(quoteRequest: IGetQuoteCreateOpportunityCommand): Promise<QuoteResponse> {
    return await this.http.post<QuoteResponse>(`${this.environment.apiEndpoint.newChasAPIv1}/pricing/quote`, quoteRequest).toPromise();
  }

  public async getQuoteBundlesAsync(quotecommand: IGetQuoteCreateOpportunityCommand): Promise<QuoteResponse[]> {
    return await this.http.post<QuoteResponse[]>(`${this.environment.apiEndpoint.newChasAPIv1}/pricing/quotes`, quotecommand).toPromise();
  }

  public async getQuotesOnlyAsync(quoteRequest: IInternalQuoteRequest): Promise<QuoteResponse[]> {
    return await this.http
      .post<QuoteResponse[]>(`${this.environment.apiEndpoint.newChasAPIv1}/pricing/quotesonly`, quoteRequest)
      .toPromise();
  }
}
