import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';

@Injectable({
  providedIn: 'root'
})
export class HashingService {

  constructor() {
  }

  public createHashForObject(object: any): any {
      return (object !== null && object !== undefined) ? new Md5().appendStr(JSON.stringify(object)).end() : "";
  }
}
