import { Component, OnInit, Input } from '@angular/core';
import { AccountsService } from '../../../core';
import { featureFlagNames } from '../../index';
import { finalize } from 'rxjs/operators';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { Store } from '@ngrx/store';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';

@Component({
  selector: 'app-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.scss'],
})
export class PrivacyModalComponent implements OnInit {
  private readonly data$ = this.store.select(selectCurrentUserState);
  @Input() modalRef: any;
  @Input() accountId: string;
  dataSharingSelected: boolean;
  isLoading = false;
  cqmsEnabled: boolean = true;

  constructor(private accountService: AccountsService, private readonly store: Store) {}

  async ngOnInit(): Promise<void> {
    this.dataSharingSelected = false;

    this.data$.subscribe((data: CurrentUserState) => {
      if (data.loaded) {
        this.dataSharingSelected = data.currentAccount.casDataSharingEnabled;
      }
    });

  }

  savePreferences(): void {
    this.isLoading = true;

    this.accountService
      .saveDataSharingPreference(this.accountId, this.dataSharingSelected)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.close();
        })
      )
      .subscribe();
  }

  close(): void {
    this.modalRef.hide();
  }
}
