<div class="modal-dialog modal-lg">
  <div class="modal-content">

    <div class="modal-header">
      <h4 class="modal-title pull-left">Common Assessment Standard Data Sharing Preferences</h4>
      <button type="button" id="close-btn" class="close pull-right" (click)="close()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body privacy-body">
      <div class="mb-1 px-2 pt-2">
        <div class="row">
          <div class="col-md-8">
            <p>Maximise your visibility and demonstrate your standards
              to clients across the UK Market.</p>
            <p>
            By agreeing to share your data, your company will have increased visibility
            to over 8,000 clients through 3 leading UK schemes.
            Your data will only be shared with other approved Common Assessment Standard
            providers and will not be used for marketing or any other purposes.
            </p>
          </div>
          <div class="col-md-4">
            <img src='../../../../assets/img/bundle/elite.png'
                 class="bundle-image"
                 alt="Elite logo"/>
          </div>
        </div>
        <div class="row pt-4 px-2">
          <div class="col-12 form-group form-check">
            <input type="checkbox" id="preferences-box"
                   [checked]="dataSharingSelected"
                   (change)="dataSharingSelected = !dataSharingSelected"
                   class="form-check-input"/>
          <label for="preferences-box" class="form-check-label pointer">

            <p>
              I agree to relevant information held by CHAS being shared
              with other Recognised Assessment Bodies for the Common Assessment
              Standard. I am an authorised representative with sufficient delegated
              powers to provide the consent specified above.
            </p>
            <p>In order for your information to be shared with other Recognised Assessment Bodies, you will need to register with them too:</p>
            <ul>
              <li><a href="https://www.achilles.com/cas-data-request/" target="_new">Click here to register with Achilles</a></li>
              <li><a href="https://www.constructionline.co.uk/support/data-portability-request" target="_new">Click here to register with Constructionline</a> </li>
              <li *ngIf="cqmsEnabled"><a href="https://cqms-ltd.co.uk/cas-data-request/" target="_new">Click here to register with CQMS</a> </li>
            </ul>
            <p>
              Click <a href='/Key-Questions-for-Data-Sharing-Version-2.pdf'
                       target="_blank"><u>here</u></a> for more information on the Common Assessment Standard and data
              sharing.
            </p>
          </label>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button class="btn btn-primary px-5" [class.is-loading]="isLoading" type="button" (click)="savePreferences()">
        Save Preferences
      </button>
    </div>
  </div>
</div>
