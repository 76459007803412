import { CreateAccountParameters } from "..";
import { Environment, IEnvironment } from "./environment";

export interface IAccountFormValue {
    companyNumber: string;
    abNumber: string;
    acNumber:string;
    companyName: string;
    street_number: string;
    route: string;
    postal_town: string;
    postal_code: string;
    country_code: string;
    firstName: string;
    lastName: string;
    contactEmail: string;
    contactEmailConfirm: string;
    contactPassword: string;
    contactPasswordConfirm: string;
    contactPhone: string;
    number_of_direct_employees: string;
    number_of_labour_only_subcontractors: string;
    employees: string;
    number_of_bona_fide_subcontractors: string;
    referenceCode: string;
    Bundle: string;
    BundleName: string;
    applicationTypeCode: string;
    assessmentTypeCode: string;
    canSubmitControl: boolean;
    fastTrack: boolean;
    confirmBuildUk: boolean;
    customerType: string;
    isAusGstRegistered: boolean;
    longitude: string | null;
    latitude: string | null;
}

interface IAccountCreateParams {
  account: IAccountFormValue;
  countryName: string;
  creditSafeNumber?: string;
  validatedReferenceCode?: string;
}

export class CreateAccount {
  private config: IEnvironment;
    
  constructor() {
    const env = new Environment();
    this.config = env.getConfigSync();
  }
  public createParams(
    data: IAccountCreateParams
  ): CreateAccountParameters {
    let regDetails: any = {
      AssessmentType: data.account.assessmentTypeCode,
      ProductNumber: data.account.Bundle,
      ClientReferenceCode: data.validatedReferenceCode,
      CompanyHouseNo: data.account.companyNumber,
      CompanyName: data.account.companyName,
      ContactFirstName: data.account.firstName,
      ContactLastName: data.account.lastName,
      CustomerType: data.account.customerType,
      EmailAddress: data.account.contactEmail,
      NoOfEmployees: data.account.employees,
      isAusGstRegistered: data.account.isAusGstRegistered,
      longitude: data.account.longitude,
      latitude: data.account.latitude,
      NumberofDirectEmployees: parseInt(data.account.number_of_direct_employees),
      LabourOnlySubContractors: parseInt(data.account.number_of_labour_only_subcontractors),
      BonafideSubContractors: parseInt(data.account.number_of_bona_fide_subcontractors),
      Password: data.account.contactPassword,
      RegAddressline1: data.account.street_number,
      RegAddressline2: data.account.route,
      RegCity: data.account.postal_town,
      RegPostcode: data.account.postal_code != null && data.account.postal_code.length > 0 ? data.account.postal_code : null,
      RegCountry: data.countryName,
      RegCountryCode: data.account.country_code,
      Telephone1: data.account.contactPhone,
      Mobile1: null,
      TradingAs: null,
      Variant: data.account.applicationTypeCode,
      SuccessUrl: this.config.urls.environmentUrl + '/payment-success/register',
      CancelUrl: this.config.urls.environmentUrl + '/order-summary',
      SkipOpportunity: true
    };

    if (data.creditSafeNumber) regDetails.CreditSafeNumber = data.creditSafeNumber;

    return regDetails;
  }
}
