export interface AccountListItem {
  AccountId: string | null;
  AccountList: AccountListItem[] | null;
  AccountName: string;
  Address: string | null;
  ContactId: string | null;
  FirstTimeLogin: boolean;
  FullName: string | null;
  MembershipId: string | null;
  PasswordExpired: boolean;
  Position: string | null;
  ReadOnlyLogin: boolean;
  TikforceUserKey: string | null;
  UserType: string | null;
}