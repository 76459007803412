import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as CurrentUserActions from '../actions/currentUser.actions';
import { AccountsService } from '../../index';

@Injectable()
export class CurrentUserEffects {
  loadCurrentUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CurrentUserActions.loadCurrentUser),
      concatMap((action: { accountId: string }) => {
        return this.accountsService.getCurrentUser(action.accountId).pipe(
          map((data) => {
            return CurrentUserActions.loadCurrentUserSuccess({
              data: data,
            });
          }),
          catchError((error) => {
            return of(CurrentUserActions.loadCurrentUserFailure({ error }));
          })
        );
      })
    );
  });

  constructor(private actions$: Actions, private readonly accountsService: AccountsService) {}
}
