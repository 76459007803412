export interface CarouselConfig {
  enabled: boolean;
  secondsPerCard: number;
  randomise: boolean;
  cookieExpireDays: number;
  onCloseChangeSlide: boolean;
  firstShowDelaySeconds: number;
  cards: Array<CarouselCard>;
}

export interface CarouselCard {
  id: string;
  backgroundColor: string;
  foregroundColor: string;
  contentHtml: string;
  imageUrl: string;
  validFrom: string;
  validTo: string;
  callToActionText: string;
  callToActionUrl: string;
  callToActionClass: string;
  onlyIfProductCardShown: boolean;
  featureFlagId: string;
}
