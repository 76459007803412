import { DateTime, Interval } from 'luxon';
import { Environment } from '../classes/environment';

export const getStorage = (storageKey: string) => {
  const env = new Environment();
  const config = env.getConfigSync();

  const storage = sessionStorage.getItem(storageKey);
  let result: any;

  if (storage) {
    result = JSON.parse(storage);

    if (result?.expiry) {
      const expiry = DateTime.fromISO(result.expiry);
      const now = DateTime.now();
      const timeSince = Interval.fromDateTimes(expiry, now).toDuration('minutes').minutes;

      if (timeSince > config.registerExpiryMinutes) {
        sessionStorage.removeItem(storageKey);
        sessionStorage.removeItem('registerStep');
        result = undefined;
      }
    }
  } else {
    result = undefined;
  }

  return result;
};

export const setStorage = (item: any, storageKey: string, shouldExpire = true) => {
  let record = item;

  if (shouldExpire) {
    const now = DateTime.now().toISO();
    let record = structuredClone(item);
    if (record) record.expiry = now;
  }

  sessionStorage.setItem(storageKey, JSON.stringify(record));
  return getStorage(storageKey);
};
