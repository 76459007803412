import { Component, OnInit, Output, EventEmitter, TemplateRef, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AccountResponse, ActiveCard, ThirdPartyProduct, SSOUrlParametersGenerator, GetQuoteRequest, UserTypeCode, CheckNewAuthenticationResponse, CompanyDetails }
  from '../../../../shared';
import { CreateOpportunityParameters } from '../../../../shared/interfaces/create-opportunity-parameters';
import { SSOService, ProductService, PaymentService } from '../../../../core';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { BasketItemsActions } from 'src/app/core/ngrx/actions/basketItems.actions';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';

@Component({
  selector: 'app-human-focus-tile',
  templateUrl: './human-focus-tile.component.html',
  styleUrls: ['./human-focus-tile.component.scss']
})

export class DashboardHumanFocusTileComponent implements OnInit, OnChanges {
  @Output() emitRagClass = new EventEmitter();
  @Output() emitOpenModal = new EventEmitter();
  @Output() emitLogin = new EventEmitter();
  @Input() authAccount: CheckNewAuthenticationResponse;
  @Input() companyDetails: CompanyDetails;
  @Input() account: AccountResponse;
  @Input() productName: string;
  @Input() typeAndVariant: { assessmentType: string, variant: string };
  @Input() membershipExpired: boolean;
  @Input() foundation: boolean;
  activeCard: ActiveCard = { name: 'CHAS_Human_Focus', active: null, credits: null };
  private $destroyed = new Subject();
  quotePrice: any;
  quoteHasVat: boolean = true;
  availableBundles: Array<ThirdPartyProduct> = [];
  opportunityObject: CreateOpportunityParameters;
  loadingQuote: boolean = false;
  isLoading: boolean = false;
  modalbtnLoading: boolean;
  error: string;
  credits: number;
  purchaseHumanFocusForm = new UntypedFormGroup({
    numWorkers: new UntypedFormControl(''),
    selectedBundle: new UntypedFormControl('')
  });
  creditBreakdown: Record<string, number>;
  isPending: boolean = false;
  groupedAvailableBundles: any[];
  private config: IEnvironment;

  constructor(
    private gtmService: GtmService,
    private SSO: SSOService,
    private Products: ProductService,
    private Payment: PaymentService,
    private readonly store: Store
  ) { }

  async ngOnInit(): Promise<void> {
    const env = new Environment();
    this.config = await env.getConfig();
    this.loadHumanFocusBundles();
  }

  loadHumanFocusBundles() {
    this.Products.getThirdPartyProducts().subscribe((data: ThirdPartyProduct[]) => {
      this.availableBundles = data.filter(x => x.hierarchyPath == "Human Focus");
      this.groupedAvailableBundles = this.getSortedBundles(this.availableBundles);
    });
  }

  ngOnChanges(): void {
    this.getCredits();
  }

  get productImage(): string {
    return `assets/img/product-cards/human_focus.png`;
  }


  setRagClass(state) {
    this.emitRagClass.emit([state, false]);
  }


  async loginAsync(): Promise<void> {

    if (this.isLoading) { return; }

    const params = new SSOUrlParametersGenerator(
      this.authAccount.accountId,
      this.authAccount.contactId
      ).humanFocus();

    try {
      this.error = null;
      this.isLoading = true;
      let redirectUrl: string = await this.SSO.urlAsync(params);
      window.open(redirectUrl, "_blank");
    }
    catch(error) {
      this.error = error.message;
    }
    finally {
      this.isLoading = false;
    }
  }

  pushGoogleTag(): void {
    this.gtmService.pushTag({
      id: this.config.googleAnalytics.googleTagManagerId,
      event: 'open_product_card_modal',
      product_card: this.activeCard.name
    });
  }


  getCredits() {
    let params = new SSOUrlParametersGenerator(this.authAccount.accountId, this.authAccount.contactId);
    this.SSO.getCreditBreakdown(params.switchProvider(this.activeCard.name))
      .pipe(takeUntil(this.$destroyed))
      .subscribe(res => {
        this.activeCard.credits = res.Credits;
        this.creditBreakdown = res.Breakdown;
        this.isPending = res.IsPending;

        if (res.Credits > 0) {
          this.setRagClass('green');
          this.activeCard.active = true;
        }
        else if(res.IsPending) {
          this.setRagClass('amber');
        }
        else {
          this.setRagClass('white');
        }
      });
  }

  getSSO() {
    let params = new SSOUrlParametersGenerator(this.authAccount.accountId, this.authAccount.contactId);
    this.SSO.isActivated(params.switchProvider(this.activeCard.name))
      .pipe(takeUntil(this.$destroyed))
      .subscribe(res => {
        this.activeCard.active = res;
      }, (response: HttpErrorResponse) => {
        if (response.status === 404) {
          this.activeCard.active = false;
        }
      });
  }

  get creditBreakdownList() {
    if(this.creditBreakdown == null) return [];
    return Object.entries(this.creditBreakdown).map(([key, value]) =>
      this.stripHumanFocusPrefixFromProductName(key) + " - " + value + " workers");
  }

  get payButtonDisabled() {
    const bundle = this.purchaseHumanFocusForm.value.selectedBundle;
    const workers = this.purchaseHumanFocusForm.value.numWorkers;
    return !bundle || !workers;
  }

  async activateAync() {
    this.modalbtnLoading = true;
    await this.getQuoteAsync(true);
    await this.Payment.activateAsync(this.quotePrice, this.opportunityObject);
  }

  async getQuoteAsync(createOpportunity?: boolean) {
    createOpportunity = createOpportunity ?? false;
    let numberOfEmployees = this.purchaseHumanFocusForm.value.numWorkers;

    if(!numberOfEmployees || numberOfEmployees === 0 || numberOfEmployees.length === 0) {
      numberOfEmployees = 1;
    }

    if(!this.purchaseHumanFocusForm.value.selectedBundle?.id) {
      return;
    }

    try {
      if(!createOpportunity) { this.loadingQuote = true; }

      const quoteRequest: GetQuoteRequest = {
        accountId: this.authAccount.accountId,
        contactId: this.authAccount.contactId,
        productId: this.purchaseHumanFocusForm.value.selectedBundle.id,
        numberOfEmployees: numberOfEmployees,
        directEmployees: this.companyDetails.numberOfDirectEmployees !== null? this.companyDetails.numberOfDirectEmployees : 0,
        labourOnlySubContractors: this.companyDetails.numberOfLabourOnlySubcontractors !== null? this.companyDetails.numberOfLabourOnlySubcontractors: 0,
        bonafideSubContractors: this.companyDetails.numberOfBonaFideSubcontractors  !== null? this.companyDetails.numberOfBonaFideSubcontractors : 0,
        createOpportunity: createOpportunity,
        successUrl: this.config.urls.environmentUrl + '/payment-success/membership',
        cancelUrl: this.config.urls.environmentUrl,
        dataSharingAllowed: false,
        customerType: UserTypeCode.CONTRACTOR
      };

      this.store.dispatch({
        type: BasketItemsActions.Add,
        item: quoteRequest
      });

      const quote = await this.Products.getIndividualQuoteAsync(quoteRequest);

      if(quote.error) {
        this.error = quote.error.message;
        this.loadingQuote = false;
        this.quotePrice = null;
        return;
      }

      this.quotePrice = quote.result.totalAmount;
      this.quoteHasVat = quote.result.vatPercent > 0;
      this.opportunityObject = this.Products.convertQuoteRequestToOpportunityObject(quoteRequest);
      this.loadingQuote = false;
    }
    catch (error) {
      this.error = 'An unexpected error occurred. Please try again in a few minutes.';
      this.loadingQuote = false;
      this.quotePrice = null;
    }
  }

  stripHumanFocusPrefixFromProductName(name: string): string {
    if(!name) return name;
    return name.replace('Human Focus - ', '');
  }

  get showUnregisteredContent(): boolean {
    if(this.isPending) return false;
    return !this.activeCard.credits || this.activeCard.credits <= 0;
  }

  get showRegisteredContent(): boolean {
    if(this.isPending) return false;
    return this.activeCard.credits && this.activeCard.credits > 0;
  }

  get showPendingState(): boolean {
    return this.isPending;
  }

  getSortedBundles(bundles: ThirdPartyProduct[]): Array<any> {
    const sortedBundles = bundles.sort((a, b) => {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    let grouped = [];

    sortedBundles.forEach(bundle => {
      let groupName = 'Other';
      let bundleName = this.stripHumanFocusPrefixFromProductName(bundle.name);

      if(bundleName.includes('-')) {
        const indexOfMarker = bundleName.indexOf('-');
        groupName = bundleName.substring(0, indexOfMarker).trim();
        bundleName = bundleName.substring(indexOfMarker + 1, bundleName.length).trim();
      }

      let group = grouped.find(g => g.name == groupName);

      if(!group) {
        group = { name: groupName, bundles: [] };
        grouped.push(group);
      }

      let bundleCopy = Object.assign({}, bundle);
      bundleCopy.name = bundleName;
      group.bundles.push(bundleCopy);
    });

    return grouped;
  }

}
