import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StripeLandingComponent } from './stripe-landing.component';
import { Routes, RouterModule } from '@angular/router';
import { BootstrapComponentsModule } from '../../core';
import { LoadingSpinnerModule } from '../../shared/components/loading-spinner/loading-spinner.module';
import { ChasLogoModule } from '../../shared/components/chas-logo/chas-logo.module';
import { SiteFooterModule } from '../../shared/components/site-footer/site-footer.module';


const routes: Routes = [
  {
    path: 'register',
    component: StripeLandingComponent,
    data: { purchased: 'register' }
  },
  {
    path: 'register-foundation',
    component: StripeLandingComponent,
    data: { purchased: 'foundation' }
  },
  {
    path: 'membership',
    component: StripeLandingComponent,
    data: { purchased: 'membership' }
  }
]

@NgModule({
  declarations: [StripeLandingComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    BootstrapComponentsModule,
    LoadingSpinnerModule,
    ChasLogoModule,
    SiteFooterModule
  ],
  exports: [StripeLandingComponent]
})
export class StripeLandingModule { }
