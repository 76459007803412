import { createAction, props } from '@ngrx/store';

export interface IMsalAuth {
  loaded: boolean;
  payload: string | null;
}

export enum MsalAuthActions {
  Add = '[MSAL Auth] Add',
  AddSuccess = '[MSAL Auth] Add Successful',
  AddError = '[MSAL Auth] Add Error',
  Remove = '[MSAL Auth] Remove',
  RemoveSuccess = '[MSAL Auth] Remove Successful',
  RemoveError = '[MSAL Auth] Remove Error',
  Load = '[MSAL Auth] Load',
  LoadSuccess = '[MSAL Auth] Load Successful',
  LoadError = '[MSAL Auth] Load Error'
}

export const msalAuthAdd = createAction(MsalAuthActions.Add, props<{ payload: IMsalAuth }>());
export const msalAuthAddSuccess = createAction(MsalAuthActions.AddSuccess, props<{ payload: IMsalAuth }>());
export const msalAuthAddError = createAction(MsalAuthActions.AddError);

export const msalAuthRemove = createAction(MsalAuthActions.Remove);
export const msalAuthRemoveSuccess = createAction(MsalAuthActions.RemoveSuccess, props<{ payload: IMsalAuth }>());
export const msalAuthRemoveError = createAction(MsalAuthActions.RemoveError);

export const msalAuthLoad = createAction(MsalAuthActions.Load);
export const msalAuthLoadSuccess = createAction(MsalAuthActions.LoadSuccess, props<{ payload: IMsalAuth }>());
export const msalAuthLoadError = createAction(MsalAuthActions.LoadError);
