<div class="inner_content">
  <div class="card_text" *ngIf="!thirdPartyRegistered">
    <p>Upgrading to CHAS Elite gives you access not only to the Common Assessment Standard but also to expert advice and
      support while you prepare for the assessment.</p>
    <p>Alternatively, registering your existing <strong>Common Assessment Standard</strong> certification is simple,
      quick, and free with CHAS.</p>
    <p>It allows us to provide evidence of your certification via the CHAS Client Portal.</p>
  </div>

  <div class="card_text" *ngIf="thirdPartyRegistered">
    <ul class="fa-ul" [ngClass]="{ 'check': isVerified }">
      <li><span class="fa-ali"></span>Recognised Assessment Body: {{thirdPartyCasProvider}} </li>
      <li><span class="fa-ali"></span>Registration Number: {{thirdPartyCasMembershipNumber}} </li>
      <li *ngIf="!dateCasDataUpdatedIsPopulated()"><span class="fa-ali"></span>Status: Data Requested</li>
      <li *ngIf="dateCasDataUpdatedIsPopulated()"><span class="fa-ali"></span>Status: Active</li>
      <li *ngIf="dateCasDataUpdatedIsPopulated()"><span class="fa-ali"></span>Last Synchronised: {{dateCasDataUpdated}}
      </li>
    </ul>
  </div>


  <div class="card_buttons" *ngIf="!thirdPartyRegistered">
    <button class="btn btn-primary on_card" (click)="casModal.show(); pushGoogleTag();">Register existing
      accreditation</button>
    <button class="btn btn-primary on_card" [routerLink]="['/membership/upgrade']"
      *ngIf="!HasEliteMembership">Upgrade
      to CHAS Elite</button>
  </div>
  <div class="card_buttons" *ngIf="thirdPartyRegistered">
    <button class="btn btn-primary not_active" (click)="updateAccountCasDataAsync(true)">
      Remove Accreditation
    </button>
  </div>
</div>




<div bsModal #casModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-sizes-name1" class="modal-title pull-left">Common Assessment Standard Third Party Registration
        </h4>
        <button type="button" class="close pull-right" #closebutton (click)="casModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="row no-gutters">
          <div class="col-md-5 col-sm-12 order-md-2">
            <img class="bundle-image" [src]="productImage" />
          </div>
          <div class="col-md-7 col-sm-12 order-md-1">
            <form [formGroup]="casThirdPartyForm">
              <div class="form-group">
                <p>Where did you achieve your Common Assessment Standard accreditation?</p>
                <div class="form-group">
                  <select #selectedProviderInput class="custom-select" formControlName="selectedProviderInput"
                    (change)="setSelectedProvider();">
                    <option hidden value="" selected>Select a Recognised Assessment Body:</option>
                    <option value="885570000">Achilles</option>
                    <option value="885570001">Constructionline</option>
                    <option value="885570002">CQMS</option>
                  </select>
                </div>
                <p>
                  Enter your Recognised Assessment Body Membership or Registration number for your Common Assessment
                  Standard.
                </p>
                <div *ngIf="isAchilles">
                  <h6>For Achilles, this is a number, e.g. 1234567</h6>
                </div>
                <div *ngIf="isConstructionline()">
                  <h6>For Constructionline, this is a number, e.g. 123456</h6>
                </div>
                <div class="form-group">
                  <input #membershipNumberInput class="form-control" type="text" formControlName="membershipNumberInput"
                    placeholder="Membership Number" maxlength="{{membershipNumberMaxLength}}"
                    (keypress)="numberOnly($event)" (change)="setMembershipNumber()" />
                </div>
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input id="confirmationCheck" class="custom-control-input" type="checkbox"
                      aria-describedby="confirmationCheck" formControlName="confirmationCheck">
                    <label id="confirmationCheck" class="custom-control-label" for="confirmationCheck">
                      <span *ngIf="isConstructionline()">
                        I have a verified Constructionline Gold or Platinum account.
                      </span>
                      <span *ngIf="!isConstructionline()">
                        I have a compliant Common Assessment Standard certification with {{ confirmationRABName }}.
                      </span>
                      <span *ngIf="!isCQMS()">
                        I have a compliant Common Assessment Standard certification with {{ confirmationRABName }}.
                      </span>
                    </label>
                    <p class="mt-1"><em>Your assessment must be compliant to use this feature.</em></p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>


        <button type="button" class="btn btn-primary mt-0" (click)="updateAccountCasDataAsync(false)"
          [class.is-loading]="modalbtnLoading" [disabled]="saveButtonDisabled">
          Save
        </button>
      </div>
    </div>
  </div>
</div>