import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MembershipResponse } from '../../shared';
import { Environment, IEnvironment } from "src/app/shared/classes/environment";

@Injectable({
  providedIn: 'root'
})
export class FoundationService {
  private config: IEnvironment;
  constructor(private http: HttpClient) {
    const env = new Environment();
    this.config = env.getConfigSync();
  }

  public isFoundation(accountId: string, contactId: string): Observable<boolean> {
    const url = `${this.config.apiEndpoint.accounts}/${accountId}/getmembershipsummary`;
    return this.http.get(url)
      .pipe(map((membership: MembershipResponse) => {
        return membership.type.toLowerCase().indexOf('foundation') !== -1;
      }));
  }

  public isFoundationAsync(accountId: string, contactId: string) {
    return this.isFoundation(accountId, contactId).toPromise();
  }
}

