<div class="inner_content">
    <div class="card_text">
        <section *ngIf="!activeCard.active">
            <h4 class="h6"><strong>Health and safety, environment, HR and employment law</strong></h4>
            <div>
                Daily news and legislation updates.<br />
                Access to live, qualified specialist advice, on a wide range of topics including waste, safety, flexible
                working, annual leave, mental health and well being.<br />
                Downloadable model policies, procedures, forms and letters.
            </div>
        </section>
        <section *ngIf="activeCard.active">
            <h4 class="h6"><strong>Health and safety, environment, HR and employment law</strong></h4>
            <div>
                Daily news and legislation updates.<br />
                Access to live, qualified specialist advice, on a wide range of topics including waste, safety, flexible
                working, annual leave, mental health and well being.<br />
                Downloadable model policies, procedures, forms and letters.
            </div>
        </section>
    </div>
    <div class="card_buttons" *ngIf="!activeCard.active">
        <button class="btn btn-primary on_card" *ngIf="!activeCard.active" (click)="shieldModal.show()"
            [class.is-loading]="isLoading" [disabled]="foundation">
            Activate my free account
        </button>
    </div>
    <div class="card_buttons" *ngIf="activeCard.active">
        <button class="btn btn-primary on_card" (click)="loginAsync()" [class.is-loading]="isLoading"
            [disabled]="foundation">
            Log in
        </button>
    </div>
</div>

<div bsModal #shieldModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-sizes-name1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="dialog-sizes-name1" class="modal-title pull-left">Business Shield – Free expert advice</h4>
                <button type="button" class="close pull-right" #closebutton (click)="shieldModal.hide()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <img class="bundle-image" [src]="productImage">


                <h5>Features</h5>
                <ul>
                    <li>Comprehensive library of step-by-step procedures across H&S, environment, HR and employment.
                    </li>
                    <li>Model policies that can be customised to suit your requirements</li>
                    <li>Samples forms and letters.</li>
                    <li>In person helpline available Monday – Friday 08:00 – 17:00.</li>
                    <li>Access to a 24-hour response line for H&S questions.</li>
                    <li>Daily news and legislation updates.</li>
                    <li>A dedicated CHAS compliance section with documentation that will assist with gaining
                        accreditation.</li>
                    <li>A dedicated CHAS compliance section with useful documentation that will assist you towards
                        accreditation</li>
                </ul>
                <h5>Benefits</h5>
                <ul>
                    <li>Helps you to comply the Management of Health and Safety at Work Regulations by giving access to
                        competent H&S advice.</li>
                    <li>Guides you through the legislative landscape on H&S, environment, HR and employment.</li>
                </ul>

                <button type="button" (click)="activateAync()" [class.is-loading]="modalbtnLoading"
                    class="btn btn-primary">
                    Activate now
                </button>
            </div>
        </div>
    </div>
</div>