import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import {
  AccountContact,
  AccountResponse,
  CheckNewAuthenticationResponse,
  MembershipResponse,
  UpdateAccountConfirmations,
} from 'src/app/shared/index';
import { AccountsService, ContactsService } from '../../../core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss'],
})
export class TermsModalComponent implements OnInit, AfterViewInit {
  @ViewChild('template') public modalTemplate: TemplateRef<any>;
  modalRef: BsModalRef;
  account: AccountResponse;
  contact: AccountContact;
  errorMessage: string;
  isPosting: boolean = false;
  rationale: Array<string> = [];
  termsHtml: any;
  pdfDownloadUrl: string;
  termsChangedDate: Date;
  authUser: CheckNewAuthenticationResponse;
  membership: MembershipResponse;
  private accountDetails: any;
  private readonly data$ = this.store.select(selectCurrentUserState);
  private config: IEnvironment;

  constructor(
    private modalService: BsModalService,
    private accountService: AccountsService,
    public sanitizer: DomSanitizer,
    public contactsService: ContactsService,
    private readonly store: Store
  ) {}

  async ngOnInit() {
    const env = new Environment();
    this.config = await env.getConfig();
    this.rationale = this.config.termsAndConditions.rationale;
    this.termsHtml = this.sanitizer.bypassSecurityTrustResourceUrl(this.config.termsAndConditions.termsHtml);
    this.pdfDownloadUrl = this.config.termsAndConditions.pdfDownload;
    this.termsChangedDate = new Date(this.config.termsAndConditions.lastUpdated);
  }

  ngAfterViewInit(): void {
    this.data$.subscribe((data: CurrentUserState) => {
      if (data.loaded) {
        this.authUser = data.currentAccount;
        this.accountDetails = data.allAccounts;
        this.contact = data.contacts.filter((data) => data.contactId === this.authUser.contactId)[0];

        this.initialiseTermsModalAsync();
      }
    });
  }

  async initialiseTermsModalAsync(): Promise<void> {
    try {
      if (this.authUser === null) return;

      const account = this.accountDetails.find((x) => x.contactId === this.authUser.contactId && x.accountId === this.authUser.accountId);
      if (account) {
        this.membership = {
          type: account.MembershipTypeId,
          expirationDate: account.membershipExpiry,
        };
      }

      //this.conditionallyShowModal();
    } catch {
      console.error('Could not query endpoints to show terms modal');
    }
  }

  conditionallyShowModal() {
    if (this.termsChangedDate > new Date()) {
      return;
    }
    if (this.contact.email.endsWith('chas.co.uk') || this.contact.email.endsWith('chas.group')) {
      return;
    }
    if (this.membership !== null && (this.membership.type === 'No Membership' || this.membership.type === 'Never Membership')) {
      return;
    }

    const termsAcceptedDateStr = this.authUser.acceptedTermsAndConditions;

    if (termsAcceptedDateStr === null || termsAcceptedDateStr.length === 0) {
      this.showModal();
      return;
    }

    const termsAcceptedDate = new Date(termsAcceptedDateStr);

    if (termsAcceptedDate < this.termsChangedDate) {
      this.showModal();
      return;
    }
  }

  showModal(): void {
    const config: ModalOptions = {
      class: 'modal-lg',
      keyboard: false,
      ignoreBackdropClick: true,
    };
    this.modalRef = this.modalService.show(this.modalTemplate, config);
  }

  hideModal(): void {
    this.modalRef.hide();
  }

  async acceptTermsAsync(): Promise<void> {
    const payload: UpdateAccountConfirmations = {
      AcceptedTermsAndConditions: true,
    };

    try {
      this.isPosting = true;
      await this.accountService.updateConfirmationsAsync(this.authUser.accountId, payload);
      this.hideModal();
    } catch {
      this.errorMessage = 'Failed to update your acceptance of the terms and conditions.';
    } finally {
      this.isPosting = false;
    }
  }
}
