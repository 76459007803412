<!-- Table of results. -->
<div class="table-responsive-md">
    <table id="uploaded-docs-table" class="table" *ngIf="documents">
        <colgroup>
            <col style="width: 5%">
            <col style="width: 35%;">
            <col style="width: 25%;">
            <col style="width: 25%;">
            <col style="width: 15%;">
        </colgroup>
        <thead class="thead">
            <tr>
                <th>&nbsp;</th>
                <th (click)="sortBy('name')" class="column">
                    File Name
                    <i class="fa fa-sort-{{sortIcon}} {{ sortIcon === 'down' ? 'align-text-top' : 'align-middle'}}"
                        *ngIf="column === 'name'"></i>
                </th>
                <th (click)="sortBy('module')" class="column">
                    Module
                    <i class="fa fa-sort-{{sortIcon}} {{ sortIcon === 'down' ? 'align-text-top' : 'align-middle'}}"
                        *ngIf="column === 'module'"></i>
                </th>
                <th (click)="sortBy('type')" class="column">
                    Document Type
                    <i class="fa fa-sort-{{sortIcon}} {{ sortIcon === 'down' ? 'align-text-top' : 'align-middle'}}"
                        *ngIf="column === 'type'"></i>
                </th>
                <th (click)="sortBy('date')" class="column">
                    Uploaded
                    <i class="fa fa-sort-{{sortIcon}} {{ sortIcon === 'down' ? 'align-text-top' : 'align-middle'}}"
                        *ngIf="column === 'date'"></i>
                </th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let document of documents;">
                <td class="center-align">
                    <input type="checkbox"  
                        (change)="updateSelection(document, $event)"
                        class="custom-checkbox" />
                </td>
                <td class="mid-align">
                    <i class="fa {{document.extension?.toLowerCase() | extensionIcon}}-o fa-lg"></i> 
                    <button class="btn btn-link text-left pr-0 doc-view" alt="view document" type="button"
                        (click)="viewDocument(document);">
                        {{ document.name }}
                    </button>
                </td>

                <td class="mid-align">{{ document.module }}</td>

                <td clsas="mid-align">{{ document.type }}</td>

                <td class="mid-align">{{ document.uploaded | date:'d MMMM y' }}</td>

            </tr>
        </tbody>
    </table>
</div>