import { createAction, props } from "@ngrx/store";
import { NotificationResponse } from "src/app/shared/interfaces/notifications-response";

export enum NotificationActions {
    Dismiss = '[Notification] Dimiss',
    DismissSuccess = '[Notification] Dimiss Success',
    DismissFail = '[Notification] Dimiss Fail',
    Action  = '[Notification] Action',
    Load    = '[Notification] Load',
    LoadSuccess    = '[Notification] Load Success',
    LoadFail    = '[Notification] Load Fail',
}

export const NotificationDismissSuccess = createAction(NotificationActions.DismissSuccess);
export const NotificationDismissFail = createAction(NotificationActions.DismissFail, props<{ notification: NotificationResponse }>());
export const NotificationDismiss = createAction(NotificationActions.Dismiss, props<{ notification: NotificationResponse }>());
export const NotificationLoad    = createAction(NotificationActions.Load, props<{ accountId: string, contactId: string }>());
export const NotificationLoadSucess    = createAction(NotificationActions.LoadSuccess, props<{ notifications: NotificationResponse[] }>());
export const NotificationLoadFail    = createAction(NotificationActions.LoadFail);