import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageContactsModalComponent } from './manage-contacts-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BootstrapComponentsModule } from '../bootstrap/bootstrap.module';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';

@NgModule({
  declarations: [ManageContactsModalComponent],
  imports: [CommonModule, ReactiveFormsModule, BootstrapComponentsModule, LoadingSpinnerModule],
  exports: [ManageContactsModalComponent]
})
export class ManageContactsModalModule {}
