<div class="text-center" *ngIf="isLoading; else displayForm">
  <app-loading-spinner></app-loading-spinner>
</div>

<ng-template #displayForm>
  <h1 class="h2">Company Details</h1>
  <form [formGroup]="detailsForm" (submit)="updateCompanyDetails()" novalidate>
    <!-- Membership ID (display only). -->
    <div class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label pt-0">Membership ID</label>
      <div class="col-md-5 col-sm-7">
        {{ membershipId }}
      </div>
    </div>

    <!-- Company name (display only). -->
    <div class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label pt-0">Company Name</label>
      <div class="col-md-5 col-sm-7">
        {{ companyName }}
      </div>
    </div>

    <!-- Business type (display only). -->
    <div class="form-group row" *ngIf="!isAus">
      <label class="col-md-4 col-sm-5 col-form-label pt-0">
        Business Type
        <br />
        <span class="small-text">(Health and Safety Application Type)</span>
      </label>
      <div class="col-md-4">
        {{ applicationType }}
      </div>
    </div>

    <!-- Business size (display only). -->
    <div class="form-group row" *ngIf="companyDetails.turnover && hasCASBundle && (businessSize === 'Small Micro' || businessSize === 'Micro')">
      <label class="col-md-4 col-sm-5 col-form-label pt-0">Business Size</label>
      <div class="col-md-4">
        {{ businessSize }}<br />
        <span class="small-text" *ngIf="businessSize === 'Small Micro'">(less than 5 employees and a turnover below £100,000)</span>
        <span class="small-text" *ngIf="businessSize === 'Micro'">(less than 10 employees and a turnover below £1.8m)</span>
      </div>
    </div>

    <!-- Trading Name. -->
    <div class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label" for="detailsTradingName">Trading Name</label>
      <div class="col-md-4">
        <div *ngIf="!readOnly; else showTradingName">
          <input id="detailsTradingName" class="form-control" type="text" formControlName="trading_name"
                 title="Trading Name" />
        </div>

        <ng-template #showTradingName>
          {{ companyDetails.tradingName }}
        </ng-template>
      </div>

    </div>

    <hr />

    <!-- Company Registration Nuumber. -->
    <div class="form-group row" *ngIf="!isAus">
      <label for="baddress" class="col-md-4 col-form-label pt-0">Company Registration Number</label>
      <div class="col-md-6">
        {{ companyDetails.id.companyHouseNo }}
      </div>
    </div>
    <div class="form-group row" *ngIf="isAus">
      <label for="baddress" class="col-md-4 col-form-label pt-0">ABN</label>
      <div class="col-md-6">
        {{ companyDetails.id.abn }}
      </div>
    </div>
    <div class="form-group row" *ngIf="isAus">
      <label for="baddress" class="col-md-4 col-form-label pt-0">ACN</label>
      <div class="col-md-6">
        {{ companyDetails.id.acn }}
      </div>
    </div>

    <!-- Registered address. -->
    <div class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label pt-0">
        Registered Address
        <br />
        <span class="small-text" *ngIf="companyDetails.id.companyHouseNo">From your Companies House entry</span>
      </label>

      <ng-container>
        <div class="col-md-5">
          {{ registeredAddress }}
        </div>
      </ng-container>
    </div>

    <!-- Trading/Correspondence address. -->
    <div class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label pt-0">Trading / Correspondence Address</label>
      <ng-container *ngIf="!editingTradingAddress; else sectionTradingAddress">
        <div class="col-md-5">
          {{ tradingAddress }}
        </div>
        <div class="col-md-2" *ngIf="!readOnly">
          <button class="btn btn-block btn-secondary py-0" type="button" (click)="editTradingAddress()">
            Edit
          </button>
        </div>
      </ng-container>

      <ng-template #sectionTradingAddress>
        <div class="col-md-5">
          <div class="form-group">
            <input style="display: none" class="form-control" type="text" formControlName="correspondence_address_id"
                   placeholder="addressId" />
          </div>
          <div class="form-group">
            <input class="form-control" type="text" formControlName="correspondence_address_street1"
                   placeholder="Address Line 1" />
            <span class="text-danger"
                  *ngIf="detailsForm.controls.correspondence_address_street1.errors?.required">
              *Address Line 1 is required
            </span>
          </div>
          <div class="form-group">
            <input class="form-control" type="text" formControlName="correspondence_address_street2"
                   placeholder="Address Line 2" />
          </div>
          <div class="form-group">
            <input class="form-control" type="text" formControlName="correspondence_address_city"
                   placeholder="Town/City" />
            <span class="text-danger"
                  *ngIf="detailsForm.controls.correspondence_address_city.errors?.required">
              *Town/City is required
            </span>
          </div>
          <div class="form-group">
            <input class="form-control" type="text" formControlName="correspondence_address_postcode"
                   placeholder="Postcode" />
            <span class="text-danger"
                  *ngIf="detailsForm.controls.correspondence_address_postcode.errors?.required">
              *Postcode is required
            </span>
          </div>
          <div class="form-group">
            <select *ngIf="!isCompanyHouse" name="cars" id="regCountry" formControlName="correspondence_address_country_code" class="form-control">
              <option *ngFor="let country of countries" [value]="country.code">{{ country.shortName }}</option>
            </select>
            <span class="text-danger" *ngIf="detailsForm.controls.correspondence_address_country_code.errors?.required">
              *Country is required
            </span>
          </div>
        </div>
      </ng-template>
    </div>

    <!-- Branch addresses. -->
    <div class="form-group row" *ngIf="!readOnly">
      <label for="baddress" class="col-md-4 col-form-label pt-0">Branch Addresses</label>
      <div class="col-md-6">
        <button class="btn btn-secondary py-0" type="button" (click)="showBranchAddresses()">
          Manage
        </button>
      </div>
    </div>

    <hr />

    <!-- Choose / Change primary contact -->
    <div *ngIf="!readOnly" class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label" for="detailsPrimaryContact">Choose / Change Primary Contact</label>
      <div class="col-md-4">
        <select id="detailsPrimaryContact" class="custom-select" formControlName="primaryContactControl">
          <option hidden [ngValue]="null" selected>Please select</option>
          <option *ngFor="let row of contacts" [ngValue]="row.contactId">{{ row.contactname }}</option>
        </select>
      </div>

      <span class="text-danger"
            *ngIf="detailsForm.controls.primaryContactControl.touched && detailsForm.controls.primaryContactControl.errors?.required">
        *Primary contact is required
      </span>
    </div>

    <!-- Primary contact name. -->
    <div class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label pt-0">Primary Contact Name</label>
      <div class="col-md-4">
        {{ primaryContactName }}
      </div>
      <div class="col-md-3" *ngIf="!readOnly">
        <button class="btn btn-sm btn-secondary py-1" type="button" routerLink="/admin/users">
          Manage Contacts
        </button>
      </div>
    </div>

    <!-- Position. -->
    <div class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label" for="detailsPosition">
        Position
      </label>

      <div class="col-md-4">
        <div *ngIf="!readOnly; else showPosition">
          <input id="primaryContact-position" class="form-control" type="text" formControlName="position" />
          <span class="text-danger"
                *ngIf="detailsForm.controls.position.touched && detailsForm.controls.position.errors?.required">
            *Position is required
          </span>
        </div>

        <ng-template #showPosition>
          {{ primaryContact?.Position }}
        </ng-template>
      </div>

    </div>

    <!-- Email address. -->
    <div class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label pt-0">Email Address</label>
      <div class="col-md-5 col-sm-7">
        {{ primaryContact?.email }}
      </div>
    </div>

    <!-- Phone number. -->
    <div class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label" for="detailsTelephone">Phone Number</label>
      <div class="col-md-4">
        <div>
          {{ primaryContact?.telephone1 }}
        </div>
      </div>
    </div>

    <hr>

    <!-- Organisation type. -->
    <div class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label" for="detailsOrganisationType">Organisation type</label>
      <div class="col-md-4">

        <div *ngIf="!readOnly; else showOrganisationType">
          <select id="detailsOrganisationType" class="custom-select" formControlName="organisation_type">
            <option hidden [ngValue]="null" selected>Please select</option>
            <option *ngFor="let row of options.organisationTypes" [value]="row.id">{{ row.label }}</option>
          </select>
        </div>

        <ng-template #showOrganisationType>
          {{ organisationType }}
        </ng-template>
      </div>

      <span class="text-danger"
            *ngIf="detailsForm.controls.organisation_type.touched && detailsForm.controls.organisation_type.errors?.required">
        *Organisation type is required
      </span>
    </div>

    <!-- Website. -->
    <div class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label" for="detailsWebsite">Website</label>
      <div class="col-md-4">
        <div *ngIf="!readOnly; else showWebsite">
          <input id="detailsWebsite" class="form-control" type="text" formControlName="website_url" />
        </div>
        <ng-template #showWebsite>
          {{companyDetails.website}}
        </ng-template>
      </div>
    </div>

    <!-- Immediate parent company. -->
    <div class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label" for="detailsImmediateParent">
        Immediate Parent Company Name or Registration Number
      </label>
      <div class="col-md-4">
        <app-company-search #immediateParent
                            id="detailsImmediateParent"
                            [readOnly]="readOnly"
                            (found)="changedImmediateParent($event)"
                            (cleared)="removeImmediateParent()">
        </app-company-search>
      </div>
    </div>

    <!-- Ultimate parent company. -->
    <div class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label" for="detailsUltimateParent">
        Ultimate Parent Company Name or Registration Number
      </label>
      <div class="col-md-4">
        <app-company-search #ultimateParent
                            id="detailsUltimateParent"
                            [readOnly]="readOnly"
                            (found)="changedUltimateParent($event)"
                            (cleared)="removeUltimateParent()">
        </app-company-search>
      </div>
    </div>

    <!-- VAT registration number. -->
    <div class="form-group row" *ngIf="!isAus">
      <label class="col-md-4 col-sm-5 col-form-label" for="detailsVAT">VAT Registration Number</label>
      <div class="col-md-4">
        <div *ngIf="!readOnly; else showVAT">
          <input id="detailsVAT" class="form-control" type="text" formControlName="vat_reg_number" />
        </div>
        <ng-template #showVAT>
          {{companyDetails.vatRegistrationNumber}}
        </ng-template>
      </div>
    </div>


    <!-- Where did you hear about CHAS? -->
    <div class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label" for="detailsWhereHeard">Where did you hear about CHAS?</label>
      <div class="col-md-4">
        <div *ngIf="!readOnly; else showHearChas">
          <select id="detailsWhereHeard" class="custom-select" formControlName="hear_chas">
            <option value="" disabled>Please select</option>
            <option *ngFor="let row of options.whereHeard" [value]="row.id">
              {{ row.label }}
            </option>
          </select>

          <input *ngIf="whereHearInputEnabled" id="whereHeardTextOption" type="text" class="form-control mt-3"
                 formControlName="hear_chas_text_option" />

          <span class="text-danger"
                *ngIf="detailsForm.controls.hear_chas_text_option
            && detailsForm.controls.hear_chas_text_option.errors?.required">
            {{ whereHeardOptionsError }}
          </span>

        </div>

        <ng-template #showHearChas>
          {{ hearChas }}
        </ng-template>
      </div>
      <span class="text-danger"
            *ngIf="detailsForm.controls.hear_chas.touched && detailsForm.controls.hear_chas.errors?.required">
        *Please tell us where you heard about CHAS
      </span>
    </div>

    <!-- Print pack -->
    <div class="form-group row" *ngIf="printPack">
      <label class="col-md-4 col-sm-5 col-form-label" for="detailsPrintPack">Your print pack is as follows</label>
      <div class="col-md-4">
        {{ printPack }}
      </div>
    </div>

    <!-- Number of direct employees. -->
    <div class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label" for="detailsNumDirectEmployees">Number of direct Employees</label>
      <div class="col-md-2">

        <div *ngIf="!readOnly; else showDirectEmployees">
          <input id="detailsNumDirectEmployees"
                 class="form-control" type="number"
                 formControlName="number_of_direct_employees"
                 min="1"
                 step="1"
                 oninput="validity.valid||(value='');" />
        </div>
        <ng-template #showDirectEmployees>
          {{companyDetails.numberOfDirectEmployees}}
        </ng-template>
      </div>

      <span class="text-danger"
            *ngIf="detailsForm.controls.number_of_direct_employees.touched && detailsForm.controls.number_of_direct_employees.errors?.required">
        *Number of direct Employees is required
      </span>
      <span class="text-danger"
            *ngIf="detailsForm.controls.number_of_direct_employees.touched && detailsForm.controls.number_of_direct_employees.errors?.required">
        *Number of direct Employees cannot be below 1
      </span>
    </div>

    <!-- Number of labour only subcontractors. -->
    <div class="form-group row" *ngIf="!isAus">
      <label class="col-md-4 col-sm-5 col-form-label" for="detailsNumLabouOnlySubcontractors">
        Number of Labour Only
        Subcontractors
      </label>
      <div class="col-md-2">
        <div *ngIf="!readOnly; else showSubContractors">
          <input id="detailsNumLabouOnlySubcontractors"
                 class="form-control"
                 type="number"
                 formControlName="number_of_labour_only_subcontractors"
                 min="0"
                 step="1"
                 oninput="validity.valid||(value='');" />
        </div>
        <ng-template #showSubContractors>
          {{companyDetails.numberOfLabourOnlySubcontractors}}
        </ng-template>
      </div>

      <span class="text-danger"
            *ngIf="detailsForm.controls.number_of_labour_only_subcontractors.touched && detailsForm.controls.number_of_labour_only_subcontractors.errors?.required">
        *Number of Labour Only Subcontractors is required
      </span>
    </div>

    <!-- Total Effective Employees. -->
    <div class="form-group row" *ngIf="!isAus">
      <label class="col-md-4 col-sm-5 col-form-label" for="detailsTotalEffectiveEmployees">
        Total Effective number of
        Employees
      </label>
      <div class="col-md-2">
        <div *ngIf="!readOnly; else showEffectiveEmployees">
          <input id="detailsTotalEffectiveEmployees" class="form-control" type="number"
                 formControlName="number_of_effective_employees" min="0" />
        </div>
        <ng-template #showEffectiveEmployees>
          {{companyDetails.numberOfEffectiveEmployees}}
        </ng-template>
      </div>
      <span class="text-danger"
            *ngIf="detailsForm.controls.number_of_effective_employees.touched && detailsForm.controls.number_of_effective_employees.errors?.required">
        *Total Effective Employees is required
      </span>
    </div>

    <!-- Number of bona fide subcontractors. -->
    <div class="form-group row" *ngIf="!isAus">
      <label class="col-md-4 col-sm-5 col-form-label" for="detailsNumBonaFideSubcontractors">
        Number of Bona Fide
        Subcontractors
      </label>
      <div class="col-md-2">
        <div *ngIf="!readOnly; else showBonaFideSubcontractors">
          <input id="detailsNumBonaFideSubcontractors"
                 class="form-control"
                 type="number"
                 formControlName="number_of_bona_fide_subcontractors"
                 min="0"
                 step="1"
                 oninput="validity.valid||(value='');" />
        </div>
        <ng-template #showBonaFideSubcontractors>
          {{companyDetails.numberOfBonaFideSubcontractors}}
        </ng-template>
      </div>

      <span class="text-danger"
            *ngIf="detailsForm.controls.number_of_bona_fide_subcontractors.touched && detailsForm.controls.number_of_bona_fide_subcontractors.errors?.required">
        *Number of Labour Only Subcontractors is required
      </span>
    </div>

    <!-- Business summary. -->
    <div class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label" for="detailsSummary">Business Summary</label>
      <div class="col-md-7">

        <div *ngIf="!readOnly; else showSummary">
          <textarea id="detailsSummary" class="form-control" formControlName="summary" rows="5"
                    cols="30"></textarea>
          <!-- Character count. -->
          <div class="character-count mt-1 mb-2" *ngIf="detailsForm.controls.summary.valid">
            <b>{{ detailsForm.value.summary.length | number }}</b> /
            5000 character(s)
          </div>
        </div>
        <ng-template #showSummary>
          {{companyDetails.summary}}
        </ng-template>


        <span class="text-danger"
              *ngIf="detailsForm.controls.summary.touched && detailsForm.controls.summary.errors?.required">
          *Business Summary is required
        </span>

        <span class="text-danger"
              *ngIf="detailsForm.controls.summary.touched && detailsForm.controls.summary.errors?.maxlength">
          *Business Summary has a max length of 5000 characters
        </span>

        <p class="mt-2">
          Please provide a brief description of the services your business
          provides. This will be visible to Buyers when looking at your company.
        </p>
      </div>
    </div>

    <hr />

    <!-- Work categories. -->
    <div class="form-group mt-3 mb-3">
      <h2 class="h5">Work Categories<span class="text-danger">*</span></h2>
      <p *ngIf="!isAus">These are the work categories that your company will be assessed by.</p>
      <div class="form-group row">
        <div class="col-lg-9">
          <app-work-categories
            *ngIf="currentUser"
            [accountId]="currentUser.AccountId"
            [selectedWorkCategories]="detailsForm.controls.workCategories.value">
          </app-work-categories>
        </div>
        <div class="col-lg-2" *ngIf="!readOnly">
          <button class="btn btn-secondary btn-block py-0" type="button" (click)="showWorkCategories()">
            Edit
          </button>
        </div>
      </div>
      <p></p>
    </div>


    <!-- Primary Work category. -->
    <div class="form-group row">
      <label class="col-md-4 col-sm-5 col-form-label" for="detailsWhereHeard">Primary Work Category</label>
      <div class="col-md-4">
        
        <div *ngIf="!readOnly; else showPrimaryWorkCategory">
          <select id="primaryWorkCategory" class="custom-select" formControlName="primary_trade"
          [attr.disabled]="healthAndSafetySubmitted">
            <option hidden value="" selected>Please select</option>
            <option *ngFor="let row of detailsForm.controls.workCategories.value;trackBy:primaryTradeTrackBy" [Selected]="row.id===detailsForm.controls.primary_trade.value" [value]="row.id" >{{ row.fullName }}</option>
          </select>
          <span class="text-warning" *ngIf="healthAndSafetySubmitted">
            *Primary Work Category is no longer editable once any assessments have been submitted
          </span>
        </div>
        <ng-template #showPrimaryWorkCategory>
          {{primaryWorkCategoryName}}
        </ng-template>

      </div>
    </div>


    <hr />

    <!-- Work areas. -->
    <div class="form-group mt-3 mb-3">
      <h2 class="h5">{{ workAreaHeadingText }}<span class="text-danger">*</span></h2>
      <div class="form-group row">
        <div class="col-lg-9">
          <app-work-areas *ngIf="currentUser" [accountId]="currentUser.AccountId" [workRegions]="detailsForm.controls.workRegions.value" [workStates]="selectedStates" [distance]="detailsForm.controls.distance.value"></app-work-areas>
        </div>
        <div class="col-lg-2" *ngIf="!readOnly">
          <button class="btn btn-secondary btn-block py-0" type="button" (click)="showWorkAreas()">
            Edit
          </button>
        </div>
      </div>
    </div>

    <alert type="success" *ngIf="success">Your company details have been saved.</alert>
    <alert type="danger" *ngIf="error">{{ error }}</alert>

    <!-- Submit button. -->
    <div class="form-group text-right" *ngIf="!readOnly">
      <button type="submit" class="btn btn-lg btn-primary"
              [class.is-loading]="isSaving">
        Update Details
      </button>
    </div>

  </form>
</ng-template>
