import { Pipe, PipeTransform } from '@angular/core';
import fileTypes from 'src/assets/json/document-types.json';

@Pipe({ name: 'extensionIcon' })
export class ExtensionIconPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      const icon = fileTypes[value];
      if (icon) {
        return `fa-${icon}`;
      }
    }
    return 'fa-file';
  }
}
