<div class="modal-dialog">
  <div class="modal-content">

    <div class="modal-header">
      <div>
        <span>Choose your CHAS membership</span>
      </div>

    </div>


    <div class="modal-body privacy-body">

      <p class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</p>

      <div class="text-center" style="height:50vh" *ngIf="isLoading">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <section *ngIf="!isLoading">

          <div class="membership_container">
            <div class="card foundation">
              <div class="foundation_image img">
                <div class="img auto_margin"></div>
              </div>
              <div class="content">
                <h3>Foundation</h3>
                <p>Welcome to CHAS Foundation, our free launchpad for contractors who want to start the accreditation process but need more time and resources.</p>
                <p>All companies are legally required to complete certain legal documentation, and at CHAS, we will help you comply with legal requirements and get assessment-ready. CHAS Foundation allows you to learn what maintenance checklists to use, talks you through legal requirements and writing policies — all while offering advice on how to comply in areas of risk management.</p>

                <div class="action_container">
                  <div class="custom-control custom-checkbox">
                    <input id="checkContact" class="custom-control-input" type="checkbox" aria-describedby="checkContact" (change)="toggleTerms($event)">
                    <label id="checkContactLabel" class="custom-control-label" for="checkContact">
                      By placing this order I confirm that I have read and accepted the CHAS
                      <a href="https://www.chas.co.uk/contractor-terms-conditions" target="_blank" rel="noopener">Terms & Conditions</a> and
                      <a href="https://www.chas.co.uk/privacy-policy" target="_blank" rel="noopener">Privacy Policy</a>
                      </label>
                  </div>
                  <button class="btn btn-primary btn_foundation" (click)="handleSelection(true)" [class.is-loading]="fdn_loading" [disabled]="!acceptTerms">Activate Foundation</button>
                </div>

              </div>
            </div>
            <div class="card membership">
              <div class="elite-image img">
                <div class="img auto_margin"></div>
              </div>
              <div class="verified-contractor-image img">
                <div class="img auto_margin"></div>
              </div>
              <div class="content">
                <h3>Accredited Membership</h3>
                <p>Become a CHAS Member — Ensure your business stays up-to-date on ever-changing risk management in the UK. Compliance with CHAS helps get you noticed by top clients, showcasing your quality and care towards your business and workers. Help build your trustworthy and reputable reputation today by becoming a CHAS member and gaining accreditation. Our award-winning team is always on hand to talk you through the process.</p>
                <button class="btn btn-primary btn_accredited" (click)="handleSelection(false)" [class.is-loading]="acr_loading">Purchase membership</button>
              </div>
            </div>
          </div>

<!--          <div class="review_images">-->
<!--            <img src='assets/img/logo/feefo_banner.png' class="feefo" alt="CHAS Feefo Reviews"/>-->
<!--            <img src='assets/img/logo/ssip_and_cas.png' alt="SSIP and CAS logos"/>-->
<!--          </div>-->

          </section>
    </div>
  </div>
</div>
