import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, lastValueFrom, Observable } from 'rxjs';
import { BasketService, CountryService, monitorService, PaymentService, ProductService } from 'src/app/core';
import { BasketAccountActions } from 'src/app/core/ngrx/actions/basketAccount.actions';
import { BasketItemsActions } from 'src/app/core/ngrx/actions/basketItems.actions';
import { BasketQuoteActions } from 'src/app/core/ngrx/actions/basketQuote.actions';
import { BasketSummaryActions } from 'src/app/core/ngrx/actions/basketSummary.actions';
import { selectBasketAccount, selectBasketItems, selectBasketQuote, selectBasketSummary } from 'src/app/core/ngrx/selectors/basket.selectors';
import { CompanySearchService } from 'src/app/core/services/company-search.service';
import { GtmBrand, GtmUserCategory, GtmUserStatus } from 'src/app/core/services/gtm/gtm.enum';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';
import { BasketItem, CompaniesHouseCompanyDetails, CreateAccountParameters, GetOrderSummary, GetQuoteRequest, IAccountContactQuoteResponse, MonitorSeverity, productTypes, QuoteResponse, UserTypeCode, UserTypeCodeAus } from 'src/app/shared';
import { CreateAccount, IAccountFormValue } from 'src/app/shared/classes/createAccount.class';
import { ICreateOrder } from 'src/app/shared/interfaces/new/createOrder';
import { BundleNames, BundleNamesAus } from 'src/app/shared/components/select-membership/select-membership.component';
import { IQuickquote } from 'src/app/shared/interfaces/new/quickQuote';
import { RegisterTrackingService } from './register-tracking.service';
import { IQuoteData } from 'src/app/shared/interfaces/new/quote-data';
import { IRegisterstepone } from 'src/app/shared/interfaces/new/registerStepOne';
import { IRegistersteptwo } from 'src/app/shared/interfaces/new/registerStepTwo';
import { IRegisterstepthree } from 'src/app/shared/interfaces/new/registerStepThree';
import { selectRegisterFour, selectRegisterOne, selectRegisterThree, selectRegisterTwo } from '../ngrx/selectors/register.selectors';
import { IRegisterstepfour } from 'src/app/shared/interfaces/new/registerStepFour';
import { PriceService } from './price.service';
import { IAccountContactQuote, ICheckoutSessionRequest, IGetQuoteCreateOpportunityCommand, IInternalQuoteRequest, IQuoteRequest, ICheckAccountExistsRequest } from 'src/app/shared/interfaces/new/register-data';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { Territory } from 'src/app/shared/constants/territory.enum';
import { ILead } from 'src/app/features/register-vip/services/vip.service';

interface IFormatQuote {
  formValue: IAccountFormValue;
  createOpportunity?: boolean;
  discountCode?: string;
  firFee: boolean;
  joinFee: boolean;
  skipSummary: boolean;
  fastTrack: boolean;
  dataSharingAllowed: boolean;
  customerType: string;
}

@Injectable({
  providedIn: 'root'
})
export class RegisterCoreService {
  private readonly basketItems$: Observable<BasketItem[]> = this.store.select(selectBasketItems);
  private readonly basketAccount$: Observable<CreateAccountParameters> = this.store.select(selectBasketAccount);
  private readonly basketQuote$: Observable<GetQuoteRequest> = this.store.select(selectBasketQuote);
  private readonly basketSummary$: Observable<GetOrderSummary> = this.store.select(selectBasketSummary);
  private readonly registerOne$: Observable<IRegisterstepone> = this.store.select(selectRegisterOne);
  private readonly registerTwo$: Observable<IRegistersteptwo> = this.store.select(selectRegisterTwo);
  private readonly registerThree$: Observable<IRegisterstepthree> = this.store.select(selectRegisterThree);
  private readonly registerFour$: Observable<IRegisterstepfour> = this.store.select(selectRegisterFour);
  private environment: IEnvironment;
  private isAus = false;

  constructor(
    private readonly http: HttpClient,
    private readonly basketService: BasketService,
    private readonly productService: ProductService,
    private readonly monitor: monitorService,
    private readonly gtmService: GtmService,
    private readonly registerTrackingService: RegisterTrackingService,
    private readonly companyHouseService: CompanySearchService,
    private readonly paymentService: PaymentService,
    private readonly store: Store,
    private readonly priceService: PriceService,
    private readonly countryService: CountryService
  ) {
    const env = new Environment();
    this.environment = env.getConfigSync();
    this.isAus = this.environment.territory === Territory.AUSTRALIA;
  }

  public getSelectors() {
    return combineLatest([
      this.basketItems$,
      this.basketAccount$,
      this.basketQuote$,
      this.basketSummary$
    ]);
  }

  public populateFromBasket(
    basketItem: BasketItem,
    basketAccount: CreateAccountParameters,
    basketQuote: GetQuoteRequest
  ) {
      return {
        assessmentTypeCode: basketAccount.AssessmentType,
        referenceCode: basketQuote.discountCode,
        companyNumber: basketAccount.CompanyHouseNo,
        companyName: basketAccount.CompanyName,
        firstName: basketAccount.ContactFirstName,
        lastName: basketAccount.ContactLastName,
        contactEmail: basketAccount.EmailAddress,
        contactEmailConfirm: basketAccount.EmailAddress,
        employees: basketAccount.NoOfEmployees,
        contactPassword: basketAccount.Password,
        contactPasswordConfirm: basketAccount.Password,
        street_number: basketAccount.RegAddressline1,
        route: basketAccount.RegAddressline2,
        postal_town: basketAccount.RegCity,
        postal_code: basketAccount.RegPostcode,
        country_code: basketAccount.RegCountryCode,
        contactPhone: basketAccount.Telephone1,
        applicationTypeCode: basketAccount.Variant,
        number_of_direct_employees: basketAccount.NumberofDirectEmployees,
        number_of_labour_only_subcontractors: basketAccount.LabourOnlySubContractors,
        number_of_bona_fide_subcontractors: basketAccount.BonafideSubContractors,
        BundleName: basketItem.name
      };
  }

  public async createAccountOpportunityAndGetQuotes(formData: any, validatedLead: ILead = null, clickDimensionId: string = null) {
    const requestData: IAccountContactQuote = await this.parseAccountContactQuoteRequestData(formData, validatedLead, clickDimensionId);

    let payload = new CreateAccount().createParams({
      account: formData,
      countryName: formData.countryName
    });

    const res: IAccountContactQuoteResponse = await this.createAndGetQuotesAsync(requestData);

    sessionStorage.setItem('guidac', res.accountId);
    sessionStorage.setItem('opportunityId', res.opportunityId);
    sessionStorage.setItem('contactId', res.contactId);
    payload.accountId = res.accountId;

    const quoteExtra = this.completeGetQuoteResponseObject(res.quotes, requestData);

    this.store.dispatch({
      type: BasketAccountActions.Add,
      account: payload
    });

    return quoteExtra;
  }

  public async getQuotesAsync(formData: any, validatedLead: ILead = null, clickDimensionId: string = null){
    const requestCommand = this.parseGetQuoteCreateOpportunityCommand(formData, clickDimensionId);
    sessionStorage.setItem('opportunityId','');

    try {
      const quotes = await this.priceService.getQuoteBundlesAsync(requestCommand);

      const quotesExtra = this.completeGetQuoteResponseObject(quotes, await this.parseAccountContactQuoteRequestData(formData, validatedLead, clickDimensionId));

      return quotesExtra;
    } catch (e) {
      console.error(e);
      this.monitor.exception(e.message, MonitorSeverity.CRITICAL);
      return null;
    }
  }

  public async getQuoteAsync(form: any) {
    try {
      const request = this.parseGetQuoteCreateOpportunityCommand(form);

      const quotes = await this.priceService.getQuoteBundlesAsync(request);
      const quoteExtra = this.completeGetQuoteResponseObject(quotes, await this.parseAccountContactQuoteRequestData(form));

      this.registerTrackingService.getQuote(form);

      let quote = quoteExtra[form.BundleName.toLocaleLowerCase()];
      return quote;
    } catch (e) {
      console.error(e);
      this.monitor.exception(e.message, MonitorSeverity.CRITICAL);
      return null;
    }
  }

  public async getQuotesOnlyAsync(formData: any){
    const requestCommand = this.parseInternalQuoteRequest(formData);
    try {
      const quotes = await this.priceService.getQuotesOnlyAsync(requestCommand);

      const quotesExtra = this.completeGetQuotesOnlyResponseObject(quotes, formData);

      return quotesExtra;
    } catch (e) {
      console.error(e);
      this.monitor.exception(e.message, MonitorSeverity.CRITICAL);
      return null;
    }
  }

  private completeGetQuoteResponseObject(quotes: QuoteResponse[], request: IAccountContactQuote){
    const output = {};
    const bundles = this.parseBundles(request.account.customerType);

    const formValue = this.convertToAccountFormValue(request);
    for (const bundle of bundles){
      const quote = quotes.find(x => x.result.productName.toLocaleLowerCase() === bundle.toLocaleLowerCase()
                                || x.result.productName.toLocaleLowerCase().startsWith(bundle.toLocaleLowerCase())
                                || x.result.productName.toLocaleLowerCase().startsWith('chas ' + bundle.toLocaleLowerCase()))

      if(quote && quote.result){
        const copy = Object.assign({}, formValue);
        copy.BundleName = bundle;

          this.store.dispatch({
            type: BasketQuoteActions.Add,
            quote: copy
          });

          this.store.dispatch({
            type: BasketSummaryActions.Add,
            orderSummary: quote.orderSummary
          });

        this.registerTrackingService.getQuote(copy);

        const quoteExtra = {
          ftsQuoteAmount: quote.result.fastTrackAmount,
          quoteAmount: quote.result.grandTotal,
          lastQuoteResult: quote
        };

        quoteExtra.lastQuoteResult.request = {
          accountId: quote.request.accountId,
          contactId: quote.result.contactId,
          priceLevelId: quote.request.priceLevelId,
          productId: quote.request.productId,
          sectorType: quote.request.sectorType,
          applicationType: quote.request.applicationType,
          numberOfEmployees: quote.request.numberOfEmployees,
          directEmployees: quote.request.directEmployees,
          labourOnlySubContractors: quote.request.labourOnlySubContractors,
          bonafideSubContractors: quote.request.bonafideSubContractors,
          discountCode: quote.request.discountCode,
          fastTrack: quote.request.fastTrack,
          firFee: quote.request.firFee,
          upgrade: quote.request.upgrade,
          joiningFee: quote.request.joiningFee,
          createOpportunity: quote.request.createOpportunity,
          successUrl: quote.request.successUrl,
          cancelUrl: quote.request.cancelUrl,
          dataSharingAllowed: quote.request.dataSharingAllowed,
          customerType: quote.request.customerType
        };

        output[bundle.toLocaleLowerCase()] = quoteExtra;

        if(quote.result.opportunityId && quote.result.opportunityId !== ''){
          sessionStorage.setItem('opportunityId', quote.result.opportunityId);
          sessionStorage.setItem('contactId', quote.contactId);
        }
      } else if(quote && quote.error){
        this.monitor.log(quote.error.message, MonitorSeverity.CRITICAL);
      } else {
        return null;
      }
    }

    return output;
  }

  private completeGetQuotesOnlyResponseObject(quotes: QuoteResponse[], request: IInternalQuoteRequest){
    const output = {};
    const bundles = this.parseBundles(request.customerType);

    for (const bundle of bundles){
      const quote = quotes.find(x => x.result.productName.toLocaleLowerCase() === bundle.toLocaleLowerCase()
                                || x.result.productName.toLocaleLowerCase().startsWith(bundle.toLocaleLowerCase())
                                || x.result.productName.toLocaleLowerCase().startsWith('chas ' + bundle.toLocaleLowerCase()))

      if(quote && quote.result){
        const quoteExtra = {
          ftsQuoteAmount: quote.result.fastTrackAmount,
          quoteAmount: quote.result.grandTotal,
          lastQuoteResult: quote
        };

        quoteExtra.lastQuoteResult.request = {
          accountId: quote.request.accountId,
          contactId: quote.result.contactId,
          priceLevelId: quote.request.priceLevelId,
          productId: quote.request.productId,
          sectorType: quote.request.sectorType,
          applicationType: quote.request.applicationType,
          numberOfEmployees: quote.request.numberOfEmployees,
          directEmployees: quote.request.directEmployees,
          labourOnlySubContractors: quote.request.labourOnlySubContractors,
          bonafideSubContractors: quote.request.bonafideSubContractors,
          discountCode: quote.request.discountCode,
          fastTrack: quote.request.fastTrack,
          firFee: quote.request.firFee,
          upgrade: quote.request.upgrade,
          joiningFee: quote.request.joiningFee,
          createOpportunity: quote.request.createOpportunity,
          successUrl: quote.request.successUrl,
          cancelUrl: quote.request.cancelUrl,
          dataSharingAllowed: quote.request.dataSharingAllowed,
          customerType: quote.request.customerType
        };

        output[bundle.toLocaleLowerCase()] = quoteExtra;

      } else if(quote && quote.error){
        this.monitor.log(quote.error.message, MonitorSeverity.CRITICAL);
      } else {
        return null;
      }
    }

    return output;
  }

  public async stripCheckout(quote: QuoteResponse, basketAccount: CreateAccountParameters, isFastTrack: boolean, isDatashareAllowed: boolean, cancelPath: string = null){

    const checkoutBasket = await this.generateCheckoutBasket(quote, basketAccount, isFastTrack)

    const request: ICheckoutSessionRequest = {
      accountId: quote.accountId,
      opportunityId: quote.result.opportunityId,
      recreate: false,
      manualPayment: false,
      isDatashareAllowed: isDatashareAllowed,
      successUrl: this.environment.urls.environmentUrl + '/payment-success/register',
      cancelUrl: this.environment.urls.environmentUrl + (cancelPath ?? '/register')
    };

    const checkoutSession = await this.paymentService.createCheckoutSession(request);

    const grossAmount = quote.orderSummary.totalGrossAmount;

    let opportunity: any = {
      name: checkoutBasket.name,
      accountId: checkoutBasket.accountId,
      bundleId: checkoutBasket.productID,
      price: checkoutBasket.price,
      assessmentType: checkoutBasket.assessmentType,
      variant: checkoutBasket.applicationType,
      employeenum: quote.request.numberOfEmployees,
      NumberofDirectEmployees: quote.request.directEmployees,
      LabourOnlySubContractors: quote.request.labourOnlySubContractors,
      BonafideSubContractors: quote.request.bonafideSubContractors,
      fastTrack: isFastTrack,
      fastTrackFee: checkoutBasket.fastTrack,
      callbackSuccessUrl: quote.request.successUrl,
      callbackCancelUrl: quote.request.cancelUrl,
      IsdataSharingAllowed: isDatashareAllowed
    };

    await this.paymentService.ProceedToStripeAsync(checkoutSession, grossAmount, opportunity);

    this.gtmService.payNow({
      status: GtmUserStatus.LoggedIn,
      uid: quote.accountId,
      ecommerce: {
        checkout: {
          actionField: { step: 1, option: 'Order Page'},
          products: [{
            id: checkoutBasket.productID,
            name: checkoutBasket.name,
            price: checkoutBasket.price,
            brand: GtmBrand.CHAS,
            category: GtmUserCategory.Contractor,
            variant: quote.applicationType,
            quantity: 1
          }]
        }
      }
    });

    return checkoutSession;
  }

  private async generateCheckoutBasket(quote: QuoteResponse, basketAccount: CreateAccountParameters, isFastTrack: boolean): Promise<BasketItem> {
    const basketItem: BasketItem = {
      accountId: quote.accountId,
      name: quote.result.productName,
      productID: quote.result.productId,
      applicationType: quote.applicationType,
      assessmentType: quote.assessmentType,
      units: quote.request.numberOfEmployees,
      price: quote.result.grandTotal,
      fastTrack: quote.result.fastTrackAmount,
      joiningFee: quote.result.joiningFeeAmount,
      checkFastTrack: isFastTrack,
      account: basketAccount,
      renewal: false,
      upgrade: false,
      discountAmount: quote.result.discountPercentage
    };

    this.store.dispatch({
      type: BasketItemsActions.Add,
      item: basketItem
    });

    this.gtmService.contractorSignUp({
      uid: this.basketService.accountID,
      status: GtmUserStatus.LoggedIn
    });

    return basketItem;
  }

 async parseQuoteRequestData(formData: any): Promise<IQuoteRequest> {
    const bundles = this.parseBundles(formData.customerType);
    let applicationType = formData.applicationTypeCode;

    let sectorType = formData.sectorType = typeof formData.sectorType === 'undefined' || formData.sectorType === null ? 885570006 : formData.sectorType;

    if(!this.isAus && formData.customerType !== UserTypeCode.SUPPLIER) {
      applicationType = this.productService.convertApplicationTypeToFeeCode(applicationType);
      sectorType = this.productService.convertSectorTypeToFeeCode(sectorType);
    }

    const request: IQuoteRequest = {
      accountId: formData.accountId ?? null,
      opportunityId: formData.opportunityId ?? null,
      assessmentType: formData.assessmentTypeCode,
      applicationType: applicationType,
      sectorType: sectorType,
      fastTrack: formData.fastTrack ?? false,
      firFee: formData.firFee ?? false,
      joiningFee: formData.joiningFee ?? false,
      discountCode: formData.discountCode ?? null,
      dataSharingAllowed: formData.dataSharingAllowed ?? false,
      selectedBundle: formData.selectedBundle ?? formData.BundleName,
      successUrl: this.environment.urls.environmentUrl + '/payment-success/register',
      cancelUrl: this.environment.urls.environmentUrl + '/register',
      customerType: formData.customerType,
      priceLevelId: '',
      BundleNames: bundles
    };

    return request;
  }

  async parseAccountContactQuoteRequestData(formData: any, validatedLead: ILead = null, clickDimensionId: string = null): Promise<IAccountContactQuote> {
    const data: IAccountContactQuote = {
      account: {
        companyHouseNo: formData.companyNumber,
        abNumber: formData.abNumber,
        acNumber:formData.acNumber,
        companyName: formData.companyName,
        customerType: formData.customerType,
        NoOfEmployees: formData.employees,
        NumberofDirectEmployees: formData.number_of_direct_employees,
        LabourOnlySubContractors: formData.number_of_labour_only_subcontractors,
        BonafideSubContractors: formData.number_of_bona_fide_subcontractors,
        tradingAs: null,
        variant: formData.applicationTypeCode,
        assessmentType: formData.assessmentTypeCode,
        isAusGstRegistered: formData.isAusGstRegistered
      },
      address: {
        line1: formData.street_number,
        line2: formData.route ?? null,
        city: formData.postal_town,
        postcode: formData.postal_code,
        countryName: formData.countryName,
        countryCode: formData.country_code,
        longitude: formData.longitude,
        latitude: formData.latitude
      },
      contact: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          emailAddress: formData.contactEmail,
          password: formData.contactPassword,
          telephone1: formData.contactPhone,
          mobile1: formData.Mobile1 ?? null
      },
      quote: await this.parseQuoteRequestData(formData),
      clickDimensionId: clickDimensionId,
      opportunityId: validatedLead?.opportunityId
    };

    return data;
  }

  parseGetQuoteCreateOpportunityCommand(formData: any, clickDimensionId: string = null): IGetQuoteCreateOpportunityCommand {
    const bundles = this.parseBundles(formData.customerType);
    let applicationType = formData.applicationTypeCode;
    let sectorType = formData.sectorType = typeof formData.sectorType === 'undefined' || formData.sectorType === null ? 885570006 : formData.sectorType;

    const cmd: IGetQuoteCreateOpportunityCommand = {
      isFIRFee: formData.firFee,
      accountId: formData.accountId,
      contactId: formData.contactId,
      assessmentType: formData.assessmentTypeCode,
      applicationType: applicationType,
      sectorType: sectorType,
      fastTrack: formData.fastTrack,
      firFee: formData.firFee,
      joiningFee: true,
      dataSharingAllowed: formData.confirmBuildUk,
      discountCode: formData.discountCode,
      selectedBundle: formData.BundleName,
      priceLevelId: null,
      membershipLevel: null,
      bundleNames: bundles,
      isUpgrade: formData.isUpgrade,
      createOpportunity: formData.createdDefaultOpportunity,
      directEmployees: formData.number_of_direct_employees,
      numberOfEmployees: formData.employees,
      labourOnlySubContractors: formData.number_of_labour_only_subcontractors,
      bonafideSubContractors: formData.number_of_bona_fide_subcontractors,
      customerType: formData.customerType,
      successUrl: this.environment.urls.environmentUrl + '/payment-success/register',
      cancelUrl: this.environment.urls.environmentUrl + '/register',
      checkoutSessionId: null,
      country: this.countryService.getNameFromCode(formData.country_code),
      countryCode: formData.country_code,
      clickDimensionId: clickDimensionId
    };

    if (cmd.numberOfEmployees == null || cmd.numberOfEmployees == 0) {
      cmd.numberOfEmployees = formData.number_of_labour_only_subcontractors + formData.number_of_direct_employees;
    }

    return cmd;
  }

  parseInternalQuoteRequest(formData: any): IInternalQuoteRequest{
    const bundles = this.parseBundles(formData.customerType);

    let applicationType = formData.assessmentTypeCode;
    let sectorType = formData.applicationTypeCode;

    if (formData.customerType !== UserTypeCode.SUPPLIER) {
      applicationType = this.productService.convertApplicationTypeToFeeCode(applicationType);
      sectorType = this.productService.convertSectorTypeToFeeCode(sectorType);
    }

    const request: IInternalQuoteRequest = {
      accountId: formData.accountId,
      contactId: formData.contactId,
      customerType: formData.customerType,
      sectorType: sectorType,
      applicationType: applicationType,
      assessmentType: formData.assessmentTypeCode,
      membershipLevel: formData.membershipLevel,
      isFIRFee: formData.isFIRFee,
      fastTrack: formData.fastTrack,
      joiningFee: formData.joiningFee,
      bundleNames: bundles
    };

    return request;
  }

  async createAndGetQuotesAsync(request: IAccountContactQuote): Promise<IAccountContactQuoteResponse>{
    return await this.http.post<IAccountContactQuoteResponse>(
      `${this.environment.apiEndpoint.accounts}/registeraccountcontactopportunity`, request)
      .toPromise();
  }

  convertToAccountFormValue(request: IAccountContactQuote){
    const formValue: IAccountFormValue = {
      companyNumber: request.account.companyHouseNo,
      abNumber:request.account.abNumber,
      acNumber:request.account.acNumber,
      companyName: request.account.companyName,
      street_number: request.address.line1,
      route: request.address.line2,
      postal_town: request.address.city,
      postal_code: request.address.postcode,
      country_code: request.address.countryCode,
      firstName: request.contact.firstName,
      lastName: request.contact.lastName,
      contactEmail: request.contact.emailAddress,
      contactEmailConfirm: request.contact.emailAddress,
      contactPassword: request.contact.password,
      contactPasswordConfirm: request.contact.password,
      contactPhone: request.contact.telephone1,
      number_of_direct_employees: request.account.NumberofDirectEmployees.toString(),
      number_of_labour_only_subcontractors: request.account.LabourOnlySubContractors.toString(),
      employees: request.account.NoOfEmployees.toString(),
      number_of_bona_fide_subcontractors: request.account.BonafideSubContractors.toString(),
      referenceCode: request.quote.discountCode,
      Bundle: request.quote.selectedBundle,
      BundleName: request.quote.selectedBundle,
      applicationTypeCode: request.quote.applicationType,
      assessmentTypeCode: request.quote.assessmentType,
      canSubmitControl: false,
      fastTrack: request.quote.fastTrack,
      confirmBuildUk: request.quote.dataSharingAllowed,
      customerType: request.account.customerType,
      isAusGstRegistered: request.account.isAusGstRegistered,
      longitude: request.address.longitude,
      latitude: request.address.latitude
    };

    return formValue;
  }

  public async getAllBundles(
    form,
    accountId: string,
    referenceCode = '',
    fastTrack = false,
    createOp = false,
  ) {
    let formValue: IAccountFormValue = Object.assign({}, form);
    const bundles = this.parseBundles(formValue.customerType);
    let  isContractorAdvanced = true;
    const output = {};

    for (const bundle of bundles) {
      formValue.BundleName = bundle;
      if(formValue.customerType === UserTypeCode.CONTRACTOR) {
        isContractorAdvanced = formValue.BundleName === BundleNames.Advanced;
      }
      const quoteData = {
        formValue: formValue,
        customerType: form.customerType ?? UserTypeCode.CONTRACTOR,
        createOp: createOp && isContractorAdvanced,
        referenceCode: referenceCode,
        joinFee: true,
        fastTrack: fastTrack
      };

      const priceList = await this.getQuote(
        quoteData,
        accountId
      );

      if(priceList) {
        output[formValue.BundleName.toLocaleLowerCase()] = priceList;
      }
    }

    return output;
  }

  private parseBundles(customerType: string){
    if(this.environment.territory === Territory.AUSTRALIA) {
      return this.parseBundlesAus(customerType);
    }

    if(customerType == UserTypeCode.SUPPLIER) {
      return [BundleNames.Supplier];
    }

    return [BundleNames.Standard, BundleNames.Advanced, BundleNames.Elite];
  }

  private parseBundlesAus(customerType: string) {

    if(customerType == UserTypeCodeAus.SUPPLIER)
      return [BundleNamesAus.VerifiedSupplier];

    return [
      BundleNamesAus.VerifiedContractor,
      BundleNamesAus.Elite
    ];
  }

  public async create(
    formValue: IAccountFormValue,
    countryName: string
  ): Promise<CreateAccountParameters> {
    const url = `${this.environment.apiEndpoint.catalyst}/account`;
    let payload = new CreateAccount().createParams({
      account: formValue,
      countryName: countryName
    });

    const res = await lastValueFrom<string>(this.http.post<string>(url, payload));

    sessionStorage.setItem('guidac', res);
    payload.accountId = res;

    this.store.dispatch({
      type: BasketAccountActions.Add,
      account: payload
    });

    return payload;
  }

  public createAll() {
    return combineLatest([
      this.registerOne$,
      this.registerTwo$,
      this.registerThree$,
      this.registerFour$
    ]).subscribe(async (data) => {
      let merged: any = {
        ...data[0],
        ...data[1],
        ...data[2],
        ...data[3]
      };
      await this.create(
        merged,
        'uk'
      );
    });
  }

  public async activateOpportunity(
    account: CreateAccountParameters,
    formValue: IAccountFormValue,
    quote: IQuickquote,
    basketItem: BasketItem
  ) {
    const bundleName = formValue.BundleName.toLowerCase();
    const grossAmount = quote[bundleName].lastQuoteResult.orderSummary.totalGrossAmount;
    let obj: any = {
      name: basketItem.name,
      accountId: basketItem.accountId,
      bundleId: basketItem.productID,
      price: basketItem.price,
      assessmentType: account.AssessmentType,
      variant: basketItem.applicationType,
      employeenum: account.NoOfEmployees,
      NumberofDirectEmployees: account.NumberofDirectEmployees,
      LabourOnlySubContractors: account.LabourOnlySubContractors,
      BonafideSubContractors: account.BonafideSubContractors,
      fastTrack: basketItem.checkFastTrack,
      fastTrackFee: basketItem.fastTrack,
      callbackSuccessUrl: account.SuccessUrl,
      callbackCancelUrl: account.CancelUrl,
      IsdataSharingAllowed: formValue.confirmBuildUk ?? false
    };

    await this.paymentService.activateAsync(grossAmount, obj);

    this.gtmService.payNow({
      status: GtmUserStatus.LoggedIn,
      uid: obj.accountId,
      ecommerce: {
        checkout: {
          actionField: { step: 1, option: 'Order Page'},
          products: [{
            id: obj.productID,
            name: obj.name,
            price: obj.price,
            brand: GtmBrand.CHAS,
            category: GtmUserCategory.Contractor,
            variant: obj.variant,
            quantity: 1
          }]
        }
      }
    });
  }

  public getCompanyHouseData(companyNo: string): Observable<CompaniesHouseCompanyDetails[]> {
    return this.companyHouseService.searchByName(companyNo);
  }

  public getAddresses(address: string): Observable<any> {
    return this.http.get(`${this.environment.apiEndpoint.newChasAPIv1}/addresssearch/search?searchText=${address}`);
  }

  public getAddressDetails(address) {
    const url = `${this.environment.apiEndpoint.newChasAPIv1}/addresssearch/detail`;
    return this.http.post<CompaniesHouseCompanyDetails>(url, address);
  }

  public getCompanyDetails(company: CompaniesHouseCompanyDetails): Observable<CompaniesHouseCompanyDetails> {
    return this.companyHouseService.getDetails(company);
  }

  public async getQuote(
    data: IQuoteData,
    accountId?: string,
    skipSummary = true
  ): Promise<ICreateOrder> {
    try {
      const req = await this.formatQuote({
        formValue: data.formValue,
        createOpportunity: data.createOp ?? false,
        discountCode: data.referenceCode ?? '',
        firFee: data.firFee ?? false,
        joinFee: data.joinFee ?? false,
        skipSummary: skipSummary ?? false,
        fastTrack: data.fastTrack ?? false,
        dataSharingAllowed: data.dataSharingAllowed ?? false,
        customerType: data.customerType ?? UserTypeCode.CONTRACTOR
      }, accountId);

      const quote = await this.productService.getMembershipQuoteAsync(req, true);

      if(data.createOp) {
        this.store.dispatch({
          type: BasketQuoteActions.Add,
          quote: quote.request
        });

        this.store.dispatch({
          type: BasketSummaryActions.Add,
          orderSummary: quote.orderSummary
        });
      }

      this.registerTrackingService.getQuote(data.formValue);

      if(quote.error) {
        this.monitor.log(quote.error.message, MonitorSeverity.CRITICAL);
      } else {
        const ftsQuoteAmount = quote.result.fastTrackAmount;
        const quoteAmount = quote.result.totalAmount;
        const lastQuoteResult = quote;

        return {
          ftsQuoteAmount: ftsQuoteAmount,
          quoteAmount: quoteAmount,
          lastQuoteResult: lastQuoteResult
        }
      }

      return null;
    } catch (e) {
      console.error(e);
      this.monitor.exception(e.message, MonitorSeverity.CRITICAL);
    }
  }

  public async createOrder(
    formValue: IAccountFormValue,
    data: ICreateOrder,
    payload: CreateAccountParameters,
  ): Promise<BasketItem> {
    const basketItem: BasketItem = {
      accountId: payload.accountId,
      name: formValue.BundleName,
      productID: data.lastQuoteResult?.result.productId,
      applicationType: formValue.applicationTypeCode,
      assessmentType: formValue.assessmentTypeCode,
      units: parseInt(formValue.employees),
      price: data.quoteAmount,
      fastTrack: data.ftsQuoteAmount,
      joiningFee: data.lastQuoteResult?.result.joiningFeeAmount,
      checkFastTrack: formValue.fastTrack,
      account: payload,
      renewal: false,
      upgrade: false,
      discountAmount: data.lastQuoteResult?.result.discountPercentage
    };

    this.store.dispatch({
      type: BasketItemsActions.Add,
      item: basketItem
    });

    this.gtmService.contractorSignUp({
      uid: this.basketService.accountID,
      status: GtmUserStatus.LoggedIn
    });

    return basketItem;
  }

  private async formatQuote(quote: IFormatQuote, accountId: string) {
    const isFoundation = quote.formValue.BundleName === BundleNames.Individual;
    const employees = parseInt(quote.formValue.number_of_direct_employees);
    const labourSubs = parseInt(quote.formValue.number_of_labour_only_subcontractors);
    const createOp = quote.createOpportunity ?? false;
    let priceListName = quote.formValue.BundleName;
    let cancelUrl = '/order-summary';

    if(quote.skipSummary) cancelUrl = '/register';

    let result: GetQuoteRequest = {
      accountId: accountId,
      sectorType: quote.formValue.applicationTypeCode,
      applicationType: quote.formValue.assessmentTypeCode,
      numberOfEmployees: this.registerTrackingService.recalculateEmployees(
        employees,
        labourSubs
      ),
      directEmployees: employees,
      labourOnlySubContractors: labourSubs,
      bonafideSubContractors: parseInt(quote.formValue.number_of_bona_fide_subcontractors),
      fastTrack: quote.fastTrack,
      firFee: quote.firFee,
      upgrade: false,
      joiningFee: quote.joinFee,
      createOpportunity: createOp,
      discountCode: quote.discountCode,
      dataSharingAllowed: quote.dataSharingAllowed,
      successUrl: this.environment.urls.environmentUrl + '/payment-success/register',
      cancelUrl: this.environment.urls.environmentUrl + cancelUrl,
      customerType: quote.customerType ?? UserTypeCode.CONTRACTOR
    }

    if(isFoundation) result.productId = productTypes.CHAS_FOUNDATION;

    if(quote.formValue.customerType === UserTypeCode.SUPPLIER) priceListName = 'Supplier';

    const matchingPriceList = await this.productService.searchForPriceListAsync(
      priceListName,
      false
    );
    result.priceLevelId = matchingPriceList.priceLevelId;
    return result;
  }

  public async getJoiningFeeAmount(
    data: IQuoteData,
    accountId: string,
    skipSummary = true
  ): Promise<number> {
    try {
      const req = await this.formatQuote({
        formValue: data.formValue,
        createOpportunity: false,
        discountCode: data.referenceCode,
        firFee: data.firFee,
        joinFee: data.joinFee,
        skipSummary: skipSummary,
        fastTrack: data.fastTrack,
        dataSharingAllowed: data.dataSharingAllowed,
        customerType: data.customerType ?? UserTypeCode.CONTRACTOR
      }, accountId);

      const quote = await this.productService.getMembershipQuoteAsync(req, true);

      if(quote.error) {
        this.monitor.log(quote.error.message, MonitorSeverity.CRITICAL);
        throw new Error(quote.error.message);
      }

      return quote.result.joiningFeeAmount;
    } catch (e) {
      console.error(e);
      this.monitor.exception(e.message, MonitorSeverity.CRITICAL);
    }
  }

  public async updateDataSharingStatus(accountId: string, isAllowed: boolean) {
    const url = `${this.environment.apiEndpoint.accounts}/public/account/${accountId}/UpdateConfirmations`;
    const opp = { AcceptedTermsAndConditions: isAllowed, AcceptedDataSharing: isAllowed, CorrespondenceAddress: null };
    return lastValueFrom(this.http.post(url, opp));
  }

  public async checkSupplierEnabled(): Promise<boolean> {
    // return new Promise((resolve, _reject) => {
    //   this.featureFlags$.subscribe((flags) => {
    //     setTimeout(() => {
    //       let value = false;
    //       const supplierFlag = flags
    //       .find(flag => flag.name === featureFlagNames.SUPPLIER)
    //         if(supplierFlag && supplierFlag.hasOwnProperty('enabled')) {
    //           value = supplierFlag.enabled;
    //         }
    //       resolve(value);
    //     }, 500);
    //   });
    // });
    return true;
  }

  public async checkAccountExistsAsync(request: ICheckAccountExistsRequest) : Promise<boolean> {
    return await this.http.post<boolean>(`${this.environment.apiEndpoint.accounts}/CheckAccountExists`, request).toPromise();
  }
}
