import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-chas-admin',
  templateUrl: './chas-admin.component.html',
  styleUrls: ['./chas-admin.component.scss']
})
export class ChasAdminComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    let currentPath = this.route.snapshot.url[0].path;

    switch(currentPath) {
      case 'chasadmin':
        this.authService.loginWithCHAS();
        break;
      case 'forgetpassword': 
        this.authService.forgetPassword();
        break;
      default:
        this.authService.externalLogin();
        break;
    }  
    
    this.router.navigateByUrl('/dashboard');
  }

}
