<div class="dropdown" dropdown *ngIf="accounts.length > 1">

  <button dropdownToggle
          id="dropdownAccountButton"
          class="account-menu__button btn dropdown-toggle"
          aria-controls="dropdownAccountMenu"
          type="button">

    <span>{{selectedAccount.accountName}}</span>
  </button>

  <!-- Dropdown menu. -->
  <div *dropdownMenu id="dropdownAccountMenu" class="dropdown-menu" aria-labelledby="dropdownAccountButton">
    <div class="dropdown-item" *ngFor="let account of accounts" (click)="selectAccount(account)">
      {{account.accountName}}
    </div>

  </div>
</div>
