import { createAction, props } from '@ngrx/store';
import { CreateAccountParameters } from 'src/app/shared';

export enum BasketAccountActions {
  Add = '[Basket Account] Add',
  AddSuccess = '[Basket Account] Add Successful',
  AddError = '[Basket Account] Add Error',
  Remove = '[Basket Account] Remove',
  RemoveSuccess = '[Basket Account] Remove Successful',
  RemoveError = '[Basket Account] Remove Error',
  Load = '[Basket Account] Load',
  LoadSuccess = '[Basket Account] Load Successful',
  LoadError = '[Basket Account] Load Error'
}

export const basketAccountAdd = createAction(BasketAccountActions.Add, props<{ account: CreateAccountParameters }>());
export const basketAccountAddSuccess = createAction(BasketAccountActions.AddSuccess, props<{ payload: CreateAccountParameters }>());
export const basketAccountAddError = createAction(BasketAccountActions.AddError);

export const basketAccountRemove = createAction(BasketAccountActions.Remove);
export const basketAccountRemoveSuccess = createAction(BasketAccountActions.RemoveSuccess, props<{ payload: CreateAccountParameters }>());
export const basketAccountRemoveError = createAction(BasketAccountActions.RemoveError);

export const basketAccountLoad = createAction(BasketAccountActions.Load);
export const basketAccountLoadSuccess = createAction(BasketAccountActions.LoadSuccess, props<{ payload: CreateAccountParameters }>());
export const basketAccountLoadError = createAction(BasketAccountActions.LoadError);
