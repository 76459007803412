import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { NotificationActions, NotificationDismiss, NotificationLoad } from "../actions/notification.actions";
import { NotificationsService } from "../../services/notifications.service";

@Injectable()
export class NotificationEffects {
    loadNotifications$ = createEffect(() => this.actions$.pipe(
        ofType(NotificationLoad),
        mergeMap((action) => this.notifications.getNotifications(action.accountId, action.contactId).pipe(
            map(notifications => ({ type: NotificationActions.LoadSuccess, notifications})),
            catchError(() => of({ type: NotificationActions.LoadFail}))
          ))
        ));

    dissmissNotification$ = createEffect(() => this.actions$.pipe(
        ofType(NotificationDismiss),
        mergeMap((action) => this.notifications.dismissNotification({...action.notification, hasBeenViewed: true}).pipe(
            map(notification => ({ type: NotificationActions.DismissSuccess })),
            catchError(() => of({ type: NotificationActions.DismissFail }))
          ))
    ));

    constructor(
        private actions$: Actions,
        private notifications: NotificationsService
      ) { }
}
