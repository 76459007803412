import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssessmentsPageComponent } from './assessments.component';
import { RouterModule, Routes } from '@angular/router';

import { BootstrapComponentsModule } from 'src/app/shared/components/bootstrap/bootstrap.module';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';

const routes: Routes = [
  { path: '', component: AssessmentsPageComponent }
]

@NgModule({
  declarations: [AssessmentsPageComponent],
  imports: [
    CommonModule, RouterModule.forChild(routes), BootstrapComponentsModule, LoadingSpinnerModule
  ],
  exports: [AssessmentsPageComponent]
})
export class AssessmentsPageModule { }
