<div class="direct-employees-text">
  Complete information about your company's number of employees and your customer type
</div>

<div class="grid">
  <app-form-field
  #tooltip="matTooltip"
          matTooltip="The number of employees is the total number of direct employees including, permanent, temporary, part-time and labour-only subcontractors"
    name="number_of_direct_employees"
    title="Number of employees"
    [showError]="false"
    [showLabel]="false"
    [control]="form.controls.number_of_direct_employees"
    type="number">
  </app-form-field>

  <div></div>
</div>
