import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { CheckAuthenticationResponse, CompanyDetails, productCodeTypes, UserTypeCode } from 'src/app/shared';
import { IAccountFormValue } from 'src/app/shared/classes/createAccount.class';
import { BundleNames } from 'src/app/shared/components/select-membership/select-membership.component';
import { IQuickquote } from 'src/app/shared/interfaces/new/quickQuote';
import { QuickquoteActions } from '../ngrx/actions/quickQuote.actions';
import { ProductService } from './product.service';
import { RegisterCoreService } from './register-core.service';

@Injectable({
  providedIn: 'root',
})
export class SiteMenuService {
  isFoundation = false;
  authDetails: CheckAuthenticationResponse;
  private readonly data$ = this.store.select(selectCurrentUserState);
  private visible: boolean;

  constructor(
    private readonly registerCoreService: RegisterCoreService,
    private readonly store: Store,
    private readonly productService: ProductService
  ) {}

  get isVisible(): boolean {
    return this.visible;
  }

  toggle(): void {
    this.visible = !this.visible;
  }

  public async getAllQuotes(formData: any) {
    formData.applicationTypeCode = productCodeTypes.CONSTRUCTION;
    formData.assessmentTypeCode = productCodeTypes.FULLAPPLICATION;
    formData.joiningFee = formData.customerType !== 'CHAS Foundation';

    const bundles = await this.registerCoreService.getQuotesOnlyAsync(formData);

    this.store.dispatch({
      type: QuickquoteActions.Add,
      quickQuote: bundles,
    });
  }
}
