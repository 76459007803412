export class ValidationResult {
  private errors = [];

  get getErrors() {
    return this.errors;
  }

  addError(ext: string): void {
    this.errors.push(ext);
  }

  isValid(): boolean {
    return this.errors.length === 0;
  }
}
