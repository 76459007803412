<div class="carousel__wrapper" *ngIf="ready">
    
    <div class="carousel__card" 
        *ngFor="let card of cards; let cardIndex=index" 
        [class.d-none]="!isSlide(cardIndex)"
        [style.background-color] = "card.backgroundColor"
        [style.color] = "card.foregroundColor">

        <div class="carousell__close">
            <button class="close pull-right" [style.color]="card.foregroundColor" title="Don't show me this" (click)="close(card.id)">×</button>
        </div>

        <div class="row">
            <div class="col-md-2 col-xs-12 m-0 p-0" *ngIf="card.imageUrl">
                <div class="carousel__image">
                    <img [src]="card.imageUrl" />
                </div>
            </div>
            <div [ngClass]="{ 'col-md-10': card.imageUrl, 'col-md-12': !card.imageUrl }">
                <div class="carousel__content" [innerHTML]="getSafeHtml(card.contentHtml)"></div>
            </div>
        </div>
        
        <div class="carousel__actions" *ngIf="card.callToActionUrl">
            <a *ngIf="!callToActionIsAnchor(card.callToActionUrl)" 
                [attr.href]="card.callToActionUrl" 
                [attr.target]="card.callToActionTarget" 
                [ngClass]="['btn', card.callToActionClass, 'carousel__actions__primary']">{{ card.callToActionText }}</a>
            
            <a *ngIf="callToActionIsAnchor(card.callToActionUrl)"
                (click)="navigateToAnchor(card)" 
                [ngClass]="['btn', card.callToActionClass, 'carousel__actions__primary']">{{ card.callToActionText }}</a>
        </div>
    </div>


    <ol class="carousel__indicators" *ngIf="cards.length > 1">
        <li *ngFor="let card of cards; let cardIndex=index" [class.active]="isSlide(cardIndex)" (click)="goToIndex(cardIndex)"></li>
    </ol>
</div>
