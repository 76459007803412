import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import { LoadingSpinnerComponent } from '../loading-spinner.component';

@Component({
    selector: 'app-loading-overlay',
    templateUrl: './loading-overlay.component.html',
    styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent implements OnInit, OnDestroy {
    @ViewChild(LoadingSpinnerComponent, { static: true }) loadingSpinner: LoadingSpinnerComponent;

    private element: any;

    constructor(private el: ElementRef) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        // Move the element to the bottom of page (just before </body>) so it can be displayed above everything else.
        document.body.appendChild(this.element);
    }

    // Remove the appended overlay when the component is destroyed.
    ngOnDestroy(): void {
        this.element.remove();
    }
}
