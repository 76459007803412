import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentListModalComponent } from './document-list-modal.component';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { BootstrapComponentsModule } from '../bootstrap/bootstrap.module';
import { DocumentListModule } from '../document-list/document-list.module';



@NgModule({
  declarations: [DocumentListModalComponent],
  imports: [
      DocumentListModule,
      FormsModule,
      ReactiveFormsModule,
      CommonModule, 
      BootstrapComponentsModule, 
      LoadingSpinnerModule
    ],
  exports: [DocumentListModalComponent]
})
export class DocumentListModalModule { }
