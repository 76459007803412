<div class="inner_content">
  <div class="card_text" *ngIf="!activeCard.active && !activeCard.credits">
    <p>Sign up to <strong>CHAS Plant</strong> to get a joined-up view of all plant owned and managed by you.</p>
    <p>Features include compliance tracking, service schedules, configurable dashboards and an in-app equipment reader.
    </p>
  </div>
  <div class="card_text" *ngIf="activeCard.credits">
    <ul>
      <li>
        {{activeCard.credits}} Assets
        <i class="fas fa-info-circle" tooltip="date of expiry" style="font-size: 12px;"></i>
      </li>
    </ul>
  </div>

  <div class="card_buttons" *ngIf="!activeCard.active && !activeCard.credits">
    <button class="btn btn-primary not_active" (click)="plantModal.show(); pushGoogleTag()">Buy credits and get
      started</button>
  </div>

  <div class="card_buttons" *ngIf="activeCard.credits">
    <button class="btn btn-primary cta on_card" [class.is-loading]="isLoading" (click)="loginAsync()"
      *ngIf="activeCard.active">Log in</button>
    <button class="btn btn-primary cta on_card" (click)="plantModal.show();pushGoogleTag();">Buy credits</button>
  </div>

</div>


<div bsModal #plantModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-sizes-name1" class="modal-title pull-left">CHAS Plant – Keep track of your plant & equipment</h4>
        <button type="button" class="close pull-right" #closebutton (click)="plantModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <img class="bundle-image" [src]="productImage">

        <h5>Features</h5>
        <ul>
          <li>Single view plant register containing a record of all owned & managed plant/machinery</li>
          <li>Repository of all maintenance records</li>
          <li>Repository of operator daily checks & inspections</li>
          <li>Evidence of compliance (e.g. NRMM Standards)</li>
          <li>Disclosure capability if needed for external parties (i.e. regulator or client audit)</li>
          <li>Asset workflow management – timely reminders of upcoming certifications & inspections</li>
        </ul>
        <h5>Benefits</h5>
        <ul>
          <li>Assist with compliance to the Provision and use of work equipment regulations (PUWER) </li>
          <li>Assist with compliance the Lifting operations and lifting equipment regulations (LOLER)</li>
          <li>Enables tracking of CO2 and Carbon emissions for SECR</li>
          <li>Tracks operator usage hours for compliance with both CPCS scheme and whole body vibration under the
            Control of vibration at work regulations </li>
        </ul>

        <form [formGroup]="purchasePlantForm">
          <div class="form-group">
            <label>Get Your Quote:</label>
            <div class="form-group">
              <input class="form-control" type="number" formControlName="numAssets"
                placeholder="How many assets do you have?" (change)="getQuoteAsync()" (keyup)="getQuoteAsync()" min="1"
                step="1" oninput="validity.valid||(value='');" />
            </div>
          </div>
          <div class="custom-control custom-checkbox">
            <input id="checkPlant" class="custom-control-input" type="checkbox" aria-describedby="checkPlant"
              formControlName="confirmPlant">
            <label id="checkPlantLabel" class="custom-control-label" for="checkPlant">
              By placing this order I confirm that I have read and accepted the CHAS Plant
              <a href="{{links.PLANT_TERMS}}" target="_blank">Terms & Conditions</a>
            </label>
          </div>
        </form>


        <div class="quote-container">
          <app-quote-container [amount]="quotePrice" [loading]="loadingQuote" [title]="'Price:'"
            [showVatLabel]="quoteHasVat"></app-quote-container>
          <button type="button" class="btn btn-primary" (click)="activateAync()" [class.is-loading]="modalbtnLoading"
            [disabled]="!purchasePlantForm.valid">
            Pay now
          </button>
        </div>
      </div>
    </div>
  </div>
</div>