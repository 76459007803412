let config: IEnvironment;

export class Environment {
    constructor() {}

    public async getConfig(): Promise<IEnvironment> {
      if(config) return config;
      
      const response = await fetch('/assets/settings/environment.json');
      const environment: IEnvironment = await response.json();

      environment.interceptor.protectedResourceMap = new Map(
        environment.interceptor.protectedResourceMap
      );

      config = environment;
      window['AppConfig'] = environment;

      return environment;
    }

    public getConfigSync(): IEnvironment {
      return config;
    }
}

export interface IEnvironment {
  countryCode: string;  
  territory: string;
  production: boolean;
  apiEndpoint: {
    companySearch: string;
    accounts: string;
    assessments: string;
    businessShield: string;
    catalyst: string;
    chasPeople: string;
    chasPlant: string;
    contacts: string;
    credits: string;
    humanFocus: string;
    notifications: string;
    workCategories: string;
    workLocations: string;
    documents: string;
    products: string;
    payments: string;
    thirdParty: string;
    companyAutoComplete: string;
    featureFlag: string;
    companiesHouse: string;
    companyAutoCompleteAus: string;
    companyGetDetailsAus: string;
    blobProducts: string;
    course: string;
    newChasAPIv1: string;
    newChasAPI: string;
  },
  publicDocumentsUri: string;
  companiesHouseAuth: string;
  vatPercent: number;
  monitorConfig :{
    connectionString: string;
  },
  googleAnalytics: {
    googleTagManagerId: string;
  },
  clickDimensions: {
    clickDimensionsId: string;
  },
  showKitchenSink: boolean;
  showDashboardCarousel: string;
  poll: {
    alerts: number;
    payments: number;
  },
  urls: {
    environmentUrl: string;
    gatewayHostName: string;
  },
  msal: {
    auth: {
      clientId: string;
      authority: string;
      chasauthority: string;
      resetAuthority: string;
      redirectUri: string;
      navigateToLoginRequestUrl: boolean;
      validateAuthority: boolean;
      knownAuthorities: Array<string>;
    },
    cache: {
      cacheLocation: string;
      storeAuthStateInCookie: boolean;
    }
    resetUrl: string;
  },
  guard: {
    interactionType: string;
    authRequest: {
      scopes: string[]
    },
    loginFailedRoute: string
  },
  interceptor: {
    protectedResourceMap: Map<string, string[]>
  },
  framework: {
    isAngular: boolean;
  }
    scopes: Array<string>;
  termsAndConditions: {
    lastUpdated: string;
    rationale: Array<string>;
    termsHtml: string;
    pdfDownload: string;
  },
  session: {
    idleTimeout: number;
    timeoutNotification: number;
  },
  fastFillToken: string;
  basketItemsSessionStorageKey: string;
  basketAccountSessionStorageKey: string;
  basketQuoteSessionStorageKey: string;
  basketSummarySessionStorageKey: string;
  quickQuoteSessionStorageKey: string;
  registerStepSessionStorageKey: string;
  registerStepOneSessionStorageKey: string;
  registerStepTwoSessionStorageKey: string;
  registerStepThreeSessionStorageKey: string;
  registerStepFourSessionStorageKey: string;
  showNewRegister: boolean;
  registerExpiryMinutes: number;
};