import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import * as  CountdownTimerActions  from '../actions/countdownTimerActionsEnum';
import { HttpClient } from '@angular/common/http';
import { Environment, IEnvironment } from '../../../shared/classes/environment';
import { CountdownTimerModel } from '../../../shared/interfaces/countdown-timer-response';
import { CountdownTimerActionsEnum } from '../actions/countdownTimerActionsEnum';

@Injectable()
export class CountdownTimerEffects {
  loadCountdownTimers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountdownTimerActionsEnum.Load),
      mergeMap((action: any) =>
        this.http.get(`${this.config.apiEndpoint.notifications}/accounts/${action.accountId}/getaccountbundledeadlines`)
          .pipe(
            map((b: CountdownTimerModel) => {
              return CountdownTimerActions.countdownTimerLoadSuccess({
                assessment: b[0],
                verification: b[1]
              });
            }),
            catchError((error) => of(CountdownTimerActions.countdownTimerLoadError({ error })))
          )
      )
    )
  )

  private readonly  config: IEnvironment;

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient
  ) {
    const env = new Environment();
    this.config = env.getConfigSync();
  }
}
