import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { LatestBundleResponse, ActiveCard, ThirdPartyProduct, SSOUrlParametersGenerator, AccountResponse, GetQuoteRequest, UserTypeCode, CheckNewAuthenticationResponse } from '../../../../shared';
import { PaymentService, ProductService, SSOService  } from '../../../../core';
import { CreateOpportunityParameters } from '../../../../shared/interfaces/create-opportunity-parameters';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';

@Component({
  selector: 'app-business-shield-tile',
  templateUrl: './business-shield-tile.component.html',
  styleUrls: ['./business-shield-tile.component.scss']
})
export class DashboardBusinessShieldTileComponent implements OnInit, OnChanges {
  @ViewChild('closebutton', { static: false }) closebutton;
  @Output() emitRagClass = new EventEmitter();
  @Output() emitLogin = new EventEmitter();
  @Input() bundle: LatestBundleResponse;
  @Input() account: AccountResponse;
  @Input() authAccount: CheckNewAuthenticationResponse;
  @Input() activeCard: ActiveCard;
  @Input() product: ThirdPartyProduct;
  @Input() typeAndVariant: { assessmentType: string, variant: string };
  @Input() membershipExpired: boolean;
  @Input() foundation: boolean;
  opportunityObject: CreateOpportunityParameters;
  modalRef: BsModalRef;
  isLoading: boolean;
  modalbtnLoading: boolean;
  error: string;
  private config: IEnvironment;
  constructor(
    private modalService: BsModalService,
    private SSO: SSOService,
    private Payment: PaymentService,
    private ProductService: ProductService) { }

  async ngOnInit(): Promise<void> {

    const env = new Environment();
    this.config = await env.getConfig();

    this.error = null;
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.getRagClass(this.activeCard.active);
    }, 10);
  }

  get productImage(): string {
    return `assets/img/product-cards/business_shield.png`;
  }

  getRagClass(activated) {
    if (activated) this.emitRagClass.emit(['green', false]);
    else this.emitRagClass.emit(['white', false]);
  }


  async loginAsync(): Promise<void> {
    if (this.isLoading) { return; }

    const params = new SSOUrlParametersGenerator(
      this.authAccount.accountId, this.authAccount.contactId
    ).businessShield();

    try {
      this.error = null;
      this.isLoading = true;
      let redirectUrl: string = await this.SSO.urlAsync(params);
      window.open(redirectUrl, "_blank");
    }
    catch(error) {
      this.error = error.message;
    }
    finally {
      this.isLoading = false;
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal(): void {
    this.closebutton.nativeElement.click();
  }

  async activateAync() {
    this.modalbtnLoading = true;

    const quoteRequest: GetQuoteRequest = {
      accountId: this.authAccount.accountId,
      contactId: this.authAccount.contactId,
      productId: this.product.id,
      numberOfEmployees: this.bundle.numEmployees,
      directEmployees: this.account.chas_numberofdirectemployees !== null? this.account.chas_numberofdirectemployees : 0,
      labourOnlySubContractors: this.account.chas_labouronlysubcontractors !== null? this.account.chas_labouronlysubcontractors: 0,
      bonafideSubContractors: this.account.chas_bonafidesubcontractors  !== null? this.account.chas_bonafidesubcontractors : 0,
      createOpportunity: true,
      successUrl: this.config.urls.environmentUrl + '/payment-success/membership',
      cancelUrl: this.config.urls.environmentUrl,
      dataSharingAllowed: false,
      customerType: UserTypeCode.CONTRACTOR
    };

    try {
      const quote = await this.ProductService.getIndividualQuoteAsync(quoteRequest);
      if(quote.error) { this.modalbtnLoading = false; return; }
      this.opportunityObject = this.ProductService.convertQuoteRequestToOpportunityObject(quoteRequest);
      await this.Payment.activateAsync(quote.result.totalAmount, this.opportunityObject, true);
    }
    catch(error) {
      this.modalbtnLoading = false;
    }
  }

}
