import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentModalComponent } from './document-modal.component';
import { LoadingSpinnerModule } from 'src/app/shared/components/loading-spinner/loading-spinner.module';
import { BootstrapComponentsModule } from '../bootstrap/bootstrap.module';
import { QuestionsetSurveyJSModule } from '../questionset-surveyjs/questionset-surveyjs.module';


@NgModule({
  declarations: [DocumentModalComponent],
  imports: [CommonModule, BootstrapComponentsModule, LoadingSpinnerModule, QuestionsetSurveyJSModule],
  exports: [DocumentModalComponent]
})
export class DocumentModalModule { }
