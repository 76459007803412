
import { createAction, props } from '@ngrx/store';
import { IRegisterstepone } from 'src/app/shared/interfaces/new/registerStepOne';

export enum RegistersteponeActions {
  Add = '[Registerstepone] Add',
  AddSuccess = '[Registerstepone] Add Successful',
  AddError = '[Registerstepone] Add Error',
  Remove = '[Registerstepone] Remove',
  RemoveSuccess = '[Registerstepone] Remove Successful',
  RemoveError = '[Registerstepone] Remove Error',
  Load = '[Registerstepone] Load',
  LoadSuccess = '[Registerstepone] Load Successful',
  LoadError = '[Registerstepone] Load Error'
}

export const registerStepOneAdd = createAction(RegistersteponeActions.Add, props<{ registerStepOne: IRegisterstepone }>());
export const registerStepOneAddSuccess = createAction(RegistersteponeActions.AddSuccess, props<{ payload: IRegisterstepone }>());
export const registerStepOneAddError = createAction(RegistersteponeActions.AddError);

export const registerStepOneRemove = createAction(RegistersteponeActions.Remove);
export const registerStepOneRemoveSuccess = createAction(RegistersteponeActions.RemoveSuccess, props<{ payload: IRegisterstepone }>());
export const registerStepOneRemoveError = createAction(RegistersteponeActions.RemoveError);

export const registerStepOneLoad = createAction(RegistersteponeActions.Load);
export const registerStepOneLoadSuccess = createAction(RegistersteponeActions.LoadSuccess, props<{ payload: IRegisterstepone }>());
export const registerStepOneLoadError = createAction(RegistersteponeActions.LoadError);
