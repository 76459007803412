import { Component, OnInit, HostListener, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Observable, Subscription, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AssessmentsService, AuthService, QuestionsetSurveyJSComponent } from '../../../core';
import {
  ComponentCanDeactivate,
  CheckAuthenticationResponse,
  AccountResponse,
  Assessment,
  CheckNewAuthenticationResponse,
  ContactRole,
  LatestBundleResponse,
} from '../../../shared';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { Store } from '@ngrx/store';
import { CompanyDetails } from 'src/app/shared';
import { CountdownState } from '../../../core/ngrx/reducers/countdownTimer.reducer';
import { getCountdownState } from '../../../core/ngrx/selectors/countdown-timer-selector';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-assessment-questionset',
  templateUrl: './questionset.component.html',
  styleUrls: ['./questionset.component.scss'],
})
export class AssessmentQuestionsetPageComponent implements OnInit, ComponentCanDeactivate {
  // NOTE: - Michael please refactor this component, unhandled RXJS subscriptions, non-encapsulated variables/functions, non-required variables etc. (Lewis 01/02/2023)
  clientId: any;
  jsonData: any;
  answers: any;
  authUser: CheckNewAuthenticationResponse;
  account: AccountResponse;
  assessmentId: any;
  allAssessments: Array<Assessment>;
  assessmentIdSubscription: any;
  renewalCopyInProgress: boolean = false;
  //@ts-ignore
  public countdownState: Observable<CountdownState> = this.store.select(getCountdownState);
  public today = DateTime.fromISO(DateTime.local().toISODate());
  companyDetails: CompanyDetails;
  private readonly data$ = this.store.select(selectCurrentUserState);
  private data: CurrentUserState;
  private $destroyed = new Subject();
  public userRoles: ContactRole[];
  public bundles: LatestBundleResponse[];

  constructor(private activeRoute: ActivatedRoute, private Assessments: AssessmentsService, private store: Store) {}

  isLoading: boolean;
  error: string;
  @ViewChild('surveyJsComponent') questionSetComponent: QuestionsetSurveyJSComponent;
  public assessmentCount = {
    timeRemaining: null,
  };
  public verificationCount = {
    timeRemaining: null,
  };

  async ngOnInit() {
    this.data$.subscribe((data) => {
      this.data = data;
      this.authUser = data.currentAccount;
      this.account = data.crm;
      this.userRoles = data.roles;
      this.bundles = data.bundles;
      this.companyDetails = data.companyDetails;
    });

    this.Assessments.cachedAssessments()
      .pipe(takeUntil(this.$destroyed))
      .subscribe((a) => {
        this.allAssessments = a;

        if (this.assessmentIdSubscription == null) {
          this.assessmentIdSubscription = this.activeRoute.params.pipe(takeUntil(this.$destroyed)).subscribe(async (params) => {
            await this.loadQuestionset(params.assessmentID);
            await this.calculateAssessmentTimer();
          });
        }
      });
  }

  private async calculateAssessmentTimer(): Promise<any> {
    const count: CountdownState = await firstValueFrom(this.countdownState);

    if (count?.assessment?.assessmentIds?.includes(this.assessmentId)) {
      this.assessmentCount.timeRemaining = count.assessment.respondByDateTime;
    }
    if (count?.verification?.assessmentIds?.includes(this.assessmentId)) {
      this.verificationCount.timeRemaining = count.verification.respondByDateTime;
    }
  }

  private clearTimerState(): void {
    this.verificationCount = { timeRemaining: null };
    this.assessmentCount = { timeRemaining: null };
  }

  get getAssessmentState() {
    let assessment = this.assessmentDetails;
    if (assessment == null) return null;
    return assessment.status.label;
  }

  get timeToRespond(): string {
    let assessment = this.assessmentDetails;
    if (assessment == null) return null;
    if (assessment.status.label != 'Additional Information Required') return null;
    return assessment.requiredToRespond;
  }

  get clonedState(): boolean {
    let assessment = this.assessmentDetails;
    if (assessment == null) return null;
    return assessment.clonedHistoricalAnswers && assessment.submittedDate == null;
  }

  get isCasAssessment() {
    let assessment = this.assessmentDetails;
    if (assessment == null) return false;
    let id = this.assessmentDetails.product?.id;
    if (id == null) return false;
    return id.includes('-CAS-');
  }

  get assessmentDetails(): Assessment {
    if (this.allAssessments == null) return null;
    let assessment = this.allAssessments.find((x) => x.id == this.assessmentId);
    if (assessment == null) return null;
    return assessment;
  }

  get showLoadingSpinner() {
    return this.isLoading || this.assessmentDetails == null;
  }

  /**
   * Returns whether the selected assessment is currently in the process
   * of copying over answers and not yet ready to edit. This method stores
   * the state on property 'renewalCopyInProgress'
   */
  isRenewalCopyInProgress(): boolean {
    if (this.allAssessments == null) {
      this.renewalCopyInProgress = false;
      return this.renewalCopyInProgress;
    }

    const assessment = this.allAssessments.find((x) => x.id == this.assessmentId);
    if (assessment == null) {
      this.renewalCopyInProgress = false;
      return this.renewalCopyInProgress;
    }

    const isCloned = assessment.clonedHistoricalAnswers;
    if (!isCloned) {
      this.renewalCopyInProgress = false;
      return this.renewalCopyInProgress;
    }

    const createdTime = new Date(assessment.created).getTime();
    const minAccessTime = createdTime + 5 * 60 * 1000;
    const now = new Date().getTime();

    this.renewalCopyInProgress = now < minAccessTime;
    return this.renewalCopyInProgress;
  }

  /**
   * Fetch the SurveyJS JSON for the questionset/assessment component.
   */
  public async loadQuestionset(questionsetID: string): Promise<void> {
    this.clearTimerState();
    this.assessmentId = questionsetID;

    if (this.isLoading) {
      return;
    }

    if (this.isRenewalCopyInProgress()) return;

    this.isLoading = true;
    this.jsonData = null;
    this.error = null;

    this.clientId = this.data.currentAccount.accountId!;
    this.Assessments.getQuestionSetWithAnswers(this.clientId, this.assessmentId).subscribe(async ([questionSet, answers]) => {
      await this.calculateAssessmentTimer();
      this.isLoading = false;
      this.jsonData = questionSet;
      this.answers = answers;
    });
  }

  async reloadQuestionSet(): Promise<void> {
    this.clearTimerState();
    this.isLoading = true;
    setTimeout(async () => {
      this.isLoading = false;
      await this.loadQuestionset(this.assessmentId);
      await this.calculateAssessmentTimer();
    }, 500);
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    const isChanged = this.questionSetComponent?.hasSurveyChanged;
    if (isChanged) {
      this.saveChanges();
      return false;
    }
    return false;
  }

  saveChanges(): void {
    this.questionSetComponent?.saveSurvey();
  }
}
