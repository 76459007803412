<!-- Table of results. -->
<div class="table-responsive-md">
  <table class="table" *ngIf="documents">
    <colgroup>
      <col style="width: 35%;">
      <col style="width: 25%;">
      <col style="width: 15%;">
      <col style="width: 10%;">
    </colgroup>
    <thead class="thead">
    <tr>
      <th (click)="sortBy('name')" class="column">
        File Name
        <i class="fa fa-sort-{{sortIcon}} {{ sortIcon === 'down' ? 'align-text-top' : 'align-middle'}}" *ngIf="column === 'name'"></i>
      </th>
      <th (click)="sortBy('module')" class="column">
        Module
        <i class="fa fa-sort-{{sortIcon}} {{ sortIcon === 'down' ? 'align-text-top' : 'align-middle'}}" *ngIf="column === 'module'"></i>
      </th>
      <th (click)="sortBy('type')" class="column">
        Document Type
        <i class="fa fa-sort-{{sortIcon}} {{ sortIcon === 'down' ? 'align-text-top' : 'align-middle'}}" *ngIf="column === 'type'"></i>
      </th>
      <th (click)="sortBy('date')" class="column">
        Uploaded
        <i class="fa fa-sort-{{sortIcon}} {{ sortIcon === 'down' ? 'align-text-top' : 'align-middle'}}" *ngIf="column === 'date'"></i>
      </th>

    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let document of documents;">
      <td>
        <i class="fa {{document.extension.toLowerCase() | extensionIcon}}-o fa-lg"></i>

        <button *ngIf="isAdmin; else showReadOnly"
                class="btn btn-link text-left pl-2 pr-0"
                alt="view document"
                type="button"
                (click)="viewDocument(document);">
          {{ document.name }}
        </button>
        <ng-template #showReadOnly>
          {{ document.name }}
        </ng-template>
      </td>

      <td>{{ document.module }}</td>

      <td>{{ document.type }}</td>

      <td>{{ document.uploaded | date:'d MMMM y' }}</td>

    </tr>
    </tbody>
  </table>
</div>

