export const AssessmentStatus = {
  SUBMITTED: 'Submitted',
  RESUBMITTED: 'Resubmitted',
  COMPLETE: 'Complete',
  IN_PROGRESS: 'In Progress',
  REJECTED: 'Assessment Rejected',
  ACCEPTED: 'Assessment Accepted',
  INFORMATION_REQUIRED: 'Additional Information Required',
  WITHDRAWN: 'Withdrawn',
  REFUNDED: 'Refunded',
  CANCELLED: 'Cancelled',
};
