export const AssuredReferenceCodes = {
  NEW_ECA02: 'NEW_ECA02',
  UPGRADE_ECA02: 'UPGRADE_ECA02',
  TAPDA21N: 'TAPDA21N',
  TAPDA21R: 'TAPDA21R',
  TABCSA21N: 'TABCSA21N',
  TABCSA21R: 'TABCSA21R',
  TABES21N: "TABES21N",
  TABES21R: "TABES21R"
};
