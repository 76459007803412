
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IRegisterstepone } from 'src/app/shared/interfaces/new/registerStepOne';
import { getStorage, setStorage } from 'src/app/shared/functions/getBasket.functions';
import { DateTime, Interval } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class RegistersteponeService {
  private readonly Registerstepone_STORAGE_KEY: string = 'registerStepOne';

  public getRegisterstepone(): Observable<IRegisterstepone> {
    return of(getStorage(this.Registerstepone_STORAGE_KEY));
  }

  public addRegisterstepone(newRegisterstepone: IRegisterstepone): Observable<IRegisterstepone> {
    return of(setStorage(newRegisterstepone, this.Registerstepone_STORAGE_KEY));
  }

  public removeRegisterstepone(): Observable<undefined> {
    this.removeStorage(this.Registerstepone_STORAGE_KEY);
    return of(undefined);
  }

  private removeStorage(storageKey: string): void {
    return sessionStorage.removeItem(storageKey);
  }
}
