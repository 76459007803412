import { createAction, props } from '@ngrx/store';
import { GetOrderSummary } from 'src/app/shared';

export enum BasketSummaryActions {
  Add = '[Basket Summary] Add',
  AddSuccess = '[Basket Summary] Add Successful',
  AddError = '[Basket Summary] Add Error',
  Remove = '[Basket Summary] Remove',
  RemoveSuccess = '[Basket Summary] Remove Successful',
  RemoveError = '[Basket Summary] Remove Error',
  Load = '[Basket Summary] Load',
  LoadSuccess = '[Basket Summary] Load Successful',
  LoadError = '[Basket Summary] Load Error'
}

export const BasketSummaryAdd = createAction(BasketSummaryActions.Add, props<{ orderSummary: GetOrderSummary }>());
export const BasketSummaryAddSuccess = createAction(BasketSummaryActions.AddSuccess, props<{ payload: GetOrderSummary }>());
export const BasketSummaryAddError = createAction(BasketSummaryActions.AddError);

export const BasketSummaryRemove = createAction(BasketSummaryActions.Remove);
export const BasketSummaryRemoveSuccess = createAction(BasketSummaryActions.RemoveSuccess, props<{ payload: GetOrderSummary }>());
export const BasketSummaryRemoveError = createAction(BasketSummaryActions.RemoveError);

export const BasketSummaryLoad = createAction(BasketSummaryActions.Load);
export const BasketSummaryLoadSuccess = createAction(BasketSummaryActions.LoadSuccess, props<{ payload: GetOrderSummary }>());
export const BasketSummaryLoadError = createAction(BasketSummaryActions.LoadError);
