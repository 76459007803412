<div class="card" [ngClass]="{
  'card--selected': isSelected
}">
  <div class="card__header" *ngIf="headerTitle || headerImage">
    <img class="card__header-image" *ngIf="headerImage" [src]="headerImage" [alt]="title"/>
    <h4 class="card__header-title" *ngIf="headerTitle">{{headerTitle}}</h4>
  </div>

  <div *ngIf="image" class="card__image"><img [src]="image" [alt]="title"/></div>
  <h4 *ngIf="title" class="card__title">{{title}}</h4>

  <div class="card__body">
    <ng-content></ng-content>
  </div>

  <div class="card__action">
    <app-button
     type="button"
     color="primary"
     (click)="selectCard()"
     [block]="buttonBlock">
     {{isSelected ? 'Selected' : 'Select'}}
    </app-button>
  </div>
</div>
