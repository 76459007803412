import { Component, Input, OnInit } from '@angular/core';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';

@Component({
  selector: 'app-tier3-card',
  templateUrl: './tier3-card.component.html',
  styleUrls: ['./tier3-card.component.scss']
})
export class DashboardTier3CardComponent implements OnInit {
  @Input() size: number;
  @Input() type: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() content: string;
  @Input() cta: string;
  @Input() ctaUrl: string;
  @Input() cta2: string;
  @Input() ctaUrl2: string;
  @Input() membershipExpired: boolean;
  @Input() foundation: boolean;
  @Input() enabledForAll: boolean;
  error: string;
  section_text: any;
  image_size: any;
  button_container: any;
  private config: IEnvironment;

  constructor(private readonly gtmService: GtmService) { }

  async ngOnInit() {
    const env = new Environment();
    this.config = await env.getConfig();

    if (this.size === 4) {
      this.section_text = ['size_4'];
      this.image_size = ['tier3-card__badge_4'];
      this.button_container = ['button_container', 'size4'];
    }
    else if (this.size === 6) {
      this.section_text = ['size_6'];
      this.image_size = ['tier3-card__badge_6'];
      this.button_container = ['button_container', 'size6'];
    }

  }

  get getContent() {
    if (this.content === null) {
      return new Array<string>();
    } else {
      return this.content;
    }

  }


  get showCta2(): boolean {
    return this.cta2 && this.cta2.length > 0 ;
  }

  get productImage(): string {
    return this.type ? `assets/img/product-cards/${this.type.toLowerCase().split(' ').join('_')}.png` : null;
  }

  doAnalytics() {
    this.gtmService.pushTag({
      id: this.config.googleAnalytics.googleTagManagerId,
      event: 'tier3_click',
      type: this.type
    });
  }

  get disableButton() {
    if (this.enabledForAll) return false;

    if (this.membershipExpired === true
      && !this.foundation) {
      return true;
    } else {
      return false;
    }
  }

}
