<div class="layout layout--auth" [ngClass]="{
  'layout--register': isNewRegisterPage
}">
  <section role="main" id="chas-main-content-area" [ngClass]="{'newBackground': newBackground }">
    <router-outlet></router-outlet>
  </section>

  <!-- SITE FOOTER -->
  <footer *ngIf="!isNewRegisterPage">
    <app-site-footer></app-site-footer>
  </footer>
</div>
