import { Component, OnInit, ViewChild, Output, EventEmitter, TemplateRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { first, finalize } from 'rxjs/operators';
import securitySettingsJSON from 'src/assets/json/security-settings.json';
import { AccountsService } from '../../../core/services/accounts.service';
import { AccountResponse, CheckAuthenticationResponse, CheckNewAuthenticationResponse } from '../../index';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { Store } from '@ngrx/store';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';

@Component({
  selector: 'app-security-settings-modal',
  templateUrl: './security-settings-modal.component.html',
  styleUrls: ['./security-settings-modal.component.scss'],
})
export class SecuritySettingsModalComponent implements OnInit {
  @Output() savedSettings = new EventEmitter();
  @ViewChild('modalSecuritySettings', { static: true }) modal: TemplateRef<any>;

  fields = securitySettingsJSON;
  settingsForm = new UntypedFormGroup({});

  private modalRef: BsModalRef;
  currentUser: CheckNewAuthenticationResponse;
  account: AccountResponse;
  isSaving: boolean;
  error: string;
  private readonly data$ = this.store.select(selectCurrentUserState);
  private data: CurrentUserState;

  constructor(private Accounts: AccountsService, private modalService: BsModalService, private readonly store: Store) {}

  ngOnInit() {
    this.data$.subscribe((data) => {
      if (data.loaded) {
        this.data = data;
        this.currentUser = data.currentAccount;
        this.createFormFieldsFromOptions();
      }
    });
  }

  /**
   * Set up our reactive form.
   */
  private createFormFieldsFromOptions() {
    this.fields.forEach((row) => {
      this.settingsForm.addControl(row.key, new UntypedFormControl('', [Validators.required]));
    });
  }

  /**
   * Populate the form based on the current user's account security settings.
   */
  populateSecuritySettings(): void {
    this.disableForm();
    this.enableForm();

    this.account = this.data.crm;

    this.settingsForm.setValue({
      chas_useraccountexpiry: this.account.chas_useraccountexpiry,
      chas_minimumpasswordlength: this.account.chas_minimumpasswordlength,
      chas_passwordcomplexity: this.account.chas_passwordcomplexity,
      chas_passwordexpiry: this.account.chas_passwordexpiry,
      chas_passwordlock: this.account.chas_passwordlock,
      chas_passwordreuseprevention: this.account.chas_passwordreuseprevention,
    });
  }

  /**
   * Reveal this component's modal window.
   */
  open(): void {
    if (this.currentUser) {
      this.populateSecuritySettings();
      this.modalRef = this.modalService.show(this.modal, { animated: false, backdrop: 'static', class: 'modal-lg' });
    }
  }

  /**
   * Closes the dialogue/modal window without saving.
   */
  close(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  /**
   * Save the security settings.
   */
  save(): void {
    if (!this.currentUser || this.isSaving) {
      return;
    }

    this.disableForm();
    this.isSaving = true;
    this.error = null;

    this.Accounts.updateSecuritySettings(this.currentUser.accountId, this.settingsForm.value)
      .pipe(
        first(),
        finalize(() => this.enableForm())
      )
      .subscribe({
        next: () => {
          // Settings were saved.
          this.close();
        },
        error: (response) => {
          // There was an error saving the settings.
          this.error = response.error.Message;
        },
      });
  }

  trackByFn(index: number, item: any) {
    return index;
  }

  private disableForm() {
    this.settingsForm.disable();
    this.isSaving = true;
  }

  private enableForm() {
    this.settingsForm.enable();
    this.isSaving = false;
  }
}
