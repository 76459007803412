export interface DocumentExpiryResponse {
  accountId: string;
  assessmentId: string;
  assessmentStatus: string;
  contractorId?: any;
  expiryDate: string;
  fileName: string;
  id?: any;
  instance: number;
  productID: string;
  productId?: any;
  productName: string;
  question: string;
  questionSet: string;
  type: string;
  uploaded: string;
  uri?: any;
}
