export const FDN_TBL_DATA = [
  {
    'name': 'Free Credit Check',
    'foundation': true,
    'accredited': true
  },
  {
    'name': 'Discounted E-Learning',
    'foundation': true,
    'accredited': true
  },
  {
    'name': 'Discounted Fuel Card',
    'foundation': true,
    'accredited': true
  },
  {
    'name': 'Free Templates & Checklists',
    'foundation': true,
    'accredited': true,
    'content': 'Restricted'
  },
  {
    'name': 'SSIP Accreditation',
    'foundation': false,
    'accredited': true
  },
  {
    'name': 'PAS91 Compliance*',
    'foundation': false,
    'accredited': true
  },
  {
    'name': 'Common Assessment Standard Compliance**',
    'foundation': false,
    'accredited': true
  },
  {
    'name': 'Visibility to 2,000+ Clients',
    'foundation': false,
    'accredited': true
  },
  {
    'name': 'Business Shield',
    'foundation': false,
    'accredited': true
  },
  {
    'name': 'Full suite of Benefits',
    'foundation': false,
    'accredited': true
  },
  {
    'name': 'Permission to use CHAS Logo',
    'foundation': false,
    'accredited': true
  },
  {
    'name': 'Award Winning Support',
    'foundation': false,
    'accredited': true
  }
]
