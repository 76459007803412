import { createAction, props } from "@ngrx/store";
import { CourseProgress } from "src/app/shared/interfaces/course";

enum CourseActions {
    LoadCourseProgress = '[Course Progress] Load',
    LoadCourseProgressSuccess = '[Course Progress] Load Success',
    LoadCourseProgressFailure = '[Course Progress] Load Fail',
    UpdateCourseProgress = '[Course Progress] Update',
    UpdateCourseProgressSuccess = '[Course Progress] Update Success',
    UpdateCourseProgressFailure = '[Course Progress] Update Fail',
}

export const loadCourseProgress = createAction(CourseActions.LoadCourseProgress, props<{ accountId: string }>());
export const loadCourseProgressSuccess = createAction(CourseActions.LoadCourseProgressSuccess, props<{ courseProgress: CourseProgress[] }>());
export const loadCourseProgressFailure = createAction(CourseActions.LoadCourseProgressFailure, props<{ error: string }>());

export const updateCourseProgress = createAction(CourseActions.UpdateCourseProgress, props<{ courseProgress: CourseProgress }>());
export const updateCourseProgressSuccess = createAction(CourseActions.UpdateCourseProgressSuccess, props<{ courseProgress: CourseProgress }>());
export const updateCourseProgressFailure = createAction(CourseActions.UpdateCourseProgressFailure, props<{ error: string }>());