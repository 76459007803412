<div class="register-form">
    <div class="logo-header">
        <span class="h2 left become-chas-accredited" *ngIf="!showFoundation">Become CHAS Accredited: join us today</span>
        <span class="h2 left become-chas-accredited" *ngIf="showFoundation">Join CHAS Today</span>
        <img class="logo" src="../../../assets/img/logo/mychas-purple.png">
    </div>

    <div class="alert alert-grey">
        <span class="left">Already a CHAS member? <a href="#" class="alert-link" (click)="goToLogin()">Sign in</a>  to access your member benefits and accreditation.</span>
        <button class="btn btn-secondary right" (click)="goToLogin()">Sign in</button>
    </div>

    <!-- Search for company details. -->
    <app-company-search-field *ngIf="!isManualEntry" (companySearch)="onCompanySearchEvent($event)"></app-company-search-field>

    <form *ngIf="isManualEntry" (submit)="getQuote()" [formGroup]="registerForm">

        <!-- Enter company details manually? -->
        <div class="registration__closeManualEntry">
          <div class="form-group row" *ngIf="isCompanyHouse">
            <div class="col-md-6">
              <h2>Not your company or not listed on Companies House?</h2>
              <p>
                <a href="/register-foundation" (click)="closeManualEntry($event)">Click here</a>
                to search again or you can enter your details manually
              </p>
            </div>
            <div class="col-md-5">
              <!-- Enter manually button. -->
              <button class="btn btn-primary" type="button" (click)="isCompanyHouse = !isCompanyHouse; enterDetailsManually();">Enter manually</button>
            </div>
          </div>
          <div *ngIf="!isCompanyHouse">
            <h2>Registered on Companies House?</h2>
            <p>
              <a href="/register-foundation" (click)="closeManualEntry($event)">Click here</a>
              to use your Company Registration Number to pre-fill your details.
            </p>
          </div>
        </div>

        <!-- Company number. -->
        <div class="form-group row" *ngIf="isCompanyHouse">
            <div class="col-md-4">
                <label for="regCompanyNumber" class="form-label">Company House Number</label>
            </div>
            <div class="col-md-5">
                <p *ngIf="isCompanyHouse">{{registerForm.controls.companyNumber.value}}</p>
            </div>
        </div>

        <!-- Company name. -->
        <div class="form-group row">
            <div class="col-md-4">
                <label for="regCompanyName" class="form-label">Company Name</label>
            </div>
            <div class="col-md-5">
                <input *ngIf="!isCompanyHouse" type="text" id="regCompanyName" class="form-control" formControlName="companyName" />
                <p *ngIf="isCompanyHouse">{{registerForm.controls.companyName.value}}</p>
                <span class="text-danger" *ngIf="registerForm.controls.companyName.touched && registerForm.controls.companyName.errors">
                  *Company Name is required
                </span>
            </div>
        </div>

        <!-- Google place autocomplete API. -->
        <hr />
        <div class="form-group row" *ngIf="!isCompanyHouse">
            <div class="col-md-4">
            </div>
            <div class="col-md-5">
                <div class="input-group" id="places-api">
                    <input ngx-google-places-autocomplete [options]='autocompleteOptions' (onAddressChange)="handleAddressChange($event)" #placesRef="ngx-places" class="form-control" autocomplete="off" placeholder="Search for an address" />
                    <div class="input-group-append">
                        <button class="btn">
                          <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Street No. -->
        <div class="form-group row">
            <div class="col-md-4">
                <label for="regStreetNo" class="form-label">Address Line 1</label>
            </div>
            <div class="col-md-8">
                <input *ngIf="!isCompanyHouse" type="text" id="regStreetNo" class="form-control" formControlName="street_number" />
                <span class="text-danger" *ngIf="registerForm.controls.street_number.touched && registerForm.controls.street_number.errors?.required">*Address Line 1 is required</span>
                <p *ngIf="isCompanyHouse">{{registerForm.controls.street_number.value}}</p>
            </div>
        </div>

        <!-- Address line 2. -->
        <div class="form-group row">
            <div class="col-md-4">
                <label for="regRoute" class="form-label">Address Line 2</label>
            </div>
            <div class="col-md-8">
                <input *ngIf="!isCompanyHouse" type="text" id="regRoute" class="form-control" formControlName="route" />
                <p *ngIf="isCompanyHouse">{{registerForm.controls.route.value}}</p>
            </div>
        </div>


        <!-- Town/City. -->
        <div class="form-group row">
            <div class="col-md-4">
                <label for="regTown" class="form-label">Town/City</label>
            </div>
            <div class="col-md-8">
                <input *ngIf="!isCompanyHouse" type="text" id="regTown" class="form-control" formControlName="postal_town" />
                <span class="text-danger" *ngIf="registerForm.controls.postal_town.touched && registerForm.controls.postal_town.errors?.required">*Town/City is required</span>
                <p *ngIf="isCompanyHouse">{{registerForm.controls.postal_town.value}}</p>
            </div>
        </div>

        <!-- Postcode. -->
        <div class="form-group row">
            <div class="col-md-4">
                <label for="regPostcode" class="form-label">Postcode</label>
            </div>
            <div class="col-md-5">
                <input *ngIf="!isCompanyHouse" type="text" id="regPostcode" class="form-control" formControlName="postal_code" />
                <span class="text-danger" *ngIf="registerForm.controls.postal_code.touched && registerForm.controls.postal_code.errors?.required">*Postcode is required</span>
                <p *ngIf="isCompanyHouse">{{registerForm.controls.postal_code.value}}</p>
            </div>
        </div>

        <!-- Country. -->
        <div class="form-group row">
          <div class="col-md-4">
              <label for="regCountry" class="form-label">Country</label>
          </div>
          <div class="col-md-5">
            <select name="cars" id="regCountry" formControlName="country_code" class="form-control">
              <option *ngFor="let country of countries" [value]="country.code">{{ country.shortName }}</option>
            </select>
            <span class="text-danger" *ngIf="registerForm.controls.country_code.touched && registerForm.controls.country_code.errors?.required">*Country is required</span>
          </div>
        </div>

        <hr />

        <!-- First name. -->
        <div class="form-group row">
            <div class="col-md-4">
                <label for="regContactName" class="form-label">First Name</label>
            </div>
            <div class="col-md-8">
                <input type="text" id="regFirstName" class="form-control" formControlName="firstName" />
                <span class="text-danger" *ngIf="registerForm.controls.firstName.touched && registerForm.controls.firstName.errors">
                  *First Name is required
                </span>
            </div>
        </div>

        <!-- Last name. -->
        <div class="form-group row">
            <div class="col-md-4">
                <label for="regContactName" class="form-label">Last Name</label>
            </div>
            <div class="col-md-8">
                <input type="text" id="regLastName" class="form-control" formControlName="lastName" />
                <span class="text-danger" *ngIf="registerForm.controls.lastName.touched && registerForm.controls.lastName.errors">
                  *Last Name is required
                </span>
            </div>
        </div>

        <!-- Contact email. -->
        <div class="form-group row">
            <div class="col-md-4">
                <label for="regContactEmail" class="form-label">Contact Email</label>
            </div>
            <div class="col-md-8">
                <input type="text" id="regContactEmail" class="form-control" formControlName="contactEmail" placeholder="Email Address" />
                <span class="text-danger" *ngIf="registerForm.controls.contactEmail.touched && registerForm.controls.contactEmail.errors?.required">
                  *Contact Email is required
                </span>
                <span class="text-danger" *ngIf="registerForm.controls.contactEmail.touched && registerForm.controls.contactEmail.errors?.email">
                  *Must enter a valid email address
                </span>
                <input type="text" id="confirmContactEmail" class="form-control matching-input" formControlName="contactEmailConfirm" placeholder="Confirm Email Address" />
                <span class="text-danger" *ngIf="registerForm.controls.contactEmailConfirm.touched && registerForm.controls.contactEmailConfirm.errors?.email">
                  *Must enter a valid email address
                </span>
                <span class="text-danger" *ngIf="registerForm.controls.contactEmailConfirm.touched && registerForm.controls.contactEmailConfirm.errors?.missmatch">
                  *Does not match your email address
                </span>
            </div>
        </div>

        <!-- Contact password. -->
        <div class="form-group row">
            <div class="col-md-4">
                <label for="regPassword" class="form-label">Password</label>
            </div>
            <div class="col-md-8" [tooltip]="passwordInfo">
                <input type="password" id="regPassword" class="form-control" formControlName="contactPassword" placeholder="Password" />
                <span class="text-danger" *ngIf="registerForm.controls.contactPassword.touched && registerForm.controls.contactPassword.errors?.required">
                  *Password is required
                </span>
                <span class="text-danger" *ngIf="registerForm.controls.contactPassword.touched && registerForm.controls.contactPassword.errors?.pattern">
                  *Password must contain 3 of the following components; uppercase, lowercase, number, or special character
                </span>
                <span class="text-danger" *ngIf="registerForm.controls.contactPassword.touched && registerForm.controls.contactPassword.errors?.match">
                  *Password must not match your email address
                </span>
                <input type="password" id="confirmPassword" class="form-control matching-input" formControlName="contactPasswordConfirm" placeholder="Confirm password" />
                <span class="text-danger" *ngIf="registerForm.controls.contactPasswordConfirm.touched && registerForm.controls.contactPasswordConfirm.errors?.required">
                  *Confirm Password is required
                </span>
                <span class="text-danger" *ngIf="registerForm.controls.contactPasswordConfirm.touched && registerForm.controls.contactPasswordConfirm.errors?.missmatch">
                  *Does not match your password
                </span>
            </div>
        </div>

        <!-- Contact phone number. -->
        <div class="form-group row">
            <div class="col-md-4">
                <label for="regPhone" class="form-label">Contact Phone Number</label>
            </div>
            <div class="col-md-8">
                <input type="tel" id="regPhone" class="form-control" formControlName="contactPhone" />
                <span class="text-danger" *ngIf="registerForm.controls.contactPhone.touched && registerForm.controls.contactPhone.errors?.required">
                  *Contact Phone Number is required
                </span>
                <span class="text-danger" *ngIf="registerForm.controls.contactPhone.touched && registerForm.controls.contactPhone.errors?.pattern">
                  *Please enter a valid phone number between 9 and 17 digits
                </span>
            </div>
        </div>

        <!-- Number of direct employees. -->
        <div class="form-group row">
            <label class="col-md-4 col-sm-5 col-form-label" for="registerNumDirectEmployees">
              Number of Direct Employees<app-helper class="float-right" [helpText]="employeesInfo"></app-helper>
              <div class="annotation">Your figure should include all directly employed office staff including managers, directors, clerical etc.</div>
            </label>
            <div class="col-md-2">
                <input id="registerNumDirectEmployees" class="form-control" type="number" formControlName="number_of_direct_employees" (change)="recalculateEmployees($event)" min="1" step="1" oninput="validity.valid||(value='');" />
            </div>

            <span class="text-danger" *ngIf="registerForm.controls.number_of_direct_employees.touched && registerForm.controls.number_of_direct_employees.errors?.required">
              *Number of Direct Employees is required
            </span>
            <span class="text-danger" *ngIf="registerForm.controls.number_of_direct_employees.touched && registerForm.controls.number_of_direct_employees.errors?.min">
              *Number of Direct Employees cannot be below 1
            </span>
        </div>

        <!-- Number of labour only subcontractors. -->
        <div class="form-group row">
            <label class="col-md-4 col-sm-5 col-form-label" for="registerNumLabouOnlySubcontractors">Number of Labour Only Subcontractors<app-helper class="float-right" [helpText]="employeesInfo"></app-helper></label>
            <div class="col-md-2">
                <input id="registerNumLabouOnlySubcontractors" class="form-control" type="number" formControlName="number_of_labour_only_subcontractors" (change)="recalculateEmployees($event)" min="0" step="1" oninput="validity.valid||(value='');" />
            </div>

            <span class="text-danger" *ngIf="registerForm.controls.number_of_labour_only_subcontractors.touched && registerForm.controls.number_of_labour_only_subcontractors.errors?.required">
              *Number of Labour Only Subcontractors is required
            </span>
            <span class="text-danger" *ngIf="registerForm.controls.number_of_labour_only_subcontractors.touched && registerForm.controls.number_of_labour_only_subcontractors.errors?.min">
              *Number of Labour Only Subcontractors cannot be below 0
            </span>
        </div>

        <!-- Total Effective Employees. -->
        <div class="form-group row">
            <label for="regEmployees" class="col-md-4 col-sm-5 col-form-label">Total Effective Number of Employees<app-helper class="float-right" [helpText]="employeesInfo"></app-helper></label>
            <div class="col-md-2">
                <input type="number" id="regEmployees" class="form-control" formControlName="employees" (change)="changeNoEmployees($event)" min="0" />
                <span class="text-danger" *ngIf="registerForm.controls.employees.touched && registerForm.controls.employees.errors">
                  *Number of Employees is required
                </span>
                <span class="text-danger" *ngIf="registerForm.controls.employees.touched && registerForm.controls.employees.min">
                  *Number of Employees cannot be below 0
                </span>
            </div>
        </div>

        <!-- Number of bona fide subcontractors. -->
        <div class="form-group row">
            <label class="col-md-4 col-sm-5 col-form-label" for="registerNumBonaFideSubcontractors">Number of Bona Fide Subcontractors<app-helper class="float-right" [helpText]="employeesInfo"></app-helper></label>
            <div class="col-md-2">
                <input id="registerNumBonaFideSubcontractors" class="form-control" type="number" formControlName="number_of_bona_fide_subcontractors" min="0" step="1" oninput="validity.valid||(value='');" />
            </div>

            <span class="text-danger" *ngIf="registerForm.controls.number_of_bona_fide_subcontractors.touched && registerForm.controls.number_of_bona_fide_subcontractors.errors?.required">
              *Number of Bona Fide Subcontractors is required
            </span>
            <span class="text-danger" *ngIf="registerForm.controls.number_of_bona_fide_subcontractors.touched && registerForm.controls.number_of_bona_fide_subcontractors.errors?.min">
              *Number of Bona Fide Subcontractors cannot be below 0
            </span>
        </div>

        <!-- Select a product bundle. -->
        <h3 class="h4 mt-5">Product Selection</h3>
        <p style="margin-bottom:-20px">Please select one</p>
        <app-product-bundles formControlName="Bundle" [foundation]="showFoundation" [preselectedProductNumber]="preselectedProductNumber"></app-product-bundles>
        <span class="text-danger" *ngIf="registerForm.invalid.Bundle">Bundle</span>

        <!-- Select an application type. -->
        <div *ngIf="registerForm.value.Bundle && isEliteBundle() && registerForm.value.Bundle.name !== 'Foundation'">
            <h3 class="h4">Health & Safety Application Type</h3>
            <p>Please select one</p>
            <app-application-types formControlName="applicationTypeCode"></app-application-types>
            <span class="text-danger" *ngIf="registerForm.invalid.applicationTypeCode">applicationTypeCode</span>
        </div>

        <!-- Select an assessment type. -->
        <div *ngIf="registerForm.value.Bundle && registerForm.value.Bundle.name !== 'Foundation'">
            <h3 class="h4">Health & Safety Assessment Type</h3>
            <p>Please select one</p>
            <app-assessment-types formControlName="assessmentTypeCode"
            [appcode]="registerForm.value.applicationTypeCode"
            [refcode]="registerForm.value.referenceCode"
            [bundleHasRefCode]="registerForm.value.Bundle.hasRefCode"
            [bundle]="registerForm.value.Bundle.name">
            </app-assessment-types>
            <span class="text-danger" *ngIf="registerForm.invalid.assessmentTypeCode">assessmentTypeCode</span>
        </div>

        <!--Reference code-->
        <div class="form-group row mt-5" *ngIf="registerForm.value.Bundle && registerForm.value.Bundle.name !== 'Foundation'">
          <div class="col-md-4">
              <label for="regReference" class="form-label">Enter Reference Code</label>
              <p style="font-size:12px">Optional: if you have been supplied a reference code by a Client or Trade Association please enter here</p>
          </div>
          <div class="col-md-5">
              <div class="input-group">
                  <input type="text" id="regReference" class="form-control" formControlName="referenceCode" />
                  <div class="input-group-append">
                      <button class="btn btn-secondary" type="button" (click)="validateReferenceCode()" [disabled]="checkingReferenceCode">{{ checkingReferenceCode ? 'Checking...' : 'Validate' }}</button>
                  </div>
                  <span class="text-danger" *ngIf="registerForm.invalid.referenceCode">referenceCode</span>
              </div>
              <div class="mt-3">
                <alert type="success" *ngIf="referenceCodeSuccess">The reference code '{{ validatedReferenceCode.toUpperCase() }}' is valid.</alert>
                <alert type="warning" *ngIf="referenceCodeError">
                    This reference code is not valid. please enter a valid reference code.
                </alert>
              </div>
            </div>
        </div>
        <!--/Reference code-->

        <div class="text-center" *ngIf="registerForm.value.Bundle && registerForm.value.Bundle.name === 'Foundation'">
          <div class="custom-control custom-checkbox">
            <input id="checkContact" class="custom-control-input" type="checkbox" aria-describedby="checkContact" (change)="toggleTerms($event)">
            <label id="checkContactLabel" class="custom-control-label" for="checkContact">
              By placing this order I confirm that I have read and accepted the CHAS
              <a href="https://www.chas.co.uk/contractor-terms-conditions" target="_blank">Terms & Conditions</a> and
              <a href="https://www.chas.co.uk/privacy-policy" target="_blank">Privacy Policy</a>
            </label>
          </div>
          <br />
          <div class="form-group text-center" *ngIf="registerForm.value.Bundle && registerForm.value.Bundle.name === 'Foundation'">
            <button class="btn btn-primary btn-lg" type="button" (click)="processFoundationOrderAsync()" [class.is-loading]="isQuoting" [disabled]="!acceptTerms">Join now</button>
          </div>
        </div>

        <div class="form-group text-center" *ngIf="registerForm.value.Bundle && registerForm.value.Bundle.name !== 'Foundation'">
            <button class="btn btn-primary btn-lg" type="button" (click)="userOrderAsync()" [class.is-loading]="isQuoting" [disabled]="checkingReferenceCode">Get Quote</button>
        </div>

        <alert type="warning" *ngIf="formInvalid">
            Registration form is not valid, please correct any errors in the form above.
        </alert>

        <alert type="warning" *ngIf="quoteError">{{ quoteError }}</alert>

    </form>
</div>
