import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SiteLayoutComponent, AuthLayoutComponent } from '../../../core';
import { AuthLoginResolver } from '../../resolvers/auth-login.resolver';
import { MustNotBeLoggedInGuard } from '../../guards/must-not-be-logged-in.guard';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: '**',
    redirectTo: '/dashboard',
  },
  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'assessment/test',
        loadChildren: () => import('src/app/features/assessments/test/test.module').then((m) => m.QuestionsetTestPageModule),
      },
    ],
  },
  {
    path: '',
    component: SiteLayoutComponent,
    canActivateChild: [MsalGuard],
    resolve: { account: AuthLoginResolver },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('src/app/features/dashboard/dashboard.module').then((m) => m.DashboardPageModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('src/app/features/admin-users/admin-users.module').then((m) => m.AdminUsersModule),
      },
      {
        path: 'assessment/:assessmentID',
        loadChildren: () =>
          import('src/app/features/assessments/questionset/questionset.module').then((m) => m.AssessmentQuestionsetPageModule),
      },
      {
        path: 'assessments',
        loadChildren: () => import('src/app/features/assessments/assessments.module').then((m) => m.AssessmentsPageModule),
      },
      {
        path: 'documents',
        loadChildren: () => import('src/app/features/documents/documents.module').then((m) => m.DocumentsPageModule),
      },
      {
        path: 'membership',
        loadChildren: () => import('src/app/features/membership/membership.module').then((m) => m.MembershipPageModule),
      },
      {
        path: 'company-details',
        loadChildren: () => import('src/app/features/company-details/company-details.module').then((m) => m.CompanyDetailsPageModule),
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    canActivateChild: [MustNotBeLoggedInGuard],
    children: [
      {
        path: 'register',
        loadChildren: () => import('src/app/features/register/register.module').then((m) => m.RegisterPageModule),
      },
      {
        path: 'register-foundation',
        loadChildren: () => import('src/app/features/register/register.module').then((m) => m.RegisterPageModule),
      },
      {
        path: 'register-existing',
        loadChildren: () => import('src/app/features/register-existing/register-existing.module').then((m) => m.RegisterExistingPageModule),
      },
      {
        path: 'order-summary',
        loadChildren: () => import('src/app/features/order-summary/order-summary.module').then((m) => m.OrderSummaryPageModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
