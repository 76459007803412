import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardProductCardComponent } from './product-card.component';
import { DashboardPlantTileComponent } from './plant-tile/plant-tile.component';
import { DashboardRamsTileComponent } from './rams-tile/rams-tile.component';
import { DashboardPeopleTileComponent } from './people-tile/people-tile.component';
import { DashboardHumanFocusTileComponent } from './human-focus-tile/human-focus-tile.component';
import { DashboardCASThirdPartyTileComponent } from './cas-third-party-tile/cas-third-party-tile.component';
import { BootstrapComponentsModule } from '../../../core';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardBusinessShieldTileComponent } from './business-shield-tile/business-shield-tile.component';
import { DashboardComplianceTileComponent } from './compliance-tile/compliance-tile.component';
import { RouterModule } from '@angular/router';
import { LoadingSpinnerModule } from '../../../shared/components/loading-spinner/loading-spinner.module';
import { QuoteContainerModule } from '../../../shared/components/quote-container/quote-container.module';
import { DashboardCreditTileComponent } from './credit-tile/credit-tile.component';
import { DashboardFirTileComponent } from './fir-tile/fir-tile.component';
import { DashboardLearningPathwayTileComponent } from './learning-pathway-tile/learning-pathway-tile.component';

@NgModule({
  declarations: [
    DashboardProductCardComponent,
    DashboardPlantTileComponent,
    DashboardPeopleTileComponent,
    DashboardHumanFocusTileComponent,
    DashboardRamsTileComponent,
    DashboardBusinessShieldTileComponent,
    DashboardComplianceTileComponent,
    DashboardCreditTileComponent,
    DashboardCASThirdPartyTileComponent,
    DashboardFirTileComponent,
    DashboardLearningPathwayTileComponent
  ],
  exports: [DashboardProductCardComponent],
  imports: [
    CommonModule,
    BootstrapComponentsModule,
    ReactiveFormsModule,
    RouterModule,
    LoadingSpinnerModule,
    QuoteContainerModule
  ]
})
export class DashboardProductCardModule { }
