import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import {
  LatestBundleResponse,
  ApplicationTypeDetails,
  DocumentExpiryResponse,
  DateHelpers,
  dateCheck,
  UserTypeCode,
  CheckNewAuthenticationResponse,
  UserTypeCodeAus,
} from '../../../../shared';
import applicationTypesJSON from 'src/assets/json/application-types.json';
import { DocumentService, ProductService } from '../../../../core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { map, finalize, catchError } from 'rxjs/operators';
import { firstValueFrom, Observable, of } from 'rxjs';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { Territory } from 'src/app/shared/constants/territory.enum';

@Component({
  selector: 'app-compliance-tile',
  templateUrl: './compliance-tile.component.html',
  styleUrls: ['./compliance-tile.component.scss'],
})
export class DashboardComplianceTileComponent implements OnInit, OnChanges {
  @Output() emitRagClass = new EventEmitter();
  @Input() bundle: LatestBundleResponse;
  @Input() authAccount: CheckNewAuthenticationResponse;
  @Input() membershipExpired: boolean;
  @Input() foundation: boolean;
  applicationTypes: ApplicationTypeDetails[] = applicationTypesJSON;
  accreditationExpired = false;
  isLoggingIn: boolean;
  isLoading: boolean;
  error: string;
  docIcon: string;
  docIconColor: string;
  certificateName: string;
  document: DocumentExpiryResponse;
  displayCertificateLink: boolean;
  public environment: IEnvironment;
  public customerTypes = UserTypeCode;
  private isAus = false;
  public isAccreditationInProgress = false;

  constructor(
    private router: Router,
    private documentService: DocumentService,
    private Products: ProductService
  ) {
    this.error = null;
  }

  async ngOnInit(): Promise<void> {
    const env = new Environment();
    this.environment = await env.getConfig();
    this.isAus = this.environment.territory === Territory.AUSTRALIA;

    if (this.isAus) this.customerTypes = UserTypeCodeAus;

    if (!this.foundation) {
      this.getDocuments();

      const certificateNameResponse: any = await firstValueFrom(
        this.documentService.getCertificateName(this.authAccount.accountId, this.bundle?.bundleId)
      );
      this.certificateName = certificateNameResponse.name;
      this.displayCertificateLink = this.certificateName !== null || this.certificateName !== undefined;
    }

    this.checkAccreditationInProgress();
  }

  ngOnChanges() {
    this.getDocuments();
    if (this.authAccount && this.authAccount.membershipExpiry && this.authAccount.accreditationExpiry) {
      const isExpired = new Date(this.authAccount.accreditationExpiry) < new Date(Date.now());
      this.accreditationExpired = isExpired;
    }
  }

  getDocuments() {
    this.isLoading = true;
    forkJoin([this.documentService.expired(this.authAccount.accountId), this.documentService.expiring(this.authAccount.accountId)])
      .pipe(
        finalize(() => {
          this.getRagClass();
          this.isLoading = false;
        }),
        map(([expired, expiring]) => {
          return expired.concat(expiring);
        })
      )
      .subscribe((docs: DocumentExpiryResponse[]) => {
        docs = docs.sort((a, b): any => {
          return new Date(a.expiryDate).getTime() - new Date(b.expiryDate).getTime();
        });
        if (docs[0]) {
          this.documentStyles(docs[0]);
        }
      });
  }

  goToDocument() {
    if (this.document) {
      let url = `./assessment/${this.document.assessmentId}`;
      this.router.navigate([url]);
    }
  }

  getRagClass() {
    if (this.foundation) {
      this.emitRagClass.emit(['white', false]);
      return;
    }
    const accreditationExpiry = this.authAccount.accreditationExpiry
      ? new DateHelpers(this.authAccount.accreditationExpiry.toString()).expiry()
      : null;

    if (accreditationExpiry && this.document) {
      const documentExpiry = new DateHelpers(this.document.expiryDate).expiry();
      if (
        (documentExpiry === dateCheck.EXPIRING && accreditationExpiry === dateCheck.VALID) ||
        (!accreditationExpiry && documentExpiry === dateCheck.EXPIRING)
      ) {
        this.emitRagClass.emit(['amber', false]);
      } else if (this.isAccreditationInProgress) {
        this.emitRagClass.emit(['amber', false]);
      } else if (accreditationExpiry === dateCheck.EXPIRED || documentExpiry === dateCheck.EXPIRED) {
        this.emitRagClass.emit(['red', false]);
      } else if (accreditationExpiry === dateCheck.VALID && documentExpiry === dateCheck.VALID) {
        this.emitRagClass.emit(['green', false]);
      } else return;
    } else {
      if (!accreditationExpiry || accreditationExpiry === dateCheck.EXPIRING) {
        this.emitRagClass.emit(['amber', false]);
      } else if (this.isAccreditationInProgress) {
        this.emitRagClass.emit(['amber', false]);
      } else if (accreditationExpiry === dateCheck.EXPIRED) {
        this.emitRagClass.emit(['red', false]);
      } else if (accreditationExpiry === dateCheck.VALID) {
        this.emitRagClass.emit(['green', false]);
      } else return;
    }
  }

  public checkAccreditationInProgress() {
    this.isAccreditationInProgress = this.authAccount && this.authAccount.incompleteAssessments !== 0;
  }

  get variantTooltip(): string {
    const text = 'If your application type is incorrect, please contact CHAS to make any changes as this may affect your assessment.';
    return this.bundle ? this.applicationTypes.find((row) => row.code === this.bundle.variant.toString()).description + ' ' + text : null;
  }

  get productSizeTooltip(): string {
    return `Your figure should include the following:
    <ul>
      <li>Number of Employees</li>
      <li>Directly employed office staff including managers, directors, clerical etc.</li>
      <li>Directly employed site staff</li>
      <li>Labour only workers</li>
    </ul>
    How are employees defined?
    <ul>
      <li>The number of employees is the total number of direct employees, PAYE, permanent, temporary, part-time and labour only subcontractors. According to UK labour law a labour-only subcontractor is considered an employee.</li>
      <li>This does not include bona-fide subcontractors who work with their own tools, insurance etc. and are usually paid by invoice.</li>
      <li>We recommend that you consider the total number of effective employees you may have/use at any one time during a 12-month rolling period.</li>
    </ul>`;
  }

  get applicationType(): string {
    return this.bundle ? this.applicationTypes.find((row) => row.code === this.bundle.variant.toString()).name : null;
  }

  get getEmployeeBand(): string {
    return this.Products.getEmployeeBands(this.bundle.numEmployees);
  }

  get bundleReferenceCode() {
    return this.bundle ? this.bundle.referenceCode : null;
  }

  get showBundleReferenceCode(): boolean {
    return this.bundle
      ? this.bundle.referenceCode && this.bundle.referenceCode.length > 0 && this.bundle.referenceCode !== 'Retired'
      : false;
  }

  downloadCertificate() {
    const fileName = `${this.certificateName}.pdf`;
    const certificateUrl = `${this.environment.apiEndpoint.documents}/accounts/${this.authAccount.accountId}/accreditations/${this.bundle.bundleId}/downloadcertificate`;
    this.documentService.download(certificateUrl).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = url;
      anchor.style.visibility = 'hidden';
      anchor.click();
    });
  }

  downloadInvoice() {
    const fileName = `Invoice Receipt - `;
    this.documentService.downloadInvoice(this.authAccount.accountId).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = fileName + new Date().getUTCFullYear() + '.pdf';
      anchor.href = url;
      anchor.style.visibility = 'hidden';
      anchor.click();
    });
  }

  private documentStyles(doc) {
    let exp = new DateHelpers(doc.expiryDate).expiry();
    if (exp === dateCheck.EXPIRED) {
      this.docIcon = 'times-circle';
      this.docIconColor = 'color:red';
    } else if (exp === dateCheck.EXPIRING) {
      this.docIcon = 'plus-circle';
      this.docIconColor = 'color:#fcba03';
    }
    this.document = doc;
  }

  public getCustomerType(type: string) {
    return this.authAccount.membershipTypeId === parseInt(type);
  }
}
