import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import * as fromData from './currentUser.reducer';


export interface State {

  [fromData.dataFeatureKey]: fromData.CurrentUserState;
}

export const reducers: ActionReducerMap<State> = {

  [fromData.dataFeatureKey]: fromData.reducer,
};


export const metaReducers: MetaReducer<State>[] = [];
