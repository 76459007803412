
import { createAction, props } from '@ngrx/store';
import { IRegisterstep } from 'src/app/shared/interfaces/new/registerStep';

export enum RegisterstepActions {
  Add = '[Registerstep] Add',
  AddSuccess = '[Registerstep] Add Successful',
  AddError = '[Registerstep] Add Error',
  Remove = '[Registerstep] Remove',
  RemoveSuccess = '[Registerstep] Remove Successful',
  RemoveError = '[Registerstep] Remove Error',
  Load = '[Registerstep] Load',
  LoadSuccess = '[Registerstep] Load Successful',
  LoadError = '[Registerstep] Load Error'
}

export const registerStepAdd = createAction(RegisterstepActions.Add, props<{ registerStep: IRegisterstep }>());
export const registerStepAddSuccess = createAction(RegisterstepActions.AddSuccess, props<{ payload: IRegisterstep }>());
export const registerStepAddError = createAction(RegisterstepActions.AddError);

export const registerStepRemove = createAction(RegisterstepActions.Remove);
export const registerStepRemoveSuccess = createAction(RegisterstepActions.RemoveSuccess, props<{ payload: IRegisterstep }>());
export const registerStepRemoveError = createAction(RegisterstepActions.RemoveError);

export const registerStepLoad = createAction(RegisterstepActions.Load);
export const registerStepLoadSuccess = createAction(RegisterstepActions.LoadSuccess, props<{ payload: IRegisterstep }>());
export const registerStepLoadError = createAction(RegisterstepActions.LoadError);
