import { Component, Input, OnChanges, OnDestroy, EmbeddedViewRef, ViewChild, TemplateRef, Output, EventEmitter, OnInit, ViewContainerRef } from '@angular/core';
import { valid_postcode, validateAlpha, validateEmail, validateNumber, validatePassword, validatePhone, validatePosition, futureDate, pastDate } from './validation';
import {
  UploadRequest,
  IntroductionText,
  AccountResponse,
  DateHelpers,
  dateCheck,
  DocumentResponse,
  QuestionInfo,
  LatestBundleResponse,
  AddedFiles,
  AssistConfiguration,
  CheckNewAuthenticationResponse,
  CompanyDetails,
  ContactRole
} from '../../index';
import jsonSurveyJSCSS from 'src/assets/json/surveyjs-css.json';
import certificateOptions from 'src/assets/json/certificate-options.json';
import { QuestionsetHelperComponent } from '../helper/questionset-helper.component';
import { AssessmentsService } from '../../../core/services/assessments.service';
import { DocumentService } from '../../../core/services/document.service';
import { SurveyAssistService } from '../../../core/services/survey-assist.service';
import { concatMap, finalize } from 'rxjs/operators';
import { Subscription, forkJoin, of } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { LoadingService } from '../../../core/services/loading-account.service';
import { Observable } from 'rxjs/internal/Observable';
import * as Survey from 'survey-angular';
import flatpickr from "flatpickr";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AccountsService } from '../../../core/services/accounts.service';
import { AssessmentMenuService } from 'src/app/core/services/assessment-menu.service';
import { TradeService } from 'src/app/core/services/trade.service';
import { AssessmentPageInfo } from 'src/app/shared/interfaces/assessment-page-info';

import { HashingService } from '../../../core/services/hashing.service';
import { SurveyPanelHashValues } from "src/app/shared/interfaces/survey-panel-hashvalues";

import { GtmAssessmentDashboardOptions, GtmUserStatus } from 'src/app/core/services/gtm/gtm.enum';
import { GtmService } from '../../../core/services/gtm/gtm.service';
import { QuestionsetSurveyJSDirective } from './questionset-surveyjs.directive';
import { NotificationLoad } from '../../../core/ngrx/actions/notification.actions';
import { Store } from '@ngrx/store';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';
import { Territory } from 'src/app/shared/constants/territory.enum';
import { DocumentValidationService } from 'src/app/core/services/document-validation.service';

@Component({
  selector: 'app-questionset-surveyjs',
  templateUrl: './questionset-surveyjs.component.html',
  styleUrls: ['./questionset-surveyjs.component.scss']
})
export class QuestionsetSurveyJSComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('modalDisabledAssessment', { static: true }) disabledHealtSafetyModal: TemplateRef<any>;
  @ViewChild('answersCheckWarningModal', { static: true }) answersCheckWarningModal: TemplateRef<any>;
  @ViewChild('certificateOptionsModal', { static: true }) certificateOptionsModal: TemplateRef<any>;
  @ViewChild('previouslyUploadedDocumentPicker', { static: true }) previouslyUploadedDocumentPicker: TemplateRef<any>;
  @ViewChild('resubmitWarningModel', { static: true }) resubmitWarningModel: TemplateRef<any>;
  @ViewChild(QuestionsetSurveyJSDirective, {static: true}) questionsetHost!: QuestionsetSurveyJSDirective;

  @Output() assessmentClosed: EventEmitter<any> = new EventEmitter();
  @Output() assessmentSubmitted: EventEmitter<any> = new EventEmitter();

  @Input() questionsetData: any;
  @Input() questionSetAnswers: any;
  @Input() clientId: string;
  @Input() public notificationId: string;
  @Input() assessmentId: string;
  @Input() authUser: CheckNewAuthenticationResponse;
  @Input() companyDetails: CompanyDetails;
  @Input() roles: ContactRole[];
  @Input() bundles: LatestBundleResponse[];
  @Input() assessmentState: string;
  @Input() showCheckAnswersWarning: boolean;
  @Input() isCasAssessment: boolean;
  @Input() overriddenEditable?: boolean = null;
  @Input() progressiveSave?: boolean = true;
  @Input() isCase?: boolean = false;
  @Input() checkWorkCategories: boolean = true;
  @Input() showResubmitWarning: boolean;

  survey: Survey.Model;
  modalRef: BsModalRef;
  surveyQuestions: any;
  introText: IntroductionText;
  surveyHasBeenUpdated = false;
  surveyWasJustSubmitted = false;
  isResubmitting = false;
  hiddenValueDictionary = {};
  lastClickedButtonId = null;
  certificateOptions: any;
  certificateForm: UntypedFormGroup;
  modalBtnLoading: boolean;
  defaultCertificateOption: string = "885570000"; //Receive both a certificate and stickers

  showErrorsInNavbar = false;

  currentAssessmentPageSub: Subscription;
  visibilityChangedQuestion = '';

  verificationSectionAdded = false;

  documents: DocumentResponse[] = [];
  documentsLoaded = false;

  selectedUploadQuestion: QuestionInfo;

  uncompletedQuestionsForResubmit: string[] = [];
  completedQuestionsForResubmit: string[] = [];

  differenceHashList: SurveyPanelHashValues[] = [];
  changeLogArray: any = [];

  surveyAssistInfo: AssistConfiguration;
  displaySurveyAssist = false;

  get accountId(): string {
    if (this.authUser) {
      return this.authUser.accountId;
    }
    return null;
  }
  private attachedView = false;
  uploadedFiles: any[] = [];

  $latestBundleData: Observable<LatestBundleResponse[]>;
  private config: IEnvironment;
  private isAus = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    public router: Router,
    private modalService: BsModalService,
    private assessmentsService: AssessmentsService,
    private documentService: DocumentService,
    private documentValidationService: DocumentValidationService,
    private loading: LoadingService,
    private accountService: AccountsService,
    private assessmentMenuService: AssessmentMenuService,
    private hashingService: HashingService,
    private surveyAssistService: SurveyAssistService,
    private tradeService: TradeService,
    private gtmService: GtmService,
    private store: Store
  ) {
    const env = new Environment();
    this.config = env.getConfigSync();
    this.isAus = this.config.territory === Territory.AUSTRALIA;
  }

  async ngOnInit() {
    const env = new Environment();
    this.config = await env.getConfig();
    this.isAus = this.config.territory === Territory.AUSTRALIA;

    this.certificateOptions = certificateOptions;
    this.certificateForm = new UntypedFormGroup({
      certificateOption: new UntypedFormControl(this.certificateOptions[0].code)
    });

    this.currentAssessmentPageSub = this.assessmentMenuService
      .getCurrentAssessmentPage()
      .subscribe(page => this.selectCurrentPage(page));

    if (this.survey !== null) {
      const allPanels = this.survey.getAllPanels(true) as Array<Survey.Panel>;

      allPanels.forEach(panel => {
        if (panel.title.toLocaleLowerCase() !== "requested information") {
          const pageInfo = this.generatePageInfo((panel.parent) as any);
          const panelRequiresResponse = pageInfo.responseRequired;
          const hashExists = this.differenceHashList.filter(x => x.accountId === this.accountId &&
            x.assessmentId === this.assessmentId &&
            x.name === panel.title);
          if (panelRequiresResponse && hashExists.length === 0) {
            this.changeLogArray.push({
              key: panel.page.name,
              value: this.getHashForPanelElementsValues(panel.elements)
            });
          }
        }
      });
    }
  }

  ngOnChanges() {
    if(this.isAus) this.surveyAssistService.setLatestBundle(of(this.bundles));

    if (this.questionsetData) {
      this.surveyQuestions = this.questionsetData.questions;
      this.isResubmitting = this.assessmentState !== 'In Progress';
      this.showResubmitWarning = this.isResubmitting;
      this.additionalInfoPanelVisible(true);
      this.displaySurveyAssistInfo();

      this.displaySurvey();
    }
  }

  ngOnDestroy(): void {
    if (this.currentAssessmentPageSub) {
      this.currentAssessmentPageSub.unsubscribe();
    }
  }

  populateLocalHashList(allPanels, accountId, assessmentId, changelog) {
    if (changelog.length > 0) {
      allPanels.forEach(panel => {
        const panelInitialHashValue = changelog.filter(x => x.key === panel.page.name);
        if (panelInitialHashValue !== null && panelInitialHashValue !== undefined && panelInitialHashValue.length > 0) {
          this.differenceHashList.push({
            name: panel.title,
            initialHash: panelInitialHashValue[0].value,
            finalHash: '',
            accountId: accountId,
            assessmentId: assessmentId
          });
        }
      });
    }
  }

  submitCertificateChoice(code) {
    this.modalBtnLoading = true;
    this.accountService
      .saveCertificateOptionsPreference(this.authUser.accountId, code)
      .pipe(finalize(() => {
        setTimeout(() => {
          this.modalBtnLoading = false;
          if(this.modalRef) this.modalRef.hide();
        }, 2000);
      }))
      .subscribe();
  }

  changeCertificateOption(e) {
    this.certificateForm.get('certificateOption').setValue(e.target.id);
    e.target.checked = true;
  }

  openCertificateModal() {
    if (this.questionsetData.name.indexOf('Health & Safety') > -1) {
      this.modalRef = this.modalService.show(this.certificateOptionsModal, {
        backdrop: 'static',
        keyboard: false
      });
    }
  }

  displayFilePicker(): boolean {
    return (this.assessmentState === 'In Progress' ||
      this.assessmentState === 'Additional Information Required');
  }

  openFilePickerModal(options: any) {
    const questionInfo = this.documentService.getQuestionInfo(options);
    this.uploadedFiles = questionInfo.uploadedFiles.map(f => f.content);
    this.selectedUploadQuestion = questionInfo;

    this.modalRef = this.modalService.show(this.previouslyUploadedDocumentPicker, {
      class: 'modal-lg',
      backdrop: 'static',
      keyboard: false
    });
  }

  closeModal() {
    this.modalRef.hide();
  }

  get readonlyMode(): boolean {
    return (this.survey !== null && this.survey !== undefined) && this.survey.mode === 'display';
  }

  registerAssessmentPages(survey: Survey.SurveyModel): void {
    const listOfPages: AssessmentPageInfo[] = survey.visiblePages
      .map(page => this.generatePageInfo(page))
      .filter(page => page != null);
    this.assessmentMenuService.setSelectedAssessmentPages(listOfPages);
  }

  generatePageInfo(page: Survey.PageModel): AssessmentPageInfo {

    let pageInfo: AssessmentPageInfo = null;
    let panel: Survey.Panel = null;
    if (page && page.elements && page.elements.length > 0) {
      panel = page.elements[0] as Survey.Panel;
    }

    if (!panel) {
      return pageInfo;
    }

    if (this.assessmentState === 'Additional Information Required') {
      let responsePresent = false;

      let isVerificationAssessment = this.questionsetData.name.indexOf('Verification') !== -1 ||
        this.questionsetData.name.toLowerCase().indexOf('deemed to satisfy') !== -1;

      if (isVerificationAssessment && panel.name === 'RequestedInfoPanel') {
        responsePresent = page.hasErrors(false);
      } else {
        const lastQuestion = panel.questions[panel.questions.length - 1];
        if (lastQuestion.title === 'Response') {
          if (!lastQuestion.value) {
            responsePresent = true;
          }
        }
      }
      pageInfo = {
        id: page.id,
        title: panel.title,
        complete: !page.hasErrors(false) && !responsePresent,
        hasErrors: this.showErrorsInNavbar && page.hasErrors(false),
        responseRequired: responsePresent,
        requestForMoreInfo: isVerificationAssessment
      };
    } else {
      pageInfo = {
        id: page.id,
        title: panel.title,
        complete: !page.hasErrors(false),
        hasErrors: this.showErrorsInNavbar && page.hasErrors(false),
        responseRequired: false,
        requestForMoreInfo: false
      };
    }
    return pageInfo;
  }

  displaySurveyAssistInfo(): void {
    this.introText =
      this.questionsetData.introductoryContent ? this.questionsetData.introductoryContent : null;

    if (!this.isCase) {
      forkJoin([
        this.surveyAssistService.getAssistConfiguration(),
        this.surveyAssistService.getLatestBundle()
      ])
        .subscribe(([config, bundles]) => {
          const bundle = bundles[0];
          const surveyAssistInfo = this.surveyAssistService
            .getSurveyAssist(bundle, this.questionsetData.name, config);

          if (surveyAssistInfo) {
            this.displaySurveyAssist = true;
            this.surveyAssistInfo = surveyAssistInfo;
          }
        });
    }
  }

  onAfterRenderSurvey(survey: Survey.SurveyModel, options: any): void {
    this.registerAssessmentPages(survey);

  }

  onVisibleChanged(survey: Survey.SurveyModel, options: any): void {
    this.registerAssessmentPages(survey);
  }

  onCurrentPageChanged(survey: Survey.SurveyModel, options: any): void {
    const pageInfo = this.generatePageInfo(survey.currentPage);
    if (pageInfo) {
      this.assessmentMenuService.setCurrentAssessmentPage(pageInfo);
    }

    this.registerAssessmentPages(survey);
  }

  selectCurrentPage(pageInfo: AssessmentPageInfo): void {
    if (pageInfo === null) { return; }

    const page = this.survey.visiblePages.find(p => p.id === pageInfo.id);
    if (page === null) { return; }

    if (this.survey.currentPage === page) { return; }
    this.survey.currentPage = page;
    this.saveProgressively().subscribe({
      error: error => console.log(error)
    });
  }

  additionalInfoPanelVisible(isVisible: boolean) {
    if (
      this.assessmentState === 'Additional Information Required' &&
      (this.questionsetData.name.indexOf('Verification') !== -1
        || this.questionsetData.name.toLowerCase().indexOf('deemed to satisfy') !== -1)) {
      const lastElement = this.surveyQuestions.pages[0].elements.length - 1;
      const panel = this.surveyQuestions.pages[0].elements[lastElement];
      panel.visible = isVisible;
      this.verificationSectionAdded = true;
    }
  }

  getSectionsCount(): number {
    const panels = this.survey.getAllPanels(true).filter(p => p.isVisible === true);
    return panels.length;
  }


  /**
   * Create and render a SurveyJS survey from the questionset data.
   */
  displaySurvey() {
    if (this.surveyWasJustSubmitted) return;
    Survey.StylesManager.applyTheme('bootstrap');

    // Add support for a "helpTip" question property, to add help text.
    Survey.Serializer.addProperty('question', 'helpTip:text');
    Survey.Serializer.addProperty('question', 'documentType:text');
    Survey.Serializer.addProperty('question', 'relatedQuestion:text');
    Survey.Serializer.addProperty('question', 'isAssessor:boolean');

    // Set up validation methods.
    const validationMethods = [
      { name: 'valid_postcode', method: valid_postcode },
      { name: 'validateAlpha', method: validateAlpha },
      { name: 'validateEmail', method: validateEmail },
      { name: 'validateNumber', method: validateNumber },
      { name: 'validatePassword', method: validatePassword },
      { name: 'validatePhone', method: validatePhone },
      { name: 'validatePosition', method: validatePosition },
      { name: 'futureDate', method: futureDate },
      { name: 'pastDate', method: pastDate }
    ];
    validationMethods.forEach(row => {
      Survey.FunctionFactory.Instance.register(row.name, row.method);
    });

    this.survey = new Survey.Model(this.surveyQuestions);

    this.updateSurveyCompletedMessage();
    this.UseHiddenAnswerDictionary();
    this.updateLocaleText();

      this.survey.showQuestionNumbers = 'off';
      this.survey.questionsOnPageMode = 'questionPerPage';
      this.survey.showNavigationButtons = true;
      this.survey.showTitle = false;
      this.survey.showPageTitles = false;

      this.survey.onAfterRenderSurvey.add((s, o) => this.onAfterRenderSurvey(s, o));
      this.survey.onVisibleChanged.add((s, o) => {
        if (this.visibilityChangedQuestion !== o.name) {
          this.onVisibleChanged(s, o);
          this.visibilityChangedQuestion = o.name;
          this.setCompleteButtonVisibility();
        }
      });

      this.survey.onCurrentPageChanged.add((s, o) => {
        this.onCurrentPageChanged(s, o);
        this.setCompleteButtonVisibility();
        this.saveProgressively().subscribe({
          error: error => console.log(error)
        });
      });

      // Ensure supplier answers question before submitting
      this.survey.onValidateQuestion.add((s, o) => {
        if (this.isAssessorQuestion(o.name) && o.question.title === 'Response' && o.question.getType() === 'comment') {
          o.error = !!o.value ? undefined : 'You must give a response'
        }
     });

    this.survey.onCompleting.add(async(survey, options) => {
      await this.onCompleteBtnPressed(survey, options)
    });
    this.survey.onComplete.add((result, options) => this.completedSurvey(result, options));
    this.survey.onMatrixAfterCellRender.add((survey, options) => this.afterMatrixCellRender(survey, options));
    this.survey.onAfterRenderQuestion.add((survey: Survey.SurveyModel, options) => {


      //this is to update section status (ticked or not)
      // Modify the question's HTML output based on the question type.


      //if question is date disable manual entry and reduce width of input to incourage using the datepicker via the icon
      if (options.question.inputType === "date") {
        const dateInput = options.htmlElement.querySelector('.form-control') as HTMLInputElement;
        dateInput.style.width = "200px";
        this.convertDateFields(options);
      }
      switch (options.question.getType()) {
        case 'checkbox':
        case 'radiogroup':
          // Modify radiogroup questions to position the INPUT element before the LABEL element.
          const labelElements = options.htmlElement.querySelectorAll('label');
          labelElements.forEach((label: HTMLLabelElement) => {
            // Find the respective INPUT field.
            const input = label.querySelector('input');
            // Move the INPUT field to before the LABEL.
            label.parentElement.prepend(input);
            // Set the LABEL's for attribute to the ID of the INPUT field.
            label.setAttribute('for', input.id);
          });
          break;
        case 'matrixdynamic':
          if (options.question.helpTip != null) {
            options.question.title = options.question.helpTip;
            options.question.helpTip = null;
          }
          break;
        case 'file':
          const inputs = options.htmlElement.querySelectorAll('input[type="file"]');
          this.convertUploadsToCustomButtons(options, inputs);
          break;
      }

      const titleElements = options.htmlElement.querySelectorAll('h5');
      titleElements.forEach((title: HTMLTitleElement) => {
        const titleSpan = title.querySelector('span');
        if (titleSpan == null || options.question.title == options.question.name) {
          return;
        }
        titleSpan.innerHTML = options.question.title;
      });

      // If the question has help text, add a widget for displaying it.
      if (options.question.helpTip) {
        this.createHelpText(options);
      }

      if (this.isQuestionRoutingButton(options.question.title)) {
        this.createVirtualRoutingButton(options, options.question);
      }

      this.formatCommentResponseQuestions(options);
    });

    this.survey.onValueChanged.add((currentSurvey, option) => {
      this.surveyHasBeenUpdated = true;
      if (!option.question) {
        return;
      }
      option.question.hasErrors(true);
      this.registerAssessmentPages(currentSurvey);
      this.setCompleteButtonVisibility();
    });


    this.survey.onUploadFiles.add(async (survey, options) => {

      const question = options.question;
      const answer = options.name;
      const filesData = options.files;

      const result = await this.documentValidationService.validateFiles(filesData);
      if (result && !result.isValid()) {
        options.question.addError(
          new Survey.CustomError(
            this.documentValidationService.getValidationErrorMessage()
          ));
        options.callback('error');
        return;
      }

      let btnUploadElement = null;
      if (this.lastClickedButtonId) {
        btnUploadElement = document.getElementById(this.lastClickedButtonId);
        if (btnUploadElement) {
          btnUploadElement.classList.add('is-loading');
        }
      }

      const uploadInfo = {
        ContractorId: this.clientId,
        AssessmentId: this.assessmentId,
        Module: this.questionsetData.name,
        Answer: answer,
        DocumentType: question.documentType,
        Files: filesData
      } as UploadRequest;

      let files: any[];

      const addedDocuments = filesData.map(f => <DocumentResponse>{
        name: f.name,
        type: question.documentType,
        uri: f.uri,
        contractorId: this.clientId,
        module: this.questionsetData.name,
        assessmentIds: [this.assessmentId],
        answerIds: [answer],
        uploaded: new Date(),
        extension: this.documentService.getExtension(f.name)
      });

      this.documentService.upload(uploadInfo)
        .pipe(
          concatMap(data => {
            files = options.files.map(f => {
              const uploadFile = data.find(i => i.fileName === f.name);
              const addedDoc = addedDocuments.find(d => d.name == f.name);
              if(addedDoc) {
                addedDoc.uri = uploadFile.uri
              }

              return {
                file: f,
                content: uploadFile.uri
              };
            });
            options.callback('success', files);
            this.mergeAnswersBackToModel();
            return this.saveProgressively();
          }))
        .subscribe({
          next: _ => {
            //console.log('survey answers have been updated');
            //console.log('current progress ' + survey.getProgress());
            if (btnUploadElement) {
              btnUploadElement.classList.remove('is-loading');
            }
            this.lastClickedButtonId = null;
            this.documents = this.documents.concat(addedDocuments);

            this.forceRender();
          },
          error: error => {
            console.log(error);
            if (btnUploadElement) {
              btnUploadElement.classList.remove('is-loading');
            }
            this.lastClickedButtonId = null;

            if (error.status === 400) {
              options.question.addError(new Survey.CustomError(
                this.documentValidationService.getValidationErrorMessage()
              ));
            } else {
              options.question.addError(new Survey.CustomError('Upload failure. Please try again.'));
            }
            options.callback('error');
          }
        });
    });

    this.survey.onDownloadFile.add((survey, options) => {
      try {
        const contentUrl = options.content.indexOf('downloadfile') > -1
          ? this.documentService.rewriteUrl(this.clientId, options.content)
          : options.content;
        this.documentService.download(contentUrl)
          .subscribe({
            next: data => {
              let contentType = 'application/octet-stream';
              if (options.fileValue?.type) {
                contentType = options.fileValue.type;
              }
              const blob = new Blob([data], { type: contentType });
              const url = window.URL.createObjectURL(blob);
              options.callback('success', url);
            },
            error: error => {
              // Failed download, display non-hyperlinked filename.
              options.callback('success');
            }
          });
      }
      catch(error) {
        // Failed download, display non-hyperlinked filename.
        options.callback('success');
      }
    });

    this.survey.onClearFiles.add((survey, options) => {

      let fileNames: string[];

      if (options.fileName) {
        const file = options.value.find(v => v.name === options.fileName);
        fileNames = [this.getFileName(file.content)];
      } else {
        fileNames = options.value.map(v => this.getFileName(v.content));
      }

      this.mergeAnswersBackToModel();
      this.saveProgressively()
        .subscribe({
          next: () => {
            options.callback('success');
            this.removeDocuments(fileNames);
            this.forceRender();
          },
          error: error => {
            const errorMessage = new Survey.CustomError('Error deleting file(s). Please try again.');
            options.question.addError(errorMessage);
            options.callback('error');
          }
        });
    });

    this.survey.data = this.questionSetAnswers?.answers?.data ?? {};

    this.survey.showProgressBar = 'bottom';
    this.survey.showQuestionNumbers = 'off';
    this.setDisplayMode();

    Survey.SurveyNG.render('surveyElement', {
      model: this.survey,
      css: jsonSurveyJSCSS
    });

    this.setCompleteButtonVisibility();
  }

  private removeDocuments(fileNames: string[]) {
    for (let i = 0; i < fileNames.length; i++) {
      const fileName = fileNames[i];
      const docIndex = this.documents.findIndex(d => d.uri.indexOf(fileName) > -1);
      if (docIndex > -1) {
        this.documents.splice(docIndex, 1);
      }
    }
  }

  private forceRender(): void {
    this.survey.focusFirstQuestionAutomatic = false;
    this.survey.onScrollingElementToTop.add((sender, options) => {
      options.cancel = true;
    });

    const page = this.survey.currentPage;
    page.visible = false;
    this.survey.currentPage = null;
    page.visible = true;
    this.survey.currentPage = page;

    setTimeout(() => {
      this.survey.focusFirstQuestionAutomatic = true;
      this.survey.onScrollingElementToTop.clear();
    }, 350);

  }

  private isAssessorQuestion(name: string): boolean {
    const splitName = name.split('.');

    return (splitName.length >= 3 && parseInt(splitName[2]) >= 3000)
  }

  private formatCommentResponseQuestions(options: any): void {
    if (this.isAssessorQuestion(options.question.name)) {

      const isResponse = options.question.title == 'Response';
      const isHistory = options.question.getType() == 'html';

      if (isResponse && !isHistory) {
        options.htmlElement.className = 'new-assessor-response';
      }

      if (this.assessmentState !== 'Additional Information Required') {
        options.htmlElement.className = 'd-none';
      }
    }
  }

  convertDateFields(options: any) {
    let question: any = options.question;
    let inputs = options.htmlElement.querySelectorAll('input[type="date"]');

    for (let i = 0; i < inputs.length; i++) {
      let input = inputs[i];

      let minDate = question.min;
      let maxDate = question.max;

      input.setAttribute('type', 'text');
      input.setAttribute('placeholder', 'Select Date...');
      input.classList.add('survey-date-field');

      flatpickr(input, {
        allowInput: false,
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d",
        altInput: true,
        minDate: minDate,
        maxDate: maxDate,
        allowInvalidPreload: true,
        disableMobile: true
      });
    }
  }

  createVirtualRoutingButton(options: any, question: Survey.Question): void {
    options.htmlElement.className = 'd-none';
    const defaultValue = options.question.title;
    question.defaultValue = defaultValue;

    if (question.value !== null && question.value !== defaultValue) {
      question.value = defaultValue;
    }
  }

  afterMatrixCellRender(survey: Survey.SurveyModel, options: any): any {
    //create helptips after matrix column header
    const spanElements = options.htmlElement.offsetParent.querySelectorAll('span');
    spanElements.forEach((span: HTMLSpanElement) => {
      if (options.column.helpTip && span.innerText === options.column.title) {
        const domElem = this.createDomElement(options);
        const spanNode = span.nextElementSibling ? span.nextElementSibling : span;
        if (spanNode) {

          if (spanNode.childNodes) {
            spanNode.childNodes.forEach(node => {
              if (node.nodeName === "APP-QUESTIONSET-HELPER") node.remove();
            });
          }

          spanNode.appendChild(domElem);
        }
      }
    });

    const fileInputs = options.htmlElement.querySelectorAll('input[type="file"]');
    this.convertUploadsToCustomButtons(options, fileInputs);
    this.convertDateFields(options);
  }

  convertUploadsToCustomButtons(options: any, inputs: HTMLInputElement[]) {
    inputs.forEach((fileInput: HTMLInputElement) => {
      const questionId = options.cellQuestion ? options.cellQuestion.name : options.question.name;
      fileInput.classList.add('d-none');
      const button = document.createElement('button');
      const buttonId = 'btn-upload-' + questionId + '-' + fileInput.id;
      button.id = buttonId;
      button.className = 'btn btn-secondary d-inline-flex';
      button.onclick = () => {
        this.lastClickedButtonId = buttonId;
        fileInput.click();
      };
      button.innerHTML = 'Choose Files';

      const div = document.createElement('div');
      div.id = 'container-' + questionId;
      div.classList.add('no-break');
      div.append(button);

      if (this.displayFilePicker()) {

        if (this.documentsLoaded) {
          this.addExistingFilePickerBtn(options, div, fileInput);
        }
        else {
          this.documentService.getFor(this.accountId, 30)
            .subscribe(documents => {
              this.documents = documents;
              this.documentsLoaded = true;
              this.addExistingFilePickerBtn(options, div, fileInput);
            });
        }
      }
      fileInput.parentElement.prepend(div);
    });
  }

  addExistingFilePickerBtn(options, div, fileInput) {

    const questionInfo = this.documentService.getQuestionInfo(options);
    const files = questionInfo.uploadedFiles.map(f => f.content);
    const filteredDocuments = this.documents.filter(d => files.indexOf(d.uri) === -1);

    if (filteredDocuments && filteredDocuments.length > 0) {

      const existingDocsButton = document.createElement('button');

      const existingDocsButtonId = 'btn-existing-upload-' + questionInfo.questionId + '-' + fileInput.id;
      existingDocsButton.id = existingDocsButtonId;
      existingDocsButton.className = 'btn btn-secondary d-inline-flex';
      existingDocsButton.innerHTML = questionInfo.isMatrixQuestion ? 'Pick uploaded' : 'Pick previously uploaded document';
      existingDocsButton.onclick = () => {
        this.openFilePickerModal(options);
      };

      const span = document.createElement('span');
      span.innerText = 'or';
      span.className = 'mx-3';


      div.append(span);
      div.append(existingDocsButton);
    }
  }

  setDisplayMode(): void {

    // Set display to read only if user permission is set to 'View Only' or state is submitted/completed
    if (
      (this.roles && this.roles.find(b => b.name === 'Assessments')?.privilege === 'View Only') ||
      (this.assessmentState !== 'In Progress' && this.assessmentState !== 'Additional Information Required')
    ) {
      this.survey.mode = 'display';
    }

    // Set display to read only and show modal if user has not submitted work catgories
    if (this.companyDetails && this.companyDetails.workCategories.length < 1 && this.questionsetData.name.indexOf('Health & Safety') > -1) {
      this.survey.mode = 'display';
      this.modalRef = this.modalService.show(this.disabledHealtSafetyModal, {
        animated: false,
        backdrop: 'static'
      });
    }

    // Override by force if required
    if (this.overriddenEditable !== null) {
      this.survey.mode = this.overriddenEditable ? 'edit' : 'display';
    }

  }

  updateSurveyCompletedMessage() {
    this.survey.completeText = this.isResubmitting ? 'Resubmit' : 'Complete';
    const isCas = this.isCasAssessment;
    const isSelfCertify = this.questionsetData.name != null && this.questionsetData.name.includes('Self-Certify');
    const isVerification = this.questionsetData.name != null && this.questionsetData.name.includes('Verification');

    let heading = '';
    let paragraph = '';

    if (this.isCase) {
      this.survey.completeText = 'Submit';
      heading = 'Thank you for submitting your updated details.';
      paragraph = 'This will now be forwarded to the Membership Team to review.';
    }
    else if (this.isResubmitting) {
      heading = `Thank you for resubmitting the ${this.questionsetData.name}.`;
      paragraph = 'This will now be returned back to the Assessor for review.';
    } else {
      heading = `Thank you for completing the ${this.questionsetData.name}.`;

      if (isCas)
        paragraph = 'Once all assessments have been submitted, these will be forwarded through to the next available assessor to review.';
      else if (isVerification)
        paragraph = 'This will be sent to the Membership Team to be processed.';
      else if (isSelfCertify)
        paragraph = '';
      else
        paragraph = 'This will be sent to the next available Assessor to be processed.';
    }

    this.survey.completedHtml = `<h5>${heading}</h5><p>${paragraph}</p>`;
  }

  UseHiddenAnswerDictionary() {
    this.survey.onVisibleChanged.add((survey, options) => {
      const question = options.question;
      const isVisible = options.visible;

      if (this.isQuestionRoutingButton(question.title)) {
        this.setCompleteButtonVisibility();
        return;
      }

      if (!isVisible) {
        this.hiddenValueDictionary[question.name] = JSON.stringify(question.value ?? null);
      } else {
        let restoreValue = this.hiddenValueDictionary[question.name];
        if (restoreValue != null) question.value = JSON.parse(restoreValue);
      }
    });
  }

  setCompleteButtonVisibility() {
    const answersReadyToSubmit = Object.values(this.survey.data).indexOf('Submit') > -1;
    const completeButtonSearch = document.getElementsByClassName('sv_complete_btn');
    if (completeButtonSearch.length == 0) return;
    const completeButton = completeButtonSearch[0];

    if (answersReadyToSubmit || this.isCase) {
      completeButton.classList.remove('d-none');
    } else {
      completeButton.classList.add('d-none');
    }

  }

  async onCompleteBtnPressed(result: Survey.SurveyModel, options: any): Promise<void> {
    if (!this.isCase) {
      if (result.hasErrors(true, true)) {
        options.allowComplete = false;
        return;
      }
    }

      if (this.isResubmitting && this.showResubmitWarning) {
        //generate hash for all the panels at the end
        var allPanels = this.survey.getAllPanels(true) as Array<Survey.Panel>;
        allPanels.forEach(panel => {
          this.differenceHashList.forEach(lm => {
            if (lm.name === panel.title && lm.accountId === this.accountId && lm.assessmentId === this.assessmentId) {
              lm.finalHash = this.getHashForPanelElementsValues(panel.elements);
            }
          });
        });

        //generate the list of changed and unchanged sections based on initial and final hash
        this.uncompletedQuestionsForResubmit = [];
        this.completedQuestionsForResubmit = [];
        this.differenceHashList.forEach(el => {
          if (el.name !== "Requested Information") {
            if (el.initialHash === el.finalHash) {
              this.uncompletedQuestionsForResubmit.push(el.name);
            } else {
              this.completedQuestionsForResubmit.push(el.name);
            }
          }
        });

        if (this.completedQuestionsForResubmit.length > 0 || this.uncompletedQuestionsForResubmit.length > 0) {
          this.modalRef = this.modalService.show(this.resubmitWarningModel);
          options.allowComplete = false;
          return;
        }
      }

    if (!this.showCheckAnswersWarning) return;
    this.modalRef = this.modalService.show(this.answersCheckWarningModal);

    options.allowComplete = false;
  }

  confirmAnswersCheck() {
    this.modalRef.hide();
    this.showCheckAnswersWarning = false;
    this.survey.doComplete();
  }

  cancelResubmit() {
    this.modalRef.hide();
  }

  confirmResubmit() {
    this.modalRef.hide();
    this.showResubmitWarning = false;
    this.survey.doComplete();
  }

  get hasSurveyChanged(): boolean {
    return this.surveyHasBeenUpdated;
  }

  private getFileName(content: string) {
    return content.substring(content.lastIndexOf('/') + 1);
  }

  /**
   * Add a helper component to a question, to represent help text.
   */
  private createHelpText(options: any) {

    const domElem = this.createDomElement(options);
    const header = options.htmlElement.querySelector('h5');
    const label = options.htmlElement.querySelector('label');

    if (header) {
      // The question has a header, so append a helper component to it.
      header.appendChild(domElem);
    } else if (label) {
      // The question has a label, so append a helper component to it.
      label.innerHTML = label.innerHTML + " ";
      label.appendChild(domElem);
    } else {
      // Create a container for the help text instead.

      const div = document.createElement('div');
      div.classList.add('small', 'mb-4');
      div.innerHTML = `<i class="fa fa-lg fa-info-circle" aria-hidden="true"></i>${options.column ? options.column.helpTip : options.question.helpTip}`;
      options.htmlElement.appendChild(div);
    }
  }

  createDomElement(options: any): HTMLElement {
    const viewContainerRef = this.questionsetHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(QuestionsetHelperComponent);
    componentRef.instance.helpText = options.column ? options.column.helpTip : options.question.helpTip;
    componentRef.changeDetectorRef.detectChanges();

    // Get DOM element from component
    return (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
  }

  /**
   *
   */
  updateLocaleText() {
    // see https://github.com/surveyjs/survey-library/blob/master/src/localization/english.ts
    const myloc = Survey.surveyLocalization.locales['en'];
    myloc.cleanCaption = 'Remove All'; // Change the "clean" button text.
    myloc.removeFileCaption = 'x';
  }

  saveSurvey() {
    this.saveSurveyObservable().subscribe({
      error: error => console.log(error)
    });
  }

  saveSurveyObservable() {
    if (this.survey.mode == 'display') return of({});
    this.mergeAnswersBackToModel();
    return this.assessmentsService
      .saveQuestionSetAnswers(this.clientId, this.questionSetAnswers);
  }

  getMinimalAnswerData(): any {
    const answerKeys: string[] = Object.keys(this.survey.data).filter(x => this.survey.getQuestionByName(x) != null);
    const answers: any = {};
    answerKeys.forEach(key => answers[key] = this.survey.data[key]);
    return answers;
  }

  saveProgressively(): Observable<Object> {
    if (!this.progressiveSave) return of({});
    this.mergeAnswersBackToModel();
    return this.assessmentsService.saveQuestionSetAnswers(this.clientId, this.questionSetAnswers);
  }

  private getNotifications() {
    this.store.dispatch(
      NotificationLoad({
        accountId: this.authUser.accountId,
        contactId: this.companyDetails.primaryContact.id
      })
    )
  }

  /**
   *
   */
  async completedSurvey(result, options) {
    let categoriesCorrect = true;
    this.surveyWasJustSubmitted = true;

    if (this.isCase) {
      this.surveyWasJustSubmitted = false;

      options.showDataSaving('Saving, please wait...');

      let categoriesCorrect = true;

      if(this.checkWorkCategories) {
        categoriesCorrect = await this.tradeService.areCategoriesCorrect(
          this.authUser.accountId,
          this.companyDetails.primaryContact.id
        );
      }

      if (categoriesCorrect) {
        this.questionSetAnswers.answers.data = this.getMinimalAnswerData();
        this.assessmentsService.createCase(this.clientId, this.notificationId, this.questionSetAnswers)
          .pipe(
            finalize(() => {
              this.loading.changeAssessmentLoading(true);
              options.showDataSavingSuccess('Successfully Updated');
              this.assessmentSubmitted.emit(null);
              this.surveyWasJustSubmitted = true;
              this.getNotifications();
              this.gtmService.completeAssessment({
                dashboard: GtmAssessmentDashboardOptions.dashboard,
                status: GtmUserStatus.LoggedIn,
                name: `${this.assessmentId}-assessment`
              });
            })
          )
          .subscribe({
            error: error => console.log(error)
          });
      }
    }
    else {
      //certificate options logic: expired by < 6 months show modal else set default value.
      if (!this.isResubmitting) {
        if (this.authUser && this.authUser.accreditationExpiry
          && !new DateHelpers(this.authUser.accreditationExpiry).foundation()
          && new DateHelpers(this.authUser.accreditationExpiry).expiry() === dateCheck.EXPIRED
        ) {
          this.openCertificateModal();
        } else {
          this.submitCertificateChoice(this.defaultCertificateOption);
        }
      }

      this.mergeAnswersBackToModel();
      if (!this.isResubmitting) {
        this.questionSetAnswers.sectionsCount = this.getSectionsCount();
      } else {
        this.questionSetAnswers.sectionCount = null;
      }
      this.assessmentsService.saveCompletedQuestionSetAnswers(this.clientId, this.questionSetAnswers, this.verificationSectionAdded)
        .pipe(
          finalize(() => {
            this.loading.changeAssessmentLoading(true);
            this.assessmentSubmitted.emit(null);
            this.additionalInfoPanelVisible(false);
            this.getNotifications();
          })
        )
        .subscribe({
          error: error => console.log(error)
        });
    }
  }

  /**
   * Navigate to company details and close the current dialogue.
   */
  async goToCompanyDetails(): Promise<void> {
      this.assessmentMenuService.setSelectedAssessmentPages([]);
      this.assessmentMenuService.setCurrentAssessmentPage(null);
      this.assessmentMenuService.setCurrentAssessmentId(null);
    this.router.navigateByUrl('/company-details')
      .then(() => this.modalRef.hide());
  }

  async goToDashboard(): Promise<void> {
      this.assessmentMenuService.setSelectedAssessmentPages([]);
      this.assessmentMenuService.setCurrentAssessmentPage(null);
      this.assessmentMenuService.setCurrentAssessmentId(null);
    this.router.navigateByUrl('/dashboard');
    this.assessmentClosed.emit(null);
  }

  isQuestionRoutingButton(questionName: string): boolean {
    return questionName === "Next"
      || questionName === "Finish"
      || questionName === "Submit";
  }

  mergeAnswersBackToModel() {
    this.questionSetAnswers.answers.data = this.survey.data;

    let allQuestions = this.survey.getAllQuestions();
    let visibleEmptyFileQuestionNames = allQuestions
      .filter(q => q.isVisible && q.isEmpty() && q.getType() === 'file')
      .map(q => q.name);

    visibleEmptyFileQuestionNames.forEach(questionName => {
      this.questionSetAnswers.answers.data[questionName] = [];
    });
  }

  filesAdded(event: AddedFiles) {
    const questionId = event.questionInfo.parentQuestionId
      ? event.questionInfo.parentQuestionId
      : event.questionInfo.questionId;
    const question = this.survey
      .getAllQuestions()
      .find(q => q.isVisible && q.name === questionId);

    const files = event.uploadedFiles.map(f => <any>{
      name: f.name,
      type: f.type,
      content: f.uri
    });

    let questionValue;
    if (question.getType() === 'matrixdynamic') {
      const value = question.value ? question.value : [];
      if (value.length > 0) {
        const filesValue = value[event.questionInfo.rowIndex][event.questionInfo.questionId];
        questionValue = filesValue ? filesValue : [];
        value[event.questionInfo.rowIndex][event.questionInfo.questionId] = questionValue.concat(files);
        question.value = value;
      }
    } else if (question.getType() === 'file') {
      questionValue = question.value ? question.value : [];
      question.value = questionValue.concat(files);
    }

    this.saveProgressively().subscribe(() => console.log('saved.'));
  }

  private getHashForPanelElementsValues(pagePanelElements: any): any {
    var valuesArray = [];
    pagePanelElements.forEach(el => {
      if (el.isVisible && el.value !== undefined && el.value !== null && !el.isQuestionRoutingButton && (el.isRequired || el.getType() === 'comment')) {
        if (el.getType() === "file") {
          var files = el.value as Array<any>;
          for (var i = 0; i < files.length; i++) {
            valuesArray.push(files[i].content);
          }
        } else {
          valuesArray.push(el.value);
        }
      }
    });

    const valuesHash = this.hashingService.createHashForObject(valuesArray);
    return valuesHash;
  }  
}
