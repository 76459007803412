
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { RegisterstepfourActions, registerStepFourAdd, registerStepFourLoad, registerStepFourRemove } from '../actions/registerStepFour.actions';
import { RegisterstepfourService } from '../../services/new/registerStepFour.service';

@Injectable()
export class RegisterstepfourEffects {
  load$ = createEffect(() => this.actions$.pipe(
    ofType(registerStepFourLoad),
    mergeMap(() => this.registerStepFourService.getRegisterstepfour().pipe(
      map(registerStepFour => ({ type: RegisterstepfourActions.LoadSuccess, payload: registerStepFour })),
      catchError(() => of({ type: RegisterstepfourActions.LoadError }))
    ))
  ));
  add$ = createEffect(() => this.actions$.pipe(
    ofType(registerStepFourAdd),
    mergeMap((action) => this.registerStepFourService.addRegisterstepfour(action.registerStepFour).pipe(
      map(registerStepFour => ({ type: RegisterstepfourActions.AddSuccess, payload: registerStepFour })),
      catchError(() => of({ type: RegisterstepfourActions.AddError }))
    ))
  ));
  remove$ = createEffect(() => this.actions$.pipe(
    ofType(registerStepFourRemove),
    mergeMap((action) => this.registerStepFourService.removeRegisterstepfour().pipe(
      map(registerStepFour => ({ type: RegisterstepfourActions.RemoveSuccess, payload: registerStepFour })),
      catchError(() => of({ type: RegisterstepfourActions.RemoveError }))
    ))
  ));

  constructor(
    private actions$: Actions,
    private registerStepFourService: RegisterstepfourService
  ) { }
}
