import { createReducer, on } from '@ngrx/store';
import {
  FeatureFlagState,
  initialState,
  LoadFeatureFlags,
  LoadFeatureFlagsError,
  LoadFeatureFlagsSuccess,
} from '../actions/feature-flag.actions';

const _featureFlagReducer = createReducer(
  initialState,
  on(LoadFeatureFlags, (state: FeatureFlagState) => ({
    ...state,
  })),
  on(LoadFeatureFlagsSuccess, (state, action) => ({
    ...state,
    enabledFeatures: action.featureFlags,
    loaded: true,
  })),
  on(LoadFeatureFlagsError, (state, action) => ({
    ...state,
    error: action.error,
  }))
);

export function FeatureFlagReducer(state, action): FeatureFlagState {
  return _featureFlagReducer(state, action);
}
