import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-helper',
    templateUrl: './helper.component.html',
    styleUrls: ['./helper.component.scss']
})
export class HelperComponent {
    @Input() helpText: string;

    get isHelptip() {
        return this.helpText != null;
    }
}
