import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import {
  BasketItem,
  CreateAccountParameters,
  CheckAuthenticationResponse,
  productCodeTypes,
  UpdateAccountConfirmations,
  featureFlagNames,
  GetQuoteRequest,
  GetOrderSummary,
  productTypes,
} from '../../shared';
import { PaymentService, AccountsService, ProductService, CountryService } from '../../core';
import {
  hsFastTrackPrice,
  hsFastTrackPrice092021,
  ppFastTrackPrice,
  ppFastTrackPrice092021,
  prFastTrackPrice,
  prFastTrackPrice092021,
} from 'src/app/shared/constants/baseline-prices.const';
import applicationTypesJSON from 'src/assets/json/application-types.json';
import assessmentTypesJSON from 'src/assets/json/assessment-types.json';
import { ISOCountry } from 'src/app/shared/interfaces/iso-country';
import { GtmBrand, GtmUserCategory, GtmUserStatus } from 'src/app/core/services/gtm/gtm.enum';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectBasketAccount, selectBasketItems, selectBasketQuote, selectBasketSummary } from 'src/app/core/ngrx/selectors/basket.selectors';
import { BasketItemsActions } from 'src/app/core/ngrx/actions/basketItems.actions';
import { BasketAccountActions } from 'src/app/core/ngrx/actions/basketAccount.actions';
import { BasketQuoteActions } from 'src/app/core/ngrx/actions/basketQuote.actions';
import { BasketSummaryActions } from 'src/app/core/ngrx/actions/basketSummary.actions';
import { take } from 'rxjs/operators';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { ProductName } from 'src/app/shared/classes/productName.class';

declare var Stripe: any;

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryPageComponent implements OnInit, OnDestroy {
  basketItems$: Observable<BasketItem[]> = this.store.select(selectBasketItems);
  basketAccount$: Observable<CreateAccountParameters> = this.store.select(selectBasketAccount);
  basketQuote$: Observable<GetQuoteRequest> = this.store.select(selectBasketQuote);
  basketSummary$: Observable<GetOrderSummary> = this.store.select(selectBasketSummary);
  basketSummary: GetOrderSummary;
  basketItems: BasketItem[];
  basketAccount: CreateAccountParameters;
  basketQuote: GetQuoteRequest;
  user: CheckAuthenticationResponse;
  editingAddress: boolean;
  isProcessing: boolean;
  isLoading: boolean;
  isPageLoading: boolean = true;
  error: boolean;
  renewal: any;
  employeeInfo: string = `How are employees defined?
    The number of employees is the total number of direct employees, PAYE, permanent, temporary, part-time and labour only subcontractors. According to UK labour law a labour-only subcontractor is considered an employee.
    This does not include bona-fide subcontractors who work with their own tools, insurance etc. and are usually paid by invoice.
    We recommend that you consider the total number of effective employees you may have/use at any one time during a 12-month rolling period.`;
  joiningFee: number;
  freeMembership: boolean = false;
  errorMessage: string;
  countries: ISOCountry[];
  basketItem: BasketItem;
  fastTrackAmount: number;
  public account: CreateAccountParameters;

  // Using a reactive form to represent the registration form.
  orderForm = new UntypedFormGroup({
    checkFastTrack: new UntypedFormControl(false),
    confirmContact: new UntypedFormControl('', [Validators.requiredTrue]),
    confirmBuildUk: new UntypedFormControl(false),
  });

  addressForm = new UntypedFormGroup({
    Line1: new UntypedFormControl('', [Validators.required]),
    Line2: new UntypedFormControl(''),
    City: new UntypedFormControl('', [Validators.required]),
    Postcode: new UntypedFormControl('', [Validators.required]),
    CountryCode: new UntypedFormControl('GB', [Validators.required]),
  });
  public isFir: boolean = false;
  public goBackLink: string = '/register';
  private selectors$: Subscription;
  public companyAddress: string;
  private readonly data$ = this.store.select(selectCurrentUserState);

  constructor(
    private Accounts: AccountsService,
    private Payment: PaymentService,
    private router: Router,
    private Product: ProductService,
    private countryService: CountryService,
    private gtmService: GtmService,
    private route: ActivatedRoute,
    private store: Store
  ) {}

  ngOnInit() {
    this.data$.subscribe((data) => {
      if (data.loaded) {
        const user = data.currentAccount;

        this.store.dispatch({ type: BasketItemsActions.Load });
        this.store.dispatch({ type: BasketAccountActions.Load });
        this.store.dispatch({ type: BasketQuoteActions.Load });
        this.store.dispatch({ type: BasketSummaryActions.Load });

        this.selectors$ = this.getData(user);

        //if we have been redirected here from oauth then we need to clean up the redirectUrl in session storage
        const redirectUrl = sessionStorage.getItem('redirectUrl');
        if (redirectUrl) sessionStorage.removeItem('redirectUrl');

        this.countries = this.countryService.getAllCountries();
      }
    });
  }

  ngOnDestroy() {
    this.selectors$.unsubscribe();
  }

  private getData(user): Subscription {
    return combineLatest([this.basketItems$, this.basketAccount$, this.basketQuote$, this.basketSummary$]).subscribe(async (data) => {
      this.basketItems = data[0];
      this.basketAccount = data[1];
      this.basketQuote = data[2];
      this.basketSummary = data[3];

      this.isFir = await this.checkIsFir();
      if (this.isFir) this.goBackLink = '/fir';

      this.account = this.basketAccount;
      if (this.account) this.companyAddress = this.getCompanyAddress();

      if (!this.basketItems) {
        return this.router.navigateByUrl(this.goBackLink);
      } else {
        this.basketItem = this.basketItems[0];
      }

      if (this.basketItem.accountId && user && this.basketItem.accountId != user.AccountId) {
        return this.router.navigateByUrl('/dashboard');
      }

      if (this.basketItem && (this.basketItem.renewal || this.basketItem.upgrade)) this.user = user;
      //set checkFastTrack checkbox with value stored in basket as this retains state for back journey from stripe
      if (this.basketItem) {
        this.isPageLoading = false;
        const checkFastTrack = this.orderForm.get('checkFastTrack');

        if (this.basketItem.checkFastTrack !== checkFastTrack.value) {
          checkFastTrack.setValue(this.basketItem.checkFastTrack);
        }
      }

      this.renewal = this.isRenewal;

      this.Product.isFreeMembership(this.basketItem.accountId).subscribe((response) => {
        this.freeMembership = response;
      });

      this.joiningFee = this.basketItem.joiningFee;
    });
  }

  private async checkIsFir(): Promise<boolean> {
    if (!this.basketSummary) {
      const queryParams = await this.route.queryParams.pipe(take(1)).toPromise();
      return queryParams && queryParams.product && queryParams.product === 'fir';
    }
    const foundFir = this.basketSummary.orderLines.find((item) => item.productName.includes('Fairness Inclusion Respect'));

    return foundFir !== undefined;
  }

  private insuranceFeatureFlag(): boolean {
    return true;
  }

  get discountMessage(): string {
    const code = this.referenceCode && this.discountAmount && this.discountAmount > 0 ? this.referenceCode.toUpperCase() : null;
    if (!code) {
      return null;
    }
    return `${code} applied ${this.discountAmount}% off`;
  }

  get isPremier(): boolean {
    if (!this.basketItems.length || this.basketItem.name === null) return false;
    return this.basketItem.name.includes(ProductName.level4) ? true : false;
  }

  get isAssuredProduct(): boolean {
    if (!this.basketItems.length) return false;
    return this.basketItem.assessmentType === productCodeTypes.ASSURED ? true : false;
  }

  get joiningFeeAmount(): number {
    if (this.renewal || !this.freeMembership) {
      return 0;
    } else {
      return this.joiningFee;
    }
  }

  get requiresJoiningFee(): boolean {
    if (this.joiningFeeAmount != 0) {
      return true;
    } else {
      return false;
    }
  }

  get items(): BasketItem[] {
    return this.basketItems.filter((item) => !item.transaction_id);
  }

  get subTotal(): number {
    let total = 0;
    let arr = this.basketItems.filter((item) => !item.transaction_id);
    arr.forEach((item) => {
      total += item.price;
    });
    return total;
  }

  get vat(): number {
    return this.subTotal * this.basketItem.vatPercent;
  }

  get grandTotal(): number {
    return this.subTotal + this.vat;
  }

  private getCompanyAddress(): string {
    const parts = [
      this.account.RegAddressline1,
      this.account.RegAddressline2,
      this.account.RegCity,
      this.account.RegPostcode,
      this.account.RegCountry,
    ];
    return parts.filter((value) => value !== null).join(', ');
  }

  goBack() {
    let url = '';

    if (this.router.url.includes('/order-summary')) {
      if (this.basketItem.foundation) {
        url = '/register-foundation';
      } else {
        url = this.goBackLink;
      }
    } else if (this.isRenewal) {
      url = '/membership/renew';
    } else if (this.basketItem.foundation) {
      url = '/membership/foundation';
    } else {
      url = '/membership/upgrade';
    }

    this.router.navigate([url]);
  }

  get isRenewal(): boolean {
    return this.basketItem.renewal;
  }

  isUpgrade(basketItem: BasketItem): boolean {
    return basketItem.upgrade;
  }

  get isFoundation(): boolean {
    return this.basketItem.productID === productTypes.CHAS_FOUNDATION;
  }

  goToLogin() {
    this.store.dispatch({ type: BasketItemsActions.Clear });
    this.store.dispatch({ type: BasketAccountActions.Remove });
    this.store.dispatch({ type: BasketQuoteActions.Remove });
    // Use browser navigation instead of router navigation so that the return URL from B2C is correct.
    window.location.href = '/';
  }

  public toggleFastTrack(e: Event) {
    const isChecked = (e.target as HTMLInputElement).checked;
    const currentPrice = this.basketItem.price;
    const fastTrackPrice = Math.abs(this.basketItem.fastTrack);
    let newPrice = currentPrice;
    let basketSummary = Object.assign({}, this.basketSummary);

    if (isChecked) {
      newPrice += fastTrackPrice;
      basketSummary.totalNetAmount += fastTrackPrice;
    } else {
      newPrice -= fastTrackPrice;
      basketSummary.totalNetAmount -= fastTrackPrice;
    }

    basketSummary.totalTaxAmount = basketSummary.totalNetAmount * basketSummary.taxRates[0];
    basketSummary.totalGrossAmount = basketSummary.totalNetAmount + basketSummary.totalTaxAmount;

    this.store.dispatch({
      type: BasketItemsActions.Update,
      change: {
        item: this.basketItem,
        field: {
          price: newPrice,
          checkFastTrack: isChecked,
        },
      },
    });

    this.store.dispatch({
      type: BasketSummaryActions.Add,
      orderSummary: basketSummary,
    });
  }

  /**
   * Returns TRUE if we're ordering a Deemed To Satisfy product.
   */
  isDeemedToSatisfy(): boolean {
    const matching = this.basketItems.find((item) => '885570002' === item.assessmentType);

    return undefined !== matching;
  }

  beginEditTradingAddress(): void {
    this.editingAddress = true;
  }

  cancelEditTradingAddress(): void {
    this.editingAddress = false;
  }

  /**
   * Returns TRUE if the order button should be enabled.
   */
  isOrderFormValid(): boolean {
    return this.orderForm.valid && (this.editingAddress ? this.addressForm.valid : true);
  }

  async updateTradeAddressAndConfirmations() {
    this.isLoading = true;

    let confirmations: UpdateAccountConfirmations = {
      AcceptedTermsAndConditions: this.orderForm.value.confirmContact,
      AcceptedDataSharing: this.isPremier ? this.orderForm.value.confirmBuildUk : null,
    };

    if (this.editingAddress) {
      confirmations.CorrespondenceAddress = {
        id: null,
        latitude: 0,
        longitude: 0,
        street1: this.addressForm.value.Line1,
        street2: this.addressForm.value.Line2,
        street3: null,
        postCode: this.addressForm.value.Postcode,
        county: null,
        town: null,
        country: this.countryService.getNameFromCode(this.addressForm.value.CountryCode),
        countryCode: this.addressForm.value.CountryCode,
        city: this.addressForm.value.City,
      };
    }

    try {
      let accountId = this.basketItem.accountId;
      await this.Accounts.updateConfirmationsAsync(accountId, confirmations);
    } catch (error) {
      console.error(error);
    }
  }

  async activateOpportunityAsync() {
    this.isLoading = true;

    await this.updateOpportunityIfRequiredAsync();

    let obj: any = {
      name: this.basketItem.name,
      accountId: this.basketItem.accountId,
      bundleId: this.basketAccount.BundleId,
      assessmentType: this.basketAccount.AssessmentType,
      variant: this.basketItem.applicationType,
      employeenum: this.basketAccount.NoOfEmployees,
      NumberofDirectEmployees: this.basketAccount.NumberofDirectEmployees,
      LabourOnlySubContractors: this.basketAccount.LabourOnlySubContractors,
      BonafideSubContractors: this.basketAccount.BonafideSubContractors,
      fastTrack: this.orderForm.value.checkFastTrack,
      callbackSuccessUrl: this.basketAccount.SuccessUrl,
      callbackCancelUrl: this.basketAccount.CancelUrl,
      renewal: this.renewal,
      freeMembership: this.freeMembership,
      referencecode: this.referenceCode,
    };
    if (this.user) obj.contactId = this.user.ContactId;
    if (this.orderForm.value.confirmBuildUk) obj.dataSharingAllowed = true;

    await this.updateTradeAddressAndConfirmations();

    await this.Payment.activateAsync(this.basketSummary.totalGrossAmount, obj);

    this.gtmService.payNow({
      status: GtmUserStatus.LoggedIn,
      uid: obj.accountId,
      ecommerce: {
        checkout: {
          actionField: { step: 1, option: 'Order Page' },
          products: [
            {
              id: obj.productID,
              name: obj.name,
              price: obj.price,
              brand: GtmBrand.CHAS,
              category: GtmUserCategory.Contractor,
              variant: obj.variant,
              quantity: 1,
            },
          ],
        },
      },
    });

    this.gtmService.orderSummary({
      status: GtmUserStatus.LoggedIn,
      uid: this.basketItem.accountId,
      ecommerce: {
        checkout: {
          actionField: { step: 2, option: 'Pay now by credit or debit card' },
          products: [
            {
              id: this.basketItem.productID,
              name: this.basketItem.name,
              price: this.basketItem.price,
              brand: GtmBrand.CHAS,
              category: GtmUserCategory.Contractor,
              variant: this.basketItem.applicationType,
              quantity: 1,
            },
          ],
        },
      },
    });
  }

  async updateQuoteAsync(): Promise<boolean> {
    if (!this.basketQuote) return false;

    try {
      const quote = await this.Product.getMembershipQuoteAsync({
        ...this.basketQuote,
        fastTrack: this.fastTrackEnabled,
        createOpportunity: true,
      });

      if (quote.error) {
        this.errorMessage = quote.error.message;
        return false;
      }

      this.store.dispatch({ type: BasketQuoteActions.Add, quote: quote.request });
    } catch {
      this.errorMessage = 'An unexpected error occurred. Please try again in a few minutes.';
      return false;
    }

    return true;
  }

  async updateOpportunityIfRequiredAsync(): Promise<void> {
    if (!this.basketQuote) return null;

    if (this.basketItem.checkFastTrack) {
      await this.updateQuoteAsync();
    }
  }

  get hsFastTrackPrice() {
    return this.insuranceFeatureFlag() ? hsFastTrackPrice092021 : hsFastTrackPrice;
  }

  get ppFastTrackPrice() {
    return this.insuranceFeatureFlag() ? ppFastTrackPrice092021 : ppFastTrackPrice;
  }

  get prFastTrackPrice() {
    return this.insuranceFeatureFlag() ? prFastTrackPrice092021 : prFastTrackPrice;
  }

  applicationType(code) {
    let a = applicationTypesJSON.filter((e) => e.code.includes(code));
    return a[0]?.name;
  }

  assessmentType(code) {
    var a = assessmentTypesJSON.filter((e) => e.code.includes(code));
    return a[0]?.name;
  }

  get referenceCode() {
    if (this.basketItem === null || this.basketItems === null || this.basketItems.length < 1) {
      return null;
    }

    return this.basketItem.referenceCode;
  }

  get discountAmount() {
    if (this.basketItem === null || this.basketItems === null || this.basketItems.length < 1) {
      return null;
    }

    return this.basketItem.discountAmount;
  }

  get fastTrackEnabled(): boolean {
    return this.orderForm.value.checkFastTrack ?? false;
  }
}
