import { QuickUploadConfig } from "../components/quick-upload/quick-upload.interfaces";

export interface CourseConfig {
  learnPathTitle: string;
  learnPathDescription: string;
  quickUploadConfig: QuickUploadConfig;
}

export interface CourseGroup {
  id: string;
  title: string;
  description: string;
  courses: Course[];
}

export interface Course {
  id: string;
  title: string;
  description: string;
  order: number;
  icon: string;
  resources: Resource[];
  sections: Section[];
}

export interface Resource {
    fileName?: string;
    mimeType?: string;
    title: string;
}

export interface Section {
    title: string;
    contentHtml: string[];
    order: number;
}

export interface CourseProgress {
  accountId: string;
  courseGroupId?: string;
  courseId: string;
  state: Progress;
  createdBy: string;
}

export enum Progress {
  NotStarted = 0,
  InProgress = 1,
  Completed = 2
}
