import { UntypedFormGroup } from '@angular/forms';

export function FieldsShouldNotMatch(a: string, b: string) {
  return (formGroup: UntypedFormGroup) => {
    const aControl = formGroup.controls[a];
    const bControl = formGroup.controls[b];

    if (!aControl || !bControl) {
      return null;
    }

    if (bControl.errors && !bControl.errors.match) {
      return null;
    }

    if (aControl.value === bControl.value) {
      bControl.setErrors({ match: true });
    } else {
      bControl.setErrors(null);
    }
  }
}
