import { Pipe, PipeTransform } from '@angular/core';
import { ComplianceStatus } from '../interfaces/compliance.model';

@Pipe({
  name: 'complianceStatus',
})
export class ComplianceStatusPipe implements PipeTransform {
  transform(value: ComplianceStatus): string {
    switch (value) {
      case ComplianceStatus.Inconclusive:
        return 'Inconclusive';
      case ComplianceStatus.Unregistered:
        return 'Unregistered';
      case ComplianceStatus.Risk:
        return 'Risk';
      case ComplianceStatus.Expiring:
        return 'Expiring';
      case ComplianceStatus.InProgress:
        return 'In Progress';
      case ComplianceStatus.UpdateRequired:
        return 'Update Required';
      case ComplianceStatus.Accredited:
        return 'Accredited';
      default:
        return '';
    }
  }
}
