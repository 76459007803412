import { Component } from '@angular/core';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { getCompanySummary } from '../../../core/ngrx/selectors/company-details.selectors';
import { AccountContact, IAccountContactQuoteResponse, QuoteResponse } from '../../../shared';
import { CheckoutSessionResponse, IAccountContactQuote, IContact } from '../../../shared/interfaces/new/register-data';
import { Environment, IEnvironment } from '../../../shared/classes/environment';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AccountsService, PaymentService } from '../../../core';
import { CompanySummary } from '../../../shared/interfaces/company-summary';
import { RegisterCoreService } from '../../../core/services/register-core.service';
import { CurrentUserState } from '../../../core/ngrx/reducers/currentUser.reducer';
import { selectCurrentUserState } from '../../../core/ngrx/selectors/currentUser.selectors';
import { BundleNamesAus } from '../../../shared/components/select-membership/select-membership.component';

@Component({
  selector: 'app-membership-foundation',
  templateUrl: './membership-foundation.component.html',
  styleUrls: ['./membership-foundation.component.scss'],
})
export class MembershipFoundationComponent {
  public stepIndex = 0;
  public account$: Observable<any> = this.store.select(getCompanySummary);
  private subscriptions$ = new Subscription();
  public loading = true;
  public companySummary: CompanySummary;
  public termsAndConditions = false;
  public opportunity: IAccountContactQuoteResponse;
  public checkoutSession: CheckoutSessionResponse;
  public productSelected = false;
  public selectedProduct: QuoteResponse;
  private config: IEnvironment;
  private readonly currentUser$ = this.store.select(selectCurrentUserState);
  private currentUser: CurrentUserState;
  private currentUserSubscription: Subscription;

  constructor(
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
    private readonly http: HttpClient,
    private readonly paymentService: PaymentService,
    private readonly accountService: AccountsService,
    private readonly registerService: RegisterCoreService
  ) {
    this.subscriptions$ = this.activatedRoute.queryParams.subscribe((params: Params) => (this.stepIndex = params.index));
  }

  async ngOnInit(): Promise<void> {
    const environment: Environment = new Environment();
    this.config = await environment.getConfig();

    this.currentUserSubscription = this.currentUser$.subscribe(async (data) => {
      this.currentUser = data;

      if (this.currentUser) {
        if (this.stepIndex == 2) {
          this.loading = false;
        } else {
          this.companySummary = await firstValueFrom(this.account$);
          this.opportunity = await this.constructQuote();
          this.loading = false;
        }
      }
    });
  }

  private async constructQuote(): Promise<IAccountContactQuoteResponse> {
    let account = {
      accountId: this.currentUser.companyDetails.id?.uid,
      abNumber: this.currentUser.companyDetails.abNumber,
      acNumber: this.currentUser.companyDetails.acNumber,
      assessmentType: undefined,
      BonafideSubContractors: this.currentUser.companyDetails.numberOfBonaFideSubcontractors,
      companyHouseNo: this.currentUser.currentAccount.companyHouseNo,
      companyName: this.currentUser.currentAccount.accountName,
      customerType: this.currentUser.currentAccount.userType,
      isAusGstRegistered: undefined,
      LabourOnlySubContractors: this.currentUser.companyDetails.numberOfLabourOnlySubcontractors,
      NoOfEmployees: this.currentUser.companyDetails.numberOfEffectiveEmployees,
      variant: undefined,
      NumberofDirectEmployees: this.currentUser.companyDetails.numberOfDirectEmployees,
      tradingAs: this.currentUser.companyDetails.tradingName,
    };

    let accountContact: AccountContact = this.currentUser.contacts.find((x) => x.isPrimaryContact);

    let contact = {
      firstName: accountContact.firstname,
      lastName: accountContact.lastname,
      emailAddress: accountContact.email,
      telephone1: accountContact.telephone1,
      mobile1: accountContact.mobilephone,
      password: accountContact.password,
    };

    let quote = {
      BundleNames: ['Verified Contractor', 'Elite'],
      accountId: this.companySummary.companyAccountID,
      assessmentType: undefined,
      applicationType: '885570000',
      cancelUrl: `${this.config.urls.environmentUrl}/membership/foundation?index=0`,
      customerType: '870520001',
      dataSharingAllowed: false,
      discountCode: null,
      fastTrack: false,
      firFee: false,
      joiningFee: false,
      opportunityId: null,
      priceLevelId: '',
      sectorType: '885570006',
      selectedBundle: this.selectedProduct?.result.productName ?? 'Elite',
      successUrl: `${this.config.urls.environmentUrl}/membership/foundation?index=2`,
    };

    const quoteObject: IAccountContactQuote = {
      account: account,
      address: this.companySummary.correspondenceAddress,
      clickDimensionId: '',
      contact: contact,
      opportunityId: '',
      quote: quote,
    };

    return await this.registerService.createAndGetQuotesAsync(quoteObject);
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
    this.currentUserSubscription.unsubscribe();
  }

  public async selectProduct(quote: QuoteResponse): Promise<void> {
    this.selectedProduct = quote;
  }

  protected readonly BundleNamesAus = BundleNamesAus;
}
