import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Environment, IEnvironment } from 'src/app/shared/classes/environment';

import { SiteMenuService } from '../../../core/services/site-menu.service';
import { CheckNewAuthenticationResponse } from '../../index';
import { AssessmentMenuService } from 'src/app/core/services/assessment-menu.service';
import { Store } from '@ngrx/store';
import { selectCurrentUserState } from 'src/app/core/ngrx/selectors/currentUser.selectors';
import { CurrentUserState } from 'src/app/core/ngrx/reducers/currentUser.reducer';
import { MsalService } from '@azure/msal-angular';
import { Territory } from '../../constants/territory.enum';
import { MyClientsService } from 'src/app/core/services/my-clients/my-clients.service';

@Component({
  selector: 'app-site-menu',
  templateUrl: './site-menu.component.html',
  styleUrls: ['./site-menu.component.scss'],
})
export class SiteMenuComponent implements OnInit {
  private authDetails: CheckNewAuthenticationResponse;
  private linkType: string = null;
  private readonly data$ = this.store.select(selectCurrentUserState);
  private currentUserState: CurrentUserState;
  private config: IEnvironment;
  public showKitchenSink: boolean = false;
  public upgradeEnabled = false;
  public isUK: boolean;
  public isOpsUserSession = false;

  constructor(
    private readonly authService: MsalService,
    private readonly router: Router,
    private readonly SiteMenu: SiteMenuService,
    private readonly assessmentMenuService: AssessmentMenuService,
    private readonly myClientsService: MyClientsService,
    private readonly store: Store
  ) {}

  async ngOnInit() {
    const env = new Environment();
    this.config = await env.getConfig();
    this.isUK = this.config.territory === Territory.UNITEDKINGDOM;

    this.showKitchenSink = this.config.showKitchenSink;

    this.data$.subscribe({
      next: async (data) => {
        if (data.loaded) {
          this.currentUserState = data;
          this.authDetails = data.currentAccount;

          const obj: any = Object.assign({}, this.authDetails);
          const formData = {
            accountId: this.authDetails.accountId,
            contactId: this.authDetails.contactId,
            customerType: obj.membershipTypeId,
            numberOfEmployees: data.companyDetails.numberOfEffectiveEmployees,
            sectorType: 0,
            applicationType: 0,
            assessmentTypeCode: 0,
            membershipLevel: null,
            isFIRFee: false,
            fastTrack: false,
          };
          this.isOpsUserSession = data.currentAccount.isLogMeInContact;

          await this.SiteMenu.getAllQuotes(formData);
        }
      },
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) return;
      if (this.SiteMenu.isVisible) {
        this.SiteMenu.toggle();
      }
    });
  }

  async navigate(link: string, type: string) {
    this.assessmentMenuService.setSelectedAssessmentPages([]);
    this.assessmentMenuService.setCurrentAssessmentPage(null);
    this.assessmentMenuService.setCurrentAssessmentId(null);

    this.linkType = type;
    this.router.navigate([link]);
  }

  isActive(type: string): boolean {
    return this.linkType === type;
  }

  logout() {
    this.authService.logoutRedirect();
  }

  upgradeButtonClick() {
    this.router.navigateByUrl('/membership', { state: { data: { renewal: true, foundation: true } } });
  }

  /**
   * Returns the account ID of the current user.
   */

  get customerType(): string {
    return this.authDetails ? this.authDetails.userType : null;
  }

  get accountId(): string {
    return this.authDetails ? this.authDetails.accountId : null;
  }

  get isMenuVisible(): boolean {
    return this.SiteMenu.isVisible;
  }

  get accessCompanyDetails(): boolean {
    return this.currentUserState?.roles.find((b) => b.name?.toLowerCase() === 'company details')?.privilege !== 'No Access' ? true : false;
  }

  get accessDocuments(): boolean {
    return this.currentUserState?.roles.find((b) => b.name === 'My Documents')?.privilege !== 'No Access' ? true : false;
  }

  get accessAssessments(): boolean {
    return this.currentUserState?.roles.find((b) => b.name === 'Assessments')?.privilege !== 'No Access' ? true : false;
  }

  get accessMyClients(): boolean {
    return this.currentUserState?.hasConnectedClients;
  }
}
