import { Component, OnChanges, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';
import { AssessmentsService } from '../../../core/services/assessments.service';
import { Assessment, AssessmentCategory, AssessmentStatus, dateCheck, DateHelpers, featureFlagNames, UserType } from '../../index';
import { LoadingService } from '../../../core/services/loading-account.service';
import { AssessmentMenuService } from 'src/app/core/services/assessment-menu.service';
import { Assessment as IAssessment, Assessment as IAssessment1 } from 'src/app/shared/interfaces/assessment';
import { AssessmentPageInfo } from 'src/app/shared/interfaces/assessment-page-info';
import statusHelpJson from 'src/assets/json/status-help.json';
import { AssessmentBundle } from '../../interfaces/assessment-bundle';

@Component({
  selector: 'app-assessments-menu',
  templateUrl: './assessments-menu.component.html',
  styleUrls: ['./assessments-menu.component.scss']
})
export class AssessmentsMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() accountId: string;
  @Input() customerType: string;
  assessmentCategories: AssessmentCategory[];
  error: string;
  isLoading: boolean;
  private $destroyed = new Subject<void>();
  assessmentPagesSubscription: Subscription;
  assessmentPages: AssessmentPageInfo[] = [];
  currentAssessmentPage: AssessmentPageInfo;
  currentPageSubscription: Subscription;

  constructor(
    private Assessments: AssessmentsService,
    private loading: LoadingService,
    private assessmentMenuService: AssessmentMenuService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.assessmentPagesSubscription = this.assessmentMenuService
      .getSelectedAssessmentPagesSubscription()
      .subscribe(pages => this.assessmentPages = pages);

    this.currentPageSubscription = this.assessmentMenuService.getCurrentAssessmentPage()
      .subscribe(page => this.currentAssessmentPage = page);
  }

  ngOnChanges() {
    if (this.accountId) {
      this.loadAssessments();
      this.loading.isAssessmentLoading.subscribe(load => {
        if (load) this.loadAssessments();
      });
    }
  }

  ngOnDestroy() {
    this.$destroyed.next();
    if (this.assessmentPagesSubscription) {
      this.assessmentPagesSubscription.unsubscribe();
    }

    if (this.currentPageSubscription) {
      this.currentPageSubscription.unsubscribe();
    }
  }


  public assessmentMenuEnabled(): boolean {
    return true;
  }

  selectAssessment(assessment: any, categoryName: string): void {
    if (assessment.id === this.assessmentMenuService.getCurrentAssessmentId()) {
      return;
    }
    this.assessmentMenuService.setSelectedAssessmentPages([]);
    this.assessmentMenuService.setCurrentAssessmentId(assessment.id);
    this.assessmentMenuService.setCurrentAssessmentPage(null);
    this.router.navigate(['/assessment', assessment.id], { state: { data: categoryName } });
  }

  selectAssessmentPage(page: AssessmentPageInfo): void {
    this.assessmentMenuService.setSectionSelected();
    this.assessmentMenuService.setCurrentAssessmentPage(page);
  }

  isAssessmentPageSelected(page: AssessmentPageInfo, index: number): boolean {
    if (this.currentAssessmentPage === undefined || this.currentAssessmentPage === null) {
      if (index === 0) {
        return true;
      }
      return false;
    }
    if (page === undefined || page === null) { return false; }
    return this.currentAssessmentPage.id === page.id;
  }

  get selectedAssessmentId() {
    return this.assessmentMenuService.getCurrentAssessmentId();
  }

  isAssessmentSelected(id: string): boolean {
    return id === this.assessmentMenuService.getCurrentAssessmentId();
  }

  /**
   * Fetches assessments for the contractor.
   */
  loadAssessments(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.error = null;
    this.Assessments
      .get(this.accountId)
      .pipe(
        takeUntil(this.$destroyed),
        tap(() => {
          this.isLoading = false;
          this.loading.changeAssessmentLoading(false);
        })
      )
      .subscribe({
        next: (data: Assessment[]) => {
          //Get Statment of best practice (COVID-19) status and id and add to storage for banner and router link on dashboard
          const covid_verification = data.find(a => a.name === 'Statement Of Best Practice (COVID-19) Verification');
          if (covid_verification) {
            sessionStorage.setItem('covid_verification', JSON.stringify({
              id: covid_verification.id,
              status: covid_verification.status
            }));
            if (covid_verification.expiryDate && new DateHelpers(covid_verification.expiryDate).expiry() === dateCheck.EXPIRED) {
              let index = data.findIndex(a => a.id === covid_verification.id);
              data.splice(index, 1);
            }
          };

          try {
            //Set health and safety assessment failed state for banner on dashboard
            const health_safety_assessment = data.find(a => a.name.toLowerCase().indexOf('health & safety') > -1);
            if (health_safety_assessment &&
              (health_safety_assessment?.status?.label === 'Failed'
                || health_safety_assessment?.status?.label === 'Fail Resubmit')) {
              sessionStorage.setItem('failed_assessment', 'true');
            } else {
              sessionStorage.setItem('failed_assessment', 'false');
            }
          } catch (err) {
            console.log(err);
          }
          this.groupAssessments(data);
        },
        error: (response: HttpErrorResponse) => this.error = response.error.Message
      });
  }

  /**
   * Organises assessments based on their status, then updates the menu.
   */
  groupAssessments(assessmentList: IAssessment[]): void {
    // Make a list of unique statuses from the available assessments.
    const statuses = assessmentList
      .map((row: IAssessment1) => row.displayStatus?.label)
      .filter((v, i, a) => a.indexOf(v) === i);
    // Build a list of assessments, organised by their status.
    this.assessmentCategories = statuses.map((status: string) => {

      const helpTip = statusHelpJson.find(s => s.status === status.toLowerCase());
      const isSupplier = this.customerType === UserType.SUPPLIER;

      let assessments = assessmentList.filter((row: Assessment) => {
        return row.displayStatus?.label === status;
      });

      if (status == AssessmentStatus.CANCELLED || status == AssessmentStatus.REFUNDED || status == AssessmentStatus.WITHDRAWN) {
        return null;
      } else {
      return {
        name: status,
        helpTip: helpTip?.help,
        class: status?.toLowerCase(),
        assessments: assessmentList.filter((row: Assessment) => {
          return row.displayStatus?.label === status;
        }),
        bundles: this.groupByBundles(assessments)
      };
      }
    });
  }

  groupByBundles(assessments: Assessment[]): AssessmentBundle[] {
    let bundles: AssessmentBundle[] = [];

    for (let i = 0; i < assessments.length; i++) {
      let assessment = assessments[i];
      let match = bundles.find(x => x.name == assessment.bundleName);

      if (match == null) {
        bundles.push({
          name: assessment.bundleName,
          class: '',
          assessments: [assessment]
        })
      }
      else {
        match.assessments.push(assessment);
      }
    }

    return this.sortBundle(bundles);
  }

  sortBundle(bundles: AssessmentBundle[]): AssessmentBundle[] {
    const orderOfNames = [
      'Elite Assessment',
      'Elite Verification',
      'Verified Contractor',
      'Verified Supplier',
      'Region Specific',
      'State Specific',
      'Other'
    ];
    
    bundles.sort((a, b) => {
      const indexA = orderOfNames.indexOf(a.name);
      const indexB = orderOfNames.indexOf(b.name);
    
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      } else if (indexA !== -1) {
        return -1;
      } else if (indexB !== -1) {
        return 1;
      } else {
        return a.name.localeCompare(b.name);
      }
    });

    return bundles;
  }
}
