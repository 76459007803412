import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecuritySettingsModalComponent } from './security-settings-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HelperModule } from '../helper/helper.module';
import { BootstrapComponentsModule } from '../bootstrap/bootstrap.module';

@NgModule({
  declarations: [SecuritySettingsModalComponent],
  imports: [
    CommonModule,
    BootstrapComponentsModule,
    HelperModule,
    ReactiveFormsModule
  ],
  exports: [SecuritySettingsModalComponent]
})
export class SecuritySettingsModalModule { }
