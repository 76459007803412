<form [formGroup]="referenceForm">
  <div class="form-group">
    <div class="input-group">
      <input type="text"
             id="appRef"
             class="form-control"
             formControlName="refCode"
             (keyup)="inputChange()"
             placeholder="Enter the code to redeem here"/>
      <div class="input-group-append">
        <button class="btn btn-secondary" type="button" (click)="validateReferenceCode()">Validate</button>
      </div>
    </div>
  </div>
  <alert type="success" *ngIf="referenceCodeSuccess">The Reference code is valid.</alert>
  <alert type="warning" *ngIf="referenceCodeError">
    The Reference code is not valid, please enter a valid reference code.
  </alert>
</form>

