import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApplicationTypesComponent } from './application-types.component';
import { NothingModule } from 'src/app/shared/components/nothing/nothing.module';

@NgModule({
  declarations: [ApplicationTypesComponent],
  imports: [
    CommonModule,
    NothingModule
  ],
  exports: [ApplicationTypesComponent]
})
export class ApplicationTypesModule { }
