<div class="modal-header">
  <h4 class="modal-title pull-left">Work Categories</h4>
</div>

<div class="modal-body">
  <form [formGroup]="workCategoriesForm">

    <div class="text-center" *ngIf="isLoading">
      <app-loading-spinner></app-loading-spinner>
    </div>

    <div class="page" [hidden]="isLoading">
      <div *ngIf="showAlertBox && nonMatchingCategories.length !== 0" class="alert alert-danger">
        Some work categories assigned to your account do not match with the Variant(s) you have purchased.
        These will be automatically removed when you click the "Save" button.
        <br>You cannot continue with your assessment until this is rectified.
      </div>
      <div>
        <form [formGroup]="searchForm" class="page__search">
          <input type="text"
            class="form-control page__search-field"
            formControlName="term"
            placeholder="Search Work Categories"
            aria-label="Search Work Categories"
            [ngModel]="searchText"
            *ngIf="!showSelectedPanel"
            (ngModelChange)="onSearchTextChanged($event)" />
        </form>
        <div *ngIf="showClearFilter" class="page__filters">
          <a (click)="clearFilter()"><i class="fas fa-times"></i> Clear Filter</a>
        </div>
      </div>
      <!-- All Items -->
      <div class="search-results" infinite-scroll
        (scrolled)="onScrollDown($event)"
      >
        <div class="page__table card" [hidden]="showSelectedPanel">
          <div class="page__table-header">
            <div></div>
            <div class="page__table-cell">Level 1</div>
            <div class="page__table-cell">Level 2</div>
            <div class="page__table-cell">Level 3</div>
          </div>
          <div *ngIf="hasWorkCategories; else noCategories">
            <div *ngFor="let item of workCategoriesView">
              <div [ngName]="item.workCode"
                class="page__table-row"
                [ngClass]="{'red-text': !item.matchesAccountVariant }">
                <div class="page__table-cell">
                  <input type="checkbox" [checked]="item.isSelected"
                    (change)="onCategorySelected($event, item)"/>
                </div>
                <div class="page__table-cell">{{ item.workType }}</div>
                <div class="page__table-cell">{{ item.subHeader }}</div>
                <div class="page__table-cell">{{ item.name }}</div>
              </div>
            </div>
          </div>
          
          <ng-template #noCategories>
            <p>
              <em class="text-danger">*No work categories defined. At least one work category is required.</em>
            </p>
          </ng-template>
        </div>
      </div>
      <!-- Selected Items -->
      <div class="page__table card" [hidden]="!showSelectedPanel">
        <div class="page__table-header">
          <div></div>
          <div class="page__table-cell">Level 1</div>
          <div class="page__table-cell">Level 2</div>
          <div class="page__table-cell">Level 3</div>
        </div>
        <div *ngIf="hasWorkCategories; else noCategories">
          <div *ngFor="let item of allSelectedCategory">
            <div [name]="item.workCode" class="page__table-row">
              <div class="page__table-cell">
                <input type="checkbox" [checked]="item.isSelected"
                  (change)="onCategoryDeselected($event, item)"/>
              </div>
              <div class="page__table-cell">{{ item.workType }}</div>
              <div class="page__table-cell">{{ item.subHeader }}</div>
              <div class="page__table-cell">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <ng-template #noCategories>
          <p>
            <em class="text-danger">*No work categories defined. At least one work category is required.</em>
          </p>
        </ng-template>
      </div>
    </div>
    <div class="row justify-content-center" [hidden]="isLoading" style="margin-top: .5em;">
      <button class="btn btn-secondary col-4 rounded-sm" [ngClass]="{'active': !showSelectedPanel}" (click)="showSelectedWorkCategories(false)" width="100">All</button>
      <button class="btn btn-secondary col-4 rounded-sm" [ngClass]="{'active': showSelectedPanel}" (click)="showSelectedWorkCategories(true)">{{ selectionReport() }}</button>
    </div>
  </form>
</div>

<alert type="danger" *ngIf="error">{{ error }}</alert>

<div class="modal-footer">
  <button class="btn btn-link" type="button" data-dismiss="modal">Close</button>
  <button
    class="btn btn-primary"
    type="button"
    (click)="updateWorkCategories()"
    [class.is-loading]="saving"
    [disabled]="!canSave">
    Save
  </button>
</div>
