import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { QuickquoteActions } from 'src/app/core/ngrx/actions/quickQuote.actions';
import { selectQuickQuote } from 'src/app/core/ngrx/selectors/quickQuote.selectors';
import { ProductService } from 'src/app/core/services/product.service';
import { Environment, IEnvironment } from '../../classes/environment';
import { Territory } from '../../constants/territory.enum';
import { getCurrency } from '../../functions/currency.functions';
import { IQuickquote } from '../../interfaces/new/quickQuote';
import { VerificationType } from '../../interfaces/verification-type';
import { BundleNames, BundleNamesAus } from '../select-membership/select-membership.component';
import { ICreateOrder } from '../../interfaces/new/createOrder';

@Component({
  selector: 'app-register-selection-aus',
  templateUrl: './register-selection.component.html',
  styleUrls: ['./register-selection.component.scss'],
})
export class RegisterSelectionAusComponent implements OnInit, OnDestroy {
  @Input() public readonly reduced: boolean = false;
  @Input() public readonly form: UntypedFormGroup;
  @Input() public readonly bundles: any;
  @Input() public readonly bundleName: string;
  @Input() public readonly isCare: boolean = false;
  @Input() public readonly isSupplier: boolean = false;
  @Output() loaded = new EventEmitter<boolean>();
  public readonly quickQuote$: Observable<IQuickquote> = this.store.select(selectQuickQuote);
  public readonly bundleNames = BundleNamesAus;
  public loading = true;
  public showStandardBundle = false;
  public showAdvancedBundle = false;
  public showEliteBundle = false;
  public showSupplierBundle = false;
  public quoteAmounts: any = {
    Standard: '0.00',
    Elite: '0.00',
  };
  public environment: IEnvironment;
  public Territory = Territory;
  public currencyCode = 'GBP';
  public show_supplier_modules = false;
  public show_contractor_modules = false;
  public show_elite_modules = false;
  public eliteModules: VerificationType[];
  public contractorModules: VerificationType[];
  public supplierModules: VerificationType[];
  private bundlesSubscription: Subscription;

  constructor(
    private readonly store: Store,
    private readonly productService: ProductService
  ) {}

  async ngOnInit(): Promise<void> {
    const env = new Environment();
    this.environment = await env.getConfig();

    this.currencyCode = getCurrency(this.environment.territory);

    this.store.dispatch({
      type: QuickquoteActions.Load,
    });

    await this.initializeProducts();
  }

  async ngOnDestroy(): Promise<void> {
    this.bundlesSubscription.unsubscribe();
  }

  private async initializeProducts(): Promise<void> {
    this.bundlesSubscription = this.quickQuote$.subscribe(async (bundles) => {
      if (!bundles) return;

      if (this.isSupplier) {
        this.showEliteBundle = false;
        this.showAdvancedBundle = false;
        this.showStandardBundle = false;
        this.showSupplierBundle = true;
        this.quoteAmounts.VerifiedSupplier =
          bundles[this.bundleNames.VerifiedSupplier.toLocaleLowerCase()].lastQuoteResult.result.totalAmount;
        if (this.quoteAmounts.VerifiedSupplier > 0) this.loading = false;
      } else {
        this.showEliteBundle = bundles && this.canShowBundle(bundles[this.bundleNames.Elite.toLocaleLowerCase()]);
        this.showStandardBundle = bundles && this.canShowBundle(bundles[this.bundleNames.VerifiedContractor.toLocaleLowerCase()]);

        if (this.showEliteBundle && !this.isCare) {
          this.showEliteBundle = true;
        }

        this.quoteAmounts.Standard = this.showStandardBundle
          ? bundles[this.bundleNames.VerifiedContractor.toLocaleLowerCase()].lastQuoteResult.result.totalAmount
          : '0.00';
        this.quoteAmounts.Elite = this.showEliteBundle
          ? bundles[this.bundleNames.Elite.toLocaleLowerCase()].lastQuoteResult.result.totalAmount
          : '0.00';

        if (this.quoteAmounts.Standard > 0 || this.quoteAmounts.Elite > 0) this.loading = false;
      }

      await this.setProductInfo();
    });
  }

  private async setProductInfo(): Promise<void> {
    if (this.showEliteBundle) {
      let result = await firstValueFrom(this.productService.getVerificationTypes(this.bundleNames.Elite));
      this.eliteModules = result.modules;
    }

    if (this.showStandardBundle) {
      let result = await firstValueFrom(this.productService.getVerificationTypes(this.bundleNames.VerifiedContractor));
      this.contractorModules = result.modules;
    }

    if (this.showSupplierBundle) {
      let result = await firstValueFrom(this.productService.getVerificationTypes(this.bundleNames.VerifiedSupplier));
      this.supplierModules = result.modules;
    }
  }

  public selectBundle(bundle: string) {
    this.form.patchValue({
      BundleName: bundle,
    });
  }

  private canShowBundle(bundle: ICreateOrder) {
    const lead = bundle?.lastQuoteResult?.lead;

    if (lead) {
      const productName = bundle.lastQuoteResult.result.productName.toLocaleLowerCase();
      return lead.preferredProducts.map((x) => x.name.toLocaleLowerCase()).includes(productName);
    }

    return bundle !== undefined;
  }
}
