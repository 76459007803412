import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';

import { FeaturesModule } from './features/features.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { LayoutModule } from './shared/components/layout/layout.module';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './core/ngrx/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { CurrentUserEffects } from './core/ngrx/effects/currentUser.effects';
import { basketItemsReducer } from './core/ngrx/reducers/basketItems.reducer';
import { basketAccountReducer } from './core/ngrx/reducers/basketAccount.reducer';
import { basketQuoteReducer } from './core/ngrx/reducers/basketQuote.reducer';
import { basketSummaryReducer } from './core/ngrx/reducers/basketSummary.reducer';
import { quickQuoteReducer } from './core/ngrx/reducers/quickQuote.reducer';
import { notificationReducer } from './core/ngrx/reducers/notification.reducer';
import { courseReducer } from './core/ngrx/reducers/course.reducer';
import { registerStepReducer } from './core/ngrx/reducers/registerStep.reducer';
import { registerStepOneReducer } from './core/ngrx/reducers/registerStepOne.reducer';
import { registerStepTwoReducer } from './core/ngrx/reducers/registerStepTwo.reducer';
import { registerStepThreeReducer } from './core/ngrx/reducers/registerStepThree.reducer';
import { registerStepFourReducer } from './core/ngrx/reducers/registerStepFour.reducer';
import { CourseEffects } from './core/ngrx/effects/course.effects';
import { RegisterstepfourEffects } from './core/ngrx/effects/registerStepFour.effects';
import { RegisterstepthreeEffects } from './core/ngrx/effects/registerStepThree.effects';
import { RegistersteptwoEffects } from './core/ngrx/effects/registerStepTwo.effects';
import { RegistersteponeEffects } from './core/ngrx/effects/registerStepOne.effects';
import { RegisterstepEffects } from './core/ngrx/effects/registerStep.effects';
import { NotificationEffects } from './core/ngrx/effects/notification.effects';
import { QuickquoteEffects } from './core/ngrx/effects/quickQuote.effects';
import { BasketSummaryEffects } from './core/ngrx/effects/basketSummary.effects';
import { BasketQuoteEffects } from './core/ngrx/effects/basketQuote.effects';
import { BasketAccountEffects } from './core/ngrx/effects/basketAccount.effects';
import { BasketItemsEffects } from './core/ngrx/effects/basketItems.effects';

import { MsalRedirectComponent } from '@azure/msal-angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { msalAuthReducer } from './core/ngrx/reducers/msalAuth.reducer.';
import { MsalAuthEffects } from './core/ngrx/effects/msalAuth.effects';
import { countdownTimerReducer } from './core/ngrx/reducers/countdownTimer.reducer';
import { CountdownTimerEffects } from './core/ngrx/effects/countdownTimer.effects';
import { FeatureFlagReducer } from './core/ngrx/reducers/feature-flag.reducer';
import { FeatureFlagEffects } from './core/ngrx/effects/feature-flag.effects';
import { CommonModule } from '@angular/common';

export function loggerCallback(logLevel, message) {
  //console.log(message);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    LayoutModule,
    InfiniteScrollModule,
    RouterModule.forRoot([], {
      enableTracing: false,
    }),
    BrowserModule,
    FeaturesModule,
    SharedModule,
    CoreModule.forRoot('assets/settings/environment.json'),
    BrowserAnimationsModule,
    StoreModule.forRoot(
      {
        ...reducers,
        ...{
          basketItems: basketItemsReducer,
          basketAccount: basketAccountReducer,
          basketQuote: basketQuoteReducer,
          basketSummary: basketSummaryReducer,
          quickQuote: quickQuoteReducer,
          notification: notificationReducer,
          course: courseReducer,
          registerStep: registerStepReducer,
          registerStepOne: registerStepOneReducer,
          registerStepTwo: registerStepTwoReducer,
          registerStepThree: registerStepThreeReducer,
          registerStepFour: registerStepFourReducer,
          msalAuth: msalAuthReducer,
          countdownTimer: countdownTimerReducer,
          featureFlags: FeatureFlagReducer,
        },
      },
      { metaReducers }
    ),
    StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot([
      CurrentUserEffects,
      BasketItemsEffects,
      BasketAccountEffects,
      BasketQuoteEffects,
      BasketSummaryEffects,
      QuickquoteEffects,
      NotificationEffects,
      RegisterstepEffects,
      RegistersteponeEffects,
      RegistersteptwoEffects,
      RegisterstepthreeEffects,
      RegisterstepfourEffects,
      CourseEffects,
      MsalAuthEffects,
      CountdownTimerEffects,
      FeatureFlagEffects,
    ]),
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  providers: [],
})
export class AppModule {}
