<form (submit)="searchCompaniesHouse()" [formGroup]="searchForm">

  <label for="searchCompanyNumber" class="form-label">Enter your company name or companies house registration number here - we'll try to match your details</label>

  <div class="input-group mb-3">
    <input [typeahead]="suggestions"
           typeaheadOptionField="companyHouseNumber"
           (typeaheadOnSelect)="typeaheadOnSelect($event)"
           [typeaheadAsync]="true"
           [isAnimated]="true"
           class="form-control"
           [typeaheadItemTemplate]="customItemTemplate"
           [typeaheadScrollable]="true"
           [typeaheadOptionsInScrollableView]="10"
           formControlName="companyNumber">
    <div class="input-group-append">
      <button class="btn btn-primary" type="button" [class.is-loading]="loading">
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>


  <ng-template #customItemTemplate let-model="item" style="width:100%">
    <p>{{ model.companyHouseNumber}}: {{ model.companyName}} (Postcode: {{model.postcode}})</p>
  </ng-template>
  <alert type="warning" *ngIf="companySearchError"> There was an error searching for your company, please try again later or enter manually. </alert>
  <alert type="warning" *ngIf="companyNotFound"> The Company Search did not return a match. Please check or enter your company details manually. </alert>
  <br />

  <!-- Enter company details manually? -->
  <div class="form-group row">
    <div class="col-md-6">
      <p class="h6">Not listed on Companies House?</p>
      <p><small>No problem, you can enter your details manually.</small></p>
    </div>
    <div class="col-md-5">
      <!-- Enter manually button. -->
      <button class="btn btn-primary" type="button" (click)="enterDetailsManually()">Enter manually</button>

      <!-- Fast Fill button (for testing purposes). -->
      <button class="btn btn-info ml-1" type="button" *ngIf="shouldShowFastFill()" (click)="fastFill()">Fast Fill</button>
    </div>
  </div>
</form>

