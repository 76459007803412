import { Component, Input, SimpleChanges, EventEmitter, Output, OnChanges } from '@angular/core';
import { ProductService } from '../../../core/services/product.service';
import { productTypes } from './../../constants/product-types.const';

@Component({
  selector: 'app-quote-container',
  templateUrl: './quote-container.component.html',
  styleUrls: ['./quote-container.component.scss']
})
export class QuoteContainerComponent implements OnChanges {
  @Output() emitQuote = new EventEmitter();
  @Input() resetQuote: any;
  @Input() productCard?: boolean;
  @Input() amount: any;
  @Input() loading: boolean;
  @Input() title: string;
  @Input() showVatLabel: boolean = true;

  constructor(private Product: ProductService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resetQuote && changes.resetQuote.currentValue) {
      this.amount = null;
    }
  }

  get displayQuote() {
    if (Math.floor(this.amount) === this.amount) return this.amount;
    else return this.amount.toFixed(2);
  }

  //if bundleId is from a product card return true (quote will = quote * employee num)
  isProductCard(bundleProductId): boolean {
    if(this.productCard === true) { return true; }

    for (let property in productTypes) {
      if (bundleProductId === productTypes[property]) {
        return true;
      }
    }
  }

  get quoteAmountStr(): string {
    if (this.amount === undefined || this.amount === null) { return null; }
    if (this.amount === 0 || this.amount === '0' || this.amount === 'FREE') { return 'FREE'; }

    if(isNaN(this.amount)) { return this.amount; }

    const quoteStr = this.amount.toFixed(2).toLocaleString('en-GB');
    return `£${quoteStr}`;
  }

  get amountTitle(): string {
    return this.title ?? 'Price from:';
  }

  get vatLabel(): string {
    return this.showVatLabel ? '+ VAT' : '';
  }
}
