import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanySearchFieldComponent } from './company-search-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BootstrapComponentsModule } from '../../../core';



@NgModule({
  declarations: [CompanySearchFieldComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TypeaheadModule.forRoot(),
    BootstrapComponentsModule
  ],
  exports:[CompanySearchFieldComponent]
})
export class CompanySearchFieldModule { }
